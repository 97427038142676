import React from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import useFilterStyles from '../Styles/useFilterStyles';
import minus from '../../images/minus.svg';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';

const CategoryFilter = ({ values, article }) => {
  const classes = useFilterStyles();
  const {
    id,
    handleClose,
    categories,
    anchorEl,
    setAnchorEl,
    categoryTab,
    setCategoryTab,
    setCatagoryActive,
    categorySearch,
    setCategorySearch,
    selectedCategory,
    setSelectedCategory,
  } = values;

  const onCategorySelect = (e, category) => {
    if (e.target.checked) {
      setSelectedCategory([...selectedCategory, category]);
    } else {
      const difference = selectedCategory.filter(d => d !== category);
      setSelectedCategory(difference);
    }
  };

  const categoryName = category => (article ? category.name : category);

  return (
    <>
      <li
        className={
          categoryTab === true ||
          (selectedCategory && selectedCategory.length > 0)
            ? `${classes.active} ${
                selectedCategory && selectedCategory.length > 0
                  ? classes.valueSelected
                  : ''
              }`
            : ''
        }
      >
        <Button
          aria-describedby={id}
          variant="contained"
          onClick={event => {
            setCategorySearch(true);
            setAnchorEl(event.currentTarget);
            setCategoryTab(true);
            setCatagoryActive(true);
          }}
        >
          {`${article ? 'Category' : 'Offer Category'}`}
          <small>
            <img src={plus} alt="plus" />
            <img src={minus} alt="minus" />
          </small>
        </Button>
        <i
          className="closeIconBtn"
          role="presentation"
          onClick={() => {
            setSelectedCategory([]);
            setAnchorEl('');
            setCatagoryActive(false);
          }}
        >
          <img src={close} alt="close" />
        </i>
        <Popover
          id="category"
          open={categorySearch}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className="filterDropdown filterDropdownCheck offerFilterDropdownCheck">
            <div className="filterDropdownin">
              {categories && categories && categories.length > 0 ? (
                <List className={classes.moreList}>
                  <h4>Select A Category(s)</h4>
                  {categories.map((category, index) => (
                    <>
                      <ListItem key={category}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={categoryName(category)}
                              checked={selectedCategory.includes(
                                categoryName(category),
                              )}
                              onChange={e => {
                                onCategorySelect(e, categoryName(category));
                                setCatagoryActive(false);
                              }}
                            />
                          }
                          label={categoryName(category)}
                        />
                      </ListItem>
                      {categories && categories.length - 1 > index && (
                        <Divider light />
                      )}
                    </>
                  ))}
                </List>
              ) : (
                <Loader type="TailSpin" color="black" height={20} width={20} />
              )}
            </div>
          </div>
        </Popover>
      </li>
    </>
  );
};
export default CategoryFilter;
