import React from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import useFilterStyles from '../Styles/useFilterStyles';
import minus from '../../images/minus.svg';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';

const StatusFilter = ({ values }) => {
  const classes = useFilterStyles();
  const {
    id,
    anchorEl,
    setAnchorEl,
    handleClose,
    selectedEventsType,
    eventsType,
    setEventsType,
    eventTypeSearch,
    setEventTypeSearch,
    onCheckEventButton,
    setOnCheckEventButton,
  } = values;

  const newStatusOptions = (selected, key) => {
    const newEvent =
      eventsType &&
      eventsType.map(events => {
        if (events && events.key === key) {
          events.selected = selected;
        }
        return events;
      });
    setEventsType(newEvent);
  };

  const clearStatusIcon = () => {
    setEventsType([
      { label: 'Paid', key: 'Paid', selected: false },
      { label: 'Free', key: 'Free', selected: false },
    ]);
  };
  return (
    <>
      <li
        className={
          onCheckEventButton === true ||
          (selectedEventsType && selectedEventsType.length > 0)
            ? `${classes.active} ${
                selectedEventsType && selectedEventsType.length > 0
                  ? classes.valueSelected
                  : ''
              }`
            : ''
        }
      >
        <Button
          aria-describedby={id}
          variant="contained"
          onClick={e => {
            setEventTypeSearch(true);
            setAnchorEl(e.currentTarget);
            setOnCheckEventButton(true);
          }}
        >
          Event Type
          <small>
            <img src={plus} alt="plus" />
            <img src={minus} alt="minus" />
          </small>
        </Button>
        <i
          className="closeIconBtn"
          role="presentation"
          onClick={clearStatusIcon}
        >
          <img src={close} alt="close" />
        </i>
        <Popover
          id="events"
          open={eventTypeSearch}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className="filterDropdown">
            <div className="filterDropdownin">
              <List className={classes.moreList}>
                <h4>Select A Status(s)</h4>
                {eventsType &&
                  eventsType.map(events => (
                    <ListItem key={events.key}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={events.label}
                            checked={events.selected}
                            onChange={e => {
                              newStatusOptions(e.target.checked, events.key);
                            }}
                          />
                        }
                        label={events.label}
                        value={events.label}
                      />
                    </ListItem>
                  ))}
              </List>
            </div>
          </div>
        </Popover>
      </li>
    </>
  );
};
export default StatusFilter;
