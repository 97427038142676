/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  // InputLabel,
  // MenuItem,
  Radio,
  RadioGroup,
  // Select,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
// import ColorPicker from 'material-ui-color-picker';
import { ColorPicker } from 'material-ui-color';
import { loyaltyCardSchema } from '../../utils/formValidate';
import dowmArw from '../../images/down-arw.svg';
// import supplierLogo from '../../images/sipplier-icon.svg';
// import buildingLogo from '../../images/building-icon.svg';
import coffeeBeans from '../../images/Coffee_Icons-05.svg';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import './LoyaltyCard.css';
import { GlobalContext } from '../../context/GlobalContext';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';
import FileUpload from '../../components/ImageUpload/FileUpload';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '70%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
    position: 'static',
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '0',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
}));

const CreateLoyaltyCard = () => {
  const classes = useStyles();
  const { setSuccessMessage } = useContext(GlobalContext);
  const history = useHistory();
  const { id } = useParams();
  const [saveFormFileds, setSaveFormFileds] = useState(false);
  const [images, setImages] = useState([]);
  const [logoImages, setLogoImages] = useState([]);
  const [supplierName, setSupplierName] = useState('');
  const [supplierLocation, setSupplierLocation] = useState('');
  const [latitude, setLatitude] = useState('');
  const [langitude, setLangitude] = useState('');
  const [stamps, setStamps] = useState('');
  const [nfc, setNfc] = useState('');
  const [cardDescription, setCardDescription] = useState('');
  const [status, setStatus] = useState('Active');
  const [backgroundcolorValue, setBackgroundColorValue] = useState('');
  const [fontValue, setFontValue] = useState('');
  const [errorMsgs, setErrorMsgs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [coffeeImg, setCoffeImages] = useState([]);
  const [brandedCoffeeCupLogo, setbrandedCoffeeCupLogo] = useState([]);
  // const [cardData, setCardData] = useState('');

  if (!JSON.parse(localStorage.getItem('rolePermission'))[0].createTenantUser) {
    history.push('/tenantusers');
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      const payload = {
        offset: 0,
        limit: 20,
      };
      ApiClient.apiPost(apiEndPoints.getLoyaltyCardAdmin, payload)
        .then(data => {
          const cateringData = data && data.data && data.data.data;
          const cardData = cateringData.find(card => card.id === id);
          setSupplierName(cardData && cardData.supplierName);
          setSupplierLocation(cardData && cardData.supplierLocation.name);
          setLatitude(cardData && cardData.supplierLocation.latitude);
          setLangitude(cardData && cardData.supplierLocation.longitude);
          setStamps(cardData && cardData.stamps);
          setNfc(
            typeof cardData?.nfcChipId === 'string'
              ? cardData?.nfcChipId?.split(',')
              : cardData?.nfcChipId,
          );
          setBackgroundColorValue(cardData && cardData.card.backgroundColor);
          setFontValue(cardData && cardData.card.fontColor);
          setCardDescription(cardData && cardData.card.description);
          setImages(
            (cardData &&
              cardData.card.supplierLogo && [
                { imageUrl: cardData.card.supplierLogo, tag: 'Card' },
              ]) ||
              [],
          );
          setLogoImages(
            (cardData &&
              cardData.card.buildingNameLogo && [
                { imageUrl: cardData.card.buildingNameLogo, tag: 'Card' },
              ]) ||
              [],
          );
          setbrandedCoffeeCupLogo(
            (cardData &&
              cardData.card.brandedCoffeeCupLogo && [
                { imageUrl: cardData.card.brandedCoffeeCupLogo, tag: 'Card' },
              ]) ||
              [],
          );

          setStatus(cardData.status);
          setLoading(false);
        })
        .catch(error => {
          console.log(error, 'error');
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    const coffeeImages = [];
    for (let i = 0; i < stamps; i++) {
      // eslint-disable-next-line no-unused-expressions
      brandedCoffeeCupLogo && brandedCoffeeCupLogo.length
        ? coffeeImages.push(
            <img src={brandedCoffeeCupLogo[0].imageUrl} alt="coffeeCupLogo" />,
          )
        : coffeeImages.push(<img src={coffeeBeans} alt="coffeeCupLogo" />);
    }
    setCoffeImages(coffeeImages);
  }, [stamps, brandedCoffeeCupLogo]);

  useEffect(() => {
    loyaltyCardSchema
      .validate({
        supplierName: supplierName,
        supplierLocation: supplierLocation,
        latitude: latitude,
        langitude: langitude,
        nfc: nfc,
        stamps: stamps,
        cardDescription: cardDescription,
        supplierImage: images,
        logoImage: logoImages,
        backgroundColor: backgroundcolorValue,
        fontColor: fontValue,
      })
      .then(() => {
        if (saveFormFileds) {
          setLoading(true);
          const payload = {
            supplierName: supplierName,
            supplierLocation: {
              name: supplierLocation,
              latitude: latitude,
              longitude: langitude,
            },
            nfcChipId: nfc?.filter(value => value),
            stamps: parseInt(stamps, 10),
            card: {
              supplierLogo: images ? images[0].imageUrl : undefined,
              buildingNameLogo: logoImages ? logoImages[0].imageUrl : undefined,
              backgroundColor: backgroundcolorValue,
              fontColor: fontValue,
              description: cardDescription,
              ...(brandedCoffeeCupLogo && brandedCoffeeCupLogo.length
                ? { brandedCoffeeCupLogo: brandedCoffeeCupLogo[0].imageUrl }
                : ''),
            },
            status: status,
          };
          const editPayload = {
            supplierName: supplierName,
            supplierLocation: {
              name: supplierLocation,
              latitude: latitude,
              longitude: langitude,
            },
            nfcChipId: nfc?.filter(value => value) || '',
            card: {
              supplierLogo: images.length > 0 ? images[0].imageUrl : undefined,
              buildingNameLogo:
                logoImages.length > 0 ? logoImages[0].imageUrl : undefined,
              backgroundColor: backgroundcolorValue,
              fontColor: fontValue,
              description: cardDescription,
              ...(brandedCoffeeCupLogo && brandedCoffeeCupLogo.length
                ? { brandedCoffeeCupLogo: brandedCoffeeCupLogo[0].imageUrl }
                : ''),
            },
            status: status,
          };
          if (id) {
            ApiClient.apiPut(`${apiEndPoints.loyaltyCard}/${id}`, editPayload)
              .then(data => {
                setLoading(false);
                const successmsg =
                  data && data.data.message
                    ? `${payload.supplierName} has been updated successfully!`
                    : '';
                setSuccessMessage(successmsg);
                history.push('/loyaltycardlist');
              })
              .catch(error => {
                setSaveFormFileds(false);
                setLoading(false);
                const errorResponse =
                  error?.response?.data?.errors?.[0] ||
                  error?.response?.data?.message;
                alert(
                  JSON.stringify(
                    errorResponse?.path
                      ? errorResponse?.path + errorResponse?.message
                      : errorResponse,
                  ),
                );
              });
          } else {
            ApiClient.apiPost(apiEndPoints.loyaltyCard, payload)
              .then(data => {
                const successmsg =
                  data && data.data.message
                    ? `${payload.supplierName}  has been added successfully!`
                    : '';
                setSuccessMessage(successmsg);
                setLoading(false);
                history.push('/loyaltycardlist');
              })
              .catch(error => {
                console.log(error.message, 'error1');
                setSaveFormFileds(false);
                setLoading(false);
                const errorResponse =
                  error?.response?.data?.errors?.[0] ||
                  error?.response?.data?.message;
                alert(
                  JSON.stringify(
                    errorResponse?.path
                      ? errorResponse?.path + errorResponse?.message
                      : errorResponse,
                  ),
                );
              });
          }
        }
      })
      .catch(err => {
        console.log(err, 'error2');
        if (saveFormFileds) {
          setErrorMsgs(err);
          setSaveFormFileds(false);
        }
      });
  }, [
    supplierName,
    supplierLocation,
    latitude,
    langitude,
    nfc,
    status,
    stamps,
    saveFormFileds,
  ]);
  const errorType = errorMsgs && errorMsgs.type;
  const errorFieldName = errorMsgs && errorMsgs.path;
  const errorFieldValue = errorMsgs && errorMsgs.value;

  const breadCrumbs = !id
    ? {
        listBreadCrumbPath: '/loyaltycardlist',
        listBreadCrumbTitle: 'Loyalty Cards',
        addBreadCrumbPath: '/createloyaltycard',
        addBreadCrumbTitle: 'Create Loyalty card',
      }
    : {
        listBreadCrumbPath: '/loyaltycardlist',
        listBreadCrumbTitle: 'Loyalty Card List',
        viewBreadCrumbPath: '/viewloyaltycard/',
        viewBreadCrumbTitle: 'View Loyalty Card',
        editBreadCrumbPath: '/edit-loyaltycard/',
        editBreadCrumbTitle: 'Edit Loyalty Card',
      };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addUserContainer">
            <div className={classes.addUserForm}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Supplier Details</FormLabel>
                </li>
              </ul>
              <ul className={`${classes.InputSection} inputSectionAlnBtm`}>
                <li>
                  <span>Supplier name*</span>
                  <Input
                    type="text"
                    inputProps={{ 'aria-label': 'description', maxLength: 150 }}
                    value={supplierName}
                    onChange={e => {
                      setSupplierName(e.target.value);
                    }}
                  />
                  {errorFieldValue && errorFieldValue.supplierName === '' && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        supplierName === '' && 'Supplier Name is required.'
                      }
                    >
                      {supplierName === '' && 'Supplier Name is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <span>Supplier Location*</span>
                  <Input
                    type="text"
                    inputProps={{ 'aria-label': 'description' }}
                    value={supplierLocation}
                    onChange={e => {
                      setSupplierLocation(e.target.value);
                    }}
                  />
                  {errorFieldValue && errorFieldValue.supplierLocation === '' && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        supplierLocation === '' &&
                        'Supplier Location is required.'
                      }
                    >
                      {supplierLocation === '' &&
                        'Supplier Location is required.'}
                    </FormHelperText>
                  )}
                </li>

                <li>
                  <span>Latitude Co-Ordinate*</span>
                  <Input
                    type="text"
                    inputProps={{ 'aria-label': 'description' }}
                    value={latitude}
                    onChange={e => {
                      const re = /^[0-9-. ]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setLatitude(e.target.value);
                      }
                    }}
                  />
                  {errorFieldValue && errorFieldValue.latitude === '' && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={latitude === '' && 'Latitude is required.'}
                    >
                      {latitude === '' && 'Latitude is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <span>Longitude Co-Ordinate*</span>
                  <Input
                    type="text"
                    inputProps={{ 'aria-label': 'description' }}
                    value={langitude}
                    onChange={e => {
                      const re = /^[0-9-. ]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setLangitude(e.target.value);
                      }
                    }}
                  />
                  {errorFieldValue && errorFieldValue.langitude === '' && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={langitude === '' && 'Longitude is required.'}
                    >
                      {langitude === '' && 'Longitude is required.'}
                    </FormHelperText>
                  )}
                </li>
                {id ? (
                  <li>
                    <span> Number Of Stamps Required*</span>
                    <Input
                      type="text"
                      inputProps={{
                        'aria-label': 'description',
                        maxLength: 1,
                      }}
                      value={stamps}
                      readOnly
                    />
                  </li>
                ) : (
                  <li>
                    <span> Number Of Stamps Required*</span>
                    <Input
                      type="text"
                      inputProps={{
                        'aria-label': 'description',
                        maxLength: 1,
                      }}
                      value={stamps}
                      onChange={e => {
                        const re = /^[1-9]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setStamps(e.target.value);
                        }
                      }}
                    />
                    {errorFieldValue && errorFieldValue.stamps === '' && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={stamps === '' && 'Number of stamps is required.'}
                      >
                        {stamps === '' && 'Number of stamps is required.'}
                      </FormHelperText>
                    )}
                  </li>
                )}

                <li className="">
                  <span>NFC Identifier*</span>
                  <Input
                    type="text"
                    inputProps={{ 'aria-label': 'description' }}
                    value={nfc.toString()}
                    onChange={e => {
                      setNfc(e.target.value.split(','));
                    }}
                  />
                  {errorFieldValue &&
                    (errorFieldValue?.nfc === null ||
                      !errorFieldValue?.nfc?.length) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          (nfc === '' || !nfc?.length) &&
                          'Nfc identifier is required.'
                        }
                      >
                        {(nfc === '' || !nfc?.length) &&
                          'Nfc identifier is required.'}
                      </FormHelperText>
                    )}
                  { errorFieldValue?.nfc?.length > 10 && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        nfc?.length > 10 &&
                        'Only 10 NFC IDs allowed'
                      }
                    >
                      {nfc?.length > 10 &&
                        'Only 10 NFC IDs allowed'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="inputSectionAlnBtm">
                <li>
                  <ul className={`${classes.InputSection} inputSectionAlnBtm`}>
                    <li className="loyaltyCardFullW">
                      <span>Card Background color*</span>
                      <ColorPicker
                        disablePlainColor
                        className="colorPickerCard"
                        name="color"
                        defaultValue="#000"
                        value={backgroundcolorValue}
                        onChange={color => {
                          setBackgroundColorValue(
                            color.css ? color.css.backgroundColor : color,
                          );
                        }}
                      />
                      {errorFieldValue &&
                        errorFieldValue.backgroundColor === '' && (
                          <FormHelperText
                            className={classes.errorMsg}
                            error={
                              backgroundcolorValue === '' &&
                              'Background color  is required.'
                            }
                          >
                            {backgroundcolorValue === '' &&
                              'Background color is required.'}
                          </FormHelperText>
                        )}
                    </li>

                    <li className="loyaltyCardFullW">
                      <span>Card Font color*</span>
                      <ColorPicker
                        disablePlainColor
                        className="colorPickerCard"
                        name="color"
                        defaultValue="#fff"
                        value={fontValue}
                        onChange={color =>
                          setFontValue(
                            color.css ? color.css.backgroundColor : color,
                          )
                        }
                      />
                      {errorFieldValue && errorFieldValue.fontColor === '' && (
                        <FormHelperText
                          className={classes.errorMsg}
                          error={fontValue === '' && 'Font color  is required.'}
                        >
                          {fontValue === '' && 'Font color is required.'}
                        </FormHelperText>
                      )}
                    </li>
                    <li className="loyaltyCardFullW">
                      <span>Card Description*</span>
                      <textarea
                        type="text"
                        maxLength="150"
                        value={cardDescription}
                        onChange={e => {
                          setCardDescription(e.target.value);
                        }}
                      />
                      {errorFieldValue &&
                        errorFieldValue.cardDescription === '' && (
                          <FormHelperText
                            className={classes.errorMsg}
                            error={
                              cardDescription === '' &&
                              'Description is required.'
                            }
                          >
                            {cardDescription === '' &&
                              'Description is required.'}
                          </FormHelperText>
                        )}
                    </li>
                  </ul>
                  <ul className="offerFormList">
                    <li>
                      <FormLabel component="legend">Media</FormLabel>
                    </li>
                  </ul>
                  <ul className="offerImgUoload">
                    <li>
                      <FileUpload
                        values={{
                          images,
                          setImages,
                          logoImages,
                          setLogoImages,
                          errorType,
                          errorFieldName,
                          errorFieldValue,
                          brandedCoffeeCupLogo,
                          setbrandedCoffeeCupLogo,
                        }}
                        loyaltyCardSupplier
                        loyaltyCardBuilding
                        loyaltyCardCoffee
                      />
                    </li>
                  </ul>
                </li>

                <li>
                  <div
                    className="loyaltyCardPrev"
                    style={{
                      background: backgroundcolorValue.css
                        ? backgroundcolorValue.css.backgroundColor
                        : backgroundcolorValue,
                    }}
                    id="loyaltyCardBg"
                  >
                    <div className="cardLogo">
                      <ul>
                        {images && images.length > 0 && (
                          <li>
                            <img src={images[0].imageUrl} alt="supplierLogo" />
                          </li>
                        )}
                        {logoImages && logoImages.length > 0 && (
                          <li>
                            <img
                              src={logoImages[0].imageUrl}
                              alt="buildingLogo"
                            />
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="latantaCardDesc">
                      <p
                        style={{
                          color: fontValue.css
                            ? fontValue.css.backgroundColor
                            : fontValue,
                        }}
                      >
                        {cardDescription}
                      </p>
                    </div>
                    <div className="latantaCupAdded">
                      <ol>
                        {coffeeImg &&
                          coffeeImg.map(cf => {
                            return (
                              <li>
                                <img
                                  className="max_height_100p"
                                  src={cf.props.src}
                                  alt="coffeeCupLogo"
                                />
                              </li>
                            );
                          })}
                      </ol>
                    </div>
                  </div>
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li className="statusMarBtm">
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        <FormControlLabel
                          control={<Radio />}
                          label="Active"
                          checked={status === 'Active'}
                          onChange={() => {
                            setStatus('Active');
                          }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Inactive"
                          checked={status === 'Inactive'}
                          onChange={() => {
                            setStatus('Inactive');
                          }}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <SaveAndCancelButtons
                setToggle={setSaveFormFileds}
                route="/loyaltycardlist"
              />
              <WarningPopup />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateLoyaltyCard;
