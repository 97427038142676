/* eslint-disable import/no-cycle */
import React, { createContext, useState, useEffect } from 'react';
import moment from 'moment';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';
import CardManagement from '../containers/CardManagement/CardManagement';
import { highestValidDate } from '../utils/constants';

export const ManageOffersContext = createContext({});

export const ManageOffersContextProvider = () => {
  const [offersList, setOffersList] = useState([]);
  const [offersSearch, setOffersSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('startDate');
  const [totalOffersCount, setTotalOffersCount] = useState(0);
  const [page, setPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [buildings, setBuildings] = useState([]);
  const [selectedVillage, setSelectedVillage] = useState('');
  const [selectedOfferType, setSelectedOfferType] = useState([]);
  const [offerTypeDigital, setOfferTypeDigital] = useState('');
  const [offerTypePhysical, setOfferTypePhysical] = useState('');
  const [offersCategories, setOffersCategories] = useState([]);
  const [selectedOfferCategory, setSelectedOfferCategory] = useState([]);
  const [offerCategoryActive, setOfferCatagoryActive] = useState(false);
  const [offerTypes, setOfferTypes] = useState([
    { label: 'Digital', key: 'Digital', selected: false },
    { label: 'Physical', key: 'Physical', selected: false },
  ]);
  const offerSearchResult = offersSearch.length > 2 ? offersSearch : '';
  // News and Announcements loop videos in Card management.
  const [newsAnnouncementList, setNewsAnnouncementList] = useState([]);
  const [newsOffset, setNewsOffset] = useState(0);
  const [newsPage, setNewsPage] = useState(0);
  const [totalArticlesCount, setTotalArticlesCount] = useState(0);
  const [articleSearch, setArticleSearch] = useState('');
  const [categories, setCategories] = useState([]);
  const [newsSortOrder, setNewsSortOrder] = useState('desc');
  const [newsSortBy, setNewsSortBy] = useState('startDate');
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [newsBuildings, setNewsBuildings] = useState([]);
  const [newsVillages, setNewsVillages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryActive, setCatagoryActive] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  const startDateApi = `${moment(currentDate)
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)}`;
  const endDateApi = `${moment(highestValidDate)
    .set('hours', 23)
    .set('minutes', 59)
    .set('seconds', 0)}`;

  const articleSearchResult =
    articleSearch.length > 2 ? articleSearch : undefined;

  const getNewsAnnouncementListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getNewsAnnouncementsList, payload)
      .then(data => {
        setNewsAnnouncementList(data && data.data && data.data.data);
        setTotalArticlesCount(data && data.data.count);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  const getArticlesCategoryAction = () => {
    if (categories.length === 0) {
      ApiClient.apiGet(apiEndPoints.getArticlesCategory)
        .then(data => {
          setCategories(data && data.data && data.data.data);
        })
        .catch(error => {
          console.log(error, 'error');
        });
    }
  };

  useEffect(() => {
    if (categoryActive === true) {
      getArticlesCategoryAction();
    }
  }, [categoryActive]);

  useEffect(() => {
    getNewsAnnouncementListAction({
      searchText: articleSearchResult ? articleSearch : undefined,
      sortOrder: newsSortOrder,
      buildings:
        newsBuildings && newsBuildings.length > 0 ? newsBuildings : undefined,
      platform: ['CustomerApp', 'All'],
      startDate: moment.tz(startDateApi, 'Europe/London').toISOString(),
      endDate: moment.tz(endDateApi, 'Europe/London').toISOString(),
      category:
        selectedCategory && selectedCategory.length > 0
          ? selectedCategory
          : undefined,
      sortBy: newsSortBy,
      offset: newsOffset,
      limit: 40,
    });
  }, [
    newsSortOrder,
    newsSortBy,
    newsOffset,
    newsBuildings,
    articleSearchResult,
    selectedStartDate,
    selectedEndDate,
    selectedCategory,
  ]);

  const getOffersListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getOffersList, payload)
      .then(data => {
        setOffersList(data && data.data && data.data.data);
        setTotalOffersCount(data && data.data.count);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.log(error, 'error');
      });
  };

  const getOffersCategoryAction = () => {
    if (offersCategories.length === 0) {
      ApiClient.apiGet(apiEndPoints.getOffersCategory)
        .then(data => {
          setOffersCategories(data && data.data && data.data.data);
        })
        .catch(error => {
          setLoading(false);
          console.log(error, 'error');
        });
    }
  };

  useEffect(() => {
    const updatedofferType = [];
    offerTypes.filter(offerType => {
      if (offerType.selected) {
        updatedofferType.push(offerType.key);
      }
      return offerType.selected !== false;
    });
    setSelectedOfferType(updatedofferType);

    if (offerCategoryActive) {
      getOffersCategoryAction();
    }
    getOffersListAction({
      searchText: offerSearchResult ? offersSearch : undefined,
      status: ['Published'],
      todayDate: new Date().toISOString(),
      buildings: buildings && buildings.length > 0 ? buildings : undefined,
      type:
        updatedofferType && updatedofferType.length > 0
          ? updatedofferType
          : undefined,
      category:
        selectedOfferCategory && selectedOfferCategory.length > 0
          ? selectedOfferCategory
          : undefined,
      sortOrder,
      sortBy,
      offset,
      limit: 40,
    });
  }, [
    offerSearchResult,
    sortOrder,
    sortBy,
    offset,
    offerTypes,
    buildings,
    selectedOfferCategory,
    offerCategoryActive,
  ]);

  return (
    <ManageOffersContext.Provider
      value={{
        // news props
        newsOffset,
        setNewsOffset,
        newsPage,
        setNewsPage,
        newsSortOrder,
        setNewsSortOrder,
        newsSortBy,
        setNewsSortBy,
        articleSearch,
        setArticleSearch,
        newsAnnouncementList,
        totalArticlesCount,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        selectedCategory,
        setSelectedCategory,
        categoryActive,
        setCatagoryActive,
        selectedPlatform,
        setSelectedPlatform,
        newsBuildings,
        setNewsBuildings,
        newsVillages,
        setNewsVillages,
        categories,
        setCategories,
        // offer props
        loading,
        offersList,
        offersSearch,
        setOffersSearch,
        totalOffersCount,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        page,
        setPage,
        setOffset,
        setBuildings,
        selectedVillage,
        setSelectedVillage,
        selectedOfferType,
        setSelectedOfferType,
        offerTypes,
        setOfferTypes,
        offerTypeDigital,
        setOfferTypeDigital,
        offerTypePhysical,
        setOfferTypePhysical,
        offersCategories,
        setOffersCategories,
        selectedOfferCategory,
        setSelectedOfferCategory,
        setOfferCatagoryActive,
        setOffersList,
      }}
    >
      <CardManagement />
    </ManageOffersContext.Provider>
  );
};
