/* eslint-disable import/no-cycle */
import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';
import EventsList from '../containers/Events/EventsList';

export const EventsContext = createContext({});

export const EventsContextProvider = () => {
  const [eventsList, setEventsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bookingSearchId, setBookingSearchId] = useState(undefined);
  const [eventsSearch, setEventsSearch] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [villages, setVillages] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [totalEventsCount, setTotalEventsCount] = useState(0);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('eventDate');
  const [statuses, setStatuses] = useState([
    { label: 'Published', key: 'Published', selected: false },
    { label: 'Unpublished', key: 'Unpublished', selected: false },
  ]);

  const startDateApiReq = `${moment
    .utc(selectedStartDate)
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)}`;

  const endDateApiReq = `${moment
    .utc(selectedEndDate)
    .set('hours', 23)
    .set('minutes', 59)
    .set('seconds', 0)}`;

  // get Booking List API Call
  const getEventsListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getEventsList, payload)
      .then(data => {
        setEventsList(data && data.data && data.data.data);
        setTotalEventsCount(data && data.data.count);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  useEffect(() => {
    const statusUpdate = [];
    statuses.filter(status => {
      if (status.selected) {
        statusUpdate.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedStatus(statusUpdate);
    getEventsListAction({
      searchText: eventsSearch.length > 2 ? eventsSearch : undefined,
      buildings: buildings && buildings.length > 0 ? buildings : undefined,
      startDate:
        selectedStartDate && selectedEndDate
          ? moment(startDateApiReq).toISOString()
          : undefined,
      endDate:
        selectedStartDate && selectedEndDate
          ? moment(endDateApiReq).toISOString()
          : undefined,
      sortOrder,
      sortBy,
      status:
        statusUpdate && statusUpdate.length > 0 ? statusUpdate : ['Published'],
      offset,
      limit: 40,
    });
  }, [
    buildings,
    eventsSearch,
    selectedStartDate,
    selectedEndDate,
    sortOrder,
    sortBy,
    statuses,
    offset,
  ]);

  return (
    <EventsContext.Provider
      value={{
        eventsList,
        setEventsList,
        loading,
        setLoading,
        eventsSearch,
        setEventsSearch,
        bookingSearchId,
        setBookingSearchId,
        buildings,
        setBuildings,
        villages,
        setVillages,
        setStatuses,
        statuses,
        setSelectedStatus,
        selectedStatus,
        totalEventsCount,
        setTotalEventsCount,
        offset,
        setOffset,
        page,
        setPage,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
      }}
    >
      <EventsList />
    </EventsContext.Provider>
  );
};
