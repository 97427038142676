import { BlobServiceClient } from '@azure/storage-blob';
import {
  BLOB_CONTAINER_NAME,
  SAS_TOKEN,
  STORAGE_ACCOUNT_NAME,
} from '../../utils/config';

export const getBlobsInContainer = async () => {
  const blobService = new BlobServiceClient(
    `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/?${SAS_TOKEN}`,
  );
  const containerClient = blobService.getContainerClient(BLOB_CONTAINER_NAME);
  await containerClient.createIfNotExists({
    access: 'container',
  });
  const returnedBlobUrls = [];
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    returnedBlobUrls.push(
      `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${BLOB_CONTAINER_NAME}/${blob.name}`,
    );
  }
  return returnedBlobUrls;
};

export const uploadFileToBlob = async file => {
  if (!file) return null;

  const blobService = new BlobServiceClient(
    `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/?${SAS_TOKEN}`,
  );
  const timeStamp = `${new Date().getTime()}.pdf`;
  const containerClient = blobService.getContainerClient(BLOB_CONTAINER_NAME);
  // await containerClient.createIfNotExists({ access: 'container' });
  const blobClient = containerClient.getBlockBlobClient(timeStamp);
  const options = { blobHTTPHeaders: { blobContentType: file.type } };
  await blobClient.uploadData(file, options);
  return `https://${STORAGE_ACCOUNT_NAME}.blob.core.windows.net/${BLOB_CONTAINER_NAME}/${timeStamp}`;
};
