import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import dowmArw from '../../images/down-arw.svg';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import buttonsEditPencil from '../../images/buttons-edit-w-pencil.svg';
import './createcategory.css';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { GlobalContext } from '../../context/GlobalContext';
import CloseButton from '../../components/Buttons/CloseButton';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
}));

const ViewCategory = () => {
  const { rolePermission } = useContext(GlobalContext);
  const classes = useStyles();
  const { id } = useParams();
  const [cateringCategoryDetails, setcateringCategoryDetails] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get tenant users api call.
    ApiClient.apiGet(apiEndPoints.getcateringCategoryDetails + id)
      .then(data => {
        const cateringData = data && data.data && data.data.data;
        setcateringCategoryDetails(cateringData);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
        setLoading(false);
      });
  }, []);

  const breadCrumbs = {
    listBreadCrumbPath: '/cateringlist',
    listBreadCrumbTitle: 'Catering categories',
    viewBreadCrumbPath: '/view-category/',
    viewBreadCrumbTitle: 'View category',
  };

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addUserContainer">
            <div className={`${classes.addUserForm} createcategoryForm`}>
              {rolePermission && rolePermission.editCateringCategory && (
                <Link to={`/edit-category/${id}`}>
                  <div className="ditIcon">
                    <img src={buttonsEditPencil} alt="buttonsEditPencil" />
                  </div>
                </Link>
              )}
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Category Details</FormLabel>
                </li>
              </ul>
              <ul className={classes.InputSection}>
                <li className="radioSectionLabel">
                  <span>Supplier</span>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        {cateringCategoryDetails.supplierName === 'Lantana' && (
                          <p>{cateringCategoryDetails.supplierName}</p>
                        )}
                        {cateringCategoryDetails.supplierName ===
                          'Derwent London' && (
                          <p>{cateringCategoryDetails.supplierName}</p>
                        )}
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <ul className={`${classes.InputSection} creaeCategoryField`}>
                <li>
                  <span>Category name</span>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={cateringCategoryDetails.categoryName}
                  />
                </li>
              </ul>
              <ul className={`${classes.InputSection}`}>
                <li className={`${classes.InputSection}`}>
                  <span>Availabilty start time</span>
                  <Input
                    readOnly
                    type="text"
                    value={moment
                      .tz(cateringCategoryDetails.startTime, 'Europe/London')
                      .format('hh:mm a')}
                  />
                </li>
                <li>
                  <span>Availability end time</span>
                  <Input
                    readOnly
                    type="text"
                    value={moment
                      .tz(cateringCategoryDetails.endTime, 'Europe/London')
                      .format('hh:mm a')}
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li className="statusMarBtm">
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul className={classes.InputSection}>
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        {cateringCategoryDetails.status === 'Available' && (
                          <p>{cateringCategoryDetails.status}</p>
                        )}
                        {cateringCategoryDetails.status ===
                          'Decommissioned' && (
                          <p>{cateringCategoryDetails.status}</p>
                        )}
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <CloseButton route="/cateringlist" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewCategory;
