/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import { CircularProgress, Divider, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import dowmArw from '../../images/down-arw.svg';
import './OffersList.css';
import OfferFilters from './OfferFilters';
import { OffersListContext } from '../../context/OffersListContext';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import { GlobalContext } from '../../context/GlobalContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import AddButton from '../../components/Buttons/AddButton';
import SnackBar from '../../components/SnackBar/SnackBar';
import EditButton from '../../components/Buttons/EditButton';
import { utcToIstDateFormate } from '../../utils/dateUtils';


const columns = [
  {
    id: 'offerName',
    SortOrder: 'desc',
    SortBy: 'offerName',
    label: 'OFFER',
    minWidth: 260,
    maxWidth: 260,
  },
  {
    id: 'companyName',
    SortOrder: 'desc',
    SortBy: 'companyName',
    label: 'COMPANY',
    minWidth: 220,
    maxWidth: 220,
  },
  {
    id: 'startDate',
    SortOrder: 'desc',
    SortBy: 'startDate',
    label: 'START DATE',
    minWidth: 170,
    maxWidth: 170,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'endDate',
    SortOrder: 'desc',
    SortBy: 'endDate',
    label: 'EXPIRY DATE',
    minWidth: 130,
    maxWidth: 130,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'redeemed',
    SortOrder: 'desc',
    SortBy: 'clicksCount',
    label: 'REDEEMED',
    align: 'right',
    minWidth: 120,
    maxWidth: 120,
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'STATUS',
    minWidth: 90,
    maxWidth: 90,
    format: value => value.toFixed(2),
  },
  {
    id: 'edit',
    SortOrder: '',
    SortBy: '',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 28px 10px 15px',
    color: '#444444',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  accountBoxImg: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      margin: '0 10px',
    },
  },
  tableBody: {
    verticalAlign: 'top',
    '& th': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
    },
    '& td': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  pageListMain: {
    position: 'relative',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '10px',
    padding: '5px 0',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#838384',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '30px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
});

const OffersList = () => {
  const classes = useStyles();
  const {
    offersList,
    loading,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
  } = useContext(OffersListContext);
  const { rolePermission } = useContext(GlobalContext);
  const history = useHistory();
  const [active, setActive] = useState(false);
  const handleClick = (columnId, sortName) => {
    setSortOrder(sortOrder === columnId ? 'asc' : 'desc');
    setSortBy(sortBy === sortName ? sortBy : sortName);
  };

  const viewUser = rowId => {
    history.push(`/view-offers/${rowId}`);
  };

  const breadCrumbs = {
    listBreadCrumbPath: '/offers-list',
    listBreadCrumbTitle: 'Offers',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.pageListMain}>
      <SnackBar />
      {rolePermission && rolePermission.createOffer && (
        <AddButton route="/create-offers" />
      )}
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      <OfferFilters />
      <Divider light />
      <div className="pageContainer tableContainer offerListTable">
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      className={`${classes.tableHeaderCell} noSort ${
                        active && column.SortBy === sortBy ? 'active' : ''
                      }`}
                      align={column.align}
                      id={column.label}
                      key={column.id}
                      disabled={column.SortBy === ''}
                      onClick={() => {
                        if (column.SortBy !== '' || column.SortBy === sortBy) {
                          handleClick(column.SortOrder, column.SortBy);
                          setActive(!active);
                        }
                      }}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TableBody>
                  {offersList && offersList.length > 0 ? (
                    offersList.map(offerList => (
                      <TableRow
                        key={offerList.id}
                        className={classes.tableBody}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ maxWidth: 260, width: 260 }}
                          onClick={() => {
                            viewUser(offerList.id);
                          }}
                        >
                          <div className="rowEllips">
                            <Tooltip title={offerList.offerName}>
                              <div className={classes.customerName}>
                                {offerList.offerName}
                              </div>
                            </Tooltip>
                            <div
                              className={`${classes.customerEmail} OfferType`}
                            >
                              {offerList.offerType === "Url" ? "Digital URL Only":offerList.offerType}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 180, width: 180 }}
                          onClick={() => {
                            viewUser(offerList.id);
                          }}
                        >
                          <div className="rowEllips">
                            {offerList.companyName}
                            <Tooltip title={offerList.offerCategory}>
                              <div className={classes.customerVillg}>
                                {offerList.offerCategory}
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 150, width: 150 }}
                          onClick={() => {
                            viewUser(offerList.id);
                          }}
                        >
                          <div className="rowEllips">
                            {moment(offerList.startDate).format('DD MMM YYYY')}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 190, width: 190 }}
                          onClick={() => {
                            viewUser(offerList.id);
                          }}
                        >
                          <div className="rowEllips">
                            {' '}
                            {moment(
                              utcToIstDateFormate(offerList.endDate),
                            ).format('DD MMM YYYY')}
      
                          </div>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ maxWidth: 120, width: 120 }}
                          onClick={() => {
                            viewUser(offerList.id);
                          }}
                        >
                          {offerList.clicksCount !== null ||
                          offerList.viewsCount !== null ? (
                            <div className="rowEllips">
                              {offerList.clicksCount}
                            </div>
                          ) : (
                            <div className="rowEllips">__</div>
                          )}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 90, width: 90 }}
                          onClick={() => {
                            viewUser(offerList.id);
                          }}
                        >
                          <div className="rowEllips">{offerList.status}</div>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ maxWidth: 50, width: 50 }}
                        >
                          {rolePermission && rolePermission.editOffer && (
                            <EditButton
                              route={`/edit-offers/${offerList.id}`}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <NoResultsFound />
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};
export default OffersList;
