import React, { useRef, useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { Dialog, DialogActions, Button } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useHistory, useLocation } from 'react-router-dom';
import { authContext } from '../../adal';
import constants from '../../helpers/constants';
import cateringConstants from '../../helpers/cateringConstants';
import './sidebar.css';
import { GlobalContext } from '../../context/GlobalContext';
import apiEndPoints from '../../helpers/apiEndPoints';
import ApiClient from '../../helpers/ApiClient';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },

  drawerPaper: {
    width: drawerWidth,
    height: 'calc(100% - 70px)',
    top: '70px',
    paddingTop: '6px',
    zIndex: 222222,
  },
  navLogout: {
    bottom: 0,
    width: '100%',
    borderTop: '1px solid #ccc',
    background: '#fff',
    position: 'relative',
    fontSize: 14,
    color: '#838384',
    padding: '0',
    '& span': {
      fontSize: 14,
      color: '#838384',
      paddingLeft: 9,
    },
  },
  navItems: {
    height: '100%',
    overflowY: 'auto',
    '& li': {
      cursor: 'pointer',
      padding: '0',
      '& div': {
        margin: '0',
        '& span': {
          fontSize: '14px',
          color: '#838384',
          padding: '15px 25px',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
    '& selected': {
      '& div': {
        '& span': {
          background: '#000',
          color: '#fff',
        },
      },
    },
  },
  subNavItem: {
    paddingLeft: '50px !important',
  },
}));

const Slidebar = props => {
  const { open, setOpen } = props;
  const history = useHistory();
  const location = useLocation();
  const wrapperRef = useRef(null);
  const classes = useStyles();
  const [selectedPath, setSelectedPath] = useState(window.location.pathname);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [subOpen, setSubOpen] = React.useState(false);
  const { rolePermission, isCatering } = useContext(GlobalContext);
  let filteredColumns = constants?.routesList;


  const handleSubClick = () => {
    setSubOpen(!subOpen);
  };

  useEffect(() => {
    if (
      location &&
      location.pathname &&
      location.pathname.includes('view-event-details')
    ) {
      setSelectedPath('/events-list');
    }
  }, [location]);

  // closing the dropdown onclick of outside
  const useOutside = ref => {
    useEffect(() => {
      const handleClickOutside = event => {
        if (
          event.target.parentNode.className !== 'MuiListItemText-root' &&
          event.target.parentNode.className !== 'MuiIconButton-label'
        ) {
          setOpen(false);
        }
      };
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutside(wrapperRef);

  const handleClose = () => {
    history.push('/');
    setSelectedPath('');
    setOpen(false);
    setCancelOpen(false);
  };

  if (isCatering === false) {
    filteredColumns = constants?.routesList.filter(col => col.type !== 'catering');
  }
  const logAct = () => {
    const payload = {
      act: 'signOut',
    };
    ApiClient.apiPost(apiEndPoints.logAct, payload)
      .then(() => {
        localStorage.clear();
        authContext.logOut();
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div className={classes.root} ref={wrapperRef}>
      <div className={classes.leftNavMain}>
        {(rolePermission && rolePermission.userType === 'Caterer' && isCatering && (
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={rolePermission.userType === 'Caterer' ? open : false}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {rolePermission.userType === 'Caterer' && (
              <List className={`${classes.navItems} sideBar`}>
                {cateringConstants &&
                  cateringConstants.routesList &&
                  cateringConstants.routesList.map(data => (
                    <ListItem
                      key={data.value}
                      className={classes.navActive}
                      selected={selectedPath === data.path}
                      onClick={() => {
                        if (
                          rolePermission &&
                          rolePermission[`${data.accessValue}`]
                        ) {
                          setSelectedPath(data.path);
                          setOpen(false);
                          history.push(data.path);
                        } else {
                          setCancelOpen(true);
                        }
                      }}
                    >
                      <ListItemText>{data.value}</ListItemText>
                    </ListItem>
                  ))}
              </List>
            )}
            <List className={classes.navLogout}>
              <ListItem
                button
                onClick={() => {
                  logAct();
                }}
              >
                <ListItemText>Log Out</ListItemText>
              </ListItem>
            </List>
          </Drawer>
        )) || (
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={rolePermission ? open : false}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <List className={`${classes.navItems} sideBar`}>
                {
                  filteredColumns.map(data => (
                    <ListItem
                      key={data.value}
                      className={classes.navActive}
                      selected={selectedPath === data.path}
                      onClick={() => {
                        if (
                          rolePermission &&
                          rolePermission[`${data.accessValue}`]
                        ) {
                          setSelectedPath(data.path);
                          setOpen(false);
                          history.push(data.path);
                        } else {
                          setCancelOpen(true);
                        }
                      }}
                    >
                      <ListItemText>{data.value}</ListItemText>
                    </ListItem>
                  ))}
                {constants &&
                  constants.routesListWithSubs &&
                  constants.routesListWithSubs.map(data => (
                    <>
                      <ListItem onClick={handleSubClick}>
                        <ListItemText>{data.parent}</ListItemText>
                        {subOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={subOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {data.children.map(child => (
                            <ListItem
                              className={classes.subNavItem}
                              key={child.value}
                              onClick={child.path !== null ? () => {
                                if (
                                  rolePermission &&
                                  rolePermission[`${child.accessValue}`]
                                ) {
                                  setSelectedPath(child.path);
                                  setOpen(false);
                                  history.push(child.path);
                                } else {
                                  setCancelOpen(true);
                                }
                              } : () => { window.open(child.url, '_blank') }}
                            >
                              <ListItemText primary={child.value} />
                            </ListItem>
                          ))}
                        </List>
                      </Collapse>
                    </>
                  ))}
              </List>
              <List className={classes.navLogout}>
                <ListItem
                  button
                  onClick={() => {
                    logAct();
                  }}
                >
                  <ListItemText>Log Out</ListItemText>
                </ListItem>
              </List>
            </Drawer>
          )}
      </div>
      <Dialog
        open={cancelOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="cancelDialogOpend"
      >
        <div className={classes.cancelDialog}>
          <h1>Access Denied</h1>
        </div>
        <DialogActions className={`${classes.formButton} popupFormButton `}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Slidebar;
