/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-cycle */
import React, { useState, useContext, useEffect } from 'react';
import {
  AccordionSummary,
  Divider,
  Button,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import './CardManagement.css';
import activeArrow from '../../images/active-arrow.svg';
import removeCard from '../../images/close-icon.svg';
import OfferManagementFilter from './OfferManagementFilter';
import { GlobalContext } from '../../context/GlobalContext';
import { ManageOffersContext } from '../../context/ManageOffersContext';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import LoopVideosFilter from './LoopVideosFilter';
import SnackBar from '../../components/SnackBar/SnackBar';
import VillageFilter from '../../components/Filters/VillageFilter';

const useStyles = makeStyles({
  leftFilterIn: {
    '& button': {
      color: '#444444',
      backgroundColor: '#f8f8f8',
      padding: '12px 12px 10px',
      fontWeight: 'bold',
      boxShadow: 'none',
      fontSize: '14px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      borderRadius: 5,
      '&:hover': {
        boxShadow: 'none',
        background: '#f8f8f8',
      },
      '& span': {
        lineHeight: '100%',
        alignItems: 'center',
        display: 'flex',

        '& small': {
          marginLeft: 20,
          top: '-2px',
          position: 'relative',
        },
        '& img': {
          '&:nth-child(2)': {
            display: 'none',
          },
        },
      },
    },
  },
});

const getItemStyle = draggableStyle => ({
  ...draggableStyle,
});

const CardManagement = () => {
  const {
    setBuildings,
    offersList,
    selectedVillage,
    setSelectedVillage,
    loading,
    newsAnnouncementList,
  } = useContext(ManageOffersContext);
  const {
    setOpen,
    setClosePopup,
    buildingGroupsList,
    setSuccessMessage,
  } = useContext(GlobalContext);
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [loopVideoToggle, setLoopVideoToggle] = useState(false);
  const [expandToggle, setExpandToggle] = useState(false);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [onSave, setOnSave] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isVideoSelected, setIsVideoSelected] = useState(false);
  const [onSaveVideoCards, setOnSaveVideoCards] = useState(false);
  const [savedVideoCards, setSavedVideoCards] = useState([]);
  const [removedVideo, setRemovedVideo] = useState({});
  const [seletedVideoCards, setSeletedVideoCards] = useState([]);
  const [clearSelectedCards, setclearSelectedCards] = useState('');
  const [selectedCardName, setSelectedCardName] = useState('');
  const [getLoopVideos, setGetLoopVideos] = useState(false);
  const featuredNewsList =
    newsAnnouncementList &&
    newsAnnouncementList.filter(
      newsAnnouncement => newsAnnouncement.mediaType === 'featured',
    );

  const selectedOffersReq = () =>
    selectedOffers &&
    selectedOffers.map(
      (selectedOffer, index) =>
        selectedOffer && {
          offerId: selectedOffer.id,
          village: selectedVillage,
          position: index + 1,
        },
    );

  const selectedVideoCardsReq = () =>
    seletedVideoCards &&
    seletedVideoCards.map(seletedVideo =>
      seletedVideo && seletedVideo.id
        ? {
            id: seletedVideo.id,
            loopId: seletedVideo.loopId,
            pageName: seletedVideo.pageName,
          }
        : {
            loopId: seletedVideo.loopId,
            pageName: seletedVideo.pageName,
          },
    );

  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      selectedOffers,
      result.source.index,
      result.destination.index,
    );
    setSelectedOffers(items);
  };

  const breadCrumbs = {
    listBreadCrumbPath: '/card-management',
    listBreadCrumbTitle: 'Card Management',
  };

  const removeOffer = selectedId =>
    selectedOffers && selectedOffers.filter(item => item.id !== selectedId);

  const removeLoopVideo = pageName =>
    seletedVideoCards &&
    seletedVideoCards.filter(item => item.pageName !== pageName);

  const addVideoToList = pageName =>
    savedVideoCards &&
    savedVideoCards.filter(
      savedVideoCard => savedVideoCard.pageName === pageName,
    );

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClear = () => {
    setSelectedVillage('');
    setSelectedOffers([]);
    setclearSelectedCards('');
  };

  useEffect(() => {
    if (selectedVillage) {
      setIsSelected(true);
      ApiClient.apiPost(apiEndPoints.allOfferCards, {
        village: selectedVillage,
      })
        .then(data => {
          setIsSelected(false);
          const resdata = data && data.data && data.data.data;
          setSelectedOffers(resdata && resdata.offerCards);
        })
        .catch(err => {
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
        });
    }
  }, [selectedVillage]);

  useEffect(() => {
    if (!isVideoSelected) {
      setGetLoopVideos(true);
      ApiClient.apiPost(apiEndPoints.allLoopVideoCards)
        .then(data => {
          const resdata = data && data.data && data.data.data;
          setSeletedVideoCards(resdata && resdata.loopVideoCards);
          setSavedVideoCards(resdata && resdata.loopVideoCards);
          setExpanded('panel1');
          setGetLoopVideos(false);
          setIsVideoSelected(!isVideoSelected);
        })
        .catch(err => {
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
        });
    }
  }, [isVideoSelected]);

  useEffect(() => {
    if (onSave) {
      ApiClient.apiPost(apiEndPoints.offerCards, { cards: selectedOffersReq() })
        .then(data => {
          setSuccessMessage(data && data.data && data.data.message);
          handleClear();
          setOnSave(false);
        })
        .catch(err => {
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
          setOnSave(false);
        });
    } else if (onSaveVideoCards) {
      ApiClient.apiPost(apiEndPoints.saveLoopVideos, {
        cards: selectedVideoCardsReq(),
      })
        .then(data => {
          setSuccessMessage(data && data.data && data.data.message);
          setOnSaveVideoCards(false);
          setExpanded(false);
          setSeletedVideoCards([]);
          setIsVideoSelected(!isVideoSelected);
        })
        .catch(err => {
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
          setOnSaveVideoCards(false);
        });
    }
  }, [onSave, onSaveVideoCards]);

  const loopVideoCard = id =>
    seletedVideoCards &&
    seletedVideoCards.filter(item => item && item.pageName === id);

  const matched = offer =>
    selectedOffers && selectedOffers.filter(item => item.id === offer);

  const disableMatched = matchedId =>
    seletedVideoCards &&
    seletedVideoCards.filter(item => item.loopId === matchedId);

  document.addEventListener(
    'play',
    e => {
      const audios = document.getElementsByTagName('video');
      for (let i = 0, len = audios.length; i < len; i++) {
        if (audios[i] !== e.target) {
          audios[i].pause();
        }
      }
    },
    true,
  );

  const selectedLoopVideos = cardName =>
    loopVideoCard && loopVideoCard(cardName).length > 0 ? (
      loopVideoCard &&
      loopVideoCard(cardName).map(item => (
        <>
          <li key={item.id}>
            <h3>{item.pageName}</h3>
            <div className="allOffercard offerCardBox videoCardBlock ">
              <div className="loopVideoRemove">
                <div className="removeOfferCard">
                  <img
                    src={removeCard}
                    alt="offerLogo"
                    role="presentation"
                    onClick={() => {
                      setSeletedVideoCards(removeLoopVideo(item.pageName));
                      setLoopVideoToggle(false);
                    }}
                  />
                </div>
                <div className="offerLogoBlock">
                  <video preload="none" src={item.loopVideoText} controls />
                </div>
                <div className="offerDetails">
                  <div className="publishDateMain">
                    <div className="publishDate">
                      <h3>{item.title}</h3>
                      <h3>{item.author}</h3>
                    </div>
                    <div className="publishType">
                      {item && item.category.name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </>
      ))
    ) : (
      <li
        className={`${
          loopVideoToggle && removedVideo && removedVideo.pageName === cardName
            ? 'shapesHide opened'
            : 'shapesHide'
        }`}
      >
        <h3>{cardName}*</h3>
        <div
          className="offerCardBox offerCardBoxadd"
          onClick={() => {
            setSelectedCardName(cardName);
            setRemovedVideo(addVideoToList(cardName)[0]);
            setLoopVideoToggle(!loopVideoToggle);
          }}
          role="presentation"
        >
          &nbsp;
        </div>
        <div className="activeShape">
          {' '}
          <img src={activeArrow} alt="activeArrow" />{' '}
        </div>
      </li>
    );

  return (
    <div className={classes.cardRoot}>
      <SnackBar />
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      <div className="pageContainer tableContainer offerCardManage">
        <div className="cardHeading">Global Cards</div>
        <div className="cardrootMain">
          <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <h5>Loop Videos</h5>
            </AccordionSummary>
            <AccordionDetails>
              {onSaveVideoCards ? (
                <CircularProgress color="inherit" className="offerLoader" />
              ) : (
                <div className="offerCardBlock">
                  <div
                    className={`${
                      loopVideoToggle
                        ? 'selectedOfferBlock opened'
                        : 'selectedOfferBlock'
                    }`}
                  >
                    {getLoopVideos ? (
                      <CircularProgress
                        color="inherit"
                        className="offerLoader"
                      />
                    ) : (
                      <ul className="offerCardBoxAdded">
                        {selectedLoopVideos('Home')}
                        {selectedLoopVideos('Meeting')}
                        {selectedLoopVideos('Events')}
                        {selectedLoopVideos('Offers')}
                      </ul>
                    )}
                  </div>
                  {loopVideoToggle &&
                    seletedVideoCards &&
                    seletedVideoCards.length < 4 && (
                      <div className="allOfferCardBlock videoCardBlock">
                        <div className="allOfferFilter">
                          <h3>All Videos </h3>
                          <div className="offerfilterRgt">
                            <LoopVideosFilter />
                          </div>
                        </div>
                        <div className="allOffercard">
                          <ul>
                            {featuredNewsList && featuredNewsList.length > 0 ? (
                              <>
                                {featuredNewsList &&
                                  featuredNewsList.map(loopData => (
                                    <li
                                      key={loopData.id}
                                      className={`${
                                        disableMatched(loopData.id) &&
                                        disableMatched(loopData.id).length > 0
                                          ? 'disable'
                                          : null
                                      }`}
                                      role="presentation"
                                      onClick={() => {
                                        if (
                                          !(
                                            disableMatched(loopData.id) &&
                                            disableMatched(loopData.id).length >
                                              0
                                          )
                                        ) {
                                          setSeletedVideoCards([
                                            ...seletedVideoCards,
                                            {
                                              ...loopData,
                                              pageName: selectedCardName,
                                              loopId: loopData.id,
                                              id:
                                                removedVideo && removedVideo.id,
                                            },
                                          ]);
                                          setLoopVideoToggle(!loopVideoToggle);
                                        }
                                      }}
                                    >
                                      <div>
                                        <div className="offerLogoBlock">
                                          <video
                                            src={loopData.loopVideoText}
                                            preload="none"
                                            controls
                                          />
                                        </div>
                                        <div className="offerDetails">
                                          <div className="publishDateMain">
                                            <div className="publishDate">
                                              <Tooltip title={loopData.title}>
                                                <h3>{loopData.title}</h3>
                                              </Tooltip>
                                              <Tooltip title={loopData.author}>
                                                <h3>{loopData.author}</h3>
                                              </Tooltip>
                                            </div>

                                            <Tooltip
                                              title={
                                                loopData &&
                                                loopData.category.name
                                              }
                                            >
                                              <div className="publishType">
                                                {loopData &&
                                                  loopData.category.name}
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </>
                            ) : (
                              <div className="offerNoresultPopup">
                                {' '}
                                <NoResultsFound />
                              </div>
                            )}
                          </ul>
                        </div>
                      </div>
                    )}
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          <div className="offerCardBtn loopVideoBtn">
            <Button
              disabled={seletedVideoCards === savedVideoCards}
              onClick={() => {
                setOpen(true);
                setClosePopup('/card-management');
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={
                (seletedVideoCards && seletedVideoCards.length < 4) ||
                seletedVideoCards === savedVideoCards
              }
              onClick={() => {
                setOnSaveVideoCards(true);
              }}
            >
              Save
            </Button>
          </div>
          <div className="villageFilter">
            <ul
              className={`${classes.leftFilterIn} offerlocationFilterDropdown`}
            >
              <VillageFilter
                values={{
                  setBuildings,
                  selectedVillage,
                  setSelectedVillage,
                  buildingGroupsList,
                  setSelectedOffers,
                }}
                offerCards
              />
            </ul>
          </div>
          <Accordion
            className="offerCardAccrd"
            expanded={!expandToggle && selectedVillage}
            onChange={() => setExpandToggle(!expandToggle)}
            disabled={!selectedVillage}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <h5>Offers</h5>
            </AccordionSummary>
            <AccordionDetails>
              {isSelected || onSave ? (
                <CircularProgress color="inherit" className="offerLoader" />
              ) : (
                <div className="offerCardBlock">
                  <div
                    className={`${
                      toggle
                        ? 'selectedOfferBlock opened'
                        : 'selectedOfferBlock'
                    }`}
                  >
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable" direction="horizontal">
                        {provided => (
                          <ul
                            className="offerCardBoxAdded"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {selectedOffers &&
                              selectedOffers.map((item, index) => (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {provide => (
                                    <>
                                      <li
                                        ref={provide.innerRef}
                                        {...provide.draggableProps}
                                        {...provide.dragHandleProps}
                                        style={getItemStyle(
                                          provide &&
                                            provide.draggableProps &&
                                            provide.draggableProps.style,
                                        )}
                                      >
                                        <div className="allOffercard offerCardBox">
                                          <div className="removeOfferCard">
                                            <img
                                              src={removeCard}
                                              alt="offerLogo"
                                              role="presentation"
                                              onClick={() =>
                                                setSelectedOffers(
                                                  removeOffer(item.id),
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="offerLogoBlock">
                                            <img
                                              src={
                                                item &&
                                                item.offerImage &&
                                                item.offerImage[0].imageUrl
                                              }
                                              alt="offerLogo"
                                            />
                                          </div>
                                          <div className="offerDetails">
                                            <h3>{item.offerName}</h3>
                                            <h3>{item.companyName}</h3>
                                            <div className="publishDateMain">
                                              <div className="publishDate">
                                                <span>Expires: </span>{' '}
                                                {moment(item.endDate).format(
                                                  'DD.MM.YYYY',
                                                )}
                                              </div>
                                              <div className="publishType">
                                                {item.offerCategory}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </>
                                  )}
                                </Draggable>
                              ))}
                            {selectedOffers && selectedOffers.length < 5 && (
                              <li>
                                <div
                                  className="offerCardBox offerCardBoxadd"
                                  onClick={() => setToggle(!toggle)}
                                  role="presentation"
                                >
                                  &nbsp;
                                </div>
                                <div className="activeShape">
                                  {' '}
                                  <img
                                    src={activeArrow}
                                    alt="activeArrow"
                                  />{' '}
                                </div>
                              </li>
                            )}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                  {toggle && (
                    <div className="allOfferCardBlock">
                      <div className="allOfferFilter">
                        <h3>All Offers </h3>
                        <div className="offerfilterRgt">
                          <OfferManagementFilter />
                        </div>
                      </div>
                      {loading ? (
                        <div className="allOffercard">
                          <CircularProgress
                            color="inherit"
                            className="offerLoader"
                          />
                        </div>
                      ) : (
                        <div className="allOffercard">
                          <ul>
                            {offersList && offersList.length > 0 ? (
                              <>
                                {offersList &&
                                  offersList.map(offer => (
                                    <li
                                      key={offer.id}
                                      className={`${
                                        matched(offer.id) &&
                                        matched(offer.id).length > 0
                                          ? 'disable'
                                          : null
                                      }`}
                                      role="presentation"
                                      onClick={() => {
                                        if (
                                          !(
                                            matched(offer.id) &&
                                            matched(offer.id).length > 0
                                          )
                                        ) {
                                          setSelectedOffers([
                                            ...selectedOffers,
                                            offer,
                                          ]);
                                          setToggle(!toggle);
                                        }
                                      }}
                                    >
                                      <div className="offerLogoBlock">
                                        <img
                                          src={offer.offerImage[0].imageUrl}
                                          alt="offerLogo"
                                        />
                                      </div>
                                      <div className="offerDetails">
                                        <h3>{offer.offerName}</h3>
                                        <h3>{offer.companyName}</h3>
                                        <div className="publishDateMain">
                                          <div className="publishDate">
                                            <span>Expires: </span>{' '}
                                            {moment(offer.endDate).format(
                                              'DD.MM.YYYY',
                                            )}
                                          </div>
                                          <div className="publishType">
                                            {offer.offerCategory}
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  ))}
                              </>
                            ) : (
                              <div className="offerNoresultPopup">
                                {' '}
                                <NoResultsFound />
                              </div>
                            )}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="offerFixedBtn">
          <div className="offerCardBtn">
            <Button
              disabled={!selectedVillage}
              onClick={() => {
                setOpen(true);
                setClosePopup('/card-management');
                setclearSelectedCards('offerCards');
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={selectedOffers && selectedOffers.length < 2}
              onClick={() => {
                setOnSave(true);
              }}
            >
              Save
            </Button>
            <WarningPopup
              cardManagement
              handleClear={handleClear}
              clearSelectedCards={clearSelectedCards}
              setclearSelectedCards={setclearSelectedCards}
              setIsVideoSelected={setIsVideoSelected}
              isVideoSelected={isVideoSelected}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardManagement;
