import { makeStyles } from '@material-ui/core';

const useFilterStyles = makeStyles({
  leftFilterIn: {
    display: 'flex',
    alignItems: 'center',
    '& li': {
      marginRight: '15px',
      color: '#838384',
      fontSize: '14px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      position: 'relative',
      '&:last-child': {
        paddingBottom: '2px',
        borderBottom: '1px solid #979797',
        cursor: 'pointer',
        marginTop: '9px',
      },
      '& button': {
        color: '#444444',
        backgroundColor: '#f8f8f8',
        padding: '12px 12px 10px',
        fontWeight: 'bold',
        boxShadow: 'none',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        borderRadius: 5,
        '&:hover': {
          boxShadow: 'none',
          background: '#f8f8f8',
        },
        '& span': {
          lineHeight: '100%',
          alignItems: 'center',
          display: 'flex',
          textTransform: 'capitalize',

          '& small': {
            marginLeft: 20,
            top: '-2px',
            position: 'relative',
            '& img': {
              '&:last-child': {
                display: 'none',
              },
              '&:nth-child(2)': {
                display: 'none',
              },
            },
          },
        },
      },
    },
  },
  active: {
    '& button': {
      background: '#000 !important',
      color: '#fff !important',
      '& span': {
        '& small': {
          '& img': {
            '&:first-child': {
              display: 'none',
            },
            '&:last-child': {
              display: 'none',
            },
            '&:nth-child(2)': {
              display: 'block !important',
            },
          },
        },
        '& i': {
          display: 'none',
        },
      },
    },
  },
  closeIconBtn: {
    display: 'none',
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    textTransform: 'capitalize',
  },
  valueSelected: {
    '& button': {
      '& span': {
        '& small': {
          '& img': {
            '&:first-child': {
              display: 'none',
            },
            '&:last-child': {
              visibility: 'hidden',
              opacity: '0',
            },
          },
        },
      },
    },
    '& i': {
      display: 'block !important',
      cursor: 'pointer',
    },
  },
  filterMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightFilter: {
    display: 'flex',
    alignItems: 'center',
  },
  exportSerch: {
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      '& li': {
        marginLeft: '20px',
      },
    },
  },
  pageListMain: {
    position: 'relative',
  },
  popupBadge: {
    background: '#000000',
    borderRadius: '11px',
    fontSize: '16px',
    color: '#fff',
    width: '50%',
    margin: '0 auto',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    padding: ' 14px 0',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      borderBottom: ' 1px solid rgba(151, 151, 151,0.2)',
      '&:last-child': {
        borderBottom: 'none',
      },
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  scarchClose: {
    '& img': {
      position: 'absolute',
      right: '5px',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'block !important',
      cursor: 'pointer',
      zIndex: 2,
    },
  },
});
export default useFilterStyles;
