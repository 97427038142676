/* eslint-disable no-unused-expressions */
import { Button, Slider } from '@material-ui/core';
import { React, useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import './cropImg.css';
import './ImageCroper.css';
import plusIcon from '../../images/plus.svg';
import minusIcon from '../../images/buttons-minus.svg';
import {
  image_title_1,
  image_title_2,
  image_title_3,
  image_title_4,
  image_title_5,
} from './constants';
import { isScalableImage } from '../../utils/constants';

const ImageCroper = ({ values }) => {
  const {
    imgIndex,
    setImgIndex,
    cropImg,
    setCropImg,
    cropSize,
    spaceFile,
    setSpaceFile, 
    tag,
    setTag,
    setCropSize,
    toggle,
    setToggle,
    previewImage,
    setPreviewImage,
    floorPlan,
    setFloorPlan,
    buildingLogo,
    setBuildingLogo,
    images,
    setImages,
    imgUrl,
    setImgUrl,
    newsOrArticleImage,
    setNewsOrArticleImage,
  } = values;
  
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.5);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixel) => {
    setCroppedAreaPixels(croppedAreaPixel);
  }, []);

  const imageFormatedUrl = originalImage =>
    `${originalImage.split('image/upload')[0]}c_crop,h_${
      croppedAreaPixels.height
    },w_${croppedAreaPixels.width},x_${croppedAreaPixels.x},y_${
      croppedAreaPixels.y
    }${
      (isScalableImage &&
        `/c_scale,h_${cropSize.height},w_${cropSize.width}`) ||
      ''
    }${originalImage.split('image/upload')[1]}`;

  const croppedImg = (index, ImgTag) =>
    (index.mediaType === 'spaceImage' &&
      spaceFile &&
      spaceFile.map(file =>
        file.order === index.order
          ? {
              image: [
                { imageUrl: file.image[0].imageUrl, tag: image_title_1 },
                {
                  imageUrl:
                    file.image[1].imageUrl.includes('c_crop') &&
                    ImgTag !== image_title_2
                      ? file.image[1].imageUrl
                      : (ImgTag === image_title_2 &&
                          imageFormatedUrl(file.image[0].imageUrl)) ||
                        file.image[1].imageUrl,
                  tag: image_title_2,
                },
                {
                  imageUrl:
                    file.image[2].imageUrl.includes('c_crop') &&
                    ImgTag !== image_title_5
                      ? file.image[2].imageUrl
                      : (ImgTag === image_title_5 &&
                          imageFormatedUrl(file.image[0].imageUrl)) ||
                        file.image[2].imageUrl,
                  tag: image_title_5,
                },
              ],
              order: file.order,
            }
          : file,
      )) ||
    (index.mediaType === 'previewImage' && [
      { imageUrl: previewImage[0].imageUrl, tag: image_title_1 },
      {
        imageUrl:
          previewImage[1].imageUrl.includes('c_crop') &&
          ImgTag !== image_title_2
            ? previewImage[1].imageUrl
            : (ImgTag === image_title_2 &&
                imageFormatedUrl(previewImage[0].imageUrl)) ||
              previewImage[1].imageUrl,
        tag: image_title_2,
      },
    ]) ||
    (index.mediaType === 'FloorImage' && [
      { imageUrl: floorPlan[0].imageUrl, tag: image_title_1 },
      {
        imageUrl:
          floorPlan[1].imageUrl.includes('c_crop') && ImgTag !== image_title_2
            ? floorPlan[1].imageUrl
            : (ImgTag === image_title_2 &&
                imageFormatedUrl(floorPlan[0].imageUrl)) ||
              floorPlan[1].imageUrl,
        tag: image_title_2,
      },
    ]) ||
    (index.mediaType === 'BuildingImage' && [
      { imageUrl: buildingLogo[0].imageUrl, tag: image_title_1 },
      {
        imageUrl:
        buildingLogo[1].imageUrl.includes('c_crop') && ImgTag !== image_title_2
            ? buildingLogo[1].imageUrl
            : (ImgTag === image_title_2 &&
                imageFormatedUrl(buildingLogo[0].imageUrl)) ||
                buildingLogo[1].imageUrl,
        tag: image_title_2,
      },
    ]) ||
    (index.mediaType === 'images' && [
      { imageUrl: images[0].imageUrl, tag: image_title_1 },
      {
        imageUrl:
          images[1].imageUrl.includes('c_crop') && ImgTag !== image_title_3
            ? images[1].imageUrl
            : (ImgTag === image_title_3 &&
                imageFormatedUrl(images[0].imageUrl)) ||
              images[1].imageUrl,
        tag: image_title_3,
      },
      {
        imageUrl:
          images[2].imageUrl.includes('c_crop') && ImgTag !== image_title_4
            ? images[2].imageUrl
            : (ImgTag === image_title_4 &&
                imageFormatedUrl(images[0].imageUrl)) ||
              images[2].imageUrl,
        tag: image_title_4,
      },
      {
        imageUrl:
          images[3].imageUrl.includes('c_crop') && ImgTag !== image_title_5
            ? images[3].imageUrl
            : (ImgTag === image_title_5 &&
                imageFormatedUrl(images[0].imageUrl)) ||
              images[3].imageUrl,
        tag: image_title_5,
      },
    ]) ||
    (index.mediaType === 'cateringMenu' && [
      { imageUrl: images[0].imageUrl, tag: image_title_1 },
      {
        imageUrl : 
        (ImgTag === image_title_2 &&
                imageFormatedUrl(images[0].imageUrl)) ||
              images[1].imageUrl,
        tag: image_title_2,
      },
    ]) ||
    (index.mediaType === 'newsOrArticleImage' && [
      { imageUrl: newsOrArticleImage[0].imageUrl, tag: image_title_1 },
      {
        imageUrl:
          newsOrArticleImage[1].imageUrl.includes('c_crop') &&
          ImgTag !== image_title_3
            ? newsOrArticleImage[1].imageUrl
            : (ImgTag === image_title_3 &&
                imageFormatedUrl(newsOrArticleImage[0].imageUrl)) ||
              newsOrArticleImage[1].imageUrl,
        tag: image_title_3,
      },
      {
        imageUrl:
          newsOrArticleImage[2].imageUrl.includes('c_crop') &&
          ImgTag !== image_title_4
            ? newsOrArticleImage[2].imageUrl
            : (ImgTag === image_title_4 &&
                imageFormatedUrl(newsOrArticleImage[0].imageUrl)) ||
              newsOrArticleImage[2].imageUrl,
        tag: image_title_4,
      },
      {
        imageUrl:
          newsOrArticleImage[3].imageUrl.includes('c_crop') &&
          ImgTag !== image_title_5
            ? newsOrArticleImage[3].imageUrl
            : (ImgTag === image_title_5 &&
                imageFormatedUrl(newsOrArticleImage[0].imageUrl)) ||
              newsOrArticleImage[3].imageUrl,
        tag: image_title_5,
      },
    ]) ||
    (index.mediaType === 'videoPreviewImage' && [
      { imageUrl: previewImage[0].imageUrl, tag: image_title_1 },
      {
        imageUrl:
          previewImage[1].imageUrl.includes('c_crop') &&
          ImgTag !== image_title_3
            ? previewImage[1].imageUrl
            : (ImgTag === image_title_3 &&
                imageFormatedUrl(previewImage[0].imageUrl)) ||
              previewImage[1].imageUrl,
        tag: image_title_3,
      },
      {
        imageUrl:
          previewImage[2].imageUrl.includes('c_crop') &&
          ImgTag !== image_title_4
            ? previewImage[2].imageUrl
            : (ImgTag === image_title_4 &&
                imageFormatedUrl(previewImage[0].imageUrl)) ||
              previewImage[2].imageUrl,
        tag: image_title_4,
      },
      {
        imageUrl:
          previewImage[3].imageUrl.includes('c_crop') &&
          ImgTag !== image_title_5
            ? previewImage[3].imageUrl
            : (ImgTag === image_title_5 &&
                imageFormatedUrl(previewImage[0].imageUrl)) ||
              previewImage[3].imageUrl,
        tag: image_title_5,
      },
    ]) ||
    (index.mediaType === 'meetingRoom' &&
      imgUrl &&
      imgUrl.map(file =>
        file.order === index.order
          ? {
              image: [
                { imageUrl: file.image[0].imageUrl, tag: image_title_1 },
                {
                  imageUrl:
                    file.image[1].imageUrl.includes('c_crop') &&
                    ImgTag !== image_title_3
                      ? file.image[1].imageUrl
                      : (ImgTag === image_title_3 &&
                          imageFormatedUrl(file.image[0].imageUrl)) ||
                        file.image[1].imageUrl,
                  tag: image_title_3,
                },
                {
                  imageUrl:
                    file.image[2].imageUrl.includes('c_crop') &&
                    ImgTag !== image_title_4
                      ? file.image[2].imageUrl
                      : (ImgTag === image_title_4 &&
                          imageFormatedUrl(file.image[0].imageUrl)) ||
                        file.image[2].imageUrl,
                  tag: image_title_4,
                },
                {
                  imageUrl:
                    file.image[3].imageUrl.includes('c_crop') &&
                    ImgTag !== image_title_5
                      ? file.image[3].imageUrl
                      : (ImgTag === image_title_5 &&
                          imageFormatedUrl(file.image[0].imageUrl)) ||
                        file.image[3].imageUrl,
                  tag: image_title_5,
                },
              ],
              order: file.order,
            }
          : file,
      ));

  const updatedView = (imagesList, order, mediaType) => {
    imagesList && imagesList[0].image
      ? imagesList &&
        imagesList.map(
          item =>
            item.order === order &&
            setImgIndex({
              order: item.order,
              images: item.image,
              imageUrl: item.image[0].imageUrl,
              mediaType,
            }),
        )
      : setImgIndex({
          order,
          images: imagesList,
          imageUrl: imagesList && imagesList[0].imageUrl,
          mediaType,
        });
    setToggle(!toggle);
  };

  const updateImgView = () =>
    updatedView(croppedImg(imgIndex, tag), imgIndex.order, imgIndex.mediaType);

  return (
    <div
      className={`${
        imgIndex.index > 4
          ? 'cropPopupContainer cropPopupContainerEdit cropPopupContainerScndRow'
          : 'cropPopupContainer cropPopupContainerEdit'
      }`}
    >
      <div className="cropPopupCont cropPopupEditCont">
        <h3>{`Edit ${tag} Image`}</h3>
        <div className="CropImgContainer">
          <div className="cropImgHW">{`${cropSize.width}x${cropSize.height}`}</div>
          <Cropper
            image={imgIndex.imageUrl}
            crop={crop}
            showGrid={false}
            zoom={zoom}
            cropSize={cropSize}
            zoomWithScroll={false}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
          />
        </div>
        <div className="cropFooter">
          <div className="cropFooterSlide">
            <Button
              className={`${(zoom === 0.1 && 'btnDisable') || ''}`}
              onClick={() => {
                setZoom((zoom > 0.2 && zoom - 0.02) || 0.1);
              }}
            >
              <img src={minusIcon} alt="minusIcon" role="presentation" />
            </Button>
            <Slider
              value={zoom}
              max={3}
              step={0.02}
              aria-labelledby="Zoom"
              onChange={(e, onZoom) => setZoom((onZoom > 0.2 && onZoom) || 0.1)}
            />
            <Button
              className={`${(zoom === 3 && 'btnDisable') || ''}`}
              onClick={() => {
                setZoom((zoom < 3 && zoom + 0.02) || 3);
              }}
            >
              <img src={plusIcon} alt="plusIcon" role="presentation" />
            </Button>
          </div>
          <div className="cropFooterBtn">
            <Button
              onClick={() => {
                setCropImg(!cropImg);
                setToggle(!toggle);
              }}
              variant="contained"
              color="primary"
            >
              Discard
            </Button>
            <Button
              onClick={() => {
                if (imgIndex.mediaType === 'spaceImage') {
                  setSpaceFile(croppedImg(imgIndex, tag));
                  updateImgView();
                } else if (imgIndex.mediaType === 'previewImage') {
                  setPreviewImage(croppedImg(imgIndex, tag));
                  updateImgView();
                } else if (imgIndex.mediaType === 'FloorImage') {
                  setFloorPlan(croppedImg(imgIndex, tag));
                  updateImgView();
                } else if (imgIndex.mediaType === 'BuildingImage') {
                  setBuildingLogo(croppedImg(imgIndex, tag));
                  updateImgView();
                }else if (imgIndex.mediaType === 'images') {
                  setImages(croppedImg(imgIndex, tag));
                  updateImgView();
                } else if (imgIndex.mediaType === 'cateringMenu') {
                  setImages(croppedImg(imgIndex, tag));
                  updateImgView();
                }
                else if (imgIndex.mediaType === 'newsOrArticleImage') {
                  setNewsOrArticleImage(croppedImg(imgIndex, tag));
                  updateImgView();
                } else if (imgIndex.mediaType === 'videoPreviewImage') {
                  setPreviewImage(croppedImg(imgIndex, tag));
                  updateImgView();
                } else {
                  setImgUrl(croppedImg(imgIndex, tag));
                  updateImgView();
                }
                setCropImg(!cropImg);
                setTag('');
                setCropSize({});
              }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCroper;