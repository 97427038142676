/* eslint-disable import/no-cycle */
import React, { createContext, useState, useEffect } from 'react';
import moment from 'moment';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';
import NewsAnnouncementsList from '../containers/NewsAnnouncements/NewsAnnouncementsList';

export const NewsAnnouncementsContext = createContext({});

export const NewsAnnouncementsContextProvider = () => {
  const [newsAnnouncementList, setNewsAnnouncementList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [totalArticlesCount, setTotalArticlesCount] = useState(0);
  const [articleSearch, setArticleSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState('startDate');
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [buildings, setBuildings] = useState([]);
  const [villages, setVillages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryActive, setCatagoryActive] = useState(false);
  const [platformOptions, setPlatformOptions] = useState([
    { label: 'Customer App', key: 'CustomerApp', selected: false },
    { label: 'Tenant Portal', key: 'TenantPortal', selected: false },
    { label: 'Both', key: 'All', selected: false },
  ]);
  const [articleOptions, setArticleOptions] = useState([
    { label: 'Announcements', key: 'Announcement', selected: false },
    { label: 'Standard News', key: 'standard', selected: false },
    { label: 'Video News', key: 'video', selected: false },
    { label: 'Feature News', key: 'featured', selected: false },
  ]);
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [statuses, setStatuses] = useState([
    { label: 'Published', key: 'Published', selected: false },
    { label: 'Unpublished', key: 'Unpublished', selected: false },
    { label: 'Expired', key: 'Expired', selected: false },
  ]);

  const startDateApi = `${moment(selectedStartDate)
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)}`;
  const endDateApi = `${moment(selectedEndDate)
    .set('hours', 23)
    .set('minutes', 59)
    .set('seconds', 0)}`;

  const articleSearchResult =
    articleSearch.length > 2 ? articleSearch : undefined;

  const getNewsAnnouncementListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getNewsAnnouncementsList, payload)
      .then(data => {
        setNewsAnnouncementList(data && data.data && data.data.data);
        setTotalArticlesCount(data && data.data.count);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  const getArticlesCategoryAction = () => {
    if (categories.length === 0) {
      ApiClient.apiGet(apiEndPoints.getArticlesCategory)
        .then(data => {
          setCategories(data && data.data && data.data.data);
        })
        .catch(error => {
          console.log(error, 'error');
        });
    }
  };

  useEffect(() => {
    if (categoryActive === true) {
      getArticlesCategoryAction();
    }
  }, [categoryActive]);

  useEffect(() => {
    const platforms = [];
    platformOptions.filter(platform => {
      if (platform.selected) {
        platforms.push(platform.key);
      }
      return platform.selected !== false;
    });
    setSelectedPlatform(platforms);

    const articles = [];
    articleOptions.filter(article => {
      if (article.selected) {
        articles.push(article.key);
      }
      return article.selected !== false;
    });
    setSelectedArticle(articles);

    const statusUpdate = [];
    statuses.filter(status => {
      if (status.selected) {
        statusUpdate.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedStatus(statusUpdate);
    getNewsAnnouncementListAction({
      searchText: articleSearchResult ? articleSearch : undefined,
      sortOrder,
      buildings: buildings && buildings.length > 0 ? buildings : undefined,
      platform: platforms && platforms.length > 0 ? platforms : undefined,
      type: articles && articles.length > 0 ? articles : undefined,
      startDate:
        selectedStartDate && selectedEndDate
          ? moment.tz(startDateApi, 'Europe/London').toISOString()
          : undefined,
      endDate:
        selectedStartDate && selectedEndDate
          ? moment.tz(endDateApi, 'Europe/London').toISOString()
          : undefined,
      category:
        selectedCategory && selectedCategory.length > 0
          ? selectedCategory
          : undefined,
      sortBy,
      status:
        statusUpdate && statusUpdate.length > 0 ? statusUpdate : ['Published'],
      offset,
      limit: 40,
    });
  }, [
    sortOrder,
    sortBy,
    offset,
    buildings,
    articleSearchResult,
    selectedStartDate && selectedEndDate,
    platformOptions,
    articleOptions,
    statuses,
    selectedCategory,
  ]);

  return (
    <NewsAnnouncementsContext.Provider
      value={{
        newsAnnouncementList,
        sortOrder,
        sortBy,
        totalArticlesCount,
        page,
        articleSearch,
        platformOptions,
        selectedPlatform,
        articleOptions,
        selectedArticle,
        setArticleOptions,
        categories,
        categoryActive,
        selectedCategory,
        villages,
        buildings,
        loading,
        setLoading,
        setVillages,
        setBuildings,
        setSelectedCategory,
        setCatagoryActive,
        setCategories,
        setSelectedPlatform,
        setPlatformOptions,
        setArticleSearch,
        setPage,
        setSortBy,
        setTotalArticlesCount,
        setOffset,
        setNewsAnnouncementList,
        setSortOrder,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        statuses,
        setStatuses,
        selectedStatus,
        setSelectedStatus,
      }}
    >
      <NewsAnnouncementsList />
    </NewsAnnouncementsContext.Provider>
  );
};
