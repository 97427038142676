import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import FileUpload from '../../components/ImageUpload/FileUpload';
import dowmArw from '../../images/down-arw.svg';
import apiEndPoints from '../../helpers/apiEndPoints';
import ApiClient from '../../helpers/ApiClient';
import { cateringMenuSchema } from '../../utils/formValidate';
import { GlobalContext } from '../../context/GlobalContext';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';
import AdditionalInformation from './AdditionalInformation';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 97% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
      },
      '& label': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        transform: 'none',
        textTransform: 'capitalize',
        position: 'static',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        minHeight: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& > div': {
          '& > div': {
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
}));

const CreateAndUpdateCateringMenu = () => {
  const { setSuccessMessage } = useContext(GlobalContext);
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const [supplierName, setSupplierName] = useState('Lantana');
  const [itemName, setItemName] = useState('');
  const [description, setDescription] = useState('');
  const [pricePerQuantity, setPricePerQuantity] = useState('');
  const [diet, setDiet] = useState([]);
  const [allergen, setAllergen] = useState([]);
  const [nutritional, setNutritional] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [categoryStatus, setCategoryStatus] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [status, setStatus] = useState('Available');
  const [cateringMenuToggle, setCateringMenuToggle] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState('');
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  if (id) {
    if (
      !JSON.parse(localStorage.getItem('rolePermission'))[0].editCateringItem
    ) {
      history.push('/cateringmenulist');
    }
  } else if (
    !JSON.parse(localStorage.getItem('rolePermission'))[0].createCateringItem
  ) {
    history.push('/cateringmenulist');
  }

  const [dropDownFields, setDropDownFields] = useState({});

  useEffect(() => {
    fetch(
      'https://stfunprofileserviceapi.blob.core.windows.net/staticpages/dietary_info.json',
    ).
    then(response => response.json())
    .then(data => {
      setDropDownFields(data);
      const nutritionalData = [];
      // eslint-disable-next-line no-unused-expressions
      data.NutrionalInformation.length > 0 &&
        data.NutrionalInformation.map(field =>
          nutritionalData.push({
            name: field.key,
            quantity: '',
            color: field.color,
          }),
        );
      if (!id) {
        setNutritional([...nutritionalData]);
      }
    });
  }, []);

  const dietSelected = value => {
    setDiet(value);
  };
  const allergenSelected = value => {
    setAllergen(value);
  };

  const onChangeHandler = event => {
    // const value = event.target.value;
    const newArr = nutritional.map(field => {
      if (field.name === event.target.name) {
        return { ...field, quantity: event.target.value };
      }
      return field;
    });
    setNutritional(newArr);
  };


  useEffect(() => {
    const payload = {
      status: ['Available', 'Decommissioned'],
      supplier: supplierName,
      sortOrder: 'asc',
      offset: 0,
      limit: 100,
    };
    ApiClient.apiPost(apiEndPoints.cateringCategories, payload)
      .then(data => {
        setCategoryList(data && data.data && data.data.data);
      })
      .catch(error => {
        setCateringMenuToggle(false);
        setLoading(false);
        alert(error);
      });
  }, [supplierName]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      ApiClient.apiGet(`${apiEndPoints.createCateringMenu}/${id}`)
        .then(data => {
          const categoryMenuDetails = data && data.data && data.data.data;
          setSupplierName(categoryMenuDetails.category.supplierName);
          setCategoryName(categoryMenuDetails.category.categoryName);
          setSelectedCategory(categoryMenuDetails.category.id);
          setCategoryStatus(categoryMenuDetails.category.status);
          setItemName(categoryMenuDetails.itemName);
          setDescription(categoryMenuDetails.itemDescription);
          setPricePerQuantity(categoryMenuDetails.pricePerQuantity);
          if (
            categoryMenuDetails &&
            categoryMenuDetails.additionalInformation
          ) {
            setDiet(
              categoryMenuDetails.additionalInformation.dietaryInformation,
            );
            setAllergen(
              categoryMenuDetails.additionalInformation.allergenInformation,
            );
            setNutritional(
              categoryMenuDetails.additionalInformation.nutritionalInformation,
            );
          }
          else{
            fetch(
              'https://stfunprofileserviceapi.blob.core.windows.net/staticpages/dietary_info.json',
            ).
            then(response => response.json())
            .then(d => {
              const nutritionalData = [];
              // eslint-disable-next-line no-unused-expressions
              d.NutrionalInformation.length > 0 &&
                d.NutrionalInformation.map(field =>
                  nutritionalData.push({
                    name: field.key,
                    quantity: '',
                    color: field.color,
                  }),
                );
              setNutritional([...nutritionalData]);
            });
            
          }
          setImages(
            (categoryMenuDetails.image && [
              { imageUrl: categoryMenuDetails.originalImage, tag: 'Full Size' },
              { imageUrl: categoryMenuDetails.image, tag: 'Card' },
            ]) ||
              [],
          );
          setStatus(categoryMenuDetails.status);
          setLoading(false);
        })
        .catch(error => {
          setCateringMenuToggle(false);
          setLoading(false);
          alert(error);
        });
    }
  }, [id]);

  useEffect(() => {
    cateringMenuSchema
      .validate({
        categoryName,
        itemName,
        description,
        pricePerQuantity,
        nutritional,
      })
      .then(() => {
        if (cateringMenuToggle) {
          setLoading(true);
          const payload = {
            id: id || undefined,
            category: {
              id: selectedCategory,
              categoryName,
              supplierName,
              status: categoryStatus,
            },
            itemName,
            image: images.length > 0 ? images[1].imageUrl : undefined,
            originalImage: images.length > 0 ? images[0].imageUrl : undefined,
            itemDescription: description,
            pricePerQuantity: Number(pricePerQuantity),
            status,
            additionalInformation: {
              dietaryInformation: diet,
              allergenInformation: allergen,
              nutritionalInformation: nutritional,
            },
          };
          if (id) {
            ApiClient.apiPut(
              `${apiEndPoints.createCateringMenu}/${id}`,
              payload,
            )
              .then(() => {
                setLoading(false);
                setSuccessMessage('Menu item has been updated successfully!');
                history.push('/catering-menu-list');
              })
              .catch(error => {
                setCateringMenuToggle(false);
                setLoading(false);
                alert(error);
              });
          } else {
            ApiClient.apiPost(apiEndPoints.createCateringMenu, payload)
              .then(() => {
                setLoading(false);
                setSuccessMessage('Menu item has been added successfully!');
                history.push('/catering-menu-list');
              })
              .catch(error => {
                setCateringMenuToggle(false);
                setLoading(false);
                alert(error);
              });
          }
        }
      })
      .catch(error => {
        console.log(error, 'error');
        if (cateringMenuToggle) {
          setErrorMsgs(error);
          setCateringMenuToggle(false);
        }
      });
  }, [
    categoryName,
    itemName,
    status,
    description,
    pricePerQuantity,
    cateringMenuToggle,
    images,
  ]);

  const handleClick = categoryId => {
    const categoryDetails =
      categoryList &&
      categoryList.filter(category => category.id === categoryId);
    setCategoryStatus(categoryDetails && categoryDetails[0].status);
    setCategoryName(categoryDetails && categoryDetails[0].categoryName);
  };

  const onChange =
    categoryList &&
    categoryList.filter(category => category.id === selectedCategory);

  const errorType = errorMsgs && errorMsgs.type;
  const errorFieldName = errorMsgs && errorMsgs.path;
  const errorFieldValue = errorMsgs && errorMsgs.value;

  const breadCrumbs = !id
    ? {
        listBreadCrumbPath: '/catering-menu-list',
        listBreadCrumbTitle: 'Catering menu',
        addBreadCrumbPath: '/create-cateringmenu',
        addBreadCrumbTitle: 'Create menu item',
      }
    : {
        listBreadCrumbPath: '/catering-menu-list',
        listBreadCrumbTitle: 'Catering menu',
        viewBreadCrumbPath: '/view-cateringmenu/',
        viewBreadCrumbTitle: 'View menu item',
        editBreadCrumbPath: '/edit-cateringmenu/',
        editBreadCrumbTitle: 'Edit menu item',
      };

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addUserContainer">
            <div className={`${classes.addUserForm} createcategoryForm`}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Menu Item Details</FormLabel>
                </li>
              </ul>
              <ul>
                <li className="radioSectionLabel">
                  <span className="suplHeading">Supplier*</span>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Lantana" name="Lantana">
                      <div className={`${classes.radioSection} radioSection`}>
                        <FormControlLabel
                          control={<Radio />}
                          label="Lantana"
                          checked={supplierName === 'Lantana'}
                          onChange={() => {
                            setSupplierName('Lantana');
                            if (onChange.length > 0) {
                              setSelectedCategory('');
                              setCategoryName('');
                            }
                          }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Derwent London"
                          checked={supplierName === 'Derwent London'}
                          onChange={() => {
                            setSupplierName('Derwent London');
                            if (onChange.length > 0) {
                              setSelectedCategory('');
                              setCategoryName('');
                            }
                          }}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <ul className={`${classes.InputSection} creaeCategoryField`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Category*
                    </InputLabel>
                    <Select
                      className={classes.formSelectBox}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      value={selectedCategory}
                      onChange={e => {
                        setSelectedCategory(e.target.value);
                        handleClick(e.target.value);
                      }}
                    >
                      {categoryList &&
                        categoryList.map(category => {
                          return (
                            <MenuItem key={category.id} value={category.id}>
                              {`${category.categoryName} - ${category.status}`}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'categoryName') ||
                    (errorFieldValue &&
                      errorFieldValue.categoryName === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={categoryName === '' && 'Category is required.'}
                    >
                      {categoryName === '' && 'Category is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <span>Item name*</span>
                  <Input
                    className="textareaSmallBox"
                    type="text"
                    inputProps={{ 'aria-label': 'description', maxLength: 100 }}
                    value={itemName}
                    onChange={e => {
                      setItemName(e.target.value);
                    }}
                  />
                  {((errorType === 'min' && errorFieldName === 'itemName') ||
                    (errorFieldValue && errorFieldValue.itemName === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        itemName === ''
                          ? itemName === '' && 'Item Name is required.'
                          : errorType === 'min' &&
                            'Must be at least 3 characters'
                      }
                    >
                      {itemName === ''
                        ? itemName === '' && 'Item Name is required.'
                        : errorType === 'min' &&
                          'Must be at least 3 characters'}
                    </FormHelperText>
                  )}
                  <div className="descriptionBox">{`${itemName.length}/100`}</div>
                </li>
              </ul>
              <ul className={`${classes.InputSection} textareaSection`}>
                <li>
                  <span>Description*</span>
                  <textarea
                    type="text"
                    maxLength="900"
                    inputProps={{ 'aria-label': 'description' }}
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value);
                    }}
                  />
                  {((errorType === 'min' && errorFieldName === 'description') ||
                    (errorFieldValue &&
                      errorFieldValue.description === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        description === ''
                          ? description === '' && 'Description is required.'
                          : errorType === 'min' &&
                            'Must be at least 3 characters'
                      }
                    >
                      {description === ''
                        ? description === '' && 'Description is required.'
                        : errorType === 'min' &&
                          'Must be at least 3 characters'}
                    </FormHelperText>
                  )}
                  <div className="descriptionBox">{`${description.length}/900`}</div>
                </li>
              </ul>
              <ul className={`${classes.InputSection}`}>
                <li className={`${classes.InputSection}`}>
                  <span>Price per quantity*</span>
                  <Input
                    type="text"
                    value={pricePerQuantity}
                    onChange={e => {
                      const re = /^[0-9]*\.?[0-9]*$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setPricePerQuantity(e.target.value);
                      }
                    }}
                  />
                  {((errorType === 'matches' &&
                    errorFieldName === 'pricePerQuantity') ||
                    (errorFieldValue &&
                      errorFieldValue.pricePerQuantity === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        pricePerQuantity === ''
                          ? pricePerQuantity === '' &&
                            'Price Per Quantity is required.'
                          : errorType === 'matches' && 'Must be only numbers'
                      }
                    >
                      {pricePerQuantity === ''
                        ? pricePerQuantity === '' &&
                          'Price Per Quantity is required.'
                        : errorType === 'matches' && 'Must be only numbers'}
                    </FormHelperText>
                  )}
                  <div className="vatText">(excluding VAT)</div>
                </li>
              </ul>
              {supplierName === 'Lantana' && (
                <>
                  <ul className="dietrySelectBox">
                    <li>
                      <span>Dietary Information</span>
                      <AdditionalInformation
                        dropDownFields={
                          dropDownFields && dropDownFields.DietaryInformation
                        }
                        setSelected={dietSelected}
                        categories={diet}
                        name="Dietary"
                      />
                    </li>
                    <li>
                      <span>Allergen Information</span>
                      <AdditionalInformation
                        dropDownFields={
                          dropDownFields && dropDownFields.AllergenInformation
                        }
                        setSelected={allergenSelected}
                        categories={allergen}
                        name="Allergen"
                      />
                    </li>
                  </ul>
                  <Divider className="formDivider" light />
                </>
              )}

              {supplierName === 'Lantana' && (
                <ul className="offerFormList">
                  <li>
                    <FormLabel component="legend">
                      Nutritional Information*
                    </FormLabel>
                  </li>
                </ul>
              )}
              {supplierName === 'Lantana' && (
                <>
                  <ul
                    className={`${classes.InputSection} nutritionalInfoSection`}
                  >
                    {nutritional.length > 0 &&
                      nutritional.map(value => {
                        return (
                          <li className={`${classes.InputSection}`}>
                            <span style={{ color: value.color }}>
                              {value.name}*
                            </span>
                            <Input
                              type="text"
                              name={value.name}
                              inputProps={{ maxLength: 15 }}
                              onChange={onChangeHandler}
                              value={value.quantity}
                            />
                            {errorType === 'required' &&
                              errorFieldValue &&
                              errorFieldValue.nutritional &&
                              value.quantity === '' && (
                                <FormHelperText className={classes.errorMsg}>
                                  {`${value.name} is required`}
                                </FormHelperText>
                              )}
                          </li>
                        );
                      })}
                  </ul>
                  <Divider className="formDivider" light />
                </>
              )}
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Media</FormLabel>
                </li>
              </ul>

              <ul className="offerImgUoload">
                <li>
                  <FileUpload
                    values={{
                      images,
                      setImages,
                    }}
                    cateringMenu
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li className="statusMarBtm">
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        <FormControlLabel
                          control={<Radio />}
                          label="Available"
                          checked={status === 'Available'}
                          onChange={() => {
                            setStatus('Available');
                          }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Decommissioned"
                          checked={status === 'Decommissioned'}
                          onChange={() => {
                            setStatus('Decommissioned');
                            // popUpOpen();
                          }}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <SaveAndCancelButtons
                setToggle={setCateringMenuToggle}
                route="/catering-menu-list"
              />
              <WarningPopup />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateAndUpdateCateringMenu;
