/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { momentLocalizer, Calendar, Views } from 'react-big-calendar';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Divider, makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import BigCalenderFilter from './BigCalenderFilter';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './bookings.css';
import apiEndPoints from '../../helpers/apiEndPoints';
import ApiClient from '../../helpers/ApiClient';
import { GlobalContext } from '../../context/GlobalContext';
import buttonLeft from '../../images/buttons-left-arw.svg';
import dotImage from '../../images/dotImage.svg';
import buttonRight from '../../images/buttons-right-arw.svg';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import { maxMeetingRoomsInCalender } from '../../utils/constants';
import {
  getMoment,
  getTimeAsDate,
  getNow,
  removeDuplicates,
  isdaylightsaving,
} from '../../utils/dateUtils';
import { logOut } from '../../utils/logOut';
import { authContext } from '../../adal';
import SnackBar from '../SnackBar/SnackBar';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  editIcon: {
    margin: '0 auto',
    cursor: 'pointer',
  },
  accountBoxImg: {
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& li': {
        width: '25%',
        '& img': {
          margin: '0 auto',
        },
      },
    },
  },
  tableBody: {
    verticalAlign: 'top',
    '& th': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
    },
    '& td': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
    },
  },
  popupBadge: {
    background: '#000000',
    borderRadius: '11px',
    fontSize: '16px',
    color: '#fff',
    width: '50%',
    margin: '0 auto',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    padding: ' 14px 0',
    fontFamily: ['Varta', 'sans-serif'].join(','),
    zIndex: '999',
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  buildVillg: {
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  addIcon: {
    position: 'fixed',
    zIndex: '999',
    right: '55px',
    bottom: '55px',
  },
  fab: {
    background: '#000',
    color: '#fff',
    '&:hover': {
      background: '#000',
      color: '#fff',
    },
  },
  pageListMain: {
    position: 'relative',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '10px',
    padding: '5px 0',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#838384',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '29px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
});

const bstmMoment = getMoment('Europe/London');
const localizer = momentLocalizer(bstmMoment);
const formats = {
  timeGutterFormat: 'HH:mm',
};
const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#fff',
    width: '100px !important',
    color: '#444444',
    height: 'auto',
    fontSize: 15,
    border: 'solid 0.2px rgba(151,151,151,0.3)',
    padding: '20px',
    borderRadius: 10,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
  },
  arrow: {
    color: '#fff',
    fontSize: 22,
    '&:before': {
      border: '1px solid rgba(151,151,151,0.3)',
    },
  },
}))(Tooltip);
const eventStyleGetter = event => {
  if (event.type === 'buffer') {
    const style = {
      backgroundColor: '#d3e7f1',
      borderRadius: '0',
      opacity: 1,
      color: 'black',
      border: '0px',
      display: 'block',
      zIndex: 0,
    };
    return {
      style,
    };
  }
  if (event && event.isCombinedMeetingRoom) {
    const style = {
      backgroundColor: '#f8f8f8',
      borderRadius: '0px',
      opacity: 1,
      color: '#737373',
      border: '0px',
      display: 'block',
    };
    return {
      style,
    };
  }
  if (event.status === 'Pending') {
    const style = {
      backgroundColor: '#939292',
      borderRadius: '0px',
      opacity: 1,
      color: '#fff',
      border: '0px',
      display: 'block',
    };
    return {
      style,
    };
  }
  if (event.status === 'Blocked') {
    const style = {
      backgroundColor: 'rgb(0,127,142)',
      borderRadius: '0px',
      opacity: 1,
      color: '#fff',
      border: '0px',
      display: 'block',
      width: '100px !important',
    };
    return {
      style,
    };
  }
  const style = {
    backgroundColor: '#000',
    borderRadius: '0px',
    opacity: 1,
    color: 'white',
    border: '0px',
    display: 'block',
  };
  return {
    style,
  };
};

function Event({ event }) {
  if (event.type === 'buffer') {
    return <div />;
  }

  const startTimeInLtFormat = moment(event.start);
  const endTImeInLtFormat = moment(event.end);
  const duration = moment(endTImeInLtFormat.toISOString()).diff(
    startTimeInLtFormat.toISOString(),
    'minutes',
  );
  const meeting_time  =   moment(event?.end).diff(event?.start, 'minutes') 
  return (
    <>
      {event && event.isCombinedMeetingRoom ? (
        <div className={`bookedSlots ${meeting_time <=15 && event?.status === 'Blocked' ? 'pd_0_10px': ''}`}>
          {duration !== 15 && <div className="bookedUserName">Occupied</div>}
          <HtmlTooltip
            arrow
            placement={`${
              Number(moment.tz(event.start, 'Europe/London').format('hh')) === 5
                ? 'top-start'
                : 'left-start'
            }`}
            classes={{ tooltip: 'custom-width' }}
            PopperProps={{
              popperOptions: {
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: '-40px 0',
                  },
                },
              },
            }}
            title={
              <>
                <Typography>Rooms In Use</Typography>
                <Typography>{event && event.isCombinedMeetingRoom}</Typography>
                <Typography>
                  {`${moment
                    .tz(event.start, 'Europe/London')
                    .format('hh:mm a')} - ${moment
                    .tz(event.end, 'Europe/London')
                    .format('hh:mm a')}`}
                </Typography>
                <Typography>{event && event.description}</Typography>
                <hr />
              </>
            }
          >
            <InfoOutlinedIcon
              className={
                duration !== 15 ? 'tooltipIcon ' : 'tooltipIcon tooltipIconUp'
              }
              fontSize="small"
            />
          </HtmlTooltip>
          {duration !== 15 && (
            <>
              <Typography>Rooms In Use</Typography>
              {duration !== 60 && (
                <>
                  <Typography>
                    {event &&
                      event.status !== 'Blocked' &&
                      event.isCombinedMeetingRoom}
                  </Typography>
                </>
              )}
              {duration !== 480 ||
                (duration !== 105 && (
                  <>
                    <Typography>
                      {`${moment
                        .tz(event.start, 'Europe/London')
                        .format('hh:mm a')} - ${moment
                        .tz(event.end, 'Europe/London')
                        .format('hh:mm a')}`}
                    </Typography>
                  </>
                ))}
            </>
          )}
        </div>
      ) : (
        <div className={`bookedSlots ${meeting_time <=15 && event?.status === 'Blocked'? 'pd_0_10px': ''}`}>
          {duration !== 15 && event.customer && (
            <div className="bookedUserName">
              {`${event && event.customer && event.customer.firstName} 
              ${event && event.customer && event.customer.lastName}`}
            </div>
          )}
          <HtmlTooltip
            arrow
            placement={`${
              Number(moment.tz(event.start, 'Europe/London').format('hh')) === 5
                ? 'top-start'
                : 'left-start'
            }`}
            classes={{ tooltip: 'custom-width' }}
            PopperProps={{
              popperOptions: {
                modifiers: {
                  offset: {
                    enabled: true,
                    offset: '-40px 0',
                  },
                },
              },
            }}
            title={
              <div className="tooltipBookedUser">
                <div className="bookedUserName">
                  {event && event.customer
                    ? `${event && event.customer && event.customer.firstName} ${
                        event && event.customer && event.customer.lastName
                      }`
                    : `${event && event.createdBy}`}
                </div>
                <div className="bookedTenantNam">
                  {event && event.customer && event.customer.tenantName}
                </div>
                <div className="bookedTenantName bookedSlotTime">
                  {`${moment
                    .tz(event.start, 'Europe/London')
                    .format('hh:mm a')} - ${moment
                    .tz(event.end, 'Europe/London')
                    .format('hh:mm a')}`}
                </div>
                <div className="bookedTenantName">
                  <div>
                    {event && event.roomLayout && event.roomLayout.name}
                  </div>
                </div>
                <div className="bookedTenantName">
                  <div>{event && event.description}</div>
                </div>
                <div className="compCattr">
                  {event &&
                  event.complimentaryCatering &&
                  event.complimentaryCatering.length > 0 &&
                  event &&
                  event.cateringOrder &&
                  event.cateringOrder.orderMenu &&
                  event.cateringOrder.orderMenu.length > 0 ? (
                    <div className="bookedTenantName">
                      Complimentary{' '}
                      {event &&
                        event.cateringOrder &&
                        event.cateringOrder.status === 'Booked' &&
                        `| ${'Catered'}`}
                    </div>
                  ) : (
                    (event &&
                      event.complimentaryCatering &&
                      event.complimentaryCatering.length > 0 && (
                        <div className="bookedTenantName">Complimentary </div>
                      )) ||
                    (event &&
                      event.cateringOrder &&
                      event.cateringOrder.status === 'Booked' &&
                      event &&
                      event.cateringOrder &&
                      event.cateringOrder.orderMenu &&
                      event.cateringOrder.orderMenu.length > 0 && (
                        <div className="catteredMenu">Catered</div>
                      ))
                  )}
                </div>
                <hr />
              </div>
            }
          >
            <InfoOutlinedIcon
              className={
                duration !== 15 ? 'tooltipIcon ' : 'tooltipIcon tooltipIconUp'
              }
              fontSize="small"
            />
          </HtmlTooltip>

          {duration !== 15 && (
            <>
              <div className="bookedTenantName rowEllips">
                {event && event.customer && event.customer.tenantName}
              </div>
              {duration !== 60 && event.status !== 'Blocked' && (
                <div className="bookedTenantName bookedSlotTime">
                  {`${moment
                    .tz(event.start, 'Europe/London')
                    .format('hh:mm a')} - ${moment
                    .tz(event.end, 'Europe/London')
                    .format('hh:mm a')}`}
                </div>
              )}
              {(duration !== 15 || duration !== 45 || duration !== 105) &&
                event.status !== 'Blocked' && (
                  <>
                    <div className="bookedTenantName">
                      {event && event.roomLayout && event.roomLayout.name}
                    </div>
                    <div className="compCattr">
                      {event &&
                      event.complimentaryCatering &&
                      event.complimentaryCatering.length > 0 &&
                      event &&
                      event.cateringOrder &&
                      event.cateringOrder.orderMenu &&
                      event.cateringOrder.orderMenu.length > 0 ? (
                        <div className="bookedTenantName">
                          Complimentary{' '}
                          {event &&
                            event.cateringOrder &&
                            event.cateringOrder.status !== 'Cancelled' &&
                            `| ${'Catered'}`}
                        </div>
                      ) : (
                        (event &&
                          event.complimentaryCatering &&
                          event.complimentaryCatering.length > 0 && (
                            <div className="bookedTenantName">
                              Complimentary{' '}
                            </div>
                          )) ||
                        (event &&
                          event.cateringOrder &&
                          event.cateringOrder.status !== 'Cancelled' &&
                          event &&
                          event.cateringOrder &&
                          event.cateringOrder.orderMenu &&
                          event.cateringOrder.orderMenu.length > 0 && (
                            <div className="catteredMenu">Catered</div>
                          ))
                      )}
                    </div>
                  </>
                )}
              {event.status === 'Blocked' && (
                <>
                  <div className="bookedTenantName blockedTenantName">
                    Meeting Room Blocked
                    <button
                      type="button"
                      id={event.id}
                      onClick={() => {
                        ApiClient.apiDelete(
                          `${apiEndPoints.deleteBlockMrBooking}${event.id}`,
                        )
                          .then(() => {
                            window.location.reload();
                          })
                          .catch(error => {
                            const err = error;
                            alert(err.response.data.message);
                          });
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}
const CustomerHeader = ({ resource }) => {
  const { activeId } = useContext(GlobalContext);
  const mrIds = activeId.map(item => {
    return item.id;
  });
  const formatedLayouts =
    resource &&
    resource.roomLayouts &&
    resource.roomLayouts.map(item =>
      item.name.toString().split(',').join(', '),
    );
  const combinedRooms =
    resource &&
    resource.combinedMeetingRooms &&
    resource.combinedMeetingRooms.map(item => {
      if (mrIds.includes(item.id)) {
        return item.name.toString().split(',').join(',');
      }
    });

  const removedUndefinedVars =
    combinedRooms && combinedRooms.filter(element => element !== undefined);

  const addCombinedRooms =
    removedUndefinedVars &&
    removedUndefinedVars.toString().split(',').join(' + ');
  return (
    <div className="meetingRoomTitle" key={resource.id}>
      <b style={{ top: '10px' }}>
        {resource && resource.roomType === 'Combined' && (
          <span className="dotClass">
            <img src={dotImage} alt="dotImage" />
          </span>
        )}
      </b>
      <strong>{resource.title}</strong>
      <HtmlTooltip
        arrow
        placement="left-start"
        classes={{ tooltip: 'custom-width' }}
        PopperProps={{
          popperOptions: {
            modifiers: {
              offset: {
                enabled: true,
                offset: '-40px 0',
              },
            },
          },
        }}
        title={
          <>
            <Typography variant="h3" className="mainHeading tooltipBHeading">
              {resource && resource.combinedMeetingRooms
                ? `Combined Room :  ${addCombinedRooms}`
                : resource.title}
            </Typography>
            <Typography
              variant="h6"
              className="tooltipBHeading tooltipBHeadingFlex"
            >
              £{resource.hourlyPrice}/Hour - £{resource.fullDayPrice}/Day
              <span className="">
                Minimum Booking: {resource.minimumBookingTime} Mins
              </span>
            </Typography>
            <Typography
              variant="h5"
              className="tooltipBSubHeading tooltipBHeadingFlex"
            >
              <div className="tooltipBHeadingCapital">
                {formatedLayouts.toString().split(',').join(' - ')}
              </div>
              <span className="">{resource.capacity} Capacity</span>
            </Typography>
            <hr className="tooltipBLine" />
            <Typography variant="h2" className="tooltipBHeading">
              Description
            </Typography>
            <div className="tooltipBSubHeading tooltipsDesc">
              {resource.description}
            </div>
            <Typography variant="h2" className="tooltipBHeading">
              Equipment
            </Typography>
            {resource &&
              resource.equipment &&
              resource.equipment.map(item => {
                return (
                  <div className="tooltipBSubHeading equipmentCol">
                    - {item.name}
                  </div>
                );
              })}
          </>
        }
      >
        <InfoOutlinedIcon className="icon" fontSize="small" />
      </HtmlTooltip>
    </div>
  );
};
const CustomToolbar = ({ date, onNavigate }) => {
  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button
          type="button"
          className="todayBtn"
          onClick={() => onNavigate('today', 'TODAY')}
        >
          Today
        </button>
        <button
          type="button"
          className="prevArrow"
          onClick={() => {
            onNavigate('back', 'PREV');
          }}
        >
          <img src={buttonLeft} alt="buttonLeft" />
        </button>
        <button
          type="button"
          className="prevArrow"
          onClick={() => {
            onNavigate('next', 'NEXT');
          }}
        >
          <img src={buttonRight} alt="buttonRight" />
        </button>
      </span>
      <span className="rbc-toolbar-label">
        {moment(date).format('dddd Do MMMM YYYY')}
      </span>
    </div>
  );
};
const customSlotPropGetter = () => {};

const customDayPropGetter = () => {
  return {
    style: {
      backgroundColor: `#fff`,
    },
  };
};

const Booking = () => {
  const history = useHistory();
  const classes = useStyles();
  const { buildingId } = useParams();
  const {
    setSelectedName,
    setStartDate,
    setEndDate,
    setNewMeetingRoomsList,
    setActiveId,
    setPermissionsStatus,
    rolePermission,
    getCacheData,
    setCacheData,
    buidlingLocalStorage,
    setBuidlingLocalStorage,
  } = useContext(GlobalContext);
  const [bookingUsersList, setBookingUsersList] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [meetingRoomsList, setMeetingRoomsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMRs, setSelectedMRs] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateChange, setDateChange] = useState(false);
  const upDatedDate = localStorage.getItem('selectedNewDate');

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  const logApi = () => {
    const payload = {
      act: 'signIn',
    };
    ApiClient.apiPost(apiEndPoints.logAct, payload)
      .then(() => {
        console.log('');
      })
      .catch(error => {
        console.log(error);
      });
  };
  // get getRolePermission API Call
  const getRolePermission = () => {
    setLoading(true);
    ApiClient.apiGet(apiEndPoints.getRolePermission)
      .then(data => {
        const permissionData = [data.data.data];
        const myHour = new Date();
        myHour.setHours(myHour.getHours() + 24);
        permissionData.push(myHour);
        localStorage.setItem('rolePermission', JSON.stringify(permissionData));
        setLoading(false);
        setPermissionsStatus(true);
      })
      .catch(error => {
        authContext.logOut();
        setLoading(false);
        console.log(error, 'error');
      });
  };
  function checkExpiration() {
    const values = JSON.parse(localStorage.getItem('rolePermission'));
    if (values && values[1] < new Date()) {
      localStorage.removeItem('rolePermission');
    }
  }

  function myFunction() {
    const myinterval = 15 * 60 * 1000; // 15 min interval
    // eslint-disable-next-line func-names
    setInterval(function () {
      checkExpiration();
    }, myinterval);
  }

  myFunction();
  useEffect(async () => {
    const buildingCache = await getCacheData('/', 'buildingData');
    setBuidlingLocalStorage((buildingCache && buildingCache) || []);
    if (localStorage.getItem('rolePermission') === null) {
      getRolePermission();
      logApi();
    } else {
      setPermissionsStatus(true);
      // logOut();
    }
  }, []);

  const transformedMrList = meetingRoomsList.map(
    ({
      id,
      name,
      building,
      description,
      equipment,
      fullDayPrice,
      hourlyPrice,
      maxCapacity,
      minimumBookingTime,
      roomLayouts,
      status,
      roomType,
      combinedMeetingRooms,
    }) => ({
      id,
      title: name,
      building,
      description,
      equipment,
      fullDayPrice,
      hourlyPrice,
      minimumBookingTime,
      capacity: maxCapacity,
      roomLayouts,
      status,
      roomType,
      combinedMeetingRooms,
    }),
  );

  useEffect(async () => {
    const data = await getCacheData('/', 'selectedMeetingRooms');
    setSelectedMRs(data);
  }, []);

  useEffect(() => {
    setCacheData('/', 'selectedMeetingRooms', selectedMRs);
  }, [selectedMRs]);

  const selectedMRList = selectedMRs.length
    ? transformedMrList.filter(item => selectedMRs.includes(item.id))
    : transformedMrList.filter(item => item.id);
  // Map occupied bookings with corresponding mr name.
  bookingUsersList.map(item => {
    if (item && item.individualBookingList) {
      item.individualBookingList.map(indbookingId => {
        const ocuupiedRoomList = bookingUsersList.find(
          o => o.id === indbookingId,
        );
        if (ocuupiedRoomList && ocuupiedRoomList.isCombinedMeetingRoom) {
          ocuupiedRoomList.isCombinedMeetingRoom = item.meetingRoom.name;
        }
      });
    }
  });
  const afterDuplicates = removeDuplicates(bookingUsersList);
  const transformedBookingList = afterDuplicates.map(
    ({
      building,
      complimentaryCatering,
      createdBy,
      createdDate,
      customer,
      endDate,
      endDateWithBuffer,
      fullDay,
      id,
      cateringOrder,
      isCombinedMeetingRoom,
      meetingRoom,
      notes,
      payment,
      roomLayout,
      startDate,
      status,
      updatedBy,
      updatedDate,
    }) => ({
      id,
      title: meetingRoom.name,
      description: notes,
      start: new Date(startDate),
      end: new Date(endDate),
      resourceId: meetingRoom.id,
      isCombinedMeetingRoom,
      building,
      cateringOrder,
      complimentaryCatering,
      createdBy,
      createdDate,
      customer,
      endDateWithBuffer,
      fullDay,
      meetingRoom,
      payment,
      roomLayout,
      status,
      updatedBy,
      updatedDate,
      type: 'booking',
    }),
  );
  // add buffer time
  if (transformedBookingList && transformedBookingList.length > 0) {
    const blockedArray = [];
    transformedBookingList.map(blockedItem => {
      if (blockedItem.status === 'Blocked') {
        const endTime = moment(blockedItem.endDateWithBuffer)
          .add(1, 'second')
          .toISOString();
        return blockedArray.push({
          ...blockedItem,
          endDateWithBuffer: endTime,
        });
      }
    });
    transformedBookingList.map(booking => {
      if (booking.status !== 'Blocked') {
        let flag = false;
        blockedArray.map(blockedItem => {
          if (
            blockedItem.endDateWithBuffer ===
              moment(booking.start).toISOString() &&
            blockedItem.resourceId === booking.resourceId
          ) {
            flag = true;
          }
        });
        const startDate = moment(booking.start)
          .subtract(15, 'minutes')
          .toISOString();
        const turnAroundTime = moment(booking.endDateWithBuffer).diff(
            booking.end.toISOString(),
            'minutes',
          );
        const endDate = moment(booking.end).add(turnAroundTime, 'minutes').toISOString();
        if (
          moment.tz(startDate, 'Europe/London').format('HH') >= '09'&&
          !flag
        ) {
          transformedBookingList.push({
            ...booking,
            type: 'buffer',
            start: new Date(Date.parse(startDate)),
            end: booking.start,
          });
        }
        if (
          moment.tz(endDate, 'Europe/London').format('HH') <= 18
        ) {
          transformedBookingList.push({
            ...booking,
            type: 'buffer',
            start: booking.end,
            end: new Date(Date.parse(endDate)),
          });
        }
      }
    });
  }

  const finalTransformedBookingList = transformedBookingList.filter(
    item =>
      item.status !== 'Cancelled' &&
      item.status !== 'NoShow' &&
      moment.tz(item.end, 'Europe/London').format('HH') >= '09' &&
      moment.tz(item.start, 'Europe/London').format('HH') < '18',
  );
  const getMeetingRoomsListAction = payload => {
    setLoading(true);
    ApiClient.apiPost(apiEndPoints.getMeetingRoomsList, payload)
      .then(data => {
        setLoading(false);
        setActiveId(data && data.data && data.data.data);
        setMeetingRoomsList(data && data.data && data.data.data);
      })
      .catch(error => {
        const err = error;
        setLoading(false);
        alert(
          err && err.response && err.response.data && err.response.data.message,
        );
      });
  };
  const getBookingListAction = payload => {
    setLoading(true);
    ApiClient.apiPost(apiEndPoints.getBookingList, payload)
      .then(data => {
        setLoading(false);
        setBookingUsersList(data && data.data && data.data.data);
      })
      .catch(error => {
        const err = error;
        setLoading(false);
        alert(
          err && err.response && err.response.data && err.response.data.message,
        );
      });
  };

  useEffect(() => {
    if (buildings && buildings.length > 0) {
      getMeetingRoomsListAction({
        searchText: undefined,
        buildings: buildings && buildings.length > 0 ? buildings : undefined,
        status: ['Active'],
        sortOrder: 'desc',
        sortBy: 'maxCapacity',
        offset: 0,
        limit: 40,
      });
      getBookingListAction({
        searchText: undefined,
        tenantId: undefined,
        buildings: buildings && buildings.length > 0 ? buildings : undefined,
        startDate: moment(selectedDate).subtract(1, 'd').toISOString(),
        endDate: moment(selectedDate).add(1, 'd').toISOString(),
        sortOrder: 'asc',
        paymentMethod: undefined,
        sortBy: 'startDate',
        status: ['Pending', 'Booked', 'Blocked'],
        offset: 0,
        limit: maxMeetingRoomsInCalender,
      });
      setBuidlingLocalStorage(buildings);
    }
  }, [buildings]);

  useEffect(() => {
    if (upDatedDate) {
      setSelectedDate(upDatedDate);
    }
  }, [upDatedDate]);

  useEffect(() => {
    if (dateChange) {
      getBookingListAction({
        searchText: undefined,
        tenantId: undefined,
        buildings: buildings && buildings.length > 0 ? buildings : undefined,
        startDate: moment(selectedDate).subtract(1, 'd').toISOString(),
        endDate: moment(selectedDate).add(1, 'd').toISOString(),
        sortOrder: 'asc',
        paymentMethod: undefined,
        sortBy: 'startDate',
        status: ['Pending', 'Booked', 'Blocked'],
        offset: 0,
        limit: maxMeetingRoomsInCalender,
      });
    }
  }, [upDatedDate]);
  const IsFutureDate = dateValu => {
    const Currentdate = new Date();
    const date = moment(dateValu).format('L');
    const todayDate = moment(Currentdate).format('L');
    const dateVal = date.split('/');
    if (
      (!moment(moment(Currentdate).format('L')).isAfter(`${dateVal}`) ||
        todayDate === date) &&
      moment(dateValu).format('dddd') !== 'Sunday' &&
      moment(dateValu).format('dddd') !== 'Saturday' &&
      !(
        (moment(dateValu).format('hh:mm a') === '10:30 pm')
      )
    ) {
      history.push('/meetingroom-booking');
      localStorage.setItem('selectedNewDate', dateValu);
    }
    return true;
  };
  const onClickBooking = booking => {
    if (
      (booking && booking.isCombinedMeetingRoom) ||
      (booking && booking.status === 'Pending') ||
      (booking && booking.status === 'Blocked')
    ) {
      return true;
    }
    history.push(`/view-booking/${booking.id}`);
  };
  const onClickEmptyBooking = ({ start, end, resourceId }) => {
    setStartDate(start);
    setEndDate(end);
    setNewMeetingRoomsList(meetingRoomsList);
    setSelectedName(resourceId);
    IsFutureDate(start);
    localStorage.setItem('selectedNewDate', start);
  };
  const breadCrumbs = {
    listBreadCrumbPath: '/',
    listBreadCrumbTitle: 'Meeting Room Bookings',
  };
  if (rolePermission && rolePermission.userType === 'Caterer') {
    window.location.reload(false);
  }
  const now = () => new Date();
  const handleNavigate = e => {
    setDateChange(true);
    const date = moment(selectedDate).format('dddd');
    if (e === 'NEXT' && date === 'Friday') {
      const nextDate = new Date(selectedDate);
      setSelectedDate(moment(nextDate).add(3, 'days'));
      localStorage.setItem('selectedNewDate', moment(nextDate).add(3, 'days'));
    } else if (
      moment(selectedDate).format('dddd') === 'Monday' &&
      e === 'PREV'
    ) {
      const nextDate = new Date(selectedDate);
      setSelectedDate(moment(nextDate).subtract(3, 'days'));
      localStorage.setItem(
        'selectedNewDate',
        moment(nextDate).subtract(3, 'days'),
      );
    } else if (
      moment(selectedDate).format('dddd') !== 'Saturday' &&
      moment(selectedDate).format('dddd') !== 'Sunday' &&
      e === 'PREV'
    ) {
      const nextDate = new Date(selectedDate);
      setSelectedDate(moment(nextDate).subtract(1, 'days'));
      localStorage.setItem(
        'selectedNewDate',
        moment(nextDate).subtract(1, 'days'),
      );
    } else if (e === 'TODAY') {
      const nextDate = new Date();
      setSelectedDate(nextDate);
      localStorage.setItem('selectedNewDate', nextDate);
    } else if (
      moment(selectedDate).format('dddd') === 'Saturday' &&
      e === 'PREV'
    ) {
      const nextDate = new Date(selectedDate);
      setSelectedDate(moment(nextDate).subtract(1, 'days'));
      localStorage.setItem(
        'selectedNewDate',
        moment(nextDate).subtract(1, 'days'),
      );
    } else if (
      moment(selectedDate).format('dddd') === 'Sunday' &&
      e === 'PREV'
    ) {
      const nextDate = new Date(selectedDate);
      setSelectedDate(moment(nextDate).subtract(1, 'days'));
      localStorage.setItem(
        'selectedNewDate',
        moment(nextDate).subtract(1, 'days'),
      );
    } else {
      const nextDate = new Date(selectedDate);
      setSelectedDate(moment(nextDate).add(1, 'days'));
      localStorage.setItem('selectedNewDate', moment(nextDate).add(1, 'days'));
    }
  };

  return (
    <div className={classes.pageListMain}>
      <SnackBar />
      <div className="pageBredcrumbTimer">
        <Breadcrumb breadCrumbs={breadCrumbs} />
      </div>
      <Divider light />
      <div className="BigCalenderMain pageContainer tableContainer mrFilter">
        <BigCalenderFilter
          setBuildings={setBuildings}
          buildings={buildings}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          buildingId={buildingId}
          transformedMrList={transformedMrList}
          setDateChange={setDateChange}
          selectedMRs={selectedMRs}
          setSelectedMRs={setSelectedMRs}
          setCacheData={setCacheData}
          buidlingLocalStorage={buidlingLocalStorage}
          setBuidlingLocalStorage={setBuidlingLocalStorage}
        />
        <div className="pageContainer addUserContainer calenderContainer">
          {loading ? (
            <CircularProgress color="inherit" />
          ) : (buidlingLocalStorage && buidlingLocalStorage.length > 0) ||
            buildingId ? (
            transformedMrList.length > 0 ? (
              <div>
                <Calendar
                  selectable
                  slotPropGetter={customSlotPropGetter}
                  dayPropGetter={customDayPropGetter}
                  onSelectSlot={onClickEmptyBooking}
                  events={finalTransformedBookingList}
                  localizer={localizer}
                  defaultView={Views.DAY}
                  views={['day']}
                  date={upDatedDate ? new Date(upDatedDate) : new Date()}
                  onNavigate={(e, x) => {
                    handleNavigate(e, x);
                  }}
                  step={15}
                  timeslots={2}
                  // dayLayoutAlgorithm="no-overlap"
                  allDay={false}
                  formats={formats}
                  onSelecting={() => false}
                  getNow={() => getNow(now, bstmMoment)}
                  min={
                    isdaylightsaving(selectedDate)
                      ? getTimeAsDate(8, bstmMoment, selectedDate)
                      : getTimeAsDate(9, bstmMoment, selectedDate)
                  }
                  max={
                    isdaylightsaving(selectedDate)
                      ? getTimeAsDate(17, bstmMoment, selectedDate)
                      : getTimeAsDate(18, bstmMoment, selectedDate)
                  }
                  resources={selectedMRList}
                  tooltipAccessor={null}
                  resourceIdAccessor="id"
                  titleAccessor="title"
                  onSelectEvent={onClickBooking}
                  toolbar
                  components={{
                    event: Event,
                    resourceHeader: CustomerHeader,
                    toolbar: CustomToolbar,
                  }}
                  eventPropGetter={eventStyleGetter}
                  defaultDate={upDatedDate}
                  onSelectDate={day => {
                    setSelectedDate({ day, view: 'day' });
                  }}
                />
              </div>
            ) : (
              <div className="noBuilding">
                <span>There are no meeting rooms on selected building</span>
              </div>
            )
          ) : (
            <div className="noBuilding">
              <span>Please select a building</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Booking;
