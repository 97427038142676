/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Divider,
  CircularProgress,
  Button,
  Tooltip,
  Dialog,
  DialogActions,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import dowmArw from '../../images/down-arw.svg';
import editIcon from '../../images/edit-icon.svg';
import EventBookingsFilter from './EventBookingsFilter';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { EventBookingsContext } from '../../context/EventBookingsContext';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import { GlobalContext } from '../../context/GlobalContext';
import SnackBar from '../../components/SnackBar/SnackBar';
import { BookingPeriod } from '../../utils/constants';

let filteredColumns = [];
const columns = [
  {
    id: 'attendee',
    SortOrder: 'desc',
    SortBy: 'nameSort',
    label: 'ATTENDEE',
    minWidth: 170,
    width: 170,
  },
  {
    id: 'tenant',
    SortOrder: 'desc',
    SortBy: 'tenantNameSort',
    label: 'TENANT',
    minWidth: 90,
    width: 90,
  },
  {
    id: 'event',
    SortOrder: 'desc',
    SortBy: 'eventNameSort',
    label: 'EVENT',
    align: 'left',
    minWidth: 220,
    width: 220,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'location',
    SortOrder: 'desc',
    SortBy: 'location',
    label: 'LOCATION',
    align: 'left',
    minWidth: 130,
    width: 130,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'start',
    SortOrder: 'desc',
    SortBy: 'startTime',
    label: 'START',
    align: 'left',
    minWidth: 100,
    width: 100,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'end',
    SortOrder: 'desc',
    SortBy: 'endTime',
    label: 'END',
    align: 'left',
    minWidth: 100,
    width: 100,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'tickets',
    SortOrder: 'desc',
    SortBy: 'guests',
    label: 'TICKET COSTS',
    align: 'left',
    minWidth: 80,
    width: 80,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'STATUS',
    align: 'left',
    minWidth: 80,
    width: 80,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'edit',
    SortOrder: '',
    SortBy: '',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 15px',
    color: '#444444',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    // '&:nth-child(2)': {
    //   cursor: 'default',
    //   pointerevents: 'none',
    //   '&:after': {
    //     display: 'none',
    //   },
    // },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  editIcon: {
    margin: '0 auto',
    cursor: 'pointer',
    position: 'relative',
    top: '5px',
  },
  accountBoxImg: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      margin: '0 10px',
    },
  },
  tableBody: {
    verticalAlign: 'middle',
    '& th': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'normal',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
      cursor: 'default !important',
      verticalAlign: 'middle',
    },
    '& td': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'normal',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
      cursor: 'default !important',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  customerVillgTop: {
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontWeight: 'bold',
  },

  addIcon: {
    position: 'fixed',
    zIndex: '999',
    right: '55px',
    bottom: '55px',
  },
  fab: {
    background: '#000',
    color: '#fff',
    '&:hover': {
      background: '#000',
      color: '#fff',
    },
  },
  pageListMain: {
    position: 'relative',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '10px',
    padding: '5px 0',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#838384',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '30px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
  platformIcons: {
    display: 'flex',
    width: 70,
    margin: 'auto',
  },
  formButton: {
    marginTop: '60px',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      height: '47px',
      lineHeight: '47px',
      border: '1px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff',
      marginLeft: '30px',
      cursor: 'pointer',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#444444',
        border: 'none',
      },
    },
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  embeddedPad: {
    padding: 0,
  },
});

const EventBookings = props => {
  const { embedded } = props;

  const {
    eventBookingsList,
    getEventsListAction,
    setSortOrder,
    sortOrder,
    setSortBy,
    sortBy,
    loading,
    offset,
    setLoading,
    selectedStatus,
    selectedEventsType,
  } = useContext(EventBookingsContext);
  const { setSuccessMessage } = useContext(GlobalContext);
  const history = useHistory();
  const classes = useStyles();
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [flag, setFlag] = useState('');
  const [onOutsideClick, setOnOutsideClick] = useState(false);
  const [selectedEventId, setSelecetedEventId] = useState('');
  const [active, setActive] = useState(false);

  const handleClick = (columnId, sortName) => {
    setSortOrder(sortOrder === columnId ? 'asc' : 'desc');
    setSortBy(sortBy === sortName ? sortBy : sortName);
  };
  const breadCrumbs = {
    listBreadCrumbPath: '/event-bookings',
    listBreadCrumbTitle: 'Event Bookings',
  };

  useEffect(() => {
    filteredColumns = columns;
    if (embedded !== undefined)
      filteredColumns = columns.filter(
        col => col.id !== 'attendee' && col.id !== 'tenant',
      );
  }, [embedded]);

  useEffect(() => {
    if (toggle) {
      ApiClient.apiPut(
        `${
          flag === 'Cancel' ? apiEndPoints.cancelEvent : apiEndPoints.dnaEvent
        }/${selectedEventId}`,
      )
        .then(data => {
          const updateMsg =
            data && data.data.message
              ? `${
                  flag === 'Cancel'
                    ? 'Event booking has been cancelled successfully'
                    : 'Event booking has been moved to DNA successfully'
                }`
              : '';
          setSuccessMessage(updateMsg);
          setToggle(false);
          setFlag('');
          setOpen(false);
          setSelecetedEventId('');
          setLoading(false);
          getEventsListAction({
            searchText: undefined,
            sortOrder: 'desc',
            sortBy: 'startTime',
            status:
              selectedStatus && selectedStatus.length > 0
                ? selectedStatus
                : ['Booked', 'Cancelled', 'DNA'],
            type:
              selectedEventsType && selectedEventsType.length > 0
                ? selectedEventsType
                : ['Paid', 'Free'],
            offset,
            limit: 40,
          });
        })
        .catch(err => {
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
          setToggle(false);
          setFlag('');
          setOpen(false);
          setLoading(false);
        });
    }
  }, [toggle]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /*
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOnOutsideClick(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);

  const viewEventDetails = eventId =>
    history.push(`/view-event-details/${eventId}`);

  const popUpClose = () => {
    setToggle(true);
  };

  const getHours = (date1, date2) => {
    return Math.round((date1 - date2) / 36e5);
  };

  return (
    <div className={classes.pageListMain}>
      {!embedded && (
        <>
          <SnackBar />
          <Breadcrumb breadCrumbs={breadCrumbs} />
          <Divider light />
          <EventBookingsFilter />
          <Divider light />
        </>
      )}
      <div
        className={`pageContainer tableContainer offerListTable ${
          embedded && classes.embeddedPad
        }`}
      >
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {filteredColumns.map(column => (
                    <TableCell
                      className={`${classes.tableHeaderCell} noSort ${
                        active && column.SortBy === sortBy ? 'active' : ''
                      }`}
                      align={column.align}
                      id={column.label}
                      key={column.id}
                      disabled={column.SortBy === ''}
                      onClick={() => {
                        if (column.SortBy !== '' || column.SortBy === sortBy) {
                          handleClick(column.SortOrder, column.SortBy);
                          setActive(!active);
                        }
                      }}
                      style={{ minWidth: column.minWidth }}
                    >
                      <p>{column.label}</p>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TableBody>
                  {eventBookingsList && eventBookingsList.length > 0
                    ? eventBookingsList.map(eventBooking => (
                        <TableRow
                          className={classes.tableBody}
                          key={eventBooking.id}
                        >
                          {!embedded && (
                            <TableCell
                              component="th"
                              scope="row"
                              align="left"
                              valign="middle"
                              style={{
                                maxWidth: 120,
                                minWidth: 120,
                                width: 120,
                                verticalAlign: 'middle',
                              }}
                            >
                              <div className="rowEllips">
                                <Tooltip
                                  title={`${eventBooking.firstName} ${eventBooking.lastName}`}
                                >
                                  <div className={classes.customerVillgTop}>
                                    {`${eventBooking.firstName} ${eventBooking.lastName}`}
                                  </div>
                                </Tooltip>
                                <Tooltip title={eventBooking.email}>
                                  <div className={classes.customerEmail}>
                                    {eventBooking.email}
                                  </div>
                                </Tooltip>
                              </div>
                            </TableCell>
                          )}
                          {!embedded && (
                            <TableCell
                              align="left"
                              style={{
                                maxWidth: 90,
                                width: 90,
                                verticalAlign: 'top',
                              }}
                            >
                              <Tooltip title={eventBooking.tenantName}>
                                <div className={classes.customerVillgTop}>
                                  {eventBooking.tenantName}
                                </div>
                              </Tooltip>
                            </TableCell>
                          )}
                          <TableCell
                            align="left"
                            style={{ maxWidth: 220, width: 220, minWidth: 220 }}
                          >
                            <div className="rowEllips">
                              <Tooltip title={eventBooking.eventName}>
                                <div
                                  role="presentation"
                                  className={`${classes.customerVillgTop} eventNameUnderline`}
                                  onClick={() =>
                                    viewEventDetails(eventBooking.eventId)
                                  }
                                >
                                  {eventBooking.eventName}
                                </div>
                              </Tooltip>
                              <div className={classes.customerVillg}>
                                {eventBooking.host}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ maxWidth: 120, width: 120, minWidth: 120 }}
                          >
                            <div className="rowEllips">
                              <Tooltip
                                title={
                                  eventBooking.address &&
                                  eventBooking.address.line1
                                }
                              >
                                <div className={classes.customerVillgTop}>
                                  {eventBooking.address &&
                                    eventBooking.address.line1}
                                </div>
                              </Tooltip>
                              <Tooltip
                                title={`${
                                  (eventBooking.address &&
                                    eventBooking.address.line2 &&
                                    eventBooking.address.line2) ||
                                  ''
                                }${
                                  eventBooking.address &&
                                  eventBooking.address.line2
                                    ? ', '
                                    : ''
                                }${
                                  eventBooking.address &&
                                  eventBooking.address.city
                                }, ${
                                  eventBooking.address &&
                                  eventBooking.address.postcode
                                }`}
                              >
                                <div className={classes.customerVillg}>
                                  {`${
                                    (eventBooking.address &&
                                      eventBooking.address.line2 &&
                                      eventBooking.address.line2) ||
                                    ''
                                  }${
                                    eventBooking.address &&
                                    eventBooking.address.line2
                                      ? ', '
                                      : ''
                                  }${
                                    eventBooking.address &&
                                    eventBooking.address.city
                                  }, ${
                                    eventBooking.address &&
                                    eventBooking.address.postcode
                                  }`}
                                </div>
                              </Tooltip>
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ maxWidth: 120, width: 100 }}
                          >
                            <div className="rowEllips">
                              <div className={classes.customerVillgTop}>
                                {moment
                                  .tz(eventBooking.eventDate, 'Europe/London')
                                  .format('DD MMM YYYY')}
                              </div>
                              <div
                                className={`${classes.customerVillg} meetingTime`}
                              >
                                {moment
                                  .tz(eventBooking.startTime, 'Europe/London')
                                  .format('hh:mm a')}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ maxWidth: 120, width: 100 }}
                          >
                            <div className="rowEllips">
                              <div className={classes.customerVillgTop}>
                                {moment
                                  .tz(eventBooking.eventDate, 'Europe/London')
                                  .format('DD MMM YYYY')}
                              </div>
                              <div
                                className={`${classes.buildVillg} meetingTime`}
                              >
                                {moment
                                  .tz(eventBooking.endTime, 'Europe/London')
                                  .format('hh:mm a')}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ maxWidth: 180, width: 80 }}
                            className="{row.status}"
                          >
                            <div className="rowEllips">
                              <div className={classes.customerVillgTop}>
                                {(eventBooking.ticketCost === 0 ||
                                  !eventBooking.type) &&
                                  'free'}
                                {eventBooking.ticketCost > 0 &&
                                  eventBooking.type &&
                                  eventBooking.type === 'Paid' &&
                                  eventBooking?.bookingInvoice?.url && (
                                    <div className="roomCoctsUnderline">
                                      <a
                                        rel="noreferrer"
                                        href={
                                          eventBooking.bookingInvoice &&
                                          eventBooking.bookingInvoice.url
                                        }
                                        target="_blank"
                                      >
                                        {' '}
                                        £
                                        {eventBooking &&
                                          eventBooking?.payment?.totalWithVat?.toFixed(2)}
                                      </a>
                                    </div>
                                  )}
                                {eventBooking.ticketCost > 0 &&
                                  eventBooking.type &&
                                  eventBooking.type === 'Paid' &&
                                  !eventBooking?.bookingInvoice?.url &&
                                  `£${eventBooking?.payment?.totalWithVat?.toFixed(2)}`}
                                {eventBooking.ticketCost > 0 &&
                                  eventBooking.type &&
                                  eventBooking.type !== 'Paid' &&
                                  `£${eventBooking?.payment?.totalWithVat?.toFixed(2)}`}
                              </div>
                              <div
                                className={`${classes.customerVillg} eventGuest`}
                              >
                                {eventBooking.guests > 0
                                  ? `${eventBooking.guests + 1} guests`
                                  : `${eventBooking.guests + 1} guest`}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              maxWidth: 140,
                              width: 80,
                              verticalAlign: 'top',
                            }}
                          >
                            <div
                              className={`${classes.customerVillgTop} rowEllips`}
                            >
                              {eventBooking.status}
                            </div>
                          </TableCell>

                          <TableCell
                            className="toLowercase"
                            align="right"
                            style={{ maxWidth: 150, width: 50 }}
                          >
                            {onOutsideClick &&
                            selectedEventId === eventBooking.id ? (
                              <div className="formButton" ref={wrapperRef}>
                                {eventBooking.startTime &&
                                getHours(
                                  moment.tz(
                                    eventBooking.startTime,
                                    'Europe/London',
                                  ),
                                  moment().tz('Europe/London'),
                                ) <= BookingPeriod.dnaBookingTime ? (
                                  <Button
                                    className="dna"
                                    onClick={() => {
                                      setOpen(true);
                                      setFlag('DNA');
                                    }}
                                  >
                                    DNA
                                  </Button>
                                ) : (
                                  ''
                                )}
                                <Button
                                  onClick={() => {
                                    setOpen(true);
                                    setFlag('Cancel');
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            ) : (
                              eventBooking.status === 'Booked' && (
                                <img
                                  src={editIcon}
                                  className={classes.editIcon}
                                  alt="editIcon"
                                  role="presentation"
                                  onClick={() => {
                                    setSelecetedEventId(eventBooking.id);
                                    setOnOutsideClick(true);
                                  }}
                                />
                              )
                            )}
                          </TableCell>
                        </TableRow>
                      ))
                    : !embedded && (
                        <>
                          <NoResultsFound />
                        </>
                      )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
        <Dialog
          open={open}
          onClose={popUpClose}
          disableBackdropClick
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="cancelDialogOpend"
        >
          {!toggle && (
            <>
              <div className={classes.cancelDialog}>
                <h2>
                  {flag === 'Cancel'
                    ? 'Are you sure you want to cancel this booking?'
                    : 'Are you sure to move this booking to DNA?'}
                </h2>
                {flag === 'Cancel' && (
                  <p>This person will no longer be allowed to attend</p>
                )}
              </div>
              <Divider className="formDivider popupDivider" light />
            </>
          )}
          <DialogActions className={`${classes.formButton} popupFormButton`}>
            {!toggle ? (
              <>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setSelecetedEventId('');
                  }}
                  color="primary"
                >
                  No, don’t
                </Button>
                <Button onClick={popUpClose} autoFocus>
                  Yes
                </Button>
              </>
            ) : (
              <CircularProgress color="inherit" />
            )}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};
export default EventBookings;
