export const offset = 0;
export const buildingsLimit = 500; // It's getBuildings api payload limit. if there are more than 500 buildings then needs to update the limit range.
export const tenantsLimit = 500; // It's getTenants api payload limit.
export const categorySorting = 'ZZZZZZZZZZ'; // This is to fix category name sort issue.
export const maxMeetingRoomsInCalender = 2000; // It's getMeetingrooms api payload limit.
// If there is no end date while creating news and articles then we will send the highestValidDate.
export const cateringCatgorystaticDate = '2021-01-01';
export const highestValidDate = '2031-01-01';
export const highestValidDateNew = '01.01.31';
export const capitalized = str =>
  `${str && str.charAt(0).toUpperCase()}${str && str.slice(1)}`; // To capitalize 1st letter in the any name while sending it to API.
export const meetingRoomBookingTimer = 15; // Meeting room booking timmer in minutes.
export const isScalableImage = false; // If this var is true then the images in the app will be scaled after and before cropping.
export const roomLayoutImgUploadError =
  'Image aspect ratio should be between 1:1.02 and 1:1. For example Width 348px Height 355px.';
export const tenantUserStatus = {
  renewalPending: 'Renewal Pending',
  active: 'Active',
  blocked: 'Blocked',
};

export const bookingTimeType = {
  pastBooking: 'PastBooking',
  futureBooking: 'FutureBooking',
  zone: 'Europe/London',
};
export const BookingPeriod = {
  extendBookingTime: '168',
  dnaBookingTime: '48',
};
export const bookingStatus = {
  cancel: 'Cancelled',
  noShow: 'NoShow',
};
export const cardType = {
  visa: 'VISA',
  mastercard: 'MASTERCARD',
  americanExpress: 'AMERICAN_EXPRESS',
  applePay: 'APPLE_PAY',
  googlePay: 'GOOGLE_PAY',
};
export const bookingCategory = {
  meetingRoomBooking: 'MeetingRoomBooking',
  eventBooking: 'EventBooking',
};
export const payments = 'payments';
export const paymentLinkPayment='paymentLinkPayments'
export const InvalidTerminalId = 'Invalid terminal id';
export const paymentStatus = {
  failed: 'Payment status failed',
};
export const verificationStatus = {
  successful: 'successful',
  timed_out: 'timed_out',
  signatureVerification: 'SIGNATURE_VERIFICATION',
  signatureProgress: 'SIGNATURE_VERIFICATION_IN_PROGRESS',
};
export const timeZone = { london: 'Europe/London' };
export const message = {
  createdSuccessfully: 'Successfully created booking',
}
export const dummyTerminalId = "dummyTeriminalId" 
export const offerType={
  digital:"Digital",
  physical:"Physical",
  digitalUrlOnly:"Digital URL Only",
}
export const environment={
  dev:'development',
  prod:'production',
}
export const messages={
  invalidUrl:'Invalid URL',
  valueEmail:'Please enter valid email address',
  emailsMatch:'Email ids should match',
  notExtended:'Meeting room not extended',
}
export const hour={
  oneDay:24,
  week:168,
  ninetyDays:2160,
}