/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { Input, makeStyles, FormHelperText } from '@material-ui/core';
import addImage from '../../images/addImage.svg';
import closeIcon from '../../images/close-icon.svg';
import ManageMedia from './ManageMedia';
import ImageCroper from './ImageCroper';
import {
  CLOUDINARY_API_KEY,
  CLOUDINARY_API_SECRET_KEY,
  CLOUDINARY_UPLOAD_PRESET,
  CLOUD_NAME,
} from '../../utils/config';
import { meetingroom_media_folder, image_title_1 } from './constants';
import {
  isScalableImage,
  roomLayoutImgUploadError,
} from '../../utils/constants';

const useStyles = makeStyles({
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    width: '100%',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  videoAddIcon: {
    position: 'absolute',
    top: '20px',
    left: 0,
    right: 0,
    margin: '0 auto',
    textAlign: 'center',
    height: '85px',
    width: '100%',
    lineHeight: '85px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'fill',
    },
  },
  videoAddIconView: {
    pointerEvents: 'none',
  },
});

const LayoutUpload = ({
  values,
  cateringMenu,
  loyaltyCardSupplier,
  loyaltyCardBuilding,
  viewMedia,
}) => {
  const {
    images,
    setImages,
    popErrorType,
    popErrorFieldName,
    roomLayoutImg,
    setRoomLayoutImg,
  } = values;
  const classes = useStyles();
  const [toggle, setToggle] = useState(false);
  const [imgIndex, setImgIndex] = useState('');
  const [cropImg, setCropImg] = useState(false);
  const [cropSize, setCropSize] = useState({});
  const [tag, setTag] = useState('');

  const imageFormatedUrl = (originalImage, sizes = '') =>
    `${originalImage.split('image/upload')[0]}image/upload/${
      (isScalableImage &&
        sizes &&
        `c_scale,h_${sizes.height},w_${sizes.width}/`) ||
      ''
    }f_jpg,f_auto,q_auto${originalImage.split('image/upload')[1]}`;

  const showUploadWidget = (e, resourceType, maxFileSize, folder, formates) => {
    window.cloudinary.openUploadWidget(
      {
        cloudName: CLOUD_NAME,
        api_key: CLOUDINARY_API_KEY,
        api_secret: CLOUDINARY_API_SECRET_KEY,
        uploadPreset: CLOUDINARY_UPLOAD_PRESET,
        showPoweredBy: false,
        folder,
        clientAllowedFormats: formates,
        sources: [
          'local',
          'camera',
          'url',
          'facebook',
          'instagram',
          'google_drive',
        ],
        minImageWidth: 348,
        minImageHeight: 355,
        showAdvancedOptions: false,
        cropping: false,
        multiple: false,
        resourceType,
        maxFileSize,
        defaultSource: 'local',
        styles: {
          palette: {
            window: '#FDFDFD',
            sourceBg: '#FFFFFF',
            windowBorder: '#000000',
            tabIcon: '#060606',
            inactiveTabIcon: '#82858A',
            menuIcons: '#000000',
            link: '#000000',
            action: '#000000',
            inProgress: '#000000',
            complete: '#000000',
            error: '#EA2727',
            textDark: '#000000',
            textLight: '#FFFFFF',
          },
          fonts: {
            default: null,
            "'Varta', sans-serif": {
              url: 'https://fonts.googleapis.com/css?family=Varta+Sans',
              active: true,
            },
          },
        },
      },
      (err, { event, info }) => {
        if (!err && event === 'success') {
          if (e === 'LayoutImage') {
            const ratio = info.height / info.width;
            if (ratio === 1 || (ratio < 1.03 && ratio > 1)) {
              setRoomLayoutImg([
                {
                  imageUrl: imageFormatedUrl(info.secure_url),
                  tag: image_title_1,
                },
              ]);
            } else {
              alert(roomLayoutImgUploadError);
            }
          }
        }
      },
    );
  };

  useEffect(() => {
    document.body.classList.toggle('modalOpen', toggle || cropImg);
  }, [toggle, cropImg]);

  return (
    <div className="spaceFileContainer">
      {toggle && (
        <ManageMedia
          values={{
            viewMedia,
            setCropSize,
            cropImg,
            toggle,
            setToggle,
            setCropImg,
            imgIndex,
            setTag,
            cateringMenu,
            loyaltyCardSupplier,
            loyaltyCardBuilding,
          }}
        />
      )}
      {cropImg && (
        <ImageCroper
          values={{
            cropSize,
            setCropSize,
            cropImg,
            setCropImg,
            toggle,
            setToggle,
            imgIndex,
            setImgIndex,
            tag,
            setTag,
            images,
            setImages,
          }}
        />
      )}
      <div className="cardPrevForBuilding">
        <ul className=" ">
          <ul className="MultipleImgUpload imgUploadVideo">
            <li style={{ marginBottom: '15px' }}>
              {!viewMedia && roomLayoutImg && roomLayoutImg.length > 0 && (
                <i className="removeImage" style={{ top: '45px' }}>
                  <img
                    src={closeIcon}
                    role="presentation"
                    alt="closeIcon"
                    onClick={() => setRoomLayoutImg([])}
                  />
                </i>
              )}
              <small className="imageAddName popImgUpload">Layout Image*</small>
              <small className="advisoryNoteCaption mb_10">
                (Width 348px Height 355px. Aspect Ratio: 1 : 1.02)
              </small>
              {!(roomLayoutImg && roomLayoutImg.length > 0) && !viewMedia && (
                <>
                  <Input
                    name="LayoutImage"
                    id="LayoutImage"
                    type="button"
                    accept="application/pdf"
                    multiple
                    onClick={() =>
                      showUploadWidget(
                        'LayoutImage',
                        'image',
                        null,
                        `${meetingroom_media_folder}`,
                        ['png', 'jpg', 'jpeg'],
                        null,
                        null,
                        null,
                        null,
                      )
                    }
                  />
                  <span className="imageAddIcon" style={{ top: '54px' }}>
                    <img
                      src={addImage}
                      role="presentation"
                      alt="roomLayoutImg"
                      id="BuildingImage"
                    />
                  </span>
                </>
              )}
              {roomLayoutImg && roomLayoutImg.length > 0 && (
                <>
                  <Input
                    name="BuildingImage"
                    id="BuildingImage"
                    type="file"
                    accept="application/pdf"
                    multiple
                    disabled={viewMedia}
                    onClick={() => {
                      window.open(roomLayoutImg && roomLayoutImg[0].imageUrl);
                    }}
                  />
                  <span className="imageAddIcon" style={{ top: '54px' }}>
                    <img
                      src={roomLayoutImg && roomLayoutImg[0].imageUrl}
                      alt="roomLayoutImg"
                      id="roomLayoutImg"
                    />
                  </span>
                </>
              )}
            </li>
            {popErrorType === 'min' && popErrorFieldName === 'roomLayoutImg' && (
              <FormHelperText
                className={classes.errorMsg}
                error={roomLayoutImg.length === 0 && 'Image is required.'}
              >
                {roomLayoutImg.length === 0 && 'Image is required.'}
              </FormHelperText>
            )}

            {viewMedia && !(roomLayoutImg && roomLayoutImg.length > 0) && (
              <p className="maxUpload">NO roomLayout Image</p>
            )}
          </ul>
        </ul>
      </div>
    </div>
  );
};
export default LayoutUpload;
