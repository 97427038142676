/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  Input,
  makeStyles,
  FormHelperText,
  // LinearProgress,
  Button,
} from '@material-ui/core';
// import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import addImage from '../../images/addImage.svg';
import closeIcon from '../../images/close-icon.svg';
import ManageMedia from './ManageMedia';
import ImageCroper from './ImageCroper';
import {
  CLOUDINARY_API_KEY,
  CLOUDINARY_API_SECRET_KEY,
  CLOUDINARY_UPLOAD_PRESET,
  CLOUD_NAME,
} from '../../utils/config';
import {
  event_img_312x296,
  event_img_344x352,
  event_img_375x368,
  mr_img_312x296,
  mr_img_344x352,
  mr_img_375x368,
  // loyalty_card_36x150,
  events_media_folder,
  catering_menu_media_folder,
  meetingroom_media_folder,
  offers_media_folder,
  loyalty_card_folder,
  image_title_1,
  image_title_5,
  image_title_3,
  image_title_4,
  image_title_2,
  catering_menu_300x352,
} from './constants';
import { isScalableImage } from '../../utils/constants';

const useStyles = makeStyles({
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    width: '100%',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  videoAddIcon: {
    position: 'absolute',
    top: '20px',
    left: 0,
    right: 0,
    margin: '0 auto',
    textAlign: 'center',
    height: '85px',
    width: '100%',
    lineHeight: '85px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'fill',
    },
  },
  videoAddIconView: {
    pointerEvents: 'none',
  },
});

// const theme = createMuiTheme({
//   palette: {
//     secondary: {
//       main: '#737373',
//     },
//   },
// });
const getItemStyle = draggableStyle => ({
  ...draggableStyle,
});

const FileUpload = ({
  values,
  offer,
  events,
  cateringMenu,
  loyaltyCardSupplier,
  loyaltyCardBuilding,
  loyaltyCardCoffee,
  viewMedia,
}) => {
  const {
    imgUrl,
    setImgUrl,
    images,
    setImages,
    setCompanyLogo,
    logoImages,
    setLogoImages,
    companyLogo,
    errorType,
    errorFieldName,
    errorFieldValue,
    brandedCoffeeCupLogo,
    setbrandedCoffeeCupLogo,
  } = values;
  const classes = useStyles();
  const [toggle, setToggle] = useState(false);
  const [imgIndex, setImgIndex] = useState('');
  const [cropImg, setCropImg] = useState(false);
  const [cropSize, setCropSize] = useState({});
  const [tag, setTag] = useState('');

  const imageFormatedUrl = (
    originalImage,
    sizes = '',
    scalableImage = isScalableImage,
  ) =>
    `${originalImage.split('image/upload')[0]}image/upload/${
      (scalableImage &&
        sizes &&
        `c_scale,h_${sizes.height},w_${sizes.width}/`) ||
      ''
    }f_jpg,f_auto,q_auto${originalImage.split('image/upload')[1]}`;

  const showUploadWidget = (
    e,
    resourceType,
    maxFileSize,
    folder,
    formates,
    minWidth = 344,
    maxWidth = 352,
  ) => {
    window.cloudinary.openUploadWidget(
      {
        cloudName: CLOUD_NAME,
        api_key: CLOUDINARY_API_KEY,
        api_secret: CLOUDINARY_API_SECRET_KEY,
        uploadPreset: CLOUDINARY_UPLOAD_PRESET,
        showPoweredBy: false,
        folder,
        clientAllowedFormats: formates,
        sources: [
          'local',
          'camera',
          'url',
          'facebook',
          'instagram',
          'google_drive',
        ],
        minImageWidth: minWidth,
        minImageHeight: maxWidth,
        showAdvancedOptions: false,
        cropping: false,
        multiple: false,
        resourceType,
        maxFileSize,
        defaultSource: 'local',
        styles: {
          palette: {
            window: '#FDFDFD',
            sourceBg: '#FFFFFF',
            windowBorder: '#000000',
            tabIcon: '#060606',
            inactiveTabIcon: '#82858A',
            menuIcons: '#000000',
            link: '#000000',
            action: '#000000',
            inProgress: '#000000',
            complete: '#000000',
            error: '#EA2727',
            textDark: '#000000',
            textLight: '#FFFFFF',
          },
          fonts: {
            default: null,
            "'Varta', sans-serif": {
              url: 'https://fonts.googleapis.com/css?family=Varta+Sans',
              active: true,
            },
          },
        },
      },
      (err, { event, info }) => {
        if (!err && event === 'success') {
          if (e === 'images') {
            setImages([
              {
                imageUrl: imageFormatedUrl(info.secure_url),
                tag: image_title_1,
              },
              {
                imageUrl: imageFormatedUrl(info.secure_url, event_img_312x296),
                tag: image_title_3,
              },
              {
                imageUrl: imageFormatedUrl(info.secure_url, event_img_344x352),
                tag: image_title_4,
              },
              {
                imageUrl: imageFormatedUrl(info.secure_url, event_img_375x368),
                tag: image_title_5,
              },
            ]);
          } else if (e === 'Companylogo') {
            setCompanyLogo(info.secure_url);
          } else if (e === 'cateringMenu') {
            setImages([
              {
                imageUrl: imageFormatedUrl(info.secure_url),
                tag: image_title_1,
              },
              {
                imageUrl: imageFormatedUrl(
                  info.secure_url,
                  catering_menu_300x352,
                ),
                tag: image_title_2,
              },
            ]);
          } else if (e === 'loyaltyCardSupplier') {
            setImages([
              {
                imageUrl: imageFormatedUrl(
                  info.secure_url,
                  // loyalty_card_36x150,
                  true,
                ),
                tag: image_title_2,
              },
            ]);
          } else if (e === 'loyaltyCardBuilding') {
            setLogoImages([
              {
                imageUrl: imageFormatedUrl(
                  info.secure_url,
                  // loyalty_card_36x150,
                  true,
                ),
                tag: image_title_2,
              },
            ]);
          } else if (e === 'loyaltyCardCoffeeCup') {
            setbrandedCoffeeCupLogo([
              {
                imageUrl: imageFormatedUrl(
                  info.secure_url,
                  // loyalty_card_36x150,
                  true,
                ),
                tag: image_title_2,
              },
            ]);
          } else if (e === 'Companylog') {
            setCompanyLogo(info.secure_url);
          } else if (e === 'ImageSelector') {
            setImgUrl([
              ...imgUrl,
              {
                image: [
                  {
                    imageUrl: imageFormatedUrl(info.secure_url),
                    tag: image_title_1,
                  },
                  {
                    imageUrl: imageFormatedUrl(info.secure_url, mr_img_312x296),
                    tag: image_title_3,
                  },
                  {
                    imageUrl: imageFormatedUrl(info.secure_url, mr_img_344x352),
                    tag: image_title_4,
                  },
                  {
                    imageUrl: imageFormatedUrl(info.secure_url, mr_img_375x368),
                    tag: image_title_5,
                  },
                ],
                order: imgUrl.length + 1,
              },
            ]);
          }
        }
      },
    );
  };

  const removePhoto = (e, idx) => {
    const index = imgUrl.findIndex(x => x.order === idx);
    if (index > -1) {
      const imgArray = imgUrl.filter(item => item.order !== idx);
      const spaceArray = [];
      if (imgArray && imgArray.length > 0) {
        imgArray &&
          imgArray.map((item, i) =>
            spaceArray.push({
              image: item.image,
              order: i + 1,
              name: item.name,
            }),
          );
      }
      setImgUrl(spaceArray);
    }
  };

  useEffect(() => {
    document.body.classList.toggle('modalOpen', toggle || cropImg);
  }, [toggle, cropImg]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      imgUrl,
      result.source.index,
      result.destination.index,
    );
    setImgUrl(items);
  };

  return (
    <div className="spaceFileContainer">
      {toggle && (
        <ManageMedia
          values={{
            viewMedia,
            setCropSize,
            cropImg,
            toggle,
            setToggle,
            setCropImg,
            imgIndex,
            setTag,
            cateringMenu,
            loyaltyCardSupplier,
            loyaltyCardBuilding,
          }}
        />
      )}
      {cropImg && (
        <ImageCroper
          values={{
            cropSize,
            setCropSize,
            cropImg,
            setCropImg,
            toggle,
            setToggle,
            imgIndex,
            setImgIndex,
            tag,
            setTag,
            images,
            setImages,
            imgUrl,
            setImgUrl,
          }}
        />
      )}
      <ul className="MultipleImgUpload">
        {!offer && (
          <>
            {(!viewMedia && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {provided => (
                    <ul
                      className="MultipleImgUpload imgUploadVideo"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {imgUrl &&
                        imgUrl.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={String(index)}
                            index={index}
                          >
                            {provide => (
                              <>
                                <li
                                  ref={provide.innerRef}
                                  {...provide.draggableProps}
                                  {...provide.dragHandleProps}
                                  style={getItemStyle(
                                    provide &&
                                      provide.draggableProps &&
                                      provide.draggableProps.style,
                                  )}
                                >
                                  {!viewMedia && (
                                    <i className="removeImage">
                                      <img
                                        src={closeIcon}
                                        alt="closeIcon"
                                        role="presentation"
                                        id={index}
                                        onClick={e => {
                                          removePhoto(e, item.order);
                                        }}
                                      />
                                    </i>
                                  )}
                                  <small className="imageAddName">
                                    {index === 0
                                      ? 'Hero Image'
                                      : `Image ${index + 1}`}
                                  </small>
                                  <span className="imageAddIconUpload">
                                    <img
                                      src={item && item.image[0].imageUrl}
                                      alt="addImage"
                                      role="presentation"
                                      id={index}
                                      onClick={() =>
                                        !viewMedia &&
                                        window.open(
                                          item && item.image[0].imageUrl,
                                        )
                                      }
                                    />
                                  </span>
                                  <Button
                                    className="cropBtn"
                                    onClick={() => {
                                      setToggle(!toggle);
                                      if (cropImg) setCropImg(!cropImg);
                                      setImgIndex({
                                        order: item.order,
                                        images: item.image,
                                        imageUrl:
                                          item && item.image[0].imageUrl,
                                        mediaType: 'meetingRoom',
                                      });
                                    }}
                                  >
                                    {' '}
                                    {viewMedia ? 'View' : ' View / Edit'}
                                  </Button>
                                </li>
                              </>
                            )}
                          </Draggable>
                        ))}
                      {!viewMedia && imgUrl && imgUrl.length < 10 && (
                        <>
                          <li>
                            <small className="imageAddName">Imagery *</small>
                            {/* {imageLoader ? (
                                <MuiThemeProvider theme={theme}>
                                  <LinearProgress color="secondary" />
                                </MuiThemeProvider>
                              ) : ( */}
                            <>
                              <Input
                                name="ImageSelector"
                                id="ImageSelector"
                                type="button"
                                accept="image/x-png,image/gif,image/jpeg"
                                multiple
                                onClick={() =>
                                  showUploadWidget(
                                    'ImageSelector',
                                    'image',
                                    null,
                                    meetingroom_media_folder,
                                    ['png', 'jpg', 'jpeg'],
                                    null,
                                    null,
                                    null,
                                  )
                                }
                              />
                              <span className="imageAddIcon">
                                <img src={addImage} alt="addImage" />
                              </span>
                            </>
                            {/* )} */}
                            {((errorType === 'min' &&
                              errorFieldName === 'imageUploadValidation') ||
                              (errorFieldValue &&
                                errorFieldValue.imageUploadValidation.length ===
                                  0)) && (
                              <FormHelperText
                                className={classes.errorMsg}
                                error={
                                  imgUrl.length === 0 && 'Imagery is required.'
                                }
                              >
                                {imgUrl.length === 0 && 'Imagery is required.'}
                              </FormHelperText>
                            )}

                            {/* <small className="uploadsize">
                                Maximum file size 1MB, 1080px by 1080px
                              </small> */}
                          </li>
                          <p className="maxUpload">
                            You may upload a maximum of 10 images
                          </p>
                        </>
                      )}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            )) || (
              <ul className="MultipleImgUpload imgUploadVideo">
                {imgUrl &&
                  imgUrl.map((item, index) => {
                    return (
                      <li>
                        {!viewMedia && (
                          <i className="removeImage">
                            <img
                              src={closeIcon}
                              alt="closeIcon"
                              role="presentation"
                              id={index}
                              onClick={e => {
                                removePhoto(e, item.order);
                              }}
                            />
                          </i>
                        )}
                        <small className="imageAddName">
                          {index === 0 ? 'Hero Image' : `Image ${index + 1}`}
                        </small>
                        <span className="imageAddIconUpload">
                          <img
                            src={item && item.image[0].imageUrl}
                            alt="addImage"
                            role="presentation"
                            id={index}
                            onClick={() =>
                              !viewMedia &&
                              window.open(item && item.image[0].imageUrl)
                            }
                          />
                        </span>
                        <Button
                          className="cropBtn"
                          onClick={() => {
                            setToggle(!toggle);
                            if (cropImg) setCropImg(!cropImg);
                            setImgIndex({
                              order: item.order,
                              images: item.image,
                              imageUrl: item && item.image[0].imageUrl,
                              mediaType: 'meetingRoom',
                            });
                          }}
                        >
                          {' '}
                          {viewMedia ? 'View' : ' View / Edit'}
                        </Button>
                      </li>
                    );
                  })}
                {!viewMedia && imgUrl && imgUrl.length < 5 && (
                  <>
                    <li>
                      <small className="imageAddName">Imagery *</small>
                      {/* {imageLoader ? (
                    <MuiThemeProvider theme={theme}>
                      <LinearProgress color="secondary" />
                    </MuiThemeProvider>
                  ) : ( */}
                      <>
                        <Input
                          name="ImageSelector"
                          id="ImageSelector"
                          type="button"
                          accept="image/x-png,image/gif,image/jpeg"
                          multiple
                          onClick={() =>
                            showUploadWidget(
                              'ImageSelector',
                              'image',
                              null,
                              meetingroom_media_folder,
                              ['png', 'jpg', 'jpeg'],
                            )
                          }
                        />
                        <span className="imageAddIcon">
                          <img src={addImage} alt="addImage" />
                        </span>
                      </>
                      {/* )} */}
                      {((errorType === 'min' &&
                        errorFieldName === 'imageUploadValidation') ||
                        (errorFieldValue &&
                          errorFieldValue.imageUploadValidation.length ===
                            0)) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          error={imgUrl.length === 0 && 'Imagery is required.'}
                        >
                          {imgUrl.length === 0 && 'Imagery is required.'}
                        </FormHelperText>
                      )}

                      {/* <small className="uploadsize">
                    Maximum file size 1MB, 1080px by 1080px
                  </small> */}
                    </li>
                    <p className="maxUpload">
                      You may upload a maximum of 10 images
                    </p>
                  </>
                )}
              </ul>
            )}
          </>
        )}
        {cateringMenu && (
          <li>
            <small className="imageAddName">Catering Image</small>
            {images && images.length > 0 && (
              <>
                <Input
                  disabled={viewMedia}
                  name="cateringMenu"
                  id="cateringMenu"
                  type="file"
                  multiple
                  onClick={() => {
                    window.open(images && images[1].imageUrl);
                  }}
                />
                <span className="imageAddIcon">
                  <img src={images && images[1].imageUrl} alt="addImage" />
                </span>
                <Button
                  className="cropBtn"
                  onClick={() => {
                    setToggle(!toggle);
                    if (cropImg) setCropImg(!cropImg);
                    setImgIndex({
                      order: 1,
                      images,
                      imageUrl: images && images[0].imageUrl,
                      mediaType: 'cateringMenu',
                    });
                  }}
                >
                  {' '}
                  {viewMedia ? 'View' : ' View / Edit'}
                </Button>
              </>
            )}
            {images && images.length > 0 && !viewMedia && (
              <i className="removeImage">
                <img
                  src={closeIcon}
                  alt="closeIcon"
                  role="presentation"
                  onClick={() => {
                    setImages([]);
                  }}
                />
              </i>
            )}
            {images && images.length === 0 && (
              <>
                <Input
                  disabled={viewMedia}
                  name="cateringMenu"
                  id="cateringMenu"
                  type="button"
                  multiple
                  onClick={() =>
                    showUploadWidget(
                      'cateringMenu',
                      'image',
                      null,
                      catering_menu_media_folder,
                      ['png', 'jpg', 'jpeg'],
                      null,
                      null,
                      null,
                    )
                  }
                />
                <span className="imageAddIcon">
                  <img src={addImage} alt="addImage" />
                </span>
              </>
            )}
          </li>
        )}
        {loyaltyCardSupplier && (
          <li>
            <small className="imageAddName">Supplier Logo*</small>
            {images && images.length > 0 && (
              <>
                <Input
                  disabled={viewMedia}
                  name="loyaltyCardSupplier"
                  id="loyaltyCardSupplier"
                  type="file"
                  multiple
                  onClick={() => {
                    window.open(images && images[0].imageUrl);
                  }}
                />
                <span className="imageAddIcon">
                  <img src={images && images[0].imageUrl} alt="addImage" />
                </span>
                <Button
                  className="cropBtn"
                  onClick={() => {
                    setToggle(!toggle);
                    if (cropImg) setCropImg(!cropImg);
                    setImgIndex({
                      order: 1,
                      images,
                      imageUrl: images && images[0].imageUrl,
                      mediaType: 'loyaltyCardSupplier',
                    });
                  }}
                >
                  {' '}
                  View
                </Button>
              </>
            )}
            {images && images.length > 0 && !viewMedia && (
              <i className="removeImage">
                <img
                  src={closeIcon}
                  alt="closeIcon"
                  role="presentation"
                  onClick={() => {
                    setImages([]);
                  }}
                />
              </i>
            )}
            {images && images.length === 0 && (
              <>
                <Input
                  disabled={viewMedia}
                  name="loyaltyCardSupplier"
                  id="loyaltyCardSupplier"
                  type="button"
                  multiple
                  onClick={() =>
                    showUploadWidget(
                      'loyaltyCardSupplier',
                      'image',
                      null,
                      loyalty_card_folder,
                      ['png', 'jpg', 'jpeg'],
                      null,
                      10,
                      10,
                    )
                  }
                />
                <span className="imageAddIcon">
                  <img src={addImage} alt="addImage" />
                </span>
                {((errorType === 'min' && errorFieldName === 'supplierImage') ||
                  (errorFieldValue && !errorFieldValue.supplierImage) ||
                  (errorFieldValue &&
                    errorFieldValue.supplierImage &&
                    errorFieldValue.supplierImage.length === 0)) && (
                  <FormHelperText className={classes.errorMsg}>
                    {images &&
                      images.length === 0 &&
                      loyaltyCardSupplier &&
                      'Supplier Logo is required.'}
                  </FormHelperText>
                )}
              </>
            )}
          </li>
        )}
        {loyaltyCardBuilding && (
          <li>
            <small className="imageAddName">Building Logo*</small>
            {logoImages && logoImages.length > 0 && (
              <>
                <Input
                  disabled={viewMedia}
                  name="loyaltyCardBuilding"
                  id="loyaltyCardBuilding"
                  type="file"
                  multiple
                  onClick={() => {
                    window.open(logoImages && logoImages[0].imageUrl);
                  }}
                />
                <span className="imageAddIcon">
                  <img
                    src={logoImages && logoImages[0].imageUrl}
                    alt="addImage"
                  />
                </span>
                <Button
                  className="cropBtn"
                  onClick={() => {
                    setToggle(!toggle);
                    if (cropImg) setCropImg(!cropImg);
                    setImgIndex({
                      order: 1,
                      logoImages,
                      imageUrl: logoImages && logoImages[0].imageUrl,
                      mediaType: 'loyaltyCardBuilding',
                    });
                  }}
                >
                  {' '}
                  View
                </Button>
              </>
            )}
            {logoImages && logoImages.length > 0 && !viewMedia && (
              <i className="removeImage">
                <img
                  src={closeIcon}
                  alt="closeIcon"
                  role="presentation"
                  onClick={() => {
                    setLogoImages([]);
                  }}
                />
              </i>
            )}
            {logoImages && logoImages.length === 0 && (
              <>
                <Input
                  disabled={viewMedia}
                  name="loyaltyCardBuilding"
                  id="loyaltyCardBuilding"
                  type="button"
                  multiple
                  onClick={() =>
                    showUploadWidget(
                      'loyaltyCardBuilding',
                      'image',
                      null,
                      loyalty_card_folder,
                      ['png', 'jpg', 'jpeg'],
                      null,
                      10,
                      10,
                    )
                  }
                />
                <span className="imageAddIcon">
                  <img src={addImage} alt="addImage" />
                </span>
                {((errorType === 'min' && errorFieldName === 'buildingImage') ||
                  (errorFieldValue && !errorFieldValue.buildingImage) ||
                  (errorFieldValue &&
                    errorFieldValue.buildingImage &&
                    errorFieldValue.buildingImage.length === 0)) && (
                  <FormHelperText className={classes.errorMsg}>
                    {logoImages &&
                      logoImages.length === 0 &&
                      loyaltyCardBuilding &&
                      'Building Logo is required.'}
                  </FormHelperText>
                )}
              </>
            )}
          </li>
        )}

        {loyaltyCardCoffee && (
          <li>
            <small className="imageAddName">Coffee Logo</small>
            {brandedCoffeeCupLogo && brandedCoffeeCupLogo.length > 0 && (
              <>
                <Input
                  disabled={viewMedia}
                  name="loyaltyCardCoffeeCup"
                  id="loyaltyCardCoffeeCup"
                  type="file"
                  multiple
                  onClick={() => {
                    window.open(
                      brandedCoffeeCupLogo &&
                        brandedCoffeeCupLogo.length &&
                        brandedCoffeeCupLogo[0].imageUrl,
                    );
                  }}
                />
                <span className="imageAddIcon">
                  <img
                    src={
                      brandedCoffeeCupLogo &&
                      brandedCoffeeCupLogo.length &&
                      brandedCoffeeCupLogo[0].imageUrl
                    }
                    alt="addImage"
                  />
                </span>
                <Button
                  className="cropBtn"
                  onClick={() => {
                    setToggle(!toggle);
                    if (cropImg) setCropImg(!cropImg);
                    setImgIndex({
                      order: 1,
                      brandedCoffeeCupLogo,
                      imageUrl:
                        brandedCoffeeCupLogo &&
                        brandedCoffeeCupLogo.length &&
                        brandedCoffeeCupLogo[0].imageUrl,
                      mediaType: 'loyaltyCardCoffeeCup',
                    });
                  }}
                >
                  {' '}
                  View
                </Button>
              </>
            )}
            {brandedCoffeeCupLogo &&
              brandedCoffeeCupLogo.length > 0 &&
              !viewMedia && (
                <i className="removeImage">
                  <img
                    src={closeIcon}
                    alt="closeIcon"
                    role="presentation"
                    onClick={() => {
                      setbrandedCoffeeCupLogo([]);
                    }}
                  />
                </i>
              )}
            {brandedCoffeeCupLogo && brandedCoffeeCupLogo.length === 0 && (
              <>
                <Input
                  disabled={viewMedia}
                  name="loyaltyCardCoffeeCup"
                  id="loyaltyCardCoffeeCup"
                  type="button"
                  multiple
                  onClick={() =>
                    showUploadWidget(
                      'loyaltyCardCoffeeCup',
                      'image',
                      null,
                      loyalty_card_folder,
                      ['png', 'jpg', 'jpeg'],
                      null,
                      10,
                      10,
                    )
                  }
                />
                <span className="imageAddIcon">
                  <img src={addImage} alt="addImage" />
                </span>
              </>
            )}
          </li>
        )}

        {offer && (
          <>
            <li>
              <small className="imageAddName">
                {(viewMedia && !events && 'Offer Image') ||
                  (viewMedia && events && 'Event Image') ||
                  (offer
                    ? (events && 'Event Image*') || 'Offer Image*'
                    : 'Preview Image')}
              </small>
              {images && images.length > 0 && (
                <>
                  <Input
                    disabled={viewMedia}
                    name="Offer Image"
                    id="images"
                    type="file"
                    multiple
                    onClick={() => {
                      window.open(images && images[0].imageUrl);
                    }}
                  />
                  <span className="imageAddIcon">
                    <img src={images && images[0].imageUrl} alt="addImage" />
                  </span>
                  <Button
                    className="cropBtn"
                    onClick={() => {
                      setToggle(!toggle);
                      if (cropImg) setCropImg(!cropImg);
                      setImgIndex({
                        order: 1,
                        images,
                        imageUrl: images && images[0].imageUrl,
                        mediaType: 'images',
                      });
                    }}
                  >
                    {' '}
                    {viewMedia ? 'View' : ' View / Edit'}
                  </Button>
                </>
              )}
              {images && images.length > 0 && !viewMedia && (
                <i className="removeImage">
                  <img
                    src={closeIcon}
                    alt="closeIcon"
                    role="presentation"
                    onClick={() => {
                      setImages([]);
                    }}
                  />
                </i>
              )}
              {/* {offerImageLoader ? (
                <MuiThemeProvider theme={theme}>
                  <LinearProgress color="secondary" />
                </MuiThemeProvider>
              ) : ( */}
              {images && images.length === 0 && (
                <>
                  <Input
                    disabled={viewMedia}
                    name="Offer Image"
                    id="images"
                    type="button"
                    multiple
                    onClick={() =>
                      showUploadWidget(
                        'images',
                        'image',
                        null,
                        `${events ? events_media_folder : offers_media_folder}`,
                        ['png', 'jpg', 'jpeg'],
                        null,
                        null,
                        null,
                      )
                    }
                  />
                  <span className="imageAddIcon">
                    <img src={addImage} alt="addImage" />
                  </span>
                  {/* {!viewMedia && (
                    <small className="uploadsize">
                      Maximum file size 1MB, 1080px by 1080px
                    </small>
                  )} */}
                </>
              )}
              {/* )} */}
              {((errorType === 'min' &&
                errorFieldName === 'offerImageValidation') ||
                (errorFieldValue && !errorFieldValue.offerImageValidation) ||
                (errorFieldValue &&
                  errorFieldValue.offerImageValidation &&
                  errorFieldValue.offerImageValidation.length === 0)) && (
                <FormHelperText className={classes.errorMsg}>
                  {(images &&
                    images.length === 0 &&
                    !events &&
                    'Offer Image is required.') ||
                    (images &&
                      images.length === 0 &&
                      events &&
                      'Event Image is required.')}
                </FormHelperText>
              )}
            </li>
            {!events && (
              <li>
                <small className="imageAddName">
                  {(viewMedia && 'Company Logo') || 'Company Logo*'}
                </small>
                {companyLogo && (
                  <>
                    <Input
                      disabled={viewMedia}
                      name="Offer Image"
                      id="images"
                      type="file"
                      multiple
                      onClick={() => {
                        window.open(companyLogo);
                      }}
                    />
                    <span className="imageAddIcon">
                      <img src={companyLogo} alt="addImage" />
                    </span>
                  </>
                )}
                {companyLogo && !viewMedia && (
                  <i className="removeImage">
                    <img
                      src={closeIcon}
                      alt="closeIcon"
                      role="presentation"
                      onClick={() => {
                        setCompanyLogo('');
                      }}
                    />
                  </i>
                )}
                {/* {companyLoader ? (
                  <MuiThemeProvider theme={theme}>
                    <LinearProgress color="secondary" />
                  </MuiThemeProvider>
                ) : ( */}
                {!companyLogo && (
                  <>
                    <Input
                      disabled={viewMedia}
                      name="Company logo"
                      id="Companylogo"
                      type="button"
                      multiple
                      onClick={() =>
                        showUploadWidget(
                          'Companylogo',
                          'image',
                          null,
                          meetingroom_media_folder,
                          ['png'],
                          null,
                          null,
                          null,
                        )
                      }
                    />
                    <span className="imageAddIcon">
                      <img src={addImage} alt="addImage" />
                    </span>
                    {/* {!viewMedia && (
                      <small className="uploadsize">
                        Maximum file size 1MB, 1080px by 1080px
                      </small>
                    )} */}
                  </>
                )}
                {/* )} */}
                {((errorType === 'required' &&
                  errorFieldName === 'companyLogoValidation') ||
                  (errorFieldValue &&
                    errorFieldValue.companyLogoValidation === '')) && (
                  <FormHelperText
                    className={classes.errorMsg}
                    error={companyLogo === '' && 'Company logo is required.'}
                  >
                    {companyLogo === '' && 'Company logo is required.'}
                  </FormHelperText>
                )}
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  );
};
export default FileUpload;
