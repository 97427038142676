/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';
import minus from '../../images/minus.svg';
import useFilterStyles from '../Styles/useFilterStyles';

const TenantsFilter = ({
  dropDownFields,
  selectAll,
  selectedTenants,
  setSelectedTenants,
  errorType,
  errorFieldName,
  errorFieldValue,
  setSelectedTenantsId,
  selectedTenantsId,
  id,
}) => {
  const classes = useFilterStyles();
  const [additionalInformationSearch, setAdditionalInformationSearch] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [additionalInformationTab, setAdditionalInformationTab] = useState('');
  const [checked, setIschecked] = useState(true);

  const onTenantsSelection = (e, fieldId) => {
    if (e.target.checked) {
      setSelectedTenants([...selectedTenants, fieldId]);
      setSelectedTenantsId([...selectedTenantsId, fieldId]);
      setIschecked(!checked);
    } else {
      const difference = selectedTenants.filter(d => d !== fieldId);
      setSelectedTenants(difference);
      const differenceForId = selectedTenants.filter(d => d !== fieldId);
      setSelectedTenantsId(differenceForId);
      setIschecked(!checked);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAdditionalInformationSearch(null);
    setAdditionalInformationTab(false);
  };
  const onSelectAll = () => {
    const allFields = dropDownFields.map(field => field.id);
    setSelectedTenants([...allFields]);
    const allFieldsId = dropDownFields.map(field => field.id);
    setSelectedTenantsId(allFieldsId);
    setIschecked(!checked);
  };
  const onDeSelectAll = () => {
    setSelectedTenants([]);
    setSelectedTenantsId([]);
  };

  return (
    <div className="offerfilterRgt">
      <ul className={`${classes.leftFilterIn} leftFilterIn`}>
        <li
          className={
            additionalInformationTab === true ||
            (selectedTenants && selectedTenants.length > 0)
              ? `${classes.active} ${
                  selectedTenants && selectedTenants.length > 0
                    ? classes.valueSelected
                    : ''
                }`
              : ''
          }
        >
          <Button
            className="buildSelectedBtn"
            aria-describedby={id}
            variant="contained"
            onClick={event => {
              setAdditionalInformationSearch(true);
              setAnchorEl(event.currentTarget);
              setAdditionalInformationTab(true);
            }}
          >
            {selectedTenants.length > 0
              ? (selectedTenants.length === 1 &&
                  `${selectedTenants.length} Tenant Selected`) ||
                `${selectedTenants.length} Tenants Selected`
              : 'Select Tenant(s)'}
            <small>
              <img src={plus} alt="plus" />
              <img src={minus} alt="minus" />
            </small>
          </Button>
          {((errorType === 'min' && errorFieldName === 'tenantValidation') ||
            (errorFieldValue &&
              errorFieldValue.tenantValidation &&
              errorFieldValue.tenantValidation.length === 0)) &&
            !selectedTenants.length > 0 && (
              <FormHelperText className={classes.errorMsg}>
                At least one tenant is required
              </FormHelperText>
            )}

          <i
            className="closeIconBtn"
            role="presentation"
            onClick={() => {
              setSelectedTenants([]);
              setSelectedTenantsId([]);
              setAnchorEl('');
            }}
          >
            <img src={close} alt="close" />
          </i>

          <Popover
            id="offer-category"
            open={additionalInformationSearch}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div className="filterDropdown filterDropdownCheck offerFilterDropdownCheck">
              <div className="filterDropdownin">
                {dropDownFields && (
                  <List className={classes.moreList}>
                    <div className="selectAllWrap">
                      <h4>Select Tenant(s) name</h4>
                      {selectAll &&
                      dropDownFields.length !== selectedTenants.length ? (
                        <Button className="selectAllLoc" onClick={onSelectAll}>
                          Select All
                        </Button>
                      ) : (
                        selectAll && (
                          <Button
                            className="selectAllLoc"
                            onClick={onDeSelectAll}
                          >
                            Deselect All
                          </Button>
                        )
                      )}
                    </div>
                    {dropDownFields.map(field => (
                      <>
                        <ListItem key={field.id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={field.id}
                                checked={
                                  selectedTenants &&
                                  selectedTenants.includes(field.id)
                                }
                                onChange={e => {
                                  onTenantsSelection(e, field.id);
                                }}
                              />
                            }
                            label={field.name}
                          />
                        </ListItem>
                      </>
                    ))}
                  </List>
                )}
              </div>
            </div>
          </Popover>
        </li>
      </ul>
    </div>
  );
};

export default TenantsFilter;
