import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  container: {
    height: 'calc(100vh - 120px)',
    width: '100vw',
    marginTop: '100px',
  },
});

const AnalyticsHome = () => {
  const classes = useStyles();


  return (
    <div className={classes.container}>
      <iframe width="100%" height="100%" title="Visitor Dashboard" src="https://app.powerbi.com/reportEmbed?reportId=7e3bb253-f729-4d52-9b52-e8f2f11fd2e4&autoAuth=true&ctid=a6a46f35-b717-43b1-90c8-4d503ab22b8f&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVrLXNvdXRoLWItcHJpbWFyeS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldC8ifQ%3D%3D" frameBorder="0" allowFullScreen="true" />
    </div>
  )
};

export default AnalyticsHome;
