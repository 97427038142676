/* eslint-disable import/no-cycle */
import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment';
import ManageVoucherList from '../containers/ManageVoucher/ManageVoucherList';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';

export const ManageVoucherContext = createContext({});

export const ManageVoucherContextProvider = () => {
  const [manageVoucherList, setManageVoucherList] = useState([]);
  const [voucherSearch, setVoucherSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [offset, setOffset] = useState(0);
  const [totalVouchersCount, settotalVouchersCount] = useState(0);
  const [selectedDiscountType, setSelectedDiscountType] = useState([]);
  const [statuses, setStatuses] = useState([
    { label: 'Active', key: 'Active', selected: false },
    { label: 'Inactive', key: 'Inactive', selected: false },
  ]);
  const [discountTypes, setDiscountTypes] = useState([
    { label: 'Value', key: 'Value', selected: false },
    { label: 'Percentage', key: 'Percentage', selected: false },
  ]);

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [statusActive, setStatusActive] = useState('');
  const [statusInactive, setStatusInactive] = useState('');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [sortBy, setSortBy] = useState('discount');

  const startDateApiReq = `${moment
    .utc(selectedStartDate)
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)}`;

  const endDateApiReq = `${moment
    .utc(selectedEndDate)
    .set('hours', 23)
    .set('minutes', 59)
    .set('seconds', 0)}`;

  const getVoucherListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getVoucherCode, payload)
      .then(data => {
        setManageVoucherList(data && data.data && data.data.data);
        settotalVouchersCount(data && data.data.count);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  useEffect(() => {
    const updateddiscountType = [];
    discountTypes.filter(discountType => {
      if (discountType.selected) {
        updateddiscountType.push(discountType.key);
      }
      return discountType.selected !== false;
    });
    setSelectedDiscountType(updateddiscountType);

    const statusUpdate = [];
    statuses.filter(status => {
      if (status.selected) {
        statusUpdate.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedStatus(statusUpdate);
    getVoucherListAction({
      searchText: voucherSearch.length > 2 ? voucherSearch : undefined,
      startDate:
        selectedStartDate && selectedEndDate
          ? moment(startDateApiReq).toISOString()
          : undefined,
      endDate:
        selectedStartDate && selectedEndDate
          ? moment(endDateApiReq).toISOString()
          : undefined,
      type:
        updateddiscountType && updateddiscountType.length > 0
          ? updateddiscountType
          : undefined,
      sortOrder,
      sortBy,
      status:
        statusUpdate && statusUpdate.length > 0 ? statusUpdate : ['Active'],
      offset,
      limit: 40,
    });
  }, [
    voucherSearch,
    offset,
    sortOrder,
    sortBy,
    selectedStartDate,
    selectedEndDate,
    statuses,
    discountTypes,
  ]);

  const voucherTypes = {
    oneTimeUse: 'oneTimeUse',
    unlimitedUse: 'unlimitedUse',
  };

  return (
    <ManageVoucherContext.Provider
      value={{
        manageVoucherList,
        setManageVoucherList,
        getVoucherListAction,
        loading,
        setLoading,
        voucherSearch,
        setVoucherSearch,
        page,
        setPage,
        setOffset,
        totalVouchersCount,
        settotalVouchersCount,
        statuses,
        setStatuses,
        selectedStatus,
        setSelectedStatus,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        statusActive,
        setStatusActive,
        statusInactive,
        setStatusInactive,
        selectedDiscountType,
        setSelectedDiscountType,
        discountTypes,
        setDiscountTypes,
        setSortOrder,
        sortOrder,
        setSortBy,
        sortBy,
        voucherTypes,
      }}
    >
      <ManageVoucherList />
    </ManageVoucherContext.Provider>
  );
};
