/* eslint-disable import/no-cycle */
import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';
import BookingList from '../containers/MeetingRoomBooking/BookingList';

export const BookingContext = createContext({});

export const BookingContextProvider = props => {
  const { embedded, userId } = props;

  const [bookingUsersList, setBookingUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [exportList, setExportList] = useState(false);
  const [bookingSearchId, setBookingSearchId] = useState(undefined);
  const [bookingSearch, setBookingSearch] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [villages, setVillages] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [payAccountSelected, setPayAccountSelected] = useState('');
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [totalBookingCount, setTotalBookingCount] = useState(0);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('startDate');
  const [fullDay, setFullDay] = useState(false);
  const [vat, setVat] = useState('');
  const [statuses, setStatuses] = useState([
    { label: 'Booked', key: 'Booked', selected: false },
    { label: 'Pending', key: 'Pending', selected: false },
    { label: 'Cancelled', key: 'Cancelled', selected: false },
    { label: 'No Show', key: 'NoShow', selected: false },
  ]);
  const [payAccountOptions, setPayAccountOptions] = useState([
    { label: 'Paid by Card', key: 'Card', selected: false },
    { label: 'Pay to Account', key: 'PayByAccount', selected: false },
    { label: 'Terminal', key: 'Pos', selected: false },
  ]);
  const startDateApi = `${moment(selectedStartDate)
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)}`;
  const endDateApi = `${moment(selectedEndDate)
    .set('hours', 23)
    .set('minutes', 59)
    .set('seconds', 0)}`;

  // get Booking List export list API Call
  const getBookingListActionExport = payload => {
    ApiClient.apiPut(apiEndPoints.exportList, payload)
      .then(data => {
        const link = document.createElement('a');
        link.href = data && data.data.data.link;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  useEffect(() => {
    const statusUpdate = [];
    const payToAccountUpdate = [];
    statuses.filter(status => {
      if (status.selected) {
        statusUpdate.push(status.key);
      }
      return status.selected !== false;
    });
    payAccountOptions.filter(account => {
      if (account.selected) {
        payToAccountUpdate.push(account.key);
      }
      return account.selected !== false;
    });
    setSelectedStatus(statusUpdate);
    setPayAccountSelected(payToAccountUpdate);
    if (exportList) {
      getBookingListActionExport({
        searchText: bookingSearch.length > 2 ? bookingSearch : undefined,
        tenantId: undefined,
        isMainBooking: true,
        buildings: buildings && buildings.length > 0 ? buildings : undefined,
        startDate:
          selectedStartDate && selectedEndDate
            ? moment(startDateApi).toISOString()
            : undefined,
        endDate:
          selectedStartDate && selectedEndDate
            ? moment(endDateApi).toISOString()
            : undefined,
        sortOrder,
        paymentMethod:
          payToAccountUpdate && payToAccountUpdate.length > 0
            ? payToAccountUpdate
            : undefined,
        sortBy,
        status:
          statusUpdate && statusUpdate.length > 0
            ? statusUpdate
            : ['Booked', 'NoShow', 'Cancelled'],
        offset: 0,
        limit: 2000,
      });
    }
  }, [exportList]);

  // get Booking List API Call
  const getBookingListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getBookingList, payload)
      .then(data => {
        setBookingUsersList('');
        setBookingUsersList(data?.data?.data);
        setTotalBookingCount(data?.data?.count);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  useEffect(() => {
    const statusUpdate = [];
    const payToAccountUpdate = [];
    statuses.filter(status => {
      if (status.selected) {
        statusUpdate.push(status.key);
      }
      return status.selected !== false;
    });
    payAccountOptions.filter(account => {
      if (account.selected) {
        payToAccountUpdate.push(account.key);
      }
      return account.selected !== false;
    });
    setSelectedStatus(statusUpdate);
    setPayAccountSelected(payToAccountUpdate);
    getBookingListAction({
      searchText: bookingSearch.length > 2 ? bookingSearch : undefined,
      tenantId: undefined,
      isMainBooking: true,
      buildings: buildings && buildings.length > 0 ? buildings : undefined,
      startDate:
        selectedStartDate && selectedEndDate
          ? moment(startDateApi).toISOString()
          : undefined,
      endDate:
        selectedStartDate && selectedEndDate
          ? moment(endDateApi).toISOString()
          : undefined,
      // startDate:'',
      // endDate:'',
      sortOrder,
      paymentMethod:
        payToAccountUpdate && payToAccountUpdate.length > 0
          ? payToAccountUpdate
          : undefined,
      sortBy,
      status:
        statusUpdate && statusUpdate.length > 0
          ? statusUpdate
          : ['Booked', 'NoShow', 'Cancelled'],
      offset,
      limit: 40,
      userId: userId,
    });
  }, [
    buildings,
    bookingSearch,
    selectedStartDate && selectedEndDate,
    sortOrder,
    sortBy,
    statuses,
    offset,
    payAccountOptions,
  ]);

  return (
    <BookingContext.Provider
      value={{
        bookingUsersList,
        setBookingUsersList,
        loading,
        setLoading,
        bookingSearch,
        setBookingSearch,
        bookingSearchId,
        setBookingSearchId,
        buildings,
        setBuildings,
        villages,
        setVillages,
        setStatuses,
        statuses,
        payAccountOptions,
        setPayAccountOptions,
        payAccountSelected,
        setPayAccountSelected,
        setSelectedStatus,
        selectedStatus,
        totalBookingCount,
        setTotalBookingCount,
        offset,
        setOffset,
        page,
        setPage,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        exportList,
        setExportList,
        discountPrice,
        setDiscountPrice,
        fullDay,
        setFullDay,
        vat,
        setVat,
      }}
    >
      <BookingList embedded={embedded} />
    </BookingContext.Provider>
  );
};
