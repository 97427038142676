/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Input,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import LocationFilter from '../../components/Filters/LocationFilter';
import exportLogo from '../../images/export.svg';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';
import minus from '../../images/minus.svg';
import { TenentContext } from '../../context/TententUserContext';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import Pagination from '../../components/Pagination/Pagination';
import { GlobalContext } from '../../context/GlobalContext';
import useFilterStyles from '../../components/Styles/useFilterStyles';

const TenentFilterSearch = () => {
  const {
    setTenantsSearch,
    searchTenants,
    setSearhTenants,
    tenants,
    handleClick,
    setBuildings,
    setVillages,
    setPayAccountTrue,
    setPayAccountFalse,
    setTenanSearchId,
    setSupportMarker,
    setNewStatus,
    newStatus,
    setStatusInactive,
    setStatusBlocked,
    setStatusActive,
    roleOptions,
    setRoleOptions,
    selectedRoles,
    selectedStatus,
    payAccountOptions,
    setPayAccountOptions,
    payAccountSelected,
    setPayAccountSelectede,
    tenantUserSearch,
    setTenantUserSearch,
    customerSearch,
    setCustomerSearch,
    buildings,
    villages,
    page,
    setPage,
    setOffset,
    totalTenants,
    setExportList,
  } = useContext(TenentContext);
  const classes = useFilterStyles();
  const { rolePermission, buildingGroupsList } = useContext(GlobalContext);
  const [locationSearch, setLocationSearch] = useState(false);
  const [roleSearch, setRoleSearch] = useState(false);
  const [moreSearch, setMoreSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [onCheckTenantButton, setOnTenantCheckButton] = useState('');
  const [onCheckMoreButton, setOnCheckMoreButton] = useState('');
  const [OnCheckedRoleButton, setOnCheckedRoleButton] = useState('');
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const placeholder = 'Type a name or email address';

  const searchResult =
    searchTenants === '' || searchTenants.length <= 2 ? (
      ''
    ) : (
      <span>No Result Found</span>
    );

  const exportCsv = () => {
    setTimeout(() => {
      setExportList(true);
    }, 1000);
    setExportList(false);
  };

  const newRoleOptions = (selected, key) => {
    const slots = roleOptions.map(role => {
      if (role && role.key === key) {
        role.selected = selected;
      }
      return role;
    });
    setRoleOptions(slots);
  };

  const newStatusOptions = (selected, key) => {
    const statusnew = newStatus.map(status => {
      if (status && status.key === key) {
        status.selected = selected;
      }
      return status;
    });
    setNewStatus(statusnew);
  };

  const payToAccountOptions = (selected, key) => {
    const paynew = payAccountOptions.map(acccount => {
      if (acccount && acccount.key === key) {
        acccount.selected = selected;
      }
      return acccount;
    });
    setPayAccountOptions(paynew);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTenantUserSearch(null);
    setLocationSearch(false);
    setRoleSearch(false);
    setMoreSearch(false);
    setOnTenantCheckButton(false);
    setOnCheckMoreButton(false);
    setOnCheckedRoleButton(false);
    setOnCheckedLocationButton(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const ClearFilter = () => {
    setRoleOptions([
      { label: 'Super Administrator', key: 'Super Administrator', selected: false },
      { label: 'Employee', key: 'Employee', selected: false },
    ]);
    setNewStatus([
      { label: 'Active', key: 'Active', selected: false },
      { label: 'Inactive', key: 'Inactive', selected: false },
      { label: 'Blocked', key: 'Blocked', selected: false },
      { label: 'Renewal Pending', key: 'Renewal Pending', selected: false },
    ]);
    setPayAccountOptions([
      { label: 'Yes', key: 'Yes', selected: false },
      { label: 'No', key: 'No', selected: false },
    ]);
    setPayAccountSelectede('');
    setSupportMarker('');
    setStatusActive('');
    setStatusInactive('');
    setStatusBlocked('');
    setPayAccountTrue('');
    setPayAccountFalse('');
    setTenantUserSearch(false);
    setSearhTenants('');
    setAnchorEl('');
    setTenantsSearch('');
    setBuildings([]);
    setVillages([]);
    setOnTenantCheckButton(false);
  };

  const clearMoreIcon = () => {
    setNewStatus([
      { label: 'Active', key: 'Active', selected: false },
      { label: 'Inactive', key: 'Inactive', selected: false },
      { label: 'Blocked', key: 'Blocked', selected: false },
      { label: 'Renewal Pending', key: 'Renewal Pending', selected: false },
    ]);
    setPayAccountOptions([
      { label: 'Yes', key: 'Yes', selected: false },
      { label: 'No', key: 'No', selected: false },
    ]);
    setOnCheckedRoleButton(false);
  };

  const clearRoleIcon = () => {
    setRoleOptions([
      { label: 'Super Administrator', key: 'Super Administrator', selected: false },
      { label: 'Employee', key: 'Employee', selected: false },
    ]);
  };

  return (
    <div className={classes.filerSection}>
      <div className="pageContainer filterContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftFilterIn`}>
              <li
                className={
                  onCheckTenantButton === true || searchTenants
                    ? `${classes.active} ${
                        searchTenants ? classes.valueSelected : ''
                      }`
                    : ''
                }
              >
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={event => {
                    setTenantUserSearch(true);
                    setAnchorEl(event.currentTarget);
                    setTenantsSearch(true);
                    setOnTenantCheckButton(true);
                  }}
                >
                  {' '}
                  Tenant
                  <small>
                    <img src={plus} alt="plus" />
                    <img src={minus} alt="minus" />
                  </small>
                </Button>
                <i
                  className="closeIconBtn"
                  role="presentation"
                  onClick={() => {
                    setSearhTenants('');
                    setOnTenantCheckButton(false);
                    setTenanSearchId(undefined);
                  }}
                >
                  <img src={close} alt="close" />
                </i>
                <Popover
                  id="tenant"
                  open={tenantUserSearch}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div className="filterDropdown">
                    <div className="filterDropdownin filterDropdownSch">
                      <div className="filterDropdownSchBox">
                        <Input
                          placeholder="Type a tenant name"
                          type="search"
                          autoFocus
                          value={searchTenants}
                          inputProps={{ 'aria-label': 'description' }}
                          onChange={event => {
                            setSearhTenants(event.target.value);
                            setTenanSearchId(undefined);
                            setOnTenantCheckButton(true);
                          }}
                        />
                      </div>
                      {tenants.length > 0 &&
                      searchTenants !== '' &&
                      searchTenants.length > 2
                        ? tenants &&
                          tenants.map(tenant => (
                            <FormHelperText
                              key={tenant.id}
                              id={tenant.id}
                              onClick={() => {
                                if (tenant.id && tenant.id) {
                                  setTenantUserSearch(false);
                                }
                                handleClick(tenant.id, tenant.name);
                                // setTenants('');
                              }}
                            >
                              {' '}
                              {tenant.name}
                            </FormHelperText>
                          ))
                        : searchResult}
                    </div>
                  </div>
                </Popover>
              </li>
              <LocationFilter
                locationSearch={locationSearch}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setLocationSearch={setLocationSearch}
                handleClose={handleClose}
                setOnCheckedLocationButton={setOnCheckedLocationButton}
                OnCheckedLocationButton={OnCheckedLocationButton}
                id={id}
                buildingGroupsList={buildingGroupsList}
                buildings={buildings}
                villages={villages}
                setVillages={setVillages}
                setBuildings={setBuildings}
              />
              <li
                className={
                  OnCheckedRoleButton === true ||
                  (selectedRoles && selectedRoles.length > 0)
                    ? `${classes.active} ${
                        selectedRoles && selectedRoles.length > 0
                          ? classes.valueSelected
                          : ''
                      }`
                    : ''
                }
              >
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={event => {
                    setRoleSearch(true);
                    setAnchorEl(event.currentTarget);
                    setOnCheckedRoleButton(true);
                  }}
                >
                  Role
                  <small>
                    <img src={plus} alt="plus" />
                    <img src={minus} alt="minus" />
                  </small>
                </Button>
                <i
                  className="closeIconBtn"
                  role="presentation"
                  onClick={clearRoleIcon}
                >
                  <img src={close} alt="close" />
                </i>
                <Popover
                  id="role"
                  open={roleSearch}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div className="filterDropdown">
                    <div className="filterDropdownin">
                      <List className={classes.moreList}>
                        <h4>Select A Role(s)</h4>
                        {roleOptions.map(role => (
                          <ListItem key={role.key}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={role.label}
                                  checked={role.selected}
                                  onChange={e => {
                                    newRoleOptions(e.target.checked, role.key);
                                  }}
                                />
                              }
                              value={role.key}
                              label={role.label}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  </div>
                </Popover>
              </li>
              <li
                className={
                  onCheckMoreButton === true ||
                  (selectedStatus && selectedStatus.length > 0) ||
                  (payAccountSelected && payAccountSelected.length > 0)
                    ? `${classes.active} ${
                        (selectedStatus && selectedStatus.length > 0) ||
                        (payAccountSelected && payAccountSelected.length > 0)
                          ? classes.valueSelected
                          : ''
                      }`
                    : ''
                }
              >
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={event => {
                    setMoreSearch(true);
                    setAnchorEl(event.currentTarget);
                    setOnCheckMoreButton(true);
                  }}
                >
                  More
                  <small>
                    <img src={plus} alt="plus" />
                    <img src={minus} alt="minus" />
                  </small>
                </Button>
                <i
                  className="closeIconBtn"
                  role="presentation"
                  onClick={clearMoreIcon}
                >
                  <img src={close} alt="close" />
                </i>
                <Popover
                  id="more"
                  open={moreSearch}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div className="filterDropdown">
                    <div className="filterDropdownin">
                      <List className={classes.moreList}>
                        <h4>Select A Status(s)</h4>
                        {newStatus.map(status => (
                          <ListItem key={status.key}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={status.label}
                                  checked={status.selected}
                                  onChange={e => {
                                    newStatusOptions(
                                      e.target.checked,
                                      status.key,
                                    );
                                  }}
                                />
                              }
                              label={status.label}
                              value={status.label}
                            />
                          </ListItem>
                        ))}
                        <Divider light />
                        <h4 className={classes.moreListHeadtwo}>
                          Select A Pay To Account Type(s)
                        </h4>
                        {payAccountOptions.map(account => (
                          <ListItem key={account.key}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={account.label}
                                  checked={account.selected}
                                  onChange={e => {
                                    payToAccountOptions(
                                      e.target.checked,
                                      account.key,
                                    );
                                  }}
                                />
                              }
                              label={account.label}
                              value={account.label}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  </div>
                </Popover>
              </li>
              <li role="presentation" onClick={ClearFilter}>
                Clear Filters
              </li>
            </ul>
          </div>
          <div className={`${classes.rightFilter} rightFilterPagination`}>
            <Pagination
              page={page}
              setPage={setPage}
              setOffset={setOffset}
              totalTenants={totalTenants}
            />
            <div className={`${classes.exportSerch} exportSerch`}>
              <ul>
                <SearchFilter
                  customerSearch={customerSearch}
                  setCustomerSearch={setCustomerSearch}
                  placeholder={placeholder}
                />
                {rolePermission && rolePermission.userType !== 'FrontOfHouse' && (
                  <li
                    role="presentation"
                    onClick={() => {
                      exportCsv();
                    }}
                  >
                    <img src={exportLogo} alt="exportLogo" />
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TenentFilterSearch;
