import React, { useState, useEffect } from 'react';
import { Input, makeStyles, TextField } from '@material-ui/core';
import moment from 'moment';
import axios from 'axios';
import apiEndPoints from '../../helpers/apiEndPoints';
import './meetingRoomGuestList.css';

const useStyles = makeStyles(() => ({
  InputSection: {
    '& li': {
      '& input': {
        height: '35px',
        padding: '0 15px !important',
        fontSize: '15px',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        width: '100%',
        background: 'transparent',
        color: '#2A2A2A',
        fontFamily: 'Conv_AtlasGrotesk-Regular-App',
      },
      '& textarea': {
        height: '127px',
        padding: '10px 15px !important',
        fontSize: '15px',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '5px',
        marginBottom: '5px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        color: '#2A2A2A',
        fontFamily: 'Conv_AtlasGrotesk-Regular-App',
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
}));

export default function MeetingRoomGuestlist() {
  const classes = useStyles();
  const [guestList, setGuestList] = useState([]);
  const [disableButton, setDisabaleButton] = useState(true);
  const [meetingData, setMeetingData] = useState();
  const [error, setError] = useState(false);
  const [errorMeeting, setErrorMeeting] = useState({
    title: 'Loading',
    message: 'Please wait...',
  });
  const [hostName, setHostName] = useState('');
  const fetchData = () => {
    const id = window.location.pathname.split('/')[2];
    const token = window.location.pathname.split('/')[3];
    if (window.location.pathname.split('/')[1] === 'requestGuestList') {
      axios
        .get(`${apiEndPoints.requestGuestList}/${id}/${token}`)
        .then(data => {
          const mrData = data && data.data && data.data.data;
          setMeetingData(mrData);
          setErrorMeeting({ title: '', message: '' });
        })
        .catch(err => {
          setErrorMeeting({
            title: 'Meeting Error',
            message:
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          });
          setMeetingData('');
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const sendGuestList = () => {
    const allguest = guestList.length ? guestList.split('\n') : '';
    const id = window.location.pathname.split('/')[2];
    const token = window.location.pathname.split('/')[3];
    axios
      .post(`${apiEndPoints.saveUserGuestList}/${id}/${token}`, {
        hostName: hostName,
        guestList: allguest,
      })
      .then(data => {
        setErrorMeeting({
          title: 'Status',
          message: data && data.data && data.data.message,
        });
        setMeetingData('');
      })
      .catch(err => {
        setErrorMeeting({
          title: 'Status',
          message:
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message,
        });
        setMeetingData('');
      });
  };

  const checkValidation = e => {
    const allowAlphabets = /^[A-Za-z\s]*$/;
    const checkEmpty = /^\s*$/;
    if (e.target.name === 'guestList') {
      setGuestList(e.target.value);
    } else if (
      e.target.name === 'hostName' &&
      allowAlphabets.test(e.target.value)
    ) {
      setHostName(e.target.value);
    }

    const allguest = guestList.length
      ? guestList.split('\n').filter(item => item !== '')
      : '';

    // eslint-disable-next-line no-unused-expressions
    allguest.length >
    (meetingData?.roomLayout?.maxLayoutCapacity ||
      meetingData?.meetingRoom?.capacity)
      ? setError(true)
      : setError(false);

    // eslint-disable-next-line no-unused-expressions
    !hostName ||
    checkEmpty.test(hostName) ||
    !allguest.length ||
    allguest.length >
      (meetingData?.roomLayout?.maxLayoutCapacity ||
        meetingData?.meetingRoom?.capacity)
      ? setDisabaleButton(true)
      : setDisabaleButton(false);
  };
  return (
    <div className="Background">
      {meetingData ? (
        <div className="guestListCard">
          <div className="cardText">
            <ul>
              <li>
                <h3>Please add a guestlist for your booking on:</h3>
                <p>
                  {`${moment
                    .tz(meetingData.startDate, 'Europe/London')
                    .format('Do MMMM YYYY')} `}
                </p>
                <p>
                  {`${moment
                    .tz(meetingData.startDate, 'Europe/London')
                    .format('hh:mm a')} - ${moment
                    .tz(meetingData.endDate, 'Europe/London')
                    .format('hh:mm a')} `}
                </p>
                <p>
                  {`${meetingData.meetingRoom.name}, ${
                    meetingData?.roomLayout?.name
                  } Layout (upto ${
                    meetingData?.roomLayout?.maxLayoutCapacity ||
                    meetingData.meetingRoom.capacity
                  } people)`}
                </p>
                <p>
                  {`${meetingData.building.name}, ${meetingData.building.address.postCode}`}
                </p>
              </li>
            </ul>

            <ul className={`${classes.InputSection}`}>
              <li>
                <TextField
                  value={guestList}
                  onChange={checkValidation}
                  onBlur={checkValidation}
                  name="guestList"
                  placeholder="Please add each name in a new line"
                  multiline
                  InputProps={{ disableUnderline: true }}
                  rows={6}
                />
                <p>
                  {error ? (
                    <p className="color_red mrt_n10">
                      {`Max limit ${
                        meetingData?.roomLayout?.maxLayoutCapacity ||
                        meetingData?.meetingRoom?.capacity
                      } people`}
                    </p>
                  ) : (
                    ''
                  )}
                </p>
              </li>
              <li>
                <li>Enter Your Full Name</li>
                <li className="mrt_10">
                  <Input
                    type="text"
                    inputProps={{ 'aria-label': 'description', maxLength: 150 }}
                    value={hostName}
                    name="hostName"
                    placeholder="Enter your Full Name"
                    onChange={checkValidation}
                    onBlur={checkValidation}
                  />
                </li>
              </li>
            </ul>
            <button
              className={`guestlistSave ${
                disableButton ? 'SaveDisable' : ''
              } mrt_20`}
              type="button"
              disabled={disableButton}
              onClick={() => sendGuestList()}
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <div className="failedPaymentCardBox">
          <div className="noResult cardPaymentError">
            <h2>{errorMeeting.title}</h2>
            <p>{errorMeeting.message}</p>
          </div>
        </div>
      )}
    </div>
  );
}
