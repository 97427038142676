/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';
import {
  Input,
  makeStyles,
  FormHelperText,
  // LinearProgress,
  Button,
} from '@material-ui/core';
// import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import addImage from '../../images/addImage.svg';
import closeIcon from '../../images/close-icon.svg';
import {
  CLOUDINARY_API_KEY,
  CLOUDINARY_API_SECRET_KEY,
  CLOUDINARY_UPLOAD_PRESET,
  CLOUD_NAME,
} from '../../utils/config';
import ManageMedia from './ManageMedia';
import ImageCroper from './ImageCroper';
import {
  announcement_img_312x296,
  announcement_img_344x352,
  announcement_img_375x368,
  image_title_1,
  image_title_3,
  image_title_4,
  image_title_5,
  newsArticles_media_folder,
} from './constants';
import { isScalableImage } from '../../utils/constants';

const useStyles = makeStyles({
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    width: '100%',
    fontFamily: ['Varta', 'sans-serif'].join(','),
    textTransform: 'capitalize',
  },
  videoAddIcon: {
    position: 'absolute',
    top: '20px',
    left: 0,
    right: 0,
    margin: '0 auto',
    textAlign: 'center',
    height: '85px',
    width: '100%',
    lineHeight: '85px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'fill',
    },
  },
  videoAddIconView: {
    pointerEvents: 'none',
  },
});

// const theme = createMuiTheme({
//   palette: {
//     secondary: {
//       main: '#737373',
//     },
//   },
// });

const NewsAndAriclesMedia = ({ values, viewMedia }) => {
  const classes = useStyles();
  const {
    newsOrArticleImage,
    setNewsOrArticleImage,
    newsVideo,
    setNewsVideo,
    previewImage,
    setPreviewImage,
    loopVideo,
    setLoopVideo,
    loopVideoTextOverlay,
    setLoopVideoTextOverlay,
    newsType,
    setNewsType,
    articleType,
    errorFieldValue,
    setSaveArticleToggle,
  } = values;

  const [toggle, setToggle] = useState(false);
  const [imgIndex, setImgIndex] = useState('');
  const [cropImg, setCropImg] = useState(false);
  const [cropSize, setCropSize] = useState({});
  const [tag, setTag] = useState('');

  const imageFormatedUrl = (originalImage, sizes = '') =>
    `${originalImage.split('image/upload')[0]}image/upload/${
      (isScalableImage &&
        sizes &&
        `c_scale,h_${sizes.height},w_${sizes.width}/`) ||
      ''
    }f_jpg,f_auto,q_auto${originalImage.split('image/upload')[1]}`;

  const showUploadWidget = (e, resourceType, maxFileSize, folder, formates) => {
    window.cloudinary.openUploadWidget(
      {
        cloudName: CLOUD_NAME,
        api_key: CLOUDINARY_API_KEY,
        api_secret: CLOUDINARY_API_SECRET_KEY,
        uploadPreset: CLOUDINARY_UPLOAD_PRESET,
        showPoweredBy: false,
        folder,
        clientAllowedFormats: formates,
        sources: [
          'local',
          'camera',
          'url',
          'facebook',
          'instagram',
          'google_drive',
        ],
        minImageWidth: 344,
        minImageHeight: 352,
        showAdvancedOptions: false,
        cropping: false,
        multiple: false,
        resourceType,
        maxFileSize,
        defaultSource: 'local',
        styles: {
          palette: {
            window: '#FDFDFD',
            sourceBg: '#FFFFFF',
            windowBorder: '#000000',
            tabIcon: '#060606',
            inactiveTabIcon: '#82858A',
            menuIcons: '#000000',
            link: '#000000',
            action: '#000000',
            inProgress: '#000000',
            complete: '#000000',
            error: '#EA2727',
            textDark: '#000000',
            textLight: '#FFFFFF',
          },
          fonts: {
            default: null,
            "'Varta', sans-serif": {
              url: 'https://fonts.googleapis.com/css?family=Varta+Sans',
              active: true,
            },
          },
        },
      },
      (err, { event, info }) => {
        if (!err && event === 'success') {
          if (e === 'newsOrArticleImage') {
            setNewsOrArticleImage([
              {
                imageUrl: imageFormatedUrl(info.secure_url),
                tag: image_title_1,
              },
              {
                imageUrl: imageFormatedUrl(
                  info.secure_url,
                  announcement_img_312x296,
                ),
                tag: image_title_3,
              },
              {
                imageUrl: imageFormatedUrl(
                  info.secure_url,
                  announcement_img_344x352,
                ),
                tag: image_title_4,
              },
              {
                imageUrl: imageFormatedUrl(
                  info.secure_url,
                  announcement_img_375x368,
                ),
                tag: image_title_5,
              },
            ]);
          } else if (e === 'newsVideo') {
            setNewsVideo(info.secure_url);
          } else if (e === 'previewImage') {
            setPreviewImage([
              {
                imageUrl: imageFormatedUrl(info.secure_url),
                tag: image_title_1,
              },
              {
                imageUrl: imageFormatedUrl(
                  info.secure_url,
                  announcement_img_312x296,
                ),
                tag: image_title_3,
              },
              {
                imageUrl: imageFormatedUrl(
                  info.secure_url,
                  announcement_img_344x352,
                ),
                tag: image_title_4,
              },
              {
                imageUrl: imageFormatedUrl(
                  info.secure_url,
                  announcement_img_375x368,
                ),
                tag: image_title_5,
              },
            ]);
          } else if (e === 'loopVideo') {
            setLoopVideo(info.secure_url);
          } else if (e === 'loopVideoTextOverlay') {
            setLoopVideoTextOverlay(info.secure_url);
          }
        }
      },
    );
  };
  useEffect(() => {
    document.body.classList.toggle('modalOpen', toggle || cropImg);
  }, [toggle, cropImg]);

  return (
    <div className="spaceFileContainer">
      {toggle && (
        <ManageMedia
          values={{
            viewMedia,
            setCropSize,
            cropImg,
            toggle,
            setToggle,
            setCropImg,
            imgIndex,
            setTag,
          }}
        />
      )}
      {cropImg && (
        <ImageCroper
          values={{
            cropSize,
            setCropSize,
            cropImg,
            setCropImg,
            toggle,
            setToggle,
            imgIndex,
            setImgIndex,
            tag,
            setTag,
            newsOrArticleImage,
            setNewsOrArticleImage,
            previewImage,
            setPreviewImage,
          }}
        />
      )}

      {articleType === 'Announcement' && (
        <ul className="announcementMediaOnly">
          <>
            {newsOrArticleImage && newsOrArticleImage.length > 0 && (
              <ul className="MultipleImgUpload MultipleImgUploadWrap">
                <li>
                  {!viewMedia && (
                    <i className="removeImage">
                      <img
                        src={closeIcon}
                        role="presentation"
                        alt="closeIcon"
                        onClick={() => setNewsOrArticleImage([])}
                      />
                    </i>
                  )}
                  <small className="imageAddName">Image</small>
                  <span className="imageAddIconUpload">
                    <img
                      src={newsOrArticleImage && newsOrArticleImage[0].imageUrl}
                      alt="addImage"
                      role="presentation"
                      onClick={() =>
                        !viewMedia &&
                        window.open(
                          newsOrArticleImage && newsOrArticleImage[0].imageUrl,
                        )
                      }
                    />
                  </span>
                  <Button
                    className="cropBtn"
                    onClick={() => {
                      setToggle(!toggle);
                      if (cropImg) setCropImg(!cropImg);
                      setImgIndex({
                        order: 1,
                        images: newsOrArticleImage,
                        imageUrl: newsOrArticleImage[0].imageUrl,
                        mediaType: 'newsOrArticleImage',
                      });
                    }}
                  >
                    {' '}
                    {viewMedia ? 'View' : ' View / Edit'}
                  </Button>
                </li>
              </ul>
            )}
            {!viewMedia &&
              newsOrArticleImage &&
              newsOrArticleImage.length === 0 && (
                <>
                  <ul className="MultipleImgUpload MultipleImgUploadWrap">
                    <li>
                      <small className="imageAddName">Image*</small>
                      <>
                        <Input
                          name="AnnouncementImage"
                          id="newsOrArticleImage"
                          type="button"
                          accept="image/x-png,image/gif,image/jpeg"
                          disabled={viewMedia}
                          multiple
                          onClick={() =>
                            showUploadWidget(
                              'newsOrArticleImage',
                              'image',
                              null,
                              newsArticles_media_folder,
                              ['png', 'jpg', 'jpeg'],
                            )
                          }
                        />
                        <span className="imageAddIcon">
                          <img src={addImage} alt="addImage" />
                        </span>
                      </>
                      {errorFieldValue &&
                        errorFieldValue.mediaTypeValidation === false &&
                        newsOrArticleImage &&
                        newsOrArticleImage.length === 0 && (
                          <FormHelperText className={classes.errorMsg}>
                            Image is required.
                          </FormHelperText>
                        )}
                    </li>
                  </ul>
                  </>
                  )}
                  <ul className="MultipleImgUpload MultipleImgUploadWrap">
                    <li>
                      <span className="imageAddName">
                        Video
                        {/* {!viewMedia && '*'} */}
                      </span>
                      <Input
                        disabled={viewMedia}
                        name="inputVideoUrl"
                        id="newsVideo"
                        type="button"
                        accept="video/mp4"
                        inputProps={{ accept: 'video/mp4' }}
                        onClick={() =>
                          showUploadWidget(
                            'newsVideo',
                            'video',
                            null,
                            newsArticles_media_folder,
                            'video',
                          )
                        }
                      />
                      {newsVideo ? (
                        <span className="imageAddIcon videoAddIcon">
                          <video src={newsVideo} controls />
                        </span>
                      ) : (
                        <span className="imageAddIcon">
                          <img src={addImage} alt="addImage" />
                        </span>
                      )}
                      {/* {errorFieldValue &&
                        errorFieldValue.mediaTypeValidation === false &&
                        newsVideo === '' && (
                          <FormHelperText className={classes.errorMsg}>
                            Video is required.
                          </FormHelperText>
                        )} */}
                      {newsVideo && !viewMedia && (
                        <i className="removeImage">
                          <img
                            src={closeIcon}
                            alt="closeIcon"
                            role="presentation"
                            onClick={() => {
                              setNewsVideo('');
                              setNewsType('standard')
                              setSaveArticleToggle(false);
                            }}
                          />
                        </i>
                      )}
                    </li>
                    <li>
                      <span className="imageAddName">
                        Preview Image
                        {/* {!viewMedia && '*'} */}
                      </span>
                      <>
                        <Input
                          disabled={viewMedia}
                          id="previewImage"
                          type="button"
                          onClick={() =>
                            showUploadWidget(
                              'previewImage',
                              'image',
                              null,
                              newsArticles_media_folder,
                              ['png', 'jpg', 'jpeg'],
                            )
                          }
                        />
                        {previewImage && previewImage.length > 0 ? (
                          <>
                            <span className="imageAddIcon videoAddIcon">
                              <img
                                src={previewImage && previewImage[0].imageUrl}
                                alt="addImage"
                                role="presentation"
                                onClick={() =>
                                  !viewMedia &&
                                  window.open(
                                    previewImage && previewImage[0].imageUrl,
                                  )
                                }
                              />
                            </span>
                            <Button
                              className="cropBtn"
                              onClick={() => {
                                setToggle(!toggle);
                                if (cropImg) setCropImg(!cropImg);
                                setImgIndex({
                                  order: 1,
                                  images: previewImage,
                                  imageUrl: previewImage[0].imageUrl,
                                  mediaType: 'videoPreviewImage',
                                });
                              }}
                            >
                              {' '}
                              {viewMedia ? 'View' : ' View / Edit'}
                            </Button>
                          </>
                        ) : (
                          <span className="imageAddIcon">
                            <img src={addImage} alt="addImage" />
                          </span>
                        )}
                      </>
                      {/* {errorFieldValue &&
                        errorFieldValue.mediaTypeValidation === false &&
                        previewImage &&
                        previewImage.length === 0 && (
                          <FormHelperText className={classes.errorMsg}>
                            Preview Image is required.
                          </FormHelperText>
                        )} */}
                      {previewImage && previewImage.length > 0 && !viewMedia && (
                        <i className="removeImage">
                          <img
                            src={closeIcon}
                            alt="closeIcon"
                            role="presentation"
                            onClick={() => {
                              setPreviewImage([]);
                              setNewsType('standard')
                              setSaveArticleToggle(false);
                            }}
                          />
                        </i>
                      )}
                    </li>
                  </ul>
                </>
        </ul>
      )}
      <ul>
        {articleType === 'News' && newsType === 'standard' && (
          <>
            {newsOrArticleImage && newsOrArticleImage.length > 0 && (
              <ul className="MultipleImgUpload MultipleImgUploadWrap">
                <li>
                  {!viewMedia && (
                    <i className="removeImage">
                      <img
                        src={closeIcon}
                        role="presentation"
                        alt="closeIcon"
                        onClick={() => setNewsOrArticleImage([])}
                      />
                    </i>
                  )}
                  <small className="imageAddName">Image</small>
                  <span className="imageAddIconUpload">
                    <img
                      src={newsOrArticleImage && newsOrArticleImage[0].imageUrl}
                      alt="addImage"
                      role="presentation"
                      onClick={() =>
                        !viewMedia &&
                        window.open(
                          newsOrArticleImage && newsOrArticleImage[0].imageUrl,
                        )
                      }
                    />
                  </span>
                  <Button
                    className="cropBtn"
                    onClick={() => {
                      setToggle(!toggle);
                      if (cropImg) setCropImg(!cropImg);
                      setImgIndex({
                        order: 1,
                        images: newsOrArticleImage,
                        imageUrl: newsOrArticleImage[0].imageUrl,
                        mediaType: 'newsOrArticleImage',
                      });
                    }}
                  >
                    {' '}
                    {viewMedia ? 'View' : ' View / Edit'}
                  </Button>
                </li>
              </ul>
            )}
            {!viewMedia &&
              newsOrArticleImage &&
              newsOrArticleImage.length === 0 && (
                <ul className="MultipleImgUpload MultipleImgUploadWrap">
                  <li>
                    <small className="imageAddName">Image*</small>
                    {/* {imageLoader ? (
                      <MuiThemeProvider theme={theme}>
                        <LinearProgress color="secondary" />
                      </MuiThemeProvider>
                    ) : ( */}
                    <>
                      <Input
                        name="AnnouncementImage"
                        id="newsOrArticleImage"
                        type="button"
                        accept="image/x-png,image/gif,image/jpeg"
                        disabled={viewMedia}
                        multiple
                        onClick={() =>
                          showUploadWidget(
                            'newsOrArticleImage',
                            'image',
                            null,
                            newsArticles_media_folder,
                            ['png', 'jpg', 'jpeg'],
                          )
                        }
                      />
                      <span className="imageAddIcon">
                        <img src={addImage} alt="addImage" />
                      </span>
                    </>
                    {/* )} */}
                    {/* {(!viewMedia ||
                      (newsOrArticleImage &&
                        newsOrArticleImage.length > 0)) && (
                      <small className="uploadsize">
                        Maximum file size 1MB, 1080px by 1080px
                      </small>
                    )} */}
                    {articleType === 'News' && newsType === 'standard' &&
                      errorFieldValue &&
                      errorFieldValue.mediaTypeValidation === false &&
                      newsOrArticleImage &&
                      newsOrArticleImage.length === 0 && (
                        <FormHelperText className={classes.errorMsg}>
                          Image is required.
                        </FormHelperText>
                      )}
                  </li>
                </ul>
              )}
          </>
        )}
      </ul>
      <ul>
        {articleType === 'News' && (
          <ul className="MultipleImgUpload">
            <>
              {newsType === 'featured' && (
                <>
                  <li>
                    <span className="imageAddName">
                      Loop Video{!viewMedia && '*'}
                    </span>
                    {/* {loopVideoLoader ? (
                      <MuiThemeProvider theme={theme}>
                        <LinearProgress color="secondary" />
                      </MuiThemeProvider>
                    ) : ( */}
                    <>
                      <Input
                        disabled={viewMedia}
                        name="inputVideoUrl"
                        id="loopVideo"
                        type="button"
                        accept="video/mp4"
                        inputProps={{ accept: 'video/mp4' }}
                        onClick={() =>
                          showUploadWidget(
                            'loopVideo',
                            'video',
                            null,
                            newsArticles_media_folder,
                            'video',
                          )
                        }
                      />
                      {loopVideo ? (
                        <span className="imageAddIcon videoAddIcon">
                          <video src={loopVideo} controls />
                        </span>
                      ) : (
                        <span className="imageAddIcon">
                          <img src={addImage} alt="addImage" />
                        </span>
                      )}
                    </>
                    {/* {!viewMedia && !loopVideo && (
                      <small className="uploadsize">
                        Maximum file size 100 MB
                      </small>
                    )} */}
                    {newsType === 'featured' &&
                      errorFieldValue &&
                      errorFieldValue.mediaTypeValidation === false &&
                      loopVideo === '' && (
                        <FormHelperText className={classes.errorMsg}>
                          Loop Video is required.
                        </FormHelperText>
                      )}
                    {loopVideo && !viewMedia && (
                      <i className="removeImage">
                        <img
                          src={closeIcon}
                          alt="closeIcon"
                          role="presentation"
                          onClick={() => {
                            setLoopVideo('');
                            setSaveArticleToggle(false);
                          }}
                        />
                      </i>
                    )}
                  </li>
                  <li>
                    <span className="imageAddName">
                      Loop Overlay{!viewMedia && '*'}
                    </span>
                    {/* {loopVideoTextLoader ? (
                      <MuiThemeProvider theme={theme}>
                        <LinearProgress color="secondary" />
                      </MuiThemeProvider>
                    ) : ( */}
                    <>
                      <Input
                        disabled={viewMedia}
                        name="loopVideo"
                        id="loopVideoTextOverlay"
                        type="button"
                        inputProps={{ accept: 'video/mp4' }}
                        onClick={() =>
                          showUploadWidget(
                            'loopVideoTextOverlay',
                            'video',
                            null,
                            newsArticles_media_folder,
                            'video',
                          )
                        }
                      />
                      {loopVideoTextOverlay ? (
                        <span className="imageAddIcon videoAddIcon">
                          <video src={loopVideoTextOverlay} controls />
                        </span>
                      ) : (
                        <span className="imageAddIcon">
                          <img src={addImage} alt="addImage" />
                        </span>
                      )}
                    </>
                    {/* {!viewMedia && !loopVideoTextOverlay && (
                      <small className="uploadsize">
                        Maximum file size 100 MB
                      </small>
                    )} */}
                    {newsType === 'featured' &&
                      errorFieldValue &&
                      errorFieldValue.mediaTypeValidation === false &&
                      loopVideoTextOverlay === '' && (
                        <FormHelperText className={classes.errorMsg}>
                          Loop Overlay is required.
                        </FormHelperText>
                      )}
                    {loopVideoTextOverlay && !viewMedia && (
                      <i className="removeImage">
                        <img
                          src={closeIcon}
                          alt="closeIcon"
                          role="presentation"
                          onClick={() => {
                            setLoopVideoTextOverlay('');
                            setSaveArticleToggle(false);
                          }}
                        />
                      </i>
                    )}
                  </li>
                </>
              )}
            </>
          </ul>
        )}
      </ul>
      {articleType === 'News' && (
        <ul className="MultipleImgUpload">
          <>
            {(newsType === 'video' || newsType === 'featured') && (
              <>
                {newsType === 'video' && (
                  <li>
                    <span className="imageAddName">
                      Video{!viewMedia && '*'}
                    </span>
                    {/* {videoLoader ? (
                    <MuiThemeProvider theme={theme}>
                      <LinearProgress color="secondary" />
                    </MuiThemeProvider>
                  ) : ( */}
                    <>
                      <Input
                        disabled={viewMedia}
                        name="inputVideoUrl"
                        id="newsVideo"
                        type="button"
                        accept="video/mp4"
                        inputProps={{ accept: 'video/mp4' }}
                        onClick={() =>
                          showUploadWidget(
                            'newsVideo',
                            'video',
                            null,
                            newsArticles_media_folder,
                            'video',
                          )
                        }
                      />
                      {newsVideo ? (
                        <span className="imageAddIcon videoAddIcon">
                          <video src={newsVideo} controls />
                        </span>
                      ) : (
                        <span className="imageAddIcon">
                          <img src={addImage} alt="addImage" />
                        </span>
                      )}
                    </>
                    {/* {!newsVideo && !viewMedia && (
                    <small className="uploadsize">
                      Maximum file size 100 MB
                    </small>
                  )} */}
                    {newsType === 'video' &&
                      errorFieldValue &&
                      errorFieldValue.mediaTypeValidation === false &&
                      newsVideo === '' && (
                        <FormHelperText className={classes.errorMsg}>
                          Video is required.
                        </FormHelperText>
                      )}
                    {newsVideo && !viewMedia && (
                      <i className="removeImage">
                        <img
                          src={closeIcon}
                          alt="closeIcon"
                          role="presentation"
                          onClick={() => {
                            setNewsVideo('');
                            setSaveArticleToggle(false);
                          }}
                        />
                      </i>
                    )}
                  </li>
                )}
                <li>
                  <span className="imageAddName">
                    Preview Image
                    {!viewMedia && '*'}
                  </span>
                  {/* {previewImgLoader ? (
                    <MuiThemeProvider theme={theme}>
                      <LinearProgress color="secondary" />
                    </MuiThemeProvider>
                  ) : ( */}
                  <>
                    <Input
                      disabled={viewMedia}
                      id="previewImage"
                      type="button"
                      onClick={() =>
                        showUploadWidget(
                          'previewImage',
                          'image',
                          null,
                          newsArticles_media_folder,
                          ['png', 'jpg', 'jpeg'],
                        )
                      }
                    />
                    {previewImage && previewImage.length > 0 ? (
                      <>
                        <span className="imageAddIcon videoAddIcon">
                          <img
                            src={previewImage && previewImage[0].imageUrl}
                            alt="addImage"
                            role="presentation"
                            onClick={() =>
                              !viewMedia &&
                              window.open(
                                previewImage && previewImage[0].imageUrl,
                              )
                            }
                          />
                        </span>
                        <Button
                          className="cropBtn"
                          onClick={() => {
                            setToggle(!toggle);
                            if (cropImg) setCropImg(!cropImg);
                            setImgIndex({
                              order: 1,
                              images: previewImage,
                              imageUrl: previewImage[0].imageUrl,
                              mediaType: 'videoPreviewImage',
                            });
                          }}
                        >
                          {' '}
                          {viewMedia ? 'View' : ' View / Edit'}
                        </Button>
                      </>
                    ) : (
                      <span className="imageAddIcon">
                        <img src={addImage} alt="addImage" />
                      </span>
                    )}
                  </>
                  {/* {previewImage && previewImage.length === 0 && !viewMedia && (
                    <small className="uploadsize">Maximum file size 1 MB</small>
                  )} */}
                  {(newsType === 'video' || newsType === 'featured') &&
                    errorFieldValue &&
                    errorFieldValue.mediaTypeValidation === false &&
                    previewImage &&
                    previewImage.length === 0 && (
                      <FormHelperText className={classes.errorMsg}>
                        Preview Image is required.
                      </FormHelperText>
                    )}
                  {previewImage && previewImage.length > 0 && !viewMedia && (
                    <i className="removeImage">
                      <img
                        src={closeIcon}
                        alt="closeIcon"
                        role="presentation"
                        onClick={() => {
                          setPreviewImage([]);
                          setSaveArticleToggle(false);
                        }}
                      />
                    </i>
                  )}
                </li>
              </>
            )}
          </>
        </ul>
      )}
    </div>
  );
};
export default NewsAndAriclesMedia;
