const constants = {
  routesList: [
    {
      path: '/managetenants',
      value: 'Tenants',
      accessValue: 'getTenants',
      type: 'getTenants',
    },
    {
      path: '/tenantusers',
      value: 'Tenant Users',
      accessValue: 'getTenants',
      type: 'getTenants',
    },
    {
      path: '/meetingrooms-list',
      value: 'Meeting Rooms',
      accessValue: 'getMeetingRooms',
      type: 'getMeetingRooms',
    },
    {
      path: '/',
      value: 'Meeting Room Bookings',
      accessValue: 'getBookings',
      type: 'getBookings',
    },
    {
      path: '/cateringlist',
      value: 'Catering Category',
      accessValue: 'getCateringCategories',
      type: 'catering',
    },
    {
      path: '/catering-menu-list',
      value: 'Catering Menu',
      accessValue: 'getCateringItems',
      type: 'catering',
    },
    {
      path: '/events-list',
      value: 'Events',
      accessValue: 'getEvents',
      type: 'getEvents',
    },
    {
      path: '/event-bookings',
      value: 'Event Bookings',
      accessValue: 'getEvents',
      type: 'getEvents',
    },
    {
      path: '/offers-list',
      value: 'Offers',
      accessValue: 'getOffers',
      type: 'getOffers',
    },
    {
      path: '/spacelist',
      value: 'Spaces',
      accessValue: 'getSpaces',
      type: 'getSpaces',
    },
    {
      path: '/news-announcements',
      value: 'News + Announcements',
      accessValue: 'getArticles',
      type: 'getArticles',
    },
    {
      path: '/building-attributes',
      value: 'Building Attributes',
      accessValue: 'getBuildings',
      type: 'getBuildings',
    },
    {
      path: '/card-management',
      value: 'Card Management',
      accessValue: 'getLoopVideoCards',
      type: 'getLoopVideoCards',
    },
    {
      path: '/voucherlist',
      value: 'Voucher Codes',
      accessValue: 'getLoopVideoCards',
      type: 'getLoopVideoCards',
    },
    {
      path: '/notification',
      value: 'Notifications',
      accessValue: 'getLoopVideoCards',
      type: 'getLoopVideoCards',
    },
    {
      path: '/loyaltycardlist',
      value: 'Loyalty Cards',
      accessValue: 'getLoopVideoCards',
      type: 'getLoopVideoCards',
    },
  ],
  routesListWithSubs:
    [
      {
        parent: 'Analytics',
        children: [
          {
            path: '/analytics',
            url: null,
            value: 'Stats',
            accessValue: 'getLoopVideoCards',
          },
          {
            path: null,
            url: 'https://derwentpayments.azurewebsites.net/Visitors',
            value: 'Visitors',
            accessValue: null,
          },
          {
            path: null,
            url: 'https://derwentpayments.azurewebsites.net/Domains',
            value: 'Domains',
            accessValue: null,
          },
        ],
      },
    ],
};
export default constants;
