/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  InputLabel,
  Input,
  FormHelperText,
  CircularProgress,
  Dialog,
  DialogActions,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import LocationFilter from '../../components/Filters/LocationFilter';
import dowmArw from '../../images/down-arw.svg';
import FileUpload from '../../components/ImageUpload/FileUpload';
import { createEventsSchema } from '../../utils/formValidate';
import { GlobalContext } from '../../context/GlobalContext';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';
import { timeZone } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '66%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& li': {
        listStyle: 'none',
        width: '48%',
        marginBottom: '22px',
        position: 'relative',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& > span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
      },
      '& label': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384 !important',
        transform: 'none',
        textTransform: 'capitalize',
        position: 'static',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& > div': {
          '& > div': {
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  formButton: {
    marginTop: '60px',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      height: '47px',
      lineHeight: '47px',
      border: '1px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff',
      marginLeft: '30px',
      cursor: 'pointer',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#444444',
        border: 'none',
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    textTransform: 'capitalize',
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
  leftFilterIn: {
    '& button': {
      color: '#444444',
      backgroundColor: '#f8f8f8',
      padding: '12px 12px 10px',
      fontWeight: 'bold',
      boxShadow: 'none',
      fontSize: '14px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      borderRadius: 5,
      '&:hover': {
        boxShadow: 'none',
        background: '#f8f8f8',
      },
      '& span': {
        lineHeight: '100%',
        alignItems: 'center',
        display: 'flex',

        '& small': {
          marginLeft: 20,
          top: '-2px',
          position: 'relative',
        },
        '& img': {
          '&:nth-child(2)': {
            display: 'none',
          },
        },
      },
    },
  },
  active: {
    '& button': {
      color: '#fff !important',
    },
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'input',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
}));

const CreateOrEditEvent = () => {
  const classes = useStyles();
  const { setSuccessMessage, buildingDataList, buildingGroupsList } =
    useContext(GlobalContext);
  const { id } = useParams();
  const history = useHistory();
  const [hostName, setHostName] = useState('');
  const [hostWebUrl, setHostWebUrl] = useState('');
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState(new Date());
  const [timePicker, setTimePicker] = useState([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [ticketCost, setTicketCost] = useState('');
  const [maxGuestPerBooking, setMaxGuestPerBooking] = useState('');
  const [maxTicketsForEvent, setMaxTicketsForEvent] = useState('');
  const [eventAddLine1, setEventAddLine1] = useState('');
  const [eventAddLine2, setEventAddLine2] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [createEventToggle, setCreateEventToggle] = useState(false);
  const [status, setStatus] = useState('Published');
  const [locationSearch, setLocationSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [villages, setVillages] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [responseError, setResponseError] = useState('');
  const [warningPopUp, setWarningPopUp] = useState(false);
  const [postCodeAlias, setPostCodeAlias] = useState('');
  const [eventType, setEventType] = useState('');
  const [vatRate, setVatRate] = useState('');
  const [totalCost, setTotalCost] = useState('0.00');
  const [flag, setFlag] = useState(false);
  const startTimeHours=id?moment.tz(startTime,timeZone.london).format('hh:mm A'):startTime
  const endTimeHour=id?moment.tz(endTime,timeZone.london).format('hh:mm A'):endTime
  const startTimeApiRequest =
     moment
        .tz(
          `${moment(eventDate).format('YYYY-MM-DD')} ${startTimeHours}`,"YYYY-MM-DD hh:mm A",
          timeZone.london,
        )
        .toISOString();

  const endTimeApiRequest = moment
        .tz(
          `${moment(eventDate).format('YYYY-MM-DD')} ${endTimeHour}`,"YYYY-MM-DD hh:mm A",
          timeZone.london,
        )
        .toISOString();

  const intervals = (startString, endString) => {
    const start = moment(startString, 'hh:mm a');
    const end = moment(endString, 'hh:mm a');
    const newValue = [];
    start.minutes(Math.ceil(start.minutes() / 15) * 15);
    const current = moment(start);
    while (current <= end) {
      newValue.push(current.format('hh:mm a'));
      current.add(15, 'minutes');
    }
    return newValue;
  };

  if (id) {
    if (!JSON.parse(localStorage.getItem('rolePermission'))[0].updateEvent) {
      history.push('/events-list');
    }
  } else if (
    !JSON.parse(localStorage.getItem('rolePermission'))[0].createEvent
  ) {
    history.push('/events-list');
  }

  const buildingPayload = buildingDataList.filter(o1 =>
    buildings.some(o2 => o1.id === o2),
  );

  const buildingArray = [];
  if (buildingPayload) {
    buildingPayload.map(item => {
      if (item.id && item.village && item.name) {
        buildingArray.push({
          id: item.id,
          name: item.name,
          village: item.village,
        });
      }
    });
  }

  const getVatRate = date => {
    ApiClient.apiGet(
      `${apiEndPoints.getVat}${
        date ? moment(date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
      }`,
    )
      .then(data => {
        const getVatValue = data?.data?.data?.filter(
          field => field.title === 'Event',
        );
        setVatRate(getVatValue?.[0]?.vatRate);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  useEffect(() => {
    setTimePicker(intervals('12:00:00 am', '11:59:00 pm'));
    if (id) {
      setLoading(true);
      ApiClient.apiGet(`${apiEndPoints.getEventDetails}/${id}`)
        .then(data => {
          const eventDetails =
            data && data.data && data.data.data && data.data.data;
          setHostName(eventDetails.host || '');
          setHostWebUrl(eventDetails.hostWebsite || '');
          setEventName(eventDetails.name || '');
          setEventDate(eventDetails.eventDate || '');
          setStartTime(eventDetails.startTime || '');
          setEndTime(eventDetails.endTime || '');
          setEventType(eventDetails.type || 'Free');
          setTicketCost(eventDetails.ticketCost || '');
          setTotalCost(eventDetails.totalCost || '0.00');
          setVatRate(eventDetails.vat || 0);
          setMaxGuestPerBooking(eventDetails.maxGuests || 0);
          setMaxTicketsForEvent(eventDetails.maxTickets || '');
          setEventAddLine1(
            (eventDetails.address && eventDetails.address.line1) || '',
          );
          setEventAddLine2(
            (eventDetails.address && eventDetails.address.line2) || '',
          );
          setCity((eventDetails.address && eventDetails.address.city) || '');
          setPostcode(
            (eventDetails.address && eventDetails.address.postcode) || '',
          );
          setLatitude(eventDetails?.address?.latitude);
          setLongitude(eventDetails?.address?.longitude);
          setEventDescription(eventDetails.description || '');
          setStatus(eventDetails.status || '');
          setImages(eventDetails.image || '');
          setBuildings(
            eventDetails.eligibility.map(item => {
              return item.id;
            }),
          );
          setLoading(false);
          setPostCodeAlias(
            (eventDetails.address && eventDetails.address.postCodeAlias) || '',
          );
          getVatRate(eventDetails.eventDate);
        })
        .catch(err => {
          setLoading(false);
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
        });
    } else {
      getVatRate();
      setEventType('Free');
    }
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
    setLocationSearch(false);
    setOnCheckedLocationButton(false);
  };

  useEffect(() => {
    createEventsSchema
      .validate({
        hostName,
        hostWebUrl,
        eventName,
        eventDate,
        startTime,
        endTime,
        ticketCost,
        eventType,
        maxGuestPerBooking,
        maxTicketsForEvent,
        eventAddLine1,
        city,
        postcode,
        latitude,
        longitude,
        locationValidation:
          buildingArray && buildingArray.length > 0 ? buildingArray : buildings,
        eventDescription,
        offerImage: images,
      })

      .then(() => {
        setErrorMsg('');
        if (createEventToggle && endTimeApiRequest >= startTimeApiRequest) {
          setLoading(true);
          const eventPayload = {
            id: id || undefined,
            host: hostName,
            hostWebsite: hostWebUrl,
            name: eventName,
            eventDate: moment
              .tz(moment(eventDate), timeZone.london)
              .toISOString(),
            startTime: id ? startTime : startTimeApiRequest,
            endTime: id ? endTime : endTimeApiRequest,
            maxGuests: Number(maxGuestPerBooking),
            maxTickets: Number(maxTicketsForEvent),
            address: {
              line1: eventAddLine1,
              line2: eventAddLine2 || undefined,
              city,
              postcode,
              latitude: Number(latitude),
              longitude: Number(longitude),
              postCodeAlias,
            },
            eligibility:
              buildingArray && buildingArray.length > 0
                ? buildingArray
                : buildings,
            description: eventDescription,
            image: images,
            status,
            ticketCost: Number(ticketCost),
            totalCost,
            type: eventType,
            vat: vatRate,
          };
          if (id) {
            ApiClient.apiPut(
              `${apiEndPoints.getEventDetails}${id}`,
              eventPayload,
            )
              .then(() => {
                setSuccessMessage(`${eventName} updated successfully`);
                setLoading(false);
                history.push('/events-list');
              })
              .catch(err => {
                alert(
                  err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.message,
                );
                setCreateEventToggle(false);
                setLoading(false);
              });
          } else {
            ApiClient.apiPost(apiEndPoints.eventDetails, eventPayload)
              .then(() => {
                setSuccessMessage(`${eventName} created successfully`);
                setLoading(false);
                history.push('/events-list');
              })
              .catch(err => {
                alert(
                  err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.message,
                );
                if (
                  err.response.data.message ===
                  `Invalid input request:Event date or time can't be in past`
                ) {
                  setResponseError(`Event start time can't be in past`);
                }
                setCreateEventToggle(false);
                setLoading(false);
              });
          }
        }
      })
      .catch(error => {
        if (createEventToggle) {
          setErrorMsg(error);
          setCreateEventToggle(false);
        }
      });
  }, [
    createEventToggle,
    hostName,
    hostWebUrl,
    eventName,
    eventDate,
    startTime,
    endTime,
    buildings,
    maxGuestPerBooking,
    maxTicketsForEvent,
    eventAddLine1,
    eventAddLine2,
    city,
    postcode,
    latitude,
    longitude,
    eventDescription,
    images,
    ticketCost,
    eventType,
  ]);

  const calTotalCost = () => {
    setTotalCost(Number(ticketCost) + Number((ticketCost * vatRate) / 100));
  };
  useEffect(() => {
    if (flag) {
      calTotalCost();
    }
  }, [ticketCost]);

  useEffect(() => {
    if (eventType === 'Free') {
      setTicketCost('0.00');
      setTotalCost('0.00');
    }
    if (eventType === 'Paid' && !id) {
      setTicketCost('');
    }
  }, [eventType]);

  const errorType = errorMsg && errorMsg.type;
  const errorFieldName = errorMsg && errorMsg.path;
  const errorFieldValue = errorMsg && errorMsg.value;

  const popUpOpen = () => setWarningPopUp(true);
  const popUpClose = () => setWarningPopUp(false);

  const open = Boolean(anchorEl);
  const openId = open ? 'simple-popover' : undefined;

  const breadCrumbs = !id
    ? {
        listBreadCrumbPath: '/events-list',
        listBreadCrumbTitle: 'Events',
        addBreadCrumbPath: '/create-an-event',
        addBreadCrumbTitle: 'Create New Event',
      }
    : {
        listBreadCrumbPath: '/events-list',
        listBreadCrumbTitle: 'Events',
        viewBreadCrumbPath: '/view-event-details/',
        viewBreadCrumbTitle: 'View Event',
        editBreadCrumbPath: '/edit-an-event/',
        editBreadCrumbTitle: 'Edit Event',
      };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }
  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addMeetingRoomUserContainer">
            <div className={`${classes.addUserForm} offerForm`}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Event Details</FormLabel>
                </li>
                <li>
                  <RadioGroup
                    aria-label="Active"
                    name="Available"
                    defaultValue="Free"
                  >
                    <div className={`${classes.radioSection} radioSection`}>
                      <FormControlLabel
                        control={<Radio />}
                        disabled={id}
                        label="Free"
                        checked={eventType === 'Free'}
                        onChange={() => {
                          setEventType('Free');
                        }}
                      />
                      <FormControlLabel
                        control={<Radio />}
                        disabled={id}
                        label="Paid"
                        checked={eventType === 'Paid'}
                        onChange={() => {
                          setEventType('Paid');
                        }}
                      />
                    </div>
                  </RadioGroup>
                </li>
              </ul>
              <ul className={`${classes.InputSection} textareaSectionHalf`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                    >
                      Host Name*
                    </InputLabel>
                    <Input
                      className="textareaSmallBox"
                      value={hostName}
                      inputProps={{
                        'aria-label': 'offerName',
                        maxLength: '100',
                      }}
                      onChange={e => {
                        setHostName(e.target.value);
                      }}
                    />
                    <div className="descriptionBox nbDescBox">{`${hostName.length} / 100
                    `}</div>
                    {((errorType === 'min' && errorFieldName === 'hostName') ||
                      (errorFieldValue && errorFieldValue.hostName === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        id="hostName"
                      >
                        {hostName === ''
                          ? hostName === '' && 'Host Name is required.'
                          : errorType === 'min' &&
                            errorFieldName === 'hostName' &&
                            'Must be at least 3 characters'}
                      </FormHelperText>
                    )}
                  </FormControl>
                </li>
                <li>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                    className={classes.formLabel}
                  >
                    Host Website URL*
                  </InputLabel>
                  <Input
                    value={hostWebUrl}
                    onChange={e => {
                      setHostWebUrl(e.target.value);
                    }}
                  />
                  {((errorType === 'required' &&
                    errorFieldName === 'hostWebUrl') ||
                    (errorType === 'url' && errorFieldName === 'hostWebUrl') ||
                    (errorFieldValue && errorFieldValue.hostWebUrl === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      id="hostWebUrl"
                    >
                      {hostWebUrl === ''
                        ? hostWebUrl === '' && 'Host Website URL is required.'
                        : errorType === 'url' && 'Please enter a valid url.'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <ul className={`${classes.InputSection} textareaSection`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Event Name*
                  </InputLabel>
                  <Input
                    className="textareaSmallBox"
                    type="text"
                    inputProps={{
                      'aria-label': 'offerName',
                      maxLength: '150',
                    }}
                    disabled={id}
                    value={eventName}
                    onChange={e => {
                      setEventName(e.target.value);
                    }}
                  />
                  <div className="descriptionBox nbDescBox">{`${eventName.length} / 150
                    `}</div>
                  {((errorType === 'min' && errorFieldName === 'eventName') ||
                    (errorFieldValue && errorFieldValue.eventName === '')) && (
                    <FormHelperText className={classes.errorMsg} id="eventName">
                      {eventName === ''
                        ? eventName === '' && 'Event Name is required.'
                        : errorType === 'min' &&
                          errorFieldName === 'eventName' &&
                          'Must be at least 3 characters'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <ul className={`${classes.InputSection} offerDtCat eventDtBox`}>
                <li>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <span>Event Date*</span>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      disabled={id}
                      margin="normal"
                      id="date-picker-inline"
                      variant="inline"
                      format="dd/MM/yyyy"
                      value={eventDate}
                      disablePast={!id}
                      minDateMessage="Event Date can't be the past date"
                      onChange={setEventDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {(errorType === 'required' &&
                    errorFieldName === 'eventDate') ||
                    (errorFieldValue && errorFieldValue.eventDate === null && (
                      <FormHelperText
                        className={classes.errorMsg}
                        id="eventDate"
                      >
                        {eventDate === null && 'Event date is required.'}
                      </FormHelperText>
                    ))}
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Start Time*
                    </InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      disabled={id}
                      value={
                        id
                          ? moment
                              .tz(startTime, timeZone.london)
                              .format('hh:mm a')
                          : startTime
                      }
                      className={classes.formSelectBox}
                      onChange={e => {
                        setStartTime(e.target.value);
                        setResponseError('');
                      }}
                    >
                      {timePicker &&
                        timePicker.map(name => {
                          return (
                            <MenuItem value={name} name={name} key={name}>
                              {name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'startTime') ||
                    responseError ||
                    (errorFieldValue && errorFieldValue.startTime === '')) && (
                    <FormHelperText className={classes.errorMsg} id="startTime">
                      {(startTime === '' && ' Start Time is required.') ||
                        responseError}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      End Time*
                    </InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      className={classes.formSelectBox}
                      disabled={id}
                      value={
                        id
                          ? moment
                              .tz(endTime, timeZone.london)
                              .format('hh:mm a')
                          : endTime
                      }
                      onChange={e => {
                        setEndTime(e.target.value);
                      }}
                    >
                      {timePicker &&
                        timePicker.map(name => {
                          return (
                            <MenuItem value={name} name={name} key={name}>
                              {name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'endTime') ||
                    (errorFieldValue && errorFieldValue.endTime === '') ||
                    (endTime !== '' &&
                      startTime !== '' &&
                      endTimeApiRequest < startTimeApiRequest)) && (
                    <FormHelperText className={classes.errorMsg} id="endTime">
                      {(endTime === '' && 'End time is required') ||
                        (endTimeApiRequest < startTimeApiRequest &&
                          `Can't be earlier than Start time.`)}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <ul className={`${classes.InputSection} offerDtCat eventDtBox`}>
                <li>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                    className={classes.formLabel}
                  >
                    Ticket Cost (ex VAT)*
                  </InputLabel>
                  <Input
                    placeholder="£0.00"
                    value={ticketCost}
                    disabled={eventType !== 'Paid'}
                    inputProps={{
                      'aria-label': 'offerName',
                    }}
                    onChange={e => {
                      const reg = /^[+]?\d{0,5}(\.\d{0,2})?$/;
                      if (e.target.value === '' || reg.test(e.target.value)) {
                        setTicketCost(e.target.value);
                        setFlag(true);
                      }
                    }}
                  />
                  {errorFieldValue && errorFieldValue.ticketCost === '' && (
                    <FormHelperText
                      className={classes.errorMsg}
                      id="ticketCost"
                    >
                      {ticketCost === '' && 'ticketCost* is required.'}
                    </FormHelperText>
                  )}
                </li>
                {eventType === 'Paid' && (
                  <>
                    <li>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                      >
                        VAT Rate*
                      </InputLabel>
                      <Input
                        value={`${vatRate?.toFixed(2)}%`}
                        disabled
                        inputProps={{
                          'aria-label': 'offerName',
                        }}
                        onChange={e => {
                          setVatRate(e.target.value);
                        }}
                      />
                    </li>
                    <li>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                      >
                        Total (inc VAT)*
                      </InputLabel>
                      <Input
                        value={`£${Number(totalCost)?.toFixed(2)}`}
                        // value={Number(totalCost)}
                        placeholder="£0.00"
                        disabled
                        inputProps={{
                          'aria-label': 'offerName',
                        }}
                      />
                    </li>
                  </>
                )}
              </ul>
              <ul className={`${classes.InputSection} offerDtCat eventDtBox`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Max Guests Per Booking*
                    </InputLabel>
                    <Input
                      value={maxGuestPerBooking}
                      inputProps={{
                        'aria-label': 'offerName',
                        maxLength: '2',
                      }}
                      onChange={e => {
                        setMaxGuestPerBooking(
                          e.target.value > 30 ? '' : e.target.value,
                        );
                      }}
                    />
                    {maxGuestPerBooking === '' && (
                      <div className="vatText">(Max 30 guests)</div>
                    )}
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'maxGuestPerBooking') ||
                    (errorFieldValue &&
                      errorFieldValue.maxGuestPerBooking === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      id="maxGuestPerBooking"
                    >
                      {maxGuestPerBooking === '' &&
                        'Max Guests Per Booking is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Max Tickets For Event*
                    </InputLabel>
                    <Input
                      inputProps={{
                        'aria-label': 'offerName',
                        maxLength: '3',
                      }}
                      value={maxTicketsForEvent}
                      onChange={e => {
                        const re = /^[0-9]+$/;
                        if (
                          e.target.value === '' ||
                          (re.test(e.target.value) && e.target.value > 0)
                        ) {
                          setMaxTicketsForEvent(e.target.value);
                        }
                      }}
                    />
                    {maxTicketsForEvent === '' && (
                      <div className="vatText">(Max 999 tickets)</div>
                    )}
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'maxTicketsForEvent') ||
                    (errorFieldValue &&
                      errorFieldValue.maxTicketsForEvent === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      id="maxTicketsForEvent"
                    >
                      {maxTicketsForEvent === '' &&
                        'Max Tickets For Event is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>&nbsp;</li>
              </ul>
              <ul className={`${classes.InputSection} physicalType`}>
                <li>
                  <ul className="physicalTypeSub">
                    <li>
                      <span>Event Address line 1*</span>
                      <Input
                        type="text"
                        inputProps={{ 'aria-label': 'offerName' }}
                        value={eventAddLine1}
                        disabled={id}
                        onChange={e => {
                          setEventAddLine1(e.target.value);
                        }}
                      />
                      {((errorType === 'min' &&
                        errorFieldName === 'eventAddLine1') ||
                        (errorFieldValue &&
                          errorFieldValue.eventAddLine1 === '')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          id="eventAddLine1"
                        >
                          {eventAddLine1 === ''
                            ? eventAddLine1 === '' &&
                              `Event Address line 1 is required.`
                            : errorType === 'min' &&
                              errorFieldName === 'eventAddLine1' &&
                              'Must be at least 3 characters'}
                        </FormHelperText>
                      )}
                    </li>
                    <li>
                      <span>Event Address line 2</span>
                      <Input
                        type="text"
                        disabled={id}
                        inputProps={{ 'aria-label': 'offerName' }}
                        value={eventAddLine2}
                        onChange={e => {
                          setEventAddLine2(e.target.value);
                        }}
                      />
                    </li>
                    <li>
                      <span>City*</span>
                      <Input
                        type="text"
                        inputProps={{ 'aria-label': 'offerName' }}
                        value={city}
                        disabled={id}
                        onChange={e => {
                          const re = /^[A-Za-z0-9 ]+$/;
                          if (
                            e.target.value === '' ||
                            re.test(e.target.value)
                          ) {
                            setCity(e.target.value);
                          }
                        }}
                      />
                      {((errorType === 'min' && errorFieldName === 'city') ||
                        (errorFieldValue && errorFieldValue.city === '')) && (
                        <FormHelperText className={classes.errorMsg} id="city">
                          {city === ''
                            ? city === '' && `City is required.`
                            : errorType === 'min' &&
                              errorFieldName === 'city' &&
                              'Must be at least 3 characters'}
                        </FormHelperText>
                      )}
                    </li>
                  </ul>
                  <ul className="eventDtBox">
                    <li>
                      <span>Postcode*</span>
                      <Input
                        type="text"
                        disabled={id}
                        inputProps={{
                          'aria-label': 'offerName',
                          maxLength: '9',
                        }}
                        value={postcode}
                        onChange={e => {
                          const re = /^[A-Za-z0-9 ]+$/;
                          if (
                            e.target.value === '' ||
                            re.test(e.target.value)
                          ) {
                            setPostcode(e.target.value);
                          }
                        }}
                      />
                      {((errorType === 'min' &&
                        errorFieldName === 'postcode') ||
                        (errorFieldValue &&
                          errorFieldValue.postcode === '')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          id="postcode"
                        >
                          {postcode === ''
                            ? postcode === '' && `Postcode is required.`
                            : errorType === 'min' &&
                              errorFieldName === 'postcode' &&
                              'Postcode should be min 3 digits'}
                        </FormHelperText>
                      )}
                    </li>
                    <li>
                      <span>Postcode Alias*</span>
                      <Input
                        type="text"
                        inputProps={{
                          maxLength: '4',
                        }}
                        value={postCodeAlias}
                        onChange={e => {
                          const ref = /^[A-Za-z0-9 ]+$/;

                          if (
                            e.target.value === '' ||
                            ref.test(e.target.value)
                          ) {
                            setPostCodeAlias(e.target.value);
                          }
                        }}
                      />
                      {((errorType === 'min' &&
                        errorFieldName === 'PostcodeAlias') ||
                        (errorFieldValue &&
                          errorFieldValue.postcode === '')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          id="PostcodeAlias"
                        >
                          {postCodeAlias === ''
                            ? postCodeAlias === '' &&
                              `Postcode Alias is required.`
                            : errorType === 'min' &&
                              errorFieldName === 'PostcodeAlias' &&
                              'Postcode Alias should be min 3 digits'}
                        </FormHelperText>
                      )}
                    </li>
                    <li>&nbsp;</li>
                  </ul>
                  <ul className="eventDtBox">
                    <li>
                      <span>Latitude co-ordinate*</span>
                      <Input
                        className={classes.input}
                        type="number"
                        disabled={id}
                        inputProps={{ 'aria-label': 'offerName' }}
                        value={latitude}
                        onChange={e => {
                          const re =
                            /^[-+]?((\d((\.)|\.\d{1,6})?)|(0*?[0-8]\d((\.)|\.\d{1,6})?)|(0*?90((\.)|\.0{1,6})?))$/;
                          if (
                            e.target.value === '' ||
                            re.test(e.target.value)
                          ) {
                            setLatitude(e.target.value);
                          }
                        }}
                      />
                      {((errorType === 'min' &&
                        errorFieldName === 'latitude') ||
                        (errorFieldValue &&
                          errorFieldValue.latitude === '')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          id="latitude"
                        >
                          {latitude === ''
                            ? latitude === '' && `Latitude is required.`
                            : errorType === 'min' &&
                              errorFieldName === 'latitude' &&
                              'Latitude should be min 3 digits'}
                        </FormHelperText>
                      )}
                    </li>
                    <li>
                      <span>Longitude co-ordinate*</span>
                      <Input
                        className={classes.input}
                        type="number"
                        disabled={id}
                        inputProps={{ 'aria-label': 'offerName' }}
                        value={longitude}
                        onChange={e => {
                          const re =
                            /^(\+|-)?((\d((\.)|\.\d{1,6})?)|(0*?\d\d((\.)|\.\d{1,6})?)|(0*?1[0-7]\d((\.)|\.\d{1,6})?)|(0*?180((\.)|\.0{1,6})?))$/;
                          if (
                            e.target.value === '' ||
                            re.test(e.target.value)
                          ) {
                            setLongitude(e.target.value);
                          }
                        }}
                      />
                      {((errorType === 'min' &&
                        errorFieldName === 'longitude') ||
                        (errorFieldValue &&
                          errorFieldValue.longitude === '')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          id="longitude"
                        >
                          {longitude === ''
                            ? longitude === '' && `Longitude is required.`
                            : errorType === 'min' &&
                              errorFieldName === 'longitude' &&
                              'Longitude should be min 3 digits'}
                        </FormHelperText>
                      )}
                    </li>
                    <li>
                      <ul
                        className={`${classes.leftFilterIn} offerlocationFilterDropdown eventLocationF`}
                      >
                        <InputLabel shrink className={classes.formLabel}>
                          Village/Building Eligibilty*
                        </InputLabel>
                        <LocationFilter
                          selectAll
                          AddOffersToggle
                          errorType={errorType}
                          errorFieldName={errorFieldName}
                          errorFieldValue={errorFieldValue}
                          buildingArray={buildingArray}
                          locationSearch={locationSearch}
                          anchorEl={anchorEl}
                          setAnchorEl={setAnchorEl}
                          setLocationSearch={setLocationSearch}
                          handleClose={handleClose}
                          setOnCheckedLocationButton={
                            setOnCheckedLocationButton
                          }
                          OnCheckedLocationButton={OnCheckedLocationButton}
                          id={openId}
                          editId={id}
                          buildingGroupsList={buildingGroupsList}
                          buildings={buildings}
                          setBuildings={setBuildings}
                          villages={villages}
                          setVillages={setVillages}
                        />
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>

              <ul className={`${classes.InputSection} richTextEdit`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                    >
                      Events Description*
                    </InputLabel>
                    <textarea
                      type="text"
                      maxLength="2000"
                      inputProps={{ 'aria-label': 'offerName' }}
                      value={eventDescription}
                      onChange={e => {
                        setEventDescription(e.target.value);
                      }}
                    />
                    <div className="descriptionBox">{`${eventDescription.length} / 2000
                    
                    `}</div>
                    {((errorType === 'min' &&
                      errorFieldName === 'eventDescription') ||
                      (errorFieldValue &&
                        errorFieldValue.eventDescription === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        id="eventDescription"
                      >
                        {eventDescription === ''
                          ? eventDescription === '' &&
                            'Events Description is required.'
                          : errorType === 'min' &&
                            errorFieldName === 'eventDescription' &&
                            'Must be at least 3 characters'}
                      </FormHelperText>
                    )}
                  </FormControl>
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Media</FormLabel>
                </li>
              </ul>

              <ul className="offerImgUoload">
                <li>
                  <FileUpload
                    values={{
                      images,
                      setImages,
                      errorType,
                      errorFieldName,
                      errorFieldValue,
                    }}
                    offer
                    events
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        <FormControlLabel
                          control={<Radio />}
                          label="Published"
                          checked={status === 'Published'}
                          onChange={() => {
                            setStatus('Published');
                          }}
                          disabled={
                            moment
                              .tz(startTime, timeZone.london)
                             <moment().tz(timeZone.london)
                          }
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Unpublished"
                          checked={status === 'Unpublished'}
                          onChange={() => {
                            setStatus('Unpublished');
                            popUpOpen();
                          }}
                          disabled={
                            moment
                              .tz(startTime, timeZone.london)
                             <moment().tz(timeZone.london)
                          }
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <SaveAndCancelButtons
                setToggle={setCreateEventToggle}
                route="/events-list"
              />
              <Dialog
                open={warningPopUp}
                onClose={popUpClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="cancelDialogOpend"
              >
                <div className={classes.cancelDialog}>
                  <h2>Users will no longer be able to book this event. </h2>
                  <p>Are you sure?</p>
                </div>
                <Divider className="formDivider popupDivider" light />
                <DialogActions
                  className={`${classes.formButton} popupFormButton`}
                >
                  <Button
                    onClick={() => {
                      setStatus('Published');
                      setWarningPopUp(false);
                    }}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={popUpClose} autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              <WarningPopup />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateOrEditEvent;
