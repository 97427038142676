import React from 'react';
import { useHistory } from 'react-router-dom';
import { Fab, makeStyles } from '@material-ui/core';
import addButton from '../../images/buttons-add.svg';

const useStyles = makeStyles(() => ({
  addIcon: {
    position: 'fixed',
    zIndex: '999',
    right: '55px',
    bottom: '55px',
  },
  fab: {
    background: '#000',
    color: '#fff',
    '&:hover': {
      background: '#000',
      color: '#fff',
    },
  },
}));

const AddButton = ({ route }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.addIcon}>
      <Fab
        className={classes.fab}
        onClick={() => {
          history.push(route);
        }}
      >
        <img src={addButton} alt="addButton" />
      </Fab>
    </div>
  );
};

export default AddButton;
