import React, { useEffect, useState } from 'react';
import { TablePagination } from '@material-ui/core';

const Pagination = props => {
  const { page, setPage, setOffset, totalTenants } = props;
  const [rowsPerPage, setRowsPerPage] = useState(40);

  useEffect(() => {
    if (totalTenants < 40) {
      setPage(0);
      setOffset(0);
    }
  }, [totalTenants]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(newPage > 0 ? newPage * 40 : newPage * 40);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <TablePagination
        rowsPerPageOptions={[40]}
        component="div"
        count={totalTenants}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
export default Pagination;
