/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { schema } from '../../utils/formValidate';
import dowmArw from '../../images/down-arw.svg';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import './adduser.css';
import { GlobalContext } from '../../context/GlobalContext';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import { buildingsLimit } from '../../utils/constants';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
    position: 'static',
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '0',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
}));

const Adduser = () => {
  const classes = useStyles();
  const { setSuccessMessage } = useContext(GlobalContext);
  const history = useHistory();
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userJobTitle, setUserJobTitle] = useState('');
  const [userMail, setUserMail] = useState('');
  const [userFullMail, setUserFullMail] = useState('');
  const [userCustomMail, setUserCustomMail] = useState(userFullMail);
  const [clearInput, setClearInput] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [saveFormFileds, setSaveFormFileds] = useState(false);
  const [userTenant, setUserTenant] = useState([]);
  const [userSelectedTenant, setUserSelectedTenant] = useState('');
  const [userTenantId, setUserTenantId] = useState('');
  const [userTenantName, setUserTenantName] = useState('');
  const [userTenantNewId, setUserTenantNewId] = useState([]);
  const [userBuilding, setUserBuilding] = useState([]);
  const [userBuildingId, setUserBuildingId] = useState('');
  const [userBuildingName, setUserBuildingName] = useState('');
  const [userSelectedBuilding, setUserSelectedBuilding] = useState('');
  const [userVillage, setUserVillage] = useState('');
  const [errorMsgs, setErrorMsgs] = useState(null);
  const [emailExists, setEmailExists] = useState('');
  const [loading, setLoading] = useState(true);
  const [jobTitlesList, setJobTitlesList] = useState([]);
  console.log('jobTitlesList', jobTitlesList);
  if (!JSON.parse(localStorage.getItem('rolePermission'))[0].createTenantUser) {
    history.push('/tenantusers');
  }
  useEffect(() => {
    const tenantsPayload = {
      searchText: '',
      sortOrder: 'asc',
      sortBy: 'name',
      offset: 0,
      limit: 500,
    };
    ApiClient.apiPost(apiEndPoints.getTenants, tenantsPayload)
      .then(data => {
        setUserTenant(data && data.data && data.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
    ApiClient.apiGet(apiEndPoints.getJobTitles)
      .then(data => {
        setJobTitlesList(data && data.data && data.data.data);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  }, []);

  const builldingData = tenant_Id => {
    const buildingsPayload = {
      tenantId: tenant_Id,
      searchText: '',
      sortBy: 'name',
      sortOrder: 'asc',
      offset: 0,
      limit: buildingsLimit,
    };
    ApiClient.apiPost(apiEndPoints.getBuilding, buildingsPayload)
      .then(builldingsData => {
        setUserBuilding(
          builldingsData && builldingsData.data && builldingsData.data.data,
        );
        setUserSelectedBuilding('');
        setUserVillage('');
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  useEffect(() => {
    schema
      .validate({
        FirstName: userFirstName,
        LastName: userLastName,
        jobTitle: userJobTitle,
        role: userRole,
        email: `${userMail}${userFullMail === 'custom' ? '' : '@'}${
          userFullMail !== 'custom' ? userFullMail : userCustomMail
        }`,
        tenant: userSelectedTenant,
        building: userSelectedBuilding.name,
        village: userVillage,
      })
      .then(() => {
        if (saveFormFileds) {
          setLoading(true);
          const payload = {
            firstName: userFirstName,
            lastName: userLastName,
            email: `${userMail}${userFullMail === 'custom' ? '' : '@'}${
              userFullMail !== 'custom' ? userFullMail : userCustomMail
            }`,
            role: userRole,
            jobTitle: userJobTitle,
            building: {
              id: userBuildingId,
              name: userBuildingName,
              village: userVillage,
            },
            tenant: {
              id: userTenantId,
              name: userTenantName,
            },
          };
          ApiClient.apiPost(apiEndPoints.tenantUsers, payload)
            .then(data => {
              const successmsg =
                data && data.data.message
                  ? `${payload.firstName} ${payload.lastName} has been added successfully!`
                  : '';
              setSuccessMessage(successmsg);
              setLoading(false);
              history.push('/tenantusers');
            })
            .catch(error => {
              console.log(error.message, 'error1');
              setSaveFormFileds(false);
              setEmailExists(error.response.status);
              setLoading(false);
            });
        }
      })
      .catch(err => {
        console.log(err, 'error2');
        if (saveFormFileds) {
          setErrorMsgs(err);
          setSaveFormFileds(false);
        }
      });
  }, [
    userFirstName,
    userLastName,
    userFullMail,
    userCustomMail,
    userMail,
    userJobTitle,
    userBuilding,
    userSelectedTenant,
    userTenant,
    userRole,
    userVillage,
    saveFormFileds,
  ]);

  const handleClick = buildingId => {
    setUserBuildingName(buildingId.value.name);
    const villaheAutoPop =
      userBuilding &&
      userBuilding.filter(data => data.id === buildingId.value.id);
    setUserVillage(villaheAutoPop[0].village);
  };

  const handleNewClick = tenantId => {
    const domainList =
      userTenant && userTenant.filter(data => data.id === tenantId);
    if (
      domainList &&
      domainList[0].domains &&
      !domainList[0].domains.includes('custom')
    ) {
      domainList[0].domains.push('custom');
    }
    setUserTenantNewId(domainList && domainList[0].domains);
  };

  const errorType = errorMsgs && errorMsgs.type;
  const errorFieldName = errorMsgs && errorMsgs.path;
  const errorFieldValue = errorMsgs && errorMsgs.value;

  const emailValidation =
    emailExists.toString() === '409'
      ? 'Email already exists'
      : userMail.length > 0 && userFullMail.length > 0
      ? ''
      : 'Email address is required.';

  const breadCrumbs = {
    listBreadCrumbPath: '/tenantusers',
    listBreadCrumbTitle: 'Tenant Users',
    addBreadCrumbPath: '/adduser',
    addBreadCrumbTitle: 'Create Tenant User',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addUserContainer">
            <div className={classes.addUserForm}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Tenant User Details</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id={userTenantId}
                      className={classes.formLabel}
                      value={userSelectedTenant}
                    >
                      Tenant*
                    </InputLabel>
                    <Autocomplete
                      id="combo-box-demo"
                      className="comboBoxSerch"
                      options={userTenant}
                      getOptionLabel={option => option.name}
                      style={{ width: '100%' }}
                      renderInput={params => (
                        <>
                          {!userSelectedTenant ? (
                            <TextField
                              {...params}
                              type="text"
                              value={userSelectedTenant}
                            />
                          ) : (
                            <TextField
                              {...params}
                              type="text"
                              value={userSelectedTenant}
                              inputProps={{
                                ...params.inputProps,
                                value: userTenantName,
                              }}
                            />
                          )}
                        </>
                      )}
                      onChange={(event, newValue) => {
                        const selectedValue =
                          newValue && newValue.id !== null ? newValue.id : '';
                        if (selectedValue && selectedValue.length > 0) {
                          setUserSelectedTenant(selectedValue);
                          setUserTenantId(selectedValue);
                          builldingData(selectedValue);
                          handleNewClick(selectedValue);
                          setUserTenantName(newValue && newValue.name);
                          setUserFullMail('');
                          setUserCustomMail('');
                        } else {
                          setUserSelectedTenant('');
                          setUserTenantName('');
                          setUserTenantId('');
                          setUserCustomMail('');
                          setUserFullMail('');
                        }
                      }}
                    />
                  </FormControl>
                  {((errorType === 'required' && errorFieldName === 'tenant') ||
                    (errorFieldValue && errorFieldValue.tenant === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={userSelectedTenant === '' && 'Tenant is required.'}
                    >
                      {userSelectedTenant === '' && 'Tenant is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id={userBuildingId}
                      className={classes.formLabel}
                      value={userSelectedBuilding}
                    >
                      Building*
                    </InputLabel>

                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      value={userSelectedBuilding}
                      className={classes.formSelectBox}
                      onChange={e => {
                        setUserSelectedBuilding(e.target.value);
                        setUserBuildingId(e.target.value.id);
                        setUserBuildingName(e.target.name);
                        handleClick(e.target);
                      }}
                    >
                      {userBuilding &&
                        userBuilding.map(building => {
                          return (
                            <MenuItem
                              value={building}
                              name={building.name}
                              key={building.id}
                            >
                              {building.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'building') ||
                    (errorFieldValue && errorFieldValue.building === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        userSelectedBuilding === '' && 'Building is required.'
                      }
                    >
                      {userSelectedBuilding === '' && 'Building is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li
                  className={`${classes.InputSection} ${classes.InputSectionReadonly}`}
                >
                  <FormControl className={classes.formControl}>
                    <span
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                      value={userVillage}
                    >
                      Village*
                    </span>
                    <Input
                      type="text"
                      autoComplete
                      readOnly
                      inputProps={{ 'aria-label': 'description' }}
                      value={userVillage}
                    />
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'village') ||
                    (errorFieldValue && errorFieldValue.village === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={userVillage === '' && 'Village is required.'}
                    >
                      {userVillage === '' && 'Village is required.'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className={`${classes.InputSection} inputSectionAlnBtm`}>
                <li>
                  <span>First name*</span>
                  <Input
                    type="text"
                    inputProps={{ 'aria-label': 'description' }}
                    value={userFirstName}
                    onChange={e => {
                      setUserFirstName(e.target.value);
                    }}
                  />
                  {((errorType === 'matches' &&
                    errorFieldName === 'FirstName') ||
                    (errorType === 'required' &&
                      errorFieldName === 'FirstName') ||
                    (errorFieldValue && errorFieldValue.FirstName === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        errorType === 'matches'
                          ? 'First name should contain only letters'
                          : userFirstName === '' && 'First Name is required.'
                      }
                    >
                      {errorType === 'matches'
                        ? 'First name should contain only letters.'
                        : userFirstName === '' && 'First Name is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <span>Last name*</span>
                  <Input
                    type="text"
                    inputProps={{ 'aria-label': 'description' }}
                    value={userLastName}
                    onChange={e => {
                      setUserLastName(e.target.value);
                    }}
                  />
                  {((errorType === 'matches' &&
                    errorFieldName === 'LastName') ||
                    (errorType === 'required' &&
                      errorFieldName === 'LastName') ||
                    (errorFieldValue && errorFieldValue.LastName === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        errorType === 'matches' &&
                        errorMsgs &&
                        errorMsgs.path === 'LastName'
                          ? 'Last name should only contain letters'
                          : userLastName === '' && 'Last Name is required. '
                      }
                    >
                      {errorType === 'matches' &&
                      errorMsgs &&
                      errorMsgs.path === 'LastName'
                        ? 'Last name should only contain letters'
                        : userLastName === '' && 'Last Name is required. '}
                    </FormHelperText>
                  )}
                </li>

                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                      value={userJobTitle}
                    >
                      Job role*
                    </InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      value={userJobTitle}
                      className={classes.formSelectBox}
                      onChange={e => {
                        setUserJobTitle(e.target.value);
                      }}
                    >
                      {jobTitlesList &&
                        jobTitlesList.map(item => {
                          return (
                            <MenuItem value={item.jobTitle} key={item.id}>
                              {item.jobTitle}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'jobTitle') ||
                    (errorFieldValue && errorFieldValue.jobTitle === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={userJobTitle === '' && 'Job Role is required.'}
                    >
                      {userJobTitle === '' && 'Job Role is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li />
                <li>
                  <span>Email address*</span>
                  <Input
                    inputProps={{ 'aria-label': 'description' }}
                    value={userMail}
                    onChange={e => {
                      const re = /^[A-Za-z0-9.]+$/;
                      if (e.target.value === '' || re.test(e.target.value)) {
                        setUserMail(e.target.value);
                      }
                    }}
                  />
                  {(errorType === 'email' ||
                    errorFieldName === 'email' ||
                    emailExists.toString() === '409' ||
                    (errorFieldValue && errorFieldValue.email === '@') ||
                    (errorType === 'matches' &&
                      errorFieldName === 'email')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        userMail === '' ||
                        userFullMail === '' ||
                        errorType === 'matches' ||
                        errorFieldName === 'email'
                          ? 'Please enter a valid email.'
                          : userMail + (userFullMail || userCustomMail) ===
                              '' ||
                            userMail === '' ||
                            userFullMail === '' ||
                            emailValidation
                      }
                    >
                      {userMail === '' ||
                      userFullMail === '' ||
                      errorType === 'matches' ||
                      errorFieldName === 'email'
                        ? 'Please enter a valid email.'
                        : userMail + (userFullMail || userCustomMail) === '' ||
                          userMail === '' ||
                          userFullMail === '' ||
                          emailValidation}
                    </FormHelperText>
                  )}
                </li>
                <li className="customMail">
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                      value={userFullMail}
                    >
                      Domain*
                    </InputLabel>
                    {userFullMail === 'custom' && clearInput === false ? (
                      <Input
                        autoFocus
                        type="text"
                        inputProps={{ 'aria-label': 'description' }}
                        placeholder="@gmail.com"
                        value={userCustomMail}
                        onChange={e => {
                          const re = /^[A-Za-z.@]+$/;
                          if (
                            e.target.value === '' ||
                            re.test(e.target.value)
                          ) {
                            setUserCustomMail(e.target.value);
                            setClearInput(false);
                          }
                        }}
                      />
                    ) : (
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        className={classes.formSelectBox}
                        value={userFullMail === 'custom' ? '' : userFullMail}
                        onChange={e => {
                          setUserFullMail(e.target.value);
                          setClearInput(false);
                        }}
                      >
                        {userTenantNewId &&
                          userTenantNewId.map(domain => {
                            return (
                              <MenuItem value={domain} key={domain.id}>
                                {`${domain !== 'custom' ? '@' : ''}${domain}`}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  </FormControl>
                  {userFullMail === 'custom' && clearInput === false && (
                    <Button
                      class="clearField"
                      onClick={() => {
                        setUserCustomMail('');
                        setClearInput(!clearInput);
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  )}
                </li>
                <Divider className="formDivider" light />
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                      value={userRole}
                    >
                      Role
                    </InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      value={userRole}
                      className={classes.formSelectBox}
                      onChange={e => {
                        setUserRole(e.target.value);
                      }}
                    >
                      <MenuItem value="Super Administrator">Super Administrator</MenuItem>
                    </Select>
                  </FormControl>
                  {((errorType === 'required' && errorFieldName === 'role') ||
                    (errorFieldValue && errorFieldValue.role === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        userRole === '' && 'Role in tenant portal is required.'
                      }
                    >
                      {userRole === '' && 'Role in tenant portal is required.'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <SaveAndCancelButtons
                setToggle={setSaveFormFileds}
                route="/tenantusers"
              />
              <WarningPopup />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Adduser;
