/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  makeStyles,
  Popover,
} from '@material-ui/core';
import * as lodash from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';
import minus from '../../images/minus.svg';
import './Filters.css';

export const useStyles = makeStyles({
  leftFilterIn: {
    display: 'flex',
    alignItems: 'center',
    '& li': {
      marginRight: '15px',
      color: '#838384',
      fontSize: '14px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      position: 'relative',
      '&:last-child': {
        paddingBottom: '2px',
        borderBottom: '1px solid #979797',
        cursor: 'pointer',
      },
      '& button': {
        color: '#444444',
        backgroundColor: '#f8f8f8',
        padding: '12px 12px 10px',
        fontWeight: 'bold',
        boxShadow: 'none',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        borderRadius: 5,
        '&:hover': {
          boxShadow: 'none',
          background: '#f8f8f8',
        },
        '& span': {
          lineHeight: '100%',
          alignItems: 'center',
          display: 'flex',

          '& small': {
            marginLeft: 20,
            top: '-2px',
            position: 'relative',
          },
          '& img': {
            '&:nth-child(2)': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  filterMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightFilter: {
    display: 'flex',
    alignItems: 'center',
  },
  exportSerch: {
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      '& li': {
        marginLeft: '20px',
      },
    },
  },
  pageListMain: {
    position: 'relative',
  },
  popupBadge: {
    background: '#000000',
    borderRadius: '11px',
    fontSize: '16px',
    color: '#fff',
    width: '50%',
    margin: '0 auto',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    padding: ' 14px 0',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    width: '90%',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:first-child': {
            '& span': {
              '& svg': {
                fill: '#979797',
                fontSize: '19px',
              },
            },
          },
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:first-child': {
            '& span': {
              '& svg': {
                fill: '#979797',
                fontSize: '19px',
              },
            },
          },
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: 'calc(100% - 40px)',
          },
        },
      },
    },
  },
  active: {
    '& button': {
      background: '#000 !important',
      color: '#fff !important',
      '& span': {
        color: '#fff !important',
        '& small': {
          '& img': {
            '&:first-child': {
              display: 'none',
            },
            '&:last-child': {
              display: 'none',
            },
            '&:nth-child(2)': {
              display: 'block !important',
            },
          },
        },
        '& i': {
          display: 'none',
        },
      },
    },
  },
  closeIconBtn: {
    display: 'none',
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    textTransform: 'capitalize',
  },
  valueSelected: {
    '& button': {
      '& span': {
        '& small': {
          '& img': {
            '&:first-child': {
              display: 'none',
            },
            '&:last-child': {
              visibility: 'hidden',
              opacity: '0',
            },
          },
        },
      },
    },
    '& i': {
      display: 'block !important',
      cursor: 'pointer',
    },
  },
});
const LocationFilter = props => {
  const classes = useStyles();
  const {
    locationSearch,
    setAnchorEl,
    anchorEl,
    setLocationSearch,
    OnCheckedLocationButton,
    setOnCheckedLocationButton,
    handleClose,
    id,
    buildingGroupsList,
    buildings,
    villages,
    setVillages,
    setBuildings,
    AddOffersToggle,
    errorType,
    errorFieldName,
    errorFieldValue,
    editId,
    bigCalender,
    setTransformedList,
    buidlingLocalStorage,
    setBuidlingLocalStorage,
    buildingAttributes,
    selectedBuildingName,
    selectAll,
    selectedBuildingId,
    setSelectedBuildingId,
    setSelectedTenants,
    setSelectedMRs,
    setCacheData,
  } = props;
  const history = useHistory();
  const { buildingId } = useParams();
  const [checked, setIschecked] = useState(true);

  if (bigCalender && buildings.length > 0) {
    setCacheData('/', 'buildingData', buildings);
  }

  useEffect(() => {
    if (
      bigCalender &&
      buidlingLocalStorage &&
      buidlingLocalStorage.length > 0 &&
      buildings &&
      buildings.length === 0
    ) {
      setBuildings(buidlingLocalStorage);
    }
  }, [buidlingLocalStorage && buidlingLocalStorage.length > 0]);

  const buildingArray = [];
  const buildingListArray = [];
  if (editId) {
    buildingGroupsList.map(item => {
      item[1].map(val => {
        if (buildings && buildings.includes(val.id)) {
          buildingArray.push({ id: val.id, village: val.village });
        }
      });
    });
    const buildingGroups = lodash.mapValues(
      lodash.groupBy(buildingArray, 'village'),
      buildingList => buildingList.map(building => building),
    );
    const buildingGroups2 = Object.entries(buildingGroups);
    buildingListArray.push(buildingGroups2);
    if (buildingListArray) {
      buildingListArray[0].map(building => {
        [buildingGroupsList][0].map(list => {
          if (list[0] === building[0]) {
            if (list[1].length === building[1].length) {
              villages.push(list[0]);
            }
          }
        });
      });
    }
  }

  const removeRepeatBuildings =
    buildings &&
    buildings.filter((value, index) => buildings.indexOf(value) === index);

  const removeRepeatVillages =
    villages &&
    villages.filter((value, index) => villages.indexOf(value) === index);

  const onVillageChange = (e, data) => {
    if (e.target.checked) {
      setVillages([...villages, data[0]]);
      setBuildings([...buildings, ...data[1].map(z => z.id)]);
      setSelectedBuildingId &&
        setSelectedBuildingId([
          ...selectedBuildingId,
          ...data[1].map(z => z.buildingId),
        ]);
      setIschecked(!checked);
    } else {
      setVillages(villages.filter(x => x !== data[0]));
      const difference = buildings.filter(
        d => !data[1].map(z => z.id).includes(d),
      );
      const differenceForBuildingId =
        selectedBuildingId &&
        selectedBuildingId.filter(
          d => !data[1].map(z => z.buildingId).includes(d),
        );
      setBuildings(difference);
      setSelectedBuildingId && setSelectedBuildingId(differenceForBuildingId);
      setIschecked(!checked);
    }
  };

  const onBuildingChange = (e, data, building, buildId) => {
    if (e.target.checked) {
      if (
        data[1].map(z => z.id).every(i => [...buildings, building].includes(i))
      ) {
        setVillages([...villages, data[0]]);
      }
      setBuildings([...buildings, building]);
      setSelectedBuildingId &&
        setSelectedBuildingId([...selectedBuildingId, buildId]);
      setIschecked(!checked);
    } else {
      setVillages(villages.filter(x => x !== data[0]));
      const difference = buildings.filter(d => d !== building);
      const differenceForBuildingId =
        selectedBuildingId && selectedBuildingId.filter(d => d !== buildId);
      setBuildings(difference);
      setSelectedBuildingId && setSelectedBuildingId(differenceForBuildingId);
      setIschecked(!checked);
    }
  };
  const buildingsList =
    buildingGroupsList &&
    buildingGroupsList.map(item => item && item[1].map(i => i.id));
  const buildingsListForBuildingId =
    buildingGroupsList &&
    buildingGroupsList.map(item => item && item[1].map(i => i.buildingId));

  const villagesList =
    buildingGroupsList && buildingGroupsList.map(item => item[0]);

  const isAllVillagesSelected = () =>
    villagesList.every(item => removeRepeatVillages.includes(item)) &&
    removeRepeatVillages.every(item => villagesList.includes(item));

  const onSelectAll = () => {
    setVillages([...villages, ...villagesList]);
    setBuildings([...buildings, ...buildingsList.flat()]);
    setSelectedBuildingId &&
      setSelectedBuildingId([
        ...selectedBuildingId,
        ...buildingsListForBuildingId.flat(),
      ]);
    setIschecked(!checked);
  };

  const onDeSelectAll = () => {
    setVillages([]);
    setBuildings([]);
    setSelectedBuildingId && setSelectedBuildingId([]);
  };

  return (
    <>
      <li
        className={
          OnCheckedLocationButton === true ||
          (buildings && buildings.length > 0) ||
          buildingId
            ? `${classes.active} ${
                (buildings && buildings.length > 0) || buildingId
                  ? classes.valueSelected
                  : ''
              }`
            : ''
        }
      >
        <Button
          className="buildSelectedBtn"
          aria-describedby={id}
          variant="contained"
          onClick={event => {
            setAnchorEl(event.currentTarget);
            setLocationSearch(true);
            setOnCheckedLocationButton(true);
          }}
        >
          {AddOffersToggle
            ? buildings.length > 0
              ? (buildings.length === 1 &&
                  `${removeRepeatBuildings.length} Building Selected`) ||
                `${removeRepeatBuildings.length} Buildings Selected`
              : 'Select Location(s)'
            : bigCalender
            ? selectedBuildingName || 'Building'
            : buildingAttributes
            ? 'Village'
            : 'Location'}
          <small>
            <img src={plus} alt="plus" />
            <img src={minus} alt="minus" />
          </small>
        </Button>
        {((errorType === 'min' && errorFieldName === 'locationValidation') ||
          (errorFieldValue &&
            errorFieldValue.locationValidation &&
            errorFieldValue.locationValidation.length === 0)) &&
          AddOffersToggle &&
          !buildings.length > 0 && (
            <FormHelperText className={classes.errorMsg}>
              At least one location is required
            </FormHelperText>
          )}
        <i
          className="closeIconBtn"
          role="presentation"
          onClick={() => {
            if (bigCalender) {
              setCacheData('/', 'buildingData', []);
              setBuidlingLocalStorage([]);
              setSelectedMRs([]);
              history.push('/');
            }
            setBuildings([]);
            setSelectedBuildingId && setSelectedBuildingId([]);
            setVillages([]);
            setAnchorEl('');
            setSelectedTenants && setSelectedTenants([]);
            localStorage.removeItem('selectedNewDate');
            setTransformedList && setTransformedList([]);
          }}
        >
          <img src={close} alt="close" />
        </i>
        {buildingGroupsList && buildingGroupsList.length > 0 && (
          <>
            <Popover
              id="location"
              open={locationSearch}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div className="filterDropdown filterDropdownCheck offerFilterDropdown">
                <div className="filterDropdownin">
                  <div className="filterCheckbox">
                    <h4>LONDON</h4>
                    {buildingGroupsList &&
                      buildingGroupsList.map(data => (
                        <>
                          {selectAll && !isAllVillagesSelected() ? (
                            <Button
                              className="selectAllLoc"
                              onClick={onSelectAll}
                            >
                              Select All
                            </Button>
                          ) : (
                            selectAll && (
                              <Button
                                className="selectAllLoc"
                                onClick={onDeSelectAll}
                              >
                                Deselect All
                              </Button>
                            )
                          )}
                          <div
                            className={`${classes.moreListHead} moreListHead`}
                          >
                            <List key={data[0]}>
                              <ListItem>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="checkedC"
                                      checked={removeRepeatVillages.includes(
                                        data[0],
                                      )}
                                      onChange={e => onVillageChange(e, data)}
                                    />
                                  }
                                  disabled={bigCalender}
                                  label={data[0].toUpperCase()}
                                />
                              </ListItem>
                            </List>
                          </div>
                          <div
                            className={`${classes.filtercheckTop} filtercheckTop`}
                          >
                            <List className={classes.moreListSub} key={data[0]}>
                              {data[1].map(datanew => (
                                <ListItem key={datanew.id}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="checkedC"
                                        checked={
                                          (buildings &&
                                            buildings.includes(datanew.id)) ||
                                          [buildingId].includes(datanew.id)
                                        }
                                        onClick={e =>
                                          onBuildingChange(
                                            e,
                                            data,
                                            datanew.id,
                                            datanew.buildingId,
                                          )
                                        }
                                      />
                                    }
                                    disabled={
                                      (bigCalender &&
                                        buildings &&
                                        buildings.length === 1) ||
                                      (buildingId && bigCalender)
                                    }
                                    label={datanew.name}
                                  />
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </Popover>
          </>
        )}
      </li>
    </>
  );
};
export default LocationFilter;
