import React,{ useContext} from 'react';
import { PaymentGlobalContext } from '../../context/PaymentGlobalContext';

const PaymentError = () => {
  const {failedPaymentError} = useContext(PaymentGlobalContext);
  console.log("error",failedPaymentError)
  return (
    <div className="noResult cardPaymentError">
      <h2>Payment Error</h2>
      <p>{failedPaymentError}</p>
    </div>
  );
};

export default PaymentError;
