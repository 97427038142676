import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Divider } from '@material-ui/core';
import useButtonStyles from '../Styles/useButtonStyles';

const CloseButton = ({ route }) => {
  const classes = useButtonStyles();
  const history = useHistory();

  return (
    <ul className={classes.lineformButtonMain}>
      <li className={classes.lineformButton}>
        <Divider className="formDivider" light />
      </li>
      <li className={classes.formButton}>
        <Button />
        <Button onClick={() => history.push(route)}>Close</Button>
      </li>
    </ul>
  );
};

export default CloseButton;
