/* eslint-disable no-useless-escape */
import * as yup from 'yup';

export const schema = yup.object().shape({
  role: yup.string().required(),
  jobTitle: yup.string().required(),
  email: yup.string().email().required(),
  LastName: yup
    .string()
    .required()
    .matches(/^[A-Za-z0-9 "'._-]+$/),
  FirstName: yup
    .string()
    .required()
    .matches(/^[A-Za-z0-9 ]+$/, { excludeEmptyString: true }),
  village: yup.string().required(),
  building: yup.string().required(),
  tenant: yup.string().required(),
});

export const meetingRoomSchema = yup.object().shape({
  village: yup.string().required(),
  building: yup.string().required(),
  meetingRoom: yup.string().min(3).required(),
  spaceType: yup.string().required(),
  meetingRoomType: yup.string().when('spaceType', {
    is: val => val === 'meetingRoom',
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
  mergeMeetingRoom: yup.string().when('meetingRoomType', {
    is: 'Combined',
    then: yup.string().required('Rooms to merge is required.'),
  }),
  descriptionFiled: yup.string().min(3).required(),
  maxCapacityField: yup.string().required(),
  minCapacity: yup.string().required(),
  layouts: yup.array().min(1).required(),
  equipmetFiled: yup.array().min(1).required(),
  minBookingTimeField: yup.string().required(),
  hourlyRateFiled: yup.string().required(),
  fullDayRateFiled: yup.string().required(),
  imageUploadValidation: yup.array().min(1).required(),
  workshopStyle: yup.array().min(1),
  theaterStyle: yup.array().min(1),
  flexiStyle: yup.array().min(1),
  consultationStyle: yup.array().min(1),
  massageStyle: yup.array().min(1),
  boardroomStyle: yup.array().min(1),
});
export const layoutPopupSchema = yup.object().shape({
  minCapacity: yup.number().required(),
  maxCapacityField: yup.number().required(),
  layoutMaxCapacity: yup
    .number()
    .strict()
    .required('Layout capacity is required.')
    .min(
      yup.ref('minCapacity'),
      'Layout capacity must be greater than or equal to min capacity.',
    )
    .max(
      yup.ref('maxCapacityField'),
      'Layout capacity must be less than or equal to max capacity.',
    ),
  layoutName: yup.string().required(),
  turnaroundTime: yup.string().required(),
  roomLayoutImg: yup.array().min(1).required(),
});
export const viewBookingSchema = yup.object().shape({
  firstName: yup.string().required(),
  guestEmail: yup.string().email().required(),
});
export const digitalOffersSchema = yup.object().shape({
  offerNameValidation: yup.string().min(3).required(),
  startDateValidation: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Start Date is required*'),
  endDateValidation: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('End Date is required*'),
  offerCategoryValidation: yup.string().required(),
  offerTypeValidation: yup.string().required(),
  // voucherCodeValidation: yup.string().min(3),
  offerUrlValidation: yup.string().url().required(),
  descriptionValidation: yup.string().required(),
  termsValidation: yup.string().min(3).required(),
  companyNameValidation: yup.string().min(3).required(),
  companyUrlValidation: yup.string().url().required(),
  locationValidation: yup.array().min(1).required(),
  offerImageValidation: yup.array().min(1).required(),
  companyLogoValidation: yup.string().required(),
  villageValidation: yup.array().min(1).required(),
});

export const physicalOffersSchema = yup.object().shape({
  offerNameValidation: yup.string().min(3).required(),
  startDateValidation: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Start Date is required*'),
  endDateValidation: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('End Date is required*'),
  offerCategoryValidation: yup.string().required(),
  offerTypeValidation: yup.string().required(),
  address1: yup.string().min(3).required(),
  city: yup
    .string()
    .min(3)
    .required()
    .matches(/^[A-Za-z]+$/, { excludeEmptyString: true }),
  country: yup.string().matches(/^[A-Za-z ]+$/, { excludeEmptyString: true }),
  pincode: yup.string().required(),
  latitude: yup.string().required(),
  longitude: yup.string().required(),
  descriptionValidation: yup.string().required(),
  termsValidation: yup.string().min(3).required(),
  companyNameValidation: yup.string().min(3).required(),
  companyUrlValidation: yup.string().url().required(),
  locationValidation: yup.array().min(1).required(),
  villageValidation: yup.array().min(1).required(),
  offerImageValidation: yup.array().min(1).required(),
  companyLogoValidation: yup.string().required(),
});

export const articleAnnouncementSchema = yup.object().shape({
  titleValidation: yup.string().min(3).required(),
  autorValidation: yup.string().notRequired(),
  startDateValidation: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Start Date is required*'),
  endDateValidation: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .notRequired('End Date is required*'),
  descriptionPreviewValidation: yup.string().min(1).required(),
  platformValidation: yup.string().required(),
  descriptionValidation: yup.string().required(),
  // locationValidation: yup.array().min(1).required(),
  mediaTypeValidation: yup.boolean().oneOf([true], 'Message'),
  // tenantValidation: yup.array().min(1).required(),
  checkBox: yup.boolean(),
  locationValidation: yup
    .array()
    .min(1)
    .when('checkBox', {
      is: false,
      then: yup.array().min(1).required(),
      otherwise: yup.array().notRequired(),
    }),
  tenantValidation: yup
    .array()
    .min(1)
    .when('checkBox', {
      is: false,
      then: yup.array().min(1).required(),
      otherwise: yup.array().notRequired(),
    }),
});

export const articleNewsSchema = yup.object().shape({
  titleValidation: yup.string().min(3).required(),
  autorValidation: yup.string().notRequired(),
  startDateValidation: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Start Date is required*'),
  endDateValidation: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .notRequired('End Date is required*'),
  articleCatgeoryValidation: yup.string().required(),
  platformValidation: yup.string().required(),
  descriptionPreviewValidation: yup.string().min(1).required(),
  descriptionValidation: yup.string().required(),
  locationValidation: yup.array().min(1).required(),
  mediaTypeValidation: yup.boolean().oneOf([true], 'Message'),
});

export const cateringMenuSchema = yup.object().shape({
  categoryName: yup.string().required(),
  itemName: yup.string().min(3).required(),
  description: yup.string().min(3).required(),
  pricePerQuantity: yup
    .string()
    .required()
    .matches(/^[0-9. ]+$/, { excludeEmptyString: true }),
  nutritional: yup.array().of(
    yup.object().shape({
      quantity: yup.string().required(),
    }),
  ),
});

export const cateringCategorySchema = yup.object().shape({
  categoryName: yup.string().required(),
  startTime: yup.string().required(),
  endTime: yup.string().required(),
});

export const spaceValidation = yup.object().shape({
  buildingValidation: yup.string().required(),
  unitNameValidation: yup.string().required(),
  unitSizeValidation: yup.string().required(),
  deskValidation: yup.string().required(),
  spaceDescriptionValidation: yup.string().required(),
  startDateValidation: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Start Date is required*'),
  spaceFeatureValidation: yup.array().min(1).required(),
  energyValidation: yup.string().required(),
  // wireValidation: yup.string().required(),
  // videoValidation: yup.string().required(),
  // videoImageValidation: yup.string().required(),
  ImageValidation: yup.array().min(1).required(),
  // FloorImageValidation: yup.string().required(),
});

export const meetingRoomBooking = yup.object().shape({
  startDateValidation: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Start Date is required*'),
  startTimeValidation: yup.string().required('start Time is required*'),
  endTimeValidation: yup.string().nullable().required('end Time is required*'),
  meetingRoom: yup.string().min(3).required(),
  bookingType: yup.string().required(),
  roomLayoutsFields: yup.string().when('bookingType', {
    is: val => val === 'Standard',
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
});

export const customerDetails = yup.object().shape({
  customerType: yup.string().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phoneNumber: yup.string().required(),
  email: yup.string().required(),
  tenant: yup.string().required(),
});

export const buildingAttributesSchema = yup.object().shape({
  description: yup.string().required(),
  features: yup.array().min(1).required(),
  ImageValidation: yup.array().min(1).required(),
  enableVisitorBooking: yup.boolean().required(),
  ThumbnailValidation: yup
    .array()
    .min(1)
    .when('enableVisitorBooking', {
      is: val => val === true,
      then: yup.array().min(1).required(),
      otherwise: yup.array().min(0).notRequired(),
    }),
  advisoryNotes: yup
    .string()
    .min(1)
    .when('enableVisitorBooking', {
      is: val => val === true,
      then: yup.string().min(1).required(),
      otherwise: yup.string().min(0).notRequired(),
    }),
  LogoValidation: yup.array().min(1).required(),
  postCodeAlias: yup.string().required(),
});
export const createEventsSchema = yup.object().shape({
  hostName: yup.string().min(3).required(),
  hostWebUrl: yup.string().url().required(),
  eventName: yup.string().min(3).required(),
  eventDate: yup.date().required(),
  startTime: yup.string().required(),
  endTime: yup.string().required(),
  maxGuestPerBooking: yup.string().required(),
  maxTicketsForEvent: yup.string().required(),
  eventAddLine1: yup.string().required(),
  city: yup.string().required(),
  postcode: yup.string().required(),
  latitude: yup.string().required(),
  longitude: yup.string().required(),
  eventDescription: yup.string().required(),
  offerImage: yup.array().min(1).required(),
  locationValidation: yup.array().min(1).required(),
  eventType: yup.string().required(),
  ticketCost: yup.string().when('eventType', {
    is: val => val === 'Paid',
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
});

export const createVoucherSchema = yup.object().shape({
  voucherCode: yup.string().required(),
  discountType: yup.string().required(),
  discount: yup.string().required(),
  startDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .required('Start Date is required*'),
  endDate: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .notRequired('End Date is required*'),

  voucherDescription: yup.string().required(),
  voucherType: yup.string().required(),
});

export const loyaltyCardSchema = yup.object().shape({
  supplierName: yup.string().required(),
  supplierLocation: yup.string().required(),
  latitude: yup.string().required(),
  langitude: yup.string().required(),
  stamps: yup.string().required(),
  nfc: yup.array().min(1, 'one required').max(10,'NfcIds Should have maximum of 10 NfcIds').required('required'),
  cardDescription: yup.string().required(),
  supplierImage: yup.array().min(1).required(),
  logoImage: yup.array().min(1).required(),
  backgroundColor: yup.string().required(),
  fontColor: yup.string().required(),
});

export const notificationSchema = yup.object().shape({
  notificationTitle: yup.string().required(),
  notificationDescription: yup.string().required(),
  deliveryDate: yup.boolean().required(),
  locationValidation: yup.array().min(1).required(),
  jobFunction: yup.array().min(1).required(),
  notificationLink: yup.object().shape({
    type: yup.string().required(),
  }),
  tenantValidation: yup.array().min(1).required(),
});

export const sendLinkSchema= yup.object().shape({
  userEmail: yup.string().email().required(),
  userConfirmEmail: yup.string().email().oneOf([yup.ref('userEmail'), null], 'Email ids should match'),
});