/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import Loader from 'react-loader-spinner';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';
import minus from '../../images/minus.svg';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import useFilterStyles from '../../components/Styles/useFilterStyles';
import { ManageOffersContext } from '../../context/ManageOffersContext';

const OfferFilters = () => {
  const {
    offersSearch,
    setOffersSearch,
    offerTypes,
    setOfferTypes,
    selectedOfferType,
    offersCategories,
    setOfferCatagoryActive,
    selectedOfferCategory,
    setSelectedOfferCategory,
  } = useContext(ManageOffersContext);

  const classes = useFilterStyles();
  const [offerSearch, setOfferSearch] = useState(false);
  const [offerCategorySearch, setOfferCategorySearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [offerTypeTab, setofferTypeTab] = useState('');
  const [offerCategoryTab, setOfferCategoryTab] = useState('');
  const placeholder = 'Search offers';
  const [checked, setIschecked] = useState(true);

  const onOfferCategory = (e, building) => {
    if (e.target.checked) {
      setSelectedOfferCategory([...selectedOfferCategory, building]);
      setIschecked(!checked);
    } else {
      const difference = selectedOfferCategory.filter(d => d !== building);
      setSelectedOfferCategory(difference);
      setIschecked(!checked);
    }
  };

  const offerTypeOptions = (selected, key) => {
    const matchedOfferType = offerTypes.map(offerType => {
      if (offerType && offerType.key === key) {
        offerType.selected = selected;
      }
      return offerType;
    });
    setOfferTypes(matchedOfferType);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOfferSearch(null);
    setOfferCategorySearch(null);
    setofferTypeTab(false);
    setOfferCategoryTab(false);
  };

  const clearOfferType = () => {
    setOfferTypes([
      { label: 'Digital', key: 'Digital', selected: false },
      { label: 'Physical', key: 'Physical', selected: false },
    ]);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.filerSection}>
      <div className="pageContainer filterContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftFilterIn`}>
              <li
                className={
                  offerCategoryTab === true ||
                  (selectedOfferCategory && selectedOfferCategory.length > 0)
                    ? `${classes.active} ${
                        selectedOfferCategory &&
                        selectedOfferCategory.length > 0
                          ? classes.valueSelected
                          : ''
                      }`
                    : ''
                }
              >
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={event => {
                    setOfferCategorySearch(true);
                    setAnchorEl(event.currentTarget);
                    setOfferCategoryTab(true);
                    setOfferCatagoryActive(true);
                  }}
                >
                  Offer Category
                  <small>
                    <img src={plus} alt="plus" />
                    <img src={minus} alt="minus" />
                  </small>
                </Button>
                <i
                  className="closeIconBtn"
                  role="presentation"
                  onClick={() => {
                    setSelectedOfferCategory([]);
                    setAnchorEl('');
                    setOfferCatagoryActive(false);
                  }}
                >
                  <img src={close} alt="close" />
                </i>
                <Popover
                  id="offer-category"
                  open={offerCategorySearch}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div className="filterDropdown filterDropdownCheck offerFilterDropdownCheck">
                    <div className="filterDropdownin">
                      {offersCategories && offersCategories.length > 0 ? (
                        <List className={classes.moreList}>
                          <h4>Select A Category(s)</h4>
                          {offersCategories.map((offersCategory, index) => (
                            <>
                              <ListItem key={offersCategory}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={offersCategory}
                                      checked={
                                        selectedOfferCategory &&
                                        selectedOfferCategory.includes(
                                          offersCategory,
                                        )
                                      }
                                      onChange={e => {
                                        onOfferCategory(e, offersCategory);
                                      }}
                                    />
                                  }
                                  label={offersCategory}
                                />
                              </ListItem>
                              {offersCategories &&
                                offersCategories.length - 1 > index && (
                                  <Divider light />
                                )}
                            </>
                          ))}
                        </List>
                      ) : (
                        <div className="smallLoader">
                          <Loader
                            type="TailSpin"
                            color="black"
                            height={20}
                            width={20}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Popover>
              </li>
              <li
                className={
                  offerTypeTab === true ||
                  (selectedOfferType && selectedOfferType.length > 0)
                    ? `${classes.active} ${
                        selectedOfferType && selectedOfferType.length > 0
                          ? classes.valueSelected
                          : ''
                      }`
                    : ''
                }
              >
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={event => {
                    setOfferSearch(true);
                    setAnchorEl(event.currentTarget);
                    setofferTypeTab(true);
                  }}
                >
                  Offer type
                  <small>
                    <img src={plus} alt="plus" />
                    <img src={minus} alt="minus" />
                  </small>
                </Button>
                <i
                  className="closeIconBtn"
                  role="presentation"
                  onClick={clearOfferType}
                >
                  <img src={close} alt="close" />
                </i>
                <Popover
                  id="offer-type"
                  open={offerSearch}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div className="filterDropdown filterDropdownCheck offerFilterDropdownCheck">
                    <div className="filterDropdownin">
                      <List className={classes.moreList}>
                        <h4>select a offer type(s)</h4>
                        {offerTypes &&
                          offerTypes.map(offerType => (
                            <>
                              <ListItem key={offerType.key}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={offerType.label}
                                      checked={offerType.selected}
                                      onChange={e => {
                                        offerTypeOptions(
                                          e.target.checked,
                                          offerType.key,
                                        );
                                      }}
                                    />
                                  }
                                  label={offerType.label}
                                  value={offerType.label}
                                />
                              </ListItem>
                              {offerType.label === 'Digital' && (
                                <Divider light />
                              )}
                            </>
                          ))}
                      </List>
                    </div>
                  </div>
                </Popover>
              </li>
            </ul>
          </div>
          <div className={`${classes.exportSerch} exportSerch`}>
            <ul>
              <SearchFilter
                customerSearch={offersSearch}
                setCustomerSearch={setOffersSearch}
                placeholder={placeholder}
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferFilters;
