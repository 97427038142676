/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import LocationFilter from '../../components/Filters/LocationFilter';
import CategoryFilter from '../../components/Filters/CategoryFilter';
import StatusFilter from '../../components/Filters/StatusFilter';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';
import minus from '../../images/minus.svg';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import Pagination from '../../components/Pagination/Pagination';
import { OffersListContext } from '../../context/OffersListContext';
import useFilterStyles from '../../components/Styles/useFilterStyles';
import { GlobalContext } from '../../context/GlobalContext';

const OfferFilters = () => {
  const {
    offersSearch,
    setOffersSearch,
    totalOffersCount,
    page,
    setPage,
    setOffset,
    statuses,
    setStatuses,
    setStatusActive,
    selectedStatus,
    setStatusInactive,
    offerTypes,
    setOfferTypes,
    selectedOfferType,
    setOfferTypeDigital,
    setOfferTypePhysical,
    // setOfferTypeUrl,
    buildings,
    setBuildings,
    villages,
    setVillages,
    categories,
    setCatagoryActive,
    selectedCategory,
    setSelectedCategory,
  } = useContext(OffersListContext);
  const { buildingGroupsList } = useContext(GlobalContext);
  const classes = useFilterStyles();
  const [locationSearch, setLocationSearch] = useState(false);
  const [statusSearch, setStatusSearch] = useState(false);
  const [offerSearch, setOfferSearch] = useState(false);
  const [categorySearch, setCategorySearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [onCheckStatusButton, setOnCheckStatusButton] = useState('');
  const [offerTypeTab, setofferTypeTab] = useState('');
  const [categoryTab, setCategoryTab] = useState('');
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const placeholder = 'Type a Offer name or company name';

  const offerTypeOptions = (selected, key) => {
    const matchedOfferType = offerTypes.map(offerType => {
      if (offerType && offerType.key === key) {
        offerType.selected = selected;
      }
      return offerType;
    });
    setOfferTypes(matchedOfferType);
  }
  const handleClose = () => {
    setAnchorEl(null);
    setLocationSearch(false);
    setStatusSearch(null);
    setOfferSearch(null);
    setCategorySearch(null);
    setofferTypeTab(false);
    setCategoryTab(false);
    setOnCheckStatusButton(false);
    setOnCheckedLocationButton(false);
  };

  const ClearFilter = () => {
    setStatuses([
      { label: 'Published', key: 'Published', selected: false },
      { label: 'Unpublished', key: 'Unpublished', selected: false },
    ]);
    setOfferTypes([
      { label: 'Digital', key: 'Digital', selected: false },
      { label: 'Physical', key: 'Physical', selected: false },
      { label: 'Digital URL Only', key: 'Url', selected: false },
    ]);
    setOfferTypeDigital('');
    setOfferTypePhysical('');
    // setOfferTypeUrl('');
    setStatusActive('');
    setStatusInactive('');
    setAnchorEl('');
    setSelectedCategory([]);
    setBuildings([]);
    setVillages([]);
    setCatagoryActive(false);
  };

  const clearOfferType = () => {
    setOfferTypes([
      { label: 'Digital', key: 'Digital', selected: false },
      { label: 'Physical', key: 'Physical', selected: false },
      { label: 'Digital URL Only', key: 'Url', selected: false },
    ]);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.filerSection}>
      <div className="pageContainer filterContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftFilterIn`}>
              <LocationFilter
                locationSearch={locationSearch}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setLocationSearch={setLocationSearch}
                handleClose={handleClose}
                setOnCheckedLocationButton={setOnCheckedLocationButton}
                OnCheckedLocationButton={OnCheckedLocationButton}
                id={id}
                buildingGroupsList={buildingGroupsList}
                buildings={buildings}
                villages={villages}
                setVillages={setVillages}
                setBuildings={setBuildings}
              />
              <CategoryFilter
                values={{
                  id,
                  handleClose,
                  categories,
                  anchorEl,
                  setAnchorEl,
                  categoryTab,
                  setCategoryTab,
                  setCatagoryActive,
                  categorySearch,
                  setCategorySearch,
                  selectedCategory,
                  setSelectedCategory,
                }}
              />
              <li
                className={
                  offerTypeTab === true ||
                  (selectedOfferType && selectedOfferType.length > 0)
                    ? `${classes.active} ${
                        selectedOfferType && selectedOfferType.length > 0
                          ? classes.valueSelected
                          : ''
                      }`
                    : ''
                }
              >
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={event => {
                    setOfferSearch(true);
                    setAnchorEl(event.currentTarget);
                    setofferTypeTab(true);
                  }}
                >
                  Offer type
                  <small>
                    <img src={plus} alt="plus" />
                    <img src={minus} alt="minus" />
                  </small>
                </Button>
                <i
                  className="closeIconBtn"
                  role="presentation"
                  onClick={clearOfferType}
                >
                  <img src={close} alt="close" />
                </i>
                <Popover
                  id="offer-type"
                  open={offerSearch}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div className="filterDropdown">
                    <div className="filterDropdownin">
                      <List className={classes.moreList}>
                        <h4>Select A Offer Type(s)</h4>
                        {offerTypes.map(offerType => (
                          <>
                            <ListItem key={offerType.key}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={offerType.label}
                                    checked={offerType.selected}
                                    onChange={e => {
                                      offerTypeOptions(
                                        e.target.checked,
                                        offerType.key,
                                      );
                                    }}
                                  />
                                }
                                label={offerType.label}
                                value={offerType.label}
                              />
                            </ListItem>
                            {offerType.label === 'Digital' && <Divider light />}
                          </>
                        ))}
                      </List>
                    </div>
                  </div>
                </Popover>
              </li>
              <StatusFilter
                values={{
                  id,
                  anchorEl,
                  setAnchorEl,
                  handleClose,
                  selectedStatus,
                  statuses,
                  setStatuses,
                  statusSearch,
                  setStatusSearch,
                  onCheckStatusButton,
                  setOnCheckStatusButton,
                }}
                activeStatus="offer"
              />
              <li role="presentation" onClick={ClearFilter}>
                Clear Filters
              </li>
            </ul>
          </div>
          <div className={`${classes.rightFilter} rightFilterPagination`}>
            <Pagination
              page={page}
              setPage={setPage}
              setOffset={setOffset}
              totalTenants={totalOffersCount}
            />
            <div className={`${classes.exportSerch} exportSerch`}>
              <ul>
                <SearchFilter
                  customerSearch={offersSearch}
                  setCustomerSearch={setOffersSearch}
                  placeholder={placeholder}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferFilters;
