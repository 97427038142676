import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import * as lodash from 'lodash';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';
import { bookingTimeType, buildingsLimit } from '../utils/constants';
import { APP_CONFIG_PARAMS } from '../utils/config';

export const GlobalContext = createContext({});

export const GlobalContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [closePopup, setClosePopup] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedName, setSelectedName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [newMeetingRoomsList, setNewMeetingRoomsList] = useState([]);
  const [extendMeetingRoomList, setExtendMeetingRoomList] = useState([]);
  const [buildingGroupsList, setBuildingGroupsList] = useState([]);
  const [buildingDataList, setBuildingDataList] = useState([]);
  const [permissionsStatus, setPermissionsStatus] = useState(false);
  const [rolePermission, setRolePermission] = useState(false);
  const [activeId, setActiveId] = useState([]);
  const [tenants, setTenants] = useState('');
  const [tenantSearchText, setTenantSearchText] = useState('');
  const [tenanSearchId, setTenanSearchId] = useState(undefined);
  const [buildingsData, setBuildingsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [extendData, setExtendData] = useState([]);
  const [meetingRoomName, setMeetingRoomName] = useState('');
  const [customerDetails, setCustomerDetails] = useState([]);
  const [mrExtendBookingId, setMRExtendBookingId] = useState('');
  const [extendTime, setExtendTime] = useState('');
  const [eventType, setEventType] = useState(bookingTimeType.futureBooking);
  const [cardDetails1, setCardDetails1] = useState('');
  const [extendRoomTotal, setExtendRoomTotal] = useState('');
  const [extendVat, setExtendVat] = useState('');
  const [extendSubtotal, setExtendSubtotal] = useState('');
  const [extendRoomPrice, setExtendRoomPrice] = useState('');
  const [extendDiscount, setExtendDiscount] = useState('');
  const [failedPaymentError, setFailedPaymentError] = useState('');
  const [buidlingLocalStorage, setBuidlingLocalStorage] = useState([]);
  const [isCatering, setIsCatering] = useState('');
  const [paymentServiceProvider,setPaymentServiceProvider]=useState('');
  useEffect(() => {
    if (permissionsStatus) {
      setRolePermission(
        localStorage.getItem('rolePermission') &&
          JSON.parse(localStorage.getItem('rolePermission'))[0],
      );
    }
  }, [permissionsStatus]);

  useEffect(() => {
    setRolePermission(
      localStorage.getItem('rolePermission') &&
        JSON.parse(localStorage.getItem('rolePermission'))[0],
    );
    axios.get(APP_CONFIG_PARAMS).then(data => {
      setIsCatering(data?.data?.enableCatering);
      setPaymentServiceProvider(data?.data?.paymentServiceProvider)
    });
  }, []);
  const roles = localStorage.getItem('rolePermission');
  const rolesJson = JSON.parse(roles);
  const user = rolesJson && rolesJson[0].userType !== 'Caterer';

  const getBuildingCallAction = buildingsPayload => {
    // get buildings api call.
    if (user) {
      ApiClient.apiPost(apiEndPoints.getBuilding, buildingsPayload)
        .then(builldingsData => {
          setBuildingDataList(builldingsData.data.data);
          setBuildingsData(
            builldingsData && builldingsData.data && builldingsData.data.data,
          );
          const buildingGroups = lodash.mapValues(
            lodash.groupBy(builldingsData.data.data, 'village'),
            buildingList => buildingList.map(building => building),
          );
          const buildingGroups2 = Object.entries(buildingGroups);
          setBuildingGroupsList(buildingGroups2);
          if (builldingsData) {
            setLoading(false);
          }
        })
        .catch(err => {
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
          setLoading(false);
        });
    }
  };

  const handleClick = (fieldId, fieldName) => {
    setTenantSearchText(fieldName);
    setTenanSearchId(fieldId);
    setTenants('');
  };

  useEffect(() => {
    if (tenanSearchId && tenantSearchText && user) {
      getBuildingCallAction({
        tenantId: tenantSearchText === '' ? undefined : tenanSearchId,
        sortBy: 'name',
        sortOrder: 'asc',
        offset: 0,
        limit: buildingsLimit,
      });
    } else if (user) {
      getBuildingCallAction({
        tenantId: undefined,
        sortBy: 'name',
        sortOrder: 'asc',
        offset: 0,
        limit: buildingsLimit,
      });
    }
  }, [tenanSearchId, tenantSearchText, user]);

  const getCacheData = async (url, name) => {
    if ('caches' in window) {
      const cacheName = name;
      const cacheUrl = url;
      const cacheStorage = await caches.open(cacheName);
      const cachedResponse = await cacheStorage.match(cacheUrl);

      // If no cache exists
      if (!cachedResponse || !cachedResponse.ok) {
        return [];
      }
      // eslint-disable-next-line no-return-await
      return await cachedResponse.json();
    }
    return 0;
  };
  const setCacheData = (url, name, data) => {
    const cacheName = name;
    const cacheUrl = url;
    const cacheData = new Response(JSON.stringify(data));
    if ('caches' in window) {
      caches.open(cacheName).then(cache => {
        cache.put(cacheUrl, cacheData);
      });
    }
  };
  return (
    <GlobalContext.Provider
      value={{
        open,
        setOpen,
        closePopup,
        setClosePopup,
        rolePermission,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        newMeetingRoomsList,
        setNewMeetingRoomsList,
        selectedName,
        setSelectedName,
        setPermissionsStatus,
        activeId,
        setActiveId,
        buildingGroupsList,
        setBuildingGroupsList,
        buildingDataList,
        setBuildingDataList,
        handleClick,
        tenants,
        setTenants,
        tenantSearchText,
        setTenantSearchText,
        tenanSearchId,
        setTenanSearchId,
        buildingsData,
        setBuildingsData,
        loading,
        setLoading,
        successMessage,
        setSuccessMessage,
        extendData,
        setExtendData,
        customerDetails,
        setCustomerDetails,
        mrExtendBookingId,
        setMRExtendBookingId,
        meetingRoomName,
        setMeetingRoomName,
        extendTime,
        setExtendTime,
        eventType,
        setEventType,
        extendRoomTotal,
        setExtendRoomTotal,
        extendVat,
        setExtendVat,
        extendSubtotal,
        setExtendSubtotal,
        extendRoomPrice,
        setExtendRoomPrice,
        extendDiscount,
        setExtendDiscount,
        cardDetails1,
        setCardDetails1,
        extendMeetingRoomList,
        setExtendMeetingRoomList,
        failedPaymentError,
        setFailedPaymentError,
        getCacheData,
        setCacheData,
        buidlingLocalStorage,
        setBuidlingLocalStorage,
        isCatering,
        paymentServiceProvider,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
