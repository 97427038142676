import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  FormLabel,
  Input,
  CircularProgress,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { createVoucherSchema } from '../../utils/formValidate';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import dowmArw from '../../images/down-arw.svg';
import { GlobalContext } from '../../context/GlobalContext';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
    position: 'static',
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '90px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        '& > div': {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
}));

const CreateManageVoucher = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { setSuccessMessage } = useContext(GlobalContext);
  const [voucherCode, setVoucherCode] = useState('');
  const [discount, setDiscount] = useState('');
  const [discountType, setDiscountType] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [, setTimePicker] = useState([]);
  const [status, setStatus] = useState('Active');
  const [loading, setLoading] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState('');
  const [voucherToggle, setVoucherToggle] = useState(false);
  const [voucherDescription, setVoucherDescription] = useState('');
  const [voucherType, setVoucherType] = useState('');

  const intervals = (startString, endString) => {
    const start = moment(startString, 'hh:mm a');
    const end = moment(endString, 'hh:mm a');
    const newValue = [];
    start.minutes(Math.ceil(start.minutes() / 15) * 15);
    const current = moment(start);
    while (current <= end) {
      newValue.push(current.format('hh:mm a'));
      current.add(15, 'minutes');
    }
    return newValue;
  };

  if (
    !JSON.parse(localStorage.getItem('rolePermission'))[0]
      .createCateringCatergory
  ) {
    history.push('/voucherlist');
  }

  useEffect(() => {
    setTimePicker(intervals('12:00:00 am', '11:59:00 pm'));
    if (id) {
      setLoading(true);
      ApiClient.apiGet(`${apiEndPoints.voucherCodes}/${id}`)
        .then(data => {
          const voucherData = data && data.data && data.data.data;
          setVoucherCode(voucherData.code || '');
          setDiscountType(voucherData.type);
          setDiscount(voucherData.discount);
          setStartDate(voucherData.startDate);
          setEndDate(voucherData.endDate);
          setVoucherDescription(voucherData.description);
          setStatus(voucherData.status || '');
          setLoading(false);
          setVoucherType(voucherData.voucherType || '');
        })
        .catch(error => {
          setVoucherToggle(false);
          setLoading(false);
          alert(error);
        });
    }
  }, [id]);

  useEffect(() => {
    createVoucherSchema
      .validate({
        voucherCode,
        voucherDescription,
        discountType,
        discount,
        startDate,
        endDate,
        voucherType,
      })
      .then(() => {
        if (voucherToggle) {
          setLoading(true);
          const payload = {
            id: id || undefined,
            code: voucherCode,
            discount: parseFloat(discount, 10),
            startDate,
            endDate,
            status,
            type: discountType,
            description: voucherDescription,
            voucherType: voucherType,
          };
          if (id) {
            ApiClient.apiPut(`${apiEndPoints.voucherCodes}/${id}`, payload)
              .then(data => {
                setLoading(false);
                const successmsg =
                  data && data.data.message
                    ? `Voucher Code has been updated successfully!`
                    : '';
                setSuccessMessage(successmsg);
                history.push('/voucherlist');
              })
              .catch(error => {
                setVoucherToggle(false);
                setLoading(false);
                alert(error);
              });
          } else {
            ApiClient.apiPost(apiEndPoints.voucherCodes, payload)
              .then(data => {
                setLoading(false);
                const successmsg =
                  data && data.data.message
                    ? `Voucher Code has been created successfully!`
                    : '';
                setSuccessMessage(successmsg);
                history.push('/voucherlist');
              })
              .catch(error => {
                alert(error?.response?.data?.message);
                setVoucherToggle(false);
                setLoading(false);
              });
          }
        }
      })
      .catch(error => {
        console.log(error, 'error');
        if (voucherToggle) {
          setVoucherToggle(false);
          setErrorMsgs(error);
        }
      });
  }, [
    discountType,
    startDate,
    endDate,
    status,
    discount,
    voucherCode,
    voucherToggle,
    voucherDescription,
    voucherType,
  ]);

  const errorFieldValue = errorMsgs && errorMsgs.value;

  const breadCrumbs = !id
    ? {
        listBreadCrumbPath: '/voucherlist',
        listBreadCrumbTitle: 'Voucher List',
        addBreadCrumbPath: '/Create-Manage-Voucher',
        addBreadCrumbTitle: 'Create Voucher',
      }
    : {
        listBreadCrumbPath: '/voucherlist',
        listBreadCrumbTitle: 'Voucher List',
        viewBreadCrumbPath: '/view-voucher/',
        viewBreadCrumbTitle: 'View Voucher',
        editBreadCrumbPath: '/edit-voucher/',
        editBreadCrumbTitle: 'Edit Voucher',
      };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.addUserFormBody}>
        <Breadcrumb breadCrumbs={breadCrumbs} />
        <Divider light />
        {loading ? (
          <CircularProgress color="inherit" />
        ) : (
          <div className={classes.addUserFormMain}>
            <div className="pageContainer addUserContainer">
              <div className={`${classes.addUserForm} createcategoryForm`}>
                <ul className="offerFormList">
                  <li>
                    <FormLabel component="legend">Voucher Details</FormLabel>
                  </li>
                </ul>
                <ul className={`${classes.InputSection} creaeCategoryField`}>
                  <li>
                    <span>Voucher code*</span>
                    <Input
                      className="textareaSmallBox"
                      type="text"
                      size="10"
                      value={voucherCode.toUpperCase()}
                      inputProps={{ maxLength: 8 }}
                      onChange={e => {
                        const re = /^[A-Za-z0-9 ]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setVoucherCode(e.target.value.toUpperCase());
                        }
                      }}
                    />
                    {errorFieldValue && errorFieldValue.voucherCode === '' && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          voucherCode === '' && 'Voucher Code is required.'
                        }
                      >
                        {voucherCode === '' && 'Voucher Code is required.'}
                      </FormHelperText>
                    )}
                  </li>
                  <li>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                        // value={userRole}
                      >
                        Discount Type*
                      </InputLabel>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        value={discountType}
                        className={classes.formSelectBox}
                        onChange={e => {
                          setDiscountType(e.target.value);
                          setDiscount('');
                        }}
                      >
                        <MenuItem value="Value">Value</MenuItem>
                        <MenuItem value="Percentage">Percentage</MenuItem>
                      </Select>
                    </FormControl>
                  </li>
                  {errorFieldValue && errorFieldValue.discountType === '' && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        discountType === '' && 'Discount Type is required.'
                      }
                    >
                      {discountType === '' && 'Discount Type is required.'}
                    </FormHelperText>
                  )}
                  <li>
                    <span>Discount*</span>
                    {discountType === 'Value' ? (
                      <Input
                        className="textareaSmallBox"
                        type="text"
                        size="10"
                        value={discount}
                        onChange={e => {
                          const re = /^[+]?\d{0,4}(\.\d{0,2})?$/;
                          if (
                            e.target.value === '' ||
                            re.test(e.target.value)
                          ) {
                            setDiscount(e.target.value);
                          }
                        }}
                      />
                    ) : (
                      <Input
                        className="textareaSmallBox"
                        type="text"
                        size="10"
                        value={discount}
                        onChange={e => {
                          const re=/^(\d{1,2}(\.\d{0,2})?|100(\.0{0,2})?)$/;
                          if (
                            e.target.value === '' ||
                            re.test(e.target.value)
                          ) {
                            setDiscount(e.target.value);
                          }
                        }}
                      />
                    )}
                    {errorFieldValue && errorFieldValue.discount === '' && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={discount === '' && 'Discount is required.'}
                      >
                        {discount === '' && 'Discount is required.'}
                      </FormHelperText>
                    )}
                  </li>
                </ul>
                <ul
                  className={`${classes.InputSection} richTextEdit voucherTextEdit`}
                >
                  <li>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                      >
                        Voucher Description*
                      </InputLabel>
                      <textarea
                        // className="textareaSmallBox"
                        // type="text"
                        size="10"
                        value={voucherDescription}
                        onChange={e => {
                          setVoucherDescription(e.target.value);
                          setVoucherToggle(false);
                        }}
                      />

                      {/* <div className="descriptionBox">{`${voucherDescription.length} / 2000
                    
                    `}</div> */}
                      {errorFieldValue &&
                        errorFieldValue.voucherDescription === '' && (
                          <FormHelperText
                            className={classes.errorMsg}
                            error={
                              voucherDescription === '' &&
                              'Description is required.'
                            }
                          >
                            {voucherDescription === '' &&
                              'Description is required.'}
                          </FormHelperText>
                        )}
                    </FormControl>
                  </li>
                </ul>
                <ul
                  className={`${classes.InputSection} offerDtCat voucherDtCat`}
                >
                  <li>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <span>Start Date*</span>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        margin="normal"
                        id="date-picker-inline"
                        variant="inline"
                        format="dd/MM/yyyy"
                        value={startDate}
                        onChange={setStartDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    {errorFieldValue && errorFieldValue.startDate === null && (
                      <FormHelperText
                        className={classes.errorMsg}
                        component="div"
                        error={startDate === null && 'Start date is required.'}
                      >
                        {startDate === null && 'Start date is required.'}
                      </FormHelperText>
                    )}
                  </li>
                  <li>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <span>Expiry Date*</span>
                      <KeyboardDatePicker
                        disableToolbar
                        autoOk
                        variant="inline"
                        format="dd/MM/yyyy"
                        value={endDate}
                        onChange={setEndDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    {new Date(
                      `${moment(startDate).format(
                        'ddd MMM DD YYYY',
                      )} ${'00:00'}`,
                    ) >
                      new Date(
                        `${moment(endDate).format(
                          'ddd MMM DD YYYY',
                        )} ${'00:00'}`,
                      ) &&
                      endDate !== null && (
                        <FormHelperText
                          className={classes.errorMsg}
                          error={
                            new Date(
                              `${moment(startDate).format(
                                'ddd MMM DD YYYY',
                              )} ${'00:00'}`,
                            ) >
                              new Date(
                                `${moment(endDate).format(
                                  'ddd MMM DD YYYY',
                                )} ${'00:00'}`,
                              ) &&
                            endDate !== null &&
                            'Expiry date should be greater than Start date.'
                          }
                        >
                          {new Date(
                            `${moment(startDate).format(
                              'ddd MMM DD YYYY',
                            )} ${'00:00'}`,
                          ) >
                            new Date(
                              `${moment(endDate).format(
                                'ddd MMM DD YYYY',
                              )} ${'00:00'}`,
                            ) &&
                            endDate !== null &&
                            'Expiry date should be greater than Start date.'}
                        </FormHelperText>
                      )}
                  </li>
                </ul>
                <Divider className="formDivider" light />
                <ul
                  className={`${classes.InputSection} offerDtCat voucherDtCat voucherCodeRadio`}
                >
                  <li>
                    <FormLabel component="legend">STATUS</FormLabel>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="Active" name="Active">
                        <div className={`${classes.radioSection} radioSection`}>
                          <FormControlLabel
                            control={<Radio />}
                            label="Active"
                            checked={status === 'Active'}
                            onChange={() => {
                              setStatus('Active');
                            }}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Inactive"
                            checked={status === 'Inactive'}
                            onChange={() => {
                              setStatus('Inactive');
                            }}
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </li>
                  <li>
                    <FormControl className={classes.formControl}>
                      <span>Voucher Type*</span>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        value={voucherType}
                        className={classes.formSelectBox}
                        onChange={e => {
                          setVoucherType(e.target.value);
                        }}
                      >
                        <MenuItem value="oneTimeUse">One Time Use</MenuItem>
                        <MenuItem value="unlimitedUse">Unlimited Use</MenuItem>
                      </Select>
                    </FormControl>
                    {errorFieldValue && errorFieldValue.voucherType === '' && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          voucherType === '' && 'voucherType Type is required.'
                        }
                      >
                        {voucherType === '' && 'voucherType Type is required.'}
                      </FormHelperText>
                    )}
                  </li>
                </ul>
                <SaveAndCancelButtons
                  setToggle={setVoucherToggle}
                  route="/voucherlist"
                />
                <WarningPopup />
              </div>
            </div>
          </div>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default CreateManageVoucher;
