import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Link,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { EventBookingsContextProvider } from '../../context/EventBookingsContext';
import { BookingContextProvider } from '../../context/BookingContext';
import { CLIENT_ID } from '../../utils/config';
import { schema } from '../../utils/formValidate';
import dowmArw from '../../images/down-arw.svg';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import './edituser.css';
import { GlobalContext } from '../../context/GlobalContext';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import { buildingsLimit, tenantUserStatus } from '../../utils/constants';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  formButton: {
    marginTop: '60px',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      height: '47px',
      lineHeight: '47px',
      border: '1px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff',
      marginLeft: '30px',
      cursor: 'pointer',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#444444',
        border: 'none',
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
  noteSubmitContainer: {
    display: 'flex',
  },
  noteText: {
    width: '100%',
    '&:after': {
      content: 'none',
    },
    '&:before': {
      content: 'none',
    },
    '& input': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      width: '100%',
      background: 'transparent',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  noteBut: {
    marginLeft: '20px',
    alignSelf: 'center',
    marginTop: '10px',
    height: '47px',
    lineHeight: '47px',
    border: '1px solid #000',
    outline: 'none',
    background: '#000000',
    fontSize: '16px',
    fontWeight: 'bold',
    width: 'auto',
    padding: '0 45px',
    color: '#fff',
    cursor: 'pointer',
    fontFamily: ['Varta', 'sans-serif'].join(','),
    '&:hover': {
      background: '#000000',
    },
  },
  notes: {
    marginTop: '10px',
    lineHeight: '20px',
  },
  noteContainer: {
    display: 'flex',
  },
  noteLink: {
    marginLeft: '10px',
    cursor: 'pointer',
    color: 'red',
  },
  generalNotes: {
    marginTop: '20px',
  },
  listTitleEvents: {
    paddingBottom: '20px',
  },
  listTitleMeetings: {
    padding: '60px 0 20px 0',
  },
  listContainer: {
    padding: '10px 100px',
  },
  footer: {
    height: '50px',
  },
  bookingsContainer: {
    width: '60%',
    margin: '0 auto',
  },
}));

const EditUser = () => {
  const decoded = jwt_decode(
    localStorage.getItem(`adal.access.token.key${CLIENT_ID}`),
  ).name;
  const classes = useStyles();
  const { setSuccessMessage } = useContext(GlobalContext);
  const history = useHistory();
  const { id } = useParams();
  const [warningPopUp, setWarningPopUp] = useState(false);
  const [userTenant, setUserTenant] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [userJobTitle, setUserJobTitle] = useState('');
  const [userMail, setUserMail] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userSelectedTenant, setUserSelectedTenant] = useState('');
  const [userTenantId, setUserTenantId] = useState('');
  const [userBuildingId, setUserBuildingId] = useState('');
  const [userBuilding, setUserBuilding] = useState([]);
  const [userSelectedBuilding, setUserSelectedBuilding] = useState('');
  const [userVillage, setUserVillage] = useState('');
  const [errorMsgs, setErrorMsgs] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saveFormFileds, setSaveFormFileds] = useState(false);
  const [behaviourNotesList, setBehaviourNotesList] = useState([]);
  const [generalNotesList, setGeneralNotesList] = useState([]);
  const [currentBehaviourNote, setCurrentBehaviourNote] = useState('');
  const [currentGeneralNote, setCurrentGeneralNote] = useState('');
  const [jobTitlesList, setJobTitlesList] = useState([]);
  const [tenantStatus, setTenantStatus] = useState('');

  if (!JSON.parse(localStorage.getItem('rolePermission'))[0].editTenantUser) {
    history.push('/tenantusers');
  }

  useEffect(() => {
    ApiClient.apiGet(apiEndPoints.getTenantsUserId + id)
      .then(data => {
        const tetenantUserData = data && data.data && data.data.data;
        setUserTenant(tetenantUserData);
        setUserFirstName(tetenantUserData.firstName);
        setUserLastName(tetenantUserData.lastName);
        setUserJobTitle(tetenantUserData.jobTitle);
        setUserSelectedBuilding(tetenantUserData.building.name);
        setUserSelectedTenant(tetenantUserData.tenant.name);
        setUserTenantId(tetenantUserData.tenant.id);
        setUserBuildingId(tetenantUserData.building.id);
        setUserVillage(tetenantUserData.building.village);
        setUserRole(tetenantUserData.role);
        setUserMail(tetenantUserData.email);
        setUserStatus(tetenantUserData.status);
        setTenantStatus(tetenantUserData.status);
        setBehaviourNotesList(
          tetenantUserData.behaviourNotes
            ? tetenantUserData.behaviourNotes
            : [],
        );
        setGeneralNotesList(
          tetenantUserData.generalNotes ? tetenantUserData.generalNotes : [],
        );
        const buildingsPayload = {
          tenantId: tetenantUserData.tenant.id,
          searchText: '',
          sortBy: 'name',
          sortOrder: 'asc',
          offset: 0,
          limit: buildingsLimit,
        };
        ApiClient.apiPost(apiEndPoints.getBuilding, buildingsPayload)
          .then(builldingsData => {
            setUserBuilding(
              builldingsData && builldingsData.data && builldingsData.data.data,
            );
            setLoading(false);
          })
          .catch(error => {
            console.log(error, 'error');
          });
      })
      .catch(error => {
        console.log(error, 'error');
      });
    ApiClient.apiGet(apiEndPoints.getJobTitles)
      .then(data => {
        setJobTitlesList(data && data.data && data.data.data);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  }, []);

  useEffect(() => {
    schema
      .validate({
        FirstName: userFirstName,
        LastName: userLastName,
        jobTitle: userJobTitle,
        role: userRole,
        email: userMail,
        tenant: userSelectedTenant,
        building: userSelectedBuilding,
        village: userVillage,
        status: userStatus,
      })
      .then(() => {
        if (saveFormFileds) {
          setLoading(true);
          const payload = {
            ...userTenant,
            accessToCustomerApp: userTenant.accessToCustomerApp,
            buildingname: userTenant.buildingname,
            id: userTenant.id,
            payToAccount: userTenant.payToAccount,
            building: {
              id: userBuildingId,
              name: userSelectedBuilding,
              village: userVillage,
            },

            tenant: {
              id: userTenant.tenant.id,
              name: userTenant.tenant.name,
            },
            firstName: userFirstName,
            lastName: userLastName,
            email: userTenant.email,
            role: userRole,
            jobTitle: userJobTitle,
            status: userStatus,
            generalNotes: generalNotesList,
            behaviourNotes: behaviourNotesList,
          };
          ApiClient.apiPut(apiEndPoints.getTenantsUserIdUpdate + id, payload)
            .then(data => {
              const updateMsg =
                data && data.data.message
                  ? `${userFirstName} ${userLastName} has been updated successfully!`
                  : '';
              setSuccessMessage(updateMsg);
              setLoading(false);
              history.push('/tenantusers');
            })
            .catch(error => {
              console.log(error, 'error1');
              setSaveFormFileds(false);
              setLoading(false);
            });
        }
      })
      .catch(err => {
        console.log(err, 'error2');
        if (saveFormFileds) {
          setErrorMsgs(err);
          setSaveFormFileds(false);
        }
      });
  }, [
    userFirstName,
    userLastName,
    userJobTitle,
    userMail,
    userSelectedTenant,
    userSelectedBuilding,
    userVillage,
    userTenant,
    userStatus,
    userRole,
    userStatus,
    saveFormFileds,
  ]);

  const fullEmail = userMail && userMail.split('@');

  const handleGeneralNoteSave = () => {
    if (currentGeneralNote.length > 1) {
      generalNotesList.push({
        noteDate: moment(),
        note: currentGeneralNote,
        loggedBy: decoded,
      });
      setCurrentGeneralNote('');
    }
  };

  const handleBehaviourNoteSave = () => {
    if (currentBehaviourNote.length > 1) {
      behaviourNotesList.push({
        noteDate: moment(),
        note: currentBehaviourNote,
        loggedBy: decoded,
      });
      setCurrentBehaviourNote('');
    }
  };

  const removeBehaviourNote = e => {
    setBehaviourNotesList(
      behaviourNotesList.filter(f => {
        return f.note !== e.note;
      }),
    );
  };

  const removeGeneralNote = e => {
    setGeneralNotesList(
      generalNotesList.filter(f => {
        return f.note !== e.note;
      }),
    );
  };

  const handleClick = buildingName => {
    setUserSelectedBuilding(buildingName.value.name);
    const villaheAutoPop =
      userBuilding &&
      userBuilding.filter(data => data.name === buildingName.value.id);
    const villageName =
      villaheAutoPop && villaheAutoPop.map(user => user.village);
    setUserVillage(villageName[0]);
  };

  const errorType = errorMsgs && errorMsgs.type;
  const errorFieldName = errorMsgs && errorMsgs.path;
  const errorFieldValue = errorMsgs && errorMsgs.value;

  const popUpOpen = () => {
    setWarningPopUp(true);
  };

  const popUpClose = () => {
    setUserStatus('Blocked');
    setWarningPopUp(false);
  };

  const breadCrumbs = {
    listBreadCrumbPath: '/tenantusers',
    listBreadCrumbTitle: 'Tenant Users',
    viewBreadCrumbPath: '/viewuser/',
    viewBreadCrumbTitle: 'View Tenant Users',
    editBreadCrumbPath: '/edituser/',
    editBreadCrumbTitle: 'Edit Tenant Users',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addUserContainer">
            <div className={classes.addUserForm}>
              <ul>
                <li
                  className={`${classes.InputSection} ${classes.InputSectionReadonly}`}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id={userTenantId}
                      className={classes.formLabel}
                      value={userSelectedTenant}
                    >
                      Tenant*
                    </InputLabel>
                    <Input
                      type="text"
                      autoComplete
                      disabled
                      inputProps={{ 'aria-label': 'description' }}
                      value={userSelectedTenant}
                    />
                  </FormControl>
                  {((errorType === 'required' && errorFieldName === 'tenant') ||
                    (errorFieldValue && errorFieldValue.tenant === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error="Tenant is required."
                    >
                      Tenant is required.
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id={userBuildingId}
                      className={classes.formLabel}
                      value={userSelectedBuilding}
                    >
                      Building*
                    </InputLabel>

                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      id={userBuildingId}
                      value={userSelectedBuilding}
                      className={classes.formSelectBox}
                      onChange={e => {
                        setUserSelectedBuilding(e.target.value);
                        setUserBuildingId(e.target.value.id);
                        handleClick(e.target);
                      }}
                    >
                      {userBuilding &&
                        userBuilding.map(building => {
                          return (
                            <MenuItem value={building.name} key={building.id}>
                              {building.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'building') ||
                    (errorFieldValue && errorFieldValue.building === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error="Building is required. "
                    >
                      Building is required.
                    </FormHelperText>
                  )}
                </li>
                <li
                  className={`${classes.InputSection} ${classes.InputSectionReadonly}`}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                      value={userVillage}
                    >
                      Village*
                    </InputLabel>
                    <Input
                      type="text"
                      autoComplete
                      readOnly
                      inputProps={{ 'aria-label': 'description' }}
                      value={userVillage}
                    />
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'village') ||
                    (errorFieldValue && errorFieldValue.village === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error="Village is required.  "
                    >
                      Village is required.
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <Divider className="formDivider" light />
              {userTenant && userTenant.profileImageUrl ? (
                <>
                  <ul className={classes.InputSection}>
                    <li>
                      <span>First name*</span>
                      <Input
                        type="text"
                        inputProps={{ 'aria-label': 'description' }}
                        value={userFirstName}
                        onChange={e => {
                          setUserFirstName(e.target.value);
                        }}
                      />
                      {((errorType === 'matches' &&
                        errorFieldName === 'FirstName') ||
                        (errorType === 'required' &&
                          errorFieldName === 'FirstName') ||
                        (errorFieldValue &&
                          errorFieldValue.FirstName === '')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          component="div"
                          error={
                            errorType === 'matches'
                              ? 'First name should contain only letters'
                              : 'First name is required. '
                          }
                        >
                          {errorType === 'matches'
                            ? 'First name should contain only letters'
                            : 'First name is required. '}
                        </FormHelperText>
                      )}
                    </li>
                    <li>
                      <span>
                        <img
                          style={{
                            height: '165px',
                            width: '165px',
                            borderRadius: '50%',
                            margin: '25px auto 0',
                          }}
                          src={userTenant.profileImageUrl}
                          alt="user-profile"
                        />
                      </span>{' '}
                    </li>
                    <li style={{ marginTop: '-117px' }}>
                      <span>Last name*</span>
                      <Input
                        type="text"
                        inputProps={{ 'aria-label': 'description' }}
                        value={userLastName}
                        onChange={e => {
                          setUserLastName(e.target.value);
                        }}
                      />
                      {((errorType === 'matches' &&
                        errorFieldName === 'LastName') ||
                        (errorType === 'required' &&
                          errorFieldName === 'LastName') ||
                        (errorFieldValue &&
                          errorFieldValue.LastName === '')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          component="div"
                          error={
                            errorType === 'matches' &&
                            errorMsgs &&
                            errorMsgs.path === 'LastName'
                              ? 'Last name should contain only letters'
                              : 'Last name is required. '
                          }
                        >
                          {errorType === 'matches' &&
                          errorMsgs &&
                          errorMsgs.path === 'LastName'
                            ? 'Last name should contain only letters'
                            : 'Last name is required. '}
                        </FormHelperText>
                      )}
                    </li>

                    <li />
                    <li style={{ marginTop: '-46px' }}>
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink
                          id="demo-simple-select-placeholder-label-label"
                          className={classes.formLabel}
                          value={userJobTitle}
                        >
                          Job role*
                        </InputLabel>
                        <Select
                          value={userJobTitle}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          className={classes.formSelectBox}
                          onChange={e => {
                            setUserJobTitle(e.target.value);
                          }}
                        >
                          {jobTitlesList &&
                            jobTitlesList.map(item => {
                              return (
                                <MenuItem value={item.jobTitle} key={item.id}>
                                  {item.jobTitle}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                      {((errorType === 'required' &&
                        errorFieldName === 'jobTitle') ||
                        (errorFieldValue &&
                          errorFieldValue.jobTitle === '')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          component="div"
                          error="Job role is required. "
                        >
                          Job Role is required.
                        </FormHelperText>
                      )}
                    </li>
                    <li />
                    <li>
                      <span>Email address*</span>
                      <Input
                        disabled
                        inputProps={{ 'aria-label': 'description' }}
                        value={fullEmail && fullEmail[0]}
                      />
                      {((errorType === 'email' && errorFieldName === 'email') ||
                        (errorFieldValue && errorFieldValue.email === '@') ||
                        (errorType === 'matches' &&
                          errorFieldName === 'email')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          component="div"
                          error={
                            errorType === 'matches' &&
                            errorFieldName === 'email'
                              ? 'Please enter a valid email.'
                              : 'Email address is required.'
                          }
                        >
                          {errorType === 'matches' && errorFieldName === 'email'
                            ? 'Please enter a valid email.'
                            : 'Email address is required.'}
                        </FormHelperText>
                      )}
                    </li>
                    <li
                      className={`${classes.InputSection} ${classes.InputSectionReadonly}`}
                    >
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink
                          id="demo-simple-select-placeholder-label-label"
                          className={classes.formLabel}
                          value={`${fullEmail && fullEmail[1]}`}
                        >
                          Domain*
                        </InputLabel>
                        <Input
                          type="text"
                          autoComplete
                          disabled
                          inputProps={{ 'aria-label': 'description' }}
                          value={`${'@'}${fullEmail && fullEmail[1]}`}
                        />
                      </FormControl>
                    </li>
                    <Divider className="formDivider" light />
                    <li>
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink
                          id="demo-simple-select-placeholder-label-label"
                          className={classes.formLabel}
                          value={userRole}
                        >
                          Role
                        </InputLabel>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          value={userRole}
                          className={classes.formSelectBox}
                          onChange={e => {
                            setUserRole(e.target.value);
                          }}
                        >
                          <MenuItem value="Employee">Employee</MenuItem>
                          <MenuItem value="Super Administrator">
                          Super Administrator
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {((errorType === 'required' &&
                        errorFieldName === 'role') ||
                        (errorFieldValue && errorFieldValue.role === '')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          component="div"
                          error="Role in Tenant Portal is required."
                        >
                          Role in tenant portal is required.
                        </FormHelperText>
                      )}
                    </li>
                    <li>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Status</FormLabel>
                        <RadioGroup aria-label="Active" name="Active">
                          <div
                            className={`${classes.radioSection} radioSection`}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              disabled={
                                tenantStatus === tenantUserStatus.renewalPending
                              }
                              label="Active "
                              checked={userStatus === tenantUserStatus.active}
                              onChange={() => {
                                setUserStatus('Active');
                              }}
                            />
                            <FormControlLabel
                              control={<Radio />}
                              label="Blocked"
                              checked={userStatus === tenantUserStatus.blocked}
                              onChange={() => {
                                popUpOpen();
                              }}
                            />
                          </div>
                        </RadioGroup>
                      </FormControl>
                    </li>
                  </ul>
                </>
              ) : (
                <ul className={classes.InputSection}>
                  <li>
                    <span>First name*</span>
                    <Input
                      type="text"
                      inputProps={{ 'aria-label': 'description' }}
                      value={userFirstName}
                      onChange={e => {
                        setUserFirstName(e.target.value);
                      }}
                    />
                    {((errorType === 'matches' &&
                      errorFieldName === 'FirstName') ||
                      (errorType === 'required' &&
                        errorFieldName === 'FirstName') ||
                      (errorFieldValue &&
                        errorFieldValue.FirstName === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        component="div"
                        error={
                          errorType === 'matches'
                            ? 'First name should contain only letters'
                            : 'First name is required. '
                        }
                      >
                        {errorType === 'matches'
                          ? 'First name should contain only letters'
                          : 'First name is required. '}
                      </FormHelperText>
                    )}
                  </li>
                  <li>
                    <span>Last name*</span>
                    <Input
                      type="text"
                      inputProps={{ 'aria-label': 'description' }}
                      value={userLastName}
                      onChange={e => {
                        setUserLastName(e.target.value);
                      }}
                    />
                    {((errorType === 'matches' &&
                      errorFieldName === 'LastName') ||
                      (errorType === 'required' &&
                        errorFieldName === 'LastName') ||
                      (errorFieldValue && errorFieldValue.LastName === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        component="div"
                        error={
                          errorType === 'matches' &&
                          errorMsgs &&
                          errorMsgs.path === 'LastName'
                            ? 'Last name should contain only letters'
                            : 'Last name is required. '
                        }
                      >
                        {errorType === 'matches' &&
                        errorMsgs &&
                        errorMsgs.path === 'LastName'
                          ? 'Last name should contain only letters'
                          : 'Last name is required. '}
                      </FormHelperText>
                    )}
                  </li>

                  <li>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                        value={userJobTitle}
                      >
                        Job role*
                      </InputLabel>
                      <Select
                        value={userJobTitle}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        className={classes.formSelectBox}
                        onChange={e => {
                          setUserJobTitle(e.target.value);
                        }}
                      >
                        {jobTitlesList &&
                          jobTitlesList.map(item => {
                            return (
                              <MenuItem value={item.jobTitle} key={item.id}>
                                {item.jobTitle}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                    {((errorType === 'required' &&
                      errorFieldName === 'jobTitle') ||
                      (errorFieldValue && errorFieldValue.jobTitle === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        component="div"
                        error="Job role is required. "
                      >
                        Job Role is required.
                      </FormHelperText>
                    )}
                  </li>
                  <li />
                  <li>
                    <span>Email address*</span>
                    <Input
                      disabled
                      inputProps={{ 'aria-label': 'description' }}
                      value={fullEmail && fullEmail[0]}
                    />
                    {((errorType === 'email' && errorFieldName === 'email') ||
                      (errorFieldValue && errorFieldValue.email === '@') ||
                      (errorType === 'matches' &&
                        errorFieldName === 'email')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        component="div"
                        error={
                          errorType === 'matches' && errorFieldName === 'email'
                            ? 'Please enter a valid email.'
                            : 'Email address is required.'
                        }
                      >
                        {errorType === 'matches' && errorFieldName === 'email'
                          ? 'Please enter a valid email.'
                          : 'Email address is required.'}
                      </FormHelperText>
                    )}
                  </li>
                  <li
                    className={`${classes.InputSection} ${classes.InputSectionReadonly}`}
                  >
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                        value={`${fullEmail && fullEmail[1]}`}
                      >
                        Domain*
                      </InputLabel>
                      <Input
                        type="text"
                        autoComplete
                        disabled
                        inputProps={{ 'aria-label': 'description' }}
                        value={`${'@'}${fullEmail && fullEmail[1]}`}
                      />
                    </FormControl>
                  </li>
                  <Divider className="formDivider" light />
                  <li>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                        value={userRole}
                      >
                        Role
                      </InputLabel>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        value={userRole}
                        className={classes.formSelectBox}
                        onChange={e => {
                          setUserRole(e.target.value);
                        }}
                      >
                        <MenuItem value="Employee">Employee</MenuItem>
                        <MenuItem value="Super Administrator">
                        Super Administrator
                        </MenuItem>
                      </Select>
                    </FormControl>
                    {((errorType === 'required' && errorFieldName === 'role') ||
                      (errorFieldValue && errorFieldValue.role === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        component="div"
                        error="Role in Tenant Portal is required."
                      >
                        Role in tenant portal is required.
                      </FormHelperText>
                    )}
                  </li>
                  <li>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Status</FormLabel>
                      <RadioGroup aria-label="Active" name="Active">
                        <div className={`${classes.radioSection} radioSection`}>
                          <FormControlLabel
                            control={<Radio />}
                            disabled={
                              tenantStatus === tenantUserStatus.renewalPending
                            }
                            label="Active "
                            checked={userStatus === tenantUserStatus.active}
                            onChange={() => {
                              setUserStatus('Active');
                            }}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Blocked"
                            checked={userStatus === tenantUserStatus.blocked}
                            onChange={() => {
                              popUpOpen();
                            }}
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </li>
                </ul>
              )}
              <div className={classes.InputSection}>
                <span>Behaviour Notes:</span>
                <div className={classes.notes}>
                  {behaviourNotesList.map((note, idx) => {
                    return (
                      <div className={classes.noteContainer}>
                        <div id={idx}>{`${note.loggedBy} [${moment
                          .tz(note.noteDate, 'Europe/London')
                          .format('DD MMM YYYY HH:mm')}] ${note.note}`}</div>
                        <Link
                          className={classes.noteLink}
                          onClick={() => {
                            removeBehaviourNote(note);
                          }}
                          id={idx}
                        >
                          remove
                        </Link>
                      </div>
                    );
                  })}
                </div>
                <div className={classes.noteSubmitContainer}>
                  <Input
                    className={classes.noteText}
                    inputProps={{ 'aria-label': 'description' }}
                    value={currentBehaviourNote}
                    onChange={e => {
                      setCurrentBehaviourNote(e.target.value);
                    }}
                  />
                  {((errorType === 'email' && errorFieldName === 'email') ||
                    (errorFieldValue && errorFieldValue.email === '@') ||
                    (errorType === 'matches' &&
                      errorFieldName === 'email')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        errorType === 'matches' && errorFieldName === 'email'
                          ? 'Please enter a valid email.'
                          : 'Email address is required.'
                      }
                    >
                      {errorType === 'matches' && errorFieldName === 'email'
                        ? 'Please enter a valid email.'
                        : 'Email address is required.'}
                    </FormHelperText>
                  )}
                  <Button
                    className={classes.noteBut}
                    onClick={() => {
                      handleBehaviourNoteSave();
                    }}
                  >
                    Add
                  </Button>
                </div>
              </div>

              <div className={classes.generalNotes}>
                <div className={classes.InputSection}>
                  <span>General Notes:</span>
                  <div className={classes.notes}>
                    {generalNotesList.map((note, idx) => {
                      return (
                        <div className={classes.noteContainer}>
                          <div id={idx}>{`${note.loggedBy} [${moment
                            .tz(note.noteDate, 'Europe/London')
                            .format('DD MMM YYYY HH:mm')}] ${note.note}`}</div>
                          <Link
                            className={classes.noteLink}
                            onClick={() => {
                              removeGeneralNote(note);
                            }}
                            id={idx}
                          >
                            remove
                          </Link>
                        </div>
                      );
                    })}
                  </div>

                  <div className={classes.noteSubmitContainer}>
                    <Input
                      className={classes.noteText}
                      inputProps={{ 'aria-label': 'description' }}
                      value={currentGeneralNote}
                      onChange={e => {
                        setCurrentGeneralNote(e.target.value);
                      }}
                    />
                    {((errorType === 'email' && errorFieldName === 'email') ||
                      (errorFieldValue && errorFieldValue.email === '@') ||
                      (errorType === 'matches' &&
                        errorFieldName === 'email')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        component="div"
                        error={
                          errorType === 'matches' && errorFieldName === 'email'
                            ? 'Please enter a valid email.'
                            : 'Email address is required.'
                        }
                      >
                        {errorType === 'matches' && errorFieldName === 'email'
                          ? 'Please enter a valid email.'
                          : 'Email address is required.'}
                      </FormHelperText>
                    )}
                    <Button
                      className={classes.noteBut}
                      onClick={() => {
                        handleGeneralNoteSave();
                      }}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </div>
              <Dialog
                open={warningPopUp}
                onClose={popUpClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="cancelDialogOpend"
              >
                <div className={classes.cancelDialog}>
                  <h2>Are you sure you want to block this person?</h2>
                  <p className={classes.errorColor}>
                    This person will no longer be able to log in.
                  </p>
                </div>
                <DialogActions
                  className={`${classes.formButton} popupFormButton`}
                >
                  <Button
                    onClick={() => {
                      if (userStatus !== tenantUserStatus.renewalPending) {
                        setUserStatus('Active');
                      }
                      setWarningPopUp(false);
                    }}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button onClick={popUpClose} autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              <WarningPopup />
            </div>
            <div className={classes.bookingsContainer}>
              <div>
                <div className={classes.listTitleEvents}>
                  Event Bookings History:
                </div>
                <EventBookingsContextProvider embedded userId={id} />
              </div>
              <div>
                <div className={classes.listTitleMeetings}>
                  Meeting Room Bookings History:
                </div>
                <BookingContextProvider embedded userId={id} />
              </div>
              <div>
                <SaveAndCancelButtons
                  setToggle={setSaveFormFileds}
                  route="/tenantusers"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={classes.footer} />
    </div>
  );
};

export default EditUser;
