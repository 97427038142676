/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext } from 'react';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  InputLabel,
  Input,
  FormHelperText,
  CircularProgress,
  Checkbox,
  Button,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import LocationFilter from '../../components/Filters/LocationFilter';
import dowmArw from '../../images/down-arw.svg';
import './AddNewsArticle.css';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import { GlobalContext } from '../../context/GlobalContext';
import {
  articleAnnouncementSchema,
  articleNewsSchema,
} from '../../utils/formValidate';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import {
  capitalized,
  categorySorting,
  highestValidDate,
} from '../../utils/constants';
import { logOut } from '../../utils/logOut';
import NewsAndAriclesMedia from '../../components/ImageUpload/NewsAndAriclesMedia';
import { news_description_folder } from '../../components/ImageUpload/constants';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';
import TenantsFilter from '../../components/Filters/TenantsFilter';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addNewArticleForm: {
    width: '61%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      marginLeft: 0,
      marginRight: 0,
      '& li': {
        flex: 1,
        listStyle: 'none',
        marginLeft: 0,
        marginRight: 15,
        marginBottom: 20,
        position: 'relative',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  inputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384 !important',
      },
      '& label': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384 !important',
        transform: 'none',
        textTransform: 'capitalize',
        position: 'static',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '90px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& > div': {
          '& > div': {
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
  leftFilterIn: {
    '& button': {
      color: '#444444',
      backgroundColor: '#f8f8f8',
      padding: '12px 12px 10px',
      fontWeight: 'bold',
      boxShadow: 'none',
      fontSize: '14px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      borderRadius: 5,
      '&:hover': {
        boxShadow: 'none',
        background: '#f8f8f8',
      },
      '& span': {
        lineHeight: '100%',
        alignItems: 'center',
        display: 'flex',

        '& small': {
          marginLeft: 20,
          top: '-2px',
          position: 'relative',
        },
        '& img': {
          '&:nth-child(2)': {
            display: 'none',
          },
        },
      },
    },
  },
  fourCol: {
    flexWrap: 'wrap',
    '& li': {
      flex: 'inherit !important',
      width: 'calc(25% - 15px)',
    },
  },
  noteText: {
    fontSize: 10,
    color: '#838384',
    marginTop: 5,
  },
  label: {
    display: 'block',
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    textTransform: 'capitalize',
    position: 'static',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
}));

const platforms = [
  { title: 'Customer App', value: 'CustomerApp' },
  { title: ' Tenant Portal', value: 'TenantPortal' },
  { title: ' Both', value: 'All' },
];

const AddNewsArticle = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const {
    setSuccessMessage,
    rolePermission,
    buildingGroupsList,
    buildingDataList,
  } = useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const [locationSearch, setLocationSearch] = useState(false);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState();
  const [selectToDate, setSelectToDate] = useState(null);
  const [selectFromDate, setSelectFromDate] = useState(
    new Date(`${moment(new Date()).format('ddd MMM DD YYYY')} ${'00:00'}`),
  );
  const [articleCategory, setArticalCategory] = useState('');
  const [selectedPlatForm, setSelectedPlatForm] = useState('');
  const [descriptionPreview, setDescriptionPreview] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [villages, setVillages] = useState([]);
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const [buildingPayload, setBuildingPayload] = useState([]);
  const [newsOrArticleImage, setNewsOrArticleImage] = useState([]);
  const [newsVideo, setNewsVideo] = useState('');
  const [previewImage, setPreviewImage] = useState([]);
  const [loopVideo, setLoopVideo] = useState('');
  const [loopVideoTextOverlay, setLoopVideoTextOverlay] = useState('');
  const [articleCategoryValues, setArticleCategoryValues] = useState([]);
  const [selectedArticleId, setSelectedArticleId] = useState('');
  const [saveArticleToggle, setSaveArticleToggle] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [articleType, setArticleType] = useState('Announcement');
  const [newsType, setNewsType] = useState('standard');
  const [status, setStatus] = useState('Published');
  const [selectedBuildingId, setSelectedBuildingId] = useState([]);
  const [selectedTenants, setSelectedTenants] = useState([]);
  const [listOfTenants, setListOfTenants] = useState([]);
  const [userTenant, setUserTenant] = useState([]);
  const [selectedTenantsId, setSelectedTenantsId] = useState([]);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      ApiClient.apiGet(`${apiEndPoints.createArticale}/${id}`)
        .then(data => {
          setLoading(false);
          const articlesData = data && data.data && data.data.data;
          setArticleType(articlesData.type);
          setTitle(articlesData.title);
          setAuthor((articlesData && articlesData.author) || '');
          setSelectFromDate(
            moment(new Date(articlesData.startDate)).format('YYYY-MM-DD'),
          );
          setSelectToDate(
            moment(new Date(articlesData.endDate)).format('YYYY-MM-DD'),
          );
          setArticalCategory(
            articlesData && articlesData.category && articlesData.category.name,
          );
          setSelectedArticleId(
            articlesData && articlesData.category && articlesData.category.id,
          );
          setSelectedPlatForm(articlesData.platform);
          setDescriptionPreview(articlesData.descriptionPreview);
          setDescriptionValue(articlesData.description);
          setBuildings(articlesData.buildings);
          setSelectedTenants(articlesData.tenants || []);
          setChecked(articlesData?.buildings === 'all' && true);
          setSelectedTenantsId(articlesData.tenants || []);
          setNewsType(articlesData.mediaType);
          setNewsOrArticleImage(articlesData.newsImage || []);
          setNewsVideo(articlesData.video || '');
          setPreviewImage(articlesData.videoPreviewImage || []);
          setLoopVideo(articlesData.loopVideo || '');
          setLoopVideoTextOverlay(articlesData.loopVideoText || '');
        })
        .catch(error => {
          setLoading(false);
          alert(error);
        });
    }
  }, [id]);
  useEffect(() => {
    if (buildingGroupsList.length > 0) {
      const buildingIdArray = [];
      buildingGroupsList.map(item => {
        item[1].map(val => {
          if (buildings && buildings.includes(val.id)) {
            buildingIdArray.push(val.buildingId);
          }
        });
      });
      setSelectedBuildingId(buildingIdArray);
    }
  }, [buildingGroupsList, buildings, id]);

  useEffect(() => {
    ApiClient.apiGet(apiEndPoints.getArticaleCategories)
      .then(res => {
        setLoading(false);
        setArticleCategoryValues(res.data.data);
      })
      .catch(error => {
        setLoading(false);
        alert(error);
      });
  }, []);

  useEffect(() => {
    articleCategoryValues.map(item => {
      if (item.name === articleCategory) {
        setSelectedArticleId(item.id);
      }
    });
  }, [articleCategory]);

  useEffect(() => {
    if (buildings !== 'all') {
      const result = buildingDataList.filter(
        o1 => buildings && buildings.some(o2 => o1.id === o2),
      );
      setBuildingPayload(result);
    }
  }, [buildings]);

  const buildingArray = [];
  if (buildingPayload) {
    buildingPayload.map(item => {
      if (item.id && item.village && item.name) {
        buildingArray.push({
          id: item.id,
          name: item.name,
          village: item.village,
        });
      }
    });
  }

  useEffect(() => {
    const tenantsPayload = {
      sortOrder: 'asc',
      sortBy: 'name',
      offset: 0,
      buildings: selectedBuildingId,
    };

    ApiClient.apiPost(apiEndPoints.getTenants, tenantsPayload)
      .then(data => {
        setUserTenant(data && data.data && data.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  }, [buildings, id, selectedBuildingId]);

  const open = Boolean(anchorEl);
  const openId = open ? 'simple-popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setLocationSearch(false);
    setOnCheckedLocationButton(false);
  };

  const handleValidation =
    articleType === 'Announcement'
      ? articleAnnouncementSchema
      : articleNewsSchema;

  const errorType = errorMsg && errorMsg.type;
  const errorFieldName = errorMsg && errorMsg.path;
  const errorFieldValue = errorMsg && errorMsg.value;

  const mediaValidation =
    (newsOrArticleImage &&
      newsOrArticleImage.length !== 0 &&
      newsType === 'standard') ||
    (loopVideo !== '' &&
      loopVideoTextOverlay !== '' &&
      previewImage &&
      previewImage.length !== 0 &&
      newsType === 'featured') ||
    (newsVideo !== '' &&
      previewImage &&
      previewImage.length !== 0 &&
      newsType === 'video');

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  const endDateApi = `${moment(selectToDate)
    .set('hours', 23)
    .set('minutes', 59)
    .set('seconds', 0)}`;

  useEffect(() => {
    if (descriptionValue === '<p><br></p>') {
      setSaveArticleToggle(false);
    }
    const announcementSchema = checked
      ? {
          titleValidation: title,
          autorValidation: author,
          startDateValidation: selectFromDate,
          platformValidation: selectedPlatForm,
          descriptionPreviewValidation: descriptionPreview,
          descriptionValidation: descriptionValue,
          mediaTypeValidation: mediaValidation,
        }
      : {
          titleValidation: title,
          autorValidation: author,
          startDateValidation: selectFromDate,
          platformValidation: selectedPlatForm,
          descriptionPreviewValidation: descriptionPreview,
          descriptionValidation: descriptionValue,
          locationValidation: buildings,
          mediaTypeValidation: mediaValidation,
          tenantValidation: selectedTenants,
        };
    const newsSchema = {
      titleValidation: title,
      autorValidation: author,
      startDateValidation: selectFromDate,
      articleCatgeoryValidation: articleCategory,
      platformValidation: selectedPlatForm,
      descriptionPreviewValidation: descriptionPreview,
      descriptionValidation: descriptionValue,
      locationValidation: buildings,
      newsImageValidation: newsOrArticleImage,
      newsVideoValidation: newsVideo,
      previewImageValidation: previewImage,
      loopVideoValidation: loopVideo,
      loopVideoTextOverlayValidation: loopVideoTextOverlay,
      mediaTypeValidation: mediaValidation,
    };
    handleValidation
      .validate(
        articleType === 'Announcement' ? announcementSchema : newsSchema,
      )
      .then(() => {
        if (
          saveArticleToggle &&
          descriptionValue.length < 2000000 &&
          descriptionValue !== '<p><br></p>'
        ) {
          setLoading(true);
          if (id) {
            let editArticalePayload;
            if (articleType === 'Announcement') {
              editArticalePayload = {
                id,
                title: capitalized(title.trim()),
                type: articleType,
                startDate: new Date(selectFromDate).toISOString(),
                endDate:
                  selectToDate !== null
                    ? new Date(endDateApi).toISOString()
                    : undefined,
                platform: selectedPlatForm,
                descriptionPreview,
                description: descriptionValue,
                buildings: checked ? 'all' : buildings,
                tenants: checked ? 'all' : selectedTenantsId,
                author: author || undefined,
                category: {
                  id: '70ea9bfb-58f4-4b3c-bf22-a652300af04e',
                  name: categorySorting,
                },
                mediaType: newsVideo ? 'video' : newsType,
                newsImage: newsOrArticleImage || undefined,
                video: newsVideo || undefined,
                videoPreviewImage: previewImage || undefined,
                status,
              };
            } else {
              editArticalePayload = {
                id,
                title: capitalized(title.trim()),
                type: articleType,
                startDate: new Date(selectFromDate).toISOString(),
                endDate:
                  selectToDate !== null
                    ? new Date(endDateApi).toISOString()
                    : undefined,
                descriptionPreview,
                description: descriptionValue,
                buildings,
                platform: selectedPlatForm,
                author: author || undefined,
                category: {
                  id: selectedArticleId,
                  name: articleCategory,
                },
                mediaType: newsVideo ? 'video' : newsType,
                newsImage:
                  newsType === 'standard' ? newsOrArticleImage : undefined,
                loopVideo: newsType === 'featured' ? loopVideo : undefined,
                loopVideoText:
                  newsType === 'featured' ? loopVideoTextOverlay : undefined,
                video: newsType === 'video' ? newsVideo : undefined,
                videoPreviewImage:
                  newsType === 'video' || newsType === 'featured'
                    ? previewImage
                    : undefined,
                status,
              };
            }
            ApiClient.apiPut(
              `${apiEndPoints.createArticale}/${id}`,
              editArticalePayload,
            )
              .then(data => {
                const updateMsg =
                  data && data.data.message
                    ? `${title} has been updated successfully!`
                    : '';
                setSuccessMessage(updateMsg);
                history.push('/news-announcements');
                setLoading(false);
              })
              .catch(error => {
                const err = error;
                alert(
                  err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.message,
                );
                setLoading(false);
              });
          } else {
            let createArticalePayload;
            if (articleType === 'Announcement') {
              createArticalePayload = {
                title: capitalized(title.trim()),
                type: articleType,
                startDate: new Date(selectFromDate).toISOString(),
                endDate:
                  selectToDate !== null
                    ? new Date(selectToDate).toISOString()
                    : undefined,
                platform: selectedPlatForm,
                descriptionPreview,
                description: descriptionValue,
                buildings: checked ? 'all' : buildings,
                tenants: checked ? 'all' : selectedTenantsId,
                author: author || undefined,
                category: {
                  id: '70ea9bfb-58f4-4b3c-bf22-a652300af04e',
                  name: categorySorting,
                },
                mediaType: newsVideo ? 'video' : newsType,
                newsImage: newsOrArticleImage || undefined,
                video: newsVideo || undefined,
                videoPreviewImage: previewImage || undefined,
                status,
              };
            } else {
              createArticalePayload = {
                title: capitalized(title.trim()),
                type: articleType,
                startDate: new Date(selectFromDate).toISOString(),
                endDate:
                  selectToDate !== null
                    ? new Date(selectToDate).toISOString()
                    : undefined,
                descriptionPreview,
                description: descriptionValue,
                buildings,
                platform: selectedPlatForm,
                author: author || undefined,
                category: {
                  id: selectedArticleId,
                  name: articleCategory,
                },
                mediaType: newsVideo ? 'video' : newsType,
                newsImage:
                  newsType === 'standard' ? newsOrArticleImage : undefined,
                loopVideo: newsType === 'featured' ? loopVideo : undefined,
                loopVideoText:
                  newsType === 'featured' ? loopVideoTextOverlay : undefined,
                video: newsType === 'video' ? newsVideo : undefined,
                videoPreviewImage:
                  newsType === 'video' || newsType === 'featured'
                    ? previewImage
                    : undefined,
                status,
              };
            }
            ApiClient.apiPost(
              apiEndPoints.createArticale,
              createArticalePayload,
            )
              .then(data => {
                const successmsg =
                  data && data.data.message
                    ? `${title} has been added successfully!`
                    : '';
                setSuccessMessage(successmsg);
                history.push('/news-announcements');
                setLoading(false);
              })
              .catch(error => {
                const err = error;
                alert(
                  err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.message,
                );
                setLoading(false);
                setSaveArticleToggle(false);
              });
          }
        }
      })
      .catch(error => {
        console.log('error', error);
        if (saveArticleToggle) {
          setErrorMsg(error);
          setLoading(false);
          setSaveArticleToggle(false);
        }
      });
  }, [
    title,
    articleType,
    selectFromDate,
    selectToDate,
    descriptionPreview,
    descriptionValue,
    selectedPlatForm,
    author,
    articleCategory,
    newsOrArticleImage,
    newsVideo,
    loopVideo,
    previewImage,
    loopVideoTextOverlay,
    saveArticleToggle,
    newsType,
    mediaValidation,
  ]);

  useEffect(() => {
    if (buildings && buildings.length <= 0) {
      setSelectedTenants([]);
    }
  }, [buildings]);

  useEffect(() => {
    if (userTenant.length !== 0 && userTenant.length < selectedTenants.length) {
      const allFields = userTenant.map(field => field.id);
      setSelectedTenants([...allFields]);
      const allFieldsId = userTenant.map(field => field.id);
      setSelectedTenantsId(allFieldsId);
    }
  }, [userTenant]);

  const breadCrumbs = !id
    ? {
        listBreadCrumbPath: '/news-announcements',
        listBreadCrumbTitle: 'News + Announcements',
        addBreadCrumbPath: '/add-newsarticle',
        addBreadCrumbTitle: 'Create New Article',
      }
    : {
        listBreadCrumbPath: '/news-announcements',
        listBreadCrumbTitle: 'News + Announcements',
        viewBreadCrumbPath: '/view-newsarticle/',
        viewBreadCrumbTitle: 'View Article',
        editBreadCrumbPath: '/edit-newsarticle/',
        editBreadCrumbTitle: 'Edit Article',
      };

  const allLocationsTenantsSelection = e => {
    setChecked(e.target.checked);
    setBuildings([]);
    setSelectedTenantsId([]);
  };
  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addMeetingRoomUserContainer">
            <div className={`${classes.addNewArticleForm} offerForm`}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Article Details</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl component="fieldset">
                    <FormLabel className={classes.label}>
                      Article type
                    </FormLabel>
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        <FormControlLabel
                          control={<Radio />}
                          label="Announcement"
                          checked={articleType === 'Announcement'}
                          onChange={() => {
                            setArticleType('Announcement');
                            setErrorMsg('');
                            setSaveArticleToggle(false);
                          }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="News"
                          checked={articleType === 'News'}
                          disabled={
                            rolePermission &&
                            rolePermission.userType === 'FrontOfHouse'
                          }
                          onChange={() => {
                            setArticleType('News');
                            setErrorMsg('');
                            setSelectedPlatForm('');
                            setArticalCategory('');
                            setSaveArticleToggle(false);
                            setChecked(false);
                          }}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <ul className={`${classes.inputSection}`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Title*
                  </InputLabel>
                  <Input
                    className="textareaSmallBox"
                    type="text"
                    inputProps={{ 'aria-label': 'offerName', maxLength: '150' }}
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value);
                      setSaveArticleToggle(false);
                    }}
                  />
                  <div className="descriptionBox nbDescBox">{`${title.length}/150`}</div>
                  {((errorType === 'min' &&
                    errorFieldName === 'titleValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.titleValidation === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        title === ''
                          ? title === '' && 'Title is required.'
                          : errorType === 'min' &&
                            'must be at least 3 characters'
                      }
                    >
                      {title === ''
                        ? title === '' && 'Title is required.'
                        : errorType === 'min' &&
                          'must be at least 3 characters'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <ul className={`${classes.inputSection} ${classes.fourCol}`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Author
                    </InputLabel>
                    <Input
                      type="text"
                      autoComplete
                      inputProps={{ 'aria-label': 'description' }}
                      onChange={e => {
                        setAuthor(e.target.value);
                        setSaveArticleToggle(false);
                      }}
                      value={author}
                    />
                  </FormControl>
                </li>
                <li className="offerDtCat">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <span>Valid from date*</span>

                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      value={selectFromDate}
                      onChange={e => {
                        setSelectFromDate(e);
                        setSaveArticleToggle(false);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {errorFieldValue &&
                    errorFieldValue.startDateValidation === null && (
                      <FormHelperText
                        className={classes.errorMsg}
                        component="div"
                        error={
                          selectFromDate === null &&
                          'Valid from date is required.'
                        }
                      >
                        {selectFromDate === null &&
                          'Valid from date is required.'}
                      </FormHelperText>
                    )}
                </li>
                <li className="offerDtCat">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <span>Valid to date</span>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      value={
                        selectToDate === highestValidDate ? null : selectToDate
                      }
                      onChange={e => {
                        setSelectToDate(e);
                        setSaveArticleToggle(false);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {new Date(
                    `${moment(selectFromDate).format(
                      'ddd MMM DD YYYY',
                    )} ${'00:00'}`,
                  ) >
                    new Date(
                      `${moment(selectToDate).format(
                        'ddd MMM DD YYYY',
                      )} ${'00:00'}`,
                    ) &&
                    selectToDate !== null && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          new Date(
                            `${moment(selectFromDate).format(
                              'ddd MMM DD YYYY',
                            )} ${'00:00'}`,
                          ) >
                            new Date(
                              `${moment(selectToDate).format(
                                'ddd MMM DD YYYY',
                              )} ${'00:00'}`,
                            ) &&
                          selectToDate !== null &&
                          'To date should be greater than From date.'
                        }
                      >
                        {new Date(
                          `${moment(selectFromDate).format(
                            'ddd MMM DD YYYY',
                          )} ${'00:00'}`,
                        ) >
                          new Date(
                            `${moment(selectToDate).format(
                              'ddd MMM DD YYYY',
                            )} ${'00:00'}`,
                          ) &&
                          selectToDate !== null &&
                          'To date should be greater than From date.'}
                      </FormHelperText>
                    )}
                </li>
                {articleType === 'News' && <li>&nbsp;</li>}
                {articleType === 'News' && (
                  <li>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink className={classes.formLabel}>
                        Article category*
                      </InputLabel>
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        value={articleCategory}
                        className={classes.formSelectBox}
                        onChange={e => {
                          setArticalCategory(e.target.value);
                          setSaveArticleToggle(false);
                        }}
                      >
                        {articleCategoryValues.map(item => {
                          return (
                            <MenuItem value={item.name} key={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {((errorType === 'required' &&
                      errorFieldName === 'articleCatgeoryValidation') ||
                      (errorFieldValue &&
                        !errorFieldValue.articleCatgeoryValidation) ||
                      (errorFieldValue &&
                        errorFieldValue.articleCatgeoryValidation === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          (articleCategory === '' || !articleCategory) &&
                          'Article category is required.'
                        }
                      >
                        {(articleCategory === '' || !articleCategory) &&
                          'Article category is required.'}
                      </FormHelperText>
                    )}
                  </li>
                )}

                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Platform*
                    </InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      value={selectedPlatForm}
                      className={classes.formSelectBox}
                      onChange={e => {
                        setSelectedPlatForm(e.target.value);
                        setSaveArticleToggle(false);
                      }}
                    >
                      {platforms &&
                        platforms.map(platform => (
                          <MenuItem value={platform.value} key={platform.value}>
                            {platform.title}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'platformValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.platformValidation.length === 0)) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={selectedPlatForm === '' && 'Platform is required.'}
                    >
                      {selectedPlatForm === '' && 'Platform is required.'}
                    </FormHelperText>
                  )}
                </li>
              </ul>

              <ul className={`${classes.inputSection}`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Description Preview*
                  </InputLabel>
                  <textarea
                    type="text"
                    maxLength="300"
                    inputProps={{ 'aria-label': 'offerName' }}
                    value={descriptionPreview}
                    onChange={e => {
                      setDescriptionPreview(e.target.value);
                      setSaveArticleToggle(false);
                    }}
                  />
                  <div className="descriptionBox">
                    {`${descriptionPreview.length}/300`}
                  </div>
                  {((errorType === 'min' &&
                    errorFieldName === 'descriptionPreviewValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.descriptionPreviewValidation === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        descriptionPreview === '' &&
                        'Description Preview is required.'
                      }
                    >
                      {descriptionPreview === '' &&
                        'Description Preview is required.'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <ul className={`${classes.inputSection}`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Description*
                  </InputLabel>
                  <div className="richTextEditor">
                    <RichTextEditor
                      descriptionValue={descriptionValue}
                      setDescriptionValue={setDescriptionValue}
                      setSaveArticleToggle={setSaveArticleToggle}
                      mediaStorage={news_description_folder}
                    />
                  </div>
                  {((errorType === 'required' &&
                    errorFieldName === 'descriptionValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.descriptionValidation === '') ||
                    descriptionValue === '<p><br></p>' ||
                    (saveArticleToggle &&
                      descriptionValue.length > 2000000)) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        ((descriptionValue === '' ||
                          descriptionValue === '<p><br></p>') &&
                          'Description is required.') ||
                        (saveArticleToggle && descriptionValue.length > 2000000
                          ? 'Article Description Exceeds The Maximum of 2MB'
                          : '')
                      }
                    >
                      {((descriptionValue === '' ||
                        descriptionValue === '<p><br></p>') &&
                        'Description is required.') ||
                        (saveArticleToggle && descriptionValue.length > 2000000
                          ? 'Article Description Exceeds The Maximum of 2MB'
                          : '')}
                    </FormHelperText>
                  )}
                  <div className={classes.noteText}>
                    Maximum of 3 images file size 500KB per image
                  </div>
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul>
                {articleType === 'Announcement' && (
                  <li className="allLocationsTenantsSelectionCheck">
                    <FormControlLabel
                      value="end"
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={allLocationsTenantsSelection}
                        />
                      }
                      label="Select all locations and tenants. Tenants added in the future will also see this article."
                      labelPlacement="end"
                    />
                  </li>
                )}
              </ul>
              <ul
                className={`${classes.leftFilterIn} offerlocationFilterDropdown newsArticleFilterDropdown`}
              >
                {checked || buildings === 'all' ? (
                  <li>
                    <FormLabel
                      className="locationFilterLabel"
                      component="legend"
                    >
                      {' '}
                      Location*
                    </FormLabel>
                    <Button
                      className="all-tenant-selectedButton"
                      variant="contained"
                    >
                      All Buildings selected
                    </Button>
                  </li>
                ) : (
                  <li>
                    <FormLabel
                      className="locationFilterLabel"
                      component="legend"
                    >
                      Location*
                    </FormLabel>
                    <LocationFilter
                      selectAll
                      id={openId}
                      editId={id}
                      AddOffersToggle
                      errorType={errorType}
                      errorFieldName={errorFieldName}
                      errorFieldValue={errorFieldValue}
                      locationSearch={locationSearch}
                      anchorEl={anchorEl}
                      buildingArray={buildingArray}
                      setAnchorEl={setAnchorEl}
                      setLocationSearch={setLocationSearch}
                      handleClose={handleClose}
                      setOnCheckedLocationButton={setOnCheckedLocationButton}
                      OnCheckedLocationButton={OnCheckedLocationButton}
                      buildingGroupsList={buildingGroupsList}
                      buildings={buildings}
                      setBuildings={setBuildings}
                      villages={villages}
                      setVillages={setVillages}
                      selectedBuildingId={selectedBuildingId}
                      setSelectedBuildingId={setSelectedBuildingId}
                      setSelectedTenants={setSelectedTenants}
                    />
                  </li>
                )}
                {checked || buildings === 'all' ? (
                  <li>
                    <FormLabel
                      className="locationFilterLabel"
                      component="legend"
                    >
                      Tenant*
                    </FormLabel>
                    <Button
                      className="all-tenant-selectedButton"
                      variant="contained"
                    >
                      All Tenants selected
                    </Button>
                  </li>
                ) : (
                  <>
                    {articleType === 'Announcement' &&
                      ((userTenant && userTenant.length > 0) || id) && (
                        <li>
                          <FormLabel
                            className="locationFilterLabel"
                            component="legend"
                          >
                            Tenant*
                          </FormLabel>
                          <TenantsFilter
                            dropDownFields={userTenant}
                            selectAll
                            setSelectedTenants={setSelectedTenants}
                            selectedTenants={selectedTenants}
                            listOfTenants={listOfTenants}
                            setListOfTenants={setListOfTenants}
                            errorType={errorType}
                            errorFieldName={errorFieldName}
                            errorFieldValue={errorFieldValue}
                            setSelectedTenantsId={setSelectedTenantsId}
                            selectedTenantsId={selectedTenantsId}
                            id={openId}
                            editId={id}
                          />
                        </li>
                      )}
                  </>
                )}
              </ul>
              <Divider className="formDivider" light />
              <ul className="multiMediaSelect">
                <li>
                  <FormControl component="fieldset">
                    <FormLabel className={classes.label}>Media</FormLabel>
                    {articleType === 'News' && (
                      <RadioGroup aria-label="Active" name="Available">
                        <div className={`${classes.radioSection} radioSection`}>
                          <FormControlLabel
                            control={<Radio />}
                            label="standard"
                            checked={newsType === 'standard'}
                            value="default-selected"
                            onChange={() => {
                              setNewsType('standard');
                            }}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Video"
                            value="default-selected"
                            checked={newsType === 'video'}
                            onChange={() => {
                              setNewsType('video');
                              setPreviewImage([]);
                            }}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Feature"
                            value="disabled"
                            checked={newsType === 'featured'}
                            onChange={() => {
                              setNewsType('featured');
                              setPreviewImage([]);
                            }}
                          />
                        </div>
                      </RadioGroup>
                    )}
                  </FormControl>
                  <div className={`${classes.fourCol} offerImgUoload`}>
                    <NewsAndAriclesMedia
                      values={{
                        errorType,
                        errorFieldName,
                        errorFieldValue,
                        mediaValidation,
                        newsOrArticleImage,
                        setNewsOrArticleImage,
                        newsVideo,
                        setNewsVideo,
                        previewImage,
                        setPreviewImage,
                        loopVideo,
                        setLoopVideo,
                        loopVideoTextOverlay,
                        setLoopVideoTextOverlay,
                        setSaveArticleToggle,
                        newsType,
                        setNewsType,
                        articleType,
                      }}
                    />
                  </div>
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        <FormControlLabel
                          control={<Radio />}
                          label="Published"
                          checked={status === 'Published'}
                          onChange={() => {
                            setStatus('Published');
                          }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Unpublished"
                          checked={status === 'Unpublished'}
                          onChange={() => {
                            setStatus('Unpublished');
                          }}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <SaveAndCancelButtons
                setToggle={setSaveArticleToggle}
                route="/news-announcements"
              />
              <WarningPopup />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNewsArticle;
