/* eslint-disable import/no-cycle */
import React, { createContext, useContext, useState } from 'react';
import moment from 'moment';
import ExtendMeetingRoomBooking from '../containers/MeetingRoomBooking/ExtendMeetingRoomBooking';
import { GlobalContext } from './GlobalContext';
import { isdaylightsaving } from '../utils/dateUtils';

export const MrExtendBookingContext = createContext({});

export const MRExtendBookingContextProvider = () => {
  const { startDate, selectedName } = useContext(GlobalContext);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [iscustomerDetailsExist, setIsCustomerDetailsExist] = useState(false);
  const [activeStep, setActiveStep] = useState(3);
  const [previewStep, setPreviewStep] = useState(3);
  // MeetingRoom Booking
  const [bookingDate, setBookingDate] = useState(startDate);
  const [startTime, setStartTime] = useState(
    moment.tz(bookingDate, 'Europe/London').format('hh:mm a'),
  );
  const [endNewDate, setEndNewDate] = useState(null);
  const [mrRoomId, setMrRoomId] = useState(selectedName);
  const [mrRoomName, setMrRoomName] = useState(selectedName);
  const [mrCapacity, setMrCapacity] = useState('');
  const [mrRoomLayout, setMrRoomLayout] = useState('');
  const [createMeetingRoom, setCreateMeetingRoom] = useState(false);
  const [fullDay, setFullDay] = useState(false);
  const [layoutName, setLayoutName] = useState('');
  const [buildingId, setBuildingId] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [village, setVillage] = useState('');
  const [fullDayPrice, setFullDayPrice] = useState('');
  const [hourlyPrice, setHourlyPrice] = useState('');
  const [categoryNames, setCategoryNames] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [catMenuId, setCatMenuId] = useState('');
  const [catMenuItemName, setCatMenuItemName] = useState('');
  const [calcPrice, setCalcPrice] = useState('');
  const [mrBookingId, setMRBookingId] = useState('');
  const [minimumBookingTime, setMinimumBookingTime] = useState('');
  const [roomLayoutArray, setRoomLayoutArray] = useState([]);
  const [notes, setNotes] = useState('');
  const [capaCityValue, setCapaCityValue] = useState('');
  const [mrPayment, setMrPayment] = useState(false);
  const [mrCardPayment, setCardPayment] = useState(false);
  const [mrPaymentPosMessage, setMrPaymentPosMessage] = useState(false);
  const [mrPaymentPos, setMrPaymentPos] = useState(false);
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);
  // Customer Details
  const [customerType, setCustomerType] = useState('Tenant');
  const [tenant, setTenant] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [customerDetailsToggle, setCustomerDetailsToggle] = useState(false);
  // Catering Booking
  const [cartItems, setCartItems] = useState([]);
  const [createCateringToggle, setCreateCateringToggle] = useState(false);
  const [vat, setVat] = useState('');
  const [terminals, setTerminals] = useState('');
  const [terminalId, setTerminalId] = useState('');
  const mrVatRate = vat && vat[0] && vat[0].vatRate;
  const cateringVatRate = vat && vat[2] && vat[2].vatRate;
  const [circularLoading, setCircularLoading] = useState(false);
  const [loaderNotification, setLoaderNotification] = useState('');

  const isDaylight = isdaylightsaving(bookingDate);
  let finalStartDate;
  let finalEndDate;
  if (isDaylight) {
    finalStartDate = moment
      .tz(
        `${moment(bookingDate).format('YYYY-MM-DD')} ${startTime}`,
        'Europe/London',
      )
      .subtract(1, 'hours')
      .toISOString();
    finalEndDate = moment
      .tz(
        `${moment(bookingDate).format('YYYY-MM-DD')} ${endNewDate}`,
        'Europe/London',
      )
      .subtract(1, 'hours')
      .toISOString();
  } else {
    finalStartDate = moment
      .tz(
        `${moment(bookingDate).format('YYYY-MM-DD')} ${startTime}`,
        'Europe/London',
      )
      .toISOString();
    finalEndDate = moment
      .tz(
        `${moment(bookingDate).format('YYYY-MM-DD')} ${endNewDate}`,
        'Europe/London',
      )
      .toISOString();
  }

  const mrBookingPrice =
    fullDay === true
      ? `${fullDayPrice}`
      : `${
          startTime && endNewDate
            ? `${calcPrice > fullDayPrice ? fullDayPrice : calcPrice}`
            : ''
        }`;

  // Meeting Booking price details like, price, discount, subTotal, vat etc...
  const mrSubTotal =
    Number(mrBookingPrice) -
    (Number(discountPrice) > Number(mrBookingPrice)
      ? 0
      : Number(discountPrice));
  const mrVat = (mrVatRate / 100) * mrSubTotal;
  const roomTotal = mrSubTotal + mrVat;

  //  Catering Booking price details like, price, discount, subTotal, vat etc...
  const cateringSubTotal = cartItems.reduce(
    (a, c) => a + c.quantity * c.price,
    0,
  );

  const cateringVat = (cateringVatRate / 100) * cateringSubTotal;
  const cateringSubTotalWithVat = cateringSubTotal + cateringVat;
  const subTotal = mrSubTotal + cateringSubTotal; // Exc vat
  const total = Number(roomTotal) + Number(cateringSubTotalWithVat); // Inc vat

  return (
    <MrExtendBookingContext.Provider
      value={{
        finalStartDate,
        finalEndDate,
        discountPrice,
        setDiscountPrice,
        cartItems,
        setCartItems,
        createCateringToggle,
        setCreateCateringToggle,
        iscustomerDetailsExist,
        setIsCustomerDetailsExist,
        vat,
        setVat,
        bookingDate,
        setBookingDate,
        endNewDate,
        setEndNewDate,
        mrRoomId,
        setMrRoomId,
        mrRoomName,
        setMrRoomName,
        mrRoomLayout,
        setMrRoomLayout,
        createMeetingRoom,
        setCreateMeetingRoom,
        fullDay,
        setFullDay,
        layoutName,
        setLayoutName,
        buildingId,
        setBuildingId,
        village,
        setVillage,
        buildingName,
        setBuildingName,
        mrCapacity,
        setMrCapacity,
        startTime,
        setStartTime,
        customerType,
        setCustomerType,
        tenant,
        setTenant,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        phoneNumber,
        setPhoneNumber,
        email,
        setEmail,
        customerDetailsToggle,
        setCustomerDetailsToggle,
        fullDayPrice,
        setFullDayPrice,
        hourlyPrice,
        setHourlyPrice,
        categoryNames,
        setCategoryNames,
        categoryId,
        setCategoryId,
        catMenuId,
        setCatMenuId,
        catMenuItemName,
        setCatMenuItemName,
        activeStep,
        setActiveStep,
        previewStep,
        setPreviewStep,
        mrBookingId,
        setMRBookingId,
        calcPrice,
        setCalcPrice,
        minimumBookingTime,
        setMinimumBookingTime,
        roomLayoutArray,
        setRoomLayoutArray,
        notes,
        setNotes,
        capaCityValue,
        setCapaCityValue,
        mrPayment,
        setMrPayment,
        mrPaymentPos,
        setMrPaymentPos,
        mrPaymentPosMessage,
        setMrPaymentPosMessage,
        mrBookingPrice,
        mrVatRate,
        cateringVatRate,
        mrSubTotal,
        mrVat,
        roomTotal,
        cateringSubTotal,
        cateringVat,
        cateringSubTotalWithVat,
        subTotal,
        total,
        selectedMenuItems,
        setSelectedMenuItems,
        mrCardPayment,
        setCardPayment,
        terminals,
        setTerminals,
        terminalId,
        setTerminalId,
        circularLoading,
        setCircularLoading,
        setLoaderNotification,
        loaderNotification,
      }}
    >
      <ExtendMeetingRoomBooking />
    </MrExtendBookingContext.Provider>
  );
};
