import React from 'react';
import { Button, Popover } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import useFilterStyles from '../Styles/useFilterStyles';
import minus from '../../images/minus.svg';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';

const DateRangeFilter = ({ values, title }) => {
  const {
    id,
    handleClose,
    anchorEl,
    setAnchorEl,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    dateRangeSearch,
    setDateRangeSearch,
    onSelectDateRangeButton,
    setOnSelectDateRangeButton,
  } = values;
  const classes = useFilterStyles();

  const clearDateRange = () => {
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  return (
    <>
      <li
        className={
          onSelectDateRangeButton === true ||
          selectedStartDate ||
          selectedEndDate
            ? `${classes.active} ${
                selectedStartDate || selectedEndDate
                  ? classes.valueSelected
                  : ''
              }`
            : ''
        }
      >
        <Button
          aria-describedby={id}
          variant="contained"
          onClick={event => {
            setDateRangeSearch(true);
            setAnchorEl(event.currentTarget);
            setOnSelectDateRangeButton(true);
          }}
        >
          {title}
          <small>
            <img src={plus} alt="plus" />
            <img src={minus} alt="minus" />
          </small>
        </Button>
        <i
          className="closeIconBtn"
          role="presentation"
          onClick={clearDateRange}
        >
          <img src={close} alt="close" />
        </i>
        <Popover
          id="date-range"
          open={dateRangeSearch}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div className="filterDropdownDtRange">
            <div className="filterDropdownin calenderPicker">
              <ul className={`${classes.InputSection} offerDtCat mrDtCal`}>
                <li>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <span>Start date*</span>
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      variant="inline"
                      disableToolbar
                      autoOk
                      value={selectedStartDate}
                      onChange={setSelectedStartDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </li>
                <li>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <span>End date*</span>
                    <KeyboardDatePicker
                      disableToolbar
                      format="dd/MM/yyyy"
                      variant="inline"
                      autoOk
                      minDate={selectedStartDate}
                      minDateMessage="Can't be earlier than Start date."
                      value={selectedEndDate}
                      onChange={setSelectedEndDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </li>
              </ul>
            </div>
          </div>
        </Popover>
      </li>
    </>
  );
};
export default DateRangeFilter;
