const cateringConstants = {
    routesList: [
      {
        path: '/',
        value: 'Catering Category',
        accessValue: 'getCateringCategories',
      },
      {
        path: '/catering-menu-list',
        value: 'Catering Menu',
        accessValue: 'getCateringItems',
      },
    ],
  };
  export default cateringConstants;
  