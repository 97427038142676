export const CLIENT_ID = '80239be4-ac80-45be-8b83-d6c569162eeb';
export const TENANT_ID = 'a6a46f35-b717-43b1-90c8-4d503ab22b8f';
export const AZURE_PROFILE_FUN_KEY =
  'KpUHyV2NAwMG/xpUvVgaZwlgUjZdyalmGd8C0qcVdnigbcgyJ5VUlA==';
export const API_BASE_URL =
  'https://derwent-central-api.azure-api.net/staff';
export const BLOB_CONTAINER_NAME = 'blob-media-derwentcx-qa-se';
export const SAS_TOKEN =
  'sp=racwdli&st=2023-11-23T06:04:35Z&se=2025-10-01T14:04:35Z&spr=https&sv=2022-11-02&sr=c&sig=UEuS1bZj94EdnpcEe6%2BYGL4NSeUaS%2BS2NCpRzoF%2BBNs%3D';
export const STORAGE_ACCOUNT_NAME = 'storageaccountderwe88fd';
export const CLOUDINARY_API_KEY = '771218461155159';
export const CLOUDINARY_API_SECRET_KEY = 'xIVKys0CbspqU-lLWHDJbQcK7r8';
export const CLOUD_NAME = 'dlapp';
export const CLOUDINARY_UPLOAD_PRESET = 'dl_app';
export const SQUARE_APPLICATION_ID = 'sq0idp-o0N6k8PPRX5F8KexhSwhBw';
export const SQUARE_LOCATION_ID = 'LJP3SPSYJ7VB9';
export const APP_CONFIG_PARAMS = 'https://stfunprofileserviceapi.blob.core.windows.net/staticpages/prodAppConfigParams.json'