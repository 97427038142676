/* eslint-disable import/no-cycle */
import React, { createContext, useState, useEffect, useContext } from 'react';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';
import { authContext } from '../adal';
import TenentUserList from '../containers/Users/TenentUserList';
import { GlobalContext } from './GlobalContext';
import { logOut } from '../utils/logOut';

export const TenentContext = createContext({});

export const TenentContextProvider = () => {
  const {
    setPermissionsStatus,
    handleClick,
    tenants,
    setTenants,
    tenantSearchText,
    setTenantSearchText,
    tenanSearchId,
    setTenanSearchId,
  } = useContext(GlobalContext);
  const [customerSearch, setCustomerSearch] = useState('');
  const [tenantsUsersList, setTenantsUsersList] = useState([]);
  const [tenantsList, setTenantsList] = useState([]);
  const [totalTenants, setTotalTenants] = useState(0);
  const [totalTenantsCount, setTotalTenantsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [tenantsSearch, setTenantsSearch] = useState(false);
  const [searchTenants, setSearhTenants] = useState('');
  const [exportList, setExportList] = useState(false);
  const [tenantUserSearch, setTenantUserSearch] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [villages, setVillages] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('firstName');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [supportMarker, setSupportMarker] = useState('');
  const [statusInactive, setStatusInactive] = useState('');
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [statusBlocked, setStatusBlocked] = useState('');
  const [statusActive, setStatusActive] = useState('');
  const [payAccountTrue, setPayAccountTrue] = useState(undefined);
  const [payAccountFalse, setPayAccountFalse] = useState('');
  const [tenantActiveUsers, setTenantActiveUsers] = useState('');
  const [payAccountSelected, setPayAccountSelectede] = useState('');
  const [offset, setOffset] = useState(0);
  const [buildingDataList, setBuildingDataList] = useState([]);
  const [descriptionValue, setDescriptionValue] = useState('');
  const [roleOptions, setRoleOptions] = useState([
    { label: 'Super Administrator', key: 'Super Administrator', selected: false },
    { label: 'Employee', key: 'Employee', selected: false },
  ]);
  const [newStatus, setNewStatus] = useState([
    { label: 'Active', key: 'Active', selected: false },
    { label: 'Inactive', key: 'Inactive', selected: false },
    { label: 'Blocked', key: 'Blocked', selected: false },
    { label: 'Renewal Pending', key: 'Renewal Pending', selected: false },
  ]);
  const [payAccountOptions, setPayAccountOptions] = useState([
    { label: 'Yes', key: true, selected: false },
    { label: 'No', key: false, selected: false },
  ]);

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  const logApi = () => {
    const payload = {
      act: 'signIn',
    };
    ApiClient.apiPost(apiEndPoints.logAct, payload)
      .then(() => {
        console.log('');
      })
      .catch(error => {
        console.log(error);
      });
  };

  // get getRolePermission API Call
  const getRolePermission = () => {
    setLoading(true);
    ApiClient.apiGet(apiEndPoints.getRolePermission)
      .then(data => {
        const permissionData = [data.data.data];
        const myHour = new Date();
        myHour.setHours(myHour.getHours() + 24);
        permissionData.push(myHour);
        localStorage.setItem('rolePermission', JSON.stringify(permissionData));
        setLoading(false);
        setPermissionsStatus(true);
      })
      .catch(error => {
        authContext.logOut();
        setLoading(false);
        console.log(error, 'error');
      });
  };

  function checkExpiration() {
    const values = JSON.parse(localStorage.getItem('rolePermission'));
    if (values && values[1] < new Date()) {
      localStorage.removeItem('rolePermission');
    }
  }

  function myFunction() {
    const myinterval = 15 * 60 * 1000; // 15 min interval
    // eslint-disable-next-line func-names
    setInterval(function () {
      checkExpiration();
    }, myinterval);
  }

  myFunction();

  // get Tenants API Call
  const getTenants = tenantsPayload => {
    if (tenantsSearch) {
      // get tenant users api call.
      ApiClient.apiPost(apiEndPoints.getTenants, tenantsPayload)
        .then(data => {
          setTenants(data && data.data && data.data.data);
          setLoading(false);
        })
        .catch(error => {
          console.log(error, 'error');
        });
    }
  };

  // get Tenants UserList API Call
  const getTenantusersListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getTenantsUsersList, payload)
      .then(data => {
        setTenantActiveUsers(data && data.data.activeUsers);
        setTotalTenants(data && data.data.count);
        setTotalTenantsCount(data && data.data.uniqueTenants);
        setTenantsUsersList(data && data.data && data.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  // get Tenants UserList API Call
  const getTenantusersListExport = payload => {
    ApiClient.apiPost(apiEndPoints.exportList, payload)
      .then(data => {
        const link = document.createElement('a');
        link.href = data && data.data.data.link;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  // combination for Filters API for Tenant Users passing payload as parameter
  useEffect(() => {
    const roles = [];
    const statusUpdate = [];
    const payToAccountUpdate = [];
    roleOptions.filter(role => {
      if (role.selected) {
        roles.push(role.key);
      }
      return role.selected !== false;
    });
    newStatus.filter(status => {
      if (status.selected) {
        statusUpdate.push(status.key);
      }
      return status.selected !== false;
    });
    payAccountOptions.filter(account => {
      if (account.selected) {
        payToAccountUpdate.push(account.key);
      }
      return account.selected !== false;
    });
    setSelectedRoles(roles);
    setSelectedStatus(statusUpdate);
    setPayAccountSelectede(payToAccountUpdate);
    if (exportList) {
      getTenantusersListExport({
        searchText: customerSearch.length > 2 ? customerSearch : undefined,
        tenantId: tenantSearchText === '' ? undefined : tenanSearchId,
        buildings: buildings && buildings.length > 0 ? buildings : undefined,
        sortOrder,
        role: roles && roles.length > 0 ? roles : undefined,
        payToAccount:
          payToAccountUpdate && payToAccountUpdate.length > 0
            ? payToAccountUpdate
            : undefined,
        sortBy,
        status:
          statusUpdate && statusUpdate.length > 0 ? statusUpdate : ['Active'],
        offset: 0,
        limit: totalTenants,
      });
    }
  }, [exportList]);

  useEffect(() => {
    if (localStorage.getItem('rolePermission') === null) {
      getRolePermission();
      logApi();
    } else {
      setPermissionsStatus(true);
    }
  }, []);

  // getTenants API call with payload
  useEffect(() => {
    if (tenantsSearch && searchTenants.length > 2) {
      getTenants({
        searchText: searchTenants.length > 2 ? searchTenants : undefined,
        sortOrder: 'asc',
        sortBy: 'name',
        offset: 0,
        limit: 500,
      });
    }
  }, [searchTenants]);

  // combination for Filters API for Tenant Users passing payload as parameter
  useEffect(() => {
    const roles = [];
    const statusUpdate = [];
    const payToAccountUpdate = [];
    roleOptions.filter(role => {
      if (role.selected) {
        roles.push(role.key);
      }
      return role.selected !== false;
    });
    newStatus.filter(status => {
      if (status.selected) {
        statusUpdate.push(status.key);
      }
      return status.selected !== false;
    });
    payAccountOptions.filter(account => {
      if (account.selected) {
        payToAccountUpdate.push(account.key);
      }
      return account.selected !== false;
    });
    setSelectedRoles(roles);
    setSelectedStatus(statusUpdate);
    setPayAccountSelectede(payToAccountUpdate);

    getTenantusersListAction({
      searchText: customerSearch.length > 2 ? customerSearch : undefined,
      tenantId: tenantSearchText === '' ? undefined : tenanSearchId,
      buildings: buildings && buildings.length > 0 ? buildings : undefined,
      sortOrder,
      role: roles && roles.length > 0 ? roles : undefined,
      payToAccount:
        payToAccountUpdate && payToAccountUpdate.length > 0
          ? payToAccountUpdate
          : undefined,
      sortBy,
      status:
        statusUpdate && statusUpdate.length > 0 ? statusUpdate : ['Active'],
      offset,
      limit: 40,
    });
  }, [
    page,
    tenantSearchText,
    customerSearch,
    sortBy,
    tenanSearchId,
    buildings,
    sortOrder,
    newStatus,
    payAccountOptions,
    roleOptions,
  ]);

  return (
    <TenentContext.Provider
      value={{
        totalTenantsCount,
        setTotalTenantsCount,
        tenantsUsersList,
        totalTenants,
        tenanSearchId,
        loading,
        payAccountOptions,
        setPayAccountOptions,
        supportMarker,
        setSupportMarker,
        statusInactive,
        setStatusInactive,
        selectedRoles,
        setSelectedRoles,
        statusBlocked,
        setStatusBlocked,
        statusActive,
        setStatusActive,
        setVillages,
        setRoleOptions,
        roleOptions,
        payAccountTrue,
        setPayAccountTrue,
        payAccountFalse,
        setPayAccountFalse,
        setSelectedStatus,
        selectedStatus,
        villages,
        buildings,
        setBuildings,
        page,
        setPage,
        setOffset,
        customerSearch,
        setCustomerSearch,
        setTenantsUsersList,
        buildingDataList,
        setBuildingDataList,
        setLoading,
        tenantsSearch,
        setTenantsSearch,
        searchTenants,
        setSearhTenants,
        setNewStatus,
        newStatus,
        tenantSearchText,
        setTenantSearchText,
        tenants,
        handleClick,
        setSortOrder,
        setTenanSearchId,
        tenantUserSearch,
        setTenantUserSearch,
        setTenants,
        sortOrder,
        setSortBy,
        sortBy,
        payAccountSelected,
        setPayAccountSelectede,
        tenantActiveUsers,
        descriptionValue,
        setDescriptionValue,
        tenantsList,
        setTenantsList,
        exportList,
        setExportList,
      }}
    >
      <TenentUserList />
    </TenentContext.Provider>
  );
};
