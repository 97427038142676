import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';
import minus from '../../images/minus.svg';
import useFilterStyles from '../../components/Styles/useFilterStyles';

const AdditionalInformation = ({
  dropDownFields,
  categories,
  setSelected,
  name,
}) => {
  const classes = useFilterStyles();
  const uniqueFields = categories.map(field => field.toLowerCase())
  const [additionalInformationSearch, setAdditionalInformationSearch] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [additionalInformationTab, setAdditionalInformationTab] = useState('');
  const [selectedCategory, setSelectedCategory] = useState([...uniqueFields]);

  const [checked, setIschecked] = useState(true);

  const onDietCategory = (e, field) => {
    if (e.target.checked) {
      setSelectedCategory([...selectedCategory, field]);
      setIschecked(!checked);
      setSelected([...selectedCategory, field]);
    } else {
      const difference = selectedCategory.filter(d => d !== field);
      setSelectedCategory(difference);
      setSelected(difference);
      setIschecked(!checked);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAdditionalInformationSearch(null);
    setAdditionalInformationTab(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="offerfilterRgt">
      <ul className={`${classes.leftFilterIn} leftFilterIn`}>
        <li
          className={
            additionalInformationTab === true ||
            (selectedCategory && selectedCategory.length > 0)
              ? `${classes.active} ${
                  selectedCategory && selectedCategory.length > 0
                    ? classes.valueSelected
                    : ''
                }`
              : ''
          }
        >
          <Button
            aria-describedby={id}
            variant="contained"
            onClick={event => {
              setAdditionalInformationSearch(true);
              setAnchorEl(event.currentTarget);
              setAdditionalInformationTab(true);
            }}
          >
            {selectedCategory.length > 0
              ? `${selectedCategory.length} Selected`
              : 'Select'}
            <small>
              <img src={plus} alt="plus" />
              <img src={minus} alt="minus" />
            </small>
          </Button>
          <i
            className="closeIconBtn"
            role="presentation"
            onClick={() => {
              setSelectedCategory([]);
              setSelected([]);
              setAnchorEl('');
            }}
          >
            <img src={close} alt="close" />
          </i>
          <Popover
            id="offer-category"
            open={additionalInformationSearch}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <div className="filterDropdown filterDropdownCheck offerFilterDropdownCheck">
              <div className="filterDropdownin">
                {dropDownFields && (
                  <List className={classes.moreList}>
                    <h4>{`Select ${name} Information`}</h4>
                    {dropDownFields.map(field => (
                      <>
                        <ListItem key={field}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={field}
                                checked={
                                  selectedCategory &&
                                  selectedCategory.includes(field)
                                }
                                onChange={e => {
                                  onDietCategory(e, field);
                                }}
                              />
                            }
                            label={field}
                          />
                        </ListItem>
                      </>
                    ))}
                  </List>
                )}
              </div>
            </div>
          </Popover>
        </li>
      </ul>
    </div>
  );
};

export default AdditionalInformation;
