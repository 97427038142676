/* eslint-disable no-unused-expressions */
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogActions,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { GlobalContext } from '../../context/GlobalContext';

const useStyles = makeStyles(() => ({
  formButton: {
    marginTop: '0',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      height: '47px',
      lineHeight: '47px',
      border: '1px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff',
      marginLeft: '30px',
      cursor: 'pointer',
      textTransform: 'capitalize',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#444444',
        border: 'none',
      },
    },
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  popupFormButton: {
    marginTop: '45px !important',
  },
}));

const WarningPopup = props => {
  const {
    inActivePopUp,
    setInActivePopUp,
    setAvailable,
    setInActive,
    popupMessage,
    setRoomstoMerge,
    setSelectedRoomType,
    roomTypeValue,
    cardManagement,
    handleClear,
    setIsVideoSelected,
    clearSelectedCards,
    isVideoSelected,
    setclearSelectedCards,
  } = props;
  const { open, setOpen, closePopup } = useContext(GlobalContext);
  const history = useHistory();
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    setSelectedRoomType && setSelectedRoomType(roomTypeValue);
    setRoomstoMerge && setRoomstoMerge(false);
    setInActivePopUp && setInActivePopUp(false);
    setAvailable && setAvailable(true);
    setInActive && setInActive(false);
  };

  const editMrRooms = () => {
    setRoomstoMerge && setRoomstoMerge(false);
    setInActivePopUp && setInActivePopUp(false);
  };

  const closePopupView = () => {
    if (cardManagement && clearSelectedCards === 'offerCards') {
      handleClear();
    } else if (cardManagement) {
      setIsVideoSelected(!isVideoSelected);
      setclearSelectedCards('');
    }
    history.push(closePopup);
    setOpen(false);
  };

  return (
    <Dialog
      open={open || inActivePopUp}
      onClose={handleClose}
      disableBackdropClick
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="cancelDialogOpend"
    >
      <div className={classes.cancelDialog}>
        <h2>{popupMessage || 'You will lose all unsaved changes.'}</h2>
        <p>{!inActivePopUp && 'Do you want to proceed?'}</p>
      </div>
      <Divider className="formDivider popupDivider" light />
      <DialogActions className={`${classes.formButton}`}>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button
          onClick={() => {
            popupMessage ? editMrRooms() : closePopupView();
          }}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningPopup;
