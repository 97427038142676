/* eslint-disable array-callback-return */
import React, { useEffect, useState, useContext } from 'react';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  MenuItem,
  Select,
  InputLabel,
  Input,
  FormHelperText,
  CircularProgress,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory, useParams } from 'react-router-dom';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import OffersLocationFilter from '../../components/Filters/OffersLocationFilter';
import LocationFilter from '../../components/Filters/LocationFilter';
import dowmArw from '../../images/down-arw.svg';
import './CreateAndUpdateOffer.css';
// import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import FileUpload from '../../components/ImageUpload/FileUpload';
import {
  digitalOffersSchema,
  physicalOffersSchema,
} from '../../utils/formValidate';
import { GlobalContext } from '../../context/GlobalContext';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
// import { offers_description_folder } from '../../components/ImageUpload/constants';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';
import PhysicalVillageFilter from '../../components/Filters/PhysicalVillageFilter';
import OffersVillageFilter from '../../components/Filters/OffersVillageFilter';
import { utcToIstDateFormate } from '../../utils/dateUtils';
import { offerType } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
        position: 'relative',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
      },
      '& label': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384 !important',
        transform: 'none',
        textTransform: 'capitalize',
        position: 'static',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& > div': {
          '& > div': {
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
  leftFilterIn: {
    '& button': {
      color: '#444444',
      backgroundColor: '#f8f8f8',
      padding: '12px 12px 10px',
      fontWeight: 'bold',
      boxShadow: 'none',
      fontSize: '14px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      borderRadius: 5,
      '&:hover': {
        boxShadow: 'none',
        background: '#f8f8f8',
      },
      '& span': {
        lineHeight: '100%',
        alignItems: 'center',
        display: 'flex',

        '& small': {
          marginLeft: 20,
          top: '-2px',
          position: 'relative',
        },
        '& img': {
          '&:nth-child(2)': {
            display: 'none',
          },
        },
      },
    },
  },
}));
const offerTypes = [
  { name: offerType.digital, id: offerType.digital },
  { name: offerType.physical, id: offerType.physical},
  { name: offerType.digitalUrlOnly, id: offerType.digitalUrlOnly },
];

const CreateAndUpdateOffer = () => {
  const classes = useStyles();
  const { setSuccessMessage, buildingDataList, buildingGroupsList } =
    useContext(GlobalContext);
  const { id } = useParams();
  const history = useHistory();
  const [offerName, setOfferName] = useState('');
  const [status, setStatus] = useState('Published');
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [offerCategories, setOfferCategories] = useState([]);
  const [selectedOfferCategory, setSelectedOfferCategory] = useState('');
  const [selectedOfferType, setSelectedOfferType] = useState('');
  const [voucherCode, setVoucherCode] = useState('');
  const [companyUrl, setCompanyUrl] = useState('');
  const [offerUrl, setOfferUrl] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [locationSearch, setLocationSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const [storeAddress, setStoreAddress] = useState('');
  const [storeAddress2, setStoreAddress2] = useState('');
  const [city, setCity] = useState('');
  const [county, setCounty] = useState('');
  const [postCode, setPostCode] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [terms, setTerms] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [images, setImages] = useState([]);
  const [companyLogo, setCompanyLogo] = useState('');
  const [loading, setLoading] = useState(true);
  const [buildings, setBuildings] = useState([]);
  const [villages, setVillages] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');
  const [voucherErrorMsg, setVoucherErrorMsg] = useState('');
  const [createOfferToggle, setCreateOfferToggle] = useState(false);
  const [selectedVillage, setSelectedVillage] = useState([]);
  const [clicksCount, setClickCount] = useState(0);
  const [viewsCount, setViewCount] = useState(0);
  if (id) {
    if (!JSON.parse(localStorage.getItem('rolePermission'))[0].editOffer) {
      history.push('/offers-list');
    }
  } else if (
    !JSON.parse(localStorage.getItem('rolePermission'))[0].createOffer
  ) {
    history.push('/offers-list');
  }
  const buildingPayload = buildingDataList.filter(o1 =>
    buildings.some(o2 => o1.id === o2),
  );

  const buildingArray = [];
  if (buildingPayload) {
    buildingPayload.map(item => {
      if (item.id && item.village && item.name) {
        buildingArray.push({
          id: item.id,
          name: item.name,
          village: item.village,
        });
      }
    });
  }
  useEffect(() => {
    if (id) {
      ApiClient.apiGet(`${apiEndPoints.createOffers}/${id}`)
        .then(data => {
          const offersData =
            data && data.data && data.data.data && data.data.data;
          setStatus(offersData.status);
          setOfferName(offersData.offerName);
          setLoading(false);
          setSelectedVillage(offersData && offersData.village);
          setSelectedStartDate(new Date(offersData.startDate));
          setSelectedEndDate(utcToIstDateFormate(offersData.endDate));
          const buildingData = offersData.buildings.map(item => {
            return item.id;
          });
          setBuildings(buildingData);
          setSelectedOfferCategory(offersData.offerCategory);
          setSelectedOfferType(
            offersData.offerType === 'Url'
              ? offerType.digitalUrlOnly
              : offersData.offerType,
          );
          setVoucherCode(offersData.voucherCode);
          setOfferUrl(offersData.offerUrl);
          setDescriptionValue(offersData.description);
          setTerms(offersData.terms);
          setCompanyName(offersData.companyName);
          setCompanyUrl(offersData.companyUrl);
          setCompanyLogo(offersData.companyLogo);
          setImages(offersData.offerImage);
          setStoreAddress(
            offersData && offersData.address && offersData.address.line1,
          );
          setStoreAddress2(
            offersData && offersData.address && offersData.address.line2,
          );
          setCity(offersData && offersData.address && offersData.address.city);
          setCounty(
            offersData && offersData.address && offersData.address.county,
          );
          setPostCode(
            offersData && offersData.address && offersData.address.postcode,
          );
          setLatitude(
            offersData && offersData.address && offersData.address.latitude,
          );
          setLongitude(
            offersData && offersData.address && offersData.address.longitude,
          );
          setClickCount(offersData?.clicksCount || 0);
          setViewCount(offersData?.viewsCount || 0);
        })
        .catch(err => {
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
        });
    }
  }, []);

  useEffect(() => {
    ApiClient.apiGet(apiEndPoints.getOffersCategory)
      .then(data => {
        setOfferCategories(data && data.data && data.data.data);
        setLoading(false);
      })
      .catch(err => {
        alert(
          err && err.response && err.response.data && err.response.data.message,
        );
      });
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
    setLocationSearch(false);
    setOnCheckedLocationButton(false);
  };
  const removeRepeatVillages =
    selectedVillage &&
    selectedVillage.filter(
      (value, index) => selectedVillage.indexOf(value) === index,
    );

  const handleValidation =
    selectedOfferType === offerType.physical
      ? physicalOffersSchema
      : digitalOffersSchema;

  useEffect(() => {
    if (
      (voucherCode &&
        voucherCode.length >= 3 &&
        voucherCode !== '' &&
        offerUrl !== '') ||
      (voucherCode && voucherCode.length >= 3 && !offerUrl) ||
      (!voucherCode && offerUrl) ||
      (voucherCode && voucherCode.length >= 3 && offerUrl)
    ) {
      setVoucherErrorMsg('');
    } else if (voucherCode && voucherCode.length < 3) {
      setVoucherErrorMsg('must be at least 3 characters');
    } else if (createOfferToggle) {
      setVoucherErrorMsg('Voucher code or Offer url is required');
    }
    const physicalSchema = {
      offerNameValidation: offerName,
      startDateValidation: selectedStartDate,
      endDateValidation: selectedEndDate,
      offerCategoryValidation: selectedOfferCategory,
      offerTypeValidation: selectedOfferType,
      descriptionValidation: descriptionValue,
      termsValidation: terms,
      companyNameValidation: companyName,
      companyUrlValidation: companyUrl,
      locationValidation:
        buildingArray && buildingArray.length > 0 ? buildingArray : buildings,
      villageValidation: selectedVillage,
      offerImageValidation: images,
      companyLogoValidation: companyLogo,
      address1: storeAddress,
      city,
      county,
      pincode: postCode,
      latitude,
      longitude,
    };
    const digitalSchema = {
      offerNameValidation: offerName,
      startDateValidation: selectedStartDate,
      endDateValidation: selectedEndDate,
      offerCategoryValidation: selectedOfferCategory,
      offerTypeValidation: selectedOfferType,
      voucherCodeValidation: voucherCode,
      offerUrlValidation: offerUrl,
      descriptionValidation: descriptionValue,
      termsValidation: terms,
      companyNameValidation: companyName,
      companyUrlValidation: companyUrl,
      locationValidation:
        buildingArray && buildingArray.length > 0 ? buildingArray : buildings,
      offerImageValidation: images,
      companyLogoValidation: companyLogo,
      villageValidation:
        (selectedOfferType === offerType.digitalUrlOnly && selectedVillage) ||
        (selectedOfferType === offerType.digital && selectedVillage) ||
        undefined,
    };

    handleValidation
      .validate(
        selectedOfferType === offerType.physical ? physicalSchema : digitalSchema,
      )
      .then(() => {
        if (
          (createOfferToggle &&
            descriptionValue !== '<p><br></p>' &&
            selectedOfferType !== offerType.physical &&
            createOfferToggle &&
            selectedEndDate >= selectedStartDate &&
            ((voucherCode && voucherCode.length >= 3 && createOfferToggle) ||
              (offerUrl && createOfferToggle))) ||
          (selectedOfferType === offerType.physical &&
            createOfferToggle &&
            selectedEndDate >= selectedStartDate)
        ) {
          setLoading(true);
          let createOfferPayload;
          if (selectedOfferType !== offerType.physical) {
            createOfferPayload = {
              id: id || undefined,
              offerName,
              offerCategory: selectedOfferCategory,
              offerImage: images,
              description: `<p>${descriptionValue.replace(/<[^>]+>/g, '')}</p>`,
              terms,
              startDate: new Date(selectedStartDate).toISOString(),
              endDate: new Date(selectedEndDate).toISOString(),
              buildings:
                buildingArray && buildingArray.length > 0
                  ? buildingArray
                  : buildings,
              companyName,
              companyLogo,
              companyUrl: companyUrl.trim(),
              offerType:
                selectedOfferType === offerType.digitalUrlOnly
                  ? 'Url'
                  : selectedOfferType,
              status,
              offerUrl: offerUrl || undefined,
              voucherCode: voucherCode || undefined,
              clicksCount: clicksCount,
              viewsCount: viewsCount,
              village: removeRepeatVillages,
            };
          } else {
            createOfferPayload = {
              id: id || undefined,
              offerName,
              offerCategory: selectedOfferCategory,
              offerImage: images,
              description: `<p>${descriptionValue.replace(/<[^>]+>/g, '')}</p>`,
              terms,
              startDate: new Date(selectedStartDate).toISOString(),
              endDate: new Date(selectedEndDate).toISOString(),
              buildings: buildingArray.length > 0 ? buildingArray : buildings,
              village: removeRepeatVillages || undefined,
              companyName,
              companyLogo,
              companyUrl: companyUrl.trim(),
              offerType: selectedOfferType,
              status,
              address: {
                line1: storeAddress,
                line2: storeAddress2,
                city,
                county,
                postcode: postCode,
                latitude: Number(latitude),
                longitude: Number(longitude),
              },
              clicksCount: clicksCount,
              viewsCount: viewsCount,
            };
          }
          if (id) {
            ApiClient.apiPut(
              `${apiEndPoints.createOffers}/${id}`,
              createOfferPayload,
            )
              .then(() => {
                setSuccessMessage(`${offerName} updated successfully`);
                setLoading(false);
                history.push('/offers-list');
              })
              .catch(err => {
                alert(
                  err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.message,
                );
                setCreateOfferToggle(false);
                setLoading(false);
              });
          } else {
            ApiClient.apiPost(apiEndPoints.createOffers, createOfferPayload)
              .then(() => {
                setSuccessMessage(`${offerName} created successfully`);
                setLoading(false);
                history.push('/offers-list');
              })
              .catch(err => {
                alert(
                  err &&
                    err.response &&
                    err.response.data &&
                    err.response.data.message,
                );
                setCreateOfferToggle(false);
                setLoading(false);
              });
          }
        }
      })
      .catch(error => {
        console.log(error, 'error');
        if (createOfferToggle) {
          setErrorMsg(error);
          setCreateOfferToggle(false);
        }
      });
  }, [
    createOfferToggle,
    offerName,
    selectedOfferCategory,
    selectedOfferType,
    selectedEndDate,
    descriptionValue,
    buildings,
    selectedVillage,
    images,
    companyLogo,
    voucherCode,
    offerUrl,
    companyName,
    companyUrl,
    terms,
    storeAddress,
    city,
    postCode,
    latitude,
    longitude,
  ]);

  const errorType = errorMsg && errorMsg.type;
  const errorFieldName = errorMsg && errorMsg.path;
  const errorFieldValue = errorMsg && errorMsg.value;

  const open = Boolean(anchorEl);
  const openId = open ? 'simple-popover' : undefined;

  const breadCrumbs = !id
    ? {
        listBreadCrumbPath: '/offers-list',
        listBreadCrumbTitle: 'Offers',
        addBreadCrumbPath: '/create-offers',
        addBreadCrumbTitle: 'Create New Offer',
      }
    : {
        listBreadCrumbPath: '/offers-list',
        listBreadCrumbTitle: 'Offers',
        viewBreadCrumbPath: '/view-offers/',
        viewBreadCrumbTitle: 'View Offer',
        editBreadCrumbPath: '/edit-offers/',
        editBreadCrumbTitle: 'Edit Offer',
      };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addMeetingRoomUserContainer">
            <div className={`${classes.addUserForm} offerForm`}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Offer Details</FormLabel>
                </li>
              </ul>
              <ul className={`${classes.InputSection} textareaSection`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Offer Name*
                  </InputLabel>
                  <Input
                    className="textareaSmallBox"
                    type="text"
                    maxLength="150"
                    inputProps={{ 'aria-label': 'offerName' }}
                    value={offerName}
                    onChange={e => {
                      setOfferName(e.target.value);
                    }}
                  />
                  <div className="descriptionBox offerFormSortDesc offerFormText">{`${offerName.length}/150`}</div>
                  {((errorType === 'min' &&
                    errorFieldName === 'offerNameValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.offerNameValidation === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        offerName === ''
                          ? offerName === '' && 'Offer Name is required.'
                          : errorType === 'min' &&
                            'must be at least 3 characters'
                      }
                    >
                      {offerName === ''
                        ? offerName === '' && 'Offer Name is required.'
                        : errorType === 'min' &&
                          'must be at least 3 characters'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <ul className={`${classes.InputSection} offerDtCat`}>
                <li>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <span>Start Date*</span>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      margin="normal"
                      id="date-picker-inline"
                      variant="inline"
                      format="dd/MM/yyyy"
                      value={selectedStartDate}
                      onChange={setSelectedStartDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {errorFieldValue &&
                    errorFieldValue.startDateValidation === null && (
                      <FormHelperText
                        className={classes.errorMsg}
                        component="div"
                        error={
                          selectedStartDate === null &&
                          'Start date is required.'
                        }
                      >
                        {selectedStartDate === null &&
                          'Start date is required.'}
                      </FormHelperText>
                    )}
                </li>
                <li>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <span>Expiry Date*</span>
                    <KeyboardDatePicker
                      disableToolbar
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      value={selectedEndDate}
                      onChange={setSelectedEndDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {((errorType === 'required' &&
                    errorFieldName === 'endDateValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.endDateValidation === null) ||
                    (selectedEndDate &&
                      selectedEndDate.getDate() < selectedStartDate &&
                      selectedStartDate.getDate())) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        (selectedEndDate === null &&
                          'Expiry date is required.') ||
                        (selectedEndDate < selectedStartDate &&
                          `Can't be earlier than Start date.`)
                      }
                    >
                      {(selectedEndDate === null &&
                        'Expiry date is required.') ||
                        (selectedEndDate < selectedStartDate &&
                          `Can't be earlier than Start date.`)}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Offer Category*
                    </InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      value={selectedOfferCategory}
                      className={classes.formSelectBox}
                      onChange={e => {
                        setSelectedOfferCategory(e.target.value);
                      }}
                    >
                      {offerCategories &&
                        offerCategories.map(offer => {
                          return (
                            <MenuItem value={offer} key={offer}>
                              {offer}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'offerCategoryValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.offerCategoryValidation === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        selectedOfferCategory === '' &&
                        'Offer Category is required.'
                      }
                    >
                      {selectedOfferCategory === '' &&
                        'Offer Category is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Offer Type*
                    </InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      className={classes.formSelectBox}
                      value={selectedOfferType}
                      onChange={e => {
                        setSelectedOfferType(e.target.value);
                        setVoucherCode('');
                        // setOfferUrl('');
                        setStoreAddress('');
                        setStoreAddress2('');
                        setCity('');
                        setCounty('');
                        setPostCode('');
                        setLatitude('');
                        setLongitude('');
                      }}
                    >
                      {offerTypes &&
                        offerTypes.map(item => {
                          return (
                            <MenuItem value={item.name} key={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'offerTypeValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.offerTypeValidation === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        selectedOfferType === '' && 'Offer Type is required.'
                      }
                    >
                      {selectedOfferType === '' && 'Offer Type is required.'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <ul className={`${classes.InputSection} physicalType`}>
                <li>
                  {selectedOfferType === offerType.physical && (
                    <ul className="physicalTypeSub">
                      <li>
                        <span>Store address line 1*</span>
                        <Input
                          type="text"
                          inputProps={{ 'aria-label': 'offerName' }}
                          value={storeAddress}
                          onChange={e => {
                            setStoreAddress(e.target.value);
                          }}
                        />
                        {((errorType === 'min' &&
                          errorFieldName === 'address1') ||
                          (errorFieldValue &&
                            errorFieldValue.address1 === '')) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            error={
                              storeAddress === ''
                                ? storeAddress === '' &&
                                  `Store address line 1 is required.`
                                : errorType === 'min' &&
                                  'must be at least 3 characters'
                            }
                          >
                            {storeAddress === ''
                              ? storeAddress === '' &&
                                `Store address line 1 is required.`
                              : errorType === 'min' &&
                                'must be at least 3 characters'}
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        <span>Store address line 2</span>
                        <Input
                          type="text"
                          inputProps={{ 'aria-label': 'offerName' }}
                          value={storeAddress2}
                          onChange={e => {
                            setStoreAddress2(e.target.value);
                          }}
                        />
                      </li>
                      <li>
                        <span>City*</span>
                        <Input
                          type="text"
                          inputProps={{ 'aria-label': 'offerName' }}
                          value={city}
                          onChange={e => {
                            setCity(e.target.value);
                          }}
                        />
                        {((errorType === 'min' && errorFieldName === 'city') ||
                          (errorType === 'matches' &&
                            errorFieldName === 'city') ||
                          (errorFieldValue && errorFieldValue.city === '')) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            error={
                              city === ''
                                ? city === '' && `City is required.`
                                : (errorType === 'min' &&
                                    'must be at least 3 characters') ||
                                  (errorType === 'matches' &&
                                    'Should not contain special characters')
                            }
                          >
                            {city === ''
                              ? city === '' && `City is required.`
                              : (errorType === 'min' &&
                                  'must be at least 3 characters') ||
                                (errorType === 'matches' &&
                                  'Should not contain special characters')}
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        <span>County</span>
                        <Input
                          type="text"
                          inputProps={{ 'aria-label': 'offerName' }}
                          value={county}
                          onChange={e => {
                            setCounty(e.target.value);
                          }}
                        />
                        {((errorType === 'matches' &&
                          errorFieldName === 'county') ||
                          (errorFieldValue &&
                            errorFieldValue.county &&
                            errorFieldValue.county.length > 0)) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            error={
                              // (errorFieldValue &&
                              //   errorFieldValue.county.length < 3 &&
                              //   'must be at least 3 characters') ||
                              errorType === 'matches' &&
                              'Should not contain special characters'
                            }
                          >
                            {
                              // (errorFieldValue &&
                              //   errorFieldValue.county.length < 3 &&
                              //   'must be at least 3 characters') ||
                              errorType === 'matches' &&
                                'Should not contain special characters'
                            }
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        <span>Postcode*</span>
                        <Input
                          type="text"
                          inputProps={{
                            'aria-label': 'offerName',
                            maxLength: '9',
                          }}
                          value={postCode}
                          onChange={e => {
                            const re = /^[A-Za-z0-9 ]+$/;
                            if (
                              e.target.value === '' ||
                              re.test(e.target.value)
                            ) {
                              setPostCode(e.target.value);
                            }
                          }}
                        />
                        {((errorType === 'min' &&
                          errorFieldName === 'pincode') ||
                          (errorType === 'matches' &&
                            errorFieldName === 'pincode') ||
                          (errorFieldValue &&
                            errorFieldValue.pincode === '')) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            error={
                              postCode === ''
                                ? postCode === '' && `Postcode is required.`
                                : (errorType === 'min' &&
                                    'Postcode should be min 3 digits') ||
                                  (errorType === 'matches' &&
                                    'Should not contain special characters')
                            }
                          >
                            {postCode === ''
                              ? postCode === '' && `Postcode is required.`
                              : (errorType === 'min' &&
                                  'Postcode should be min 3 digits') ||
                                (errorType === 'matches' &&
                                  'Should not contain special characters')}
                          </FormHelperText>
                        )}
                      </li>
                    </ul>
                  )}
                  {selectedOfferType === offerType.physical && (
                    <ul className="physicalTypeSubWrap">
                      <li>
                        <span>Latitude co-ordinate*</span>
                        <Input
                          type="text"
                          inputProps={{ 'aria-label': 'offerName' }}
                          value={latitude}
                          onChange={e => {
                            const re = /^[0-9-. ]+$/;
                            if (
                              e.target.value === '' ||
                              re.test(e.target.value)
                            ) {
                              setLatitude(e.target.value);
                            }
                          }}
                        />
                        {((errorType === 'min' &&
                          errorFieldName === 'latitude') ||
                          (errorType === 'matches' &&
                            errorFieldName === 'latitude') ||
                          (errorFieldValue &&
                            errorFieldValue.latitude === '')) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            error={
                              latitude === ''
                                ? latitude === '' && `Latitude is required.`
                                : (errorType === 'min' &&
                                    'Latitude should be min 3 digits') ||
                                  (errorType === 'matches' &&
                                    'Please enter valid latitude')
                            }
                          >
                            {latitude === ''
                              ? latitude === '' && `Latitude is required.`
                              : (errorType === 'min' &&
                                  'Latitude should be min 3 digits') ||
                                (errorType === 'matches' &&
                                  'Please enter valid latitude')}
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        <span>Longitude co-ordinate*</span>
                        <Input
                          type="text"
                          inputProps={{ 'aria-label': 'offerName' }}
                          value={longitude}
                          onChange={e => {
                            const re = /^[0-9-. ]+$/;
                            if (
                              e.target.value === '' ||
                              re.test(e.target.value)
                            ) {
                              setLongitude(e.target.value);
                            }
                          }}
                        />
                        {((errorType === 'min' &&
                          errorFieldName === 'longitude') ||
                          (errorType === 'matches' &&
                            errorFieldName === 'longitude') ||
                          (errorType === 'min' &&
                            errorFieldName === 'longitude') ||
                          (errorFieldValue &&
                            errorFieldValue.longitude === '')) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            error={
                              longitude === ''
                                ? longitude === '' && `Longitude is required.`
                                : (errorType === 'min' &&
                                    'Longitude should be min 3 digits') ||
                                  (errorType === 'matches' &&
                                    'Please enter valid longitude')
                            }
                          >
                            {longitude === ''
                              ? longitude === '' && `Longitude is required.`
                              : (errorType === 'min' &&
                                  'Longitude should be min 3 digits') ||
                                (errorType === 'matches' &&
                                  'Please enter valid longitude')}
                          </FormHelperText>
                        )}
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
              {selectedOfferType === offerType.digital && (
                <ul className={`${classes.InputSection} digitalType`}>
                  <li>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                      >
                        {`${
                          voucherCode && voucherCode.length >= 3
                            ? 'Voucher Code*'
                            : 'Voucher Code*'
                        }`}
                      </InputLabel>
                      <Input
                        type="text"
                        autoComplete
                        inputProps={{ 'aria-label': 'description' }}
                        value={voucherCode}
                        onChange={e => {
                          setCreateOfferToggle(false);
                          setVoucherCode(e.target.value);
                        }}
                      />
                      <FormHelperText
                        className={classes.errorMsg}
                        error={voucherErrorMsg || ''}
                      >
                        {voucherErrorMsg || ''}
                      </FormHelperText>
                    </FormControl>
                  </li>
                  <li>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                      value={offerUrl}
                    >
                      {`${offerUrl ? 'Offer URL*' : 'Offer URL*'}`}
                    </InputLabel>
                    <Input
                      value={offerUrl}
                      inputProps={{
                        'aria-label': 'offerName',
                        maxLength: '150',
                      }}
                      onChange={e => {
                        setCreateOfferToggle(false);
                        setOfferUrl(e.target.value);
                      }}
                    />
                    {((errorType === 'url' &&
                      errorFieldName === 'offerUrlValidation') ||
                      (errorFieldValue &&
                        errorFieldValue.offerUrlValidation === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          errorType === 'url' && 'Please enter a valid url.'
                        }
                      >
                        {errorType === 'url' && 'Please enter a valid url.'}
                      </FormHelperText>
                    )}
                  </li>
                </ul>
              )}
              {selectedOfferType === offerType.digitalUrlOnly && (
                <ul className={`${classes.InputSection} digitalType`}>
                  <li>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                      value={offerUrl}
                      errorType={errorType}
                      errorFieldName={errorFieldName}
                      errorFieldValue={errorFieldValue}
                    >
                      Digital URL*
                    </InputLabel>
                    <Input
                      value={offerUrl}
                      inputProps={{
                        'aria-label': 'offerName',
                        maxLength: '150',
                      }}
                      onChange={e => {
                        setCreateOfferToggle(false);
                        setOfferUrl(e.target.value);
                      }}
                    />

                    {((errorType === 'url' &&
                      errorFieldName === 'offerUrlValidation') ||
                      (errorFieldValue &&
                        errorFieldValue.offerUrlValidation === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          errorType === 'url' && 'Please enter a valid url.'
                        }
                      >
                        {errorType === 'url' && 'Please enter a valid url.'}
                      </FormHelperText>
                    )}
                  </li>
                </ul>
              )}
              <ul className={`${classes.InputSection} richTextEdit`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Description*
                  </InputLabel>
                  {/* <RichTextEditor
                    descriptionValue={descriptionValue}
                    setDescriptionValue={setDescriptionValue}
                    setCreateOfferToggle={setCreateOfferToggle}
                    mediaStorage={offers_description_folder}
                  /> */}
                  <textarea
                    type="text"
                    value={descriptionValue.replace(/<[^>]+>/g, '')}
                    onChange={e => {
                      setDescriptionValue(e.target.value);
                    }}
                  />
                  {((errorType === 'required' &&
                    errorFieldName === 'descriptionValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.descriptionValidation === '') ||
                    descriptionValue === '<p><br></p>') && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        (descriptionValue === '' ||
                          descriptionValue === '<p><br></p>') &&
                        'Description is required.'
                      }
                    >
                      {(descriptionValue === '' ||
                        descriptionValue === '<p><br></p>') &&
                        'Description is required.'}
                    </FormHelperText>
                  )}
                  {/* {((errorType === 'required' &&
                      errorFieldName === 'descriptionValidation') ||
                      (errorFieldValue &&
                        errorFieldValue.descriptionValidation === '')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          error={
                            descriptionValue === '' && 'Description is required.'
                          }
                        >
                          {descriptionValue === '' && 'Description is required.'}
                        </FormHelperText>
                      )} */}
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                    >
                      T{`${'&'}`}Cs*
                    </InputLabel>
                    <textarea
                      type="text"
                      maxLength="2000"
                      inputProps={{ 'aria-label': 'offerName' }}
                      value={terms}
                      onChange={e => {
                        setTerms(e.target.value);
                      }}
                    />
                    <div className="descriptionBox">{`${terms.length}/2000`}</div>
                    {((errorType === 'min' &&
                      errorFieldName === 'termsValidation') ||
                      (errorFieldValue &&
                        errorFieldValue.termsValidation === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          terms === ''
                            ? terms === '' && 'T&Cs is required.'
                            : errorType === 'min' &&
                              'must be at least 3 characters'
                        }
                      >
                        {terms === ''
                          ? terms === '' && 'T&Cs is required.'
                          : errorType === 'min' &&
                            'must be at least 3 characters'}
                      </FormHelperText>
                    )}
                  </FormControl>
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">COMPANY DETAILS</FormLabel>
                </li>
              </ul>
              <ul className={`${classes.InputSection} textareaSectionHalf`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                    >
                      Company Name*
                    </InputLabel>
                    <Input
                      value={companyName}
                      inputProps={{
                        'aria-label': 'offerName',
                        maxLength: '150',
                      }}
                      onChange={e => {
                        setCompanyName(e.target.value);
                      }}
                    />
                    <div className="descriptionBox offerFormSortDesc">{`${companyName.length}/150`}</div>
                    {((errorType === 'min' &&
                      errorFieldName === 'companyNameValidation') ||
                      (errorFieldValue &&
                        errorFieldValue.companyNameValidation === '')) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          companyName === ''
                            ? companyName === '' && 'Company Name is required.'
                            : errorType === 'min' &&
                              'must be at least 3 characters'
                        }
                      >
                        {companyName === ''
                          ? companyName === '' && 'Company Name is required.'
                          : errorType === 'min' &&
                            'must be at least 3 characters'}
                      </FormHelperText>
                    )}
                  </FormControl>
                </li>
                <li>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                    className={classes.formLabel}
                  >
                    Company URL*
                  </InputLabel>
                  <Input
                    value={companyUrl}
                    onChange={e => {
                      setCompanyUrl(e.target.value);
                    }}
                  />
                  {((errorType === 'required' &&
                    errorFieldName === 'companyUrlValidation') ||
                    (errorType === 'url' &&
                      errorFieldName === 'companyUrlValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.companyUrlValidation === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={
                        companyUrl === ''
                          ? companyUrl === '' && 'Company Url is required.'
                          : errorType === 'url' && 'Please enter a valid url.'
                      }
                    >
                      {companyUrl === ''
                        ? companyUrl === '' && 'Company Url is required.'
                        : errorType === 'url' && 'Please enter a valid url.'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul
                className={`${classes.leftFilterIn} offerlocationFilterDropdown offerVillageFilterDropdown`}
              >
                {(selectedOfferType !== offerType.physical && (
                  <li>
                    <FormLabel
                      className="locationFilterLabel"
                      component="legend"
                    >
                      Location
                    </FormLabel>
                    <OffersLocationFilter
                      selectAll
                      AddOffersToggle
                      buildingPayload={buildingPayload}
                      selectedVillage={selectedVillage}
                      setSelectedVillage={setSelectedVillage}
                      errorType={errorType}
                      errorFieldName={errorFieldName}
                      errorFieldValue={errorFieldValue}
                      buildingArray={buildingArray}
                      locationSearch={locationSearch}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      setLocationSearch={setLocationSearch}
                      handleClose={handleClose}
                      setOnCheckedLocationButton={setOnCheckedLocationButton}
                      OnCheckedLocationButton={OnCheckedLocationButton}
                      id={openId}
                      editId={id}
                      buildingGroupsList={buildingGroupsList}
                      buildings={buildings}
                      setBuildings={setBuildings}
                      villages={villages}
                      setVillages={setVillages}
                    />
                  </li>
                )) || (
                  <li>
                    <FormLabel
                      className="locationFilterLabel"
                      component="legend"
                    >
                      Location
                    </FormLabel>
                    <LocationFilter
                      selectAll
                      AddOffersToggle
                      errorType={errorType}
                      errorFieldName={errorFieldName}
                      errorFieldValue={errorFieldValue}
                      buildingArray={buildingArray}
                      locationSearch={locationSearch}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      setLocationSearch={setLocationSearch}
                      handleClose={handleClose}
                      setOnCheckedLocationButton={setOnCheckedLocationButton}
                      OnCheckedLocationButton={OnCheckedLocationButton}
                      id={openId}
                      editId={id}
                      buildingGroupsList={buildingGroupsList}
                      buildings={buildings}
                      setBuildings={setBuildings}
                      villages={villages}
                      setVillages={setVillages}
                    />
                  </li>
                )}

                {(selectedOfferType !== offerType.physical && (
                  <li>
                    <FormLabel
                      className="locationFilterLabel"
                      component="legend"
                    >
                      Village
                    </FormLabel>
                    <OffersVillageFilter
                      values={{
                        buildingGroupsList,
                        selectedVillage,
                        setSelectedVillage,
                        buildingPayload,
                        errorType,
                        errorFieldName,
                        errorFieldValue,
                      }}
                      offers
                      errorType={errorType}
                      errorFieldName={errorFieldName}
                      errorFieldValue={errorFieldValue}
                    />
                  </li>
                )) || (
                  <li>
                    <FormLabel
                      className="locationFilterLabel"
                      component="legend"
                    >
                      Village
                    </FormLabel>
                    <PhysicalVillageFilter
                      values={{
                        buildingGroupsList,
                        selectedVillage,
                        setSelectedVillage,
                        errorType,
                        errorFieldName,
                        errorFieldValue,
                      }}
                      offers
                      errorType={errorType}
                      errorFieldName={errorFieldName}
                      errorFieldValue={errorFieldValue}
                    />
                  </li>
                )}
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Media</FormLabel>
                </li>
              </ul>
              <ul className="offerImgUoload">
                <li>
                  <FileUpload
                    values={{
                      images,
                      setImages,
                      setCompanyLogo,
                      companyLogo,
                      errorType,
                      errorFieldName,
                      errorFieldValue,
                    }}
                    offer
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        <FormControlLabel
                          control={<Radio />}
                          label="Published"
                          checked={status === 'Published'}
                          onChange={() => {
                            setStatus('Published');
                          }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Unpublished"
                          checked={status === 'Unpublished'}
                          onChange={() => {
                            setStatus('Unpublished');
                          }}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <SaveAndCancelButtons
                setToggle={setCreateOfferToggle}
                route="/offers-list"
              />
              <WarningPopup />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateAndUpdateOffer;
