/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import LocationFilter from '../../components/Filters/LocationFilter';
import CategoryFilter from '../../components/Filters/CategoryFilter';
import StatusFilter from '../../components/Filters/StatusFilter';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import Pagination from '../../components/Pagination/Pagination';
import { NewsAnnouncementsContext } from '../../context/NewsAnnouncementsContext';
import { GlobalContext } from '../../context/GlobalContext';
import useFilterStyles from '../../components/Styles/useFilterStyles';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';
import minus from '../../images/minus.svg';

const NewsFilters = () => {
  const classes = useFilterStyles();
  const {
    page,
    setPage,
    setOffset,
    totalArticlesCount,
    articleSearch,
    platformOptions,
    selectedPlatform,
    setPlatformOptions,
    articleOptions,
    selectedArticle,
    setArticleOptions,
    setArticleSearch,
    categories,
    setCatagoryActive,
    selectedCategory,
    setSelectedCategory,
    setBuildings,
    setVillages,
    buildings,
    villages,
    setSelectedStartDate,
    setSelectedEndDate,
    setStatuses,
    statuses,
    selectedStatus,
  } = useContext(NewsAnnouncementsContext);
  const { buildingGroupsList } = useContext(GlobalContext);
  const [locationSearch, setLocationSearch] = useState(false);
  const [platformSearch, setPlatformSearch] = useState(false);
  const [newArticleSearch, setNewArticleSearch] = useState(false);
  const [categorySearch, setCategorySearch] = useState(false);
  const [statusSearch, setStatusSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [categoryTab, setCategoryTab] = useState('');
  const [OnCheckedPlatformButton, setOnCheckedPlatformButton] = useState('');
  const [OnCheckedArticleButton, setOnCheckedArticleButton] = useState('');
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const [onCheckStatusButton, setOnCheckStatusButton] = useState('');
  const placeholder = 'Search by title';

  const handleClose = () => {
    setAnchorEl(null);
    setLocationSearch(false);
    setPlatformSearch(null);
    setNewArticleSearch(null);
    setOnCheckedLocationButton(false);
    setOnCheckedPlatformButton(false);
    setOnCheckedArticleButton(false);
    setOnCheckStatusButton(false);
    setStatusSearch(null);
    setCategorySearch(null);
    setCategoryTab(false);
  };

  const ClearFilter = () => {
    setPlatformOptions([
      { label: 'Customer App', key: 'CustomerApp', selected: false },
      { label: 'Tenant Portal', key: 'TenantPortal', selected: false },
      { label: 'Both', key: 'All', selected: false },
    ]);
    setArticleOptions([
      { label: 'Announcements', key: 'Announcement', selected: false },
      { label: 'Standard News', key: 'standard', selected: false },
      { label: 'Video News', key: 'video', selected: false },
      { label: 'Feature News', key: 'featured', selected: false },
    ]);
    setStatuses([
      { label: 'Published', key: 'Published', selected: false },
      { label: 'Unpublished', key: 'Unpublished', selected: false },
      { label: 'Expired', key: 'Expired', selected: false },
    ]);
    setAnchorEl('');
    setBuildings([]);
    setVillages([]);
    setCatagoryActive(false);
    setCategoryTab(false);
    setSelectedCategory([]);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  const clearPlatformType = () => {
    setPlatformOptions([
      { label: 'Customer App', key: 'CustomerApp', selected: false },
      { label: 'Tenant Portal', key: 'TenantPortal', selected: false },
      { label: 'Both', key: 'All', selected: false },
    ]);
  };

  const clearArticleType = () => {
    setArticleOptions([
      { label: 'Announcements', key: 'Announcement', selected: false },
      { label: 'Standard News', key: 'standard', selected: false },
      { label: 'Video News', key: 'video', selected: false },
      { label: 'Feature News', key: 'featured', selected: false },
    ]);
  };

  const newPlatformOptions = (selected, key) => {
    const platforms = platformOptions.map(platform => {
      if (platform && platform.key === key) {
        platform.selected = selected;
      }
      return platform;
    });
    setPlatformOptions(platforms);
  };

  const newArticleOptions = (selected, key) => {
    const articles = articleOptions.map(article => {
      if (article && article.key === key) {
        article.selected = selected;
      }
      return article;
    });
    setArticleOptions(articles);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.filerSection}>
      <div className="pageContainer filterContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftFilterIn`}>
              <LocationFilter
                locationSearch={locationSearch}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setLocationSearch={setLocationSearch}
                handleClose={handleClose}
                setOnCheckedLocationButton={setOnCheckedLocationButton}
                OnCheckedLocationButton={OnCheckedLocationButton}
                id={id}
                buildingGroupsList={buildingGroupsList}
                buildings={buildings}
                villages={villages}
                setVillages={setVillages}
                setBuildings={setBuildings}
              />
              <CategoryFilter
                values={{
                  id,
                  handleClose,
                  categories,
                  anchorEl,
                  setAnchorEl,
                  categoryTab,
                  setCategoryTab,
                  setCatagoryActive,
                  categorySearch,
                  setCategorySearch,
                  selectedCategory,
                  setSelectedCategory,
                }}
                article
              />
              <StatusFilter
                values={{
                  id,
                  anchorEl,
                  setAnchorEl,
                  handleClose,
                  selectedStatus,
                  statuses,
                  setStatuses,
                  statusSearch,
                  setStatusSearch,
                  onCheckStatusButton,
                  setOnCheckStatusButton,
                }}
                activeStatus="event"
              />
              <li
                className={
                  OnCheckedPlatformButton === true ||
                  (selectedPlatform && selectedPlatform.length > 0)
                    ? `${classes.active} ${
                        selectedPlatform && selectedPlatform.length > 0
                          ? classes.valueSelected
                          : ''
                      }`
                    : ''
                }
              >
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={event => {
                    setPlatformSearch(true);
                    setAnchorEl(event.currentTarget);
                    setOnCheckedPlatformButton(true);
                  }}
                >
                  Platform
                  <small>
                    <img src={plus} alt="plus" />
                    <img src={minus} alt="minus" />
                  </small>
                </Button>
                <i
                  className="closeIconBtn"
                  role="presentation"
                  onClick={clearPlatformType}
                >
                  <img src={close} alt="close" />
                </i>
                <Popover
                  id="platform"
                  open={platformSearch}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div className="filterDropdown">
                    <div className="filterDropdownin">
                      <List className={classes.moreList}>
                        <h4>Select A Platform(s)</h4>
                        {platformOptions &&
                          platformOptions.map((platform, index) => (
                            <>
                              <ListItem key={platform.key}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={platform.label}
                                      checked={platform.selected}
                                      onChange={e => {
                                        newPlatformOptions(
                                          e.target.checked,
                                          platform.key,
                                        );
                                      }}
                                    />
                                  }
                                  value={platform.key}
                                  label={platform.label}
                                />
                              </ListItem>
                              {platformOptions &&
                                platformOptions.length - 1 > index && (
                                  <Divider light />
                                )}
                            </>
                          ))}
                      </List>
                    </div>
                  </div>
                </Popover>
              </li>
              <li
                className={
                  OnCheckedArticleButton === true ||
                  (selectedArticle && selectedArticle.length > 0)
                    ? `${classes.active} ${
                        selectedArticle && selectedArticle.length > 0
                          ? classes.valueSelected
                          : ''
                      }`
                    : ''
                }
              >
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={event => {
                    setNewArticleSearch(true);
                    setAnchorEl(event.currentTarget);
                    setOnCheckedArticleButton(true);
                  }}
                >
                  Type
                  <small>
                    <img src={plus} alt="plus" />
                    <img src={minus} alt="minus" />
                  </small>
                </Button>
                <i
                  className="closeIconBtn"
                  role="presentation"
                  onClick={clearArticleType}
                >
                  <img src={close} alt="close" />
                </i>
                <Popover
                  id="platform"
                  open={newArticleSearch}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div className="filterDropdown">
                    <div className="filterDropdownin">
                      <List className={classes.moreList}>
                        <h4>Select A Platform(s)</h4>
                        {articleOptions &&
                          articleOptions.map((article, index) => (
                            <>
                              <ListItem key={article.key}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={article.label}
                                      checked={article.selected}
                                      onChange={e => {
                                        newArticleOptions(
                                          e.target.checked,
                                          article.key,
                                        );
                                      }}
                                    />
                                  }
                                  value={article.key}
                                  label={article.label}
                                />
                              </ListItem>
                              {articleOptions && article.length - 1 > index && (
                                <Divider light />
                              )}
                            </>
                          ))}
                      </List>
                    </div>
                  </div>
                </Popover>
              </li>
              <li role="presentation" onClick={ClearFilter}>
                Clear Filters
              </li>
            </ul>
          </div>
          <div className={`${classes.rightFilter} rightFilterPagination`}>
            <Pagination
              page={page}
              setPage={setPage}
              setOffset={setOffset}
              totalTenants={totalArticlesCount}
            />
            <div className={`${classes.exportSerch} exportSerch`}>
              <ul>
                <SearchFilter
                  customerSearch={articleSearch}
                  setCustomerSearch={setArticleSearch}
                  placeholder={placeholder}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsFilters;
