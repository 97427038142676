/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  Input,
} from '@material-ui/core';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { GlobalContext } from '../../context/GlobalContext';
import { MrExtendBookingContext } from '../../context/MRExtendBookingContext';
import Ping from '../../components/Ping/Ping';
import { logOut } from '../../utils/logOut';
import popup_close from '../../images/popup_close.svg';
import {
  bookingStatus,
  bookingTimeType,
  dummyTerminalId,
  messages,
  paymentStatus,
  timeZone,
  verificationStatus,
} from '../../utils/constants';


function Status({
  mrPaymentPos,
  mrBookingId,
  setMrPaymentPosMessage,
  setMrPaymentPos,
  setSuccessMessage,
  history,
}) {
  Ping(() => {
    if (mrPaymentPos === true) {
      ApiClient.apiGet(`${apiEndPoints.createMrBooking}/${mrBookingId}`)
        .then(data => {
          setMrPaymentPosMessage(data.data.data.extendBooking.paymentStatus);
          if (data.data.data.extendBooking.paymentStatus === 'Paid') {
            setMrPaymentPos(false);
            setSuccessMessage('Successfully created booking');
            history.push('/');
          }
        })
        .catch(err => {
          console.log(err);
          setMrPaymentPos(false);
          setMrPaymentPosMessage('Unable to complete payment.');
        });
    }
  }, 5000);
  return null;
}
const useStyles = makeStyles({
  table: {
    maxWidth: '100%',
  },
  BookingStepForm: {
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  extendPopupFormButton: {
    '& button': {
      background: '#000',
      color: '#fff !important',
      border: '2px solid #000',
      padding: '0 32px',
      marginTop: 20,
      textTransform: 'capitalize !important',
      '&:hover': {
        background: '#000',
        color: '#fff !important',
      },
    },
  },
  popupHeading: {
    color: '#444444',
    fontSize: '19px',
    fontFamily: 'Varta, sans-serif',
    fontWeight: 'bold',
    marginBottom: '15px',
    lineHeight: '20px',
  },
  popupText: {
    color: '#838384',
    display: 'block',
    fontSize: '16px',
    fontFamily: 'Varta,sans-serif',
    fontWeight: 'normal',
    marginTop: '-6px',
  },
});

const ExtendPreview = () => {
  const classes = useStyles();
  const {
    tenant,
    setMrCapacity,
    mrPayment,
    setMrPayment,
    setMinimumBookingTime,
    setMrRoomName,
    mrPaymentPos,
    setMrPaymentPos,
    setMrPaymentPosMessage,
    discountPrice,
    setDiscountPrice,
    vat,
    setVat,
    fullDay,
    selectedMenuItems,
    terminalId,
    setCircularLoading,
    setLoaderNotification,
  } = useContext(MrExtendBookingContext);
  const {
    setSuccessMessage,
    extendData,
    customerDetails,
    extendTime,
    eventType,
    selectedName,
    newMeetingRoomsList,
    setExtendRoomTotal,
    setExtendSubtotal,
    setExtendVat,
    setExtendDiscount,
    setExtendRoomPrice,
    extendMeetingRoomList,
    setExtendMeetingRoomList,
    paymentServiceProvider,
  } = useContext(GlobalContext);
  const history = useHistory();
  const [discountToggle, setDiscountToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullDayPrice, setFullDayPrice] = useState('');
  const [hourlyPrice, setHourlyPrice] = useState(
    extendData && extendData.meetingRoom && extendData.meetingRoom.hourlyPrice,
  );
  const [endNewDate] = useState(
    moment.tz(extendData.endDate, timeZone.london).format('hh:mm a'),
  );
  const [startNewDate] = useState(
    moment.tz(extendData.startDate,  timeZone.london).format('hh:mm a'),
  );
  const [extendDiscountPrice, setExtendDiscountPrice] = useState(
    extendData && extendData.extendBooking && extendData.extendBooking.discount,
  );
  const [endNewDate1] = useState(
    eventType===bookingTimeType.futureBooking?
    moment
      .tz(
        extendData &&
        extendData.extendBooking &&
        extendData.extendBooking.startDate,
         timeZone.london,
      )
      .format('hh:mm a'):  moment
      .tz(
        extendData &&
        extendData.extendBooking &&
        extendData.extendBooking.endDate,
         timeZone.london,
      )
      .format('hh:mm a'),
  );
  const [endTimeBuffer] = useState(
    moment
      .tz(extendData.endDate,  timeZone.london)
      .add(extendTime, 'minutes')
      .format('hh:mm a'),
  );
  const [signatureAlert, setSignatureAlert] = useState(false);
  const [timeoutPopup, setTimeoutPopup] = useState(false);
  const [posRequestId, setRequestId] = useState('');

  useEffect(() => {
    ApiClient.apiGet(
      `${apiEndPoints.getVat}${moment(extendData.endDate).format(
        'YYYY-MM-DD',
      )}`,
    )
      .then(data => {
        setVat(data && data.data && data.data.data);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  }, []);

  const posPaymentStatus = requestId => {
    setCircularLoading(true);
    const intervalId = setInterval(() => {
      ApiClient.apiPost(apiEndPoints.getPosPaymentStatus, {
        terminalId,
        requestId,
      })
        .then(data => {
          const notifications = data?.data?.data?.notifications[0];
          setRequestId(requestId);
          setLoaderNotification(data?.data?.data?.notifications[0]);
          const transactionResult = data?.data?.data?.transactionResult;
          if (
            transactionResult &&
            transactionResult.toLowerCase() === verificationStatus.successful
          ) {
            clearInterval(intervalId);
            handleSuccessfulTransaction(requestId);
          } else if (
            transactionResult &&
            transactionResult.toLowerCase() === verificationStatus.timed_out
          ) {
            clearInterval(intervalId);
            handleTimeOutTransaction(transactionResult);
          } else if (
            transactionResult
          ) {
            clearInterval(intervalId);
            setMrPaymentPosMessage(transactionResult);
            setMrPaymentPos(false);
            setCircularLoading(false);
            statusCallExtend();
            alert(transactionResult);
          } else if (
            notifications === verificationStatus.signatureVerification ||
            notifications === verificationStatus.signatureProgress
          ) {
            clearInterval(intervalId);
            setCircularLoading(false);
            setSignatureAlert(true);
          }
        })
        .catch(err => {
          clearInterval(intervalId);
          handleError(err);
        });
    }, 2000);

    const handleTimeOutTransaction = () => {
      setTimeoutPopup(true);
      setCircularLoading(false);
    };

    const handleError = err => {
      setMrPaymentPosMessage(
        err?.response?.data?.message || paymentStatus.failed,
      );
      setMrPaymentPos(false);
      setCircularLoading(false);
      alert(err?.response?.data?.message);
    };
  };

  const handleSuccessfulTransaction = requestId => {
    setCircularLoading(true)
    ApiClient.apiPost(
      `${apiEndPoints.createMrBooking}/${extendData && extendData.id
      }/completeExtension`,
      {
        discount: Number(extendDiscountPrice || discountPrice) || 0,
        terminalId: terminalId,
        method: 'Pos',
        requestId,
      },
    )
      .then(data => {
        fullyDiscountedBooking(data);
      })
      .catch(err => {
        setMrPaymentPosMessage('Payment status failed');
        setMrPaymentPos(false);
        setCircularLoading(false);
        alert(
          err && err.response && err.response.data && err.response.data.message,
        );

      });
  };

  const fullyDiscountedBooking = data => {
    alert('Payment Successful');
    setSuccessMessage(data?.data?.message);
    setCircularLoading(false);
    history.push('/');
  }

  const statusCallExtend = () => {
    setCircularLoading(true)
    const payload = {
      status: bookingStatus.cancel,
    };
    ApiClient.apiPut(`${apiEndPoints.cancelExtend}/${extendData.id}`, payload)
      .then(() => {
        setCircularLoading(false);
      })
      .catch(error => {
        const err = error;
        setCircularLoading(false)
        alert(err.response.data.message);
      });
  };
  const timeOutBackCall = () => {
    setMrPaymentPos(false);
    setTimeoutPopup(false);
    statusCallExtend();
  };

  const handleTerminalDialogClose = () => {
    setTimeoutPopup(false);
    setMrPaymentPos(false);
    timeOutBackCall();
  };

  const verifySignatureCall = accepted => {
    setSignatureAlert(false);
    setCircularLoading(true);
    ApiClient.apiPut(`${apiEndPoints.verifyPosSignature}`, {
      accepted,
      terminalId: terminalId,
      requestId: posRequestId,
    })
      .then(() => {
        posPaymentStatus(posRequestId);
      })
      .catch(() => {
        posPaymentStatus(posRequestId);
      });
  };
  useEffect(() => {
    if (mrPayment) {
      setLoading(true);
      const payload = {
        method: 'PayByAccount',
        discount:
          (extendDiscountPrice && Number(extendDiscountPrice)) ||
          Number(discountPrice),
      };
      if (extendData.extendBooking) {
        ApiClient.apiPost(
          `${apiEndPoints.createMrBooking}/${extendData.id}/completeExtension`,
          payload,
        )
          .then(data => {
            setSuccessMessage(data && data.data.message);
            // if (buidlingLocalStorage) {
            // ? history.push(`./calender-bookings/${buidlingLocalStorage.toString()}`)
            history.push('/bookinglist');
            // }
            localStorage.removeItem('payToAccount');
            setLoading(false);
          })
          .catch(err => {
            setMrPayment(false);
            setLoading(false);
            alert(
              err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
            );
          });
      } else {
        const startTimeInLtFormat1 = moment(
          (extendData &&
            extendData.extendBooking &&
            extendData.extendBooking.startDate) ||
          extendData.endDate,
        );
        const endTImeInLtFormat1 = moment(extendData && extendData.endDate).add(
          extendTime,
          'minutes',
        );
        const duratione = moment(endTImeInLtFormat1.toISOString()).diff(
          startTimeInLtFormat1.toISOString(),
          'minutes',
        );
        ApiClient.apiPost(`${apiEndPoints.bookingExtend}/${extendData.id}`, {
          extend: parseInt(duratione, 10),
          eventType: eventType,
        })
          .then(() => {
            ApiClient.apiPost(
              `${apiEndPoints.createMrBooking}/${extendData.id}/completeExtension`,
              payload,
            )
              .then(data => {
                setSuccessMessage(data && data.data.message);
                history.push('/bookinglist');
                localStorage.removeItem('payToAccount');
                setLoading(false);
              })
              .catch(err => {
                setMrPayment(false);
                setLoading(false);
                alert(
                  err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.message,
                );
              });
          })
          .catch(error => {
            console.log(error);
            alert(error?.response?.data?.message || messages.notExtended);
            history.push('/');
          });
      }
    }
  }, [mrPayment]);
  const startTimeInLtFormat1 = moment(
    (extendData &&
      extendData.extendBooking &&
      extendData.extendBooking.startDate) ||
    extendData.endDate,
  );
  const endTImeInLtFormat1 = moment(extendData && extendData.endDate).add(
    extendTime,
    'minutes',
  );
  const duratione = moment(endTImeInLtFormat1.toISOString()).diff(
    startTimeInLtFormat1.toISOString(),
    'minutes',
  );

  useEffect(() => {
    if (!mrPaymentPos) {
      return;
    }
    let payload;
    if (!paymentServiceProvider) {
      payload = {
        discount:
          (extendDiscountPrice && Number(extendDiscountPrice)) ||
          Number(discountPrice) ||
          0,
        method: 'Pos',
      };
    } else {
      payload = {
        discount:
          (extendDiscountPrice && Number(extendDiscountPrice)) ||
          Number(discountPrice) ||
          0,
        method: 'Pos',
        terminalId: terminalId || dummyTerminalId,
      };
    }
    if (extendData.extendBooking) {
      setLoaderNotification('PROCESSING');
      setCircularLoading(true);
      ApiClient.apiPost(
        `${apiEndPoints.createMrBooking}/${extendData && extendData.id
        }/completeExtension`,
        payload,
      )
        .then(data => {
          if (data.status === 200)
            fullyDiscountedBooking(data)
          else if (data.status === 202)
            posPaymentStatus(data?.data?.data?.requestId);
        })
        .catch(err => {
          setMrPaymentPosMessage('Payment status failed');
          setMrPaymentPos(false);
          setCircularLoading(false);
          alert(
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message,
          );
        });
    } else {
      setLoaderNotification('PROCESSING');
      setCircularLoading(true);
      ApiClient.apiPost(`${apiEndPoints.bookingExtend}/${extendData.id}`, {
        extend: parseInt(duratione, 10),
        eventType: eventType,
      })
        .then(() => {
          ApiClient.apiPost(
            `${apiEndPoints.createMrBooking}/${extendData && extendData.id
            }/completeExtension`,
            payload,
          )
            .then(data => {
              if (data.status === 200)
                fullyDiscountedBooking(data)
              else if (data.status === 202)
                posPaymentStatus(data?.data?.data?.requestId);
            })
            .catch(err => {
              setMrPaymentPosMessage('Payment status failed');
              setMrPaymentPos(false);
              setCircularLoading(false);
              alert(
                err &&
                err.response &&
                err.response.data &&
                err.response.data.message,
              );
              history.push('/');
            });
        })
        .catch(error => {
          setMrPaymentPos(false);
          setCircularLoading(false);
          console.log(error);
          alert(error?.response?.data?.message || messages.notExtended);
          history.push('/');
        });
    }
  }, [mrPaymentPos]);

  useEffect(() => {
    const roomId =
      newMeetingRoomsList &&
      newMeetingRoomsList.filter(data => data.id === selectedName);
    // setFullDayPrice(roomId && roomId[0] && roomId[0].fullDayPrice);
    // setMrCapacity(roomId && roomId[0] && roomId[0].maxCapacity);
    // setHourlyPrice(roomId && roomId[0] && roomId[0].hourlyPrice);
    // setMinimumBookingTime(roomId && roomId[0] && roomId[0].minimumBookingTime);
    setMrRoomName(roomId && roomId[0] && roomId[0].name);
  }, [selectedName]);

  useEffect(() => {
    const meetingRoomId =
      extendData && extendData.meetingRoom && extendData.meetingRoom.id;
    ApiClient.apiGet(apiEndPoints.getMeetingRoomId + meetingRoomId)
      .then(data => {
        setExtendMeetingRoomList(() => data && data.data && data.data.data);
      })
      .catch(error => {
        alert(error);
      });
  }, []);

  useEffect(() => {
    setHourlyPrice(extendMeetingRoomList.hourlyPrice);
    setFullDayPrice(extendMeetingRoomList.fullDayPrice);
    setMrCapacity(extendMeetingRoomList.maxCapacity);
    setMinimumBookingTime(extendMeetingRoomList.minimumBookingTime);
  }, [extendMeetingRoomList]);

  function createRow(desc, qty, price, totalPrice) {
    return { desc, qty, price, totalPrice };
  }

  const endDateTime = new Date(
    `${moment(extendData.endDate).format('ddd MMM DD YYYY')} ${endNewDate}`,
  );
  const startDateTime = new Date(
    `${moment(extendData.startDate).format('ddd MMM DD YYYY')} ${startNewDate}`,
  );
  const endDate = new Date(
    `${moment(
      extendData &&
      extendData.extendBooking &&
      extendData.extendBooking.startDate,
    ).format('ddd MMM DD YYYY')} ${endNewDate1}`,
  );
  const mrVatRate = vat && vat[0] && vat[0].vatRate;
  const startTimeInLtFormat = moment(
    (extendData &&
      extendData.extendBooking &&
      extendData.extendBooking.startDate) ||
    extendData.endDate,
  );
  const endTImeInLtFormat = extendData.extendBooking
    ? moment(extendData && extendData.extendBooking.endDate).add(0, 'minutes')
    : moment(extendData && extendData.endDate).add(extendTime, 'minutes');
  const duration = moment(endTImeInLtFormat.toISOString()).diff(
    startTimeInLtFormat.toISOString(),
    'minutes',
  );
  const hours = duration / 60;
  const calcPrice = hourlyPrice * hours;
  const mrBookingPrice =
    fullDay === true
      ? `${fullDayPrice}`
      : `${endDateTime && endTimeBuffer
        ? `${calcPrice > fullDayPrice ? fullDayPrice : calcPrice}`
        : ''
      }`;
  const mrSubTotal1 =
    Number(mrBookingPrice) -
    (((extendDiscountPrice && Number(extendDiscountPrice)) ||
      Number(discountPrice)) > Number(mrBookingPrice)
      ? 0
      : (extendDiscountPrice && Number(extendDiscountPrice)) ||
      Number(discountPrice));
  const mrVat1 = (mrVatRate / 100) * mrSubTotal1;
  const roomTotal1 = mrSubTotal1 + mrVat1;
  setExtendRoomTotal(roomTotal1);
  setExtendSubtotal(mrSubTotal1);
  setExtendDiscount(discountPrice);
  setExtendVat(mrVat1);

  const meetingRoomDetails = [
    createRow(
      `${extendData && extendData.meetingRoom && extendData.meetingRoom.name
      } - ${extendData && extendData.roomLayout && extendData.roomLayout.name
      } - ${extendData && extendData.building && extendData.building.name} - 

      ${moment(extendData.endDate).format('DD MMM YYYY')} - 
      ${eventType === bookingTimeType.futureBooking
        ? ` ${moment(
          (extendData.extendBooking && endDate) || endDateTime,
        ).format('h:mm a')} - ${moment(endDateTime)
          .add(extendData.extendBooking ? '' : extendTime, 'minutes')
          .format('h:mm a')}`
        : `${moment(startDateTime)
          .subtract(extendData.extendBooking ? '' : extendTime, 'minutes')
          .format('h:mm a')} - ${moment(
            (extendData.extendBooking && endDate) || startDateTime,
          ).format('h:mm a')}`
      }
     `,

      '--',
      '--',
      Number(mrBookingPrice),
    ),
  ];
  setExtendRoomPrice(mrBookingPrice);

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={`${classes.BookingStepForm}`}>
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <>
          <div className="orderMainTable">
            <Status
              mrPaymentPos={mrPaymentPos}
              mrBookingId={extendData.id}
              setMrPaymentPosMessage={setMrPaymentPosMessage}
              setMrPaymentPos={setMrPaymentPos}
              setSuccessMessage={setSuccessMessage}
              history={history}
            />
            <TableContainer component={Paper} className="orderTable">
              <Table className={classes.table} aria-label="spanning table">
                <TableHead>
                  <TableRow>
                    <TableCell>Meeting Room (Extended)</TableCell>
                    <TableCell align="left">Qty</TableCell>
                    <TableCell align="right">Price </TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="orderPCont">
                  {meetingRoomDetails.map(meetingRoomDetail => (
                    <TableRow key={meetingRoomDetail.desc}>
                      <TableCell>{meetingRoomDetail.desc}</TableCell>
                      <TableCell align="left">
                        {meetingRoomDetail.qty}
                      </TableCell>
                      <TableCell align="right">
                        {meetingRoomDetail.price === '--'
                          ? '--'
                          : `£${meetingRoomDetail.price.toFixed(2)}`}
                      </TableCell>
                      <TableCell align="right">
                        £{calcPrice.toFixed(2)}
                      </TableCell>
                    </TableRow>
                  ))}
                  {selectedMenuItems &&
                    selectedMenuItems.map(selectedMenuItem => (
                      <>
                        {selectedMenuItem && selectedMenuItem.capacity > 0 && (
                          <TableRow key={selectedMenuItem.categoryId}>
                            <TableCell>{`${selectedMenuItem.categoryName} - ${selectedMenuItem &&
                              selectedMenuItem.menu &&
                              selectedMenuItem.menu
                                .map(menuItem => menuItem.itemName)
                                .toString()
                                .split(',')
                                .join(', ')
                              }`}</TableCell>
                            <TableCell align="left">
                              {selectedMenuItem.capacity}
                            </TableCell>
                            <TableCell align="right">
                              {(0).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              £{(0).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                </TableBody>

                <TableBody className="orderPBreakdown">
                  <TableRow className="orderDiscount">
                    <TableCell align="left">&nbsp;</TableCell>
                    <TableCell
                      align="left"
                      onClick={() => setDiscountToggle(true)}
                    >
                      <span>Discount</span>
                    </TableCell>
                    <TableCell colSpan={2} align="right">
                      <div className="discountBox">
                        {discountToggle ? (
                          <>
                            <small>£</small>
                            <Input
                              type="text"
                              autoFocus
                              value={`${Number(discountPrice) > Number(mrBookingPrice)
                                ? 0
                                : `${discountPrice !== 0
                                  ? discountPrice.replace(/^0+/, '')
                                  : ''
                                }`
                                }`}
                              onChange={e => {
                                const re = /^[0-9]*\.?[0-9]*$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  setDiscountPrice(e.target.value);
                                  setExtendDiscountPrice(e.target.value)
                                }
                              }}
                            />


                          </>
                        ) : (
                          `£${Number(
                            (extendDiscountPrice && extendDiscountPrice) ||
                            discountPrice,
                          ).toFixed(2)}`
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">&nbsp;</TableCell>
                    <TableCell align="left">Subtotal</TableCell>
                    <TableCell colSpan={2} align="right">
                      £{mrSubTotal1 && mrSubTotal1.toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">&nbsp;</TableCell>
                    <TableCell align="left">VAT @ {mrVatRate}%</TableCell>
                    <TableCell colSpan={2} align="right">
                      £{mrVat1 && mrVat1.toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left">&nbsp;</TableCell>
                    <TableCell align="left">Room Total</TableCell>
                    <TableCell colSpan={2} align="right">
                      £{Number(roomTotal1).toFixed(2)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="customerDtls">
            <h2>Customer Details</h2>
            <p>{`${customerDetails.firstName} ${customerDetails.lastName} ${tenant ||
              (customerDetails.firstName &&
                customerDetails.lastName &&
                customerDetails.type === 'Guest')
              ? '-'
              : ''
              } ${customerDetails.firstName &&
                customerDetails.lastName &&
                customerDetails.type === 'Guest'
                ? 'Guest'
                : tenant
              } - ${customerDetails.email} ${customerDetails.phoneNumber
                ? ` - ${customerDetails.phoneNumber}`
                : ''
              }`}</p>
          </div>
          <div className="totalDtls">
            <h3> Total</h3>
            <h5>£{Number(roomTotal1).toFixed(2)}</h5>
          </div>

          <Dialog
            open={timeoutPopup}
            onClose={handleTerminalDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="cancelDialogOpend savedCardPopupOpend"
          >
            <Button
              className="popupCloseBtn"
              onClick={handleTerminalDialogClose}
            >
              <img src={popup_close} alt="popup_close.svg" />
            </Button>
            <h4 className={`${classes.popupHeading}`}>Transaction Status</h4>
            <div className={`${classes.popupText}`}>
              <p>
                Communication with the PDQ has failed and payment may or may not
                have been taken. Please check the receipt and record
                accordingly.
              </p>
            </div>
            <DialogActions className={`${classes.extendPopupFormButton}`}>
              <Button
                onClick={() => {
                  timeOutBackCall();
                }}
                autoFocus
              >
                Back
              </Button>
              <Button
                onClick={() => handleSuccessfulTransaction(posRequestId)}
                autoFocus
              >
                Complete Booking
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={signatureAlert}
            onClose={() => verifySignatureCall(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="cancelDialogOpend savedCardPopupOpend"
          >
            <Button
              className="popupCloseBtn"
              onClick={() => verifySignatureCall(false)}
            >
              <img src={popup_close} alt="popup_close.svg" />
            </Button>
            <h4 className={`${classes.popupHeading}`}>Transaction Status</h4>
            <div className={`${classes.popupText}`}>
              <p>Verify Signature</p>
            </div>
            <DialogActions className={`${classes.extendPopupFormButton}`}>
              <Button
                onClick={() => {
                  verifySignatureCall(false);
                }}
                autoFocus
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  verifySignatureCall(true);
                }}
                autoFocus
              >
                Signature Verified
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default ExtendPreview;
