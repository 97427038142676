/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import { CircularProgress, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import dowmArw from '../../images/down-arw.svg';
import newIcon from '../../images/new.svg';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import { SpacesContext } from '../../context/SpacesContext';
import SpacesFilters from './SpacesFilters';
import { GlobalContext } from '../../context/GlobalContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import AddButton from '../../components/Buttons/AddButton';
import SnackBar from '../../components/SnackBar/SnackBar';
import EditButton from '../../components/Buttons/EditButton';

const columns = [
  {
    id: 'location',
    SortOrder: 'desc',
    SortBy: 'building.name',
    label: 'LOCATION',
  },
  {
    id: 'unit',
    SortOrder: 'desc',
    SortBy: 'unitSort',
    label: 'UNIT',
  },
  {
    id: 'availableFrom',
    SortOrder: 'desc',
    SortBy: 'availabilityDate',
    label: 'AVAILABLE FROM',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'areaSize',
    SortOrder: 'desc',
    SortBy: 'sizeAreaSqFt',
    label: 'AREA SIZE (SQ FT) ',
    align: 'right',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'areaSize',
    SortOrder: 'desc',
    SortBy: 'desk',
    label: 'Number of desks',
    align: 'right',
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'STATUS',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'edit',
    SortOrder: '',
    SortBy: '',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 28px 10px 15px',
    color: '#444444',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:nth-child(4)': {
      padding: '10px 60px 10px 15px',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  accountBoxImg: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      margin: '0 10px',
    },
  },
  tableBody: {
    verticalAlign: 'top',
    '& th': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
      '&:nth-child(4)': {
        padding: '10px 60px 10px 15px',
      },
    },
    '& td': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
      '&:nth-child(4)': {
        padding: '10px 60px 10px 15px',
      },
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  pageListMain: {
    position: 'relative',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '10px',
    padding: '5px 0',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#838384',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '30px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
  platformIcons: {
    display: 'flex',
    width: 70,
    margin: 'auto',
  },
});

const SpaceList = () => {
  const classes = useStyles();
  const {
    spacesList,
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    loading,
  } = useContext(SpacesContext);
  const { rolePermission } = useContext(GlobalContext);
  const history = useHistory();
  const [active, setActive] = useState(false);

  const getUserCreatedDate = createDate => {
    const date1 = new Date().getTime();
    const date2 = new Date(createDate).getTime();
    const msDiff = date1 - date2;
    const minutesDifference = Math.floor(msDiff / 60000);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    return daysDifference;
  };

  const handleClick = (columnId, sortName) => {
    setSortOrder(sortOrder === columnId ? 'asc' : 'desc');
    setSortBy(sortBy === sortName ? sortBy : sortName);
  };

  const ViewSpace = rowId => {
    history.push(`/viewspace/${rowId}`);
  };

  const breadCrumbs = {
    listBreadCrumbPath: '/spacelist',
    listBreadCrumbTitle: 'Spaces',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }
  return (
    <div className={classes.pageListMain}>
      <SnackBar />
      {rolePermission && rolePermission.createSpace && (
        <AddButton route="/createspace" />
      )}
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      <SpacesFilters />
      <Divider light />
      <div className="pageContainer tableContainer offerListTable">
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      className={`${classes.tableHeaderCell} noSort ${
                        active && column.SortBy === sortBy ? 'active' : ''
                      }`}
                      align={column.align}
                      id={column.label}
                      key={column.id}
                      disabled={column.SortBy === ''}
                      onClick={() => {
                        if (column.SortBy !== '' || column.SortBy === sortBy) {
                          handleClick(column.SortOrder, column.SortBy);
                          setActive(!active);
                        }
                      }}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TableBody>
                  {spacesList && spacesList.length > 0 ? (
                    spacesList.map(spaceList => (
                      <>
                        <TableRow
                          className={classes.tableBody}
                          key={spaceList.id}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            valign="middle"
                            style={{
                              maxWidth: 250,
                              width: 250,
                              verticalAlign: 'middle',
                            }}
                            onClick={() => {
                              ViewSpace(spaceList.id);
                            }}
                          >
                            <div className="rowEllips">
                              {spaceList.building.name}
                              {getUserCreatedDate(spaceList.createdDate) <
                                30 && (
                                <span className={classes.customerNameNew}>
                                  <img src={newIcon} alt="buttonsAdd" />
                                </span>
                              )}
                              <div className={classes.customerVillg}>
                                {spaceList.building.village}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell
                            style={{ maxWidth: 180, width: 150 }}
                            onClick={() => {
                              ViewSpace(spaceList.id);
                            }}
                          >
                            <div className="rowEllips">{spaceList.unit}</div>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ maxWidth: 180, width: 180 }}
                            onClick={() => {
                              ViewSpace(spaceList.id);
                            }}
                          >
                            <div className="rowEllips">
                              {moment(spaceList.availabilityDate).format(
                                'DD MMM YYYY',
                              )}
                            </div>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ maxWidth: 180, width: 180 }}
                            onClick={() => {
                              ViewSpace(spaceList.id);
                            }}
                          >
                            <div className="rowEllips">
                              {spaceList.sizeAreaSqFt}
                            </div>
                          </TableCell>
                          <TableCell
                            align="right"
                            style={{ maxWidth: 180, width: 150 }}
                            onClick={() => {
                              ViewSpace(spaceList.id);
                            }}
                          >
                            <div className="rowEllips">{spaceList.desk}</div>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{ maxWidth: 120, width: 120 }}
                            onClick={() => {
                              ViewSpace(spaceList.id);
                            }}
                          >
                            <div className="rowEllips">{spaceList.status}</div>
                          </TableCell>
                          <TableCell style={{ maxWidth: 50, width: 50 }}>
                            {rolePermission && rolePermission.editSpace && (
                              <EditButton
                                route={`/editspace/${spaceList.id}`}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    ))
                  ) : (
                    <NoResultsFound />
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};
export default SpaceList;
