/* eslint-disable import/no-cycle */
import React, { createContext, useState, useEffect } from 'react';
import CateringCategoryList from '../containers/CateringCategory/CateringCategory';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';

export const CateringCategoryContext = createContext({});

export const CategoryContextProvider = () => {
  const [cateringCategoryList, setCateringCategoryList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [totalCateringCount, setTotalCateringCount] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [cateringSearch, setCateringSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState('asc');
  const [statusActive, setStatusActive] = useState('');
  const [statusInactive, setStatusInactive] = useState('');
  const [sortBy, setSortBy] = useState('categoryName');
  const [statuses, setStatuses] = useState([
    { label: 'Available', key: 'Available', selected: false },
    { label: 'Decommissioned', key: 'Decommissioned', selected: false },
  ]);

  const getCateringListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getCategoryList, payload)
      .then(data => {
        setCateringCategoryList(data && data.data && data.data.data);
        setTotalCateringCount(data && data.data.count);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  useEffect(() => {
    const updatedStatus = [];
    statuses.filter(status => {
      if (status.selected) {
        updatedStatus.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedStatus(updatedStatus);
    getCateringListAction({
      status:
        updatedStatus && updatedStatus.length > 0
          ? updatedStatus
          : ['Available'],
      searchText: cateringSearch.length > 2 ? cateringSearch : '',
      sortOrder,
      sortBy,
      offset,
      limit: 40,
    });
  }, [sortOrder, sortBy, offset, cateringSearch, statuses]);

  return (
    <CateringCategoryContext.Provider
      value={{
        cateringCategoryList,
        sortOrder,
        sortBy,
        page,
        cateringSearch,
        setCateringSearch,
        setPage,
        loading,
        setSortBy,
        totalCateringCount,
        setOffset,
        setCateringCategoryList,
        setSortOrder,
        selectedStatus,
        setSelectedStatus,
        statusActive,
        setStatusActive,
        statusInactive,
        setStatusInactive,
        statuses,
        setStatuses,
      }}
    >
      <CateringCategoryList />
    </CateringCategoryContext.Provider>
  );
};
