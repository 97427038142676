import React from 'react';

const PaymentSuccess = () => {
  return (
    <div className="failedPaymentBody">
      <div className="failedPaymentCardBox">
        <div>
          <div className="orderMainTable">
            <div className="noResult cardPaymentSuccess">
              <h2>Payment Status</h2>
              <p>
                Payment successfully completed
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
