import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  makeStyles,
  InputLabel,
  Input,
  RadioGroup,
  CircularProgress,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import dowmArw from '../../images/down-arw.svg';
import './AddNewsArticle.css';
import buttonsEditPencil from '../../images/buttons-edit-w-pencil.svg';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { GlobalContext } from '../../context/GlobalContext';
import { highestValidDateNew } from '../../utils/constants';
import { logOut } from '../../utils/logOut';
import NewsAndAriclesMedia from '../../components/ImageUpload/NewsAndAriclesMedia';
import CloseButton from '../../components/Buttons/CloseButton';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addNewArticleForm: {
    width: '61%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      marginLeft: 0,
      marginRight: 0,
      '& li': {
        flex: 1,
        listStyle: 'none',
        marginLeft: 0,
        marginRight: 15,
        marginBottom: 20,
        position: 'relative',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  inputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
      },
      '& label': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        transform: 'none',
        textTransform: 'capitalize',
        position: 'static',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '90px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& > div': {
          '& > div': {
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
  leftFilterIn: {
    '& button': {
      color: '#444444',
      backgroundColor: '#f8f8f8',
      padding: '12px 12px 10px',
      fontWeight: 'bold',
      boxShadow: 'none',
      fontSize: '14px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      borderRadius: 5,
      '&:hover': {
        boxShadow: 'none',
        background: '#f8f8f8',
      },
      '& span': {
        lineHeight: '100%',
        alignItems: 'center',
        display: 'flex',

        '& small': {
          marginLeft: 20,
          top: '-2px',
          position: 'relative',
        },
        '& img': {
          '&:nth-child(2)': {
            display: 'none',
          },
        },
      },
    },
  },
  fourCol: {
    flexWrap: 'wrap',
    '& li': {
      flex: 'inherit !important',
      width: 'calc(25% - 15px)',
    },
  },
  BlankDate: {
    '& input': {
      textAlign: 'left',
    },
  },
  noteText: {
    fontSize: 10,
    color: '#838384',
    marginTop: 5,
  },
  label: {
    display: 'block',
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    textTransform: 'capitalize',
    position: 'static',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
}));

const platforms = {
  CustomerApp: 'Customer App',
  TenantPortal: 'Tenant Portal',
  All: 'Both',
};

const ViewNewsArticle = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [newsAndArticlesDetails, setNewsAndArticlesDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newsOrArticleImage, setNewsOrArticleImage] = useState([]);
  const [newsVideo, setNewsVideo] = useState('');
  const [previewImage, setPreviewImage] = useState([]);
  const [loopVideo, setLoopVideo] = useState('');
  const [loopVideoTextOverlay, setLoopVideoTextOverlay] = useState('');
  const [articleType, setArticleType] = useState('Announcement');
  const [newsType, setNewsType] = useState('standard');
  const { rolePermission } = useContext(GlobalContext);
  const location =
    newsAndArticlesDetails &&
    newsAndArticlesDetails.buildings &&
    (newsAndArticlesDetails.buildings === 'all'
      ? 'All'
      : newsAndArticlesDetails.buildings.length);
  const tenant =
    newsAndArticlesDetails &&
    newsAndArticlesDetails.tenants &&
    (newsAndArticlesDetails.tenants === 'all'
      ? 'All'
      : newsAndArticlesDetails.tenants.length);

  useEffect(() => {
    ApiClient.apiGet(`${apiEndPoints.createArticale}/${id}`)
      .then(data => {
        setLoading(false);
        setNewsAndArticlesDetails(data && data.data && data.data.data);
        const articlesData = data && data.data && data.data.data;
        setArticleType(articlesData.type);
        setNewsType(articlesData.mediaType);
        setNewsOrArticleImage(articlesData.newsImage || []);
        setNewsVideo(articlesData.video);
        setPreviewImage(articlesData.videoPreviewImage || []);
        setLoopVideo(articlesData.loopVideo);
        setLoopVideoTextOverlay(articlesData.loopVideoText);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        alert(error);
      });
  }, []);

  const breadCrumbs = {
    listBreadCrumbPath: '/news-announcements',
    listBreadCrumbTitle: 'News + Announcements',
    viewBreadCrumbPath: '/view-newsarticle/',
    viewBreadCrumbTitle: 'View Article',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addMeetingRoomUserContainer">
            {!(
              rolePermission &&
              rolePermission.userType === 'FrontOfHouse' &&
              newsAndArticlesDetails &&
              newsAndArticlesDetails.type === 'News'
            ) && (
              <Link to={`/edit-newsarticle/${id}`}>
                <div className="ditIcon">
                  <img src={buttonsEditPencil} alt="buttonsEditPencil" />
                </div>
              </Link>
            )}
            <div className={`${classes.addNewArticleForm} offerForm`}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">
                    {newsAndArticlesDetails.type === 'Announcement'
                      ? 'Announcement Details'
                      : 'News Article Details'}
                  </FormLabel>
                </li>
              </ul>
              <ul className={`${classes.inputSection}`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Title
                  </InputLabel>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={newsAndArticlesDetails.title}
                  />
                </li>
              </ul>
              <ul className={`${classes.inputSection} ${classes.fourCol}`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Author
                    </InputLabel>
                    <Input
                      readOnly
                      type="text"
                      value={newsAndArticlesDetails.author}
                    />
                  </FormControl>
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Valid from date
                    </InputLabel>
                    <Input
                      readOnly
                      type="text"
                      className={classes.textField}
                      value={moment(
                        new Date(newsAndArticlesDetails.startDate),
                      ).format('DD MMM YYYY')}
                    />
                  </FormControl>
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Valid to date
                    </InputLabel>
                    <Input
                      readOnly
                      type="text"
                      className={`${classes.textField} ${classes.BlankDate} `}
                      value={
                        moment(new Date(newsAndArticlesDetails.endDate)).format(
                          'DD.MM.YY',
                        ) === highestValidDateNew
                          ? '---'
                          : moment(
                              new Date(newsAndArticlesDetails.endDate),
                            ).format('DD MMM YYYY')
                      }
                    />
                  </FormControl>
                </li>
                {newsAndArticlesDetails.type === 'News' && <li>&nbsp;</li>}
                {newsAndArticlesDetails.type === 'News' && (
                  <li>
                    <FormControl className={classes.formControl}>
                      <InputLabel shrink className={classes.formLabel}>
                        Article category
                      </InputLabel>
                      <Input
                        readOnly
                        type="text"
                        className={classes.textField}
                        value={
                          newsAndArticlesDetails &&
                          newsAndArticlesDetails.category &&
                          newsAndArticlesDetails.category.name
                        }
                      />
                    </FormControl>
                  </li>
                )}
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Platform
                    </InputLabel>
                    <Input
                      readOnly
                      type="text"
                      value={platforms[newsAndArticlesDetails.platform]}
                    />
                  </FormControl>
                </li>
              </ul>
              <ul className={`${classes.inputSection}`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Description Preview
                  </InputLabel>
                  <textarea
                    readOnly
                    type="text"
                    value={newsAndArticlesDetails.descriptionPreview}
                  />
                </li>
              </ul>
              <ul className={`${classes.inputSection}`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Description
                  </InputLabel>
                  <div className="viewOfferDescription" readOnly>
                    {ReactHtmlParser(newsAndArticlesDetails.description)}
                  </div>
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList offerLocationBtn">
                <li>
                  <FormLabel className="locationFilterLabel" component="legend">
                    Location
                  </FormLabel>
                  <Button aria-describedby={id} variant="contained">
                    {location > 1
                      ? `${location} Buildings selected`
                      : `${location} Building selected`}
                  </Button>
                </li>
                {articleType === 'Announcement' && (
                  <li>
                    <FormLabel
                      className="locationFilterLabel"
                      component="legend"
                    >
                      Tenant
                    </FormLabel>
                    <Button aria-describedby={id} variant="contained">
                      {tenant > 1
                        ? `${tenant} Tenants selected`
                        : `${tenant} Tenant selected`}
                    </Button>
                  </li>
                )}
              </ul>
              <Divider className="formDivider" light />
              <>
                <ul className="offerFormList">
                  <li>
                    <FormLabel component="legend">Media</FormLabel>
                  </li>
                </ul>
                <div className={`${classes.fourCol} offerImgUoload`}>
                  <NewsAndAriclesMedia
                    values={{
                      newsOrArticleImage,
                      setNewsOrArticleImage,
                      newsVideo,
                      setNewsVideo,
                      previewImage,
                      setPreviewImage,
                      loopVideo,
                      setLoopVideo,
                      loopVideoTextOverlay,
                      setLoopVideoTextOverlay,
                      newsType,
                      articleType,
                    }}
                    viewMedia
                  />
                </div>
              </>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        {newsAndArticlesDetails.status === 'Published' && (
                          <p>{newsAndArticlesDetails.status}</p>
                        )}
                        {newsAndArticlesDetails.status === 'Unpublished' && (
                          <p>{newsAndArticlesDetails.status}</p>
                        )}
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <CloseButton route="/news-announcements" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewNewsArticle;
