import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  makeStyles,
  RadioGroup,
  InputLabel,
  Input,
  CircularProgress,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import dowmArw from '../../images/down-arw.svg';
import FileUpload from '../../components/ImageUpload/FileUpload';
import buttonsEditPencil from '../../images/buttons-edit-w-pencil.svg';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import { GlobalContext } from '../../context/GlobalContext';
import CloseButton from '../../components/Buttons/CloseButton';
import { utcToIstDateFormate } from '../../utils/dateUtils';


const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
        position: 'relative',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
      },
      '& label': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        transform: 'none',
        textTransform: 'capitalize',
        position: 'static',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '65px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& > div': {
          '& > div': {
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
  leftFilterIn: {
    '& button': {
      color: '#444444',
      backgroundColor: '#f8f8f8',
      padding: '12px 12px 10px',
      fontWeight: 'bold',
      boxShadow: 'none',
      fontSize: '14px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      borderRadius: 5,
      '&:hover': {
        boxShadow: 'none',
        background: '#f8f8f8',
      },
      '& span': {
        lineHeight: '100%',
        alignItems: 'center',
        display: 'flex',

        '& small': {
          marginLeft: 20,
          top: '-2px',
          position: 'relative',
        },
        '& img': {
          '&:nth-child(2)': {
            display: 'none',
          },
        },
      },
    },
  },
}));

const ViewOffer = () => {
  const { rolePermission } = useContext(GlobalContext);
  const classes = useStyles();
  const { id } = useParams();
  const [offerDetails, setOfferDetails] = useState([]);
  const [offerDescription, setOfferDescription] = useState('');
  const [images, setImages] = useState([]);
  const [companyLogo, setCompanyLogo] = useState('');
  const [loading, setLoading] = useState(true);
  const buildings =
    (offerDetails && offerDetails.buildings && offerDetails.buildings.length) ||
    'No';

  useEffect(() => {
    ApiClient.apiGet(apiEndPoints.getOfferDetails + id).then(data => {
      setOfferDetails(data && data.data && data.data.data);
      const descData = data && data.data && data.data.data;
      setOfferDescription(descData.description);
      setImages(descData.offerImage);
      setCompanyLogo(descData.companyLogo);
      setLoading(false);
    });
  }, []);

  const breadCrumbs = {
    listBreadCrumbPath: '/offers-list',
    listBreadCrumbTitle: 'Offers',
    viewBreadCrumbPath: '/view-offers/',
    viewBreadCrumbTitle: 'View Offer',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addMeetingRoomUserContainer">
            {rolePermission && rolePermission.editOffer && (
              <Link to={`/edit-offers/${offerDetails.id}`}>
                <div className="ditIcon">
                  <img src={buttonsEditPencil} alt="buttonsEditPencil" />
                </div>
              </Link>
            )}
            <div className={`${classes.addUserForm} offerForm`}>
              <ul className="offerFormList">
                <li className="mouseClickCountHeading">
                  <FormLabel component="legend">Offer Details</FormLabel>
                  {/* <div className="mouseClickCount"> */}
                  {/* <img src={mouseClicks} alt="mouseClicks" /> */}
                  <span>REDEEMED : {offerDetails.clicksCount}</span>
                  {/* </div> */}
                </li>
              </ul>
              <ul className={`${classes.InputSection} textareaSection`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Offer Name
                  </InputLabel>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    inputprops={{ 'aria-label': 'offerName' }}
                    value={offerDetails.offerName}
                  />
                </li>
              </ul>
              <ul
                className={`${classes.InputSection} offerDtCat offerDtCatView`}
              >
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Start Date
                    </InputLabel>
                    <Input
                      readOnly
                      type="text"
                      className={classes.textField}
                      value={moment(offerDetails.startDate).format(
                        'DD MMM YYYY',
                      )}
                    />
                  </FormControl>
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Expiry Date
                    </InputLabel>
                    <Input
                      readOnly
                      type="text"
                      className={classes.textField}
                      value={moment(utcToIstDateFormate(offerDetails.endDate))
                        .format('DD MMM YYYY')}
                    />
                  </FormControl>
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Offer Category
                    </InputLabel>
                    <Input
                      readOnly
                      value={offerDetails.offerCategory}
                      className={classes.textField}
                    />
                  </FormControl>
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Offer Type
                    </InputLabel>
                    <Input
                      readOnly
                      className={classes.textField}
                      value={
                        offerDetails.offerType === 'Url'
                          ? 'Digital URL Only'
                          : offerDetails.offerType
                      }
                    />
                  </FormControl>
                </li>
              </ul>
              {offerDetails.offerType === 'Physical' && (
                <ul className={`${classes.InputSection} physicalType`}>
                  <li>
                    <ul className="physicalTypeSub">
                      <li>
                        <span>Store address line 1</span>
                        <Input
                          readOnly
                          type="text"
                          inputprops={{ 'aria-label': 'offerName' }}
                          value={offerDetails.address.line1}
                        />
                      </li>
                      <li>
                        <span>Store address line 2</span>
                        <Input
                          readOnly
                          type="text"
                          inputprops={{ 'aria-label': 'offerName' }}
                          value={offerDetails.address.line2}
                        />
                      </li>
                      <li>
                        <span>City</span>
                        <Input
                          readOnly
                          type="text"
                          inputprops={{ 'aria-label': 'offerName' }}
                          value={offerDetails.address.city}
                        />
                      </li>
                      <li>
                        <span>Country</span>
                        <Input
                          readOnly
                          type="text"
                          inputprops={{ 'aria-label': 'offerName' }}
                          value={offerDetails.address.county}
                        />
                      </li>
                      <li>
                        <span>Postcode</span>
                        <Input
                          readOnly
                          type="text"
                          inputprops={{ 'aria-label': 'offerName' }}
                          value={offerDetails.address.postcode}
                        />
                      </li>
                    </ul>
                    <ul className="physicalTypeSubWrap">
                      <li>
                        <span>Latitude co-ordinate</span>
                        <Input
                          readOnly
                          type="text"
                          inputprops={{ 'aria-label': 'offerName' }}
                          value={offerDetails.address.latitude}
                        />
                      </li>
                      <li>
                        <span>Longitude co-ordinate</span>
                        <Input
                          readOnly
                          type="text"
                          inputprops={{ 'aria-label': 'offerName' }}
                          value={offerDetails.address.longitude}
                        />
                      </li>
                    </ul>
                  </li>
                </ul>
              )}
              {offerDetails.offerType !== 'Physical' && (
                <ul className={`${classes.InputSection} digitalType`}>
                    {offerDetails.offerType !== 'Url' && (
                  <li>
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink
                          id="demo-simple-select-placeholder-label-label"
                          className={classes.formLabel}
                        >
                          Voucher Code
                        </InputLabel>
                        <Input
                          readOnly
                          type="text"
                          inputprops={{ 'aria-label': 'description' }}
                          value={offerDetails.voucherCode}
                        />
                      </FormControl>
                  </li>
                    )}
                  <li>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                      >
                       {offerDetails.offerType === 'Url' && "Digital URL" || "Offer URL"}
                        
                      </InputLabel>
                      <Input
                        readOnly
                        type="text"
                        inputprops={{ 'aria-label': 'description' }}
                        value={offerDetails.offerUrl}
                      />
                    </FormControl>
                  </li>
                </ul>
              )}
              <ul className={`${classes.InputSection} richTextEdit`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Description
                  </InputLabel>
                  <div className="viewOfferDescription" readOnly>
                    {ReactHtmlParser(offerDescription)}
                  </div>
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                    >
                      T{`${'&'}`}Cs
                    </InputLabel>
                    <textarea
                      readOnly
                      type="text"
                      inputprops={{ 'aria-label': 'terms' }}
                      value={offerDetails.terms}
                    />
                  </FormControl>
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">COMPANY DETAILS</FormLabel>
                </li>
              </ul>
              <ul className={`${classes.InputSection} textareaSectionHalf`}>
                <li>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                    className={classes.formLabel}
                  >
                    Company Name
                  </InputLabel>
                  <Input
                    readOnly
                    inputprops={{ 'aria-label': 'companyName' }}
                    value={offerDetails.companyName}
                  />
                </li>
                <li>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                    className={classes.formLabel}
                  >
                    Company URL
                  </InputLabel>
                  <Input
                    readOnly
                    inputprops={{ 'aria-label': 'companyUrl' }}
                    value={offerDetails.companyUrl}
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerLocationBtn">
                <li>
                  <FormLabel className="locationFilterLabel" component="legend">
                    Location
                  </FormLabel>
                  <Button aria-describedby={id} variant="contained">
                    {buildings 
                      ? `${buildings} Buildings selected`
                      : `No Building selected`}
                  </Button>
                </li>
                  <li>
                    <FormLabel
                      className="locationFilterLabel"
                      component="legend"
                    >
                      Village
                    </FormLabel>
                    <Button aria-describedby={id} variant="contained">
                    {offerDetails?.village?.length  ? `${offerDetails?.village?.length} Villages Selected` : 'No Village Selected'}
                    </Button>
                  </li>
         
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Media</FormLabel>
                </li>
              </ul>
              <ul className="offerImgUoload">
                <li>
                  <FileUpload
                    values={{
                      images,
                      setImages,
                      setCompanyLogo,
                      companyLogo,
                    }}
                    offer
                    viewMedia
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        {offerDetails.status === 'Published' && (
                          <p>{offerDetails.status}</p>
                        )}
                        {offerDetails.status === 'Unpublished' && (
                          <p>{offerDetails.status}</p>
                        )}
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <CloseButton route="/offers-list" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewOffer;
