/* eslint-disable import/no-cycle */
import React, { createContext, useState, useEffect } from 'react';
import SpaceList from '../containers/Space/SpaceList';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';

export const SpacesContext = createContext({});
export const SpacesContextProvider = () => {
  const [spacesList, setSpacesList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [totalSpacesCount, setTotalSpacesCount] = useState(0);
  const [cateringSearch, setCateringSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('availabilityDate');
  const [villages, setVillages] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [statusActive, setStatusActive] = useState('');
  const [statusInactive, setStatusInactive] = useState('');
  const [statuses, setStatuses] = useState([
    { label: 'Published', key: 'Published', selected: false },
    { label: 'Unpublished', key: 'Unpublished', selected: false },
  ]);
  const [spacesSearch, setSpacesSearch] = useState('');

  const spacesSearchResult = spacesSearch.length > 2 ? spacesSearch : '';
  const getSpaceListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getSpacesList, payload)
      .then(data => {
        setSpacesList(data && data.data && data.data.data);
        setTotalSpacesCount(data && data.data.count);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  useEffect(() => {
    const updatedStatus = [];
    statuses.filter(status => {
      if (status.selected) {
        updatedStatus.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedStatus(updatedStatus);
    getSpaceListAction({
      searchText: spacesSearchResult ? spacesSearch : '',
      status:
        updatedStatus && updatedStatus.length > 0
          ? updatedStatus
          : ['Published'],
      buildings: buildings && buildings.length > 0 ? buildings : undefined,
      sortOrder,
      sortBy,
      offset,
      limit: 40,
    });
  }, [sortOrder, sortBy, offset, statuses, spacesSearch, buildings]);

  return (
    <SpacesContext.Provider
      value={{
        spacesList,
        setSpacesList,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        page,
        setPage,
        cateringSearch,
        setCateringSearch,
        loading,
        totalSpacesCount,
        setOffset,
        buildings,
        setBuildings,
        villages,
        setVillages,
        selectedStatus,
        setSelectedStatus,
        statusActive,
        setStatusActive,
        statusInactive,
        setStatusInactive,
        statuses,
        setStatuses,
        spacesSearch,
        setSpacesSearch,
      }}
    >
      <SpaceList />
    </SpacesContext.Provider>
  );
};
