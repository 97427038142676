import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  RadioGroup,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import dowmArw from '../../images/down-arw.svg';
import buttonsEditPencil from '../../images/buttons-edit-w-pencil.svg';
import apiEndPoints from '../../helpers/apiEndPoints';
import ApiClient from '../../helpers/ApiClient';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FileUpload from '../../components/ImageUpload/FileUpload';
import { GlobalContext } from '../../context/GlobalContext';
import CloseButton from '../../components/Buttons/CloseButton';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
      },
      '& label': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        transform: 'none',
        textTransform: 'capitalize',
        position: 'static',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        minHeight: '160px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& > div': {
          '& > div': {
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
}));

const ViewCateringMenu = () => {
  const classes = useStyles();
  const { id } = useParams();
  const [categoryItemDetails, setCategoryItemDetails] = useState('');
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const { rolePermission } = useContext(GlobalContext);
  useEffect(() => {
    ApiClient.apiGet(`${apiEndPoints.createCateringMenu}/${id}`)
      .then(data => {
        const categoryItemData = data && data.data && data.data.data;
        setCategoryItemDetails(data && data.data && data.data.data);
        setImages(
          (categoryItemData.image && [
            { imageUrl: categoryItemData.image, tag: 'Full Size' },
            { imageUrl: categoryItemData.image, tag: 'Card' },
          ]) ||
            [],
        );
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        alert(error);
      });
  }, []);

  const breadCrumbs = {
    listBreadCrumbPath: '/catering-menu-list',
    listBreadCrumbTitle: 'Catering menu',
    viewBreadCrumbPath: '/view-cateringmenu/',
    viewBreadCrumbTitle: 'View menu item',
  };

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="ingerit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addUserContainer">
            <div className={`${classes.addUserForm} createcategoryForm`}>
              {rolePermission && rolePermission.editCateringItem && (
                <Link to={`/edit-cateringmenu/${id}`}>
                  <div className="ditIcon">
                    <img src={buttonsEditPencil} alt="buttonsEditPencil" />
                  </div>
                </Link>
              )}
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Menu Item Details</FormLabel>
                </li>
              </ul>
              <ul>
                <li className="radioSectionLabel">
                  <span>Supplier</span>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        {categoryItemDetails.category.supplierName ===
                          'Lantana' && (
                          <p>{categoryItemDetails.category.supplierName}</p>
                        )}
                        {categoryItemDetails.category.supplierName ===
                          'Derwent London' && (
                          <p>{categoryItemDetails.category.supplierName}</p>
                        )}
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <ul className={`${classes.InputSection} creaeCategoryField`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Category
                  </InputLabel>
                  <Input
                    readOnly
                    type="text"
                    value={categoryItemDetails.category.categoryName}
                  />
                </li>
                <li>
                  <span>Item name</span>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={categoryItemDetails.itemName}
                  />
                </li>
              </ul>
              <ul className={`${classes.InputSection} textareaSection`}>
                <li>
                  <span>Description</span>
                  <textarea
                    readOnly
                    value={categoryItemDetails.itemDescription}
                    type="text"
                    inputProps={{ 'aria-label': 'description' }}
                  />
                </li>
              </ul>
              <ul className={`${classes.InputSection}`}>
                <li className={`${classes.InputSection}`}>
                  <span>Price per quantity</span>
                  <Input
                    readOnly
                    value={categoryItemDetails.pricePerQuantity}
                    type="text"
                  />
                </li>
              </ul>
              {categoryItemDetails.additionalInformation && (
                <ul className="offerLocationBtn">
                  <li>
                    <FormLabel
                      className="locationFilterLabel"
                      component="legend"
                    >
                      Dietary Information
                    </FormLabel>
                    <Button aria-describedby={id} variant="contained">
                      {categoryItemDetails.additionalInformation
                        .dietaryInformation.length > 1
                        ? `${categoryItemDetails.additionalInformation.dietaryInformation.length} Items selected`
                        : `${categoryItemDetails.additionalInformation.dietaryInformation.length} Item selected`}
                    </Button>
                  </li>
                  <li>
                    <FormLabel
                      className="locationFilterLabel"
                      component="legend"
                    >
                      Allergen Information
                    </FormLabel>
                    <Button aria-describedby={id} variant="contained">
                      {categoryItemDetails.additionalInformation
                        .allergenInformation.length > 1
                        ? `${categoryItemDetails.additionalInformation.allergenInformation.length} Items selected`
                        : `${categoryItemDetails.additionalInformation.allergenInformation.length} Item selected`}
                    </Button>
                  </li>
                </ul>
              )}
              {/* <Divider className="formDivider" light /> */}

              { categoryItemDetails.additionalInformation && 
               categoryItemDetails.additionalInformation
                  .nutritionalInformation  &&
              
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">
                    Nutritional Information
                  </FormLabel>
                </li>
              </ul>}
              {categoryItemDetails.additionalInformation &&
                categoryItemDetails.additionalInformation
                  .nutritionalInformation && (
                    <ul className={`${classes.InputSection}`}>
                        {categoryItemDetails.additionalInformation.nutritionalInformation.map(
                          value => {
                            return (
                              <li className={`${classes.InputSection}`}>
                                <span style={{ color: value.color }}>
                                  {value.name}
                                </span>
                                <Input
                                  readOnly
                                  type="text"
                                  value={value.quantity}
                                />
                              </li>
                            );
                          },
                        )}
                      </ul>
                )}

              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Media</FormLabel>
                </li>
              </ul>
              <ul className="offerImgUoload">
                <li>
                  <FileUpload values={{ images }} cateringMenu viewMedia />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li className="statusMarBtm">
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        {categoryItemDetails.status === 'Available' && (
                          <p>{categoryItemDetails.status}</p>
                        )}
                        {categoryItemDetails.status === 'Decommissioned' && (
                          <p>{categoryItemDetails.status}</p>
                        )}
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <CloseButton route="/catering-menu-list" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewCateringMenu;
