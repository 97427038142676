/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import { CircularProgress, Divider, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import dowmArw from '../../images/down-arw.svg';
import accountBoxImg1 from '../../images/accountBoxImg1.svg';
import accountBoxImg2 from '../../images/accountBoxImg2.svg';
import accountBoxImg3 from '../../images/accountBoxImg3.svg';
import newIcon from '../../images/new.svg';
import TenentFilterSearch from './TenentFilterSearch';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import { TenentContext } from '../../context/TententUserContext';
import { GlobalContext } from '../../context/GlobalContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import AddButton from '../../components/Buttons/AddButton';
import SnackBar from '../../components/SnackBar/SnackBar';
import EditButton from '../../components/Buttons/EditButton';
import { tenantUserStatus } from '../../utils/constants';

const columns = [
  {
    id: 'firstName',
    SortOrder: 'desc',
    SortBy: 'firstName',
    label: 'CUSTOMER',
    minWidth: 260,
    maxWidth: 260,
  },
  {
    id: 'jobTitle',
    SortOrder: 'desc',
    SortBy: 'jobTitle',
    label: 'JOB ROLE',
    minWidth: 220,
    maxWidth: 220,
  },
  {
    id: 'tenantName',
    SortOrder: 'desc',
    SortBy: 'tenant.name',
    label: 'TENANT',
    minWidth: 170,
    maxWidth: 170,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'village',
    SortOrder: 'desc',
    SortBy: 'building.name',
    label: 'LOCATION',
    minWidth: 130,
    maxWidth: 130,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'role',
    SortOrder: 'desc',
    SortBy: 'role',
    label: 'ROLE',
    minWidth: 120,
    maxWidth: 120,
    format: value => value.toFixed(2),
  },
  {
    id: 'payToAccount',
    SortOrder: 'desc',
    SortBy: 'payToAccount',
    label: 'ACCOUNT ',
    minWidth: 150,
    maxWidth: 150,
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'STATUS',
    minWidth: 90,
    maxWidth: 90,
    format: value => value.toFixed(2),
  },
  {
    id: 'edit',
    SortOrder: '',
    SortBy: '',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 245px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 15px',
    color: '#444444',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  accountBoxImg: {
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& li': {
        width: '31%',
        '& img': {
          margin: '0 auto',
          width: '25px',
        },
        '&:first-child': {
          '& img': {
            width: '16px',
          },
        },
      },
    },
  },
  tableBody: {
    verticalAlign: 'top',
    '& th': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
    },
    '& td': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  buildVillg: {
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  pageListMain: {
    position: 'relative',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '10px',
    padding: '5px 0',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#838384',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '29px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
});

const TenentUserList = () => {
  const {
    tenantsUsersList,
    loading,
    tenantActiveUsers,
    setSortOrder,
    sortOrder,
    setSortBy,
    sortBy,
    totalTenantsCount,
  } = useContext(TenentContext);
  const { rolePermission } = useContext(GlobalContext);
  const history = useHistory();
  const classes = useStyles();
  const [active, setActive] = useState(false);

  const getUserCreatedDate = createDate => {
    const date1 = new Date().getTime();
    const date2 = new Date(createDate).getTime();
    const msDiff = date1 - date2;
    const minutesDifference = Math.floor(msDiff / 60000);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    return daysDifference;
  };

  const handleClick = (columnId, sortName) => {
    setSortOrder(sortOrder === columnId ? 'asc' : 'desc');
    setSortBy(sortBy === sortName ? sortBy : sortName);
  };

  const viewUser = rowId => {
    history.push(`/viewuser/${rowId}`);
  };

  const breadCrumbs = {
    listBreadCrumbPath: '/tenantusers',
    listBreadCrumbTitle: 'Tenant Users',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.pageListMain}>
      <SnackBar />
      {rolePermission && rolePermission.createTenantUser && (
        <AddButton route="/adduser" />
      )}
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      <TenentFilterSearch />

      <Divider light />
      <div className="pageContainer tableContainer tenantUserListTable">
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      className={`${classes.tableHeaderCell} noSort ${
                        active && column.SortBy === sortBy ? 'active' : ''
                      }`}
                      key={column.id}
                      disabled={
                        column.SortBy === 'payToAccount' && column.SortBy === ''
                      }
                      align={column.align}
                      id={column.label}
                      onClick={() => {
                        if (
                          (column.SortBy !== 'payToAccount' &&
                            column.SortBy !== '') ||
                          column.SortBy === sortBy
                        ) {
                          handleClick(column.SortOrder, column.SortBy);
                          setActive(!active);
                        }
                      }}
                      style={{ minWidth: column.minWidth }}
                    >
                      <p>{column.label}</p>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TableBody>
                  {tenantsUsersList && tenantsUsersList.length > 0 ? (
                    tenantsUsersList.map(row => (
                      <TableRow key={row.id} className={classes.tableBody}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ maxWidth: 260, width: 260 }}
                          onClick={() => {
                            viewUser(row.id);
                          }}
                        >
                          <div className="rowEllips">
                            <Tooltip title={`${row.firstName} ${row.lastName}`}>
                              <div className={classes.customerName}>
                                {' '}
                                {`${row.firstName} ${row.lastName}`}{' '}
                                {getUserCreatedDate(row.createdDate) < 30 && (
                                  <span className={classes.customerNameNew}>
                                    <img src={newIcon} alt="buttonsAdd" />
                                  </span>
                                )}
                              </div>
                            </Tooltip>
                            <Tooltip title={row.email}>
                              <div className={classes.customerEmail}>
                                {' '}
                                {row.email}
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 150, width: 150 }}
                          onClick={() => {
                            viewUser(row.id);
                          }}
                        >
                          <div className="rowEllips">{row.jobTitle}</div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 190, width: 190 }}
                          onClick={() => {
                            viewUser(row.id);
                          }}
                        >
                          <Tooltip title={row?.tenant?.name}>
                            <div className="rowEllips">{row?.tenant?.name}</div>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 180, width: 180 }}
                          onClick={() => {
                            viewUser(row.id);
                          }}
                        >
                          <div className="rowEllips">
                            <Tooltip title={row?.building?.name}>
                              <div className={classes.buildVillg}>
                                {row?.building?.name}
                              </div>
                            </Tooltip>
                            <div className={classes.customerVillg}>
                              {row?.building?.village}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 120, width: 120 }}
                          onClick={() => {
                            viewUser(row.id);
                          }}
                        >
                          <Tooltip title={row.role}>
                            <div className="rowEllips">{row.role}</div>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={classes.accountBoxImgColumn}
                          style={{ maxWidth: 150, width: 150 }}
                          onClick={() => {
                            viewUser(row.id);
                          }}
                        >
                          <div className="rowEllips">
                            <div className={classes.accountBoxImg}>
                              <ul>
                                <li>
                                  {row.accessToCustomerApp === true && (
                                    <img
                                      src={accountBoxImg2}
                                      alt="accountBoxImg2"
                                    />
                                  )}
                                </li>
                                <li>
                                  <img
                                    src={accountBoxImg1}
                                    alt="accountBoxImg1"
                                  />
                                </li>
                                <li>
                                  {row.payToAccount === true && (
                                    <img
                                      src={accountBoxImg3}
                                      alt="accountBoxImg3"
                                    />
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.accountBoxImgColumnActive}
                          align="left"
                          style={{ maxWidth: 90, width: 90 }}
                          onClick={() => {
                            viewUser(row.id);
                          }}
                        >
                          <div className="rowEllips">
                            <Tooltip
                              title={`${row.status}${'  '}${
                                row.status === tenantUserStatus.blocked && row.inactiveDate
                                  ? moment(row.inactiveDate).format(
                                      'DD MMM YYYY',
                                    )
                                  : ''
                              } ${
                                row.status === tenantUserStatus.renewalPending &&
                                row.lastRenewalDate
                                  ? moment(row.lastRenewalDate).format(
                                      'DD MMM YYYY',
                                    )
                                  : ''
                              }`}
                            >
                              <div className={classes.customerName}>
                                {row.status}{' '}
                              </div>
                            </Tooltip>
                            <div className={classes.userAddTime}>
                              {row.status === tenantUserStatus.blocked && row.inactiveDate
                                ? moment(row.inactiveDate).format('DD MMM YYYY')
                                : ''}
                              {row.status === tenantUserStatus.renewalPending &&
                              row.lastRenewalDate
                                ? moment(row.lastRenewalDate).format(
                                    'DD MMM YYYY',
                                  )
                                : ''}
                            </div>
                          </div>
                        </TableCell>

                        <TableCell
                          className={classes.accountBoxImgColumn}
                          align="right"
                          style={{ maxWidth: 50, width: 50 }}
                        >
                          {rolePermission && rolePermission.editTenantUser && (
                            <EditButton route={`/edituser/${row.id}`} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <NoResultsFound />
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
        <div className={classes.totalCount}>
          <ul>
            <li>Total active users: {tenantActiveUsers || '0'}</li>
            <li>Total tenants: {totalTenantsCount || '0'}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default TenentUserList;
