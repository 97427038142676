/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import { CircularProgress, Divider, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import dowmArw from '../../images/down-arw.svg';
import { MeetingRoomsContext } from '../../context/MeetingRoomContext';
import MeetingRoomFilters from './MeetingRoomFilters';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import { GlobalContext } from '../../context/GlobalContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import AddButton from '../../components/Buttons/AddButton';
import SnackBar from '../../components/SnackBar/SnackBar';
import EditButton from '../../components/Buttons/EditButton';

const columns = [
  {
    id: 'nameSort',
    SortOrder: 'desc',
    SortBy: 'nameSort',
    label: 'MEETING ROOM',
    minWidth: 160,
    maxWidth: 160,
  },
  {
    id: 'building',
    SortOrder: 'desc',
    SortBy: 'building.name',
    label: 'LOCATION',
    minWidth: 160,
    maxWidth: 160,
  },
  {
    id: 'building',
    SortOrder: 'desc',
    SortBy: 'roomType',
    label: 'ROOM TYPE',
    minWidth: 160,
    maxWidth: 160,
  },
  {
    id: 'maxCapacity',
    SortOrder: 'desc',
    SortBy: 'maxCapacity',
    label: 'CAPACITY',
    minWidth: 130,
    maxWidth: 130,
    align: 'right',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'hourlyPrice',
    SortOrder: 'desc',
    SortBy: 'hourlyPrice',
    label: 'HOURLY RATE ',
    minWidth: 130,
    maxWidth: 130,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'fullDayPrice',
    SortOrder: 'desc',
    SortBy: 'fullDayPrice',
    label: 'FULL DAY RATE',
    minWidth: 140,
    maxWidth: 140,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'STATUS',
    minWidth: 100,
    maxWidth: 100,
    format: value => value.toFixed(2),
  },
  {
    id: 'edit',
    SortOrder: '',
    SortBy: '',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 28px 10px 15px',
    color: '#444444',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  accountBoxImg: {
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    display: 'flex',
    justifyContent: 'center',
    '& li': {
      width: '30%',
      '& img': {
        margin: '0 auto',
        width: '75%',
      },
    },
  },
  tableBody: {
    '& th': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      padding: '8px 16px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      verticalAlign: 'top',
    },
    '& td': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      padding: '8px 16px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      verticalAlign: 'top',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginTop: '4px',
  },
  pageListMain: {
    position: 'relative',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  buildVillg: {
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '10px',
    padding: '5px 0',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#838384',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle !important',
  },
});

const MeetingRoomsList = () => {
  const {
    meetingRoomsList,
    loading,
    setSortOrder,
    sortOrder,
    setSortBy,
    sortBy,
  } = useContext(MeetingRoomsContext);
  const { rolePermission } = useContext(GlobalContext);
  const history = useHistory();
  const classes = useStyles();
  const [active, setActive] = useState(false);

  const handleClick = (columnId, sortName) => {
    setSortOrder(sortOrder === columnId ? 'asc' : 'desc');
    setSortBy(sortBy === sortName ? sortBy : sortName);
  };
  const viewMeetingRoomList = rowId => {
    history.push(`/viewmeetingroomlist/${rowId}`);
  };

  const breadCrumbs = {
    listBreadCrumbPath: '/meetingrooms-list',
    listBreadCrumbTitle: 'Meeting Rooms',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.pageListMain}>
      <SnackBar />
      {rolePermission && rolePermission.createMeetingRoom && (
        <AddButton route="/addmeetingroom" />
      )}
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      <MeetingRoomFilters />
      <Divider light />
      <div className="pageContainer tableContainer meetingroomListTable">
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      className={`${classes.tableHeaderCell} noSort ${
                        active && column.SortBy === sortBy ? 'active' : ''
                      }`}
                      key={column.id}
                      disabled={
                        column.SortBy === 'roomLayouts' && column.SortBy === ''
                      }
                      align="left"
                      id={column.label}
                      onClick={() => {
                        if (
                          (column.SortBy !== 'roomLayouts' &&
                            column.SortBy !== '') ||
                          column.SortBy === sortBy
                        ) {
                          handleClick(column.SortOrder, column.SortBy);
                          setActive(!active);
                        }
                      }}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TableBody>
                  {meetingRoomsList && meetingRoomsList.length > 0 ? (
                    meetingRoomsList.map(row => (
                      <TableRow key={row.id} className={classes.tableBody}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ maxWidth: 160, width: 160 }}
                          onClick={() => {
                            viewMeetingRoomList(row.id);
                          }}
                        >
                          <div className="rowEllips">
                            <Tooltip title={row.name}>
                              <div className="rowEllips">{row.name}</div>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell
                          // className={classes.accountBoxImgColumn}
                          align="left"
                          style={{ maxWidth: 210, width: 210 }}
                          onClick={() => {
                            viewMeetingRoomList(row.id);
                          }}
                        >
                          <div className="rowEllips">
                            <Tooltip title={row.building.name}>
                              <div className={classes.buildVillg}>
                                {row.building.name}
                              </div>
                            </Tooltip>
                            <div className={classes.customerVillg}>
                              {row.building.village}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 160, width: 160 }}
                          onClick={() => {
                            viewMeetingRoomList(row.id);
                          }}
                        >
                          {row.roomType}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ maxWidth: 130, width: 130 }}
                          onClick={() => {
                            viewMeetingRoomList(row.id);
                          }}
                        >
                          {row.maxCapacity}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ maxWidth: 115, width: 115 }}
                          onClick={() => {
                            viewMeetingRoomList(row.id);
                          }}
                        >
                          £{row.hourlyPrice.toFixed(2)}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ maxWidth: 115, width: 115 }}
                          onClick={() => {
                            viewMeetingRoomList(row.id);
                          }}
                        >
                          £{row.fullDayPrice.toFixed(2)}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 100, width: 100 }}
                          onClick={() => {
                            viewMeetingRoomList(row.id);
                          }}
                        >
                          {row.status}
                          <div className={classes.userAddTime}>
                            {row.status === 'Inactive' && row.inactiveDate
                              ? moment(row.inactiveDate).format('DD MMM YYYY')
                              : ''}
                          </div>
                        </TableCell>

                        <TableCell
                          className={classes.accountBoxImgColumn}
                          align="center"
                          style={{ maxWidth: 50, width: 50 }}
                        >
                          {rolePermission && rolePermission.editMeetingRoom && (
                            <EditButton route={`/editmeetingroom/${row.id}`} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <NoResultsFound />
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

export default MeetingRoomsList;
