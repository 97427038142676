/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { CircularProgress, Divider, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import dowmArw from '../../images/down-arw.svg';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import BuildingAttributesFilters from './BuildingAttributesFilters';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { BuildingAttributesContext } from '../../context/BuildingAttributesContext';
import { logOut } from '../../utils/logOut';
import SnackBar from '../../components/SnackBar/SnackBar';
import EditButton from '../../components/Buttons/EditButton';

const columns = [
  {
    id: 'buildingName',
    SortOrder: 'desc',
    SortBy: 'name',
    label: 'BUILDING',
    minWidth: 220,
    width: 220,
  },
  {
    id: 'village',
    SortOrder: 'desc',
    SortBy: 'village',
    label: 'VILLAGE',
    minWidth: 140,
    width: 140,
  },
  {
    id: 'address',
    SortOrder: 'desc',
    SortBy: 'addressLine1',
    label: 'ADDRESS',
    align: 'left',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'buildingDescription',
    SortOrder: '',
    SortBy: '',
    label: 'BUILDING DESCRIPTION',
    align: 'left',
    format: value => value.toLocaleString('en-US'),
  },

  {
    id: 'edit',
    SortOrder: '',
    SortBy: '',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 15px',
    color: '#444444',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
    '&:nth-child(4)': {
      cursor: 'default',
      pointerevents: 'none',
      '&:after': {
        display: 'none',
      },
    },
    '&:nth-child(5)': {
      cursor: 'default',
      pointerevents: 'none',
      '&:after': {
        display: 'none',
      },
    },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  accountBoxImg: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      margin: '0 10px',
    },
  },
  tableBody: {
    verticalAlign: 'top',
    '& th': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
    },
    '& td': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      padding: '8px 16px',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  addIcon: {
    position: 'fixed',
    zIndex: '999',
    right: '55px',
    bottom: '55px',
  },
  fab: {
    background: '#000',
    color: '#fff',
    '&:hover': {
      background: '#000',
      color: '#fff',
    },
  },
  pageListMain: {
    position: 'relative',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '10px',
    padding: '5px 0',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#838384',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '30px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
  platformIcons: {
    display: 'flex',
    width: 70,
    margin: 'auto',
  },
});

const BuildingAttributesList = () => {
  const classes = useStyles();
  const {
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    loading,
    buildingAttributesList,
  } = useContext(BuildingAttributesContext);
  const history = useHistory();
  const [active, setActive] = useState(false);

  const handleClick = (columnId, sortName) => {
    setSortOrder(sortOrder === columnId ? 'asc' : 'desc');
    setSortBy(sortBy === sortName ? sortBy : sortName);
  };

  const viewBuildingAttributes = rowId => {
    history.push(`/view-building-attributes/${rowId}`);
  };

  const breadCrumbs = {
    listBreadCrumbPath: '/building-attributes',
    listBreadCrumbTitle: 'Building Attributes',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.pageListMain}>
      <SnackBar />
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      <BuildingAttributesFilters />
      <Divider light />
      <div className="pageContainer tableContainer offerListTable">
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      className={`${classes.tableHeaderCell} noSort ${
                        active && column.SortBy === sortBy ? 'active' : ''
                      }`}
                      align={column.align}
                      id={column.label}
                      key={column.id}
                      disabled={column.SortBy === ''}
                      onClick={() => {
                        if (column.SortBy !== '' || column.SortBy === sortBy) {
                          handleClick(column.SortOrder, column.SortBy);
                          setActive(!active);
                        }
                      }}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TableBody>
                  {buildingAttributesList &&
                  buildingAttributesList.length > 0 ? (
                    buildingAttributesList.map(buildingAttribute => (
                      <TableRow
                        className={classes.tableBody}
                        key={buildingAttribute.id}
                      >
                        <Tooltip
                          title={buildingAttribute.name || ''}
                          arrow
                          placement="top"
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            align="left"
                            valign="top"
                            style={{
                              maxWidth: 180,
                              width: 180,
                              verticalAlign: 'top',
                            }}
                            key={buildingAttribute.id}
                            onClick={() => {
                              viewBuildingAttributes(buildingAttribute.id);
                            }}
                          >
                            <div className="rowEllips">
                              {buildingAttribute.name}
                              <div className={classes.customerVillg}>
                                {buildingAttribute.addressLine1}
                              </div>
                            </div>
                          </TableCell>
                        </Tooltip>
                        <TableCell
                          style={{ maxWidth: 180, width: 180 }}
                          onClick={() => {
                            viewBuildingAttributes(buildingAttribute.id);
                          }}
                        >
                          <div className="rowEllips">
                            {buildingAttribute.village}
                          </div>
                        </TableCell>
                        <Tooltip
                          title={buildingAttribute.agentEmail || ''}
                          arrow
                          placement="top"
                        >
                          <TableCell
                            className="toLowercase"
                            align="left"
                            style={{ maxWidth: 220, width: 220 }}
                            onClick={() => {
                              viewBuildingAttributes(buildingAttribute.id);
                            }}
                          >
                            <div className="rowEllips">
                              <div className="agentName">
                                {`${buildingAttribute.addressLine1}${
                                  buildingAttribute.addressLine1 && ', '
                                }${buildingAttribute.postcode}`}
                              </div>
                            </div>
                          </TableCell>
                        </Tooltip>
                        <Tooltip
                          title={buildingAttribute.description || ''}
                          arrow
                          placement="top"
                        >
                          <TableCell
                            align="left"
                            style={{ maxWidth: 400, width: 400 }}
                            onClick={() => {
                              viewBuildingAttributes(buildingAttribute.id);
                            }}
                          >
                            <div className="twoRowEllips">
                              {buildingAttribute.description}
                            </div>
                          </TableCell>
                        </Tooltip>
                        {/* <TableCell
                          align="left"
                          style={{ maxWidth: 150, width: 150 }}
                          onClick={() => {
                            viewBuildingAttributes(buildingAttribute.id);
                          }}
                        >
                          <div className="rowEllips">
                            {buildingAttribute.agentPhone}
                          </div>
                        </TableCell> */}
                        <TableCell style={{ maxWidth: 50, width: 50 }}>
                          <EditButton
                            route={`/edit-building-attributes/${buildingAttribute.id}`}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <NoResultsFound />
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};
export default BuildingAttributesList;
