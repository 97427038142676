import { AuthenticationContext } from 'react-adal';
import { CLIENT_ID, TENANT_ID } from './utils/config';

export const adalConfig = {
  clientId: CLIENT_ID,
  endpoints: {
    api: CLIENT_ID,
  },
  cacheLocation: 'localStorage',
  postLogoutRedirectUri: window.location.origin,
  tenant: TENANT_ID,
};
export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);
