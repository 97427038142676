/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import LocationFilter from '../../components/Filters/LocationFilter';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import Pagination from '../../components/Pagination/Pagination';
import { BuildingAttributesContext } from '../../context/BuildingAttributesContext';
import useFilterStyles from '../../components/Styles/useFilterStyles';
import { GlobalContext } from '../../context/GlobalContext';

const BuildingAttributesFilters = () => {
  const {
    page,
    setPage,
    setOffset,
    buildings,
    setBuildings,
    villages,
    setVillages,
    totalBACount,
    buildingSearch,
    setBuildingSearch,
  } = useContext(BuildingAttributesContext);
  const { buildingGroupsList } = useContext(GlobalContext);
  const classes = useFilterStyles();
  const [locationSearch, setLocationSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const placeholder = 'Type a building name';

  const handleClose = () => {
    setAnchorEl(null);
    setLocationSearch(false);
    setOnCheckedLocationButton(false);
  };

  const ClearFilter = () => {
    setAnchorEl('');
    setBuildings([]);
    setVillages([]);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.filerSection}>
      <div className="pageContainer filterContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftFilterIn`}>
              <LocationFilter
                locationSearch={locationSearch}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setLocationSearch={setLocationSearch}
                handleClose={handleClose}
                setOnCheckedLocationButton={setOnCheckedLocationButton}
                OnCheckedLocationButton={OnCheckedLocationButton}
                id={id}
                buildingGroupsList={buildingGroupsList}
                buildings={buildings}
                setBuildings={setBuildings}
                villages={villages}
                setVillages={setVillages}
                buildingAttributes
              />

              <li role="presentation" onClick={ClearFilter}>
                Clear Filter
              </li>
            </ul>
          </div>
          <div className={`${classes.rightFilter} rightFilterPagination`}>
            <Pagination
              page={page}
              setPage={setPage}
              setOffset={setOffset}
              totalTenants={totalBACount}
            />
            <div className={`${classes.exportSerch} exportSerch`}>
              <ul>
                <SearchFilter
                  customerSearch={buildingSearch}
                  setCustomerSearch={setBuildingSearch}
                  placeholder={placeholder}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingAttributesFilters;
