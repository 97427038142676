/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import LocationFilter from '../Filters/LocationFilter';
import { GlobalContext } from '../../context/GlobalContext';
import MeetingRoomFilter from '../Filters/MeetingRoomFilter';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './bookings.css';
import listIcon from '../../images/buttons-new-list.svg';

export const useStyles = makeStyles({
  leftFilterIn: {
    display: 'flex',
    alignItems: 'center',
    '& li': {
      marginRight: '15px',
      color: '#838384',
      fontSize: '14px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      position: 'relative',
      '&:last-child': {
        paddingBottom: '2px',
        borderBottom: '1px solid #979797',
        cursor: 'pointer',
      },
      '& button': {
        color: '#444444',
        backgroundColor: '#f8f8f8',
        padding: '9px 12px 10px',
        fontWeight: '600',
        boxShadow: 'none',
        fontSize: '15px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        borderRadius: 5,
        '&:hover': {
          boxShadow: 'none',
          background: '#f8f8f8',
        },
        '& span': {
          lineHeight: '100%',
          alignItems: 'center',
          display: 'flex',
          textTransform: 'capitalize',

          '& small': {
            marginLeft: 20,
            top: '-2px',
            position: 'relative',
            '& img': {
              '&:last-child': {
                display: 'none',
              },
              '&:nth-child(2)': {
                display: 'none',
              },
            },
          },
        },
      },
    },
  },
  active: {
    '& button': {
      background: '#000 !important',
      color: '#fff !important',
      '& span': {
        '& small': {
          '& img': {
            '&:first-child': {
              display: 'none',
            },
            '&:last-child': {
              display: 'none',
            },
            '&:nth-child(2)': {
              display: 'block !important',
            },
          },
        },
        '& i': {
          display: 'none',
        },
      },
    },
  },

  closeIconBtn: {
    display: 'none',
  },
  valueSelected: {
    '& button': {
      '& span': {
        '& small': {
          '& img': {
            '&:first-child': {
              display: 'none',
            },
            '&:last-child': {
              visibility: 'hidden',
              opacity: '0',
            },
          },
        },
      },
    },
    '& i': {
      display: 'block !important',
      cursor: 'pointer',
    },
  },
  filterMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rightFilter: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 20,
    '& svg': {
      width: 25,
    },
  },
  exportSerch: {
    '& ul': {
      display: 'flex',
      alignItems: 'center',
      '& li': {
        marginLeft: '20px',
      },
    },
  },
  pageListMain: {
    position: 'relative',
  },
  popupBadge: {
    background: '#000000',
    borderRadius: '11px',
    fontSize: '16px',
    color: '#fff',
    width: '50%',
    margin: '0 auto',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    padding: ' 14px 0',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      borderBottom: ' 1px solid rgba(151, 151, 151,0.2)',
      '&:last-child': {
        borderBottom: 'none',
      },
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  scarchClose: {
    '& img': {
      position: 'absolute',
      right: '5px',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'block !important',
      cursor: 'pointer',
      zIndex: 2,
    },
  },
  datePicker: {
    position: 'absolute',
    left: '114%',
  },
});
const BigCalenderFilter = props => {
  const {
    setBuildings,
    buildings,
    setSelectedDate,
    buildingId,
    transformedMrList,
    setDateChange,
    selectedMRs,
    setSelectedMRs,
    setCacheData,
    buidlingLocalStorage,
    setBuidlingLocalStorage,
  } = props;
  const { buildingGroupsList, buildingDataList } = useContext(GlobalContext);
  const classes = useStyles();
  const [transformedList, setTransformedList] = useState(transformedMrList);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openId = open ? 'simple-popover' : undefined;
  const [locationSearch, setLocationSearch] = useState(false);
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const [villages, setVillages] = useState([]);
  const history = useHistory();

  let buildingName;
  if (buidlingLocalStorage) {
    buildingName = buildingDataList.filter(
      item => item.id === buidlingLocalStorage[0],
    );
  }

  const handleClose = () => {
    setAnchorEl(null);
    setLocationSearch(false);
    setOnCheckedLocationButton(false);
  };
  useEffect(() => {
    if (buildings) {
      setAnchorEl(null);
      setLocationSearch(false);
      setOnCheckedLocationButton(false);
    }
  }, [buildings]);
  const filterWeekends = date => {
    // Return false if Saturday or Sunday
    return date.getDay() === 0 || date.getDay() === 6;
  };
  useEffect(() => {
    setTransformedList(transformedMrList);
  }, [transformedMrList]);

  return (
    <div className={classes.filerSection}>
      <div className="pageContainer filterContainer calenderContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftCalenderFilterIn`}>
              <LocationFilter
                bigCalender
                locationSearch={locationSearch}
                buidlingLocalStorage={buidlingLocalStorage}
                setBuidlingLocalStorage={setBuidlingLocalStorage}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setLocationSearch={setLocationSearch}
                handleClose={handleClose}
                setOnCheckedLocationButton={setOnCheckedLocationButton}
                OnCheckedLocationButton={OnCheckedLocationButton}
                id={openId}
                buildingGroupsList={buildingGroupsList}
                setTransformedList={setTransformedList}
                buildings={buildings}
                setBuildings={setBuildings}
                villages={villages}
                setVillages={setVillages}
                selectedBuildingName={
                  buildingName && buildingName[0] && buildingName[0].name
                }
                setSelectedMRs={setSelectedMRs}
                setCacheData={setCacheData}
              />

              <MeetingRoomFilter
                transformedMrList={transformedList}
                selectedMRs={selectedMRs}
                setSelectedMRs={setSelectedMRs}
                selectedBuildingName={
                  buildingName && buildingName[0] && buildingName[0].name
                }
              />

              <div className={`${classes.rightFilter}`}>
                <ul>
                  <li>
                    {(buildingId ||
                      (transformedList && transformedList.length > 0) ||
                      (buidlingLocalStorage &&
                        buidlingLocalStorage.length > 0)) && (
                      <div className="calenderDatePicker">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            autoOk
                            onChange={e => {
                              setDateChange(true);
                              setSelectedDate(e);
                              localStorage.setItem('selectedNewDate', e);
                            }}
                            shouldDisableDate={filterWeekends}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                    )}
                  </li>
                  <li>
                    <img
                      src={listIcon}
                      role="presentation"
                      alt="listIcon"
                      onClick={() => {
                        history.push('/bookinglist');
                      }}
                    />
                  </li>
                </ul>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigCalenderFilter;
