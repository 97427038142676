import React, { useMemo } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from 'quill-image-uploader';
import 'react-quill/dist/quill.snow.css';
import { CLOUDINARY_UPLOAD_PRESET, CLOUD_NAME } from '../../utils/config';
import { cloudinary_upload_api_base_url } from '../ImageUpload/constants';

Quill.register('modules/imageUploader', ImageUploader);
const RichTextEditor = props => {
  const {
    descriptionValue,
    setDescriptionValue,
    setSaveArticleToggle,
    setCreateOfferToggle,
    mediaStorage,
  } = props;

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ size: [] }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }],
          [{ list: 'bullet' }],
          ['link', 'image'],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
      imageUploader: {
        upload: file => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
            formData.append('cloud_name', CLOUD_NAME);
            formData.append('resource_type', 'image');
            formData.append('folder', mediaStorage);
            fetch(`${cloudinary_upload_api_base_url}/${CLOUD_NAME}/upload`, {
              method: 'POST',
              body: formData,
            })
              .then(response => response.json())
              .then(result => {
                console.log(result);
                resolve(result && result.url);
              })
              .catch(error => {
                reject(error);
                alert(error);
              });
          });
        },
      },
    }),
    [],
  );

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'list',
    'link',
    'image',
    'imageBlot',
  ];

  const checkCharacterCount = event => {
    if (descriptionValue.length > 2000000 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  const offers =
    window.location.pathname.includes('edit-offers') ||
    window.location.pathname.includes('create-offers');

  return (
    <div>
      <ReactQuill
        theme="snow"
        value={descriptionValue}
        modules={modules}
        formats={formats}
        onChange={e => {
          if (!offers) {
            setSaveArticleToggle(false);
          } else {
            setCreateOfferToggle(false);
          }
          setDescriptionValue(e);
        }}
        onKeyDown={e => {
          checkCharacterCount(e);
        }}
      />
    </div>
  );
};

export default RichTextEditor;
