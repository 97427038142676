/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { meetingRoomSchema, layoutPopupSchema } from '../../utils/formValidate';
import dowmArw from '../../images/down-arw.svg';
import './editmeetingroom.css';
import FileUpload from '../../components/ImageUpload/FileUpload';
import { GlobalContext } from '../../context/GlobalContext';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';
import editIcon from '../../images/edit-icon.svg';
import BinIcon from '../../images/BinIcon.svg';
import addImage from '../../images/addImage.svg';
import LayoutUpload from '../../components/ImageUpload/LayoutUpload';

const useStyles = makeStyles(theme => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384 !important',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 30px 0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797 !important',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  labelMsg: {
    fontSize: '13px',
    color: '#000000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
  root: {
    '& .MuiTextField-root': {
      marginBottom: theme.spacing(2),
    },
  },

  imageInput: {
    display: 'none',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  dialogPaper: {
    maxWidth: '852px',
    maxHeight: '360px',
  },
  editIcon: {
    top: '-1px',
    cursor: 'pointer',
    margin: '0 auto',
    position: 'relative',
    left: '3px',
  },
  deleteIcon: {
    margin: '0 auto',
    cursor: 'pointer',
    position: 'relative',
    top: '5px',
    height: '15px',
  },
}));

const minTimeBookings = [15, 30, 45, 60, 120, 180, 240, 480];
const roomTypes = [
  { name: 'Single', key: 'Single' },
  { name: 'Combined', key: 'Combined' },
  { name: 'Mergeable', key: 'Mergeable' },
];

const EditMeetingRoom = () => {
  const { setSuccessMessage, buildingsData } = useContext(GlobalContext);
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [equipmentData, setEquipmentData] = useState([]);
  const [userBuildingId, setUserBuildingId] = useState('');
  const [userSelectedBuilding, setUserSelectedBuilding] = useState('');
  const [meetingRoomName, setMeetingRoomName] = useState('');
  const [description, setDescription] = useState('');
  const [minCapacity, setMinCapacity] = useState('');
  const [maxCapacity, setMaxCapacity] = useState('');
  const [userVillage, setUserVillage] = useState('');
  const [selectedEquipement, setSelectedEquipement] = useState([]);
  const [minBookingTime, setMinBookingTime] = useState('');
  const [hourlyRate, setHourlyRate] = useState('');
  const [fullDayRate, setFullDayRate] = useState('');
  const [inActive, setInActive] = useState(false);
  const [inActivePopUp, setInActivePopUp] = useState(false);
  const [available, setAvailable] = useState(false);
  const [editMeetingRoomToggle, setEditMeetingRoomToggle] = useState(false);
  const [imgUrl, setImgUrl] = useState([]);
  const [mrExists, setMrExists] = useState('');
  const [validationError, setValidationError] = useState('');
  const [selectedRoomType, setSelectedRoomType] = useState('');
  const [roomTypeValue, setRoomTypeValue] = useState('');
  const [roomstoMerge, setRoomstoMerge] = useState(false);
  const [meetingRoomsList, setMeetingRoomsList] = useState([]);
  const [meetingRoomSearch] = useState('');
  const [spaceType, setSpaceType] = useState('');
  const [visibility, setVisibility] = useState(true);
  const [statuses] = useState([
    { label: 'Active', key: 'Active', selected: false },
    { label: 'Inactive', key: 'Inactive', selected: false },
  ]);
  const [offset] = useState(0);
  const [sortOrder] = useState('asc');
  const [sortBy] = useState('nameSort');
  const [mergeableRooms, setMergeableRooms] = useState([]);
  const [roomLayoutImg, setRoomLayoutImg] = useState([]);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [layoutName, setLayoutName] = useState('');
  const [turnaroundTime, setTurnaroundTime] = useState('');
  const [layouts, setLayouts] = useState([]);
  const [layoutMaxCapacity, setLayoutMaxCapacity] = useState('');
  const [popupErrMsg, setPopupErrMsg] = useState('');
  const [flag, setFlag] = useState('');
  const selectedEquipements =
    equipmentData &&
    equipmentData.filter(
      item => selectedEquipement && selectedEquipement.indexOf(item.id) !== -1,
    );
  const meetingRoomSearchResult =
    meetingRoomSearch.length > 0 ? meetingRoomSearch : '';
  const finalMrList = meetingRoomsList.filter(
    item => item.roomType === 'Mergeable',
  );

  if (!JSON.parse(localStorage.getItem('rolePermission'))[0].editMeetingRoom) {
    history.push('/meetingrooms-list');
  }
  const mergeableRoomsArray = [];
  mergeableRooms &&
    mergeableRooms.map(item => {
      finalMrList &&
        finalMrList.map(itemValue => {
          if (item === itemValue.id) {
            const exists = mergeableRoomsArray.find(
              itemId => itemId.id === item,
            );
            if (exists) {
              mergeableRoomsArray.push(
                mergeableRoomsArray.filter(
                  object => selectedFeature.indexOf(object.id) > -1,
                ),
              );
            } else {
              mergeableRoomsArray.push({
                id: itemValue.id,
                name: itemValue.name,
              });
            }
          }
        });
    });

  const meetingRoomImages = [];
  imgUrl &&
    imgUrl.map((item, index) => {
      meetingRoomImages.push({
        image: item.image,
        order: index + 1,
        name: item.name,
      });
    });

  useEffect(() => {
    if (userBuildingId) {
      buildingsData &&
        buildingsData.filter(data => {
          if (data.id === userBuildingId) {
            setUserVillage(data.village);
          }
        });
    }
  }, [userSelectedBuilding]);

  useEffect(() => {
    buildingsData &&
      buildingsData.filter(building => {
        if (building.name === userSelectedBuilding) {
          setUserBuildingId(building.id);
        }
      });
  }, [userSelectedBuilding]);

  useEffect(() => {
    const updatedStatus = [];
    statuses.filter(status => {
      if (status.selected) {
        updatedStatus.push(status.key);
      }
      return status.selected !== false;
    });
    const mrPayload = {
      searchText: meetingRoomSearchResult ? meetingRoomSearch : '',
      buildings: [userBuildingId],
      status:
        updatedStatus && updatedStatus.length > 0 ? updatedStatus : ['Active'],
      sortOrder,
      sortBy,
      offset,
      limit: 40,
    };
    if (userBuildingId) {
      setLoading(true);
      ApiClient.apiPost(apiEndPoints.getMeetingRoomsList, mrPayload)
        .then(data => {
          setMeetingRoomsList(data && data.data && data.data.data);
          setLoading(false);
        })
        .catch(error => {
          const err = error;
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
          setLoading(false);
        });
    }
  }, [userBuildingId]);

  useEffect(() => {
    ApiClient.apiGet(apiEndPoints.getEquipment)
      .then(data => {
        setEquipmentData(data && data.data && data.data.data);
      })
      .catch(error => {
        const err = error;
        alert(
          err && err.response && err.response.data && err.response.data.message,
        );
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    ApiClient.apiGet(apiEndPoints.getMeetingRoomId + id)
      .then(data => {
        const meetingRoomData = data && data.data && data.data.data;
        setLayouts(meetingRoomData.roomLayouts || []);
        setVisibility(meetingRoomData?.visibleOnMobile);
        setSpaceType(meetingRoomData?.spaceType || '');
        setLoading(false);
        const equipmentIds = meetingRoomData.equipment.map(item => {
          return item.id;
        });
        setImgUrl(meetingRoomData && meetingRoomData.images);
        setRoomTypeValue(meetingRoomData && meetingRoomData.roomType);
        setSelectedRoomType(
          (meetingRoomData && meetingRoomData.roomType) || '',
        );
        setSelectedEquipement(equipmentIds);
        setUserSelectedBuilding(meetingRoomData.building.name);
        setUserBuildingId(meetingRoomData.building.id);
        if (userSelectedBuilding) {
          buildingsData &&
            buildingsData.filter(building => {
              if (building.name === userSelectedBuilding) {
                setUserBuildingId(building.id);
              }
            });
        }
        setUserVillage(meetingRoomData.building.village);
        setMeetingRoomName(meetingRoomData.name);
        const combinedIds = [];
        meetingRoomData &&
          meetingRoomData.combinedMeetingRooms &&
          meetingRoomData &&
          meetingRoomData.combinedMeetingRooms.map(item => {
            combinedIds.push(item.id);
          });
        setMergeableRooms(combinedIds || []);
        setDescription(meetingRoomData.description);
        setMaxCapacity(meetingRoomData.maxCapacity);
        setMinCapacity(meetingRoomData.minCapacity);
        setMinBookingTime(meetingRoomData.minimumBookingTime);
        setHourlyRate(meetingRoomData.hourlyPrice);
        setFullDayRate(meetingRoomData.fullDayPrice);
        meetingRoomData.status === 'Active'
          ? setAvailable(true)
          : setInActive(true);
      })
      .catch(error => {
        const err = error;
        alert(
          err && err.response && err.response.data && err.response.data.message,
        );
        setLoading(false);
      });
  }, []);

  let timeHour;

  const generateUniqueId = () => {
    return uuidv4();
  };
  const addMeetingLayout = () => {
    setLayoutMaxCapacity(maxCapacity);
    setOpenPopUp(true);
  };
  const handleDialogClose = () => {
    setLayoutName('');
    setTurnaroundTime('');
    setLayoutMaxCapacity('');
    setRoomLayoutImg([]);
    setPopupErrMsg('');
    setFlag('');
    setOpenPopUp(false);
  };
  const handleSaveLayout = () => {
    layoutPopupSchema
      .validate({
        minCapacity: minCapacity,
        maxCapacityField: maxCapacity,
        layoutName,
        layoutMaxCapacity: Number(layoutMaxCapacity),
        turnaroundTime,
        roomLayoutImg,
      })
      .then(() => {
        if (!flag) {
          const layoutId = generateUniqueId();
          setLayouts([
            ...layouts,
            {
              id: layoutId,
              name: layoutName,
              maxLayoutCapacity: Number(layoutMaxCapacity),
              turnAroundTime: Number(turnaroundTime),
              layoutImage: roomLayoutImg[0].imageUrl,
            },
          ]);
          setLayoutName('');
          setTurnaroundTime('');
          setLayoutMaxCapacity('');
          setRoomLayoutImg([]);
          setPopupErrMsg('');
          setOpenPopUp(false);
        } else {
          const updatedLayout =
            layouts &&
            layouts.map(item => {
              if (item.id === flag) {
                item.id = flag;
                item.name = layoutName;
                item.maxLayoutCapacity = Number(layoutMaxCapacity);
                item.turnAroundTime = Number(turnaroundTime);
                item.layoutImage = roomLayoutImg[0].imageUrl || roomLayoutImg;
              }
              return item;
            });
          setLayouts(updatedLayout);
          setFlag('');
          setOpenPopUp(false);
        }
      })
      .catch(error => {
        setPopupErrMsg(error);
      });
  };
  const maxCapacityLayout = e => {
    const re = /^[0-9]+$/;
    if (
      e.target.value === '' ||
      (re.test(e.target.value) && e.target.value > 0)
    ) {
      setLayoutMaxCapacity(e.target.value);
    }
  };
  const EditLayoutHandler = layout => () => {
    setLayoutName(layout.name);
    setTurnaroundTime(layout.turnAroundTime);
    setLayoutMaxCapacity(layout.maxLayoutCapacity);
    setRoomLayoutImg([{ imageUrl: layout.layoutImage }]);
    setFlag(layout.id);
    setOpenPopUp(true);
    setPopupErrMsg('');
  };
  const deleteLayoutHandler = layout => () => {
    const arrayOfObjects = layouts.filter(obj => obj.id !== layout.id);
    setLayouts(arrayOfObjects);
  };

  const selectionRenderer = values => {
    // change the default comma separated rendering
    return <span style={{ color: '#ff4081' }}>{values.join('; ')}</span>;
  };
  const equipmentLayoutText = `Names${
    selectedEquipement.length > 1 ? ` (${selectedEquipement.length})` : ''
  }`;

  useEffect(() => {
    meetingRoomSchema
      .validate({
        building: userSelectedBuilding,
        village: userVillage,
        meetingRoom: meetingRoomName,
        spaceType: spaceType,
        meetingRoomType: selectedRoomType,
        mergeMeetingRoom: mergeableRooms.toString(),
        descriptionFiled: description,
        maxCapacityField: maxCapacity,
        minCapacity: minCapacity,
        equipmetFiled: selectedEquipement,
        minBookingTimeField: minBookingTime,
        hourlyRateFiled: hourlyRate,
        fullDayRateFiled: fullDayRate,
        imageUploadValidation: imgUrl,
        layouts: layouts,
      })
      .then(() => {
        if (
          editMeetingRoomToggle &&
          selectedEquipement.length > 0 &&
          (selectedRoomType === 'Combined'
            ? mergeableRoomsArray.length > 0
            : [])
        ) {
          setLoading(true);
          const payload = {
            id,
            building: {
              id: userBuildingId,
              name: userSelectedBuilding,
              village: userVillage,
            },
            name: meetingRoomName,
            description,
            minCapacity: Number(minCapacity),
            maxCapacity: Number(maxCapacity),
            hourlyPrice: Number(hourlyRate),
            fullDayPrice: Number(fullDayRate),
            status: available ? 'Active' : 'Inactive',
            minimumBookingTime: minBookingTime,
            equipment: selectedEquipements,
            images: meetingRoomImages,
            visibleOnMobile: visibility,
            spaceType: spaceType,
            roomLayouts: layouts,
          };
          if (spaceType === 'meetingRoom') {
            payload.roomType = selectedRoomType;
            payload.combinedMeetingRooms = mergeableRoomsArray;
          }
          ApiClient.apiPut(apiEndPoints.getMeetingRoomId + id, payload)
            .then(data => {
              const updateMsg =
                data && data.data.message
                  ? `${meetingRoomName} has been updated successfully!`
                  : '';
              setSuccessMessage(updateMsg);
              history.push('/meetingrooms-list');
            })
            .catch(error => {
              setEditMeetingRoomToggle(false);
              setMrExists(error.response.status);
              const err = error;
              alert(
                err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.message,
              );
              setLoading(false);
            });
        }
      })
      .catch(error => {
        if (editMeetingRoomToggle) {
          setValidationError(error);
          setEditMeetingRoomToggle(false);
        }
      });
  }, [
    userSelectedBuilding,
    meetingRoomName,
    userVillage,
    description,
    maxCapacity,
    selectedEquipement,
    minBookingTime,
    hourlyRate,
    fullDayRate,
    editMeetingRoomToggle,
    imgUrl,
    spaceType,
  ]);

  const errorType = validationError && validationError.type;
  const errorFieldName = validationError && validationError.path;
  const errorFieldValue = validationError && validationError.value;
  const popErrorType = popupErrMsg && popupErrMsg.type;
  const popErrorFieldName = popupErrMsg && popupErrMsg.path;
  const popErrorFieldValue = popupErrMsg && popupErrMsg.value;
  const errorMassage = popupErrMsg && popupErrMsg.message;

  const breadCrumbs = {
    listBreadCrumbPath: '/meetingrooms-list',
    listBreadCrumbTitle: 'Meeting Rooms',
    viewBreadCrumbPath: '/viewmeetingroomlist/',
    viewBreadCrumbTitle: 'View Meeting Room',
    editBreadCrumbPath: '/editmeetingroom/',
    editBreadCrumbTitle: ' Edit Meeting Room',
  };

  useEffect(() => {
    if (
      (roomTypeValue === 'Combined' &&
        (selectedRoomType === 'Single' || selectedRoomType === 'Mergeable')) ||
      (roomTypeValue === 'Mergeable' && selectedRoomType === 'Single')
    ) {
      setRoomstoMerge(true);
    } else {
      setRoomstoMerge(false);
    }
  }, [
    (roomTypeValue === 'Combined' && selectedRoomType !== 'Combined') ||
      (roomTypeValue === 'Mergeable' && selectedRoomType === 'Single'),
  ]);

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addMeetingRoomUserContainer">
            <div className={classes.addUserForm}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Meeting Room Details</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id={userBuildingId}
                      className={classes.formLabel}
                      value={userSelectedBuilding}
                    >
                      Building*
                    </InputLabel>

                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      id={userBuildingId}
                      value={userSelectedBuilding}
                      className={classes.formSelectBox}
                      onChange={e => {
                        setMergeableRooms([]);
                        setUserSelectedBuilding(e.target.value);
                      }}
                    >
                      {buildingsData &&
                        buildingsData.map(building => {
                          return (
                            <MenuItem value={building.name} key={building.id}>
                              {building.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'building') ||
                    (errorFieldValue && errorFieldValue.building === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        userSelectedBuilding === '' && 'Building is required.'
                      }
                    >
                      {userSelectedBuilding === '' && 'Building is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li
                  className={`${classes.InputSection} ${classes.InputSectionReadonly}`}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                      value={userVillage}
                    >
                      Village*
                    </InputLabel>
                    <Input
                      type="text"
                      autoComplete
                      readOnly
                      inputProps={{ 'aria-label': 'description' }}
                      value={userVillage}
                    />
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'village') ||
                    (errorFieldValue && errorFieldValue.village === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={userVillage === '' && 'Village is required.'}
                    >
                      {userVillage === '' && 'Village is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li
                  className={`${classes.InputSection} ${classes.InputSectionReadonly}`}
                >
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                    >
                      Meeting Room Name*
                    </InputLabel>
                    <Input
                      type="text"
                      autoComplete
                      inputProps={{ 'aria-label': 'description' }}
                      value={meetingRoomName}
                      onChange={e => {
                        setMeetingRoomName(e.target.value);
                      }}
                    />
                  </FormControl>
                  {((errorType === 'min' && errorFieldName === 'meetingRoom') ||
                    mrExists.toString() === '409' ||
                    (errorType === 'matches' &&
                      errorFieldName === 'meetingRoom') ||
                    (errorFieldValue &&
                      errorFieldValue.meetingRoom === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        meetingRoomName === ''
                          ? meetingRoomName === '' &&
                            'Meeting Room Name is required.'
                          : (errorType === 'min' &&
                              'Meeting room with that name already exists in the building') ||
                            (mrExists.toString() &&
                              'Meeting room Email already exists') ||
                            (errorType === 'matches' &&
                              'should not contain any special characters')
                      }
                    >
                      {meetingRoomName === ''
                        ? meetingRoomName === '' &&
                          'Meeting Room Name is required.'
                        : (meetingRoomName.length < 3 &&
                            'Must be at least 3 characters') ||
                          (mrExists.toString() &&
                            'Meeting room with that name already exists in the building') ||
                          (errorType === 'matches' &&
                            'should not contain any special characters')}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className={`${classes.InputSection}`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Space Type*
                    </InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      className={classes.formSelectBox}
                      value={spaceType}
                      onChange={e => {
                        setSpaceType(e.target.value);
                      }}
                    >
                      <MenuItem value="meetingRoom">Meeting Room</MenuItem>
                      <MenuItem value="eventSpace">Event Space</MenuItem>
                      <MenuItem value="privateHireSpace">
                        Private Hire Space
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'spaceType') ||
                    (errorFieldValue && errorFieldValue.spaceType === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      error={spaceType === '' && 'Space Type is required.'}
                    >
                      {spaceType === '' && 'Space Type is required.'}
                    </FormHelperText>
                  )}
                </li>
                {spaceType === 'meetingRoom' ? (
                  <>
                    <li>
                      <FormControl className={classes.formControl}>
                        <InputLabel
                          shrink
                          id="demo-simple-select-placeholder-label-label"
                          className={classes.formLabel}
                        >
                          Room type*
                        </InputLabel>
                        <Select
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          className={classes.formSelectBox}
                          value={selectedRoomType}
                          onChange={e => {
                            setSelectedRoomType(e.target.value);
                            setEditMeetingRoomToggle(false);
                          }}
                        >
                          {roomTypes.map(item => {
                            return (
                              <MenuItem
                                className="multiSelectMenu"
                                value={item.name}
                                key={item.key}
                              >
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {((errorType === 'required' &&
                        errorFieldName === 'meetingRoomType') ||
                        (errorFieldValue &&
                          errorFieldValue.meetingRoomType === '')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          error={
                            selectedRoomType === '' && 'Room Type is required.'
                          }
                        >
                          {selectedRoomType === '' && 'Room Type is required.'}
                        </FormHelperText>
                      )}
                    </li>
                    {selectedRoomType === 'Combined' && (
                      <li>
                        <FormControl className={classes.formControl}>
                          <InputLabel
                            shrink
                            id="demo-simple-select-placeholder-label-label"
                            className={classes.formLabel}
                          >
                            Rooms to merge*
                          </InputLabel>
                          <Select
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                            className={classes.formSelectBox}
                            multiple
                            value={mergeableRooms}
                            onChange={e => {
                              setMergeableRooms(e.target.value);
                              setEditMeetingRoomToggle(false);
                            }}
                          >
                            {finalMrList.map(item => {
                              return (
                                <MenuItem
                                  className="multiSelectMenu"
                                  value={item.id}
                                  key={item.id}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {(errorFieldName === 'mergeMeetingRoom' ||
                          (errorFieldValue &&
                            errorFieldValue.mergeMeetingRoom &&
                            errorFieldValue.mergeMeetingRoom === '')) && (
                          <FormHelperText
                            className={classes.errorMsg}
                            error={
                              mergeableRooms.length === 0 &&
                              'Rooms to merge is required'
                            }
                          >
                            {mergeableRooms.length === 0 &&
                              'Rooms to merge is required'}
                          </FormHelperText>
                        )}
                      </li>
                    )}
                  </>
                ) : (
                  ''
                )}
              </ul>
              <Divider className="formDivider" light />
              <ul className={`${classes.InputSection} textareaSection`}>
                <li>
                  <span>Description*</span>
                  <textarea
                    type="text"
                    maxLength="1500"
                    inputProps={{ 'aria-label': 'description' }}
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value);
                    }}
                  />
                  <div className="descriptionBox">{`${description.length}/1500`}</div>
                  {((errorType === 'min' &&
                    errorFieldName === 'descriptionFiled') ||
                    (errorFieldValue &&
                      errorFieldValue.descriptionFiled === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        description === ''
                          ? description === '' && 'Description is required.'
                          : description.length < 3 &&
                            'Must be at least 3 characters'
                      }
                    >
                      {description === ''
                        ? description === '' && 'Description is required.'
                        : description.length < 3 &&
                          'Must be at least 3 characters'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className={`${classes.InputSection} InputSectionMeeting`}>
                <li>
                  <span>Min Capacity*</span>
                  <Input
                    type="text"
                    value={minCapacity}
                    onChange={e => {
                      const re = /^[0-9]+$/;
                      if (
                        e.target.value === '' ||
                        (re.test(e.target.value) && e.target.value > 0)
                      ) {
                        setMinCapacity(e.target.value);
                      }
                    }}
                    inputProps={{
                      'aria-label': 'description',
                      minLength: '1',
                      maxLength: '3',
                      autoComplete: 'false',
                    }}
                  />
                  {((errorType === 'required' &&
                    errorFieldName === 'minCapacity') ||
                    (errorFieldValue &&
                      errorFieldValue.minCapacity === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={minCapacity === '' && 'Min Capacity is required.'}
                    >
                      {minCapacity === '' && 'Min Capacity is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <span>Max Capacity*</span>
                  <Input
                    type="text"
                    value={maxCapacity}
                    onChange={e => {
                      const re = /^[0-9]+$/;
                      if (
                        e.target.value === '' ||
                        (re.test(e.target.value) && e.target.value > 0)
                      ) {
                        setMaxCapacity(e.target.value);
                      }
                    }}
                    inputProps={{
                      'aria-label': 'description',
                      minLength: '1',
                      maxLength: '3',
                      autoComplete: 'false',
                    }}
                  />
                  {((errorType === 'required' &&
                    errorFieldName === 'maxCapacityField') ||
                    (errorFieldValue &&
                      errorFieldValue.maxCapacityField === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={maxCapacity === '' && 'Max Capacity is required.'}
                    >
                      {maxCapacity === '' && 'Max Capacity is required.'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Room Layouts*</FormLabel>
                </li>
              </ul>
              <div>
                <Box className="layoutBoxStyleEdit">
                  <TableContainer>
                    <Table>
                      <TableHead className="tableHeader">
                        <TableRow>
                          <TableCell className="tableHead">
                            Layout Name*
                          </TableCell>
                          <TableCell className="tableHead">
                            Max Capacity (Layout)*
                          </TableCell>
                          <TableCell className="tableHead">
                            Turn Around Time*
                          </TableCell>
                          <TableCell className="tableHead">Image*</TableCell>
                          <TableCell
                            className={classes.accountBoxImgColumn}
                            align="center"
                            style={{ maxWidth: 50, width: 50 }}
                          />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {layouts.map((row, index) => (
                          <TableRow>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.maxLayoutCapacity}</TableCell>
                            <TableCell>{row.turnAroundTime}{" "}Minute</TableCell>
                            <TableCell
                              onClick={() => {
                                window.open(row.layoutImage);
                              }}
                            >
                              <img
                                src={row.layoutImage}
                                alt={`${index}`}
                                className="layoutImg"
                              />
                            </TableCell>
                            <TableCell
                              className={classes.accountBoxImgColumn}
                              align="center"
                            >
                              <img
                                src={editIcon}
                                className={classes.editIcon}
                                alt="editIcon"
                                role="presentation"
                                onClick={EditLayoutHandler(row)}
                              />
                              <img
                                src={BinIcon}
                                className={classes.deleteIcon}
                                alt="editIcon"
                                role="presentation"
                                onClick={deleteLayoutHandler(row)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="addLayoutBtn">
                    <span
                      className={`layoutBrd ${
                        minCapacity === '' || maxCapacity === ''
                          ? 'd_none'
                          : 'd_flex'
                      }`}
                    >
                      <img
                        onClick={addMeetingLayout}
                        src={addImage}
                        role="presentation"
                        alt="roomLayoutImg"
                        id="BuildingImage"
                      />
                    </span>
                  </div>
                  <div className="layoutNote">
                    Enter min capacity and max capacity before adding a layout.
                  </div>
                </Box>
                {((errorType === 'required' &&
                  errorFieldName === 'roomLayoutsFields') ||
                  (errorFieldValue &&
                    errorFieldValue.layouts.length === 0)) && (
                  <FormHelperText
                    className={classes.errorMsg}
                    component="div"
                    error={layouts.length === 0 && 'Min 1 Layout is required.'}
                  >
                    {layouts.length === 0 && 'Min 1 Layout is required.'}
                  </FormHelperText>
                )}
              </div>
              <Divider className="formDivider" light />
              <ul className={`${classes.InputSection} InputSectionMeeting`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                    >
                      Equipment*
                    </InputLabel>
                    <Select
                      className={classes.formSelectBox}
                      multiple
                      floatingLabelText={equipmentLayoutText}
                      value={selectedEquipement}
                      id="equipment"
                      onChange={e => {
                        setSelectedEquipement(e.target.value);
                      }}
                      selectionRenderer={selectionRenderer}
                    >
                      {equipmentData &&
                        equipmentData.map(euipment => {
                          return (
                            <MenuItem
                              key={euipment.id}
                              insetChildren
                              checked={euipment && euipment.name}
                              value={euipment.id}
                              primaryText={euipment.name}
                            >
                              {euipment.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'min' &&
                    errorFieldName === 'equipmetFiled') ||
                    (errorFieldValue &&
                      errorFieldValue.equipmetFiled.length === 0)) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        selectedEquipement.length === 0 &&
                        'Equipment is required.'
                      }
                    >
                      {selectedEquipement.length === 0 &&
                        'Equipment is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                    >
                      Minimum Booking Time*
                    </InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      className={classes.formSelectBox}
                      value={minBookingTime}
                      onChange={e => {
                        setMinBookingTime(e.target.value);
                      }}
                    >
                      {minTimeBookings &&
                        minTimeBookings.map(time => {
                          if (time === 60) {
                            timeHour = '1 hour';
                          } else if (time === 120) {
                            timeHour = '2 hours';
                          } else if (time === 180) {
                            timeHour = '3 hours';
                          } else if (time === 240) {
                            timeHour = '4 hours';
                          } else if (time === 480) {
                            timeHour = '8 hours';
                          } else {
                            timeHour = `${time} mins`;
                          }
                          return (
                            <MenuItem value={time} key={time}>
                              {timeHour}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'minBookingTimeField') ||
                    (errorFieldValue &&
                      errorFieldValue.minBookingTimeField === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        minBookingTime === '' &&
                        'Minimum Booking Time is required.'
                      }
                    >
                      {minBookingTime === '' &&
                        'Minimum Booking Time is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <span>Hourly Rate*</span>
                  <Input
                    type="text"
                    inputProps={{ 'aria-label': 'description' }}
                    value={hourlyRate}
                    onChange={e => {
                      setHourlyRate(e.target.value);
                    }}
                    onKeyPress={e => {
                      if (e.key !== 'Enter' && isNaN(parseInt(e.key, 10)))
                        e.preventDefault();
                    }}
                  />
                  <div className="vatText">(excluding VAT)</div>
                  {((errorType === 'required' &&
                    errorFieldName === 'hourlyRateFiled') ||
                    (errorFieldValue &&
                      errorFieldValue.hourlyRateFiled === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={hourlyRate === '' && 'Hourly Price is required.'}
                    >
                      {hourlyRate === '' && 'Hourly Price is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <span>Full Day Rate*</span>
                  <Input
                    type="text"
                    inputProps={{ 'aria-label': 'description' }}
                    value={fullDayRate}
                    onChange={e => {
                      setFullDayRate(e.target.value);
                    }}
                    onKeyPress={e => {
                      if (e.key !== 'Enter' && isNaN(parseInt(e.key, 10)))
                        e.preventDefault();
                    }}
                  />
                  <div className="vatText">(excluding VAT)</div>
                  {((errorType === 'required' &&
                    errorFieldName === 'fullDayRateFiled') ||
                    (errorFieldValue &&
                      errorFieldValue.fullDayRateFiled === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        fullDayRate === '' && 'Full Day Price is required.'
                      }
                    >
                      {fullDayRate === '' && 'Full Day Price is required.'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Media</FormLabel>
                </li>
              </ul>
              <li>
                <FileUpload
                  values={{
                    errorType,
                    errorFieldName,
                    errorFieldValue,
                    imgUrl,
                    setImgUrl,
                  }}
                />
              </li>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul id="StatusInAddMeetingRoom">
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        <FormControlLabel
                          control={<Radio />}
                          label="Available"
                          checked={available}
                          onChange={() => {
                            setAvailable(true);
                            setInActive(false);
                          }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Inactive"
                          checked={inActive}
                          onChange={() => {
                            setAvailable(false);
                            setInActivePopUp(true);
                            setInActive(true);
                          }}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <div id="showOnMobileButtonArea">
                    <ul>
                      <li id="showOnMobileButtonName">
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink className={classes.formLabel}>
                            Show On Mobile
                          </InputLabel>
                        </FormControl>
                      </li>
                      <li className="YesNoSwitchBtn">
                        <Typography className="YesNoBtn">No</Typography>
                        <Switch
                          disabled={!available}
                          onChange={() => setVisibility(!visibility)}
                          checked={visibility}
                          color="primary"
                        />
                        <Typography className="YesNoBtn">Yes</Typography>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <SaveAndCancelButtons
                setToggle={setEditMeetingRoomToggle}
                route="/meetingrooms-list"
              />
              <WarningPopup
                inActivePopUp={inActivePopUp || roomstoMerge}
                setRoomstoMerge={setRoomstoMerge}
                setInActivePopUp={setInActivePopUp}
                setAvailable={setAvailable}
                setInActive={setInActive}
                popupMessage={
                  (roomTypeValue === 'Combined' &&
                    (selectedRoomType === 'Single' ||
                      selectedRoomType === 'Mergeable')) ||
                  (roomTypeValue === 'Mergeable' &&
                    selectedRoomType === 'Single') ? (
                    <>
                      <h2>
                        Any existing combined room relationship will be lost.
                      </h2>
                      <p>Do you want to proceed?</p>
                    </>
                  ) : (
                    inActivePopUp && (
                      <>
                        <h2>This meeting room will be made inactive.</h2>
                        <p>Do you want to proceed?</p>
                      </>
                    )
                  )
                }
                setSelectedRoomType={setSelectedRoomType}
                roomTypeValue={roomTypeValue}
              />
              <Dialog
                classes={{ paper: classes.dialogPaper }}
                open={openPopUp}
                onClose={handleDialogClose}
              >
                <DialogContent className={classes.root}>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <ul
                        className={`${classes.InputSection} InputSectionMeeting`}
                      >
                        <li>
                          <span>Layout Name*</span>
                          <Input
                            type="text"
                            value={layoutName}
                            onChange={e => setLayoutName(e.target.value)}
                            inputProps={{
                              maxLength: '30',
                            }}
                          />
                          {((popErrorType === 'required' &&
                            popErrorFieldName === 'layoutName') ||
                            (popErrorFieldValue &&
                              popErrorFieldValue.layoutName === '')) && (
                            <FormHelperText
                              className={classes.errorMsg}
                              component="div"
                              error={
                                layoutName === '' && 'Layout Name is required.'
                              }
                            >
                              {layoutName === '' && 'Layout Name is required.'}
                            </FormHelperText>
                          )}
                        </li>
                      </ul>
                    </Grid>
                    <Grid item md={6}>
                      <ul
                        className={`${classes.InputSection} InputSectionMeeting`}
                      >
                        <li>
                          <span>Max Capacity For The Layout*</span>
                          <Input
                            type="text"
                            value={layoutMaxCapacity}
                            onChange={maxCapacityLayout}
                            inputProps={{
                              'aria-label': 'description',
                              minLength: '1',
                              maxLength: '3',
                              autoComplete: 'false',
                            }}
                          />
                          {(((popErrorType === 'min' ||
                            popErrorType === 'max') &&
                            popErrorFieldName === 'layoutMaxCapacity') ||
                            popErrorFieldValue?.layoutMaxCapacity === '') && (
                            <FormHelperText
                              className={classes.errorMsg}
                              component="div"
                              error={errorMassage}
                            >
                              {popErrorFieldValue?.layoutMaxCapacity === 0
                                ? 'Max Capacity is required.'
                                : errorMassage}
                            </FormHelperText>
                          )}
                        </li>
                      </ul>
                    </Grid>
                    <Grid item md={6}>
                      <ul className={`${classes.InputSection}`}>
                        <li>
                          <FormControl className={classes.formControl}>
                            <InputLabel shrink className={classes.formLabel}>
                              Turn Around Time*
                            </InputLabel>
                            <Select
                              MenuProps={{
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                              }}
                              className={classes.formSelectBox}
                              value={turnaroundTime}
                              onChange={e => {
                                setTurnaroundTime(e.target.value);
                              }}
                            >
                              <MenuItem value={15}>15 Minute</MenuItem>
                              <MenuItem value={30}>30 Minute</MenuItem>
                              <MenuItem value={45}>45 Minute</MenuItem>
                            </Select>
                          </FormControl>
                          {((popErrorType === 'required' &&
                            popErrorFieldName === 'turnaroundTime') ||
                            (popErrorFieldValue &&
                              popErrorFieldValue.turnaroundTime === '')) && (
                            <FormHelperText
                              className={classes.errorMsg}
                              error={
                                turnaroundTime === '' &&
                                'Turn Around Time is required.'
                              }
                            >
                              {turnaroundTime === '' &&
                                'Turn Around Time is required.'}
                            </FormHelperText>
                          )}
                        </li>
                      </ul>
                    </Grid>
                    <Grid item md={7}>
                      <li>
                        <LayoutUpload
                          values={{
                            popErrorType,
                            popErrorFieldName,
                            popErrorFieldValue,
                            roomLayoutImg,
                            setRoomLayoutImg,
                          }}
                        />
                      </li>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions
                  className={`${classes.formButton} popupFormButton`}
                >
                  <Button
                    className="cancelBtnForLayout"
                    onClick={handleDialogClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="saveButtonForLayout"
                    autoFocus
                    onClick={handleSaveLayout}
                  >
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditMeetingRoom;
