/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './mrbooking.css';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '../../images/down-arw.svg';
import cartAdd from '../../images/cart-add.svg';
import cartRemove from '../../images/cart-remove.svg';
import apiEndPoints from '../../helpers/apiEndPoints';
import ApiClient from '../../helpers/ApiClient';
import { MrBookingContext } from '../../context/MRBookingContext';
import { logOut } from '../../utils/logOut';

const useStyles = makeStyles({
  BookingStepForm: {
    margin: '0 auto',
    padding: '30px 0',
  },

  lineformButton: {
    marginBottom: '0px !important',
    marginTop: '20px !important',
    width: '100% !important',
    '& hr': {
      margin: '24px auto',
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
});

export const ExpandIcon = () => {
  return (
    <div className="expandIcon">
      <img src={ExpandMoreIcon} alt="ExpandMoreIcon" />
    </div>
  );
};

const CateringBooking = () => {
  const classes = useStyles();
  const {
    mrBookingId,
    setActiveStep,
    cartItems,
    setCartItems,
    createCateringToggle,
    setCreateCateringToggle,
    iscustomerDetailsExist,
    finalStartDate,
    finalEndDate,
  } = useContext(MrBookingContext);
  const [categoryMenuList, setCategoryMenuList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const payload = {
      status: 'Available',
      startTime: finalStartDate,
      endTime: finalEndDate,
    };
    ApiClient.apiPost(apiEndPoints.getCateringCategories, payload)
      .then(data => {
        setCategoryMenuList(data && data.data && data.data.data.orderMenu);
        setLoading(false);
      })
      .catch(error => {
        alert(error, 'error');
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (mrBookingId && createCateringToggle) {
      setLoading(true);
      const payload = {
        orderMenu: cartItems,
      };
      ApiClient.apiPut(
        `${apiEndPoints.createMrBooking}/${mrBookingId}/catering`,
        payload,
      )
        .then(() => {
          setLoading(false);
          setActiveStep(
            iscustomerDetailsExist
              ? prevActiveStep => prevActiveStep + 2
              : prevActiveStep => prevActiveStep + 1,
          );
          setCreateCateringToggle(false);
        })
        .catch(error => {
          alert(error, 'error');
          setLoading(false);
          setCreateCateringToggle(false);
        });
    }
  }, [mrBookingId, createCateringToggle]);

  const onAdd = product => {
    const exist = cartItems.find(x => x.itemId === product.itemId);
    if (exist) {
      setCartItems(
        cartItems.map(x =>
          x.itemId === product.itemId
            ? {
                ...exist,
                quantity: exist.quantity < 30 ? exist.quantity + 1 : 30,
              }
            : x,
        ),
      );
    } else {
      setCartItems([...cartItems, { ...product, quantity: 1 }]);
    }
  };

  const onRemove = product => {
    const exist = cartItems.find(x => x.itemId === product.itemId);
    if (exist && exist.quantity === 1) {
      setCartItems(cartItems.filter(x => x.itemId !== product.itemId));
    } else {
      setCartItems(
        cartItems.map(x =>
          x.itemId === product.itemId
            ? {
                ...exist,
                quantity: exist.quantity > 0 ? exist.quantity - 1 : 0,
              }
            : x,
        ),
      );
    }
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.BookingStepForm}>
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className="BookingStepFormAccrd">
          {categoryMenuList && categoryMenuList.length > 0 ? (
            <>
              {categoryMenuList &&
                categoryMenuList.map(categoryMenu => (
                  <>
                    <Accordion square key={categoryMenu.categoryId}>
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={<ExpandIcon />}
                      >
                        <Typography>
                          {categoryMenu.categoryName.toLowerCase()}
                        </Typography>
                      </AccordionSummary>
                      {categoryMenu &&
                        categoryMenu.orderItems &&
                        categoryMenu.orderItems.map(orderItem => (
                          <>
                            <AccordionDetails
                              className="cateringAccdnCont"
                              key={orderItem.itemId}
                            >
                              <ul className="cateringContMain">
                                <li>
                                  <div className="cateringCont">
                                    <h3>
                                      {orderItem.itemName}{' '}
                                      <span>{`£${orderItem.price.toFixed(
                                        2,
                                      )}`}</span>
                                    </h3>
                                    <p>{orderItem.itemDescription}.</p>
                                  </div>
                                  <div className="cateringAddCart">
                                    <ul className="cartItemsNo">
                                      <li>
                                        <Button
                                          onClick={() => {
                                            onRemove({
                                              ...orderItem,
                                              itemCategoryId:
                                                categoryMenu.categoryId,
                                            });
                                          }}
                                        >
                                          <img
                                            src={cartRemove}
                                            alt="cartRemove"
                                          />
                                        </Button>
                                      </li>
                                      <li>
                                        <div className="cartItemValue">
                                          {cartItems &&
                                          cartItems.find(
                                            x => x.itemId === orderItem.itemId,
                                          )
                                            ? cartItems &&
                                              cartItems.map(
                                                cartItem =>
                                                  cartItem.itemId ===
                                                    orderItem.itemId &&
                                                  cartItem.quantity,
                                              )
                                            : orderItem.quantity}
                                        </div>
                                      </li>
                                      <li>
                                        <Button
                                          value={orderItem.itemName}
                                          onClick={() => {
                                            onAdd({
                                              ...orderItem,
                                              itemCategoryId:
                                                categoryMenu.categoryId,
                                            });
                                          }}
                                        >
                                          <img src={cartAdd} alt="cartAdd" />
                                        </Button>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </ul>
                            </AccordionDetails>
                          </>
                        ))}
                    </Accordion>
                  </>
                ))}
            </>
          ) : (
            <div className="noResult catBooknoResult">
              <h2>No Menu Found</h2>
              <p>For the selected time interval.</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
export default CateringBooking;
