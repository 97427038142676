/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import LocationFilter from '../../components/Filters/LocationFilter';
import DateRangeFilter from '../../components/Filters/DateRangeFilter';
import StatusFilter from '../../components/Filters/StatusFilter';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import Pagination from '../../components/Pagination/Pagination';
import useFilterStyles from '../../components/Styles/useFilterStyles';
import { EventsContext } from '../../context/EventsContext';
import { GlobalContext } from '../../context/GlobalContext';

const EventsFilter = () => {
  const {
    setBuildings,
    setVillages,
    eventsSearch,
    setEventsSearch,
    buildings,
    villages,
    setStatuses,
    statuses,
    selectedStatus,
    totalEventsCount,
    setOffset,
    page,
    setPage,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
  } = useContext(EventsContext);
  const { buildingGroupsList } = useContext(GlobalContext);
  const classes = useFilterStyles();
  const [locationSearch, setLocationSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusSearch, setStatusSearch] = useState(false);
  const [dateRangeSearch, setDateRangeSearch] = useState(false);
  const [onSelectDateRangeButton, setOnSelectDateRangeButton] = useState('');
  const [onCheckStatusButton, setOnCheckStatusButton] = useState('');
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const placeholder = 'Type an event name or host name';

  const handleClose = () => {
    setAnchorEl(null);
    setLocationSearch(false);
    setDateRangeSearch(null);
    setOnCheckStatusButton(false);
    setOnSelectDateRangeButton(false);
    setStatusSearch(null);
    setOnCheckedLocationButton(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const ClearFilter = () => {
    setStatuses([
      { label: 'Published', key: 'Published', selected: false },
      { label: 'Unpublished', key: 'Unpublished', selected: false },
    ]);
    setAnchorEl('');
    setBuildings([]);
    setVillages([]);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  return (
    <div className={classes.filerSection}>
      <div className="pageContainer filterContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftFilterIn`}>
              <LocationFilter
                locationSearch={locationSearch}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setLocationSearch={setLocationSearch}
                handleClose={handleClose}
                setOnCheckedLocationButton={setOnCheckedLocationButton}
                OnCheckedLocationButton={OnCheckedLocationButton}
                id={id}
                buildingGroupsList={buildingGroupsList}
                buildings={buildings}
                villages={villages}
                setVillages={setVillages}
                setBuildings={setBuildings}
              />
              <DateRangeFilter
                values={{
                  id,
                  handleClose,
                  anchorEl,
                  setAnchorEl,
                  selectedStartDate,
                  setSelectedStartDate,
                  selectedEndDate,
                  setSelectedEndDate,
                  dateRangeSearch,
                  setDateRangeSearch,
                  onSelectDateRangeButton,
                  setOnSelectDateRangeButton,
                }}
                title="Event Date"
              />
              <StatusFilter
                values={{
                  id,
                  anchorEl,
                  setAnchorEl,
                  handleClose,
                  selectedStatus,
                  statuses,
                  setStatuses,
                  statusSearch,
                  setStatusSearch,
                  onCheckStatusButton,
                  setOnCheckStatusButton,
                }}
                activeStatus="event"
              />
              <li role="presentation" onClick={ClearFilter}>
                Clear Filters
              </li>
            </ul>
          </div>
          <div className={`${classes.rightFilter} rightFilterPagination`}>
            <Pagination
              page={page}
              setPage={setPage}
              setOffset={setOffset}
              totalTenants={totalEventsCount}
            />
            <div className={`${classes.exportSerch} exportSerch`}>
              <ul>
                <SearchFilter
                  customerSearch={eventsSearch}
                  setCustomerSearch={setEventsSearch}
                  placeholder={placeholder}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsFilter;
