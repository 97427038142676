import { React } from 'react';
import { Button } from '@material-ui/core';
import editIcon from '../../images/edit-icon.svg';
import './cropImg.css';
import {
  announcement_img_312x296,
  announcement_img_344x352,
  announcement_img_375x368,
  catering_menu_300x352,
  event_img_312x296,
  event_img_344x352,
  event_img_375x368,
  loyalty_card_36x150,
  image_title_1,
  image_title_2,
  image_title_3,
  image_title_4,
  image_title_5,
  mr_img_312x296,
  mr_img_344x352,
  mr_img_375x368,
  space_img_344x352,
  space_img_375x368,
  space_previewImg_344x200,
} from './constants';

const ManageMedia = ({ values }) => {
  const {
    toggle,
    setToggle,
    imgIndex,
    setCropImg,
    cropImg,
    setCropSize,
    viewMedia,
    setTag,
    cateringMenu,
    loyaltyCardBuilding,
  } = values;
  const onEditImage = (imageType, imgFormat) =>
    (imageType === 'spaceImage' &&
      ((imgFormat.tag === image_title_2 && space_img_344x352) ||
        (imgFormat.tag === image_title_5 && space_img_375x368))) ||
    (imageType === 'images' &&
      ((imgFormat.tag === image_title_3 && event_img_312x296) ||
        (imgFormat.tag === image_title_4 && event_img_344x352) ||
        (imgFormat.tag === image_title_5 && event_img_375x368))) ||
    (imageType === 'cateringMenu' &&
      imgFormat.tag === image_title_2 &&
      catering_menu_300x352) ||
    imageType === 'loyaltyCardSupplier' ||
    imageType === 'loyaltyCardCoffeeCup' ||
    ('loyaltyCardBuilding' &&
      imgFormat.tag === image_title_2 &&
      loyalty_card_36x150) ||
    (imageType === 'newsOrArticleImage' &&
      ((imgFormat.tag === image_title_3 && announcement_img_312x296) ||
        (imgFormat.tag === image_title_4 && announcement_img_344x352) ||
        (imgFormat.tag === image_title_5 && announcement_img_375x368))) ||
    (imageType === 'videoPreviewImage' &&
      ((imgFormat.tag === image_title_3 && announcement_img_312x296) ||
        (imgFormat.tag === image_title_4 && announcement_img_344x352) ||
        (imgFormat.tag === image_title_5 && announcement_img_375x368))) ||
    (imageType === 'meetingRoom' &&
      ((imgFormat.tag === image_title_3 && mr_img_312x296) ||
        (imgFormat.tag === image_title_4 && mr_img_344x352) ||
        (imgFormat.tag === image_title_5 && mr_img_375x368))) ||
    (imageType === 'previewImage' &&
      imgFormat.tag === image_title_2 &&
      space_previewImg_344x200) ||
    (imageType === 'FloorImage' &&
      imgFormat.tag === image_title_2 &&
      space_previewImg_344x200) ||
    (imageType === 'BuildingImage' &&
      imgFormat.tag === image_title_2 &&
      space_previewImg_344x200);

  return (
    <div
      className={`${
        imgIndex.index > 1
          ? 'cropPopupContainer cropPopupContainerScndRow'
          : 'cropPopupContainer'
      }`}
    >
      <div className="cropPopupCont">
        <h3>View</h3>
        <div
          className={
            cateringMenu
              ? 'CropImgContainer CropImgCateringMenu'
              : 'CropImgContainer'
          }
        >
          {(imgIndex.mediaType === 'loyaltyCardSupplier' && (
            <ul>
              <li>
                <div className="cropImageBox">
                  <img
                    src={imgIndex.images[0].imageUrl}
                    role="presentation"
                    alt="floorPlan"
                    id="FloorImage"
                    onClick={() =>
                      !viewMedia && window.open(imgIndex.images[0].imageUrl)
                    }
                  />
                </div>
              </li>
            </ul>
          )) ||
            (imgIndex.mediaType === 'loyaltyCardCoffeeCup' && (
              <ul>
                <li>
                  <div className="cropImageBox">
                    <img
                      src={imgIndex.brandedCoffeeCupLogo.length && imgIndex.brandedCoffeeCupLogo[0].imageUrl}
                      role="presentation"
                      alt="floorPlan"
                      id="FloorImage"
                      onClick={() =>
                        !viewMedia && window.open( imgIndex.brandedCoffeeCupLogo.length && imgIndex.brandedCoffeeCupLogo[0].imageUrl)
                      }
                    />
                  </div>
                </li>
              </ul>
            )) ||
            (loyaltyCardBuilding && (
              <ul>
                <li>
                  <div className="cropImageBox">
                    <img
                      src={imgIndex.logoImages[0].imageUrl}
                      role="presentation"
                      alt="floorPlan"
                      id="FloorImage"
                      onClick={() =>
                        !viewMedia &&
                        window.open(imgIndex.logoImages[0].imageUrl)
                      }
                    />
                  </div>
                </li>
              </ul>
            )) ||
            (cateringMenu && (
              <ul>
                <li>
                  <div className="cropImgName">
                    <h4>{imgIndex.images[1].tag}</h4>
                    {!viewMedia && imgIndex.images[1].tag !== image_title_1 && (
                      <img
                        src={editIcon}
                        alt="editIcon"
                        role="presentation"
                        onClick={() => {
                          setCropSize(
                            onEditImage(imgIndex.mediaType, imgIndex.images[1]),
                          );
                          setTag(imgIndex.images[1].tag);
                          setToggle(!toggle);
                          setCropImg(!cropImg);
                        }}
                      />
                    )}
                  </div>
                  <div className="cropImageBox">
                    <img
                      src={imgIndex.images[1].imageUrl}
                      role="presentation"
                      alt="floorPlan"
                      id="FloorImage"
                      onClick={() =>
                        !viewMedia && window.open(imgIndex.images[1].imageUrl)
                      }
                    />
                  </div>
                </li>
              </ul>
            )) || (
              <ul>
                {imgIndex &&
                  imgIndex.images &&
                  imgIndex.images.map(imgFormat => (
                    <li key={imgFormat.order}>
                      <div className="cropImgName">
                        <h4>{imgFormat.tag}</h4>
                        {!viewMedia && imgFormat.tag !== image_title_1 && (
                          <img
                            src={editIcon}
                            alt="editIcon"
                            role="presentation"
                            onClick={() => {
                              setCropSize(
                                onEditImage(imgIndex.mediaType, imgFormat),
                              );
                              setTag(imgFormat.tag);
                              setToggle(!toggle);
                              setCropImg(!cropImg);
                            }}
                          />
                        )}
                      </div>
                      <div className="cropImageBox">
                        <img
                          src={imgFormat.imageUrl}
                          role="presentation"
                          alt="floorPlan"
                          id="FloorImage"
                          onClick={() =>
                            !viewMedia && window.open(imgFormat.imageUrl)
                          }
                        />
                      </div>
                    </li>
                  ))}
              </ul>
            )}
        </div>
        <div className="cropImgBtn">
          <Button onClick={() => setToggle(!toggle)}>Close</Button>
        </div>
      </div>
    </div>
  );
};

export default ManageMedia;
