import { Input, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import lodash from 'lodash';
import searchLogo from '../../images/search.svg';
import buttonClear from '../../images/button-clear-sm.svg';

const useStyles = makeStyles({
  serchFilter: {
    '& div': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
      '& input': {
        borderRadius: '3px',
        backgroundImage: `url(${searchLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center left 15px',
        backgroundColor: 'rgba(216, 216, 216, 0.19)',
        backgroundSize: '15px',
        height: '44px',
        lineHeight: '44px',
        padding: '0px 26px',
        paddingLeft: '40px',
        width: '100%',
        display: 'block',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        color: ' #838384',
      },
    },
  },
});
const NotificationSearchFilter = props => {
  const classes = useStyles();
  const { setCustomerSearch, placeholder, disabled  } = props;
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [debounceText, setDebounceText] = useState('');

  const debouncedSave = useCallback(
    lodash.debounce(nextValue => setCustomerSearch(nextValue), 500),
    [],
  );

  // closing the dropdown onclick of outside
  const useOutside = ref => {
    useEffect(() => {
      const handleClickOutside = event => {
        if (
          event.target.parentNode.className !==
            'MuiInputBase-root MuiInput-root MuiInput-underline' &&
          event.target.parentNode.className !==
            'MuiInputBase-root MuiInput-root MuiInput-underline Mui-focused Mui-focused' &&
          event.target.parentNode.className !== 'clearSearch'
        ) {
          setSearchExpanded(false);
        }
      };
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const wrapperRef = useRef(null);
  useOutside(wrapperRef);

  const textInput = useRef(null);
  const handleSearchExpand = () => {
    setSearchExpanded(true);
    setTimeout(() => {
      textInput.current.focus();
    }, 100);
  };

  return (
    <>
      <li
        className={searchExpanded ? 'searchExpanded' : ''}
        role="presentation"
        onClick={handleSearchExpand}
      >
        <img src={searchLogo} alt="searchLogo" />
        <div className={`${classes.serchFilter} serchFilter`} ref={wrapperRef}>
          <Input
            placeholder={placeholder}
            type="text"
            inputRef={textInput}
            autoFocus
            value={debounceText}
            disabled={disabled}
            inputProps={{ 'aria-label': 'description' }}
            onChange={e => {
              const nextValue = e.target.value;
              setDebounceText(nextValue);
              debouncedSave(nextValue);
            }}
          />
          {debounceText.length > 0 && (
            <span
              className="clearSearch"
              role="presentation"
              onClick={() => {
                setDebounceText('');
                setCustomerSearch('');
              }}
            >
              <img src={buttonClear} alt="buttonClear" role="presentation" />
            </span>
          )}
        </div>
      </li>
    </>
  );
};
export default NotificationSearchFilter;
