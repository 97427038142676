import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  Tooltip,
  RadioGroup,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import dowmArw from '../../images/down-arw.svg';
import './space.css';
import buttonsEditPencil from '../../images/buttons-edit-w-pencil.svg';
import apiEndPoints from '../../helpers/apiEndPoints';
import ApiClient from '../../helpers/ApiClient';
import SpaceFiles from '../../components/ImageUpload/SpaceFiles';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import { GlobalContext } from '../../context/GlobalContext';
import CloseButton from '../../components/Buttons/CloseButton';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
      },
      '& label': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        transform: 'none',
        textTransform: 'capitalize',
        position: 'static',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        minHeight: '65px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& > div': {
          '& > div': {
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
}));

const ViewSpace = () => {
  const { rolePermission } = useContext(GlobalContext);
  const classes = useStyles();
  const { id } = useParams();
  const [spaceDetails, setspaceDetails] = useState([]);
  const [spaceFile, setSpaceFile] = useState([]);
  const [previewImage, setPreviewImage] = useState([]);
  const [spaceVideo, setSpaceVideo] = useState('');
  const [floorPlan, setFloorPlan] = useState([]);
  const [createSpace, setCreateSpace] = useState(false);
  const [spaceFeatures, setSpaceFeatures] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ApiClient.apiGet(`${apiEndPoints.createSpace}/${id}`).then(data => {
      setspaceDetails(data && data.data && data.data.data);
      const spaceData = data && data.data && data.data.data;
      const fetures = spaceData.features.map(item => {
        return item.name;
      });
      setSpaceVideo(spaceData.video);
      setSpaceFeatures(fetures);
      setPreviewImage(spaceData.previewImage || []);
      setFloorPlan(spaceData.floorPlan || []);
      setSpaceFile(spaceData.images);
      setLoading(false);
    });
  }, []);

  const breadCrumbs = {
    listBreadCrumbPath: '/spacelist',
    listBreadCrumbTitle: 'Spaces',
    viewBreadCrumbPath: '/viewspace/',
    viewBreadCrumbTitle: 'View space',
  };
  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }
  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addUserContainer">
            <div
              className={`${classes.addUserForm} createcategoryForm spaceForm `}
            >
              {rolePermission && rolePermission.editSpace && (
                <Link to={`/editspace/${id}`}>
                  <div className="ditIcon">
                    <img src={buttonsEditPencil} alt="buttonsEditPencil" />
                  </div>
                </Link>
              )}
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Space Details</FormLabel>
                </li>
              </ul>
              <ul className={`${classes.InputSection}`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Building
                  </InputLabel>
                  <Input
                    readOnly
                    type="text"
                    value={spaceDetails.building.name}
                  />
                </li>
                <li>
                  <span>Village</span>
                  <Input
                    readOnly
                    type="text"
                    value={spaceDetails.building.village}
                  />
                </li>
                <Divider className="formDivider" light />
                <li>
                  <span>Unit name</span>
                  <Input readOnly type="text" value={spaceDetails.unit} />
                </li>
                <li>
                  <span>Unit size</span>
                  <Input
                    readOnly
                    type="text"
                    value={spaceDetails.sizeAreaSqFt}
                  />
                  <div className="vatText">(in sq ft)</div>
                </li>

                <li>
                  <span>Number Of Desks</span>
                  <Input readOnly type="text" value={spaceDetails.desk} />
                </li>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Availablity Date
                  </InputLabel>
                  <Input
                    readOnly
                    type="text"
                    value={moment(spaceDetails.availabilityDate).format(
                      'DD MMM YYYY',
                    )}
                  />
                </li>
              </ul>
              <ul className={`${classes.InputSection} textareaSection`}>
                <li>
                  <span>Description*</span>
                  <textarea
                    readOnly
                    type="text"
                    value={spaceDetails.description}
                  />
                </li>
              </ul>
              <ul className={`${classes.InputSection}`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Space features
                  </InputLabel>
                  <Tooltip
                    title={spaceFeatures.toString().split(',').join(', ')}
                  >
                    <Input
                      readOnly
                      type="text"
                      value={`${
                        spaceFeatures.length > 1
                          ? `${spaceFeatures.length} Features selected`
                          : `${spaceFeatures.length}  Feature selected`
                      }`}
                    />
                  </Tooltip>
                </li>
                <li>
                  <ul className={`${classes.InputSection} createSpaceFetr`}>
                    <li>
                      <InputLabel shrink className={classes.formLabel}>
                        Energy Certificate
                      </InputLabel>
                      <Input readOnly type="text" value={spaceDetails.epc} />
                    </li>
                    <li>
                      <InputLabel shrink className={classes.formLabel}>
                        WiredScore Certification
                      </InputLabel>
                      <Input
                        readOnly
                        type="text"
                        value={spaceDetails.wireCertified}
                      />
                    </li>
                  </ul>
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Media</FormLabel>
                </li>
              </ul>
              <li className="viewSpaceUpload">
                <SpaceFiles
                  values={{
                    setSpaceFile,
                    spaceFile,
                    createSpace,
                    setCreateSpace,
                    setPreviewImage,
                    previewImage,
                    setSpaceVideo,
                    spaceVideo,
                    setFloorPlan,
                    floorPlan,
                  }}
                  viewMedia
                />
              </li>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        <p>{spaceDetails.status}</p>
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <CloseButton route="/spacelist" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewSpace;
