import React, { useEffect, useState, useRef, useContext } from 'react';
import './DojoCardComponent.css';
import { useHistory } from 'react-router-dom';
import { forEach } from 'lodash';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import { PaymentGlobalContext } from '../../context/PaymentGlobalContext';
import apiEndPoints from '../../helpers/apiEndPoints';
import {
  bookingCategory,
  paymentLinkPayment,
  payments,
} from '../../utils/constants';
import DefaultStyles from './defaultStyle';

export default function DojoCardComponent() {
  const [card, setCard] = useState('');
  const [shaToken, setShaToken] = useState('');
  const [paymentIntentId, setPaymentIntentId] = useState('');
  const [loading, setLoading] = useState(true);
  const paymentContainer = useRef();
  const errorDisplay = useRef();
  const history = useHistory();
  const { setFailedPaymentError } = useContext(PaymentGlobalContext);
  useEffect(async () => {
    try {
      const sha = window.location.pathname.split('/')[3];
      const bookingId = window.location.pathname.split('/')[2];
      const paymentFor = window.location.pathname.split('/')[1];
      const category =
        paymentFor === payments || paymentFor === paymentLinkPayment
          ? bookingCategory.meetingRoomBooking
          : bookingCategory.eventBooking;
      let isExtended;
      if (category === bookingCategory.meetingRoomBooking) {
        const booking = await axios.get(
          `${apiEndPoints.failedPayments}/${bookingId}/${sha}`,
        );
        isExtended = booking?.data?.data?.extendBooking;
      }
      const url = !isExtended
        ? `${apiEndPoints.getPaymentIntent}/${bookingId}`
        : `${apiEndPoints.getPaymentIntent}/${bookingId}/extendBooking`;
      const { data } = await axios.post(url, {
        bookingCategory: category,
        shaToken: sha,
      });

      const clientSessionSecret = data?.data?.clientSessionSecret;

      const config = {
        paymentDetails: {
          paymentToken: clientSessionSecret,
        },
        containerId: 'paymentContainer',
        fontCss: ['https://fonts.googleapis.com/css?family=Do+Hyeon'],
        styles: { ...DefaultStyles },
      };

      setCard(new window.Dojo.Payment(config, displayErrorsCallback));
      setShaToken(sha);
      setPaymentIntentId(data?.data?.id);
    } catch (err) {
      setFailedPaymentError(err?.response?.data?.message);
    }
  }, []);
  useEffect(() => {
    setLoading(false);
  }, [card]);
  const processPayment = async e => {
    try {
      e.target.innerText = 'Loading...';
      e.target.disabled = true;
      const res = await card.executePayment();
      if (res?.statusCode === 0) {
        const paymentFor = window.location.pathname.split('/')[1];
        const apiPath =
          paymentFor === payments || paymentFor === paymentLinkPayment
            ? apiEndPoints.completeMeetingRoomBooingWallet
            : apiEndPoints.completEventBookingWallet;
        axios
          .post(`${apiPath}`, {
            data: {
              paymentIntentId: paymentIntentId,
              shaToken: shaToken,
            },
          })
          .then(() => {
            history.push('/payments/success');
          })
          .catch(err => {
            setFailedPaymentError(err?.response?.data?.message);
          });
      } else {
        setFailedPaymentError(res?.message);
      }
    } catch (err) {
      e.target.innerText = 'Pay';
      e.target.disabled = false;
      displayErrorsCallback(err);
    }
  };

  function displayErrorsCallback(errors) {
    errorDisplay.current.innerHTML = '';
    if (errors && errors.length) {
      const list = document.createElement('ul');
      forEach(errors, error => {
        const item = document.createElement('li');
        item.innerText = error.message;
        list.appendChild(item);
      });
      errorDisplay.current.appendChild(list);
    } else if (errors?.message) {
      errorDisplay.current.innerText = errors.message;
    }
  }

  return loading ? (
    <CircularProgress />
  ) : (
    <>
      <div id="paymentContainer" ref={paymentContainer} />
      <div id="errors" ref={errorDisplay} />
      <button
        type="button"
        id="pay"
        className="btn-primary btn pull-right"
        data-loading-text="Processing..."
        onClick={processPayment}
      >
        Pay
      </button>
    </>
  );
}
