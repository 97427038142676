import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  FormControl,
  FormLabel,
  makeStyles,
  InputLabel,
  Input,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import dowmArw from '../../images/down-arw.svg';
import buttonsEditPencil from '../../images/buttons-edit-w-pencil.svg';
import FileUpload from '../../components/ImageUpload/FileUpload';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import { GlobalContext } from '../../context/GlobalContext';
import CloseButton from '../../components/Buttons/CloseButton';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '66%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& li': {
        listStyle: 'none',
        width: '48%',
        marginBottom: '22px',
        position: 'relative',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& > span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
      },
      '& label': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384 !important',
        transform: 'none',
        textTransform: 'capitalize',
        position: 'static',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& > div': {
          '& > div': {
            '&:before': {
              display: 'none',
            },
            '&:after': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
  leftFilterIn: {
    '& button': {
      color: '#444444',
      backgroundColor: '#f8f8f8',
      padding: '12px 12px 10px',
      fontWeight: 'bold',
      boxShadow: 'none',
      fontSize: '14px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      borderRadius: 5,
      '&:hover': {
        boxShadow: 'none',
        background: '#f8f8f8',
      },
      '& span': {
        lineHeight: '100%',
        alignItems: 'center',
        display: 'flex',

        '& small': {
          marginLeft: 20,
          top: '-2px',
          position: 'relative',
        },
        '& img': {
          '&:nth-child(2)': {
            display: 'none',
          },
        },
      },
    },
  },
}));

const ViewEventDetails = () => {
  const { rolePermission } = useContext(GlobalContext);
  const classes = useStyles();
  const { id } = useParams();
  const [eventDetails, setEventDetails] = useState('');
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      ApiClient.apiGet(`${apiEndPoints.getEventDetails}/${id}`)
        .then(data => {
          const eventData = data && data.data && data.data.data;
          setEventDetails(eventData);
          setImages((eventData && eventData.image) || []);
          setLoading(false);
        })
        .catch(err => {
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
        });
    }
  }, []);

  const breadCrumbs = {
    listBreadCrumbPath: '/events-list',
    listBreadCrumbTitle: 'Events',
    viewBreadCrumbPath: '/view-event-details/',
    viewBreadCrumbTitle: 'View Event',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addMeetingRoomUserContainer">
            {rolePermission && rolePermission.updateEvent && (
              <Link to={`/edit-an-event/${id}`}>
                <div className="ditIcon">
                  <img src={buttonsEditPencil} alt="buttonsEditPencil" />
                </div>
              </Link>
            )}
            <div className={`${classes.addUserForm} offerForm`}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Event Details</FormLabel>
                </li>
              </ul>
              <ul className={`${classes.InputSection} textareaSectionHalf`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                    >
                      Host Name
                    </InputLabel>
                    <Input
                      disabled
                      value={eventDetails.host || ''}
                      inputProps={{
                        'aria-label': 'offerName',
                      }}
                    />
                  </FormControl>
                </li>
                <li>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                    className={classes.formLabel}
                  >
                    Host Website URL
                  </InputLabel>
                  <Input disabled value={eventDetails.hostWebsite || ''} />
                </li>
              </ul>
              <ul className={`${classes.InputSection} textareaSection`}>
                <li>
                  <InputLabel shrink className={classes.formLabel}>
                    Event Name
                  </InputLabel>
                  <Input
                    className="textareaSmallBox"
                    type="text"
                    inputProps={{
                      'aria-label': 'offerName',
                    }}
                    disabled
                    value={eventDetails.name || ''}
                  />
                </li>
              </ul>
              <ul className={`${classes.InputSection} offerDtCat eventDtBox`}>
                <li>
                  <span>Event Date</span>
                  <Input
                    type="text"
                    inputProps={{ 'aria-label': 'offerName' }}
                    value={
                      moment
                        .tz(eventDetails.eventDate, 'Europe/London')
                        .format('DD/MM/YYYY') || ''
                    }
                    disabled
                  />
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Start Time
                    </InputLabel>
                    <Input
                      className="textareaSmallBox"
                      type="text"
                      inputProps={{
                        'aria-label': 'offerName',
                        maxLength: '100',
                      }}
                      disabled
                      value={
                        moment
                          .tz(eventDetails.startTime, 'Europe/London')
                          .format('hh:mm a') || ''
                      }
                    />
                  </FormControl>
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      End Time
                    </InputLabel>
                    <Input
                      className="textareaSmallBox"
                      type="text"
                      inputProps={{
                        'aria-label': 'offerName',
                        maxLength: '100',
                      }}
                      disabled
                      value={
                        moment
                          .tz(eventDetails.endTime, 'Europe/London')
                          .format('hh:mm a') || ''
                      }
                    />
                  </FormControl>
                </li>
              </ul>
              <ul className={`${classes.InputSection} offerDtCat eventDtBox`}>
                <li>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                    className={classes.formLabel}
                  >
                    Ticket cost
                  </InputLabel>
                  <Input
                    disabled
                    value={eventDetails.ticketCost || 0}
                    inputProps={{
                      'aria-label': 'offerName',
                    }}
                  />
                </li>
                <li>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                    className={classes.formLabel}
                  >
                    Vat Rate
                  </InputLabel>
                  <Input
                    disabled
                    value={eventDetails.vat}
                    inputProps={{
                      'aria-label': 'offerName',
                    }}
                  />
                </li>
                <li>
                  <InputLabel
                    shrink
                    id="demo-simple-select-placeholder-label-label"
                    className={classes.formLabel}
                  >
                    Total cost
                  </InputLabel>
                  <Input
                    disabled
                    value={eventDetails.totalCost || 0}
                    inputProps={{
                      'aria-label': 'offerName',
                    }}
                  />
                </li>
              </ul>
              <ul className={`${classes.InputSection} offerDtCat eventDtBox`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Max Guests Per Booking
                    </InputLabel>
                    <Input
                      type="text"
                      inputProps={{ 'aria-label': 'offerName' }}
                      value={eventDetails.maxGuests || ''}
                      disabled
                    />
                  </FormControl>
                </li>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Max Tickets For Event
                    </InputLabel>
                    <Input
                      type="text"
                      inputProps={{ 'aria-label': 'offerName' }}
                      value={eventDetails.maxTickets || ''}
                      disabled
                    />
                  </FormControl>
                </li>
                <li>&nbsp;</li>
              </ul>
              <ul className={`${classes.InputSection} physicalType`}>
                <li>
                  <ul className="physicalTypeSub">
                    <li>
                      <span>Event Address line 1</span>
                      <Input
                        type="text"
                        inputProps={{ 'aria-label': 'offerName' }}
                        value={
                          (eventDetails.address &&
                            eventDetails.address.line1) ||
                          ''
                        }
                        disabled
                      />
                    </li>
                    <li>
                      <span>Event Address line 2</span>
                      <Input
                        type="text"
                        disabled
                        inputProps={{ 'aria-label': 'offerName' }}
                        value={
                          (eventDetails.address &&
                            eventDetails.address.line2) ||
                          ''
                        }
                      />
                    </li>
                    <li>
                      <span>City</span>
                      <Input
                        type="text"
                        inputProps={{ 'aria-label': 'offerName' }}
                        value={
                          (eventDetails.address && eventDetails.address.city) ||
                          ''
                        }
                        disabled
                      />
                    </li>
                  </ul>
                  <ul className="eventDtBox">
                    <li>
                      <span>Postcode</span>
                      <Input
                        type="text"
                        disabled
                        inputProps={{
                          'aria-label': 'offerName',
                          maxLength: '9',
                        }}
                        value={
                          (eventDetails.address &&
                            eventDetails.address.postcode) ||
                          ''
                        }
                      />
                    </li>
                    <li>
                      <span>Postcode Alias</span>
                      <Input
                        type="text"
                        readOnly
                        value={
                          (eventDetails.address &&
                            eventDetails.address.postCodeAlias) ||
                          ''
                        }
                      />
                    </li>
                    <li>&nbsp;</li>
                  </ul>
                  <ul className="eventDtBox">
                    <li>
                      <span>Latitude co-ordinate</span>
                      <Input
                        type="text"
                        disabled
                        inputProps={{ 'aria-label': 'offerName' }}
                        value={eventDetails?.address?.latitude}
                      />
                    </li>
                    <li>
                      <span>Longitude co-ordinate</span>
                      <Input
                        type="text"
                        disabled
                        inputProps={{ 'aria-label': 'offerName' }}
                        value={eventDetails?.address?.longitude}
                      />
                    </li>
                    <li>
                      <span
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                      >
                        Village/Building Eligibilty
                      </span>
                      <ul
                        className={`${classes.leftFilterIn} offerlocationFilterDropdown eventLocationF offerLocationBtn `}
                      >
                        <li>
                          <Button aria-describedby={id} variant="contained">
                            {`${
                              (eventDetails.eligibility &&
                                eventDetails.eligibility.length) ||
                              ''
                            } Building selected`}
                          </Button>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>

              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Media</FormLabel>
                </li>
              </ul>
              <ul className="offerImgUoload">
                <li>
                  <FileUpload values={{ images }} offer events viewMedia />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <div className={`${classes.radioSection} radioSection`}>
                    <p>{eventDetails.status || ''}</p>
                  </div>
                </li>
              </ul>
              <CloseButton route="/events-list" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewEventDetails;
