import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import jwt_decode from 'jwt-decode';
import { CLIENT_ID } from '../../utils/config';
import profile from '../../images/icPopupProfile.svg';
import logo from './images/logo.svg';
import menuIcon from './images/menu-icon.svg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  profileName: {
    marginLeft: 0,
    flexShrink: 0,
    marginRight: '10px',
    fontSize: '16px',
    color: '#000',
    fontWeight: 'normal',
    fontFamily: ['Lato', 'sans-serif'].join(','),
    profileLogo: {
      flexShrink: 0,
    },
  },
  navMenu: {
    width: '30%',
    display: 'flex',
    alignItems: 'center',
    '& button': {
      padding: '0px',
      marginLeft: 0,
      marginRight: 30,
      borderRadius: '100%',
      height: 70,
      width: 70,
      '& span': {
        zIndex: '1',
        padding: '0px',
        '& svg': {
          fill: '#000',
          width: '40px',
          height: 'auto',
        },
      },
    },
    '& h6': {
      '& a': {
        '&:hover': {
          background: 'transparent',
        },
      },
    },
  },
  ProfileMenu: {
    width: '50%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& img': {
      width: '50px',
    },
  },
  mainHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mainHeaderTop: {
    background: '#fff',
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.08)',
    padding: '3px 0',
    zIndex: 999999,
  },
  logo: {
    width: '135px',
  },
}));

const Header = props => {
  const { open, setOpen } = props;
  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const decoded = jwt_decode(
    localStorage.getItem(`adal.access.token.key${CLIENT_ID}`),
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.mainHeaderTop}>
        <Toolbar className={classes.mainHeader}>
          <div className={classes.navMenu}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <img src={menuIcon} className={classes.menuIcon} alt="menuIcon" />{' '}
            </IconButton>
            <Typography variant="h6" noWrap>
              <Button color="inherit" href="/">
                <img src={logo} className={classes.logo} alt="logo" />{' '}
              </Button>
            </Typography>
          </div>
          <div className={classes.ProfileMenu}>
            <Typography variant="h6" noWrap className={classes.profileName}>
              {decoded.name}
            </Typography>
            <img src={profile} className={classes.profileLogo} alt="logo" />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
