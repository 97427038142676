/* eslint-disable import/no-cycle */
import React, { createContext, useEffect, useState } from 'react';
import CateringMenuList from '../containers/CateringMenu/CateringMenuList';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';

export const CateringManuContext = createContext({});

export const CateringManuContextProvider = () => {
  const [cateringMenuList, setCateringMenuList] = useState([]);
  const [categorySearch, setCategorySearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(0);
  const [offset, setOffset] = useState(0);
  const [totalCategoriesCount, setTotalCategoriesCount] = useState(0);
  const [statuses, setStatuses] = useState([
    { label: 'Available', key: 'Available', selected: false },
    { label: 'Decommissioned', key: 'Decommissioned', selected: false },
  ]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [statusActive, setStatusActive] = useState('');
  const [statusInactive, setStatusInactive] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('categoryName');

  const categorySearchResult = categorySearch.length > 0 ? categorySearch : '';

  const getCateringMenuListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getCateringMenuList, payload)
      .then(data => {
        setCateringMenuList(data && data.data && data.data.data);
        setTotalCategoriesCount(data && data.data.count);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  useEffect(() => {
    const updatedStatus = [];
    statuses.filter(status => {
      if (status.selected) {
        updatedStatus.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedStatus(updatedStatus);
    getCateringMenuListAction({
      status:
        updatedStatus && updatedStatus.length > 0
          ? updatedStatus
          : ['Available'],
      searchText: categorySearchResult ? categorySearch : '',
      sortOrder,
      sortBy,
      offset,
      limit: 40,
    });
  }, [categorySearchResult, statuses, offset, sortOrder, sortBy]);

  return (
    <CateringManuContext.Provider
      value={{
        cateringMenuList,
        loading,
        categorySearch,
        setCategorySearch,
        page,
        setPage,
        setOffset,
        totalCategoriesCount,
        statuses,
        setStatuses,
        selectedStatus,
        setSelectedStatus,
        statusActive,
        setStatusActive,
        statusInactive,
        setStatusInactive,
        setSortOrder,
        sortOrder,
        setSortBy,
        sortBy,
      }}
    >
      <CateringMenuList />
    </CateringManuContext.Provider>
  );
};
