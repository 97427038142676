import React, { useState } from 'react';
import {
  Button,
  Popover,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useStyles as LocationFilterStyles } from './LocationFilter';
import './Filters.css';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';
import minus from '../../images/minus.svg';

export default function MeetingRoomFilter(props) {
  const classes = LocationFilterStyles();
  const {
    transformedMrList,
    selectedMRs,
    setSelectedMRs,
    selectedBuildingName,
  } = props;

  const [meetingRoomSearch, setMeetingRoomSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = () => {
    setMeetingRoomSearch(true);
  };
  const handleClose = () => {
    setMeetingRoomSearch(false);
  };
  const onMeetingRoomChange = (value, id) => {
    if (value && selectedMRs.length < 8) {
      setSelectedMRs([...selectedMRs, id]);
    } else {
      const updatedMRs = selectedMRs.filter(item => item !== id);
      setSelectedMRs(updatedMRs);
    }
  };
  return transformedMrList.length ? (
    <>
      <li
        className={
          selectedBuildingName &&
          selectedMRs.length &&
          `${classes.active} ${classes.valueSelected}`
        }
      >
        {selectedBuildingName && (
          <>
            <Button
              className="buildSelectedBtn"
              aria-describedby="meetinRoom"
              variant="contained"
              onClick={e => {
                handleClick(e);
                setAnchorEl(e.currentTarget);
              }}
            >
              Meeting Rooms
              <small>
                <img src={plus} alt="plus" />
                <img src={minus} alt="minus" />
              </small>
            </Button>
            <i
              className="closeIconBtn"
              role="presentation"
              onClick={() => {
                if (selectedMRs.length) {
                  setSelectedMRs([]);
                }
              }}
            >
              <img src={close} alt="close" />
            </i>
            <Popover
              id="meetingRoom"
              open={meetingRoomSearch}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <div className="filterDropdown filterDropdownCheck offerFilterDropdown">
                <div className="filterDropdownin">
                  <div className="filterCheckbox">
                    <h4>Select Meeting Rooms</h4>
                    <div className={`${classes.filtercheckTop} filtercheckTop`}>
                      <List>
                        {transformedMrList &&
                          transformedMrList.map(item => {
                            return (
                              <ListItem key={item.id}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="checkedC"
                                      checked={
                                        selectedMRs.length &&
                                        selectedMRs.includes(item.id)
                                      }
                                      onChange={e =>
                                        onMeetingRoomChange(
                                          e.target.checked,
                                          item.id,
                                        )
                                      }
                                    />
                                  }
                                  label={item.title}
                                />
                              </ListItem>
                            );
                          })}
                      </List>
                    </div>
                  </div>
                </div>
              </div>
            </Popover>
          </>
        )}
      </li>
    </>
  ) : (
    <></>
  );
}
