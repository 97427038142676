/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import LocationFilter from '../../components/Filters/LocationFilter';
import DateRangeFilter from '../../components/Filters/DateRangeFilter';
import StatusFilter from '../../components/Filters/StatusFilter';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import Pagination from '../../components/Pagination/Pagination';
import { BookingContext } from '../../context/BookingContext';
import exportLogo from '../../images/export.svg';
import calendarIcon from '../../images/calender-icon.svg';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';
import minus from '../../images/minus.svg';
import useFilterStyles from '../../components/Styles/useFilterStyles';
import { GlobalContext } from '../../context/GlobalContext';

const MrBookingFilterSearch = () => {
  const {
    setBuildings,
    setVillages,
    bookingSearch,
    setBookingSearch,
    buildings,
    villages,
    payAccountOptions,
    setPayAccountOptions,
    setStatuses,
    statuses,
    selectedStatus,
    payAccountSelected,
    totalBookingCount,
    setOffset,
    page,
    setPage,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    setExportList,
  } = useContext(BookingContext);
  const { buildingGroupsList } = useContext(GlobalContext);
  const classes = useFilterStyles();
  const history = useHistory();
  const [locationSearch, setLocationSearch] = useState(false);
  const [paymentSearch, setPaymentSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusSearch, setStatusSearch] = useState(false);
  const [dateRangeSearch, setDateRangeSearch] = useState(false);
  const [onSelectDateRangeButton, setOnSelectDateRangeButton] = useState('');
  const [OnCheckPaymentButton, setOnCheckPaymentButton] = useState('');
  const [onCheckStatusButton, setOnCheckStatusButton] = useState('');
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const placeholder = 'Type a name or email address';

  const exportCsv = () => {
    setTimeout(() => {
      setExportList(true);
    }, 1000);
    setExportList(false);
  };

  const payToAccountOptions = (selected, key) => {
    const paynew = payAccountOptions.map(acccount => {
      if (acccount && acccount.key === key) {
        acccount.selected = selected;
      }
      return acccount;
    });
    setPayAccountOptions(paynew);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setLocationSearch(false);
    setDateRangeSearch(null);
    setPaymentSearch(null);
    setOnCheckPaymentButton(false);
    setOnCheckStatusButton(false);
    setOnSelectDateRangeButton(false);
    setStatusSearch(null);
    setOnCheckedLocationButton(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const ClearFilter = () => {
    setStatuses([
      { label: 'Booked', key: 'Booked', selected: false },
      { label: 'Pending', key: 'Pending', selected: false },
      { label: 'Cancelled', key: 'Cancelled', selected: false },
      { label: 'No Show', key: 'NoShow', selected: false },
    ]);
    setPayAccountOptions([
      { label: 'Paid by Card', key: 'Card', selected: false },
      { label: 'Pay to Account', key: 'PayByAccount', selected: false },
      { label: 'Terminal', key: 'Pos', selected: false },
    ]);
    setAnchorEl('');
    setBuildings([]);
    setVillages([]);
    setSelectedStartDate(null);
    setSelectedEndDate(null);
  };

  const clearPaymentIcon = () => {
    setPayAccountOptions([
      { label: 'Paid by Card', key: 'Card', selected: false },
      { label: 'Pay to Account', key: 'PayByAccount', selected: false },
      { label: 'Terminal', key: 'Pos', selected: false },
    ]);
    setOnCheckPaymentButton(false);
  };

  return (
    <div className={classes.filerSection}>
      <div className="pageContainer filterContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftFilterIn `}>
              <LocationFilter
                locationSearch={locationSearch}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setLocationSearch={setLocationSearch}
                handleClose={handleClose}
                setOnCheckedLocationButton={setOnCheckedLocationButton}
                OnCheckedLocationButton={OnCheckedLocationButton}
                id={id}
                buildingGroupsList={buildingGroupsList}
                buildings={buildings}
                villages={villages}
                setVillages={setVillages}
                setBuildings={setBuildings}
              />
              <DateRangeFilter
                values={{
                  id,
                  handleClose,
                  anchorEl,
                  setAnchorEl,
                  selectedStartDate,
                  setSelectedStartDate,
                  selectedEndDate,
                  setSelectedEndDate,
                  dateRangeSearch,
                  setDateRangeSearch,
                  onSelectDateRangeButton,
                  setOnSelectDateRangeButton,
                }}
                title="Date Range"
              />
              <li
                className={
                  OnCheckPaymentButton === true ||
                  (payAccountSelected && payAccountSelected.length > 0)
                    ? `${classes.active} ${
                        payAccountSelected && payAccountSelected.length > 0
                          ? classes.valueSelected
                          : ''
                      }`
                    : ''
                }
              >
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={event => {
                    setPaymentSearch(true);
                    setAnchorEl(event.currentTarget);
                    setOnCheckPaymentButton(true);
                  }}
                >
                  Payment method
                  <small>
                    <img src={plus} alt="plus" />
                    <img src={minus} alt="minus" />
                  </small>
                </Button>
                <i
                  className="closeIconBtn"
                  role="presentation"
                  onClick={clearPaymentIcon}
                >
                  <img src={close} alt="close" />
                </i>
                <Popover
                  id="payment-method"
                  open={paymentSearch}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div className="filterDropdown">
                    <div className="filterDropdownin">
                      <List className={classes.moreList}>
                        <h4>Select A Payment Method(s)</h4>
                        {payAccountOptions.map(account => (
                          <ListItem key={account.key}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name={account.label}
                                  checked={account.selected}
                                  onChange={e => {
                                    payToAccountOptions(
                                      e.target.checked,
                                      account.key,
                                    );
                                  }}
                                />
                              }
                              label={account.label}
                              value={account.label}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </div>
                  </div>
                </Popover>
              </li>
              <StatusFilter
                values={{
                  id,
                  anchorEl,
                  setAnchorEl,
                  handleClose,
                  selectedStatus,
                  statuses,
                  setStatuses,
                  statusSearch,
                  setStatusSearch,
                  onCheckStatusButton,
                  setOnCheckStatusButton,
                }}
                activeStatus="meetingRoomBooking"
              />
              <li role="presentation" onClick={ClearFilter}>
                Clear Filters
              </li>
            </ul>
          </div>
          <div className={`${classes.rightFilter} rightFilterPagination`}>
            <Pagination
              page={page}
              setPage={setPage}
              setOffset={setOffset}
              totalTenants={totalBookingCount}
            />
            <div className={`${classes.exportSerch} exportSerch`}>
              <ul>
                <SearchFilter
                  customerSearch={bookingSearch}
                  setCustomerSearch={setBookingSearch}
                  placeholder={placeholder}
                />
                <li
                  role="presentation"
                  onClick={() => {
                    exportCsv();
                  }}
                >
                  <img src={exportLogo} alt="exportLogo" />
                </li>
                <li
                  role="presentation"
                  onClick={() => {
                    history.push('/');
                  }}
                >
                  <img src={calendarIcon} alt="calenderIcon" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MrBookingFilterSearch;
