/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  FormLabel,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  CircularProgress,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory, useParams } from 'react-router-dom';
import dowmArw from '../../images/down-arw.svg';
import './space.css';
import { GlobalContext } from '../../context/GlobalContext';
import SpaceFiles from '../../components/ImageUpload/SpaceFiles';
import apiEndPoints from '../../helpers/apiEndPoints';
import ApiClient from '../../helpers/ApiClient';
import { spaceValidation } from '../../utils/formValidate';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import { capitalized } from '../../utils/constants';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        '& > div': {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
}));

const WireCertified = [
  { name: 'Bronze', key: 'Bronze' },
  { name: 'Silver', key: 'Silver' },
  { name: 'Gold', key: 'Gold' },
  { name: 'Platinum', key: 'Platinum' },
];

const EnergyCertified = [
  { name: 'Rating A', key: 'Rating A' },
  { name: 'Rating B', key: 'Rating B' },
  { name: 'Rating C', key: 'Rating C' },
  { name: 'Rating D', key: 'Rating D' },
  { name: 'Rating E', key: 'Rating E' },
  { name: 'Rating F', key: 'Rating F' },
];

const CreateSpace = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { setSuccessMessage, buildingDataList } = useContext(GlobalContext);
  const [selectedBuilding, setSelectedBuilding] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [userVillage, setUserVillage] = useState('');
  const [unitName, setUnitName] = useState('');
  const [unitSize, setUnitSize] = useState('');
  const [selectDate, setSelectDate] = useState(null);
  const [status, setStatus] = useState('Published');
  const [selectWireCertified, setSelecteWireCertified] = useState('');
  const [energyCertified, setEnergyCertified] = useState('');
  const [spaceFile, setSpaceFile] = useState([]);
  const [createSpace, setCreateSpace] = useState(false);
  const [attributesToggle, setAttributesToggle] = useState(false);
  const [previewImage, setPreviewImage] = useState([]);
  const [spaceVideo, setSpaceVideo] = useState('');
  const [floorPlan, setFloorPlan] = useState([]);
  const [errorMsgs, setErrorMsgs] = useState(null);
  const [spacefeaturesData, setSpacefeaturesData] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [loading, setLoading] = useState(false);
  const [desk, setDesk] = useState('');
  const [spaceDescription, setSpaceDescription] = useState('');
  const [isUnitExist, setIsUnitExist] = useState();

  const errorMsg = error =>
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message;

  const imageArray = [];
  spaceFile &&
    spaceFile.map((item, index) => {
      imageArray.push({
        image: item.image,
        order: index + 1,
      });
    });

  const spaceFeatures = [];
  selectedFeature &&
    selectedFeature.map(item => {
      spacefeaturesData &&
        spacefeaturesData.map(item1 => {
          if (item === item1.id) {
            const exists = spaceFeatures.find(
              spaceFeature => spaceFeature.id === item,
            );
            if (exists) {
              spaceFeatures.push(
                spaceFeatures.filter(
                  object => selectedFeature.indexOf(object.id) > -1,
                ),
              );
            } else {
              spaceFeatures.push({
                id: item1.id,
                name: item1.name,
                imageIcon: item1.imageIcon,
                sortOrder: item1.sortOrder,
              });
            }
          }
        });
    });

  const handleClick = buildingId => {
    const villaheAutoPop =
      buildingDataList &&
      buildingDataList.filter(data => data.id === buildingId);
    setBuildingName(villaheAutoPop && villaheAutoPop[0].name);
    setUserVillage(villaheAutoPop && villaheAutoPop[0].village);
  };

  useEffect(() => {
    ApiClient.apiGet(apiEndPoints.getSpaceFeatures)
      .then(response => {
        setSpacefeaturesData(response && response.data && response.data.data);
      })
      .catch(error => {
        alert(error);
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      ApiClient.apiGet(`${apiEndPoints.createSpace}/${id}`)
        .then(data => {
          const spaceData = data && data.data && data.data.data;
          setSelectedBuilding(
            spaceData && spaceData.building && spaceData.building.id,
          );
          setBuildingName(
            spaceData && spaceData.building && spaceData.building.name,
          );
          setUserVillage(
            spaceData && spaceData.building && spaceData.building.village,
          );
          setUnitName(spaceData && spaceData.unit);
          setUnitSize(spaceData && spaceData.sizeAreaSqFt);
          setSelectDate(new Date(spaceData.availabilityDate));
          const featureIds =
            spaceData &&
            spaceData.features.map(item => {
              return item.id;
            });
          setSelectedFeature(featureIds);
          setEnergyCertified(spaceData && spaceData.epc);
          setSelecteWireCertified(spaceData && spaceData.wireCertified);
          setSpaceVideo(spaceData && spaceData.video);
          setPreviewImage((spaceData && spaceData.previewImage) || []);
          setFloorPlan((spaceData && spaceData.floorPlan) || []);
          setSpaceFile(spaceData && spaceData.images);
          setStatus(spaceData && spaceData.status);
          setLoading(false);
          setDesk(spaceData && spaceData.desk);
          setSpaceDescription(spaceData && spaceData.description);
        })
        .catch(error => {
          alert(errorMsg(error));
          setLoading(false);
        });
    }
  }, [id]);

  useEffect(() => {
    spaceValidation
      .validate({
        buildingValidation: buildingName,
        unitNameValidation: unitName,
        unitSizeValidation: unitSize,
        startDateValidation: selectDate,
        spaceFeatureValidation: spaceFeatures,
        energyValidation: energyCertified,
        deskValidation: desk,
        spaceDescriptionValidation: spaceDescription,
        // wireValidation: selectWireCertified,
        // videoValidation: spaceVideo,
        // videoImageValidation: spaceVideo,
        ImageValidation: spaceFile,
        // FloorImageValidation: floorPlan,
      })
      .then(() => {
        if (
          createSpace &&
          (spaceVideo && spaceVideo.length > 0
            ? previewImage && previewImage.length > 0
            : true)
        ) {
          setLoading(true);
          const payload = {
            id: id || undefined,
            building: {
              id: selectedBuilding,
              name: buildingName,
              village: userVillage,
            },
            unit: capitalized(unitName.trim()),
            epc: energyCertified,
            wireCertified: selectWireCertified || undefined,
            availabilityDate: new Date(selectDate).toISOString(),
            sizeAreaSqFt: Number(unitSize),
            status,
            previewImage: spaceVideo ? previewImage : undefined,
            video: spaceVideo || undefined,
            floorPlan: floorPlan || undefined,
            images: imageArray,
            features: spaceFeatures.sort(
              (a, b) =>
                (a.sortOrder > b.sortOrder && 1) ||
                (b.sortOrder > a.sortOrder && -1),
            ),
            desk: Number(desk),
            description: spaceDescription,
          };
          if (id) {
            ApiClient.apiPut(`${apiEndPoints.createSpace}/${id}`, payload)
              .then(res => {
                const successmsg = res && res.data.message;
                setLoading(false);
                setSuccessMessage(successmsg);
                history.push('/spacelist');
                setCreateSpace(false);
              })
              .catch(error => {
                setIsUnitExist(error.response.status.toString());
                setLoading(false);
                setCreateSpace(false);
                if (error.response.status.toString() !== '409')
                  alert(errorMsg(error));
              });
          } else {
            ApiClient.apiPost(apiEndPoints.createSpace, payload)
              .then(res => {
                const successmsg = res && res.data.message;
                setLoading(false);
                setSuccessMessage(successmsg);
                history.push('/spacelist');
                setCreateSpace(false);
              })
              .catch(error => {
                setIsUnitExist(error.response.status.toString());
                setLoading(false);
                setCreateSpace(false);
                if (error.response.status.toString() !== '409')
                  alert(errorMsg(error));
              });
          }
        }
      })
      .catch(error => {
        console.log('error', error);
        if (createSpace) {
          setErrorMsgs(error);
          setCreateSpace(false);
        }
      });
  }, [
    createSpace,
    selectedBuilding,
    unitName,
    energyCertified,
    selectWireCertified,
    selectDate,
    status,
    spaceDescription,
    desk,
    previewImage,
    spaceVideo,
    spaceFile,
    unitSize,
    attributesToggle,
  ]);

  const errorType = errorMsgs && errorMsgs.type;
  const errorFieldName = errorMsgs && errorMsgs.path;
  const errorFieldValue = errorMsgs && errorMsgs.value;

  const breadCrumbs = !id
    ? {
        listBreadCrumbPath: '/spacelist',
        listBreadCrumbTitle: 'Spaces',
        addBreadCrumbPath: '/createspace',
        addBreadCrumbTitle: 'Create new space',
      }
    : {
        listBreadCrumbPath: '/spacelist',
        listBreadCrumbTitle: 'Spaces',
        viewBreadCrumbPath: '/viewspace/',
        viewBreadCrumbTitle: 'View space',
        editBreadCrumbPath: '/editspace/',
        editBreadCrumbTitle: 'Edit space',
      };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addUserContainer">
            <div className={`${classes.addUserForm} spaceForm`}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Space Details</FormLabel>
                </li>
              </ul>
              <ul className={`${classes.InputSection}`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Building*
                    </InputLabel>
                    <Select
                      className={classes.formSelectBox}
                      value={selectedBuilding}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      onChange={e => {
                        setSelectedBuilding(e.target.value);
                        setCreateSpace(false);
                        handleClick(e.target.value);
                      }}
                    >
                      {buildingDataList &&
                        buildingDataList.map(item => {
                          return (
                            <MenuItem value={item.id} key={item.id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'buildingValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.buildingValidation === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={selectedBuilding === '' && 'Building is required.'}
                    >
                      {selectedBuilding === '' && 'Building is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <span>Village</span>
                  <Input type="text" readOnly value={userVillage} />
                </li>
                <Divider className="formDivider" light />
                <li>
                  <span>Unit Name*</span>
                  <Input
                    type="text"
                    value={unitName}
                    maxLength="15"
                    onChange={e => {
                      setUnitName(e.target.value);
                      setCreateSpace(false);
                      setIsUnitExist('');
                    }}
                    inputProps={{
                      'aria-label': 'description',
                      maxLength: '15',
                    }}
                  />
                  {((errorType === 'required' &&
                    errorFieldName === 'unitNameValidation') ||
                    isUnitExist === '409' ||
                    (errorFieldValue &&
                      errorFieldValue.unitNameValidation === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        (unitName === '' && 'Unit Name is required.') ||
                        (isUnitExist === '409' &&
                          'Unit with that name already exists in the building')
                      }
                    >
                      {(unitName === '' && 'Unit Name is required.') ||
                        (isUnitExist === '409' &&
                          'Unit with that name already exists in the building')}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <span>Unit Size*</span>
                  <Input
                    type="text"
                    value={unitSize}
                    onChange={e => {
                      setUnitSize(e.target.value);
                      setCreateSpace(false);
                    }}
                    onKeyPress={e => {
                      if (e.key !== 'Enter' && isNaN(parseInt(e.key, 10)))
                        e.preventDefault();
                    }}
                    inputProps={{
                      'aria-label': 'description',
                      maxLength: '7',
                      autoComplete: 'false',
                    }}
                  />
                  <div className="vatText">(in sq ft)</div>
                  {((errorType === 'required' &&
                    errorFieldName === 'unitSizeValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.unitSizeValidation === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={unitSize === '' && 'Unit Size is required.'}
                    >
                      {unitSize === '' && 'Unit Size is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <span>Number of desks*</span>
                  <Input
                    type="text"
                    value={desk}
                    onChange={e => {
                      const re = /^[0-9]+$/;
                      if (
                        e.target.value === '' ||
                        (re.test(e.target.value) && e.target.value > 0)
                      ) {
                        setCreateSpace(false);
                        setDesk(e.target.value);
                      }
                    }}
                    inputProps={{
                      'aria-label': 'description',
                      maxLength: '3',
                    }}
                  />
                  {/* <div className="vatText">(in sq ft)</div> */}
                  {((errorType === 'required' &&
                    errorFieldName === 'deskValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.deskValidation === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={desk === '' && 'Number of desks is required.'}
                    >
                      {desk === '' && 'Number of desks is required.'}
                    </FormHelperText>
                  )}
                </li>
                <li className="offerDtCat">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <span> Availablity Date*</span>
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      value={selectDate}
                      onChange={setSelectDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  {((errorType === 'required' &&
                    errorFieldName === 'startDateValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.startDateValidation === null)) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        selectDate === null && 'Availablity Date is required'
                      }
                    >
                      {selectDate === null && 'Availablity Date is required'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <ul className={`${classes.InputSection} textareaSection`}>
                <li>
                  <span>Description*</span>
                  <textarea
                    type="text"
                    maxLength="500"
                    inputProps={{ 'aria-label': 'description' }}
                    value={spaceDescription}
                    onChange={e => {
                      setSpaceDescription(e.target.value);
                    }}
                  />
                  <div className="descriptionBox">{`${spaceDescription.length}/500`}</div>
                  {((errorType === 'min' &&
                    errorFieldName === 'spaceDescriptionValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.spaceDescriptionValidation === '')) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        spaceDescription === ''
                          ? spaceDescription === '' &&
                            'Space description is required.'
                          : spaceDescription.length < 3 &&
                            'Must be at least 3 characters'
                      }
                    >
                      {spaceDescription === ''
                        ? spaceDescription === '' &&
                          'Space description is required.'
                        : spaceDescription.length < 3 &&
                          'Must be at least 3 characters'}
                    </FormHelperText>
                  )}
                </li>
              </ul>
              <ul className={`${classes.InputSection}`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel shrink className={classes.formLabel}>
                      Space Features*
                    </InputLabel>
                    <Select
                      className={classes.formSelectBox}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      multiple
                      value={selectedFeature}
                      onChange={e => {
                        setSelectedFeature(e.target.value);
                      }}
                    >
                      {spacefeaturesData &&
                        spacefeaturesData.map(item => {
                          return (
                            <MenuItem
                              value={item.id}
                              key={item.id}
                              className="multiSelectMenu"
                              insetChildren
                              checked={item && item.name}
                              primaryText={item.name}
                              name={item.name}
                            >
                              {item.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                  {((errorType === 'required' &&
                    errorFieldName === 'spaceFeatureValidation') ||
                    (errorFieldValue &&
                      errorFieldValue.spaceFeatureValidation.length === 0)) && (
                    <FormHelperText
                      className={classes.errorMsg}
                      component="div"
                      error={
                        spaceFeatures.length === 0 &&
                        'Space Features are required.'
                      }
                    >
                      {spaceFeatures.length === 0 &&
                        'Space Features are required.'}
                    </FormHelperText>
                  )}
                </li>
                <li>
                  <ul className={`${classes.InputSection} createSpaceFetr`}>
                    <li>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink className={classes.formLabel}>
                          EPC Certificate*
                        </InputLabel>
                        <Select
                          className={classes.formSelectBox}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          value={energyCertified}
                          onChange={e => {
                            setEnergyCertified(e.target.value);
                            setCreateSpace(false);
                          }}
                        >
                          {EnergyCertified.map(item => {
                            return (
                              <MenuItem value={item.name} key={item.key}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                      {((errorType === 'required' &&
                        errorFieldName === 'energyValidation') ||
                        (errorFieldValue &&
                          errorFieldValue.energyValidation === '')) && (
                        <FormHelperText
                          className={classes.errorMsg}
                          component="div"
                          error={
                            energyCertified === '' &&
                            'Energy Certificate is required.'
                          }
                        >
                          {energyCertified === '' &&
                            'Energy Certificate is required.'}
                        </FormHelperText>
                      )}
                    </li>
                    <li>
                      <FormControl className={classes.formControl}>
                        <InputLabel shrink className={classes.formLabel}>
                          WiredScore Certification
                        </InputLabel>
                        <Select
                          className={classes.formSelectBox}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          value={selectWireCertified}
                          onChange={e => {
                            setSelecteWireCertified(e.target.value);
                            setCreateSpace(false);
                          }}
                        >
                          {WireCertified.map(item => {
                            return (
                              <MenuItem value={item.name} key={item.key}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </li>
                  </ul>
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Media</FormLabel>
                </li>
              </ul>
              <li>
                <SpaceFiles
                  values={{
                    errorType,
                    errorFieldName,
                    errorFieldValue,
                    setSpaceFile,
                    spaceFile,
                    setCreateSpace,
                    setPreviewImage,
                    previewImage,
                    setSpaceVideo,
                    setAttributesToggle,
                    spaceVideo,
                    setFloorPlan,
                    floorPlan,
                  }}
                />
              </li>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul>
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        <FormControlLabel
                          control={<Radio />}
                          label="Published"
                          checked={status === 'Published'}
                          onChange={() => {
                            setStatus('Published');
                            setCreateSpace(false);
                          }}
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Unpublished"
                          checked={status === 'Unpublished'}
                          onChange={() => {
                            setStatus('Unpublished');
                            setCreateSpace(false);
                          }}
                        />
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <SaveAndCancelButtons
                setToggle={setCreateSpace}
                route="/spacelist"
              />
              <WarningPopup />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateSpace;
