import React, { useContext } from 'react';
import { Button, Divider } from '@material-ui/core';
import { GlobalContext } from '../../context/GlobalContext';
import useButtonStyles from '../Styles/useButtonStyles';

// pass clearData as para for clearData()
const SaveAndCancelButtons = ({ route, setToggle,disabled }) => {
  const { setOpen, setClosePopup } = useContext(GlobalContext);
  const classes = useButtonStyles();
  return (
    <ul className={classes.lineformButtonMain}>
      <li className={classes.lineformButton}>
        <Divider className="formDivider" light />
      </li>
      <li className={classes.formButton}>
        <Button
          onClick={() => {
            setOpen(true);
            setClosePopup(route);
            // clearData()
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            setToggle(true);
          }}
          disabled={disabled}
        >
          Save
        </Button>
      </li>
    </ul>
  );
};

export default SaveAndCancelButtons;
