/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Divider,
  FormControl,
  FormLabel,
  Input,
  InputLabel,
  makeStyles,
  RadioGroup,
  CircularProgress,
  Tooltip,
  Switch,
  Select,
  MenuItem,
  Typography,
  TableContainer,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import dowmArw from '../../images/down-arw.svg';
import './addmeetingroomuser.css';
import FileUpload from '../../components/ImageUpload/FileUpload';
import buttonsEditPencil from '../../images/buttons-edit-w-pencil.svg';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import { GlobalContext } from '../../context/GlobalContext';
import CloseButton from '../../components/Buttons/CloseButton';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '65px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
  boxStyle: {
    border: 'solid 0.5px rgb(203,203,203)',
    width: '100%',
    height: 'fit-content',
    padding: '20px',
    color: '#444444',
    borderRadius: '3px',
    marginTop: '-15px',
  },
}));

const MeetingRoomViewList = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { buildingsData, rolePermission } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [selectedEquipement, setSelectedEquipement] = useState([]);
  const [mrDetails, setMRDetails] = useState([]);
  const [imgUrl, setImgUrl] = useState([]);
  const [selectedRoomType, setSelectedRoomType] = useState('');
  const [mergeableRooms, setMergeableRooms] = useState([]);
  const [userBuildingId, setUserBuildingId] = useState('');
  const [userSelectedBuilding, setUserSelectedBuilding] = useState('');
  const [meetingRoomsList, setMeetingRoomsList] = useState([]);
  const [selectedSpaceType, setSelectedSpaceType] = useState('');
  const [visibility, setVisibility] = useState(true);
  const mergeableRoomName = [];
  const [meetingRoomSearch] = useState('');
  const [offset] = useState(0);
  const [sortOrder] = useState('asc');
  const [sortBy] = useState('nameSort');
  const [layouts, setLayouts] = useState([]);
  const [statuses] = useState([
    { label: 'Active', key: 'Active', selected: false },
    { label: 'Inactive', key: 'Inactive', selected: false },
  ]);
  const meetingRoomSearchResult =
    meetingRoomSearch.length > 0 ? meetingRoomSearch : '';

  if (
    meetingRoomsList &&
    meetingRoomsList.length > 0 &&
    mergeableRooms &&
    mergeableRooms.length > 0
  ) {
    meetingRoomsList &&
      meetingRoomsList.map(item => {
        mergeableRooms &&
          mergeableRooms.map(value => {
            if (item.id === value.id) {
              mergeableRoomName.push(item.name);
            }
          });
      });
  }

  useEffect(() => {
    const updatedStatus = [];
    statuses.filter(status => {
      if (status.selected) {
        updatedStatus.push(status.key);
      }
      return status.selected !== false;
    });
    const mrPayload = {
      searchText: meetingRoomSearchResult ? meetingRoomSearch : '',
      buildings: [userBuildingId],
      status:
        updatedStatus && updatedStatus.length > 0 ? updatedStatus : ['Active'],
      sortOrder,
      sortBy,
      offset,
      limit: 40,
    };
    if (userBuildingId.length > 0) {
      setLoading(true);
      ApiClient.apiPost(apiEndPoints.getMeetingRoomsList, mrPayload)
        .then(data => {
          const mergeableRoomsData = data && data.data && data.data.data;
          const mergeRooms =
            mergeableRoomsData &&
            mergeableRoomsData.filter(item => item.roomType === 'Mergeable');
          setMergeableRooms(mergeRooms);
          setLoading(false);
        })
        .catch(error => {
          const err = error;
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
          setLoading(false);
        });
    }
  }, [userBuildingId]);

  useEffect(() => {
    buildingsData &&
      buildingsData.filter(building => {
        if (building.name === userSelectedBuilding) {
          setUserBuildingId(building.id);
        }
      });
  }, [userSelectedBuilding.length > 0]);

  useEffect(() => {
    ApiClient.apiGet(apiEndPoints.getMeetingRoomId + id).then(data => {
      setLoading(false);
      const meetingRoomData = data && data.data && data.data.data;
      setMRDetails(meetingRoomData);
      setLayouts(meetingRoomData.roomLayouts);
      const equipmentIds = meetingRoomData.equipment.map(item => {
        return item.name;
      });
      setSelectedEquipement(equipmentIds);
      setUserSelectedBuilding(
        meetingRoomData &&
          meetingRoomData.building &&
          meetingRoomData.building.name,
      );
      setUserBuildingId(
        meetingRoomData &&
          meetingRoomData.building &&
          meetingRoomData.building.id,
      );
      setImgUrl(meetingRoomData && meetingRoomData.images);
      setSelectedRoomType(meetingRoomData && meetingRoomData.roomType);
      setSelectedSpaceType(meetingRoomData?.spaceType);
      setVisibility(meetingRoomData?.visibleOnMobile);
      const combinedIds =
        meetingRoomData &&
        meetingRoomData.combinedMeetingRooms &&
        meetingRoomData &&
        meetingRoomData.combinedMeetingRooms.map(item => {
          return item;
        });
      setMeetingRoomsList(combinedIds);
    });
  }, []);

  const bookingTime =
    mrDetails.minimumBookingTime > 60
      ? `${mrDetails.minimumBookingTime / 60} hours`
      : `${mrDetails.minimumBookingTime / 60} hour`;

  const breadCrumbs = {
    listBreadCrumbPath: '/meetingrooms-list',
    listBreadCrumbTitle: ' Meeting Rooms',
    viewBreadCrumbPath: '/viewmeetingroomlist/',
    viewBreadCrumbTitle: 'View Meeting Room',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      <div className={classes.addUserFormMain}>
        <div className="pageContainer addMeetingRoomUserContainer">
          {rolePermission && rolePermission.editMeetingRoom && (
            <Link to={`/editmeetingroom/${mrDetails.id}`}>
              <div className="ditIcon">
                <img src={buttonsEditPencil} alt="buttonsEditPencil" />
              </div>
            </Link>
          )}
          {loading ? (
            <CircularProgress color="inherit" />
          ) : (
            <div className={classes.addUserForm}>
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Meeting Room Details</FormLabel>
                </li>
              </ul>
              <ul className={classes.InputSection}>
                <li>
                  <span>Building</span>
                  <Input
                    readOnly
                    className={classes.formLabel}
                    inputprops={{ 'aria-label': 'Building' }}
                    value={
                      mrDetails && mrDetails.building && mrDetails.building.name
                    }
                  />
                </li>
                <li
                  className={`${classes.InputSection} ${classes.InputSectionReadonly}`}
                >
                  <InputLabel
                    id="demo-simple-select-placeholder-label-label"
                    className={classes.formLabel}
                  >
                    Village
                  </InputLabel>
                  <Input
                    readOnly
                    type="text"
                    inputprops={{ 'aria-label': 'Village' }}
                    value={
                      mrDetails &&
                      mrDetails.building &&
                      mrDetails.building.village
                    }
                  />
                </li>
                <li
                  className={`${classes.InputSection} ${classes.InputSectionReadonly}`}
                >
                  <InputLabel
                    id="demo-simple-select-placeholder-label-label"
                    className={classes.formLabel}
                  >
                    Meeting Room Name
                  </InputLabel>
                  <Input
                    readOnly
                    type="text"
                    inputprops={{ 'aria-label': 'Meeting Room Name' }}
                    value={mrDetails.name}
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className={`${classes.InputSection}`}>
                <li>
                  <FormControl className={classes.formControl}>
                    <InputLabel
                      shrink
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                    >
                      Space Type*
                    </InputLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      className={classes.formSelectBox}
                      value={selectedSpaceType}
                      readOnly
                      disabled
                    >
                      <MenuItem value="meetingRoom">Meeting Room</MenuItem>
                      <MenuItem value="eventSpace">Event Space</MenuItem>
                      <MenuItem value="privateHireSpace">
                        Private Hire Space
                      </MenuItem>
                    </Select>
                  </FormControl>
                </li>
                {selectedSpaceType === 'meetingRoom' ? (
                  <li>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                      >
                        Room type
                      </InputLabel>
                      <Input
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        className={classes.formSelectBox}
                        value={selectedRoomType}
                        readOnly
                      />
                    </FormControl>
                  </li>
                ) : (
                  ''
                )}
                {selectedRoomType === 'Combined' && (
                  <li>
                    <FormControl className={classes.formControl}>
                      <InputLabel
                        shrink
                        id="demo-simple-select-placeholder-label-label"
                        className={classes.formLabel}
                      >
                        Rooms to merge*
                      </InputLabel>
                      <Input
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        }}
                        className={classes.formSelectBox}
                        multiple
                        value={
                          mergeableRoomName &&
                          mergeableRoomName.toString().split(',').join(', ')
                        }
                        readOnly
                      />
                    </FormControl>
                  </li>
                )}
              </ul>
              <Divider className="formDivider" light />
              <ul className={`${classes.InputSection} textareaSection`}>
                <li>
                  <span>Description</span>
                  <textarea
                    readOnly
                    type="text"
                    inputprops={{ 'aria-label': 'Description' }}
                    value={mrDetails.description}
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className={`${classes.InputSection} InputSectionMeeting`}>
                <li>
                  <span>Min Capacity</span>
                  <Input
                    readOnly
                    type="text"
                    value={mrDetails.minCapacity}
                    inputprops={{
                      'aria-label': 'description',
                    }}
                  />
                </li>
                <li>
                  <span>Max Capacity</span>
                  <Input
                    readOnly
                    type="text"
                    value={mrDetails.maxCapacity}
                    inputprops={{
                      'aria-label': 'description',
                    }}
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Room Layouts*</FormLabel>
                </li>
              </ul>
              <div>
                <Box className={`${classes.boxStyle}`}>
                  <TableContainer>
                    <Table>
                      <TableHead className="tableHeader">
                        <TableRow>
                          <TableCell className="tableHead">
                            Layout Name*
                          </TableCell>
                          <TableCell className="tableHead">
                            Max Capacity (Layout)*
                          </TableCell>
                          <TableCell className="tableHead">
                            Turn Around Time*
                          </TableCell>
                          <TableCell className="tableHead">Image*</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {layouts.map((row, index) => (
                          <TableRow
                            className={
                              index === layouts.length - 1
                                ? 'tblRowData lastRow'
                                : 'tblRowData'
                            }
                          >
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.maxLayoutCapacity}</TableCell>
                            <TableCell>{row.turnAroundTime}{" "}Minute</TableCell>
                            <TableCell
                              onClick={() => {
                                window.open(row.layoutImage);
                              }}
                            >
                              <img
                                src={row.layoutImage}
                                alt={`${index}`}
                                className="layoutImg"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </div>
              <Divider className="formDivider" light />
              <ul className={`${classes.InputSection} InputSectionMeeting`}>
                <li>
                  <span>Equipment</span>
                  <Tooltip
                    title={selectedEquipement.toString().split(',').join(', ')}
                  >
                    <Input
                      readOnly
                      id="demo-simple-select-placeholder-label-label"
                      className={classes.formLabel}
                      value={`${
                        selectedEquipement.length > 1
                          ? `${selectedEquipement.length} Equipments selected`
                          : `${selectedEquipement.length}  Equipment selected`
                      }`}
                    />
                  </Tooltip>
                </li>
                <li>
                  <span>Minimum Booking Time</span>
                  <Input
                    readOnly
                    id="demo-simple-select-placeholder-label-label"
                    className={classes.formLabel}
                    value={
                      mrDetails.minimumBookingTime < 60
                        ? `${mrDetails.minimumBookingTime} mins`
                        : bookingTime
                    }
                  />
                </li>
                <li>
                  <span>Hourly Rate</span>
                  <Input
                    readOnly
                    type="text"
                    inputprops={{ 'aria-label': 'Hourly Rate' }}
                    value={mrDetails.hourlyPrice}
                  />
                </li>
                <li>
                  <span>Full Day Rate</span>
                  <Input
                    readOnly
                    type="text"
                    inputprops={{ 'aria-label': 'Full Day Rate' }}
                    value={mrDetails.fullDayPrice}
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Media</FormLabel>
                </li>
              </ul>
              <li>
                <FileUpload
                  values={{
                    imgUrl,
                    setImgUrl,
                  }}
                  viewMedia
                />
              </li>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul id="StatusInAddMeetingRoom">
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        {mrDetails.status === 'Active' && (
                          <p>{mrDetails.status}</p>
                        )}
                        {mrDetails.status === 'Inactive' && (
                          <p>{mrDetails.status}</p>
                        )}
                      </div>
                    </RadioGroup>
                  </FormControl>
                  <div id="showMobButtonInView">
                    <ul>
                      <li id="showOnMobBtnName">
                        <FormControl className={classes.formControl}>
                          <InputLabel shrink className={classes.formLabel}>
                            Show On Mobile
                          </InputLabel>
                        </FormControl>
                      </li>
                      <li className="YesNoSwitchBtn">
                        <Typography className="YesNoBtn">No</Typography>
                        <Switch
                          disabled={id}
                          checked={visibility}
                          color="primary"
                        />
                        <Typography className="YesNoBtn">Yes</Typography>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
              <CloseButton route="/meetingrooms-list" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MeetingRoomViewList;
