/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import LocationFilter from '../../components/Filters/LocationFilter';
import StatusFilter from '../../components/Filters/StatusFilter';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';
import minus from '../../images/minus.svg';
import { MeetingRoomsContext } from '../../context/MeetingRoomContext';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import Pagination from '../../components/Pagination/Pagination';
import useFilterStyles from '../../components/Styles/useFilterStyles';
import { GlobalContext } from '../../context/GlobalContext';

const MeetingRoomFilters = () => {
  const {
    page,
    setPage,
    setOffset,
    statuses,
    setStatuses,
    setStatusActive,
    setStatusInactive,
    buildings,
    setBuildings,
    villages,
    setVillages,
    totalMeetingRoomsCount,
    selectedStatus,
    meetingRoomSearch,
    setMeetingRoomSearch,
    roomTypes,
    setRoomTypes,
    selectedRoomType,
  } = useContext(MeetingRoomsContext);
  const { buildingGroupsList } = useContext(GlobalContext);
  const classes = useFilterStyles();
  const [locationSearch, setLocationSearch] = useState(false);
  const [moreRoomType, setMoreRoomType] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusSearch, setStatusSearch] = useState(false);
  const [onCheckStatusButton, setOnCheckStatusButton] = useState('');
  const [onCheckRoomTypeButton, setOnCheckRoomTypeButton] = useState('');
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const placeholder = 'Type a meeting room name';

  const handleClose = () => {
    setAnchorEl(null);
    setLocationSearch(false);
    setStatusSearch(null);
    setMoreRoomType(null);
    setOnCheckStatusButton(false);
    setOnCheckRoomTypeButton(false);
    setOnCheckedLocationButton(false);
  };

  const ClearFilter = () => {
    setStatuses([
      { label: 'Active', key: 'Active', selected: false },
      { label: 'Inactive', key: 'Inactive', selected: false },
    ]);
    setRoomTypes([
      { name: 'Single', key: 'Single', selected: false },
      { name: 'Combined', key: 'Combined', selected: false },
      { name: 'Mergeable', key: 'Mergeable', selected: false },
    ]);
    setStatusActive('');
    setStatusInactive('');
    setAnchorEl('');
    setBuildings([]);
    setVillages([]);
  };

  const clearRoomType = () => {
    setRoomTypes([
      { name: 'Single', key: 'Single', selected: false },
      { name: 'Combined', key: 'Combined', selected: false },
      { name: 'Mergeable', key: 'Mergeable', selected: false },
    ]);
    setOnCheckRoomTypeButton(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const roomTypeOptions = (selected, key) => {
    const slots =
      roomTypes &&
      roomTypes.map(role => {
        if (role && role.key === key) {
          role.selected = selected;
        }
        return role;
      });
    setRoomTypes(slots);
  };

  return (
    <div className={classes.filerSection}>
      <div className="pageContainer filterContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftFilterIn`}>
              <LocationFilter
                locationSearch={locationSearch}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setLocationSearch={setLocationSearch}
                handleClose={handleClose}
                setOnCheckedLocationButton={setOnCheckedLocationButton}
                OnCheckedLocationButton={OnCheckedLocationButton}
                id={id}
                buildingGroupsList={buildingGroupsList}
                buildings={buildings}
                setBuildings={setBuildings}
                villages={villages}
                setVillages={setVillages}
              />
              <StatusFilter
                values={{
                  id,
                  anchorEl,
                  setAnchorEl,
                  handleClose,
                  selectedStatus,
                  statuses,
                  setStatuses,
                  statusSearch,
                  setStatusSearch,
                  onCheckStatusButton,
                  setOnCheckStatusButton,
                }}
                activeStatus="meetingRoom"
              />
              <li
                className={
                  onCheckRoomTypeButton === true ||
                  (selectedRoomType && selectedRoomType.length > 0)
                    ? `${classes.active} ${
                        selectedRoomType && selectedRoomType.length > 0
                          ? classes.valueSelected
                          : ''
                      }`
                    : ''
                }
              >
                <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={event => {
                    setMoreRoomType(true);
                    setAnchorEl(event.currentTarget);
                    setOnCheckRoomTypeButton(true);
                  }}
                >
                  Room type
                  <small>
                    <img src={plus} alt="plus" />
                    <img src={minus} alt="minus" />
                  </small>
                </Button>
                <i
                  className="closeIconBtn"
                  role="presentation"
                  onClick={clearRoomType}
                >
                  <img src={close} alt="close" />
                </i>
                <Popover
                  id="room-type"
                  open={moreRoomType}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <div className="filterDropdown">
                    <div className="filterDropdownin">
                      <List className={classes.moreList}>
                        <h4>Select A Room Type(s)</h4>
                        {roomTypes &&
                          roomTypes.map(role => (
                            <>
                              <ListItem key={role.key}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={role.name}
                                      checked={role.selected}
                                      onChange={e => {
                                        roomTypeOptions(
                                          e.target.checked,
                                          role.key,
                                        );
                                      }}
                                    />
                                  }
                                  value={role.key}
                                  label={role.name}
                                />
                              </ListItem>
                              {role.name !== 'Mergeable' && <Divider light />}
                            </>
                          ))}
                      </List>
                    </div>
                  </div>
                </Popover>
              </li>
              <li role="presentation" onClick={ClearFilter}>
                Clear Filters
              </li>
            </ul>
          </div>
          <div className={`${classes.rightFilter} rightFilterPagination`}>
            <Pagination
              page={page}
              setPage={setPage}
              setOffset={setOffset}
              totalTenants={totalMeetingRoomsCount}
            />
            <div className={`${classes.exportSerch} exportSerch`}>
              <ul>
                <SearchFilter
                  customerSearch={meetingRoomSearch}
                  setCustomerSearch={setMeetingRoomSearch}
                  placeholder={placeholder}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingRoomFilters;
