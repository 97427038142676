import React, { createContext, useState,useEffect } from 'react';
import axios from 'axios';
import { APP_CONFIG_PARAMS } from '../utils/config';

export const PaymentGlobalContext = createContext({});

export const PaymentGlobalContextProvider = ({ children }) => {
    
  const [successMessage, setSuccessMessage] = useState('');
  const [failedPaymentError, setFailedPaymentError] = useState('');
  const [paymentServiceProvider,setPaymentServiceProvider]=useState('');

  useEffect(() => {
    axios.get(APP_CONFIG_PARAMS).then(data => {
      setPaymentServiceProvider(data?.data?.paymentServiceProvider);
    });
  }, []);

  return (
    <PaymentGlobalContext.Provider
      value={{
        successMessage,
        setSuccessMessage,
        failedPaymentError,
        setFailedPaymentError,
        paymentServiceProvider,
      }}
    >
      {children}
    </PaymentGlobalContext.Provider>
  );
};
