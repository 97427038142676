/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';
import minus from '../../images/minus.svg';
import DateRangeFilter from '../../components/Filters/DateRangeFilter';
import StatusFilter from '../../components/Filters/StatusFilter';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import Pagination from '../../components/Pagination/Pagination';
import useFilterStyles from '../../components/Styles/useFilterStyles';
import { ManageVoucherContext } from '../../context/ManageVoucherContext';

const ManageVoucherFilter = () => {
  const {
        voucherSearch,
        setVoucherSearch,
        page,
        setPage,
        setOffset,
        totalVouchersCount,
        selectedDiscountType,
        discountTypes,
        setDiscountTypes,
        statuses,
        setStatuses,
        selectedStatus,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
  } = useContext(ManageVoucherContext);
  const classes = useFilterStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusSearch, setStatusSearch] = useState(false);
  const [discountSearch, setDiscountSearch] = useState(false);
  const [dateRangeSearch, setDateRangeSearch] = useState(false);
  const [onSelectDateRangeButton, setOnSelectDateRangeButton] = useState('');
  const [onCheckStatusButton, setOnCheckStatusButton] = useState('');
  const [onCheckDiscountButton, setOnCheckDiscountButton] = useState('');

  const placeholder = 'Type a Voucher code';

  const handleClose = () => {
    setAnchorEl(null);
    setDateRangeSearch(null);
    setDiscountSearch(null);
    setOnCheckStatusButton(false);
    setOnCheckDiscountButton(false);
    setOnSelectDateRangeButton(false);
    setStatusSearch(null);
  };
  const offerTypeOptions = (selected, key) => {
    const matchedDiscountType = discountTypes.map(discountType => {
      if (discountType && discountType.key === key) {
        discountType.selected = selected;
      }
      return discountType;
    });
    setDiscountTypes(matchedDiscountType);
  };
  const clearOfferType = () => {
    setDiscountTypes([
      { label: 'Value', key: 'Value', selected: false },
      { label: 'Percentage', key: 'Percentage', selected: false },
    ]);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const ClearFilter = () => {
    setStatuses([
      { label: 'Active', key: 'Active', selected: false },
      { label: 'Inactive', key: 'Inactive', selected: false },
    ]);
    setDiscountTypes([
      { label: 'Value', key: 'Value', selected: false },
      { label: 'Percentage', key: 'Percentage', selected: false },
    ]);
    setAnchorEl('');
    setSelectedStartDate(null);
    setSelectedEndDate(null);    
  };

  return (
    <div className={classes.filerSection}>
      <div className="pageContainer filterContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftFilterIn`}>
              <DateRangeFilter
                values={{
                  id,
                  handleClose,
                  anchorEl,
                  setAnchorEl,
                  selectedStartDate,
                  setSelectedStartDate,
                  selectedEndDate,
                  setSelectedEndDate,
                  dateRangeSearch,
                  setDateRangeSearch,
                  onSelectDateRangeButton,
                  setOnSelectDateRangeButton,
                }}
                title="Voucher Date"
              />
              <StatusFilter
                values={{
                  id,
                  anchorEl,
                  setAnchorEl,
                  handleClose,
                  selectedStatus,
                  statuses,
                  setStatuses,
                  statusSearch,
                  setStatusSearch,
                  onCheckStatusButton,
                  setOnCheckStatusButton,
                }}
                activeStatus="voucher"
              />
              <li
                className={
                  onCheckDiscountButton === true ||
                  (selectedDiscountType && selectedDiscountType.length > 0)
                    ? `${classes.active} ${
                        selectedDiscountType && selectedDiscountType.length > 0
                          ? classes.valueSelected
                          : ''
                      }`
                    : ''
                }
              >
              <Button
                  aria-describedby={id}
                  variant="contained"
                  onClick={event => {
                    setDiscountSearch(true);
                    setAnchorEl(event.currentTarget);
                    setOnCheckDiscountButton(true);
                  }}
                >
                  Discount type
                  <small>
                    <img src={plus} alt="plus" />
                    <img src={minus} alt="minus" />
                  </small>
                </Button>
                <i
                  className="closeIconBtn"
                  role="presentation"
                  onClick={clearOfferType}
                >
                  <img src={close} alt="close" />
                </i>
                <Popover
                  id="offer-type"
                  open={discountSearch}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                <div className="filterDropdown filterDropdownCheck offerFilterDropdownCheck">
                        <div className="filterDropdownin">
                          <List className={classes.moreList}>
                            <h4>select a Discount type(s)</h4>
                            {discountTypes &&
                              discountTypes.map(discountType => (
                                <>
                                  <ListItem key={discountType.key}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          name={discountType.label}
                                          checked={discountType.selected}
                                          onChange={e => {
                                            offerTypeOptions(
                                              e.target.checked,
                                              discountType.key,
                                            );
                                          }}
                                        />
                                      }
                                      label={discountType.label}
                                      value={discountType.label}
                                    />
                                  </ListItem>
                                  {discountType.label === 'Digital' && (
                                    <Divider light />
                                  )}
                                </>
                              ))}
                          </List>
                        </div>
                      </div>
                    </Popover>
                  </li>
              <li role="presentation" onClick={ClearFilter}>
                Clear Filters
              </li>
            </ul>
          </div>
          <div className={`${classes.rightFilter} rightFilterPagination`}>
            <Pagination
              page={page}
              setPage={setPage}
              setOffset={setOffset}
              totalTenants={totalVouchersCount}
            />          
          <div className={`${classes.exportSerch} exportSerch`}>
              <ul>
                <SearchFilter
                  customerSearch={voucherSearch}
                  setCustomerSearch={setVoucherSearch}
                  placeholder={placeholder}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageVoucherFilter;
