/* eslint-disable global-require */
/* eslint-disable no-undef */
import moment from 'moment';

export const momentTimeZone = require('moment-timezone');

// const bst = momentTimeZone.tz('Europe/London');
export const getMoment = (timezone = currentTimezone) => {
  const m = (...args) => momentTimeZone.tz(...args, timezone);
  m.localeData = moment.localeData;
  return m;
};

export const convertDateTimeToDate = (datetime, tzMoment) => {
  return new Date(tzMoment(datetime).format()); // sets Date using ISO 8601 format
};
export const getNow = (now, tzMoment) => convertDateTimeToDate(now(), tzMoment);

export const overlap = (firstBooking, secondBooking) => {
  if (firstBooking.meetingRoom.id !== secondBooking.meetingRoom.id)
    return false;
  if (
    secondBooking.startDate >= firstBooking.startDate &&
    secondBooking.startDate <= firstBooking.endDate
  )
    return true;
  if (
    secondBooking.endDate >= firstBooking.startDate &&
    secondBooking.endDate <= firstBooking.endDate
  )
    return true;
  return false;
};

export const removeDuplicates = bookingArray => {
  // this is an o(n^2) algorithm
  for (let i = 0; i < bookingArray.length; i++) {
    for (let j = i + 1; j < bookingArray.length; j++) {
      if (overlap(bookingArray[i], bookingArray[j])) {
        // now these two bookings overlap
        let finalStartDate;
        let finalEndDate;
        if (bookingArray[i].startDate < bookingArray[j].startDate)
          finalStartDate = bookingArray[i].startDate;
        else finalStartDate = bookingArray[j].startDate;
        if (bookingArray[i].endDate < bookingArray[j].endDate)
          finalEndDate = bookingArray[j].endDate;
        else finalEndDate = bookingArray[i].endDate;

        // now we have the final startDate and final endDate

        bookingArray[i].startDate = finalStartDate;
        bookingArray[i].endDate = finalEndDate;
        if (
          bookingArray &&
          bookingArray[i] &&
          bookingArray[i].isCombinedMeetingRoom &&
          !bookingArray[i].isCombinedMeetingRoom.includes(
            bookingArray &&
              bookingArray[j] &&
              bookingArray[j].isCombinedMeetingRoom,
            0,
          )
        ) {
          if (
            bookingArray &&
            bookingArray[i] &&
            bookingArray[i].isCombinedMeetingRoom !== undefined &&
            bookingArray &&
            bookingArray[j] &&
            bookingArray[j].isCombinedMeetingRoom !== undefined
          ) {
            bookingArray[i].isCombinedMeetingRoom += ` + ${
              bookingArray &&
              bookingArray[j] &&
              bookingArray[j].isCombinedMeetingRoom
            }`;
          }
        }

        // this is to remove the j th element since it already overlaps with i th element
        // spilce takes o(n) time
        bookingArray.splice(j, 1);
      }
    }
  }
  return bookingArray;
};
export const convert = str => {
  const date = new Date(str);
  const mnth = `0${date.getMonth() + 1}`.slice(-2);
  const day = `0${date.getDate()}`.slice(-2);
  return [date.getFullYear(), mnth, day].join('-');
};

export const isdaylightsaving = seldate => {
  const selDay = new Date(seldate).getDay() + 1;
  const selctedDay = new Date(seldate).getDate();
  const month = new Date(seldate).getMonth();
  const dateOutput = selctedDay + (7 - selDay);
  if (month + 1 > 3 && month + 1 < 10) {
    return true;
  }
  if (month + 1 === 3 && dateOutput > 30) {
    return true;
  }
  if (month + 1 === 10 && dateOutput < 30) {
    return true;
  }
  return false;
};

export const getTimeAsDate = (hour, tzMoment, selectedDate) => {
  if (hour < 0 || hour > 23) {
    throw Error(
      `*${hour}* is an invalid 'hour' value, which must be between 0 and 23.`,
    );
  }
  let dayHours;
  if (isdaylightsaving(selectedDate)) {
    const m = tzMoment(selectedDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
    dayHours = new Date(
      m
        .hour(hour + 1)
        .minute(0)
        .format(),
    );
  } else {
    const m = tzMoment(selectedDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
    dayHours = new Date(m.hour(hour).minute(0).format());
  }
  const myDate = new Date(dayHours);
  const isoDate = moment(myDate).toISOString();
  return new Date(isoDate);
};

export const utcToIstDateFormate = date => {
  return new Date(
    `${moment.utc(date).set('hours', 0).set('minutes', 0).set('seconds', 0)}`,
  );
};

export const changeToBstHourOnlyFormat=time=>{
  const timeMoment = moment(time);
  return timeMoment.tz('Europe/London').format('HH');
}