import React, { useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  Popover,
} from '@material-ui/core';
import useFilterStyles from '../Styles/useFilterStyles';
import minus from '../../images/minus.svg';
import plus from '../../images/plus.svg';
import close from '../../images/close.svg';

const VillageFilter = ({ values, offerCards, offers }) => {
  const classes = useFilterStyles();
  const {
    setBuildings,
    buildingGroupsList,
    selectedVillage,
    setSelectedVillage,
    setSelectedOffers,
    errorType,
    errorFieldName,
    errorFieldValue,
  } = values;
  const [anchorEl, setAnchorEl] = useState(null);
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const villages =
    buildingGroupsList && buildingGroupsList.map(item => item && item[0]);
  const builingsList =
    buildingGroupsList &&
    buildingGroupsList.map(item => item && item[1].map(i => i));

  const buildingIds = () =>
    builingsList
      .flat()
      .filter(villagesList => villagesList.village === selectedVillage)
      .map(buildingids => buildingids.id);

  useMemo(() => {
    if (offerCards)
      if (selectedVillage) {
        setBuildings(buildingIds());
      } else {
        setBuildings([]);
      }
  }, [selectedVillage]);

  const onVillageSelect = (e, village) => {
    if (e.target.checked) {
      setSelectedVillage(village);
    } else {
      setSelectedVillage('');
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOnCheckedLocationButton(false);
  };

  const open = Boolean(anchorEl);
  const openId = open ? 'simple-popover' : undefined;

  return (
    <>
      <li
        className={
          selectedVillage
            ? `${classes.active} ${
                selectedVillage ? classes.valueSelected : ''
              }`
            : ''
        }
      >
        <Button
          aria-describedby={openId}
          variant="contained"
          onClick={event => {
            setOnCheckedLocationButton(true);
            setAnchorEl(event.currentTarget);
          }}
        >
          {selectedVillage || 'Village'}
          <small>
            <img src={plus} alt="plus" />
            <img src={minus} alt="minus" />
          </small>
        </Button>
        {((errorType === 'required' &&
          errorFieldName === 'offerCategoryValidation') ||
          (errorFieldValue && errorFieldValue.villageValidation === '')) && (
          <FormHelperText className={classes.errorMsg}>
            {selectedVillage === '' && 'Village is required'}
          </FormHelperText>
        )}
        <i
          className="closeIconBtn"
          role="presentation"
          onClick={() => {
            setSelectedVillage('');
            setAnchorEl('');
            setOnCheckedLocationButton(false);
            if (offerCards) setSelectedOffers([]);
          }}
        >
          <img src={close} alt="close" />
        </i>
        <Popover
          id="category"
          open={OnCheckedLocationButton}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div
            className={`filterDropdown filterDropdownCheck ${
              offers ? 'offerFilterDropdown' : ''
            }`}
          >
            <div className="filterDropdownin">
              <List className={classes.moreList}>
                <h4>Select A Village</h4>
                {villages &&
                  villages.map((village, index) => (
                    <>
                      <ListItem key={village}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={village}
                              checked={village === selectedVillage}
                              onChange={e => {
                                onVillageSelect(e, village);
                                setOnCheckedLocationButton(false);
                              }}
                            />
                          }
                          label={village}
                        />
                      </ListItem>
                      {villages && villages.length - 1 > index && (
                        <Divider light />
                      )}
                    </>
                  ))}
              </List>
            </div>
          </div>
        </Popover>
      </li>
    </>
  );
};
export default VillageFilter;
