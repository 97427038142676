import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { GlobalContext } from '../../context/GlobalContext';

const useStyles = makeStyles(() => ({
  popupBadge: {
    background: '#000000',
    borderRadius: '11px',
    fontSize: '16px',
    color: '#fff',
    width: '50%',
    margin: '0 auto',
    position: 'fixed',
    top: '75px',
    left: 0,
    right: 0,
    textAlign: 'center',
    padding: ' 12px 0',
    fontFamily: ['Varta', 'sans-serif'].join(','),
    zIndex: '999999',
  },
}));

const SnackBar = () => {
  const { successMessage, setSuccessMessage } = useContext(GlobalContext);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setSuccessMessage('');
    }, 3000);
  }, [successMessage]);

  return (
    <>
      {successMessage && (
        <div className={classes.popupBadge}>{successMessage}</div>
      )}
    </>
  );
};
export default SnackBar;
