/* eslint-disable import/no-cycle */
import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';
import EventBookings from '../containers/EventBookings/EventBookings';

export const EventBookingsContext = createContext({});

export const EventBookingsContextProvider = props => {
  const { embedded, userId } = props;

  const [eventBookingsList, setEventBookingsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bookingSearchId, setBookingSearchId] = useState(undefined);
  const [eventsSearch, setEventsSearch] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [villages, setVillages] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [totalEventsCount, setTotalEventsCount] = useState(0);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('startTime');
  const [exportList, setExportList] = useState(false);
  const [statuses, setStatuses] = useState([
    { label: 'Booked', key: 'Booked', selected: false },
    { label: 'Cancelled', key: 'Cancelled', selected: false },
    { label: 'DNA', key: 'DNA', selected: false },
  ]);
  const [eventsType, setEventsType] = useState([
    { label: 'Paid', key: 'Paid', selected: false },
    { label: 'Free', key: 'Free', selected: false },
  ]);
  const [selectedEventsType, setSelectedEventsType] = useState([]);
  const startDateApiReq = `${moment
    .utc(selectedStartDate)
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)}`;

  const endDateApiReq = `${moment
    .utc(selectedEndDate)
    .set('hours', 23)
    .set('minutes', 59)
    .set('seconds', 0)}`;

  // get Booking List API Call
  const getEventsListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getEventBooKings, payload)
      .then(data => {
        setEventBookingsList(data && data.data && data.data.data);
        setTotalEventsCount(data && data.data.count);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  // get Booking List export list API Call
  const getBookingsExport = payload => {
    ApiClient.apiPost(apiEndPoints.eventExportList, payload)
      .then(data => {
        const link = document.createElement('a');
        link.href = data && data.data.data.link;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      })
      .catch(error => {
        alert(error, 'error');
      });
  };

  useEffect(() => {
    const statusUpdate = [];
    statuses.filter(status => {
      if (status.selected) {
        statusUpdate.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedStatus(statusUpdate);

    const eventsUpdate = [];
    eventsType.filter(event => {
      if (event.selected) {
        eventsUpdate.push(event.key);
      }
      return event.selected !== false;
    });
    setSelectedEventsType(eventsUpdate);

    if (exportList)
      getBookingsExport({
        searchText: eventsSearch.length > 2 ? eventsSearch : undefined,
        buildings: buildings && buildings.length > 0 ? buildings : undefined,
        eventStartDate:
          selectedStartDate && selectedEndDate
            ? moment(startDateApiReq).toISOString()
            : undefined,
        eventEndDate:
          selectedStartDate && selectedEndDate
            ? moment(endDateApiReq).toISOString()
            : undefined,
        sortOrder,
        sortBy,
        status:
          statusUpdate && statusUpdate.length > 0
            ? statusUpdate
            : ['Booked', 'Cancelled', 'DNA'],
        type:
          eventsUpdate && eventsUpdate.length > 0
            ? eventsUpdate
            : ['Paid', 'Free'],
        offset,
        limit: 2000,
      });
  }, [exportList]);

  useEffect(() => {
    const statusUpdate = [];
    statuses.filter(status => {
      if (status.selected) {
        statusUpdate.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedStatus(statusUpdate);

    const eventsUpdate = [];
    eventsType.filter(event => {
      if (event.selected) {
        eventsUpdate.push(event.key);
      }
      return event.selected !== false;
    });
    setSelectedEventsType(eventsUpdate);
    getEventsListAction({
      searchText: eventsSearch.length > 2 ? eventsSearch : undefined,
      buildings: buildings && buildings.length > 0 ? buildings : undefined,
      eventStartDate:
        selectedStartDate && selectedEndDate
          ? moment(startDateApiReq).toISOString()
          : undefined,
      eventEndDate:
        selectedStartDate && selectedEndDate
          ? moment(endDateApiReq).toISOString()
          : undefined,
      sortOrder,
      sortBy,
      status:
        statusUpdate && statusUpdate.length > 0
          ? statusUpdate
          : ['Booked', 'Cancelled', 'DNA'],
      type:
        eventsUpdate && eventsUpdate.length > 0
          ? eventsUpdate
          : ['Paid', 'Free'],
      offset,
      limit: 40,
      userId: userId,
    });
  }, [
    buildings,
    eventsSearch,
    selectedStartDate,
    selectedEndDate,
    sortOrder,
    sortBy,
    statuses,
    offset,
    eventsType,
  ]);

  return (
    <EventBookingsContext.Provider
      value={{
        eventBookingsList,
        setEventBookingsList,
        getEventsListAction,
        loading,
        setLoading,
        eventsSearch,
        setEventsSearch,
        bookingSearchId,
        setBookingSearchId,
        buildings,
        setBuildings,
        villages,
        setVillages,
        setStatuses,
        statuses,
        setSelectedStatus,
        selectedStatus,
        totalEventsCount,
        setTotalEventsCount,
        offset,
        setOffset,
        page,
        setPage,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        setExportList,
        eventsType,
        setEventsType,
        selectedEventsType,
      }}
    >
      <EventBookings embedded={embedded} />
    </EventBookingsContext.Provider>
  );
};
