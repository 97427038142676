/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import {
  Button,
  FormHelperText,
  Input,
  LinearProgress,
  makeStyles,
} from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import addImage from '../../images/addImage.svg';
import closeIcon from '../../images/close-icon.svg';
import pdfIcon from '../../images/pdf-icon.png';
import { uploadFileToBlob } from './storage';
import ManageMedia from './ManageMedia';
import {
  CLOUDINARY_API_KEY,
  CLOUDINARY_API_SECRET_KEY,
  CLOUDINARY_UPLOAD_PRESET,
  CLOUD_NAME,
} from '../../utils/config';
import ImageCroper from './ImageCroper';
import {
  space_img_344x352,
  space_img_375x368,
  space_previewImg_344x200,
  buildings_media_folder,
  spaces_media_folder,
  image_title_1,
  image_title_5,
  image_title_2,
} from './constants';
import { isScalableImage } from '../../utils/constants';
import MicImage from '../../images/Mic Img.png';
// import CardBGImg from '../../images/Card Bg Image.png';

const useStyles = makeStyles({
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  videoAddIcon: {
    position: 'absolute',
    top: '20px',
    left: 0,
    right: 0,
    margin: '0 auto',
    textAlign: 'center',
    height: '85px',
    width: '100%',
    lineHeight: '85px',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& video': {
      width: '100%',
      height: '100%',
      objectFit: 'fill',
    },
  },
  videoAddIconView: {
    pointerEvents: 'none',
  },
});

const theme = createMuiTheme({
  palette: {
    secondary: {
      main: '#737373',
    },
  },
});

const SpaceFiles = ({ values, viewMedia, buildingAttributes }) => {
  const classes = useStyles();
  const {
    spaceFile,
    setSpaceFile,
    setCreateSpace,
    setAttributesToggle,
    setPreviewImage,
    previewImage,
    setSpaceVideo,
    spaceVideo,
    setFloorPlan,
    floorPlan,
    buildingLogo,
    setBuildingLogo,
    errorType,
    errorFieldName,
    errorFieldValue,
    visitor,
    thumnail,
    setThumnail,
  } = values;
  const [imageLoader, setImageLoader] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [imgIndex, setImgIndex] = useState('');
  const [cropImg, setCropImg] = useState(false);
  const [cropSize, setCropSize] = useState({});
  const [tag, setTag] = useState('');

  const imageFormatedUrl = (originalImage, sizes = '') =>
    `${originalImage.split('image/upload')[0]}image/upload/${
      (isScalableImage &&
        sizes &&
        `c_scale,h_${sizes.height},w_${sizes.width}/`) ||
      ''
    }f_jpg,f_auto,q_auto${originalImage.split('image/upload')[1]}`;

  const removePhoto = (e, idx) => {
    const index = spaceFile.findIndex(x => x.order === idx);
    if (index > -1) {
      const imgArray = spaceFile.filter(item => item.order !== idx);
      const spaceArray = [];
      if (imgArray && imgArray.length > 0) {
        imgArray &&
          imgArray.map((item, i) =>
            spaceArray.push({
              image: item.image,
              order: i + 1,
              name: item.name,
            }),
          );
      }
      setSpaceFile(spaceArray);
    }
  };

  const showUploadWidget = (
    e,
    resourceType,
    maxFileSize,
    folder,
    formates,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
  ) => {
    window.cloudinary.openUploadWidget(
      {
        cloudName: CLOUD_NAME,
        api_key: CLOUDINARY_API_KEY,
        api_secret: CLOUDINARY_API_SECRET_KEY,
        uploadPreset: CLOUDINARY_UPLOAD_PRESET,
        showPoweredBy: false,
        folder,
        clientAllowedFormats: formates,
        sources: [
          'local',
          'camera',
          'url',
          'facebook',
          'instagram',
          'google_drive',
        ],
        minImageWidth: minWidth,
        minImageHeight: minHeight,
        maxImageWidth: maxWidth,
        maxImageHeight: maxHeight,
        showAdvancedOptions: false,
        cropping: false,
        multiple: false,
        resourceType,
        maxFileSize,
        defaultSource: 'local',
        styles: {
          palette: {
            window: '#FDFDFD',
            sourceBg: '#FFFFFF',
            windowBorder: '#000000',
            tabIcon: '#060606',
            inactiveTabIcon: '#82858A',
            menuIcons: '#000000',
            link: '#000000',
            action: '#000000',
            inProgress: '#000000',
            complete: '#000000',
            error: '#EA2727',
            textDark: '#000000',
            textLight: '#FFFFFF',
          },
          fonts: {
            default: null,
            "'Varta', sans-serif": {
              url: 'https://fonts.googleapis.com/css?family=Varta+Sans',
              active: true,
            },
          },
        },
      },
      (err, { event, info }) => {
        if (!err && event === 'success') {
          if (e === 'previewImage') {
            setPreviewImage([
              {
                imageUrl: imageFormatedUrl(info.secure_url),
                tag: image_title_1,
              },
              {
                imageUrl: imageFormatedUrl(
                  info.secure_url,
                  space_previewImg_344x200,
                ),
                tag: image_title_2,
              },
            ]);
          } else if (e === 'inputVideoUrl') {
            setSpaceVideo(info.secure_url);
          } else if (e === 'ImageSelector') {
            setSpaceFile([
              ...spaceFile,
              {
                image: [
                  {
                    imageUrl: imageFormatedUrl(info.secure_url),
                    tag: image_title_1,
                  },
                  {
                    imageUrl: imageFormatedUrl(
                      info.secure_url,
                      space_img_344x352,
                    ),
                    tag: image_title_2,
                  },
                  {
                    imageUrl: imageFormatedUrl(
                      info.secure_url,
                      space_img_375x368,
                    ),
                    tag: image_title_5,
                  },
                ],
                order: spaceFile.length + 1,
              },
            ]);
          } else if (e === 'FloorImage') {
            setFloorPlan([
              {
                imageUrl: imageFormatedUrl(info.secure_url),
                tag: image_title_1,
              },
              {
                imageUrl: imageFormatedUrl(
                  info.secure_url,
                  space_previewImg_344x200,
                ),
                tag: image_title_2,
              },
            ]);
          } else if (e === 'BuildingImage') {
            if (info.height * 3 === info.width) {
              setBuildingLogo([
                {
                  imageUrl: imageFormatedUrl(info.secure_url),
                  tag: image_title_1,
                },
                {
                  imageUrl: imageFormatedUrl(
                    info.secure_url,
                    space_previewImg_344x200,
                  ),
                  tag: image_title_2,
                },
              ]);
            } else {
              alert(
                'Building logo Height to Width ratio should be 3. Recommended Width - 450 px and Height - 150px.',
              );
            }
          } else if (e === 'ThumbnailImage') {
            if(info.height===info.width && info.height>=348){
              setThumnail([
                {
                  imageUrl: imageFormatedUrl(info.secure_url),
                  tag: image_title_1,
                },
              ]);
            }
            else {
              alert(
                'Building Thumbnail Height to Width ratio should be 1:1. Recommended Min-Width - 348px and Min-Height - 348px.',
              );
            }
          }
        }
      },
    );
  };

  const onFileChange = async (event, id) => {
    const url = await uploadFileToBlob(event.target.files[0]);
    setImageLoader(false);
    if (id === 'FloorImage') {
      setFloorPlan(url);
    }
  };

  // this is for Building Brochure
  const validatePDF = objFileControl => {
    const files = objFileControl.target.files[0];
    const file = objFileControl.target.value;
    const len = file && file.length;
    const ext = file && file.slice(len - 4, len);
    if (ext && ext.toUpperCase() === '.PDF') {
      if (files.size > 5e6) {
        alert('Maximum file size 5MB.');
        setImageLoader(false);
      } else {
        onFileChange(objFileControl, objFileControl.target.id);
        setCreateSpace(false);
        setAttributesToggle(false);
      }
    } else {
      setImageLoader(false);
      alert('Only PDF files allowed.');
    }
  };

  useEffect(() => {
    document.body.classList.toggle('modalOpen', toggle || cropImg);
  }, [toggle, cropImg]);

  return (
    <div className="spaceFileContainer">
      {toggle && (
        <ManageMedia
          values={{
            viewMedia,
            setCropSize,
            cropImg,
            toggle,
            setToggle,
            setCropImg,
            imgIndex,
            setTag,
          }}
        />
      )}
      {cropImg && (
        <ImageCroper
          values={{
            cropSize,
            setCropSize,
            cropImg,
            setCropImg,
            toggle,
            setToggle,
            imgIndex,
            setImgIndex,
            tag,
            setTag,
            spaceFile,
            setSpaceFile,
            previewImage,
            setPreviewImage,
            floorPlan,
            setFloorPlan,
            buildingLogo,
            setBuildingLogo,
          }}
        />
      )}
      {buildingAttributes && (
        <div className="cardPrevForBuilding">
          <ul className=" ">
            {buildingAttributes && (
              <>
                <ul className="MultipleImgUpload imgUploadVideo">
                  <li>
                    {!viewMedia && buildingLogo && buildingLogo.length > 0 && (
                      <i className="removeImage">
                        <img
                          src={closeIcon}
                          role="presentation"
                          alt="closeIcon"
                          onClick={() => setBuildingLogo([])}
                        />
                      </i>
                    )}
                    <small className="imageAddName">Building Logo*</small>
                    {imageLoader ? (
                      <MuiThemeProvider theme={theme}>
                        <LinearProgress color="secondary" />
                      </MuiThemeProvider>
                    ) : (
                      <>
                        {!(buildingLogo && buildingLogo.length > 0) &&
                          !viewMedia && (
                            <>
                              <Input
                                name="BuildingImage"
                                id="BuildingImage"
                                type="button"
                                accept="application/pdf"
                                multiple
                                onClick={() =>
                                  showUploadWidget(
                                    'BuildingImage',
                                    'image',
                                    null,
                                    `${
                                      buildingAttributes
                                        ? buildings_media_folder
                                        : spaces_media_folder
                                    }`,
                                    ['png'],
                                    null,
                                    null,
                                    null,
                                    null,
                                  )
                                }
                              />
                              <span className="imageAddIcon">
                                <img
                                  src={addImage}
                                  role="presentation"
                                  alt="buildingLogo"
                                  id="BuildingImage"
                                />
                              </span>
                            </>
                          )}
                        {buildingLogo && buildingLogo.length > 0 && (
                          <>
                            <Input
                              name="BuildingImage"
                              id="BuildingImage"
                              type="file"
                              accept="application/pdf"
                              multiple
                              disabled={viewMedia}
                              onClick={() => {
                                window.open(
                                  buildingLogo && buildingLogo[0].imageUrl,
                                );
                              }}
                            />
                            <span className="imageAddIcon">
                              <img
                                src={buildingLogo && buildingLogo[0].imageUrl}
                                alt="buildingLogo"
                                id="BuildingImage"
                              />
                            </span>
                          </>
                        )}

                        {!viewMedia &&
                          buildingLogo &&
                          buildingLogo.length === 0 && (
                            <small className="uploadsize">
                              File type - PNG
                            </small>
                          )}
                      </>
                    )}
                    {((errorType === 'min' &&
                      errorFieldName === 'LogoValidation') ||
                      (errorFieldValue &&
                        errorFieldValue.LogoValidation &&
                        errorFieldValue.LogoValidation.length === 0)) &&
                      !buildingLogo.length > 0 && (
                        <FormHelperText
                          className={classes.errorMsg}
                          error="Image is required."
                        >
                          Logo is required.
                        </FormHelperText>
                      )}
                  </li>

                  {viewMedia && !(buildingLogo && buildingLogo.length > 0) && (
                    <p className="maxUpload">NO BUILDING LOGO</p>
                  )}
                </ul>
              </>
            )}
          </ul>

          {buildingAttributes && buildingLogo && buildingLogo.length > 0 && (
            <ul className=" ">
              <li>
                <div className="cardPrevWithLogo" id="prevCardBg">
                  <div className="cardNameAndPic">
                    <ul>
                      <li>
                        <h1>DL/MEMBER CARD</h1>
                      </li>
                      <li>
                        <img
                          src={MicImage}
                          className={classes.profileLogo}
                          alt="buildingLogo"
                        />
                      </li>
                    </ul>
                  </div>
                  <div className="prevCardDesc">
                    <h3>Michael Simons</h3>
                    <span>Derwent London plc</span>
                  </div>
                  <div className="cardDateAndLogo">
                    <ul>
                      <li>
                        <span>Expires Jan 2025</span>
                      </li>
                      {buildingLogo && buildingLogo.length > 0 && (
                        <li>
                          <img
                            src={buildingLogo[0].imageUrl}
                            alt="buildingLogo"
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          )}
        </div>
      )}
      {buildingAttributes && visitor && (
        <div className="cardPrevForBuilding">
          <ul className=" ">
            {buildingAttributes && (
              <>
                <ul className="MultipleImgUpload imgUploadVideo">
                  <li>
                    {!viewMedia && thumnail && thumnail.length > 0 && (
                      <i className="removeImage top_35">
                        <img
                          src={closeIcon}
                          role="presentation"
                          alt="closeIcon"
                          onClick={() => setThumnail([])}
                        />
                      </i>
                    )}

                    <small className="imageAddName m_0">
                      Building Thumbnail*
                    </small>
                    <small className="advisoryNoteCaption mb_10">
                      (Shown on mobile app, upcoming guests page)
                    </small>
                    {imageLoader ? (
                      <MuiThemeProvider theme={theme}>
                        <LinearProgress color="secondary" />
                      </MuiThemeProvider>
                    ) : (
                      <>
                        {!(thumnail && thumnail.length > 0) && !viewMedia && (
                          <>
                            <Input
                              name="Thumbnail"
                              id="ThumbnailImage"
                              type="button"
                              accept="application/pdf"
                              multiple
                              onClick={() =>
                                showUploadWidget(
                                  'ThumbnailImage',
                                  'image',
                                  null,
                                  `${
                                    thumnail
                                      ? buildings_media_folder
                                      : spaces_media_folder
                                  }`,
                                  [
                                    'jpg',
                                    'jpeg',
                                    'jfif',
                                    'pjpeg',
                                    'pjp',
                                    'png',
                                    'svg',
                                  ],
                                  null,
                                  null,
                                  null,
                                  null,
                                )
                              }
                            />
                            <span className="imageAddIcon imageAddIconThumbnail">
                              <img
                                src={addImage}
                                role="presentation"
                                alt="Thumbnail"
                                id="ThumbnailImage"
                              />
                            </span>
                          </>
                        )}
                        {thumnail && thumnail.length > 0 && (
                          <>
                            <Input
                              name="Thumbnail"
                              id="ThumbnailImage"
                              type="file"
                              accept="application/pdf"
                              multiple
                              disabled={viewMedia}
                              onClick={() => {
                                window.open(thumnail && thumnail[0].imageUrl);
                              }}
                            />
                            <span className="imageAddIcon top_47">
                              <img
                                src={thumnail && thumnail[0].imageUrl}
                                alt="Thumbnail"
                                id="ThumbnailImage"
                              />
                            </span>
                          </>
                        )}

                        {!viewMedia &&
                          thumnail &&
                          thumnail.length === 0 && (
                            <small className="uploadsize mrt_10">
                              Minimum Width & Height - 348px, Aspect Ratio 1:1
                            </small>
                          )}
                      </>
                    )}

                    {((errorType === 'min' &&
                      errorFieldName === 'ThumbaThumbnailValidationilVa') ||
                      (errorFieldValue &&
                        errorFieldValue.ThumbnailValidation &&
                        errorFieldValue.ThumbnailValidation.length === 0)) &&
                      !thumnail.length > 0 && (
                        <FormHelperText
                          className={classes.errorMsg}
                          error="Image is required."
                        >
                          Thumbnail is required.
                        </FormHelperText>
                      )}
                  </li>

                  {viewMedia && !(thumnail && thumnail.length > 0) && (
                    <p className="maxUpload">NO THUMBNAIL LOGO</p>
                  )}
                </ul>
              </>
            )}
          </ul>
        </div>
      )}
      <ul
        className={
          buildingAttributes && buildingLogo && buildingLogo.length > 0
            ? 'buildingAttributeImages MultipleImgUpload'
            : 'MultipleImgUpload'
        }
      >
        <li className="videoPlayBox">
          <small className="imageAddName">Video</small>
          {!viewMedia && spaceVideo && (
            <i className="removeImage">
              <img
                src={closeIcon}
                alt="closeIcon"
                role="presentation"
                onClick={() => {
                  setCreateSpace(false);
                  setAttributesToggle(false);
                  setSpaceVideo('');
                  setPreviewImage([]);
                }}
              />
            </i>
          )}
          {/* {videoLoader ? (
            <MuiThemeProvider theme={theme}>
              <LinearProgress color="secondary" />
            </MuiThemeProvider>
          ) : ( */}
          <>
            {!viewMedia && (
              <>
                <Input
                  name="inputVideoUrl"
                  id="inputVideoUrl"
                  type="button"
                  disabled={viewMedia}
                  onClick={() =>
                    showUploadWidget(
                      'inputVideoUrl',
                      'video',
                      null,
                      `${
                        buildingAttributes
                          ? buildings_media_folder
                          : spaces_media_folder
                      }`,
                      'video',
                      344,
                      200,
                      null,
                      null,
                    )
                  }
                />
                {spaceVideo ? (
                  <span className="imageAddIcon">
                    <video src={spaceVideo} autoPlay controls muted />
                  </span>
                ) : (
                  <span className="imageAddIcon">
                    <img src={addImage} alt="addImage" />
                  </span>
                )}
              </>
            )}
            {spaceVideo && viewMedia && (
              <>
                <Input
                  name="inputVideoUrl"
                  id="inputVideoUrl"
                  type="button"
                  disabled={viewMedia}
                />
                <span className="imageAddIcon">
                  <video src={spaceVideo} autoPlay controls muted />
                </span>
              </>
            )}
          </>
          {/* {!viewMedia && !spaceVideo && (
            <small className="uploadsize">
              Maximum file size 100MB, 1080px by 1080px
            </small>
          )} */}
        </li>
        {viewMedia && !spaceVideo && (
          <p className="maxUpload">NO VIDEO UPLOADED</p>
        )}
        {spaceVideo && (
          <li>
            <small className="imageAddName">Video Image</small>
            {!viewMedia && previewImage && previewImage.length > 0 && (
              <i className="removeImage">
                <img
                  src={closeIcon}
                  alt="closeIcon"
                  role="presentation"
                  onClick={() => {
                    setAttributesToggle(false);
                    setCreateSpace(false);
                    setPreviewImage([]);
                  }}
                />
              </i>
            )}
            {previewImage && previewImage.length > 0 && (
              <>
                <Input
                  name="previewImage"
                  id="previewImage"
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  multiple
                  disabled={viewMedia}
                  onClick={() => {
                    window.open(previewImage && previewImage[0].imageUrl);
                  }}
                />
                <span className="imageAddIcon">
                  <img
                    src={previewImage && previewImage[0].imageUrl}
                    alt="previewImage"
                    id="previewImage"
                  />
                </span>
                <Button
                  className="cropBtn"
                  onClick={() => {
                    setToggle(!toggle);
                    if (cropImg) setCropImg(!cropImg);
                    setImgIndex({
                      order: 1,
                      images: previewImage,
                      imageUrl: previewImage && previewImage[0].imageUrl,
                      mediaType: 'previewImage',
                    });
                  }}
                >
                  {' '}
                  {viewMedia ? 'View' : ' View / Edit'}
                </Button>
              </>
            )}
            {/* {videoImageLoader ? (
              <MuiThemeProvider theme={theme}>
                <LinearProgress color="secondary" />
              </MuiThemeProvider>
            ) : ( */}
            {!(previewImage && previewImage.length > 0) && (
              <>
                <Input
                  name="previewImage"
                  id="previewImage"
                  type="button"
                  onClick={() => {
                    showUploadWidget(
                      'previewImage',
                      'image',
                      null,
                      `${
                        buildingAttributes
                          ? buildings_media_folder
                          : spaces_media_folder
                      }`,
                      ['png', 'jpg', 'jpeg'],
                      344,
                      200,
                      null,
                      null,
                    );
                    setAttributesToggle(false);
                    setCreateSpace(false);
                  }}
                />
                <span className="imageAddIcon">
                  <img
                    src={addImage}
                    role="presentation"
                    alt="previewImage"
                    id="previewImage"
                  />
                </span>
              </>
            )}
            {/* )} */}
            {((previewImage && previewImage.length === 0) ||
              previewImage === undefined) &&
              spaceVideo.length > 0 && (
                <FormHelperText className={classes.errorMsg}>
                  {((previewImage && previewImage.length === 0) ||
                    previewImage === undefined) &&
                    spaceVideo.length > 0 &&
                    'Video image is required.'}
                </FormHelperText>
              )}
            {/* {!viewMedia && previewImage && previewImage.length === 0 && (
              <small className="uploadsize">
                Maximum file size 1MB, 1080px by 1080px
              </small>
            )} */}
          </li>
        )}
      </ul>

      <ul className="MultipleImgUpload imgUploadVideo">
        {spaceFile &&
          spaceFile.map((item, index) => {
            return (
              <li>
                {!viewMedia && (
                  <i className="removeImage">
                    <img
                      src={closeIcon}
                      alt="closeIcon"
                      role="presentation"
                      id={index}
                      onClick={e => {
                        if (toggle) setToggle(!toggle);
                        removePhoto(e, item.order);
                      }}
                    />
                  </i>
                )}
                <small className="imageAddName">
                  {index === 0 ? 'Hero Image' : `Image ${index + 1}`}
                </small>
                <span className="imageAddIconUpload">
                  <img
                    src={item.image[0].imageUrl || addImage}
                    role="presentation"
                    alt="addImage"
                    disabled={viewMedia}
                    id={index}
                    onClick={() => {
                      window.open(item.image[0].imageUrl);
                    }}
                  />
                </span>
                <Button
                  className="cropBtn"
                  onClick={() => {
                    setToggle(!toggle);
                    if (cropImg) setCropImg(!cropImg);
                    setImgIndex({
                      order: item.order,
                      images: item.image,
                      imageUrl: item.image[0].imageUrl,
                      mediaType: 'spaceImage',
                    });
                  }}
                >
                  {' '}
                  {viewMedia ? 'View' : ' View / Edit'}
                </Button>
              </li>
            );
          })}

        {!viewMedia && spaceFile && spaceFile.length < 10 && (
          <>
            <li>
              <small className="imageAddName">{`${
                (spaceFile.length === 0 && 'Hero Image*') || ''
              }`}</small>
              {/* {heroImageLoader ? (
                <MuiThemeProvider theme={theme}>
                  <LinearProgress color="secondary" />
                </MuiThemeProvider>
              ) : ( */}
              <>
                <Input
                  name="ImageSelector"
                  id="ImageSelector"
                  type="button"
                  accept="image/x-png,image/gif,image/jpeg"
                  multiple
                  onClick={() =>
                    showUploadWidget(
                      'ImageSelector',
                      'image',
                      null,
                      `${
                        buildingAttributes
                          ? buildings_media_folder
                          : spaces_media_folder
                      }`,
                      ['png', 'jpg', 'jpeg'],
                      344,
                      200,
                      null,
                      null,
                    )
                  }
                />
                <span className="imageAddIcon">
                  <img src={addImage} alt="addImage" />
                </span>
              </>
              {/* )} */}
              {((errorType === 'min' && errorFieldName === 'ImageValidation') ||
                (errorFieldValue &&
                  errorFieldValue.ImageValidation &&
                  errorFieldValue.ImageValidation.length === 0)) &&
                !spaceFile.length > 0 && (
                  <FormHelperText
                    className={classes.errorMsg}
                    error="Image is required."
                  >
                    Image is required.
                  </FormHelperText>
                )}
              {/* <small className="uploadsize">
                Maximum file size 1MB, 1080px by 1080px
              </small> */}
            </li>
            <p className="maxUpload">You may upload a maximum of 10 images</p>
          </>
        )}
        <li>
          {viewMedia && spaceFile && spaceFile.length === 0 && (
            <small className="imageAddName">Imagery</small>
          )}
        </li>

        {viewMedia && spaceFile && spaceFile.length === 0 && (
          <p className="maxUpload">NO IMAGERY UPLOADED</p>
        )}
      </ul>
      {!buildingAttributes && (
        <ul className="MultipleImgUpload imgUploadVideo">
          <li>
            {!viewMedia && floorPlan && floorPlan.length > 0 && (
              <i className="removeImage">
                <img
                  src={closeIcon}
                  role="presentation"
                  alt="closeIcon"
                  onClick={() => setFloorPlan([])}
                />
              </i>
            )}
            <small className="imageAddName">Floor plan</small>
            {imageLoader ? (
              <MuiThemeProvider theme={theme}>
                <LinearProgress color="secondary" />
              </MuiThemeProvider>
            ) : (
              <>
                {!(floorPlan && floorPlan.length > 0) &&
                  !buildingAttributes &&
                  !viewMedia && (
                    <>
                      <Input
                        name="FloorImage"
                        id="FloorImage"
                        type="button"
                        accept="application/pdf"
                        multiple
                        onClick={() =>
                          showUploadWidget(
                            'FloorImage',
                            'image',
                            null,
                            `${
                              buildingAttributes
                                ? buildings_media_folder
                                : spaces_media_folder
                            }`,
                            ['png', 'jpg', 'jpeg'],
                            344,
                            200,
                            null,
                            null,
                          )
                        }
                      />
                      <span className="imageAddIcon">
                        <img
                          src={addImage}
                          role="presentation"
                          alt="floorPlan"
                          id="FloorImage"
                        />
                      </span>
                    </>
                  )}
                {floorPlan && floorPlan.length > 0 && (
                  <>
                    <Input
                      name="FloorImage"
                      id="FloorImage"
                      type="file"
                      accept="application/pdf"
                      multiple
                      disabled={viewMedia}
                      onClick={() => {
                        window.open(floorPlan && floorPlan[0].imageUrl);
                      }}
                    />
                    <span className="imageAddIcon">
                      <img
                        src={floorPlan && floorPlan[0].imageUrl}
                        alt="floorPlan"
                        id="FloorImage"
                      />
                    </span>
                    <Button
                      className="cropBtn"
                      onClick={() => {
                        setToggle(!toggle);
                        if (cropImg) setCropImg(!cropImg);
                        setImgIndex({
                          order: 1,
                          images: floorPlan,
                          imageUrl: floorPlan && floorPlan[0].imageUrl,
                          mediaType: 'FloorImage',
                        });
                      }}
                    >
                      {' '}
                      {viewMedia ? 'View' : ' View / Edit'}
                    </Button>
                  </>
                )}
                {!viewMedia && floorPlan && floorPlan.length === 0 && (
                  <small className="uploadsize">
                    jpg or png <br /> Maximum file size 2MB
                  </small>
                )}
              </>
            )}
          </li>
          {viewMedia && !(floorPlan && floorPlan.length > 0) && (
            <p className="maxUpload">NO FLOOR PLAN UPLOADED</p>
          )}
        </ul>
      )}

      {buildingAttributes && (
        <ul className="MultipleImgUpload imgUploadVideo">
          <li>
            {!viewMedia && floorPlan && (
              <i className="removeImage">
                <img
                  src={closeIcon}
                  role="presentation"
                  alt="closeIcon"
                  onClick={() => setFloorPlan('')}
                />
              </i>
            )}
            <small className="imageAddName">Building Brochure</small>
            {imageLoader ? (
              <MuiThemeProvider theme={theme}>
                <LinearProgress color="secondary" />
              </MuiThemeProvider>
            ) : (
              <>
                {!floorPlan && !viewMedia && (
                  <>
                    <Input
                      name="FloorImage"
                      id="FloorImage"
                      type="file"
                      accept="application/pdf"
                      multiple
                      onChange={e => {
                        setImageLoader(true);
                        validatePDF(e);
                      }}
                    />
                    <span className="imageAddIcon">
                      <img
                        src={addImage}
                        role="presentation"
                        alt="floorPlan"
                        id="FloorImage"
                      />
                    </span>
                  </>
                )}
                {floorPlan && (
                  <>
                    <Input
                      name="FloorImage"
                      id="FloorImage"
                      type="file"
                      accept="application/pdf"
                      multiple
                      disabled={viewMedia}
                      onClick={() => {
                        window.open(floorPlan);
                      }}
                    />
                    <span className="imageAddIcon">
                      <img src={pdfIcon} alt="floorPlan" id="FloorImage" />
                    </span>
                  </>
                )}
                {!viewMedia && !floorPlan && (
                  <small className="uploadsize">Maximum file size 5MB</small>
                )}
              </>
            )}
          </li>
          {viewMedia && !floorPlan && (
            <p className="maxUpload">NO BROCHURE UPLOADED</p>
          )}
        </ul>
      )}
    </div>
  );
};
export default SpaceFiles;
