import React from 'react';
import { Link, useParams } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Breadcrumbs } from '@material-ui/core';

const Breadcrumb = props => {
  const { id } = useParams();
  const { breadCrumbs } = props;
  return (
    <div className="pageContainer pageBredcrumbFix">
      <div className="breadcrumbs">
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
        >
          {breadCrumbs.listBreadCrumbPath && (
            <Link color="inherit" to={breadCrumbs.listBreadCrumbPath}>
              {breadCrumbs.listBreadCrumbTitle}
            </Link>
          )}
          {breadCrumbs.viewBreadCrumbPath && (
            <Link color="inherit" to={`${breadCrumbs.viewBreadCrumbPath}${id}`}>
              {breadCrumbs.viewBreadCrumbTitle}
            </Link>
          )}
          {breadCrumbs.editBreadCrumbPath && (
            <Link color="inherit" to={`${breadCrumbs.editBreadCrumbPath}${id}`}>
              {breadCrumbs.editBreadCrumbTitle}
            </Link>
          )}
          {breadCrumbs.addBreadCrumbPath && (
            <Link color="inherit" to={`${breadCrumbs.addBreadCrumbPath}`}>
              {breadCrumbs.addBreadCrumbTitle}
            </Link>
          )}
        </Breadcrumbs>
      </div>
    </div>
  );
};

export default Breadcrumb;
