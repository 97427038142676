import { API_BASE_URL } from '../utils/config';

const apiEndPoints = {
  login: `${API_BASE_URL}signIn`,
  tenantUsers: `${API_BASE_URL}/v1/tenants/users`,
  getTenants: `${API_BASE_URL}/v1/tenants/search`,
  getBuilding: `${API_BASE_URL}/v1/buildings/search`,
  editTenent: `${API_BASE_URL}/v1/tenants`,
  getTenantsUsersList: `${API_BASE_URL}/v1/tenants/users/search`,
  getTenantsUserId: `${API_BASE_URL}/v1/tenants/users/`,
  getTenantsUserIdUpdate: `${API_BASE_URL}/v1/tenants/users/`,
  getMeetingRoomsList: `${API_BASE_URL}/v1/meeting-rooms/search`,
  getNewsAnnouncementsList: `${API_BASE_URL}/v1/articles/search`,
  getArticlesCategory: `${API_BASE_URL}/v1/article-categories`,
  getEquipment: `${API_BASE_URL}/v1/equipment`,
  getRoomLayouts: `${API_BASE_URL}/v1/room-layouts`,
  createMeetingRooms: `${API_BASE_URL}/v1/meeting-rooms`,
  getOffersList: `${API_BASE_URL}/v1/offers/search`,
  getMeetingRoomId: `${API_BASE_URL}/v1/meeting-rooms/`,
  getOffersCategory: `${API_BASE_URL}/v1/offer-categories`,
  createOffers: `${API_BASE_URL}/v1/offers`,
  getOfferDetails: `${API_BASE_URL}/v1/offers/`,
  getRolePermission: `${API_BASE_URL}/v1/role/permissions`,
  createArticale: `${API_BASE_URL}/v1/articles`,
  getArticaleCategories: `${API_BASE_URL}/v1/article-categories`,
  getCateringMenuList: `${API_BASE_URL}/v1/catering-items/search`,
  createCateringMenu: `${API_BASE_URL}/v1/catering-items`,
  cateringCategories: `${API_BASE_URL}/v1/catering-categories/search`,
  getCategoryList: `${API_BASE_URL}/v1/catering-categories/search`,
  cateringCategory: `${API_BASE_URL}/v1/catering-categories`,
  getCateringCategoryUpdate: `${API_BASE_URL}/v1/catering-categories`,
  getSpacesList: `${API_BASE_URL}/v1/spaces/search`,
  createSpace: `${API_BASE_URL}/v1/spaces`,
  getSpaceFeatures: `${API_BASE_URL}/v1/space-features?`,
  getcateringCategoryDetails: `${API_BASE_URL}/v1/catering-categories/`,
  getBookingList: `${API_BASE_URL}/v1/bookings/search`,
  createMrBooking: `${API_BASE_URL}/v1/bookings`,
  deleteBlockMrBooking: `${API_BASE_URL}/v1/delete/blocked-room/`,
  cancelMrBooking: `${API_BASE_URL}/v1/bookings/`,
  getCateringCategories: `${API_BASE_URL}/v1/catering-menu-order-list`,
  cancelCatering: `${API_BASE_URL}/v1/bookings`,
  getBuildingAttributesList: `${API_BASE_URL}/v1/buildings/search`,
  getBuildingAttributes: `${API_BASE_URL}/v1/buildings`,
  getBuildingFeatures: `${API_BASE_URL}/v1/building-features`,
  getVat: `${API_BASE_URL}/v1/vat?startDate=`,
  logAct: `${API_BASE_URL}/v1/logSiSo`,
  getEventsList: `${API_BASE_URL}/v1/events/search`,
  eventDetails: `${API_BASE_URL}/v1/events`,
  getEventDetails: `${API_BASE_URL}/v1/events/`,
  exportList: `${API_BASE_URL}/v1/export`,
  eventExportList: `${API_BASE_URL}/v1/export?eventBooking=true`,
  offerCards: `${API_BASE_URL}/v1/saveOfferCards`,
  allOfferCards: `${API_BASE_URL}/v1/offerCards/search`,
  allLoopVideoCards: `${API_BASE_URL}/v1/loopVideoCards/search`,
  saveLoopVideos: `${API_BASE_URL}/v1/saveLoopVideoCards`,
  getEventBooKings: `${API_BASE_URL}/v1/eventsAdmin`,
  cancelEvent: `${API_BASE_URL}/v1/cancelEvent`,
  getJobTitles: `${API_BASE_URL}/v1/jobtitles`,
  staffNotes: `${API_BASE_URL}/v1/staffNotes`,
  voucherCodes: `${API_BASE_URL}/v1/voucherCode`,
  getVoucherCode: `${API_BASE_URL}/v1/getVoucherCode`,
  bookingExtend: `${API_BASE_URL}/v1/bookingExtend`,
  cancelExtend: `${API_BASE_URL}/v1/cancelExtend`,
  extendSavedCards: `${API_BASE_URL}/v1/cardsAdmin`,
  failedPayments: `${API_BASE_URL}/v1/failedBooking`,
  failedEventsPayments: `${API_BASE_URL}/v1/events-payments`,
  getFailedEventDetail: `${API_BASE_URL}/v1/failedEventBooking`,
  getLoyaltyCards: `${API_BASE_URL}/v1/coffeLoyalty`,
  loyaltyCard: `${API_BASE_URL}/v1/loyaltyCard`,
  getLoyaltyCardAdmin: `${API_BASE_URL}/v1/coffeLoyaltyAdmin`,
  getLoyaltyExport: `${API_BASE_URL}/v1/export?coffee=true`,
  getNotifications: `${API_BASE_URL}/v1/notifications`,
  dnaEvent: `${API_BASE_URL}/v1/dnaEvent`,
  requestGuestList: `${API_BASE_URL}/v1/requestGuestList`,
  saveGuestList: `${API_BASE_URL}/v1/saveGuestList`,
  saveUserGuestList: `${API_BASE_URL}/v1/saveUserGuestList`,
  getPaymentIntent:`${API_BASE_URL}/v1/getpaymentintent`,
  completeMeetingRoomBooingWallet:`${API_BASE_URL}/v1/complete-meetingroom-booking/wallet`,
  completEventBookingWallet:`${API_BASE_URL}/v1/complete-event-booking/wallet`,
  getTerminals:`${API_BASE_URL}/v1/getTerminals`,
  getPosPaymentStatus:`${API_BASE_URL}/v1/getPosPaymentStatus`,
  verifyPosSignature: `${API_BASE_URL}/v1/verifyPosSignature`,
  payLink:`${API_BASE_URL}/v1/paymentLink`,
};
export default apiEndPoints;
