/* eslint-disable import/no-cycle */
import React, { createContext, useState, useEffect } from 'react';
import MeetingRoomsList from '../containers/MeetingRooms/MeetingRoomsList';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';

export const MeetingRoomsContext = createContext({});

export const MeetingRoomContextProvider = () => {
  const [meetingRoomsList, setMeetingRoomsList] = useState([]);
  const [meetingRoomCreateMsg, setMeetingRoomCreateMsg] = useState('');
  const [meetingRoomSearch, setMeetingRoomSearch] = useState('');
  const [page, setPage] = React.useState(0);
  const [offset, setOffset] = useState(0);
  const [totalMeetingRoomsCount, setTotalMeetingRoomsCount] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedRoomType, setSelectedRoomType] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortBy, setSortBy] = useState('nameSort');
  const [loading, setLoading] = useState(true);
  const [statusActive, setStatusActive] = useState('');
  const [statusInactive, setStatusInactive] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [villages, setVillages] = useState([]);
  const [statuses, setStatuses] = useState([
    { label: 'Active', key: 'Active', selected: false },
    { label: 'Inactive', key: 'Inactive', selected: false },
  ]);

  const [roomTypes, setRoomTypes] = useState([
    { name: 'Single', key: 'Single', selected: false },
    { name: 'Combined', key: 'Combined', selected: false },
    { name: 'Mergeable', key: 'Mergeable', selected: false },
  ]);
  const meetingRoomSearchResult =
    meetingRoomSearch.length > 0 ? meetingRoomSearch : '';

  const getMeetingRoomsListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getMeetingRoomsList, payload)
      .then(data => {
        setMeetingRoomsList(data && data.data && data.data.data);
        setTotalMeetingRoomsCount(data && data.data.count);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  useEffect(() => {
    const updatedStatus = [];
    statuses.filter(status => {
      if (status.selected) {
        updatedStatus.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedStatus(updatedStatus);
    const updatedRoomTypes = [];
    roomTypes.filter(status => {
      if (status.selected) {
        updatedRoomTypes.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedRoomType(updatedRoomTypes);
    getMeetingRoomsListAction({
      searchText: meetingRoomSearchResult ? meetingRoomSearch : '',
      buildings: buildings && buildings.length > 0 ? buildings : undefined,
      status:
        updatedStatus && updatedStatus.length > 0 ? updatedStatus : ['Active'],
      roomType:
        updatedRoomTypes && updatedRoomTypes.length > 0 && updatedRoomTypes,
      sortOrder,
      sortBy,
      offset,
      limit: 40,
    });
  }, [
    sortBy,
    sortOrder,
    offset,
    statuses,
    meetingRoomSearchResult,
    buildings,
    roomTypes,
  ]);

  return (
    <MeetingRoomsContext.Provider
      value={{
        meetingRoomsList,
        meetingRoomCreateMsg,
        setMeetingRoomCreateMsg,
        meetingRoomSearch,
        setMeetingRoomSearch,
        selectedStatus,
        setSelectedStatus,
        selectedRoomType,
        setSelectedRoomType,
        statusActive,
        setStatusActive,
        statusInactive,
        setStatusInactive,
        villages,
        setVillages,
        buildings,
        setBuildings,
        loading,
        page,
        setPage,
        setOffset,
        statuses,
        setStatuses,
        roomTypes,
        setRoomTypes,
        totalMeetingRoomsCount,
        setSortOrder,
        sortOrder,
        setSortBy,
        sortBy,
      }}
    >
      <MeetingRoomsList />
    </MeetingRoomsContext.Provider>
  );
};
