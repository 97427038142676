/* eslint-disable no-console */
import axios from 'axios';
import { isUndefined } from 'lodash';
import { AZURE_PROFILE_FUN_KEY, CLIENT_ID } from '../utils/config';
import apiEndPoints from './apiEndPoints';
import { authContext } from '../adal';

const checkExpiration = ephocDate => {
  const expirationDate = new Date(ephocDate * 1000);
  if (
    new Date().toISOString() > expirationDate.toISOString() ||
    ephocDate === '0'
  ) {
    authContext.handleWindowCallback(window.location.origin);
    return new Promise((resolve, reject) => {
      authContext.acquireToken(CLIENT_ID, (error, token) => {
        if (error) {
          reject(error);
        }
        resolve(token);
      });
    });
  }
  return true;
};

function getDefaultOptions() {
  return {
    crossDomain: true,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('adal.idtoken')}`,
      'content-type': 'application/json',
      'x-functions-key': AZURE_PROFILE_FUN_KEY,
    },
  };
}
function validateUser(res) {
  res.then(
    () => {},
    error => {
      if (isUndefined(error.status)) {
        // localStorage.clear();
        localStorage.setItem(
          'networkErr',
          'Something went wrong, please try again after some time.',
        );
        // window.location.href = '/';
        if (error && error.response && error.response.status === 409) {
          localStorage.setItem('email_check', 'Email already exists');
        }
      } else if (
        error.response.status === 401 &&
        apiEndPoints.getClentDetails !== error.response.config.url
      ) {
        // localStorage.clear();
        // window.location.href = '/';
      }
    },
  );
}
const ApiClient = {
  buildUrl(path) {
    return path;
  },

  async apiGet(path) {
    try {
      const token = await checkExpiration(
        localStorage.getItem(`adal.expiration.key${CLIENT_ID}`),
      );
      if (token) {
        const res = axios.get(path, getDefaultOptions());
        validateUser(res);
        return res;
      }
    } catch (err) {
      window.alert('Sorry, could not refresh the token. Please login again.');
      authContext.logOut();
      return '';
    }
    const res = axios.get(path, getDefaultOptions());
    validateUser(res);
    return res;
  },
  async apiPost(path, payload, options) {
    try {
      const token = await checkExpiration(
        localStorage.getItem(`adal.expiration.key${CLIENT_ID}`),
      );
      if (token) {
        const optionsWithDefaults = { ...getDefaultOptions(), ...options };
        const res = axios.post(
          this.buildUrl(path),
          payload,
          optionsWithDefaults,
        );
        validateUser(res);
        return res;
      }
    } catch (err) {
      window.alert('Sorry, could not refresh the token. Please login again.');
      authContext.logOut();
      return '';
    }
    const optionsWithDefaults = { ...getDefaultOptions(), ...options };
    const res = axios.post(this.buildUrl(path), payload, optionsWithDefaults);
    validateUser(res);
    return res;
  },
  async apiformPost(path, payload) {
    try {
      const token = await checkExpiration(
        localStorage.getItem(`adal.expiration.key${CLIENT_ID}`),
      );
      if (token) {
        const formData = new FormData();
        formData.append('file', payload);
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('adal.idtoken')}`,
            accesstoken: `Web ${localStorage.getItem('accesstoken')}`,
            'content-type': 'multipart/form-data',
          },
        };
        const res = axios.post(this.buildUrl(path), formData, config);
        validateUser(res);
        return res;
      }
    } catch (err) {
      window.alert('Sorry, could not refresh the token. Please login again.');
      authContext.logOut();
      return '';
    }
    const formData = new FormData();
    formData.append('file', payload);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('adal.idtoken')}`,
        accesstoken: `Web ${localStorage.getItem('accesstoken')}`,
        'content-type': 'multipart/form-data',
      },
    };
    const res = axios.post(this.buildUrl(path), formData, config);
    validateUser(res);
    return res;
  },
  async apiPut(path, payload, options) {
    try {
      const token = await checkExpiration(
        localStorage.getItem(`adal.expiration.key${CLIENT_ID}`),
      );
      if (token) {
        const optionsWithDefaults = { ...getDefaultOptions(), ...options };
        const res = axios.put(
          this.buildUrl(path),
          payload,
          optionsWithDefaults,
        );
        validateUser(res);
        return res;
      }
    } catch (err) {
      window.alert('Sorry, could not refresh the token. Please login again.');
      authContext.logOut();
      return '';
    }
    const optionsWithDefaults = { ...getDefaultOptions(), ...options };
    const res = axios.put(this.buildUrl(path), payload, optionsWithDefaults);
    validateUser(res);
    return res;
  },
  async apiDelete(path, payload, options) {
    try {
      const token = await checkExpiration(
        localStorage.getItem(`adal.expiration.key${CLIENT_ID}`),
      );
      if (token) {
        const optionsWithDefaults = {
          ...getDefaultOptions(),
          ...options,
          data: payload,
        };
        const res = axios.delete(this.buildUrl(path), optionsWithDefaults);
        validateUser(res);
        return res;
      }
    } catch (error) {
      window.alert('Sorry, could not refresh the token. Please login again.');
      authContext.logOut();
      return '';
    }
    const optionsWithDefaults = {
      ...getDefaultOptions(),
      ...options,
      data: payload,
    };
    const res = axios.delete(this.buildUrl(path), optionsWithDefaults);
    validateUser(res);
    return res;
  },
};

export default ApiClient;
