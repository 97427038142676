import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  CircularProgress,
} from '@material-ui/core';
import { Link, useParams } from 'react-router-dom';
import dowmArw from '../../images/down-arw.svg';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import buttonsEditPencil from '../../images/buttons-edit-w-pencil.svg';
// import './createcategory.css';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { GlobalContext } from '../../context/GlobalContext';
import CloseButton from '../../components/Buttons/CloseButton';
// import supplierLogo from '../../images/sipplier-icon.svg';
// import buildingLogo from '../../images/building-icon.svg';
import coffeeBeans from '../../images/Coffee_Icons-05.svg';
import FileUpload from '../../components/ImageUpload/FileUpload';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '70%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        minHeight: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
}));

const ViewLoyaltyCard = () => {
  const { rolePermission } = useContext(GlobalContext);
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [cardData, setCardData] = useState('');
  const [images, setImages] = useState([]);
  const [logoImages, setLogoImages] = useState([]);
  const [backgroundColor, setBackgroundColor] = useState('');
  const [fontColor, setFontColor] = useState('');
  const [stamps, setStamps] = useState('');
  const [coffeeImg, setCoffeImages] = useState([]);
  const [brandedCoffeeCupLogo, setbrandedCoffeeCupLogo] = useState([]);

  useEffect(() => {
    const payload = {
      offset: 0,
      limit: 20,
    };
    ApiClient.apiPost(apiEndPoints.getLoyaltyCardAdmin, payload)
      .then(data => {
        const cateringData = data && data.data && data.data.data;
        const loyaltyData = cateringData.find(card => card.id === id);
        setCardData(loyaltyData);
        setLoading(false);
        setStamps(loyaltyData.stamps);
        setImages(
          (cardData && [
            { imageUrl: cardData.card.supplierLogo, tag: 'Card' },
          ]) ||
            [],
        );
        setLogoImages(
          (cardData &&
            cardData.card.buildingNameLogo && [
              { imageUrl: cardData.card.buildingNameLogo, tag: 'Card' },
            ]) ||
            [],
        );
        setbrandedCoffeeCupLogo(
          (cardData &&
            cardData.card.brandedCoffeeCupLogo && [
              { imageUrl: cardData.card.brandedCoffeeCupLogo, tag: 'Card' },
            ]) ||
            [],
        );
        setBackgroundColor(cardData && cardData.card.backgroundColor);
        setFontColor(cardData && cardData.card.fontColor);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
        setLoading(false);
      });
  }, [stamps]);
  useEffect(() => {
    const coffeeImages = [];
    for (let i = 0; i < stamps; i++) {
      // eslint-disable-next-line no-unused-expressions
      brandedCoffeeCupLogo && brandedCoffeeCupLogo.length ? coffeeImages.push(<img src={brandedCoffeeCupLogo[0].imageUrl} alt="coffeeCupLogo" />) :coffeeImages.push(<img src={coffeeBeans} alt="coffeeCupLogo" />);
    }
    setCoffeImages(coffeeImages);
  }, [stamps,brandedCoffeeCupLogo]);
  const breadCrumbs = {
    listBreadCrumbPath: '/loyaltycardlist',
    listBreadCrumbTitle: 'Loyalty Card List',
    viewBreadCrumbPath: '/viewloyaltycard/',
    viewBreadCrumbTitle: 'View Loyalty Card',
  };

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addUserContainer">
            <div className={`${classes.addUserForm}`}>
              {rolePermission && rolePermission.editCateringCategory && (
                <Link to={`/edit-loyaltycard/${id}`}>
                  <div className="ditIcon">
                    <img src={buttonsEditPencil} alt="buttonsEditPencil" />
                  </div>
                </Link>
              )}
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Supplier Details</FormLabel>
                </li>
              </ul>
              <ul className={`${classes.InputSection} inputSectionAlnBtm`}>
                <li>
                  <span>Supplier Name*</span>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={cardData.supplierName}
                  />
                </li>
                <li>
                  <span>Supplier Building*</span>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={cardData.supplierLocation.name}
                  />
                </li>
                <li>
                  <span>Latitude Co-Ordinate*</span>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={cardData.supplierLocation.latitude}
                  />
                </li>
                <li>
                  <span>Longitude Co-Ordinate*</span>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={cardData.supplierLocation.longitude}
                  />
                </li>
                <li>
                  <span>Number Of Stamps Required*</span>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={cardData.stamps}
                  />
                </li>
                <li className="">
                  <span>NFC Identifier*</span>
                  <Input
                    disabled
                    readOnly
                    type="text"
                    value={cardData.nfcChipId}
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="inputSectionAlnBtm">
                <li>
                  <ul className={`${classes.InputSection} inputSectionAlnBtm`}>
                    <li className="loyaltyCardFullW">
                      <span>Card Background Color*</span>
                      <Input
                        disabled
                        readOnly
                        // type="color"
                        value={backgroundColor}
                      />
                    </li>
                    <li className="loyaltyCardFullW">
                      <span>Card Font color*</span>
                      <Input
                        disabled
                        readOnly
                        // type="color"
                        value={fontColor}
                      />
                    </li>
                    <li className="loyaltyCardFullW">
                      <span>Card Description</span>
                      <textarea
                        readOnly
                        value={cardData.card.description}
                        type="text"
                        inputProps={{ 'aria-label': 'description' }}
                      />
                    </li>
                  </ul>

                  <ul className="offerFormList">
                    <li>
                      <FormLabel component="legend">Media</FormLabel>
                    </li>
                  </ul>
                  <ul className="offerImgUoload">
                    <li>
                      <FileUpload
                        values={{
                          images,
                          setImages,
                          logoImages,
                          setLogoImages,
                          brandedCoffeeCupLogo,
                          setbrandedCoffeeCupLogo,
                        }}
                        loyaltyCardSupplier
                        loyaltyCardBuilding
                        loyaltyCardCoffee
                        viewMedia
                      />
                    </li>
                  </ul>
                </li>

                <li>
                  <div
                    className="loyaltyCardPrev"
                    style={{ background: backgroundColor }}
                  >
                    <div className="cardLogo">
                      <ul>
                        {images && images.length > 0 && (
                          <li>
                            <img src={images[0].imageUrl} alt="supplierLogo" />
                          </li>
                        )}

                        {logoImages && logoImages.length > 0 && (
                          <li>
                            <img
                              src={logoImages[0].imageUrl}
                              alt="buildingLogo"
                            />
                          </li>
                        )}
                      </ul>
                    </div>
                    <div className="latantaCardDesc">
                      <p style={{ color: fontColor }}>
                        {cardData.card.description}
                      </p>
                    </div>
                    <div className="latantaCupAdded">
                      <ol>
                        {coffeeImg &&
                          coffeeImg.map(cf => {
                            return (
                              <li>
                                <img  className='max_height_100p' src={cf.props.src} alt="coffeeCupLogo" />
                              </li>
                            );
                          })}
                      </ol>
                    </div>
                  </div>
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className="offerFormList">
                <li className="statusMarBtm">
                  <FormLabel component="legend">Status</FormLabel>
                </li>
              </ul>
              <ul className={classes.InputSection}>
                <li>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        <p>{cardData && cardData?.status}</p>
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
              </ul>
              <CloseButton route="/loyaltycardlist" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewLoyaltyCard;