/* eslint-disable import/no-cycle */
import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment';
import LoyaltyCardList from '../containers/LoyaltyCards/LoyaltyCardList';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';

export const LoyaltyCardContext = createContext({});

export const LoyaltyCardContextProvider = () => {
  const [cardList, setCardList] = useState([]);
  const [voucherSearch, setVoucherSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [exportList, setExportList] = useState(false);
  const [page, setPage] = React.useState(0);
  const [offset, setOffset] = useState(0);
  const [totalCardCount, setTotalCardCount] = useState(0);
  const [selectedDiscountType, setSelectedDiscountType] = useState([]);
  const [statuses, setStatuses] = useState([
    { label: 'Active', key: 'Active', selected: false },
    { label: 'Inactive', key: 'Inactive', selected: false },
  ]);
  const [supplier, setSupplier] = useState([
    { label: 'Supplier1', key: 'Supplier1', selected: false },
    { label: 'Supplier2', key: 'Supplier2', selected: false },
  ]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [statusActive, setStatusActive] = useState('');
  const [statusInactive, setStatusInactive] = useState('');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [sortBy, setSortBy] = useState('supplierName');
  const [updatedStamps, setUpdatedStamps] = useState('');
  const [updatedRewards, setUpdatedRewards] = useState('');

  const startDateApiReq = `${moment
    .utc(selectedStartDate)
    .set('hours', 0)
    .set('minutes', 0)
    .set('seconds', 0)}`;

  const endDateApiReq = `${moment
    .utc(selectedEndDate)
    .set('hours', 23)
    .set('minutes', 59)
    .set('seconds', 0)}`;

  const getCardListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getLoyaltyCardAdmin, payload)
      .then(data => {
        setCardList(data && data.data && data.data.data);
        setTotalCardCount(data && data.data.count);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  const getCardsExport = payload => {
    ApiClient.apiPut(`${apiEndPoints.getLoyaltyExport}`, payload)
      .then(data => {
        const link = document.createElement('a');
        link.href = data && data.data.data.link;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      })
      .catch(error => {
        alert(error, 'error');
      });
  };
  useEffect(() => {
    const statusUpdate = [];
    statuses.filter(status => {
      if (status.selected) {
        statusUpdate.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedStatus(statusUpdate);
    if (exportList) {
      getCardsExport({
        startDate:
          selectedStartDate && selectedEndDate
            ? moment(startDateApiReq).toISOString()
            : undefined,
        endDate:
          selectedStartDate && selectedEndDate
            ? moment(endDateApiReq).toISOString()
            : undefined,
        supplierName: voucherSearch.length > 2 ? voucherSearch : undefined,
        status:
          statusUpdate && statusUpdate.length > 0 ? statusUpdate : ['Active'],
        offset,
        limit: 40,
      });
    }

  },[exportList])

  useEffect(() => {
    const statusUpdate = [];
    statuses.filter(status => {
      if (status.selected) {
        statusUpdate.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedStatus(statusUpdate);
    getCardListAction({
      supplierName: voucherSearch.length > 2 ? voucherSearch : undefined,
      startDate:
        selectedStartDate && selectedEndDate
          ? moment(startDateApiReq).toISOString()
          : undefined,
      endDate:
        selectedStartDate && selectedEndDate
          ? moment(endDateApiReq).toISOString()
          : undefined,
      sortOrder,
      sortBy,
      status:
        statusUpdate && statusUpdate.length > 0 ? statusUpdate : ['Active'],
      offset,
      limit: 40,
    });
  }, [
    voucherSearch,
    offset,
    sortOrder,
    sortBy,
    selectedStartDate,
    selectedEndDate,
    statuses,
  ]);

  return (
    <LoyaltyCardContext.Provider
      value={{
        cardList,
        setCardList,
        getCardListAction,
        loading,
        setLoading,
        voucherSearch,
        setVoucherSearch,
        page,
        setPage,
        setOffset,
        totalCardCount,
        setTotalCardCount,
        statuses,
        setStatuses,
        selectedStatus,
        setSelectedStatus,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        statusActive,
        setStatusActive,
        statusInactive,
        setStatusInactive,
        selectedDiscountType,
        setSelectedDiscountType,
        setSortOrder,
        sortOrder,
        setSortBy,
        sortBy,
        exportList,
        setExportList,
        supplier,
        setSupplier,
        updatedStamps,
        setUpdatedStamps,
        updatedRewards,
        setUpdatedRewards,
      }}
    >
      <LoyaltyCardList />
    </LoyaltyCardContext.Provider>
  );
};