import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import editIcon from '../../images/edit-icon.svg';

const useStyles = makeStyles(() => ({
  editIcon: {
    margin: '0 auto',
    cursor: 'pointer',
    position: 'relative',
    top: '5px',
  },
}));

const EditButton = ({ route }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <img
      src={editIcon}
      className={classes.editIcon}
      alt="editIcon"
      role="presentation"
      onClick={() => {
        history.push(route);
      }}
    />
  );
};

export default EditButton;
