/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import StatusFilter from '../../components/Filters/StatusFilter';
import exportLogo from '../../images/export.svg';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import DateRangeFilter from '../../components/Filters/DateRangeFilter';
import Pagination from '../../components/Pagination/Pagination';
import useFilterStyles from '../../components/Styles/useFilterStyles';
import { LoyaltyCardContext } from '../../context/LoyaltyCardContext';

const LoyaltyCardFilter = () => {
  const {
        voucherSearch,
        setVoucherSearch,
        page,
        setPage,
        setOffset,
        totalCardCount,
        statuses,
        setStatuses,
        selectedStatus,
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        setExportList,
  } = useContext(LoyaltyCardContext);
  const classes = useFilterStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [statusSearch, setStatusSearch] = useState(false);
  const [onCheckStatusButton, setOnCheckStatusButton] = useState('');
  const [dateRangeSearch, setDateRangeSearch] = useState(false);
  const [onSelectDateRangeButton, setOnSelectDateRangeButton] = useState('');

  const placeholder = 'Type a Supplier Name..';
  const exportCsv = () => {
    setTimeout(() => {
      setExportList(true);
    }, 1000);
    setExportList(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDateRangeSearch(null);
    setOnCheckStatusButton(false);
    setOnSelectDateRangeButton(false);
    setStatusSearch(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const ClearFilter = () => {
    setStatuses([
      { label: 'Active', key: 'Active', selected: false },
      { label: 'Inactive', key: 'Inactive', selected: false },
    ]);
    setAnchorEl('');
    setSelectedStartDate(null);
    setSelectedEndDate(null);    
  };

  return (
    <div className={classes.filerSection}>
      <div className="pageContainer filterContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftFilterIn`}>
              <StatusFilter
                values={{
                  id,
                  anchorEl,
                  setAnchorEl,
                  handleClose,
                  selectedStatus,
                  statuses,
                  setStatuses,
                  statusSearch,
                  setStatusSearch,
                  onCheckStatusButton,
                  setOnCheckStatusButton,
                }}
                activeStatus="loyaltyCard"
              />
               <DateRangeFilter
                values={{
                  id,
                  handleClose,
                  anchorEl,
                  setAnchorEl,
                  selectedStartDate,
                  setSelectedStartDate,
                  selectedEndDate,
                  setSelectedEndDate,
                  dateRangeSearch,
                  setDateRangeSearch,
                  onSelectDateRangeButton,
                  setOnSelectDateRangeButton,
                }}
                title="Scan Date"
              />
              <li role="presentation" onClick={ClearFilter}>
                Clear Filters
              </li>
            </ul>
          </div>
          <div className={`${classes.rightFilter} rightFilterPagination`}>
            <Pagination
              page={page}
              setPage={setPage}
              setOffset={setOffset}
              totalTenants={totalCardCount}
            />          
          <div className={`${classes.exportSerch} exportSerch`}>
              <ul>
                <SearchFilter
                  customerSearch={voucherSearch}
                  setCustomerSearch={setVoucherSearch}
                  placeholder={placeholder}
                />
                <li
                  role="presentation"
                  onClick={() => {
                    exportCsv();
                  }}
                >
                  <img src={exportLogo} alt="exportLogo" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyCardFilter;
