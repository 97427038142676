/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Typography,
  CircularProgress,
  Backdrop,
  Input,
  FormHelperText,
} from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './mrbooking.css';
import { sendLinkSchema } from '../../utils/formValidate';
import MeetingRoom from './MeetingRoom';
import CustomerDetails from './CustomerDetails';
import ExtendPreview from './ExtendPreview';
import { GlobalContext } from '../../context/GlobalContext';
import CateringBooking from './CateringBooking';
import workshopstyle from '../../images/icons-workshop-1.svg';
import theatrestyle from '../../images/icons-workshop-3.svg';
import boardroomstyle from '../../images/icons-workshop-2.svg';
import popup_close from '../../images/popup_close.svg';
import cardLogo1 from '../../images/ic_cardVisa.svg';
import cardLogo2 from '../../images/ic_cardMasterCard.svg';
import cardLogo3 from '../../images/ic_cardAmex.svg';
import cardLogo4 from '../../images/ic_cardApplePay.svg';
import cardLogo5 from '../../images/ic_gpay.svg';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { MrExtendBookingContext } from '../../context/MRExtendBookingContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import {
  cardType,
  hour,
  meetingRoomBookingTimer,
  messages,
} from '../../utils/constants';
import { handleClickOpenTerminals, TerminalDialog } from './MeetingRoomBooking';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  formButton: {
    marginTop: '0',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& Button': {
      height: '47px',
      lineHeight: '47px',
      border: '2px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff',
      marginLeft: '30px',
      cursor: 'pointer',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      minWidth: '33%',
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#000',
        border: 'none',
      },
    },
  },
  cardPopupFormButton: {
    justifyContent: 'center',
    '& button': {
      margin: '0 auto !important',
      textTransform: 'initial',
      '& small': {
        marginLeft: '7px',
      },
    },
  },
  extendPopupFormButton: {
    '& button': {
      '&:first-child': {
        background: '#000',
        color: '#fff !important',
        border: '2px solid #000',
        padding: '0 32px',
        marginTop: 20,
      },
    },
  },
  holdBtn: {
    background: 'transparent !important',
    color: '#000 !important',
    outline: ' none !important',
    boxShadow: 'none !important',
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  errorColor: {
    color: '#d60303 !important',
  },
  bookingTimeLeft: {
    fontSize: '16px',
    lineHeight: '22px',
    color: '#838384',
    zIndex: '999999',
    position: 'absolute',
    right: '30px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  bookingTimeWarning: {
    color: '#cb0000',
  },
  activeCard: {
    borderColor: '#000 !important',
    '& button': {
      borderColor: '#000 !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  flexNone: {
    display: 'block !important',
  },
  mb_20: {
    marginBottom: '20px !important',
  },
});

function getSteps() {
  return ['Meeting Room', 'Catering', 'Customer Details', 'Order Preview'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <MeetingRoom />;
    case 1:
      return <CateringBooking />;
    case 2:
      return <CustomerDetails />;
    case 3:
      return <ExtendPreview />;
    default:
      return 'Unknown stepIndex';
  }
}

const ExtendMeetingRoomBooking = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    newMeetingRoomsList,
    setSuccessMessage,
    extendRoomPrice,
    extendData,
    extendTime,
    eventType,
    customerDetails,
    extendSubtotal,
    extendVat,
    extendRoomTotal,
    extendDiscount,
    extendMeetingRoomList,
    setExtendMeetingRoomList,
    buidlingLocalStorage,
    isCatering,
    paymentServiceProvider,
  } = useContext(GlobalContext);
  const [fullDayPrice, setFullDayPrice] = useState('');
  const [hourlyPrice, setHourlyPrice] = useState('');
  const [endTimeBuffer] = useState(
    moment
      .tz(extendData.endDate, 'Europe/London')
      .add(extendTime, 'minutes')
      .format('hh:mm a'),
  );
  const {
    bookingDate,
    endNewDate,
    mrRoomId,
    mrRoomName,
    setMrRoomName,
    setCreateMeetingRoom,
    layoutName,
    setBuildingId,
    village,
    setVillage,
    buildingName,
    setBuildingName,
    mrCapacity,
    setMrCapacity,
    startTime,
    customerType,
    tenant,
    firstName,
    lastName,
    phoneNumber,
    email,
    fullDay,
    setCustomerDetailsToggle,
    activeStep,
    setActiveStep,
    mrBookingId,
    setMinimumBookingTime,
    setMrPayment,
    mrPaymentPos,
    setMrPaymentPos,
    mrPaymentPosMessage,
    mrCardPayment,
    setCardPayment,
    cartItems,
    setCreateCateringToggle,
    vat,
    setVat,
    selectedMenuItems,
    terminalId,
    setTerminalId,
    terminals,
    setTerminals,
    circularLoading,
    setCircularLoading,
    loaderNotification,
  } = useContext(MrExtendBookingContext);
  const steps = getSteps();
  const [mrBooikingStatus, setMrBooikingStatus] = useState(false);
  const [warningPopUp, setWarningPopUp] = useState(false);
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');
  const [timeOut, setTimeOut] = useState(false);
  const [cardDetails, setCardDetails] = useState([]);
  const [savedCardId, setCardId] = useState('');
  const [, setCard] = useState('');
  const [isExpanded, setIsExpanded] = useState('');
  const payToAccountCheck = localStorage.getItem('payToAccount');
  const [open, setOpen] = React.useState(false);
  const [openTerminalDialog, setOpenTerminalDialog] = useState();
  const [openSendLinkDialog, setOpenSendLinkDialog] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userConfirmEmail, setUserConfirmEmail] = useState('');
  const [mrPaymentLink, setMrPaymentLink] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState(null);

  const errorType = errorMsgs?.type;
  const errorFieldName = errorMsgs?.path;
  const currentIso = moment(new Date().toISOString());
  const startDate = moment(extendData?.startDate);
  const timeDiff = moment.duration(startDate.diff(currentIso)).asHours();

  const handleDialogClose = () => {
    setOpen(false);
    setCardId('');
  };

  const handleChange = panel => (event, expanded) => {
    setIsExpanded(expanded ? panel : false);
  };
  const endDateTime = new Date(
    `${moment(bookingDate).format('ddd MMM DD YYYY')} ${endNewDate}`,
  );
  const startDateTime = new Date(
    `${moment(bookingDate).format('ddd MMM DD YYYY')} ${startTime}`,
  );

  const popUpOpen = () => {
    setWarningPopUp(true);
  };

  const popUpClose = () => {
    setWarningPopUp(false);
  };

  let interval;
  const currentTime = meetingRoomBookingTimer;
  let countDownTime = currentTime * 60;

  const startTimer = () => {
    interval = setInterval(() => {
      const timeInMinutes = Math.floor(countDownTime / 60);
      const timeInSeconds = Math.floor(countDownTime % 60);
      countDownTime--;
      if (timeInMinutes < 0) {
        clearInterval(interval.current);
        setTimeOut(true);
        popUpOpen();
      } else {
        setMinutes(timeInMinutes);
        setSeconds(timeInSeconds);
      }
    }, 1000);
  };
  const stausCall1 = value => {
    const payload = {
      status: value,
    };
    ApiClient.apiPut(`${apiEndPoints.cancelExtend}/${extendData.id}`, payload)
      .then(() => {
        history.push('/bookinglist');
      })
      .catch(error => {
        const err = error;
        alert(err.response.data.message);
      });
  };

  useEffect(() => {
    if (mrBookingId) {
      startTimer();
    }
    if (!mrRoomId) {
      if (buidlingLocalStorage) history.push('./');
    }
  }, [mrBookingId, !mrRoomId]);

  useEffect(() => {
    const meetingRoomData =
      newMeetingRoomsList && newMeetingRoomsList.filter(x => x.id === mrRoomId);
    if (newMeetingRoomsList && newMeetingRoomsList.length > 0) {
      const buildingObj = meetingRoomData && meetingRoomData[0].building;
      setBuildingId(buildingObj.id);
      setBuildingName(buildingObj.name);
      setVillage(buildingObj.village);
    }
  }, [mrRoomId]);

  useEffect(() => {
    const roomId =
      newMeetingRoomsList &&
      newMeetingRoomsList.filter(data => data.id === mrRoomId);
    setMrRoomName(roomId && roomId[0] && roomId[0].name);
  }, [mrRoomId]);

  useEffect(() => {
    const meetingRoomId =
      extendData && extendData.meetingRoom && extendData.meetingRoom.id;
    ApiClient.apiGet(apiEndPoints.getMeetingRoomId + meetingRoomId)
      .then(data => {
        setExtendMeetingRoomList(data && data.data && data.data.data);
        setHourlyPrice(extendMeetingRoomList.hourlyPrice);
        setFullDayPrice(extendMeetingRoomList.fullDayPrice);
        setMrCapacity(extendMeetingRoomList.maxCapacity);
        setMinimumBookingTime(extendMeetingRoomList.minimumBookingTime);
      })
      .catch(error => {
        alert(error);
      });
  }, []);

  useEffect(() => {
    setHourlyPrice(extendMeetingRoomList.hourlyPrice);
  }, [extendMeetingRoomList]);

  const startTimeInLtFormat = moment(
    extendData &&
      extendData.extendBooking &&
      extendData.extendBooking.startDate,
  );
  const endTImeInLtFormat = extendData.extendBooking
    ? moment(extendData && extendData.endDate).add(0, 'minutes')
    : moment(extendData && extendData.endDate).add(extendTime, 'minutes');
  const duration = moment(endTImeInLtFormat.toISOString()).diff(
    startTimeInLtFormat.toISOString(),
    'minutes',
  );
  const hours = duration / 60;
  const calcPrice = hourlyPrice * hours;
  const mrBookingPrice =
    fullDay === true
      ? `${fullDayPrice}`
      : `${
          endDateTime && endTimeBuffer
            ? `${calcPrice > fullDayPrice ? fullDayPrice : calcPrice}`
            : ''
        }`;

  const handleReset = () => {
    setActiveStep(0);
  };
  const cancelRedirect = () => {
    history.push('/');
  };

  useEffect(() => {
    if (mrBookingPrice.length !== 0 && vat.length === 0) {
      ApiClient.apiGet(
        `${apiEndPoints.getVat}${moment(bookingDate).format('YYYY-MM-DD')}`,
      )
        .then(data => {
          setVat(data && data.data && data.data.data);
        })
        .catch(error => {
          console.log(error, 'error');
        });
    }
    if (mrBooikingStatus) {
      const payload = {
        status: 'Abandon',
      };
      ApiClient.apiPut(
        `${apiEndPoints.cancelExtend}${extendData.id}/status`,
        payload,
      )
        .then(data => {
          setSuccessMessage(data && data.data.message);
          if (buidlingLocalStorage) history.push('./');
          localStorage.removeItem('payToAccount');
        })
        .catch(error => {
          setMrBooikingStatus(false);
          alert(error);
        });
    }
  }, [mrBooikingStatus, bookingDate, mrBookingPrice, vat]);

  const handleNext = () => {
    setCreateMeetingRoom(true);
    if (mrBookingId && activeStep === steps.length - 2)
      setCustomerDetailsToggle(true);
    if (mrBookingId) setCreateCateringToggle(true);
  };
  const onHold = () => {
    ApiClient.apiPost(`${apiEndPoints.bookingExtend}/${extendData.id}`, {
      extend: parseInt(extendTime, 10),
      discount: Number(extendDiscount),
      eventType: eventType,
    })
      .then(() => {
        history.push('/bookinglist');
      })
      .catch(error => {
        console.log(error);
        alert(error?.response?.data?.message || messages.notExtended);
        history.push('/');
      });
  };

  const onSavedCards = () => {
    ApiClient.apiGet(
      `${apiEndPoints.extendSavedCards}/${customerDetails.tenantUserId}`,
    )
      .then(data => {
        setCardDetails(data && data.data && data.data.data);
      })
      .catch(error => {
        alert(error);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
    onSavedCards();
  };

  const handleTerminalDialogClose = () => {
    setOpenTerminalDialog(false);
    setTerminalId('');
  };
  const handleSendLinkDialogClose = () => {
    setOpenSendLinkDialog(false);
  };
  const OnCompletePayment = () => {
    let payload;
    if (!paymentServiceProvider) {
      payload = {
        method: 'Card',
        discount:
          Number(extendDiscount) || Number(extendData?.extendBooking?.discount),
        cardId: savedCardId,
      };
    } else {
      payload = {
        method: 'Card',
        discount:
          Number(extendDiscount) || Number(extendData?.extendBooking?.discount),
        setupIntentId: savedCardId,
      };
    }
    setCircularLoading(true);
    if (extendData.extendBooking) {
      ApiClient.apiPost(
        `${apiEndPoints.createMrBooking}/${extendData.id}/completeExtension`,
        payload,
      )
        .then(data => {
          setCircularLoading(false);
          setSuccessMessage(data && data.data.message);
          history.push('/bookinglist');
        })
        .catch(err => {
          setCircularLoading(false);
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
          history.push(`/view-booking/${extendData.id}`);
        });
    } else {
      ApiClient.apiPost(`${apiEndPoints.bookingExtend}/${extendData.id}`, {
        extend: parseInt(extendTime, 10),
        eventType: eventType,
      })
        .then(() => {
          ApiClient.apiPost(
            `${apiEndPoints.createMrBooking}/${extendData.id}/completeExtension`,
            payload,
          )
            .then(data => {
              setCircularLoading(false);
              setCard(data && data.data && data.data.data);
              setSuccessMessage(data && data.data.message);
              history.push('/bookinglist');
            })
            .catch(err => {
              setCircularLoading(false);
              alert(
                err &&
                  err.response &&
                  err.response.data &&
                  err.response.data.message,
              );
              history.push(`/view-booking/${extendData.id}`);
            });
        })
        .catch(err => {
          setCircularLoading(false);
          alert(
            err &&
              err.response &&
              err.response.data &&
              err.response.data.message,
          );
        });
    }
  };

  const getCardsDetails = () => {
    // * SQUARE PAYMENT Cards
    if (!paymentServiceProvider) {
      return (
        cardDetails &&
        cardDetails.map(card => (
          <>
            {card && card.cardBrand === cardType.visa && (
              <li
                key={card.id}
                className={`${savedCardId === card.id ? 'cardActive' : ''}`}
              >
                <Button onClick={() => setCardId(card.id)}>
                  <p>
                    <img src={cardLogo1} alt="cardLogo1" />
                    Visa •••• <span>{card.last4}</span>
                  </p>
                  <small>
                    Expires {card.expMonth}/{card.expYear}
                  </small>
                </Button>
              </li>
            )}
            {card && card.cardBrand === cardType.mastercard && (
              <li
                key={card.id}
                className={`${savedCardId === card.id ? 'cardActive' : ''}`}
              >
                <Button onClick={() => setCardId(card.id)}>
                  <p>
                    <img src={cardLogo2} alt="cardLogo2" />
                    Mastercard •••• <span>{card.last4}</span>
                  </p>
                  <small>
                    Expires {card.expMonth}/{card.expYear}
                  </small>
                </Button>
              </li>
            )}
            {card && card.cardBrand === cardType.americanExpress && (
              <li
                key={card.id}
                className={`${savedCardId === card.id ? 'cardActive' : ''}`}
              >
                <Button onClick={() => setCardId(card.id)}>
                  <p>
                    <img src={cardLogo3} alt="cardLogo4" />
                    Amex •••• <span>{card.last4}</span>
                  </p>
                  <small>
                    Expires {card.expMonth}/{card.expYear}
                  </small>
                </Button>
              </li>
            )}
            {card && card.cardBrand === cardType.applePay && (
              <li
                key={card.id}
                className={`${savedCardId === card.id ? 'cardActive' : ''}`}
              >
                <Button onClick={() => setCardId(card.id)}>
                  <p>
                    <img src={cardLogo4} alt="cardLogo4" />
                    ApplePay •••• <span>{card.last4}</span>
                  </p>
                  <small>
                    Expires {card.expMonth}/{card.expYear}
                  </small>
                </Button>
              </li>
            )}
            {card && card.cardBrand === cardType.googlePay && (
              <li
                key={card.id}
                className={`${savedCardId === card.id ? 'cardActive' : ''}`}
              >
                <Button onClick={() => setCardId(card.id)}>
                  <img src={cardLogo5} alt="cardLogo4" />
                  <p>
                    GooglePay •••• <span>{card.last4}</span>
                  </p>
                </Button>
              </li>
            )}
          </>
        ))
      );
    }
    // * DOJO PAYMENT Cards
    return (
      cardDetails &&
      cardDetails.map(card => (
        <>
          {card && card.cardType === cardType.visa && (
            <li
              key={card.setupIntentId}
              className={`${
                savedCardId === card.setupIntentId ? 'cardActive' : ''
              }`}
            >
              <Button onClick={() => setCardId(card.setupIntentId)}>
                <p>
                  <img src={cardLogo1} alt="cardLogo1" />
                  Visa •••• <span>{card.cardNumber.slice(-4)}</span>
                </p>
                <small>
                  Expires {new Date(card.expiryDate).getMonth()}/
                  {new Date(card.expiryDate).getFullYear()}
                </small>
              </Button>
            </li>
          )}
          {card && card.cardType === cardType.mastercard && (
            <li
              key={card.setupIntentId}
              className={`${
                savedCardId === card.setupIntentId ? 'cardActive' : ''
              }`}
            >
              <Button onClick={() => setCardId(card.setupIntentId)}>
                <p>
                  <img src={cardLogo2} alt="cardLogo2" />
                  Mastercard •••• <span>{card.cardNumber.slice(-4)}</span>
                </p>
                <small>
                  Expires {new Date(card.expiryDate).getMonth()}/
                  {new Date(card.expiryDate).getFullYear()}
                </small>
              </Button>
            </li>
          )}
          {card && card.cardType === cardType.americanExpress && (
            <li
              key={card.setupIntentId}
              className={`${
                savedCardId === card.setupIntentId ? 'cardActive' : ''
              }`}
            >
              <Button onClick={() => setCardId(card.setupIntentId)}>
                <p>
                  <img src={cardLogo3} alt="cardLogo4" />
                  Amex •••• <span>{card.cardNumber.slice(-4)}</span>
                </p>
                <small>
                  Expires {new Date(card.expiryDate).getMonth()}/
                  {new Date(card.expiryDate).getFullYear()}
                </small>
              </Button>
            </li>
          )}
          {card && card.cardType === cardType.applePay && (
            <li
              key={card.setupIntentId}
              className={`${
                savedCardId === card.setupIntentId ? 'cardActive' : ''
              }`}
            >
              <Button onClick={() => setCardId(card.setupIntentId)}>
                <p>
                  <img src={cardLogo4} alt="cardLogo4" />
                  ApplePay •••• <span>{card.cardNumber.slice(-4)}</span>
                </p>
                <small>
                  Expires {new Date(card.expiryDate).getMonth()}/
                  {new Date(card.expiryDate).getFullYear()}
                </small>
              </Button>
            </li>
          )}
          {card && card.cardBrand === cardType.googlePay && (
            <li
              key={card.setupIntentId}
              className={`${
                savedCardId === card.setupIntentId ? 'cardActive' : ''
              }`}
            >
              <Button onClick={() => setCardId(card.setupIntentId)}>
                <img src={cardLogo5} alt="cardLogo4" />
                <p>
                  GooglePay •••• <span>{card.cardNumber.slice(-4)}</span>
                </p>
              </Button>
            </li>
          )}
        </>
      ))
    );
  };

  const sendPayLink = () => {
    //* send payment link
    ApiClient.apiPut(`${apiEndPoints.payLink}/${extendData.id}/extendBooking`, {
      email: userEmail,
      roomDiscount:
        Number(extendDiscount) ||
        Number(extendData?.extendBooking?.discount) ||
        0,
    })
      .then(data => {
        setCircularLoading(false);
        setSuccessMessage(data?.data?.message);
        history.push('/bookinglist');
      })
      .catch(error => {
        setCircularLoading(false);
        setMrPaymentLink(false);
        alert(error?.response?.data?.message || messages.notExtended);
        history.push(`/view-booking/${extendData.id}`);
      });
  };
  useEffect(() => {
    if (customerDetails.type !== 'Guest') {
      ApiClient.apiGet(
        `${apiEndPoints.extendSavedCards}/${customerDetails.tenantUserId}`,
      )
        .then(data => {
          setCardDetails(data && data.data && data.data.data);
        })
        .catch(error => {
          alert(error);
        });
    }
  }, []);
  useEffect(() => {
    if (!mrPaymentLink) return;
    setErrorMsgs('');
    setCircularLoading(true);
    sendLinkSchema
      .validate({
        userEmail,
        userConfirmEmail,
      })
      .then(() => {
        if (extendData?.extendBooking) {
          // * existing extend booking
          sendPayLink();
        } else {
          // * fresh extend booking
          ApiClient.apiPost(`${apiEndPoints.bookingExtend}/${extendData.id}`, {
            extend: parseInt(extendTime, 10),
            discount: Number(extendDiscount),
            eventType: eventType,
          })
            .then(() => {
              sendPayLink();
            })
            .catch(error => {
              setCircularLoading(false);
              setMrPaymentLink(false);
              alert(error?.response?.data?.message || messages.notExtended);
              history.push('/');
            });
        }
      })
      .catch(err => {
        // * validation errors set
        setErrorMsgs(err);
        setCircularLoading(false);
        setMrPaymentLink(false);
      });
  }, [mrPaymentLink]);

  const breadCrumbs = {
    listBreadCrumbPath: '/',
    listBreadCrumbTitle: 'Meeting Room Bookings',
    addBreadCrumbPath: '/',
    addBreadCrumbTitle: 'New Booking',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }
  const timeValue = '00:00';
  return (
    <>
      <Backdrop className="loaderZindex" open={circularLoading}>
        <CircularProgress color="inherit" />
        {mrPaymentPos ? (
          <Typography variant="body2" style={{ marginTop: '7vw' }}>
            {loaderNotification
              .toLowerCase()
              .split('_')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
          </Typography>
        ) : (
          ''
        )}
      </Backdrop>
      <div className={classes.pageListMain}>
        <div className="pageBredcrumbTimer">
          <Breadcrumb breadCrumbs={breadCrumbs} />
          {activeStep !== steps.length - 4 && (
            <div
              className={` extendBookingTimeLeft ${classes.bookingTimeLeft} ${
                minutes < 2 &&
                minutes !== '00' &&
                seconds !== '00' &&
                classes.bookingTimeWarning
              }`}
            >
              {!mrPaymentPos
                ? `Booking has ${minutes < 10 ? `0${minutes}` : minutes}:${
                    seconds < 10 ? `0${seconds}` : seconds
                  } minutes left`
                : `Booking has ${timeValue} minutes left`}
            </div>
          )}
        </div>
        <Divider light />
        <div className="pageContainer mrBookingPage">
          <div className="mrBookingPageIn">
            <div className="mrBookingLft">
              <div className="mrBookingLftIn">
                <Stepper
                  className="mrBooking"
                  activeStep={
                    isCatering || activeStep === 0 ? activeStep : activeStep - 1
                  }
                  alternativeLabel
                >
                  {!isCatering
                    ? steps
                        .filter(label => label !== 'Catering')
                        .map(label => (
                          <Step
                            key={label}
                            className="mrBookingStepsWithoutCatering"
                          >
                            <StepLabel
                              className="mrBookingStepsLabel"
                              disabled={label !== 'Order Preview'}
                            >
                              {label}
                            </StepLabel>
                          </Step>
                        ))
                    : steps.map(label => (
                        <Step key={label} className="mrBookingSteps">
                          <StepLabel
                            className="mrBookingStepsLabel"
                            disabled={label !== 'Order Preview'}
                          >
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                </Stepper>
                <div>
                  {activeStep === steps.length ? (
                    <div>
                      <Typography className={classes.instructions}>
                        All steps completed
                      </Typography>
                      <Button onClick={handleReset}>Reset</Button>
                    </div>
                  ) : (
                    <div className="BookingStepAll">
                      {getStepContent(activeStep)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mrBookingRgt">
              <div className="mrBookingRgtSticky">
                <div className="mrBookingAccordion">
                  <Accordion
                    disabled={activeStep === steps.length - 1}
                    expanded={
                      isExpanded === 'panel1' || activeStep === steps.length - 4
                    }
                    onChange={handleChange('panel1')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}>
                        Meeting Room
                      </Typography>
                      {mrBookingPrice && mrBookingPrice.length !== 0 && (
                        <div className="headingPrice">
                          <span>
                            {mrBookingPrice &&
                              `£${Number(extendRoomPrice).toFixed(2)}`}
                          </span>
                        </div>
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul>
                        <li>
                          {mrRoomName}
                          <span>({mrCapacity})</span>{' '}
                          {layoutName === 'Workshop' && (
                            <img
                              src={workshopstyle}
                              alt="workshopstyle"
                              key={workshopstyle}
                            />
                          )}
                          {layoutName === 'Board Room' && (
                            <img
                              src={boardroomstyle}
                              alt="boardroomstyle"
                              key={boardroomstyle}
                            />
                          )}
                          {layoutName === 'Theatre' && (
                            <img
                              src={theatrestyle}
                              alt="theatrestyle"
                              key={theatrestyle}
                            />
                          )}
                        </li>
                        <li>{`${buildingName}, ${village}`}</li>
                        <li>
                          {moment(startDateTime).format(
                            'dddd Do MMMM, YYYY',
                          ) === 'Invalid date'
                            ? ''
                            : moment(startDateTime).format(
                                'dddd Do MMMM, YYYY',
                              )}
                        </li>
                        <li>
                          {`${
                            moment(startDateTime).format(
                              'dddd Do MMMM, YYYY',
                            ) === 'Invalid date'
                              ? ''
                              : moment(startDateTime).format('h:mm a')
                          }${
                            moment(endDateTime).format('h:mm a') ===
                            'Invalid date'
                              ? ''
                              : ` - ${moment(endDateTime).format('h:mm a')}`
                          }`}{' '}
                        </li>
                        {selectedMenuItems &&
                          selectedMenuItems.map(menuItem => (
                            <>
                              {menuItem && menuItem.capacity > 0 && (
                                <li key={menuItem.categoryId}>
                                  {`x${menuItem.capacity} ${
                                    menuItem &&
                                    menuItem.menu &&
                                    menuItem.menu
                                      .map(menu => menu.itemName)
                                      .toString()
                                      .split(',')
                                      .join(', ')
                                  }`}
                                </li>
                              )}
                            </>
                          ))}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    disabled={
                      activeStep === steps.length - 1 ||
                      activeStep === steps.length - 2
                    }
                    expanded={
                      isExpanded === 'panel3' || activeStep === steps.length - 2
                    }
                    onChange={handleChange('panel3')}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className={classes.heading}>
                        Customer Details
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul>
                        <li>{`${firstName} ${lastName} ${
                          tenant ||
                          (firstName && lastName && customerType === 'Guest')
                            ? '-'
                            : ''
                        } ${
                          firstName && lastName && customerType === 'Guest'
                            ? 'Guest'
                            : tenant
                        }`}</li>
                        <li>{email}</li>
                        <li>{phoneNumber}</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className="bookingToatalBtm">
                  <div className="bookingToatal">
                    {/* {subTotal > 0 && ( */}
                    <ul>
                      <li>Subtotal</li>
                      <li>
                        <span>£ {Number(extendSubtotal).toFixed(2)}</span>
                      </li>
                    </ul>
                    {/* )} */}
                    <Divider className="formDivider fullWidth" light />
                    {/* {subTotal > 0 && total > 0 && ( */}
                    <ul>
                      <li>VAT</li>
                      <li>
                        <span>£ {Number(extendVat).toFixed(2)}</span>
                      </li>
                    </ul>
                    {/* )} */}
                    <Divider className="formDivider fullWidth" light />
                    {/* {total > 0 && ( */}
                    <ul>
                      <li>Total</li>
                      <li>
                        <span>£ {Number(extendRoomTotal).toFixed(2)}</span>
                      </li>
                    </ul>
                    {/* )} */}
                  </div>
                  {mrPaymentPosMessage && (
                    <div className="bookingToatal">
                      <Divider className="formDivider fullWidth" light />
                      <ul>
                        <li>Status</li>
                        <li>
                          <span>{mrPaymentPosMessage}</span>
                        </li>
                      </ul>
                    </div>
                  )}
                  <Divider className="formDivider fullWidth" light />
                  <div className={`${classes.formButton} booingButton`}>
                    {activeStep !== steps.length - 1 && (
                      <>
                        <Button
                          onClick={popUpOpen}
                          className={classes.backButton}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleNext();
                          }}
                        >
                          {(activeStep === steps.length - 3 &&
                            cartItems.length === 0 &&
                            'Skip') ||
                            'Next'}
                        </Button>
                      </>
                    )}
                    {activeStep === steps.length - 1 && (
                      <div className="orderPreviewBtn">
                        <Button
                          disabled={mrPaymentPos || circularLoading}
                          onClick={() => {
                            handleClickOpenTerminals(
                              paymentServiceProvider,
                              setOpenTerminalDialog,
                              setMrPaymentPos,
                              setTerminals,
                            );
                          }}
                        >
                          Terminal
                        </Button>
                        {timeDiff > hour.oneDay && timeDiff < hour.week && (
                          <Button
                            className="savedCardBtn"
                            variant="contained"
                            color="primary"
                            disabled={
                              timeDiff < hour.oneDay || timeDiff > hour.week
                            }
                            onClick={() => setOpenSendLinkDialog(true)}
                          >
                            Send Pay Link
                          </Button>
                        )}

                        <Button
                          className="savedCardBtn"
                          variant="contained"
                          disabled={
                            (payToAccountCheck !== null &&
                              payToAccountCheck.toString() === 'false') ||
                            customerDetails.type === 'Guest' ||
                            mrPaymentPos
                          }
                          color="primary"
                          onClick={() => {
                            setMrPayment(true);
                          }}
                        >
                          Pay to Account
                        </Button>

                        <Button
                          className={classes.holdBtn}
                          variant="contained"
                          color="primary"
                          disabled={extendData.extendBooking}
                          onClick={onHold}
                        >
                          Hold
                        </Button>
                        <Button
                          className="savedCardBtn"
                          variant="contained"
                          color="primary"
                          disabled={
                            cardDetails.length <= 0 || customerType === 'Guest'
                          }
                          onClick={handleClickOpen}
                        >
                          Saved Cards
                        </Button>
                        <Button
                          className="cancelPos"
                          variant="contained"
                          color="primary"
                          onClick={popUpOpen}
                        >
                          Cancel Booking
                        </Button>
                      </div>
                    )}
                    <div className="extendPopup savedCardsPopup">
                      <Dialog
                        open={open}
                        onClose={handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="cancelDialogOpend savedCardPopupOpend"
                      >
                        <Button
                          className="popupCloseBtn"
                          onClick={handleDialogClose}
                        >
                          <img src={popup_close} alt="popup_close.svg" />
                        </Button>
                        <h4>Saved Cards</h4>
                        <div className="allCardList">
                          <ul className={classes.cardList}>
                            {getCardsDetails()}
                          </ul>
                        </div>
                        <DialogActions
                          className={`${classes.formButton} ${classes.popupFormButton} ${classes.extendPopupFormButton} ${classes.cardPopupFormButton}`}
                        >
                          <Button
                            disabled={savedCardId === '' || mrCardPayment}
                            onClick={() => {
                              OnCompletePayment();
                              setCardPayment(true);
                            }}
                            autoFocus
                          >
                            Pay{' '}
                            <small>
                              {' '}
                              £{Number(extendRoomTotal).toFixed(2)}
                            </small>
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                    <Dialog
                      open={warningPopUp}
                      onClose={popUpClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      className="cancelDialogOpend"
                    >
                      <div className={classes.cancelDialog}>
                        {!timeOut ? (
                          <>
                            <h2>Are you sure you want to cancel?</h2>
                            <p className={classes.errorColor}>
                              This will delete all records created and the time
                              slot
                            </p>
                            <p className={classes.errorColor}>
                              will be made available again.
                            </p>
                          </>
                        ) : (
                          <>
                            <h2>Booking has expired.</h2>
                            <p>
                              You will have to start your booking process again.
                            </p>
                          </>
                        )}
                      </div>
                      <Divider className="formDivider popupDivider" light />
                      <DialogActions
                        className={`${classes.formButton} popupFormButton`}
                      >
                        {!timeOut && (
                          <Button
                            onClick={() => setWarningPopUp(false)}
                            color="primary"
                          >
                            Continue Booking
                          </Button>
                        )}
                        {(extendData.extendBooking && (
                          <Button
                            className="popupFormButtonLast"
                            onClick={() => {
                              stausCall1('Cancelled');
                            }}
                            autoFocus
                          >
                            Yes
                          </Button>
                        )) || (
                          <Button
                            className="popupFormButtonLast"
                            onClick={cancelRedirect}
                            autoFocus
                          >
                            Yes
                          </Button>
                        )}
                      </DialogActions>
                    </Dialog>

                    <div className="extendPopup savedCardsPopup">
                      <TerminalDialog
                        classes={classes}
                        openTerminalDialog={openTerminalDialog}
                        handleTerminalDialogClose={handleTerminalDialogClose}
                        terminals={terminals}
                        setTerminalId={setTerminalId}
                        mrPaymentPos={mrPaymentPos}
                        terminalId={terminalId}
                        setMrPaymentPos={setMrPaymentPos}
                        total={extendRoomTotal}
                      />
                    </div>
                    <div className="extendPopup savedCardsPopup">
                      <Dialog
                        open={openSendLinkDialog}
                        onClose={handleSendLinkDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="cancelDialogOpend savedCardPopupOpend"
                      >
                        <Button
                          className="popupCloseBtn"
                          onClick={handleSendLinkDialogClose}
                        >
                          <img src={popup_close} alt="popup_close.svg" />
                        </Button>
                        <h4 className={`${classes.mb_20}`}>
                          Send Payment Link
                        </h4>
                        <ul className={`${classes.InputSection} `}>
                          <li
                            className={`${classes.flexNone} ${classes.mb_20}`}
                          >
                            <span>Email*</span>
                            <Input
                              type="text"
                              inputProps={{ 'aria-label': 'description' }}
                              value={userEmail}
                              onChange={e => {
                                setErrorMsgs('');
                                setUserEmail(e.target.value);
                              }}
                            />
                            {errorType === 'email' &&
                              errorFieldName === 'userEmail' && (
                                <FormHelperText
                                  className={classes.errorMsg}
                                  component="div"
                                  error={messages.valueEmail}
                                >
                                  {messages.valueEmail}
                                </FormHelperText>
                              )}
                          </li>
                          <li
                            className={`${classes.flexNone} ${classes.mb_20} `}
                          >
                            <span>Confirm Email*</span>
                            <Input
                              type="text"
                              inputProps={{ 'aria-label': 'description' }}
                              value={userConfirmEmail}
                              onChange={e => {
                                setErrorMsgs('');
                                setUserConfirmEmail(e.target.value);
                              }}
                            />
                            {errorType === 'email' &&
                              errorFieldName === 'userConfirmEmail' && (
                                <FormHelperText
                                  className={classes.errorMsg}
                                  component="div"
                                  error={messages.valueEmail}
                                >
                                  {messages.valueEmail}
                                </FormHelperText>
                              )}
                            {/* email ids should match */}
                            {errorType === 'oneOf' &&
                              errorFieldName === 'userConfirmEmail' && (
                                <FormHelperText
                                  className={classes.errorMsg}
                                  component="div"
                                  error={messages.emailsMatch}
                                >
                                  {messages.emailsMatch}
                                </FormHelperText>
                              )}
                          </li>
                        </ul>
                        <DialogActions
                          className={`${classes.formButton} ${classes.popupFormButton} ${classes.extendPopupFormButton} ${classes.cardPopupFormButton}`}
                        >
                          <Button
                            disabled={
                              userEmail === '' || userConfirmEmail === ''
                            }
                            onClick={() => setMrPaymentLink(true)}
                            autoFocus
                          >
                            Send
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ExtendMeetingRoomBooking;
