/* eslint-disable import/no-cycle */
import React, { createContext, useState, useEffect } from 'react';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';
import OffersList from '../containers/Offers/OffersList';

export const OffersListContext = createContext({});

export const OffersListContextProvider = () => {
  const [offersList, setOffersList] = useState([]);
  const [offersSearch, setOffersSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('startDate');
  const [totalOffersCount, setTotalOffersCount] = useState(0);
  const [page, setPage] = React.useState(0);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [statusInactive, setStatusInactive] = useState('');
  const [statusActive, setStatusActive] = useState('');
  const [buildings, setBuildings] = useState([]);
  const [villages, setVillages] = useState([]);
  const [selectedOfferType, setSelectedOfferType] = useState([]);
  const [offerTypeDigital, setOfferTypeDigital] = useState('');
  const [offerTypePhysical, setOfferTypePhysical] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [categoryActive, setCatagoryActive] = useState(false);
  const [statuses, setStatuses] = useState([
    { label: 'Published', key: 'Published', selected: false },
    { label: 'Unpublished', key: 'Unpublished', selected: false },
  ]);
  const [offerTypes, setOfferTypes] = useState([
    { label: 'Digital', key: 'Digital', selected: false },
    { label: 'Physical', key: 'Physical', selected: false },
    { label: 'Digital URL Only', key: 'Url', selected: false },
  ]);

  const offerSearchResult = offersSearch.length > 2 ? offersSearch : '';

  const getOffersListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getOffersList, payload)
      .then(data => {
        setOffersList(data && data.data && data.data.data);
        setTotalOffersCount(data && data.data.count);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  const getOffersCategoryAction = () => {
    if (categories.length === 0) {
      ApiClient.apiGet(apiEndPoints.getOffersCategory)
        .then(data => {
          setCategories(data && data.data && data.data.data);
        })
        .catch(error => {
          console.log(error, 'error');
        });
    }
  };

  useEffect(() => {
    if (categoryActive) {
      getOffersCategoryAction();
    }
  }, [categoryActive]);

  useEffect(() => {
    const updatedStatus = [];
    const updatedofferType = [];
    offerTypes.filter(offerType => {
      if (offerType.selected) {
        updatedofferType.push(offerType.key);
      }
      return offerType.selected !== false;
    });
    setSelectedOfferType(updatedofferType);

    statuses.filter(status => {
      if (status.selected) {
        updatedStatus.push(status.key);
      }
      return status.selected !== false;
    });
    setSelectedStatus(updatedStatus);
    getOffersListAction({
      searchText: offerSearchResult ? [offersSearch] : undefined,
      status:
        updatedStatus && updatedStatus.length > 0
          ? updatedStatus
          : ['Published'],
      buildings: buildings && buildings.length > 0 ? buildings : undefined,
      type:
        updatedofferType && updatedofferType.length > 0
          ? updatedofferType
          : undefined,
      category:
        selectedCategory && selectedCategory.length > 0
          ? selectedCategory
          : undefined,
      sortOrder,
      sortBy,
      offset,
      limit: 40,
    });
  }, [
    offerSearchResult,
    sortOrder,
    sortBy,
    offset,
    statuses,
    offerTypes,
    buildings,
    selectedCategory,
  ]);

  return (
    <OffersListContext.Provider
      value={{
        loading,
        offersList,
        offersSearch,
        setOffersSearch,
        totalOffersCount,
        selectedStatus,
        setSelectedStatus,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        page,
        setPage,
        setOffset,
        statuses,
        setStatuses,
        statusActive,
        setStatusActive,
        statusInactive,
        setStatusInactive,
        selectedOfferType,
        setSelectedOfferType,
        offerTypes,
        setOfferTypes,
        offerTypeDigital,
        setOfferTypeDigital,
        offerTypePhysical,
        setOfferTypePhysical,
        categories,
        setCategories,
        selectedCategory,
        setSelectedCategory,
        buildings,
        setBuildings,
        villages,
        setVillages,
        setCatagoryActive,
      }}
    >
      <OffersList />
    </OffersListContext.Provider>
  );
};
