/* eslint-disable import/no-cycle */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import './mrbooking.css';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from '@material-ui/core';
import moment from 'moment';
import dowmArw from '../../images/down-arw.svg';
import { GlobalContext } from '../../context/GlobalContext';
import { meetingRoomBooking } from '../../utils/formValidate';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import { MrBookingContext } from '../../context/MRBookingContext';
import { logOut } from '../../utils/logOut';

const useStyles = makeStyles({
  BookingStepForm: {
    margin: '0 auto',
    padding: '40px 0',
    '& > ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      '& > li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat right 15px center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      position: 'relative',
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        minHeight: '92px',
        lineHeight: '22px',
        padding: '10px 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        resize: 'none',
        fontFamily: ['Varta', 'sans-serif'].join(','),
        outline: 'none',
      },
      '& > div': {
        '& > div': {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      fontSize: '16px',
      lineHeight: '20px',
      color: '#838384',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  formButton: {
    marginTop: '40px',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& Button': {
      height: '47px',
      lineHeight: '47px',
      border: '1px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff',
      marginLeft: '30px',
      cursor: 'pointer',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#444444',
        border: 'none',
      },
    },
  },
  lineformButton: {
    marginBottom: '0px !important',
    marginTop: '20px !important',
    width: '100% !important',
    '& hr': {
      margin: '24px auto',
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
});

const MeetingRoom = () => {
  const {
    bookingDate,
    setBookingDate,
    endNewDate,
    setEndNewDate,
    mrRoomId,
    setMrRoomId,
    mrRoomName,
    setMrRoomName,
    mrRoomLayout,
    setMrRoomLayout,
    createMeetingRoom,
    setCreateMeetingRoom,
    setDisableButton,
    fullDay,
    setFullDay,
    layoutName,
    setLayoutName,
    buildingId,
    village,
    buildingName,
    mrCapacity,
    hourlyPrice,
    fullDayPrice,
    setActiveStep,
    setCalcPrice,
    setMRBookingId,
    minimumBookingTime,
    roomLayoutArray,
    setRoomLayoutArray,
    notes,
    setNotes,
    setCapaCityValue,
    startTime,
    setStartTime,
    selectedMenuItems,
    setSelectedMenuItems,
    finalStartDate,
    finalEndDate,
    bookingType,
    setBookingType,
    setBlockedBookingId,
  } = useContext(MrBookingContext);
  const classes = useStyles();
  const { newMeetingRoomsList, setMRExtendBookingId, isCatering } =
    useContext(GlobalContext);
  const [errorMsgs, setErrorMsgs] = useState(null);
  const [cateringMenuList, setCateringMenuList] = useState([]);
  const [endTimeSelected, setEndTimeSelected] = useState([]);
  const [responseError, setResponseError] = useState('');
  const [errorNew, setErrorNew] = useState(false);
  const [startTimeSelected, setStartTimeSelected] = useState([]);
  const [newDateFormate, setNewDateFormate] = useState('');
  const selectedComplimentary =
    selectedMenuItems &&
    selectedMenuItems.filter(selectedMenu => selectedMenu.capacity > 0);
  const endDate = new Date(
    `${moment(bookingDate).format('ddd MMM DD YYYY')} ${endNewDate}`,
  );
  const startDate = new Date(
    `${moment(bookingDate).format('ddd MMM DD YYYY')} ${startTime}`,
  );
  const history = useHistory();
  const getCateringMenuListAction = payload => {
    ApiClient.apiPost(apiEndPoints.getCateringCategories, payload)
      .then(data => {
        setCateringMenuList(data && data.data && data.data.data.orderMenu);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  const intervalsEnd = (startString, endString) => {
    const start = moment(startString, 'hh:mm a');
    const end = moment(endString, 'hh:mm a');
    let newValue = [];
    // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
    // note that 59 will round up to 60, and moment.js handles that correctly
    if (minimumBookingTime) {
      start.minutes(Math.ceil(start.minutes() / 15) * 15);
      const result = [];
      const current = moment(start);
      while (current <= end) {
        result.push(current.format('hh:mm a'));
        current.add(15, 'minutes');
      }
      const count = minimumBookingTime / 15;
      if (bookingType === 'Blocked') {
        result.shift();
      } else {
        for (let i = 1; i <= count; i++) {
          result.shift();
        }
      }
      if (result.length === 0) {
        setErrorNew(true);
      }
      newValue = result;
    }
    return newValue;
  };

  const intervalsStart = (startString, endString) => {
    const start = moment(startString, 'hh:mm a');
    const end = moment(endString, 'hh:mm a');
    // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
    // note that 59 will round up to 60, and moment.js handles that correctly
    start.minutes(Math.ceil(start.minutes() / 15) * 15);
    const result = [];
    const current = moment(start);
    while (current <= end) {
      result.push(current.format('hh:mm a'));
      current.add(15, 'minutes');
    }
    return result;
  };

  useEffect(() => {
    if (isCatering) {
      getCateringMenuListAction({
        supplierName: 'Derwent London',
        status: 'Available',
      });
    }
    setStartTimeSelected(intervalsStart('12:00:00 am', '11:59:00 pm'));
    setEndTimeSelected(
      intervalsEnd(moment(newDateFormate).format('LT'), '11:59:00 pm'),
    );
    setNewDateFormate(
      new Date(`${moment(bookingDate).format('ddd MMM DD YYYY')} ${startTime}`),
    );
  }, []);

  useEffect(() => {
    setRoomLayoutArray(
      newMeetingRoomsList &&
        newMeetingRoomsList.filter(data => data.id === mrRoomId),
    );
    setStartTimeSelected(intervalsStart('12:00:00 am', '11:59:00 pm'));
    setEndTimeSelected(
      intervalsEnd(moment(newDateFormate).format('LT'), '11:59:00 pm'),
    );
  }, [mrRoomId, bookingDate, newDateFormate, minimumBookingTime, bookingType]);

  useEffect(() => {
    meetingRoomBooking
      .validate({
        startDateValidation: bookingDate,
        startTimeValidation: startDate,
        endTimeValidation: endNewDate,
        meetingRoom: mrRoomId,
        roomLayoutsFields: mrRoomLayout,
        bookingType: bookingType,
      })
      .then(() => {
        if (
          createMeetingRoom &&
          new Date(endDate).getTime() > new Date(startDate).getTime() &&
          !errorNew
        ) {
          setDisableButton(true);
          const Payload = {
            startDate: finalStartDate,
            endDate: finalEndDate,
            fullDay,
            meetingRoom: {
              id: mrRoomId,
              name: mrRoomName,
              capacity: mrCapacity,
            },
            building: {
              id: buildingId,
              name: buildingName,
              village,
            },
            roomLayout:
              bookingType === 'Standard'
                ? {
                    id: mrRoomLayout,
                    name: layoutName,
                  }
                : undefined,
            notes: notes || undefined,
            complimentaryCatering:
              selectedComplimentary.length > 0
                ? selectedComplimentary
                : undefined,
            bookingType: bookingType,
          };
          ApiClient.apiPost(apiEndPoints.createMrBooking, Payload)
            .then(res => {
              if (bookingType === 'Standard') {
                setMRBookingId(
                  res && res.data && res.data.data && res.data.data.id,
                );
                setMRExtendBookingId(
                  res && res.data && res.data.data && res.data.data.id,
                );
                setCreateMeetingRoom(false);
                // eslint-disable-next-line no-unused-expressions
                isCatering
                  ? setActiveStep(prevActiveStep => prevActiveStep + 1)
                  : setActiveStep(prevActiveStep => prevActiveStep + 2);
              } else {
                setBlockedBookingId(
                  res && res.data && res.data.data && res.data.data.id,
                );
                setCreateMeetingRoom(false);
                history.push('/');
              }
            })
            .catch(error => {
              const err = error;
              if (
                err.response.status === 420 ||
                err.response.data.message ===
                  'Meeting room not available during the time interval'
              ) {
                setResponseError(err.response.data.message);
              }
              setCreateMeetingRoom(false);
              setDisableButton(false);
            });
        }
      })
      .catch(error => {
        console.log('error', error);
        if (createMeetingRoom) {
          setErrorMsgs(error);
          setCreateMeetingRoom(false);
        }
      });
  }, [
    bookingDate,
    endNewDate,
    fullDay,
    mrRoomId,
    mrCapacity,
    mrRoomLayout,
    mrRoomName,
    buildingId,
    buildingName,
    village,
    notes,
    layoutName,
    createMeetingRoom,
  ]);

  useEffect(() => {
    const startTimeInLtFormat = moment(startDate);
    const endTImeInLtFormat = moment(endDate);
    const duration = moment(endTImeInLtFormat.toISOString()).diff(
      startTimeInLtFormat.toISOString(),
      'minutes',
    );
    const hours = duration / 60;
    setCalcPrice(hours * hourlyPrice);
  }, [bookingDate, startDate, endDate]);

  const errorType = errorMsgs && errorMsgs.type;
  const errorFieldName = errorMsgs && errorMsgs.path;
  const errorFieldValue = errorMsgs && errorMsgs.value;

  const handleClick = mrId => {
    const mrName =
      newMeetingRoomsList &&
      newMeetingRoomsList.filter(data => data.id === mrId);
    setMrRoomName(mrName && mrName[0] && mrName[0].name);
  };

  const handleLayoutClick = layoutId => {
    const layoutNames =
      roomLayoutArray &&
      roomLayoutArray[0].roomLayouts &&
      roomLayoutArray &&
      roomLayoutArray[0].roomLayouts.filter(data => data.id === layoutId);
    setLayoutName(layoutNames && layoutNames[0] && layoutNames[0].name);
  };

  const handleDateChange = startdate => {
    setBookingDate(
      (startdate && startdate.getDay() === 0) ||
        (startdate && startdate.getDay() === 6)
        ? ''
        : startdate,
    );
    setEndNewDate(endNewDate);
    setErrorNew(false);
    localStorage.setItem('selectedNewDate', startdate);
  };

  const fullDayChange = e => {
    if (e.target.checked) {
      setFullDay(true);
      setStartTime('09:00 am');
      setEndNewDate('06:00 pm');
      setNewDateFormate(
        new Date(
          `${moment(bookingDate).format('ddd MMM DD YYYY')} ${'09:00 am'}`,
        ),
      );
    } else {
      setFullDay(false);
      setStartTime(startTime);
      setEndNewDate(endNewDate);
    }
  };

  const disableWeekends = wekenddate => {
    return wekenddate.getDay() === 0 || wekenddate.getDay() === 6;
  };

  // Complimentary dropdown loop based on meeting room capacity
  // eslint-disable-next-line prefer-const
  let obj = {
    array: [],
  };
  for (let l = 0; l < mrCapacity + 1; l++) {
    obj.array[l] = l + 0;
  }

  const menuDetails = (details, capacityNo) => {
    const exist = selectedMenuItems.find(
      x => x.categoryId === details.categoryId,
    );
    if (exist) {
      setCateringMenuList(
        cateringMenuList &&
          cateringMenuList.map(
            menuItem =>
              menuItem && {
                ...menuItem,
                capacity:
                  menuItem.categoryId === details.categoryId ? capacityNo : '',
              },
          ),
      );
      setSelectedMenuItems(
        selectedMenuItems &&
          selectedMenuItems.map(x =>
            x.categoryId === details.categoryId
              ? {
                  ...exist,
                  capacity: capacityNo,
                }
              : x,
          ),
      );
    } else {
      setCateringMenuList(
        cateringMenuList &&
          cateringMenuList.map(
            menuItem =>
              menuItem && {
                ...menuItem,
                capacity:
                  menuItem.categoryId === details.categoryId ? capacityNo : '',
              },
          ),
      );
      setSelectedMenuItems([
        ...selectedMenuItems,
        {
          ...details,
          capacity: capacityNo,
        },
      ]);
    }
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <>
      <div className={classes.BookingStepForm}>
        <ul>
          <li>
            <FormControl component="fieldset">
              <RadioGroup aria-label="Active" name="Available">
                <div className={`${classes.radioSection} radioSection`}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Standard Booking"
                    checked={bookingType === 'Standard'}
                    onChange={() => {
                      setBookingType('Standard');
                    }}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Calender Block"
                    checked={bookingType === 'Blocked'}
                    onChange={() => {
                      setBookingType('Blocked');
                    }}
                  />
                </div>
              </RadioGroup>
            </FormControl>
          </li>
        </ul>

        <ul className={`${classes.InputSection} BookingStepFormTop`}>
          <li className="offerDtCat">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <span>Start Date*</span>
              <KeyboardDatePicker
                format="dd/MM/yyyy"
                disableToolbar
                autoOk
                variant="inline"
                minDate={new Date()}
                shouldDisableDate={disableWeekends}
                value={bookingDate}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
            {(errorType === 'required' &&
              errorFieldName === 'startDateValidation') ||
              (errorFieldValue &&
                errorFieldValue.startDateValidation === null && (
                  <FormHelperText
                    className={classes.errorMsg}
                    component="div"
                    error={bookingDate === null && 'Start Date is required.'}
                  >
                    {bookingDate === null && 'Start Date is required.'}
                  </FormHelperText>
                ))}
          </li>
          <li>
            <span>Start Time*</span>
            {fullDay === true && (
              <Input readOnly type="text" value={startTime} />
            )}
            {fullDay === false && (
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                value={startTime}
                className={classes.formSelectBox}
                onChange={e => {
                  setStartTime(e.target.value);
                  setNewDateFormate(
                    new Date(
                      `${moment(bookingDate).format('ddd MMM DD YYYY')} ${
                        e.target.value
                      }`,
                    ),
                  );
                  setResponseError('');
                  setErrorNew(false);
                }}
              >
                {startTimeSelected &&
                  startTimeSelected.map(name => {
                    return (
                      <MenuItem value={name} name={name} key={name}>
                        {name}
                      </MenuItem>
                    );
                  })}
              </Select>
            )}
            {((errorType === 'required' &&
              errorFieldName === 'startTimeValidation') ||
              responseError ||
              (errorFieldValue &&
                errorFieldValue.startTimeValidation === null)) && (
              <FormHelperText
                className={classes.errorMsg}
                component="div"
                error={
                  (startTime === null && 'Start Time is required.') ||
                  responseError
                }
              >
                {(startTime === null && 'Start Time is required.') ||
                  responseError}
              </FormHelperText>
            )}
          </li>
          <li>
            <span>End Time*</span>
            {fullDay === true && (
              <Input readOnly type="text" value={endNewDate} />
            )}
            {fullDay === false && (
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                value={endNewDate}
                className={classes.formSelectBox}
                onChange={e => {
                  setEndNewDate(e.target.value);
                  setErrorNew(false);
                }}
              >
                {endTimeSelected &&
                  endTimeSelected.map(name => {
                    return (
                      <MenuItem value={name} name={name} key={name}>
                        {name}
                      </MenuItem>
                    );
                  })}
              </Select>
            )}
            {((errorType === 'required' &&
              errorFieldName === 'endTimeValidation') ||
              (errorFieldValue && errorFieldValue.endTimeValidation === null) ||
              errorNew === true ||
              endNewDate !== null ||
              (endDate !== null &&
                new Date(endDate).getTime() <
                  new Date(startDate).getTime())) && (
              <FormHelperText
                className={classes.errorMsg}
                component="div"
                error={
                  (endNewDate === null && 'End Time is required.') ||
                  (errorNew === true &&
                    `can't book lessthan minumum booking time`) ||
                  (new Date(endDate).getTime() <
                    new Date(startDate).getTime() &&
                    `can't be earlier than Start time.`)
                }
              >
                {(endNewDate === null && 'End Time is required.') ||
                  (errorNew === true &&
                    `can't book lessthan minumum booking time`) ||
                  (new Date(endDate).getTime() <
                    new Date(startDate).getTime() &&
                    `can't be earlier than start time.`)}
              </FormHelperText>
            )}
          </li>
          <li className="fullDayBox">
            <FormControlLabel
              control={<Checkbox checked={fullDay} onChange={fullDayChange} />}
              label="Full day"
              value={fullDay}
            />
          </li>
        </ul>
        <ul className={`${classes.InputSection} BookingStepFormbtm`}>
          <li>
            <div className="pericePerDay">{`(£${hourlyPrice} per hour / £${fullDayPrice} per day)`}</div>
            <FormControl className={classes.formControl}>
              <InputLabel shrink className={classes.formLabel}>
                Meeting Room*
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                value={mrRoomId}
                className={classes.formSelectBox}
                onChange={e => {
                  setMrRoomId(e.target.value);
                  handleClick(e.target.value);
                }}
              >
                {newMeetingRoomsList &&
                  newMeetingRoomsList.map(name => {
                    return (
                      <MenuItem value={name.id} name={name.id} key={name.id}>
                        {name.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {((errorType === 'required' && errorFieldName === 'meetingRoom') ||
              (errorFieldValue && errorFieldValue.meetingRoom === '')) && (
              <FormHelperText
                className={classes.errorMsg}
                component="div"
                error={mrRoomId === '' && 'Meeting Room is required.'}
              >
                {mrRoomId === '' && 'Meeting Room is required.'}
              </FormHelperText>
            )}
          </li>
          {bookingType === 'Standard' ? (
            <li>
              <FormControl className={classes.formControl}>
                <InputLabel shrink className={classes.formLabel}>
                  Room Layout*
                </InputLabel>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  value={mrRoomLayout}
                  className={classes.formSelectBox}
                  onChange={e => {
                    setMrRoomLayout(e.target.value);
                    handleLayoutClick(e.target.value);
                  }}
                >
                  {roomLayoutArray &&
                    roomLayoutArray.map(name => {
                      return name.roomLayouts.map(room => {
                        return <MenuItem value={room.id}>{room.name}</MenuItem>;
                      });
                    })}
                </Select>
              </FormControl>
              {((errorType === 'required' &&
                errorFieldName === 'roomLayoutsFields') ||
                (errorFieldValue &&
                  errorFieldValue.roomLayoutsFields === '')) && (
                <FormHelperText
                  className={classes.errorMsg}
                  component="div"
                  error={mrRoomLayout === '' && 'Room Layout is required.'}
                >
                  {mrRoomLayout === '' && 'Room Layout is required.'}
                </FormHelperText>
              )}
            </li>
          ) : (
            <></>
          )}
        </ul>
        <ul className={`${classes.InputSection} textareaSection`}>
          <li>
            <span>Meeting Room Notes</span>
            <textarea
              type="text"
              maxLength="150"
              inputProps={{ 'aria-label': 'description' }}
              value={notes}
              onChange={e => {
                setNotes(e.target.value);
              }}
            />
            <div className="descriptionBox">{`${notes.length}/150`}</div>
            {((errorType === 'required' &&
              errorFieldName === 'MeetingroomnotesValidation') ||
              (errorFieldValue &&
                errorFieldValue.MeetingroomnotesValidation === '')) && (
              <FormHelperText
                className={classes.errorMsg}
                component="div"
                error={notes === '' && 'Meeting room notes is required.'}
              >
                {notes === '' && 'Meeting room notes is required.'}
              </FormHelperText>
            )}
          </li>
          {cateringMenuList.length > 0 &&
            cateringMenuList &&
            cateringMenuList.map(categoryMenu => (
              <li className="complimentaryBox" key={categoryMenu.categoryId}>
                <>
                  <p>
                    {`${categoryMenu.categoryName} - ${
                      categoryMenu &&
                      categoryMenu.orderItems &&
                      categoryMenu.orderItems
                        .map(orderItem => orderItem.itemName)
                        .toString()
                        .split(',')
                        .join(', ')
                    }`}
                  </p>
                  <FormControl className={classes.formControl}>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      value={categoryMenu.capacity}
                      className={classes.formSelectBox}
                      onChange={e => {
                        setCapaCityValue(e.target.value);
                        menuDetails(
                          {
                            categoryId: categoryMenu.categoryId,
                            categoryName: categoryMenu.categoryName,
                            menu:
                              categoryMenu &&
                              categoryMenu.orderItems &&
                              categoryMenu.orderItems.map(
                                orderItem =>
                                  orderItem && {
                                    ...orderItem,
                                    id: orderItem.itemId,
                                    itemId: undefined,
                                    itemDescription: undefined,
                                    price: undefined,
                                    quantity: undefined,
                                  },
                              ),
                          },
                          e.target.value,
                        );
                      }}
                    >
                      {obj.array.map(item => (
                        <MenuItem value={item} name={item} key={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};
export default MeetingRoom;
