/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import { CircularProgress, Divider, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import dowmArw from '../../images/down-arw.svg';
import './cateringmenu.css';
import { CateringManuContext } from '../../context/CateringManuContext';
import CateringMenuFilters from './CateringMenuFilters';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import { GlobalContext } from '../../context/GlobalContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import AddButton from '../../components/Buttons/AddButton';
import SnackBar from '../../components/SnackBar/SnackBar';
import EditButton from '../../components/Buttons/EditButton';

const columns = [
  {
    id: 'categoryName',
    SortOrder: 'desc',
    SortBy: 'categoryName',
    label: 'CATEGORY',
  },
  {
    id: 'supplierName',
    SortOrder: 'desc',
    SortBy: 'supplierName',
    label: 'SUPPLIER',
  },
  {
    id: 'itemName',
    SortOrder: 'desc',
    SortBy: 'itemName',
    label: 'ITEM',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'pricePerQuantity',
    SortOrder: 'desc',
    SortBy: 'pricePerQuantity',
    label: 'PRICE £ (EX. VAT)',
    align: 'right',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'description',
    SortOrder: 'desc',
    SortBy: 'itemDescription',
    label: 'DESCRIPTION ',
  },

  {
    id: 'imagePresent',
    SortOrder: 'desc',
    SortBy: 'imagePresent',
    label: 'IMAGE',
    align: 'left',
    format: value => value.toLocaleString('en-US'),
  },

  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'STATUS',
    align: 'left',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'edit',
    SortOrder: '',
    SortBy: '',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 28px 10px 15px',
    color: '#444444',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:nth-child(5)': {
      '&:after': {
        display: 'none',
      },
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  accountBoxImg: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      margin: '0 10px',
    },
  },
  tableBody: {
    verticalAlign: 'middle',
    '& th': {
      fontSize: '14px',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
    },
    '& td': {
      fontSize: '14px',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  pageListMain: {
    position: 'relative',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '10px',
    padding: '5px 0',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#838384',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '30px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
  platformIcons: {
    display: 'flex',
    width: 70,
    margin: 'auto',
  },
});

const CateringMenuList = () => {
  const classes = useStyles();
  const {
    cateringMenuList,
    setSortOrder,
    sortOrder,
    setSortBy,
    sortBy,
    loading,
  } = useContext(CateringManuContext);
  const { rolePermission } = useContext(GlobalContext);
  const history = useHistory();
  const [active, setActive] = useState(false);

  const handleClick = (columnId, sortName) => {
    setSortOrder(sortOrder === columnId ? 'asc' : 'desc');
    setSortBy(sortBy === sortName ? sortBy : sortName);
  };

  const viewItem = rowId => {
    history.push(`/view-cateringmenu/${rowId}`);
  };

  const breadCrumbs = {
    listBreadCrumbPath: '/catering-menu-list',
    listBreadCrumbTitle: 'Catering menu',
  };

  return (
    <div className={classes.pageListMain}>
      <SnackBar />
      {rolePermission && rolePermission.createCateringItem && (
        <AddButton route="/create-cateringmenu" />
      )}
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      <CateringMenuFilters />
      <Divider light />
      <div className="pageContainer tableContainer offerListTable">
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      className={`${classes.tableHeaderCell} noSort ${
                        active && column.SortBy === sortBy ? 'active' : ''
                      }`}
                      align={column.align}
                      id={column.label}
                      key={column.id}
                      disabled={
                        column.SortBy === 'itemDescription' &&
                        column.SortBy === ''
                      }
                      onClick={() => {
                        if (
                          (column.SortBy !== 'itemDescription' &&
                            column.SortBy !== '') ||
                          column.SortBy === sortBy
                        ) {
                          handleClick(column.SortOrder, column.SortBy);
                          setActive(!active);
                        }
                      }}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TableBody>
                  {cateringMenuList && cateringMenuList.length > 0 ? (
                    cateringMenuList.map(cateringMenu => (
                      <TableRow
                        className={classes.tableBody}
                        key={cateringMenu.id}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          valign="middle"
                          style={{ maxWidth: 160, width: 160 }}
                          onClick={() => {
                            viewItem(cateringMenu.id);
                          }}
                        >
                          <div className="rowEllips">
                            {cateringMenu.category.categoryName}
                          </div>
                        </TableCell>
                        <TableCell
                          style={{ maxWidth: 160, width: 160 }}
                          onClick={() => {
                            viewItem(cateringMenu.id);
                          }}
                        >
                          <div className="rowEllips">
                            {cateringMenu.category.supplierName}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 130, width: 130 }}
                          onClick={() => {
                            viewItem(cateringMenu.id);
                          }}
                        >
                          <Tooltip title={cateringMenu.itemName}>
                            <div className="rowEllips">
                              {cateringMenu.itemName}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ maxWidth: 150, width: 150 }}
                          onClick={() => {
                            viewItem(cateringMenu.id);
                          }}
                        >
                          <div className="rowEllips">
                            {cateringMenu.pricePerQuantity.toFixed(2)}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            maxWidth: 220,
                            width: 220,
                            textTransform: 'initial',
                          }}
                          onClick={() => {
                            viewItem(cateringMenu.id);
                          }}
                        >
                          <Tooltip title={cateringMenu.itemDescription}>
                            <div className="rowEllips">
                              {cateringMenu.itemDescription}
                            </div>
                          </Tooltip>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 120, width: 120 }}
                          onClick={() => {
                            viewItem(cateringMenu.id);
                          }}
                        >
                          <div className="rowEllips">{cateringMenu.imagePresent}</div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 120, width: 120 }}
                          onClick={() => {
                            viewItem(cateringMenu.id);
                          }}
                        >
                          <div className="rowEllips">{cateringMenu.status}</div>
                        </TableCell>
                        <TableCell style={{ maxWidth: 50, width: 50 }}>
                          {rolePermission &&
                            rolePermission.editCateringItem && (
                              <EditButton
                                route={`/edit-cateringmenu/${cateringMenu.id}`}
                              />
                            )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <NoResultsFound />
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};
export default CateringMenuList;
