import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  CircularProgress,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useHistory, useParams } from 'react-router-dom';
import { cateringCategorySchema } from '../../utils/formValidate';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import dowmArw from '../../images/down-arw.svg';
import { GlobalContext } from '../../context/GlobalContext';
import WarningPopup from '../../components/WarningPopup/WarningPopup';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { cateringCatgorystaticDate } from '../../utils/constants';
import SaveAndCancelButtons from '../../components/Buttons/SaveAndCancelButtons';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& > div': {
        '& > div': {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
}));

const CreateCategory = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const { setSuccessMessage } = useContext(GlobalContext);
  const [supplierName, setSupplierName] = useState('Lantana');
  const [categoryName, setCategoryName] = useState('');
  const [timePicker, setTimePicker] = useState([]);
  const [startTime, setstartTime] = useState(null);
  const [endTime, setendTime] = useState(null);
  const [status, setStatus] = useState('Available');
  const [loading, setLoading] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState('');
  const [cateringCategoryToggle, setcateringCategoryToggle] = useState(false);

  const intervals = (startString, endString) => {
    const start = moment(startString, 'hh:mm a');
    const end = moment(endString, 'hh:mm a');
    const newValue = [];
    start.minutes(Math.ceil(start.minutes() / 15) * 15);
    const current = moment(start);
    while (current <= end) {
      newValue.push(current.format('hh:mm a'));
      current.add(15, 'minutes');
    }
    return newValue;
  };

  const selectedTime = e => {
    const time = moment
      .tz(`${cateringCatgorystaticDate} ${e.target.value}`, 'Europe/London')
      .toISOString();
    return time;
  };

  if (
    !JSON.parse(localStorage.getItem('rolePermission'))[0]
      .createCateringCatergory
  ) {
    history.push('/cateringlist');
  }

  useEffect(() => {
    setTimePicker(intervals('12:00:00 am', '11:59:00 pm'));
    if (id) {
      setLoading(true);
      ApiClient.apiGet(`${apiEndPoints.getcateringCategoryDetails}/${id}`)
        .then(data => {
          const cateringData = data && data.data && data.data.data;
          setSupplierName(cateringData.supplierName);
          setCategoryName(cateringData.categoryName);
          setstartTime(cateringData.startTime);
          setendTime(cateringData.endTime);
          setStatus(cateringData.status);
          setLoading(false);
        })
        .catch(error => {
          setcateringCategoryToggle(false);
          setLoading(false);
          alert(error);
        });
    }
  }, [id]);

  useEffect(() => {
    cateringCategorySchema
      .validate({ categoryName, startTime, endTime })
      .then(() => {
        if (cateringCategoryToggle && endTime >= startTime) {
          setLoading(true);
          const payload = {
            id: id || undefined,
            supplierName,
            categoryName,
            startTime,
            endTime,
            status,
          };
          if (id) {
            ApiClient.apiPut(
              apiEndPoints.getcateringCategoryDetails + id,
              payload,
            )
              .then(data => {
                setLoading(false);
                const successmsg =
                  data && data.data.message
                    ? `Catering category has been updated successfully!`
                    : '';
                setSuccessMessage(successmsg);
                history.push('/cateringlist');
              })
              .catch(error => {
                setcateringCategoryToggle(false);
                setLoading(false);
                alert(error);
              });
          } else {
            ApiClient.apiPost(apiEndPoints.cateringCategory, payload)
              .then(data => {
                setLoading(false);
                const successmsg =
                  data && data.data.message
                    ? `Catering category has been created successfully!`
                    : '';
                setSuccessMessage(successmsg);
                history.push('/cateringlist');
              })
              .catch(error => {
                alert(error);
                setcateringCategoryToggle(false);
                setLoading(false);
              });
          }
        }
      })
      .catch(error => {
        console.log(error, 'error');
        if (cateringCategoryToggle) {
          setcateringCategoryToggle(false);
          setErrorMsgs(error);
        }
      });
  }, [
    supplierName,
    startTime,
    endTime,
    categoryName,
    status,
    cateringCategoryToggle,
  ]);

  const errorFieldValue = errorMsgs && errorMsgs.value;

  const breadCrumbs = !id
    ? {
        listBreadCrumbPath: '/cateringlist',
        listBreadCrumbTitle: 'Catering categories',
        addBreadCrumbPath: '/create-category',
        addBreadCrumbTitle: 'Create new category',
      }
    : {
        listBreadCrumbPath: '/cateringlist',
        listBreadCrumbTitle: 'Catering categories',
        viewBreadCrumbPath: '/view-category/',
        viewBreadCrumbTitle: 'View category',
        editBreadCrumbPath: '/edit-category/',
        editBreadCrumbTitle: 'Edit category',
      };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.addUserFormBody}>
        <Breadcrumb breadCrumbs={breadCrumbs} />
        <Divider light />
        {loading ? (
          <CircularProgress color="inherit" />
        ) : (
          <div className={classes.addUserFormMain}>
            <div className="pageContainer addUserContainer">
              <div className={`${classes.addUserForm} createcategoryForm`}>
                <ul className="offerFormList">
                  <li>
                    <FormLabel component="legend">Category Details</FormLabel>
                  </li>
                </ul>
                <ul className={`${classes.InputSection} creaeCategoryFieldTop`}>
                  <li>
                    <span className="suplHeading">Supplier*</span>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="Active" name="Available">
                        <div className={`${classes.radioSection} radioSection`}>
                          <FormControlLabel
                            control={<Radio />}
                            label="Lantana"
                            checked={supplierName === 'Lantana'}
                            onChange={() => {
                              setSupplierName('Lantana');
                            }}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Derwent London "
                            checked={supplierName === 'Derwent London'}
                            onChange={() => {
                              setSupplierName('Derwent London');
                            }}
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </li>
                </ul>
                <ul className={`${classes.InputSection} creaeCategoryField`}>
                  <li>
                    <span>Category name*</span>
                    <Input
                      className="textareaSmallBox"
                      type="text"
                      size="10"
                      value={categoryName}
                      inputProps={{ maxLength: 100 }}
                      onChange={e => {
                        setCategoryName(e.target.value);
                      }}
                    />
                    <div className="descriptionBox">{`${categoryName.length}/100`}</div>
                    {errorFieldValue && errorFieldValue.categoryName === '' && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          categoryName === '' && 'Category name is required.'
                        }
                      >
                        {categoryName === '' && 'Category name is required.'}
                      </FormHelperText>
                    )}
                  </li>
                </ul>
                <ul className={`${classes.InputSection} offerTimeCat`}>
                  <li className={`${classes.InputSection} `}>
                    <span>Availabilty start time*</span>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      id="date-picker1"
                      value={moment
                        .tz(startTime, 'Europe/London')
                        .format('hh:mm a')}
                      className={classes.formSelectBox}
                      onChange={e => {
                        setstartTime(selectedTime(e));
                        setcateringCategoryToggle(false);
                      }}
                    >
                      {timePicker &&
                        timePicker.map(name => {
                          return (
                            <MenuItem value={name} name={name} key={name}>
                              {name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {errorFieldValue && errorFieldValue.startTime === null && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={startTime === null && 'Start time is required'}
                      >
                        {startTime === null && 'Start Time is required'}
                      </FormHelperText>
                    )}
                  </li>
                  <li>
                    <span>Availabilty end time*</span>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                      id="date-picker1"
                      value={moment
                        .tz(endTime, 'Europe/London')
                        .format('hh:mm a')}
                      className={classes.formSelectBox}
                      onChange={e => {
                        setendTime(selectedTime(e));
                        setcateringCategoryToggle(false);
                      }}
                    >
                      {timePicker &&
                        timePicker.map(time => {
                          return (
                            <MenuItem value={time} name={time} key={time}>
                              {time}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {((errorFieldValue && errorFieldValue.endTime === null) ||
                      (endTime !== null &&
                        startTime !== null &&
                        endTime < startTime)) && (
                      <FormHelperText
                        className={classes.errorMsg}
                        error={
                          (endTime === null && 'End time is required') ||
                          (endTime < startTime &&
                            `Can't be earlier than Start time.`)
                        }
                      >
                        {(endTime === null && 'End Time is required') ||
                          (endTime < startTime &&
                            `Can't be earlier than Start time.`)}
                      </FormHelperText>
                    )}
                  </li>
                </ul>
                <Divider className="formDivider" light />
                <ul className="offerFormList">
                  <li className="statusMarBtm">
                    <FormLabel component="legend">Status</FormLabel>
                  </li>
                </ul>
                <ul className={classes.InputSection}>
                  <li>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="Active" name="Available">
                        <div className={`${classes.radioSection} radioSection`}>
                          <FormControlLabel
                            control={<Radio />}
                            label="Available"
                            checked={status === 'Available'}
                            onChange={() => {
                              setStatus('Available');
                            }}
                          />
                          <FormControlLabel
                            control={<Radio />}
                            label="Decommissioned"
                            checked={status === 'Decommissioned'}
                            onChange={() => {
                              setStatus('Decommissioned');
                            }}
                          />
                        </div>
                      </RadioGroup>
                    </FormControl>
                  </li>
                </ul>
                <SaveAndCancelButtons
                  setToggle={setcateringCategoryToggle}
                  route="/cateringlist"
                />
                <WarningPopup />
              </div>
            </div>
          </div>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default CreateCategory;
