/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import { Divider, CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import dowmArw from '../../images/down-arw.svg';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import { ManageVoucherContext } from '../../context/ManageVoucherContext';
import { GlobalContext } from '../../context/GlobalContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import AddButton from '../../components/Buttons/AddButton';
import SnackBar from '../../components/SnackBar/SnackBar';
import EditButton from '../../components/Buttons/EditButton';
import './ManageVoucher.css';
import ManageVoucherFilter from './ManageVoucherFilter';

const columns = [
  {
    id: 'code',
    SortOrder: 'desc',
    SortBy: 'code',
    label: 'Voucher code',
  },
  {
    id: 'type',
    SortOrder: 'desc',
    SortBy: 'type',
    label: 'Discount Type',
  },
  {
    id: 'discount',
    SortOrder: 'desc',
    SortBy: 'discount',
    label: 'Discount',
  },
  {
    id: 'validFrom',
    SortOrder: 'desc',
    SortBy: 'startDate',
    label: 'Start date',
    align: 'left',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'validTo',
    SortOrder: 'desc',
    SortBy: 'endDate',
    label: 'Expiry date',
    align: 'left',
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'redeemed',
    SortOrder: 'desc',
    SortBy: 'redeemed',
    label: 'Redeemed',
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'STATUS',
    align: 'left',
  },
  {
    id: 'voucherType',
    SortOrder: 'desc',
    SortBy: 'voucherType',
    label: 'VOUCHER TYPE',
    align: 'left',
  },
  {
    id: 'edit',
    SortOrder: '',
    SortBy: '',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 15px',
    color: '#444444',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  accountBoxImg: {
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      margin: '0 10px',
    },
  },
  tableBody: {
    verticalAlign: 'middle',
    '& th': {
      fontSize: '14px',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
    },
    '& td': {
      fontSize: '14px',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      padding: '8px 16px',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  pageListMain: {
    position: 'relative',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '10px',
    padding: '5px 0',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#838384',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
  },
  customerNameNew: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginLeft: '4px',
    '& img': {
      width: '30px',
    },
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle',
  },
  platformIcons: {
    display: 'flex',
    width: 70,
    margin: 'auto',
  },
});

const ManageVoucherList = () => {
  const classes = useStyles();
  const {
    sortOrder,
    setSortOrder,
    sortBy,
    setSortBy,
    manageVoucherList,
    loading,
    voucherTypes,
  } = useContext(ManageVoucherContext);
  const { rolePermission } = useContext(GlobalContext);
  const history = useHistory();
  const [active, setActive] = useState(false);

  const handleClick = (columnId, sortName) => {
    setSortOrder(sortOrder === columnId ? 'ASC' : 'DESC');
    setSortBy(sortBy === sortName ? sortBy : sortName);
  };

  const viewVoucher = rowId => {
    history.push(`/view-voucher/${rowId}`);
  };

  const breadCrumbs = {
    listBreadCrumbPath: '/voucherlist',
    listBreadCrumbTitle: 'Voucher List',
  };

  return (
    <div className={classes.pageListMain}>
      <SnackBar />
      {rolePermission && rolePermission.createVoucerCoder && (
        <AddButton route="/Create-Manage-Voucher" />
      )}
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      <ManageVoucherFilter />
      <Divider light />
      <div className="pageContainer tableContainer offerListTable">
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      className={`${classes.tableHeaderCell} noSort ${
                        active && column.SortBy === sortBy ? 'active' : ''
                      }`}
                      align={column.align}
                      id={column.label}
                      key={column.id}
                      disabled={column.SortBy === ''}
                      onClick={() => {
                        if (column.SortBy !== '' || column.SortBy === sortBy) {
                          handleClick(column.SortOrder, column.SortBy);
                          setActive(!active);
                        }
                      }}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TableBody>
                  {manageVoucherList && manageVoucherList.length > 0 ? (
                    manageVoucherList.map(row => (
                      <TableRow className={classes.tableBody} key={row.id}>
                        <TableCell
                          component="th"
                          scope="row"
                          align="left"
                          valign="middle"
                          style={{
                            maxWidth: 150,
                            width: 150,
                            verticalAlign: 'middle',
                          }}
                          onClick={() => {
                            viewVoucher(row.id);
                          }}
                        >
                          <div className="rowEllips">{row.code}</div>
                        </TableCell>
                        <TableCell
                          style={{ maxWidth: 180, width: 180 }}
                          onClick={() => {
                            viewVoucher(row.id);
                          }}
                        >
                          <div className="rowEllips">{row.type}</div>
                        </TableCell>
                        <TableCell
                          style={{ maxWidth: 180, width: 180 }}
                          onClick={() => {
                            viewVoucher(row.id);
                          }}
                        >
                          <div className="rowEllips">{`${row.discount}`}</div>
                        </TableCell>
                        <TableCell
                          className="toUppercase"
                          align="left"
                          style={{ maxWidth: 180, width: 180 }}
                          onClick={() => {
                            viewVoucher(row.id);
                          }}
                        >
                          <div className="rowEllips">
                            {moment
                              .tz(row.startDate, 'Europe/London')
                              .format('DD MMM YYYY')}
                          </div>
                        </TableCell>
                        <TableCell
                          className="toUppercase"
                          align="left"
                          style={{ maxWidth: 180, width: 180 }}
                          onClick={() => {
                            viewVoucher(row.id);
                          }}
                        >
                          <div className="rowEllips">
                            {moment
                              .tz(row.endDate, 'Europe/London')
                              .format('DD MMM YYYY')}
                          </div>
                        </TableCell>
                        <TableCell
                          style={{ maxWidth: 180, width: 180 }}
                          onClick={() => {
                            viewVoucher(row.id);
                          }}
                        >
                          <div className="rowEllips">{row.redeemed}</div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 180, width: 180 }}
                          onClick={() => {
                            viewVoucher(row.id);
                          }}
                        >
                          <div className="rowEllips">{row.status}</div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 180, width: 180 }}
                          onClick={() => {
                            viewVoucher(row.id);
                          }}
                        >
                          <div className="rowEllips">
                            {row.voucherType || voucherTypes.unlimitedUse}
                          </div>
                        </TableCell>
                        <TableCell style={{ maxWidth: 50, width: 50 }}>
                          {rolePermission && rolePermission.editVoucerCode && (
                            <EditButton route={`/edit-voucher/${row.id}`} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <NoResultsFound />
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};
export default ManageVoucherList;
