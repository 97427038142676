import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { runWithAdal } from 'react-adal';
import { authContext } from './adal';
import './index.css';
import Routes from './Route/Routes';
import Payments from './containers/Payments/Payments';
import PaymentLinkPayments from './containers/Payments/PaymentLinkPayments';
import PaymentSuccess from './containers/Payments/PaymentSuccess';
import { GlobalContextProvider } from './context/GlobalContext';
import { PaymentGlobalContextProvider } from './context/PaymentGlobalContext';
import MeetingRoomGuestlist from './containers/MeetingRooms/MeetingRoomGuestlist';
// import { CategoryContextProvider } from '../context/CateringCategoryContext';

// const roles = localStorage.getItem('rolePermission')
// const rolesJson = JSON.parse(roles)
// console.log(rolesJson[0].createArticle)

if (
  window.location.pathname.split('/')[1] === 'payments' ||
  window.location.pathname.split('/')[1] === 'events-payments' ||
  window.location.pathname.split('/')[1] === 'paymentLinkPayments' ||
  window.location.pathname.split('/')[1] === 'requestGuestList'
) {
  ReactDOM.render(
    <PaymentGlobalContextProvider>
      <Router>
        <Switch>
          <Route exact path="/payments/:id/:token" component={Payments} />
          <Route exact path="/paymentLinkPayments/:id/:token" component={PaymentLinkPayments} />
          <Route
            exact
            path="/events-payments/:id/:token"
            component={Payments}
          />
          <Route exact path="/payments/success" component={PaymentSuccess} />
          <Route
            exact
            path="/requestGuestList/:id/:token"
            component={MeetingRoomGuestlist}
          />
        </Switch>
      </Router>
    </PaymentGlobalContextProvider>,
    document.getElementById('root'),
  );
} else {
  runWithAdal(
    authContext,
    () => {
      ReactDOM.render(
        <GlobalContextProvider>
          <Routes />
        </GlobalContextProvider>,
        document.getElementById('root'),
      );
    },
    false,
  );
}
