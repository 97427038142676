/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import LocationFilter from '../../components/Filters/LocationFilter';
import CategoryFilter from '../../components/Filters/CategoryFilter';
import SearchFilter from '../../components/SearchFilter/SearchFilter';
import { GlobalContext } from '../../context/GlobalContext';
import useFilterStyles from '../../components/Styles/useFilterStyles';
import { ManageOffersContext } from '../../context/ManageOffersContext';

const LoopVideosFilter = () => {
  const classes = useFilterStyles();
  const {
    articleSearch,
    setArticleSearch,
    categories,
    setCatagoryActive,
    selectedCategory,
    setSelectedCategory,
    setNewsBuildings,
    setNewsVillages,
    newsBuildings,
    newsVillages,
  } = useContext(ManageOffersContext);
  const { buildingGroupsList } = useContext(GlobalContext);
  const [locationSearch, setLocationSearch] = useState(false);
  const [categorySearch, setCategorySearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [categoryTab, setCategoryTab] = useState('');
  const [OnCheckedLocationButton, setOnCheckedLocationButton] = useState('');
  const placeholder = 'Search by video title';

  const handleClose = () => {
    setAnchorEl(null);
    setLocationSearch(false);
    setOnCheckedLocationButton(false);
    setCategorySearch(null);
    setCategoryTab(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.filerSection}>
      <div className="pageContainer filterContainer">
        <div className={classes.filterMain}>
          <div className={classes.leftFilter}>
            <ul className={`${classes.leftFilterIn} leftFilterIn`}>
              <LocationFilter
                locationSearch={locationSearch}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setLocationSearch={setLocationSearch}
                handleClose={handleClose}
                setOnCheckedLocationButton={setOnCheckedLocationButton}
                OnCheckedLocationButton={OnCheckedLocationButton}
                id={id}
                buildingGroupsList={buildingGroupsList}
                buildings={newsBuildings}
                villages={newsVillages}
                setVillages={setNewsVillages}
                setBuildings={setNewsBuildings}
                buildingAttributes
              />
              <CategoryFilter
                values={{
                  id,
                  handleClose,
                  categories,
                  anchorEl,
                  setAnchorEl,
                  categoryTab,
                  setCategoryTab,
                  setCatagoryActive,
                  categorySearch,
                  setCategorySearch,
                  selectedCategory,
                  setSelectedCategory,
                }}
                article
              />
            </ul>
          </div>
          <div className={`${classes.rightFilter} rightFilterPagination`}>
            <div className={`${classes.exportSerch} exportSerch`}>
              <ul>
                <SearchFilter
                  customerSearch={articleSearch}
                  setCustomerSearch={setArticleSearch}
                  placeholder={placeholder}
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoopVideosFilter;
