/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import { CircularProgress, Divider, Tooltip } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import dowmArw from '../../images/down-arw.svg';
import EventsFilter from './EventsFilter';
import NoResultsFound from '../../components/NoResultsFound/NoResultsFound';
import { GlobalContext } from '../../context/GlobalContext';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import { EventsContext } from '../../context/EventsContext';
import AddButton from '../../components/Buttons/AddButton';
import SnackBar from '../../components/SnackBar/SnackBar';
import EditButton from '../../components/Buttons/EditButton';

const columns = [
  {
    id: 'event',
    SortOrder: 'desc',
    SortBy: 'name',
    label: 'EVENT',
    minWidth: 190,
    maxWidth: 190,
  },
  {
    id: 'venue',
    SortOrder: 'desc',
    SortBy: 'venue',
    label: 'VENUE',
    minWidth: 220,
    maxWidth: 220,
  },
  {
    id: 'date',
    SortOrder: 'desc',
    SortBy: 'eventDate',
    label: 'DATE',
    align: 'left',
    minWidth: 110,
    maxWidth: 110,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'start',
    SortOrder: 'desc',
    SortBy: 'startTime',
    label: 'START',
    align: 'left',
    minWidth: 110,
    maxWidth: 110,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'END',
    SortOrder: 'desc',
    SortBy: 'endTime',
    label: 'END',
    align: 'left',
    minWidth: 110,
    maxWidth: 110,
    format: value => value.toLocaleString('en-US'),
  },
  {
    id: 'tickets',
    SortOrder: 'desc',
    SortBy: 'ticketCost',
    label: 'TICKETS ',
    minWidth: 115,
    maxWidth: 115,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'availability',
    SortOrder: 'desc',
    SortBy: 'maxTickets',
    label: 'AVAILABILITY',
    minWidth: 100,
    maxWidth: 100,
    align: 'right',
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    SortOrder: 'desc',
    SortBy: 'status',
    label: 'STATUS',
    minWidth: 100,
    maxWidth: 100,
    format: value => value.toFixed(2),
  },
  {
    id: 'edit',
    SortOrder: '',
    SortBy: '',
    label: '',
    minWidth: 50,
    maxWidth: 50,
    format: value => value.toFixed(2),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'calc(100vh - 240px)',
  },
  tableHeaderCell: {
    background: '#f8f8f8',
    border: 'none',
    padding: '10px 28px 10px 15px',
    color: '#444444',
    fontSize: '13px',
    fontWeight: 700,
    fontFamily: ['Varta', 'sans-serif'].join(','),
    cursor: 'pointer',
    '&:after': {
      position: 'absolute',
      content: '""',
      background: `url(${dowmArw}) no-repeat 95% center !important`,
      right: '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      height: '10px',
      width: '10px',
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      background: '#dfdfdf',
      right: '0',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '1px',
      height: '40%',
    },
    '&:last-child': {
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  },
  active: {
    '&:after': {
      transform: 'translateY(-50%) rotate(180deg)',
    },
  },
  accountBoxImg: {
    '& ul': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    display: 'flex',
    justifyContent: 'center',
    '& li': {
      width: '30%',
      '& img': {
        margin: '0 auto',
        width: '75%',
      },
    },
  },
  tableBody: {
    '& th': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      padding: '8px 16px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      verticalAlign: 'top',
    },
    '& td': {
      fontSize: '14px',
      color: '#838384',
      fontWeight: 'bold',
      padding: '8px 16px',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      textTransform: 'capitalize',
      verticalAlign: 'top',
    },
  },
  customerEmail: {
    fontWeight: 'normal',
    textTransform: 'lowercase',
    marginTop: '4px',
  },
  customerVillg: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
    marginTop: '4px',
  },
  pageListMain: {
    position: 'relative',
  },
  moreList: {
    '& h4': {
      fontSize: '11px',
      fontWeight: 'bold',
      color: '#838384',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
    '& li': {
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '14px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
          },
        },
      },
    },
  },
  moreListHeadtwo: {
    marginTop: '25px',
  },
  buildVillg: {
    textTransform: 'capitalize',
    marginBottom: '4px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  moreListHead: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
  filtercheckTop: {
    paddingLeft: '10px',
    '& li': {
      padding: 0,
      '& label': {
        '& span': {
          '&:last-child': {
            fontSize: '12px',
            color: '#444444',
            fontFamily: ['Varta', 'sans-serif'].join(','),
            lineHeight: '19px',
            position: 'relative',
            top: '2px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
            width: '72%',
          },
        },
      },
    },
  },
  totalCount: {
    marginTop: '10px',
    padding: '5px 0',
    '& ul': {
      display: 'flex',
      alignItems: 'çenter',
      justifyContent: 'space-between',
      '& li': {
        color: '#838384',
        fontSize: '14px',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
    },
  },
  userAddTime: {
    fontWeight: 'normal',
  },
  accountBoxImgColumn: {
    verticalAlign: 'middle !important',
  },
});

const EventsList = () => {
  const {
    eventsList,
    loading,
    setSortOrder,
    sortOrder,
    setSortBy,
    sortBy,
  } = useContext(EventsContext);
  const { rolePermission } = useContext(GlobalContext);
  const history = useHistory();
  const classes = useStyles();
  const [active, setActive] = useState(false);

  const handleClick = (columnId, sortName) => {
    setSortOrder(sortOrder === columnId ? 'asc' : 'desc');
    setSortBy(sortBy === sortName ? sortBy : sortName);
  };

  const viewEventDetails = rowId => {
    history.push(`/view-event-details/${rowId}`);
  };

  const breadCrumbs = {
    listBreadCrumbPath: '/events-list',
    listBreadCrumbTitle: 'Events ',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.pageListMain}>
      <SnackBar />
      {rolePermission && rolePermission.createEvent && (
        <AddButton route="/create-an-event" />
      )}
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      <EventsFilter />
      <Divider light />
      <div className="pageContainer tableContainer">
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      className={`${classes.tableHeaderCell} noSort ${
                        active && column.SortBy === sortBy ? 'active' : ''
                      }`}
                      key={column.id}
                      disabled={column.SortBy === ''}
                      align="left"
                      id={column.label}
                      onClick={() => {
                        if (column.SortBy !== '' || column.SortBy === sortBy) {
                          handleClick(column.SortOrder, column.SortBy);
                          setActive(!active);
                        }
                      }}
                      style={{
                        minWidth: column.minWidth,
                        textAlign: column.align,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <CircularProgress color="inherit" />
              ) : (
                <TableBody>
                  {eventsList && eventsList.length > 0 ? (
                    eventsList.map(row => (
                      <TableRow key={row.id} className={classes.tableBody}>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ maxWidth: 190, width: 190 }}
                          onClick={() => {
                            viewEventDetails(row.id);
                          }}
                        >
                          <div className="rowEllips">
                            <Tooltip title={row && row.name}>
                              <div className={classes.buildVillg}>
                                {row && row.name}
                              </div>
                            </Tooltip>
                            <div className={classes.customerVillg}>
                              {row && row.host}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.accountBoxImgColumn}
                          align="left"
                          style={{ maxWidth: 220, width: 220 }}
                          onClick={() => {
                            viewEventDetails(row.id);
                          }}
                        >
                          <div className="rowEllips">
                            <Tooltip
                              title={row && row.address && row.address.line1}
                            >
                              <div className={classes.buildVillg}>
                                {row && row.address && row.address.line1}
                              </div>
                            </Tooltip>
                            <div className={classes.customerVillg}>
                              {row && row.address && row.address.city}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 110, width: 110 }}
                          onClick={() => {
                            viewEventDetails(row.id);
                          }}
                        >
                          {moment
                            .tz(row.eventDate, 'Europe/London')
                            .format('DD MMM YYYY')}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            maxWidth: 110,
                            width: 110,
                            textTransform: 'lowercase',
                          }}
                          onClick={() => {
                            viewEventDetails(row.id);
                          }}
                        >
                          {moment
                            .tz(row.startTime, 'Europe/London')
                            .format('hh:mm a')}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            maxWidth: 110,
                            width: 110,
                            textTransform: 'lowercase',
                          }}
                          onClick={() => {
                            viewEventDetails(row.id);
                          }}
                        >
                          {moment
                            .tz(row.endTime, 'Europe/London')
                            .format('hh:mm a')}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            maxWidth: 115,
                            width: 115,
                            textTransform: 'lowercase',
                          }}
                          onClick={() => {
                            viewEventDetails(row.id);
                          }}
                        >
                          <div className={classes.buildVillg}>
                            {row && row.ticketCost.toFixed(2)}
                          </div>
                          <div
                            className={`${classes.customerVillg} eventGuest`}
                          >
                            {`${row && row.maxGuests}${
                              (row && row.maxGuests === 0) ||
                              (row && row.maxGuests > 1)
                                ? ' guests'
                                : ' guest'
                            }`}
                          </div>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ maxWidth: 115, width: 115 }}
                          onClick={() => {
                            viewEventDetails(row.id);
                          }}
                        >
                          <div className={classes.buildVillg}>
                            {Number(row && row.maxTickets) -
                              Number(row.soldOutTickets)}
                          </div>
                          <div className={classes.customerVillg}>
                            {`/ ${row && row.maxTickets}`}
                          </div>
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{ maxWidth: 100, width: 100 }}
                          onClick={() => {
                            viewEventDetails(row.id);
                          }}
                        >
                          {row.status}
                        </TableCell>

                        <TableCell
                          className={classes.accountBoxImgColumn}
                          align="center"
                          style={{ maxWidth: 50, width: 50 }}
                        >
                          {rolePermission && rolePermission.updateEvent && (
                            <EditButton route={`/edit-an-event/${row.id}`} />
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <NoResultsFound />
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </div>
  );
};

export default EventsList;
