import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Button,
  CircularProgress,
  Divider,
  Dialog,
  DialogActions,
  Select,
  MenuItem,
  Input,
  FormHelperText,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import dowmArw from '../../images/down-arw.svg';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { logOut } from '../../utils/logOut';
import { GlobalContext } from '../../context/GlobalContext';
import { viewBookingSchema } from '../../utils/formValidate';
import {
  BookingPeriod,
  bookingStatus,
  bookingTimeType,
} from '../../utils/constants';

const useStyles = makeStyles({
  table: {
    maxWidth: '100%',
  },
  BookingStepForm: {
    margin: '0 auto',
    padding: '0 0 40px',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formButton: {
    margin: '20px 0 32px !important',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      height: '47px',
      lineHeight: 'normal',
      border: '1px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff !important',
      marginLeft: '30px',
      cursor: 'pointer',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#000000 !important',
        border: '2px solid #000',
        padding: '0 32px',
      },
      // '&:nth-child(2)': {
      //   background: 'transparent',
      //   color: '#000000 !important',
      //   border: '2px solid #000',
      //   padding: '0 32px',
      // },
      '& extendBtn': {
        background: 'transparent',
        color: '#000000 !important',
        border: '2px solid #000',
        padding: '0 32px',
      },
    },
  },
  extendPopupFormButton: {
    '& button': {
      '&:first-child': {
        background: '#000',
        color: '#fff !important',
        border: '2px solid #000',
        padding: '0 32px',
        marginTop: 20,
      },
    },
  },
  cancelOrderButton: {
    margin: '20px 0 30px !important',
    '& button': {
      '&:first-child': {
        background: '#000',
        color: '#fff !important',
        border: '2px solid #000',
        padding: '0 32px',
      },
    },
  },
  cancelDialog: {
    textAlign: 'center',
    '& h2': {
      fontSize: '19px',
      fontWeight: 'bold',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    '& p': {
      fontSize: '16px',
      fontWeight: 'normal',
      color: '#444444',
      marginBottom: '15px',
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  },
  popupFormButton: {
    margin: '0 !important',
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px !important',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& > div': {
        '& > div': {
          '&:before': {
            display: 'none',
          },
          '&:after': {
            display: 'none',
          },
        },
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  errorMsgForGuest: {
    color: '#f90000',
    width: '100%',
    fontSize: '13px',
    fontFamily: 'Varta sans-serif',
  },
});

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit, total) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price, total };
}

const ViewBookedMeeting = () => {
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const {
    setExtendData,
    setCustomerDetails,
    setMeetingRoomName,
    setExtendTime,
    extendTime,
    eventType,
    setEventType,
    isCatering,
  } = useContext(GlobalContext);
  const [mrName, setMrName] = useState('');
  const [roomLayoutName, setRoomLayoutName] = useState('');
  const [buildingName, setBuildingName] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [totalAmount, setTotalAmount] = useState('');
  const [roomDiscount, setRoomDiscount] = useState('');
  const [vat, setVat] = useState('');
  const [roomTotal, setRoomTotal] = useState('');
  const [customerFirstName, setCustomerFirstName] = useState('');
  const [customerLastName, setCustomerLastName] = useState('');
  const [tenantName, setTenantName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [notes, setNotes] = useState('');
  const [loading, setLoading] = useState(false);
  const [complimentaryMenu, setComplimentaryMenu] = useState('');
  const [listData, setListData] = useState({});
  const [cateringDataPayment, setCateringDataPayment] = useState('');
  const [totalAmountWithVat, setTotalAmountWithVat] = useState('');
  const [mrBookingStatus, setMRBookingStatus] = useState('');
  const [mrBookingMethod, setMRBookingMethod] = useState('');
  const [warningPopUp, setWarningPopUp] = useState(false);
  const [warningPopUpSelected, setWarningPopUpSelected] = useState(false);
  const [staffNotes, setStaffNotes] = useState('');
  const [staffNotes1, setStaffNotes1] = useState([]);
  const [presentDate, setPresentDate] = useState('');
  // const [bookingInvoiceDate, setBookingInvoiceDate] = useState('');
  const [bookingInvoice, setBookingInvoice] = useState('');
  const [cateringInvoice, setCateringInvoice] = useState('');
  const [extendRoomTotal, setExtendRoomTotal] = useState('');
  const [extendTotal, setExtendTotal] = useState('');
  const [extendTotalWithVat, setExtendTotalWithVat] = useState('');
  const [extendDiscount, setExtendDiscount] = useState('');
  const [extendStart, setExtendStart] = useState('');
  const [extendEnd, setExtendEnd] = useState('');
  const [extendInvoice, setExtendInvoice] = useState('');
  const [extend, setExtend] = useState('');
  const [extendPaymentStatus, setExtendPaymentStatus] = useState('');
  const [bookingDate, setBookingDate] = useState('');
  const [voucherDiscount, setVoucherDiscount] = useState('');

  const [open, setOpen] = React.useState(false);
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [guestEmail, setGuestEmail] = useState('');
  const [line, setLine] = useState([]);
  const [guestListRequestedOn, setGuestListRequestedOn] = useState();
  const [firstName, setFirstName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [capacity, setCapacity] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [hostName, setHostName] = useState('');
  const [guestListName, setGuestListName] = useState([]);
  const [turnAroundTime, setTurnAroundTime] = useState('');

  const handleClickOpen = () => {
    setExtendTime('');
    setOpen(true);
  };
  const handleRequestListPopUp = () => {
    setOpenPopUp(true);
  };
  const MakePaymentClick = () => {
    history.push('/order-preview/');
  };

  const errorType = errorMsg && errorMsg.type;
  const errorFieldName = errorMsg && errorMsg.path;
  const errorFieldValue = errorMsg && errorMsg.value;

  const getMeetingRoomData = () => {
    setLoading(true);
    ApiClient.apiGet(`${apiEndPoints.createMrBooking}/${id}`)
      .then(data => {
        setLoading(false);
        const mrData = data && data.data && data.data.data;
        setHostName(mrData?.hostName || '');
        setGuestListRequestedOn(mrData?.guestListCompletedOn);
        setCapacity(mrData?.roomLayout?.maxLayoutCapacity || mrData?.meetingRoom?.capacity || '');
        setGuestEmail(mrData?.customer?.email);
        setFirstName(mrData?.customer?.firstName || '');
        setLine((mrData?.guestList && mrData?.guestList.join('\n')) || '');
        setExtendData(mrData && mrData);
        setEventType(
          mrData?.extendBooking?.endDate < mrData?.endDate
            ? bookingTimeType.pastBooking
            : bookingTimeType.futureBooking,
        );
        setExtendRoomTotal(
          mrData && mrData.extendBooking && mrData.extendBooking.roomTotal,
        );
        setExtend(mrData && mrData.extendBooking);
        setBookingDate(mrData && mrData.createdDate);
        setExtendTotal(
          mrData && mrData.extendBooking && mrData.extendBooking.total,
        );
        setExtendTotalWithVat(
          mrData && mrData.extendBooking && mrData.extendBooking.totalWithVat,
        );
        setExtendPaymentStatus(
          (mrData &&
            mrData.extendBooking &&
            mrData.extendBooking.paymentStatus) ||
            '',
        );
        setVoucherDiscount(mrData && mrData.discount && mrData.discount.amount);
        setExtendStart(
          mrData && mrData.extendBooking && mrData.extendBooking.startDate,
        );
        setExtendEnd(
          mrData && mrData.extendBooking && mrData.extendBooking.endDate,
        );
        setExtendDiscount(
          mrData && mrData.extendBooking && mrData.extendBooking.discount,
        );
        setExtendInvoice(
          mrData &&
            mrData.extendBooking &&
            mrData.extendBooking.extensionInvoice,
        );
        setMeetingRoomName(
          mrData && mrData.meetingRoom && mrData.meetingRoom.name,
        );
        setCustomerDetails(mrData && mrData.customer);
        setMRBookingStatus(mrData && mrData.status);
        setMRBookingMethod(mrData && mrData.payment && mrData.payment.method);
        setCateringDataPayment(mrData && mrData.payment);
        setMrName(mrData.meetingRoom.name);
        setListData(mrData.cateringOrder);
        setRoomLayoutName(
          mrData && mrData.roomLayout && mrData.roomLayout.name,
        );
        setBuildingName(mrData && mrData.building && mrData.building.name);
        setStartTime(mrData && mrData.startDate);
        setEndTime(mrData && mrData.endDate);
        setTotalAmount(mrData && mrData.payment && mrData.payment.total);
        setRoomDiscount(
          mrData && mrData.payment && mrData.payment.roomDiscount,
        );
        setVat(mrData && mrData.payment && mrData.payment.vatRate);
        setRoomTotal(mrData && mrData.payment && mrData.payment.roomTotal);
        setTotalAmountWithVat(
          mrData && mrData.payment && mrData.payment.totalWithVat,
        );
        setTurnAroundTime(
          (mrData && mrData.roomLayout && mrData.roomLayout.turnAroundTime) ||
            15,
        );
        setCustomerFirstName(
          mrData && mrData.customer && mrData.customer.firstName,
        );
        setCustomerLastName(
          mrData && mrData.customer && mrData.customer.lastName,
        );
        setTenantName(mrData && mrData.customer && mrData.customer.tenantName);
        setCustomerEmail(mrData && mrData.customer && mrData.customer.email);
        setCustomerPhone(
          (mrData && mrData.customer && mrData.customer.phone) || '',
        );
        setNotes(mrData && mrData.notes);
        setStaffNotes1(mrData.staffNotes);
        setPresentDate(mrData.startDate);
        // setBookingInvoiceDate(
        //   mrData && mrData.bookingInvoice && mrData.bookingInvoice.invoiceDate,
        // );
        setBookingInvoice(mrData && mrData.bookingInvoice);
        setCateringInvoice((mrData && mrData.cateringInvoice) || '');
        setComplimentaryMenu(mrData && mrData.complimentaryCatering);
      })
      .catch(error => {
        setErrorMsg(error);
        setLoading(false);
      });
  };

  const handleClose = () => {
    if (!extendTime || extendTime === '') {
      alert('Please select extend time');
    } else {
      setOpen(false);
      history.push('/order-preview/');
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
    setOpenPopUp(false);
    setExtendTime('');
  };
  const handleSendMail = () => {
    viewBookingSchema
      .validate({
        firstName,
        guestEmail,
      })
      .then(() => {
        ApiClient.apiPost(`${apiEndPoints.requestGuestList}/${id}`, {
          email: guestEmail,
          firstName: firstName,
        })
          .then(() => {
            setOpenPopUp(false);
          })
          .catch(error => {
            setErrorMsg(error);
          });
      })
      .catch(error => {
        setErrorMsg(error);
      });
  };

  const cancelHandleSendMail = () => {
    setOpenPopUp(false);
  };

  const handleTextChange = event => {
    const text = event.target.value;
    const lines = text.split('\n').filter(value => value.trim() !== '');
    if (lines.length >= capacity + 1) {
      setErrorMessage(`Maximum Guest limit is ${capacity}.`);
    } else {
      setErrorMessage('');
      setLine(text);
    }
    setGuestListName(lines);
  };
  const handleKeyPress = event => {
    if (line === '' && event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleSaveButton = () => {
    setErrorMessage('');
    if (line.length !== 0) {
      ApiClient.apiPost(`${apiEndPoints.saveGuestList}/${id}`, {
        guestList: guestListName,
      })
        .then(() => {
          getMeetingRoomData();
        })
        .catch(error => {
          setErrorMsg(error);
        });
    } else {
      setErrorMessage('Guestlist Is Required');
    }
  };
  const cancelCatering = () => {
    setLoading(true);
    ApiClient.apiPut(`${apiEndPoints.cancelCatering}/${id}/cancelcatering`)
      .then(() => {
        setLoading(false);
        history.push('/');
      })
      .catch(error => {
        const err = error;
        alert(err.response.data.message);
        setLoading(false);
      });
  };
  const onNotesChange = e => setStaffNotes(e.target.value);
  const Discount =
    (roomDiscount === 0 && roomDiscount + voucherDiscount) || roomDiscount;
  const handleSubmit = () => {
    ApiClient.apiPost(`${apiEndPoints.staffNotes}/${id}`, {
      message: staffNotes,
    })
      // eslint-disable-next-line no-unused-vars
      .then(data => {
        setStaffNotes(staffNotes);
        getMeetingRoomData();
        setStaffNotes('');
        setLoading(false);
      })
      .catch(error => {
        const err = error;
        alert(err.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getMeetingRoomData();
    setErrorMessage('');
  }, []);

  const stausCall = value => {
    setLoading(true);
    const payload = {
      status: value,
    };
    ApiClient.apiPut(`${apiEndPoints.createMrBooking}/${id}/status`, payload)
      .then(() => {
        setLoading(false);
        history.push('/');
      })
      .catch(error => {
        const err = error;
        alert(err.response.data.message);
        setLoading(false);
      });
  };
  const statusCallExtend = value => {
    setLoading(true);
    const payload = {
      status: value,
    };
    ApiClient.apiPut(`${apiEndPoints.cancelExtend}/${id}`, payload)
      .then(() => {
        setLoading(false);
        history.push('/');
      })
      .catch(error => {
        const err = error;
        alert(err.response.data.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (warningPopUpSelected) {
      stausCall(bookingStatus.noShow);
    }
  }, [warningPopUpSelected]);

  const popUpOpen = () => setWarningPopUp(true);
  const popUpClose = () => {
    setWarningPopUp(false);
  };

  const rows = [
    createRow(
      `${mrName}  -  ${roomLayoutName}  -   ${buildingName}   -  ${moment
        .utc(startTime)
        .format('DD MMM YYYY')}  -  
        ${
          extend && extend?.eventType === bookingTimeType.futureBooking
            ? ` ${moment
                .tz(startTime, bookingTimeType.zone)
                .format('h:mm a')} - ${moment
                .tz(extend.startDate, bookingTimeType.zone)
                .format('h:mm a')} `
            : ''
        }

        ${
          extend && extend?.eventType === bookingTimeType.pastBooking
            ? ` ${moment
                .tz(extend.endDate, bookingTimeType.zone)
                .format('h:mm a')} - ${moment
                .tz(endTime, bookingTimeType.zone)
                .format('h:mm a')} `
            : ''
        }

        ${
          !extend
            ? ` ${moment
                .tz(startTime, bookingTimeType.zone)
                .format('h:mm a')} - ${moment
                .tz(endTime, bookingTimeType.zone)
                .format('h:mm a')} `
            : ''
        }
            -  ${turnAroundTime} Min`,
      '__',
      '__',
      `${Number(roomTotal).toFixed(2)}`,
    ),
  ];

  const rows1 = [
    createRow(
      `${mrName}  -  ${roomLayoutName}  -   ${buildingName}   -  ${moment
        .utc(extendStart)
        .format('DD MMM YYYY')}  -   ${moment
        .tz(extendStart, bookingTimeType.zone)
        .format('h:mm a')} - ${moment
        .tz(extendEnd, bookingTimeType.zone)
        .format('h:mm a')} `,
      '__',
      '__',
      `${Number(roomTotal).toFixed(2)}`,
    ),
  ];

  const timeDiff = Math.abs(new Date(startTime) - new Date()) / 36e5;

  const cateringDetails = [createRow('No catering ordered')];

  const cancellCateringStatus = listData && listData.status;

  const breadCrumbs = {
    listBreadCrumbPath: '/',
    listBreadCrumbTitle: 'Meeting Room Bookings',
    viewBreadCrumbPath: '/view-booking/',
    viewBreadCrumbTitle: 'View booking',
  };

  if (!(localStorage && localStorage.getItem('adal.session.state'))) {
    logOut();
  }

  return (
    <div className={classes.addUserFormBody}>
      <div className="pageBredcrumbTimer">
        <Breadcrumb breadCrumbs={breadCrumbs} />
      </div>
      <Divider light />
      <div className="pageContainer BookingStepContainer">
        {loading ? (
          <CircularProgress color="inherit" />
        ) : (
          <div className={`${classes.BookingStepForm}`}>
            <div className="cancelShowBtns">
              <ul>
                <li className={classes.formButton}>
                  <Button
                    className={classes.extendBtn}
                    onClick={handleClickOpen}
                    disabled={
                      timeDiff >= BookingPeriod.extendBookingTime ||
                      extend ||
                      new Date(startTime) < new Date().setHours(0, 0, 0, 0) ||
                      mrBookingStatus === bookingStatus.cancel
                    }
                  >
                    Extend
                  </Button>
                  <Button
                    disabled={
                      mrBookingMethod === 'Pos' ||
                      new Date(startTime) < new Date() ||
                      mrBookingStatus === bookingStatus.cancel
                    }
                    onClick={() => {
                      stausCall(bookingStatus.cancel);
                    }}
                  >
                    Cancel All
                  </Button>
                  <Button
                    disabled={
                      new Date(startTime) < new Date() ||
                      mrBookingStatus === bookingStatus.cancel ||
                      mrBookingStatus === bookingStatus.noShow
                    }
                    onClick={() => {
                      popUpOpen();
                    }}
                  >
                    No Show
                  </Button>
                  <div className="extendPopup">
                    <Dialog
                      open={open}
                      onClose={handleDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      className="cancelDialogOpend extendPopupDialogOpend pb_30 width_45p width_100p"
                    >
                      <ul className={`${classes.InputSection} offerTimeCat`}>
                        <li className={`${classes.InputSection} mb_22`}>
                          <RadioGroup
                            aria-label="Active"
                            name="Available"
                            defaultValue="Free"
                            value={eventType}
                            onChange={e => {
                              setEventType(e.target.value);
                            }}
                          >
                            <div
                              className={`${classes.radioSection} radioSection`}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Extend After Meeting End Time"
                                checked={
                                  eventType === bookingTimeType.futureBooking
                                }
                                value={bookingTimeType.futureBooking}
                              />
                              <FormControlLabel
                                control={<Radio />}
                                label="Extend Before Meeting Start Time"
                                checked={
                                  eventType === bookingTimeType.pastBooking
                                }
                                value={bookingTimeType.pastBooking}
                              />
                            </div>
                          </RadioGroup>
                        </li>
                        <li className={`${classes.InputSection} `}>
                          <span>Extended Time*</span>
                          <Select
                            className={classes.formSelectBox}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            }}
                            onChange={e => {
                              setExtendTime(e.target.value);
                            }}
                          >
                            <MenuItem value="15">15</MenuItem>
                            <MenuItem value="30">30</MenuItem>
                            <MenuItem value="45">45</MenuItem>
                            <MenuItem value="60">60</MenuItem>
                            <MenuItem value="75">75</MenuItem>
                            <MenuItem value="90">90</MenuItem>
                            <MenuItem value="105">105</MenuItem>
                            <MenuItem value="120">120</MenuItem>
                            <MenuItem value="135">135</MenuItem>
                            <MenuItem value="150">150</MenuItem>
                            <MenuItem value="165">165</MenuItem>
                            <MenuItem value="180">180</MenuItem>
                            <MenuItem value="195">195</MenuItem>
                            <MenuItem value="210">210</MenuItem>
                            <MenuItem value="225">225</MenuItem>
                            <MenuItem value="240">240</MenuItem>
                          </Select>
                        </li>
                      </ul>
                      <DialogActions
                        className={`${classes.formButton} ${classes.popupFormButton} ${classes.extendPopupFormButton}`}
                      >
                        <Button onClick={handleClose} autoFocus>
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div className="extendPopup">
                    <Dialog
                      open={openPopUp}
                      onClose={handleDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      className="cancelDialogOpend extendPopupDialogOpend"
                    >
                      <ul
                        style={{ marginBottom: '20px' }}
                        className={`${classes.InputSection} offerTimeCat`}
                      >
                        <li className={`${classes.InputSection} `}>
                          <span>
                            First Name* (Email will be addressed to this name)
                          </span>
                          <Input
                            className="textareaSmallBox"
                            placeholder="Enter first name"
                            value={firstName}
                            onChange={e => {
                              const re = /^[A-Za-z0-9.@]+$/;
                              if (
                                e.target.value === '' ||
                                re.test(e.target.value)
                              ) {
                                setFirstName(e.target.value);
                              }
                            }}
                          />
                          {errorFieldValue && errorFieldValue.firstName === '' && (
                            <FormHelperText
                              style={{ color: 'red' }}
                              className={classes.errorMsg}
                              id="firstName"
                            >
                              {firstName === '' && 'First Name* is required.'}
                            </FormHelperText>
                          )}
                        </li>
                      </ul>
                      <ul className={`${classes.InputSection} offerTimeCat`}>
                        <li className={`${classes.InputSection} `}>
                          <span>Email*</span>
                          <Input
                            className="textareaSmallBox"
                            placeholder="Enter Email"
                            value={guestEmail}
                            onChange={e => {
                              setGuestEmail(e.target.value);
                            }}
                          />
                          {((errorType === 'required' &&
                            errorFieldName === 'guestEmail') ||
                            (errorType === 'email' &&
                              errorFieldName === 'guestEmail') ||
                            (errorFieldValue &&
                              errorFieldValue.guestEmail === '')) && (
                            <FormHelperText
                              style={{ color: 'red' }}
                              className={classes.errorMsg}
                              id="guestEmail"
                            >
                              {guestEmail === ''
                                ? 'Email* is required.'
                                : errorType === 'email' &&
                                  'Please enter a valid Email.'}
                            </FormHelperText>
                          )}
                        </li>
                      </ul>
                      <DialogActions
                        className={`${classes.formButton} popupFormButton`}
                      >
                        <Button onClick={cancelHandleSendMail}>Cancel</Button>
                        <Button onClick={handleSendMail} autoFocus>
                          Send
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <Dialog
                    open={warningPopUp}
                    onClose={popUpClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="cancelDialogOpend"
                  >
                    <div
                      className={`${classes.cancelDialog} meetingRCancelDialog`}
                    >
                      <h2>Are you sure you want to mark this as a no show?</h2>
                      <p>The room and time slot will become available again.</p>
                    </div>
                    <Divider className="formDivider popupDivider" light />
                    <DialogActions
                      className={`${classes.formButton} ${classes.popupFormButton} popupFormButton`}
                    >
                      <Button
                        onClick={() => {
                          setWarningPopUp(false);
                        }}
                        color="primary"
                      >
                        No, go back
                      </Button>
                      <Button
                        onClick={() => {
                          setWarningPopUpSelected(true);
                          popUpClose();
                        }}
                        autoFocus
                      >
                        Yes, cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                </li>
              </ul>
            </div>
            <div className="orderMainTable">
              <TableContainer component={Paper} className="orderTable">
                <Table className={classes.table} aria-label="spanning table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Meeting Room</TableCell>
                      <TableCell align="left">Qty</TableCell>
                      <TableCell align="right">Price </TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="orderPCont">
                    {rows.map(row => (
                      <TableRow key={row.desc}>
                        <TableCell>{row.desc}</TableCell>
                        <TableCell align="left">{row.qty}</TableCell>
                        <TableCell align="right">{row.unit}</TableCell>
                        <TableCell align="right">£{row && row.total}</TableCell>
                      </TableRow>
                    ))}
                    {complimentaryMenu &&
                      complimentaryMenu.map(complimentaryItems => (
                        <TableRow key={complimentaryItems.categoryId}>
                          <TableCell>{`${
                            complimentaryItems &&
                            complimentaryItems.menu &&
                            complimentaryItems.menu
                              .map(menuItem => menuItem.itemName)
                              .toString()
                              .split(',')
                              .join(', ')
                          }`}</TableCell>
                          <TableCell align="left">
                            {complimentaryItems.capacity}
                          </TableCell>
                          <TableCell align="right">{(0).toFixed(2)}</TableCell>
                          <TableCell align="right">£{(0).toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>

                  <TableBody className="orderPBreakdown">
                    <TableRow className="orderDiscount">
                      <TableCell align="left">&nbsp;</TableCell>
                      <TableCell align="left">
                        <span>Discount</span>
                      </TableCell>
                      <TableCell colSpan="2" align="right">
                        £{Number(Math.min(roomTotal, Discount)).toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">&nbsp;</TableCell>
                      <TableCell align="left">Subtotal</TableCell>
                      <TableCell colSpan="2" align="right">
                        £{Number(totalAmount).toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">&nbsp;</TableCell>
                      <TableCell align="left">VAT @ {vat}%</TableCell>
                      <TableCell colSpan="2" align="right">
                        £{Number(totalAmountWithVat - totalAmount).toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">&nbsp;</TableCell>
                      <TableCell align="left">Room Total</TableCell>
                      <TableCell colSpan="2" align="right">
                        £{Number(totalAmountWithVat).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="orderDateInvoice">
              <div>
                Booking made on{' '}
                <span>
                  {moment(bookingDate)
                    .tz(bookingTimeType.zone)
                    .format('DD/MM/YYYY hh:mm a')}
                </span>
              </div>
              {bookingInvoice && bookingInvoice.url ? (
                <div className="roomCoctsUnderline">
                  <a rel="noreferrer" href={bookingInvoice.url} target="_blank">
                    Meeting room invoice
                  </a>
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="orderNotes">
              Notes:
              <span>{notes}</span>
            </div>
            {moment().isAfter({ presentDate }) ? (
              <div className="orderNotes staffNotes">
                Staff Notes:
                <span style={{ whiteSpace: 'pre' }}>
                  {staffNotes1 &&
                    staffNotes1.map(note => (
                      <div key={note.name}>
                        {note.name}[
                        {moment(note.timeStamp)
                          .tz(bookingTimeType.zone)
                          .format('DD MMM YYYY hh:mm a')}
                        ]&nbsp;-&nbsp;{note.notes}
                      </div>
                    ))}
                </span>
                <textarea value={staffNotes} onChange={onNotesChange} />
                <br />
                <Button
                  type="submit"
                  disabled={!staffNotes}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            ) : (
              ''
            )}
            {isCatering ? (
              <div className="cateringMainTable">
                <div className="cancelShowBtns">
                  <ul>
                    <li
                      className={`${classes.formButton} ${classes.cancelOrderButton}`}
                    >
                      <Button
                        disabled={
                          (listData &&
                            listData.orderMenu &&
                            listData.orderMenu.length === 0) ||
                          new Date(startTime) < new Date() ||
                          cancellCateringStatus === bookingStatus.cancel ||
                          cateringDataPayment.method === 'Pos'
                        }
                        onClick={() => {
                          cancelCatering();
                        }}
                      >
                        Cancel Order
                      </Button>
                    </li>
                  </ul>
                </div>
                <TableContainer component={Paper} className="orderTable">
                  <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Catering</TableCell>
                        {listData &&
                          listData.orderMenu &&
                          listData.orderMenu.length > 0 && (
                            <>
                              <TableCell align="left">Qty</TableCell>
                              <TableCell align="right">Price </TableCell>
                              <TableCell align="right">Total</TableCell>
                            </>
                          )}
                      </TableRow>
                    </TableHead>
                    {(!listData ||
                      (listData &&
                        listData.orderMenu &&
                        listData.orderMenu.length === 0)) &&
                      cateringDetails.map(cateringDetail => (
                        <TableRow key={cateringDetail.desc}>
                          <TableCell>{cateringDetail.desc}</TableCell>
                        </TableRow>
                      ))}
                    {listData &&
                      listData.orderMenu &&
                      listData.orderMenu.map(orderMenuItems => (
                        <TableBody
                          className="orderPCont"
                          key={orderMenuItems.categoryId}
                        >
                          {orderMenuItems &&
                            orderMenuItems.orderItems.map(orderItem => (
                              <TableRow key={orderItem.itemId}>
                                <TableCell>{orderItem.itemName}</TableCell>
                                <TableCell align="left">
                                  {orderItem.quantity}
                                </TableCell>
                                <TableCell align="right">
                                  £{orderItem.price.toFixed(2)}
                                </TableCell>
                                <TableCell align="right">
                                  £
                                  {Number(
                                    orderItem.price * orderItem.quantity,
                                  ).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      ))}
                    {listData &&
                      listData.orderMenu &&
                      listData.orderMenu.length !== 0 && (
                        <TableBody className="orderPBreakdown">
                          <TableRow>
                            <TableCell align="left">&nbsp;</TableCell>
                            <TableCell align="left">Subtotal</TableCell>
                            <TableCell colSpan={2} align="right">
                              £
                              {listData &&
                                listData.totalPrice &&
                                listData.totalPrice.toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&nbsp;</TableCell>
                            <TableCell align="left">
                              VAT @ {listData && listData.vat}%
                            </TableCell>
                            <TableCell colSpan={2} align="right">
                              £
                              {(
                                Number(listData && listData.totalWithVat) -
                                Number(listData && listData.totalPrice)
                              ).toFixed(2)}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell align="left">&nbsp;</TableCell>
                            <TableCell align="left">Catering Total</TableCell>
                            <TableCell colSpan={2} align="right">
                              £
                              {(
                                listData &&
                                listData.totalPrice &&
                                listData.totalWithVat
                              ).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                  </Table>
                </TableContainer>
                <div className="orderDateInvoice">
                  <div>
                    Booking made on{' '}
                    <span>
                      {moment(bookingDate)
                        .tz(bookingTimeType.zone)
                        .format('DD/MM/YYYY hh:mm a')}
                    </span>
                  </div>
                  <div className="roomCoctsUnderline">
                    {(cateringInvoice.url && (
                      <a
                        rel="noreferrer"
                        href={cateringInvoice.url}
                        target="_blank"
                      >
                        Catering invoice
                      </a>
                    )) ||
                      ''}
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="customerDtls">
              <h2>Customer Details</h2>
              <p>
                {`${customerFirstName} ${customerLastName} - ${tenantName} -
                ${customerEmail} ${
                  customerPhone ? ` - ${customerPhone}` : ''
                } `}
              </p>
            </div>
            <div className="GuestList">
              <div>
                <span>Meeting Room Guestlist</span>
                <Button
                  style={{ backgroundColor: 'black', color: 'black' }}
                  disabled={
                    new Date(endTime) < new Date() || guestListRequestedOn
                  }
                  className="requestguestlistbtn"
                  onClick={handleRequestListPopUp}
                >
                  <span style={{ color: 'white', textTransform: 'none' }}>
                    Request Guestlist
                  </span>
                </Button>
              </div>
              <textarea
                disabled={new Date(endTime) < new Date()}
                value={line}
                placeholder="Please add each name on a new line"
                onChange={handleTextChange}
                onKeyPress={handleKeyPress}
              />
              {errorMessage && (
                <p className={`${classes.errorMsgForGuest}`}>{errorMessage}</p>
              )}
              {hostName ? (
                <div>
                  Submitted By: {hostName} on{' '}
                  {moment(guestListRequestedOn)
                    .tz(bookingTimeType.zone)
                    .format('DD/MM/YYYY hh:mm a')}
                </div>
              ) : (
                ''
              )}
              <Button
                style={{
                  backgroundColor: 'black',
                  color: '#ffffff',
                  textTransform: 'none',
                }}
                className="savebtnforguestlist"
                onClick={handleSaveButton}
                disabled={new Date(endTime) < new Date()}
              >
                Save
              </Button>
            </div>
            {(extend && (
              <div className="orderMainTable extendedOrderTable">
                <div className={`${classes.BookingStepForm}`}>
                  <div className="cancelShowBtns">
                    <ul>
                      <li className={classes.formButton}>
                        <Button
                          className={classes.extendBtn}
                          onClick={MakePaymentClick}
                          disabled={extendPaymentStatus === 'Paid'}
                        >
                          Make payment
                        </Button>
                        <Button
                          className={classes.extendBtn}
                          disabled={
                            new Date(startTime) <
                            new Date().setHours(0, 0, 0, 0)
                          }
                          onClick={() => {
                            statusCallExtend(bookingStatus.cancel);
                          }}
                        >
                          Cancel Extend
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
                <TableContainer component={Paper} className="orderTable">
                  <Table className={classes.table} aria-label="spanning table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Meeting Room (Extended)</TableCell>
                        <TableCell align="left">Qty</TableCell>
                        <TableCell align="right">Price </TableCell>
                        <TableCell align="right">Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="orderPCont">
                      {rows1.map(row => (
                        <TableRow key={row.desc}>
                          <TableCell>{row.desc}</TableCell>
                          <TableCell align="left">{row.qty}</TableCell>
                          <TableCell align="right">{row.unit}</TableCell>
                          <TableCell align="right">
                            £{extendRoomTotal}
                          </TableCell>
                        </TableRow>
                      ))}
                      {complimentaryMenu &&
                        complimentaryMenu.map(complimentaryItems => (
                          <TableRow key={complimentaryItems.categoryId}>
                            <TableCell>{`${
                              complimentaryItems &&
                              complimentaryItems.menu &&
                              complimentaryItems.menu
                                .map(menuItem => menuItem.itemName)
                                .toString()
                                .split(',')
                                .join(', ')
                            }`}</TableCell>
                            <TableCell align="left">
                              {complimentaryItems.capacity}
                            </TableCell>
                            <TableCell align="right">
                              {(0).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              £{(0).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>

                    <TableBody className="orderPBreakdown">
                      <TableRow className="orderDiscount">
                        <TableCell align="left">&nbsp;</TableCell>
                        <TableCell align="left">
                          <span>Discount</span>
                        </TableCell>
                        <TableCell colSpan="2" align="right">
                          £{Number(extendDiscount).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">&nbsp;</TableCell>
                        <TableCell align="left">Subtotal</TableCell>
                        <TableCell colSpan="2" align="right">
                          £{Number(extendRoomTotal - extendDiscount).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">&nbsp;</TableCell>
                        <TableCell align="left">VAT @ {vat}%</TableCell>
                        <TableCell colSpan="2" align="right">
                          £{Number(extendTotalWithVat - extendTotal).toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">&nbsp;</TableCell>
                        <TableCell align="left">Room Total</TableCell>
                        <TableCell colSpan="2" align="right">
                          £{Number(extendTotalWithVat).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="orderDateInvoice">
                  <div>
                    Booking made on{' '}
                    <span>
                      {moment(extend.createdDate)
                        .tz(bookingTimeType.zone)
                        .format('DD/MM/YYYY hh:mm a')}
                    </span>
                  </div>
                  <div className="roomCoctsUnderline">
                    {(extendInvoice && (
                      <a rel="noreferrer" href={extendInvoice} target="_blank">
                        Extend Meeting room invoice
                      </a>
                    )) ||
                      ''}
                  </div>
                </div>
              </div>
            )) ||
              ''}

            <div className="totalDtls">
              <h3> Total</h3>
              {(extend && (
                <h5>
                  £
                  {listData
                    ? (
                        (cancellCateringStatus === bookingStatus.cancel
                          ? 0
                          : listData && listData.totalWithVat) +
                        Number(extendTotalWithVat + totalAmountWithVat)
                      ).toFixed(2)
                    : Number(extendTotalWithVat + totalAmountWithVat).toFixed(
                        2,
                      )}
                </h5>
              )) || (
                <h5>
                  £
                  {listData
                    ? (
                        (cancellCateringStatus === bookingStatus.cancel
                          ? 0
                          : listData && listData.totalWithVat) +
                        Number(totalAmountWithVat)
                      ).toFixed(2)
                    : Number(totalAmountWithVat).toFixed(2)}
                </h5>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ViewBookedMeeting;
