import { authContext } from '../adal';
import ApiClient from '../helpers/ApiClient';
import apiEndPoints from '../helpers/apiEndPoints';

export const logOut = () => {
  const payload = {
    act: 'signOut',
  };
  ApiClient.apiPost(apiEndPoints.logAct, payload)
    .then(() => {
      localStorage.clear();
      authContext.logOut();
    })
    .catch(error => {
      localStorage.clear();
      authContext.logOut();
      console.log(error);
    });
};
