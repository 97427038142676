import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  FormControl,
  FormLabel,
  Input,
  RadioGroup,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import dowmArw from '../../images/down-arw.svg';
import ApiClient from '../../helpers/ApiClient';
import apiEndPoints from '../../helpers/apiEndPoints';
import buttonsEditPencil from '../../images/buttons-edit-w-pencil.svg';
import './ManageVoucher.css';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { GlobalContext } from '../../context/GlobalContext';
import CloseButton from '../../components/Buttons/CloseButton';

const useStyles = makeStyles(() => ({
  pageContainer: {
    height: '100%',
    width: '100%',
    marginTop: '88px',
    padding: '0 30px',
    boxSizing: 'border-box',
  },
  addUserForm: {
    width: '60%',
    margin: '0 auto',
    padding: '40px 0',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'flex-end',
      '& li': {
        listStyle: 'none',
        width: '46%',
        marginBottom: '22px',
      },
    },
  },
  formControl: {
    width: '100%',
    '& InputLabel': {
      position: 'relative',
    },
  },
  formLabel: {
    fontSize: '16px',
    fontWeight: 'normal',
    color: '#838384',
    transform: 'none',
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
  formSelectBox: {
    '& svg': {
      display: 'none',
    },
    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '& > div': {
      height: '45px',
      lineHeight: '45px',
      padding: '0 15px !important',
      fontSize: '16px',
      color: '#444444',
      borderRadius: '3px',
      border: 'solid 0.5px #979797',
      marginTop: '10px',
      background: `transparent url(${dowmArw}) no-repeat 95% center !important`,
      backgroundSize: '10px',
      appearance: 'none',
      fontFamily: ['Varta', 'sans-serif'].join(','),
    },
  },
  formSelectBoxNodown: {
    '& > div': {
      background: ' transparent !important',
    },
  },
  InputSection: {
    '& li': {
      '& span': {
        display: 'block',
        fontSize: '16px',
        fontWeight: 'normal',
        color: '#838384',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& input,textarea': {
        height: '45px',
        lineHeight: '45px',
        padding: '0 15px !important',
        fontSize: '16px',
        color: '#444444',
        borderRadius: '3px',
        border: 'solid 0.5px #979797',
        marginTop: '10px',
        width: '100%',
        background: 'transparent',
        fontFamily: ['Varta', 'sans-serif'].join(','),
      },
      '& textarea': {
        height: '90px',
      },
      '& > div': {
        width: '100%',
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
      },
    },
  },
  InputSectionReadonly: {
    '& > div': {
      '& > div': {
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '& input': {
          height: '45px',
          lineHeight: '45px',
          padding: '0 15px !important',
          fontSize: '16px',
          color: '#444444',
          borderRadius: '3px',
          border: 'solid 0.5px #979797',
          marginTop: '10px',
          appearance: 'none',
          fontFamily: ['Varta', 'sans-serif'].join(','),
        },
      },
    },
  },
  radioSection: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '& svg': {
        fontSize: '16px',
        fill: '#979797',
        '&:last-child': {
          fill: '#4e4e4e',
        },
      },
    },
  },
  errorMsg: {
    fontSize: '13px',
    color: '#f90000 !important',
    marginTop: 4,
    fontFamily: ['Varta', 'sans-serif'].join(','),
  },
}));

const ViewVoucher = () => {
  const { rolePermission } = useContext(GlobalContext);
  const classes = useStyles();
  const { id } = useParams();
  const [voucherDetails, setVoucherDetails] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ApiClient.apiGet(`${apiEndPoints.voucherCodes}/${id}`)
      .then(data => {
        const voucherData = data && data.data && data.data.data;
        setVoucherDetails(voucherData);
        setLoading(false);
      })
      .catch(error => {
        console.log(error, 'error');
        setLoading(false);
      });
  }, []);

  const breadCrumbs = {
    listBreadCrumbPath: '/voucherlist',
    listBreadCrumbTitle: 'Manage Voucher List',
    viewBreadCrumbPath: '/view-voucher/',
    viewBreadCrumbTitle: 'View Voucher',
  };

  return (
    <div className={classes.addUserFormBody}>
      <Breadcrumb breadCrumbs={breadCrumbs} />
      <Divider light />
      {loading ? (
        <CircularProgress color="inherit" />
      ) : (
        <div className={classes.addUserFormMain}>
          <div className="pageContainer addUserContainer">
            <div className={`${classes.addUserForm} createcategoryForm`}>
              {rolePermission && rolePermission.editVoucerCode && (
                <Link to={`/edit-voucher/${id}`}>
                  <div className="ditIcon">
                    <img src={buttonsEditPencil} alt="buttonsEditPencil" />
                  </div>
                </Link>
              )}
              <ul className="offerFormList">
                <li>
                  <FormLabel component="legend">Voucher Details</FormLabel>
                </li>
              </ul>
              <ul className={`${classes.InputSection} creaeCategoryField`}>
                <li>
                  <span>Voucher code*</span>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={voucherDetails.code}
                  />
                </li>
                <li>
                  <span>Discount Type*</span>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={voucherDetails.type}
                  />
                </li>
                <li>
                  <span>Discount*</span>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={voucherDetails.discount}
                  />
                </li>
                <li>
                  <span>Redeemed</span>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={voucherDetails.redeemed}
                  />
                </li>
              </ul>
              <ul className={`${classes.InputSection} creaeCategoryField`}>
                <li>
                  <span>Voucher Description*</span>
                  <textarea
                    readOnly
                    type="text"
                    value={voucherDetails.description}
                  />
                </li>
              </ul>
              <ul className={`${classes.InputSection}`}>
                <li className={`${classes.InputSection}`}>
                  <span>Start Date*</span>
                  <Input
                    readOnly
                    type="text"
                    value={moment(voucherDetails.startDate).format(
                      'DD MMM YYYY',
                    )}
                  />
                </li>
                <li>
                  <span>Expiry Date*</span>
                  <Input
                    readOnly
                    type="text"
                    value={moment(voucherDetails.endDate).format('DD MMM YYYY')}
                  />
                </li>
              </ul>
              <Divider className="formDivider" light />
              <ul className={`${classes.InputSection} voucherCodeRadio `}>
                <li>
                  <FormLabel component="legend">Status</FormLabel>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="Active" name="Available">
                      <div className={`${classes.radioSection} radioSection`}>
                        <p>{voucherDetails.status}</p>
                      </div>
                    </RadioGroup>
                  </FormControl>
                </li>
                <li>
                  <span>Voucher Type*</span>
                  <Input
                    className="textareaSmallBox"
                    readOnly
                    type="text"
                    value={voucherDetails.voucherType}
                  />
                </li>
              </ul>
              <CloseButton route="/voucherlist" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewVoucher;
