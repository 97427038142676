/* eslint-disable import/named */
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Header from '../components/Header/Header';
import Sidebar from '../components/Sidebar/Sidebar';
import Adduser from '../containers/Users/AddUser';
import EditUser from '../containers/Users/EditUser';
import AddViewUser from '../containers/Users/AddViewUser';
import { MeetingRoomContextProvider } from '../context/MeetingRoomContext';
import { NewsAnnouncementsContextProvider } from '../context/NewsAnnouncementsContext';
import { OffersListContextProvider } from '../context/OffersListContext';
import CreateAndUpdateOffer from '../containers/Offers/CreateAndUpdateOffer';
import ViewOffer from '../containers/Offers/ViewOffer';
import AddNewsArticle from '../containers/NewsAnnouncements/AddNewsArticle';
import ViewNewsArticle from '../containers/NewsAnnouncements/ViewNewsArticle';
import CateringCategoryList from '../containers/CateringCategory/CateringCategory';
import CreateCategory from '../containers/CateringCategory/CreateCategory';
import { TenentContextProvider } from '../context/TententUserContext';
import CreateSpace from '../containers/Space/CreateSpace';
import CreateAndUpdateCateringMenu from '../containers/CateringMenu/CreateAndUpdateCateringMenu';
import ViewCateringMenu from '../containers/CateringMenu/ViewCateringMenu';
import { CateringManuContextProvider } from '../context/CateringManuContext';
import { SpacesContextProvider } from '../context/SpacesContext';
import ViewSpace from '../containers/Space/ViewSpace';
import { CategoryContextProvider } from '../context/CateringCategoryContext';
import ViewCategory from '../containers/CateringCategory/ViewCategory';
import EditMeetingRoom from '../containers/MeetingRooms/EditMeetingRoom';
import AddMeetingRoomUser from '../containers/MeetingRooms/AddMeetingRoomUser';
import MeetingRoomViewList from '../containers/MeetingRooms/MeetingRoomViewList';
import BigCalender from '../components/BigCalender/BigCalender';
import { BookingContextProvider } from '../context/BookingContext';
import ViewBookedMeeting from '../containers/MeetingRoomBooking/ViewBookedMeeting';
import { MRBookingContextProvider } from '../context/MRBookingContext';
import EditBuildingAttributes from '../containers/BuildingAttributes/EditBuildingAttributes';
import ViewBuildingAttributes from '../containers/BuildingAttributes/ViewBuildingAttributes';
import { BuildingAttributesContextProvider } from '../context/BuildingAttributesContext';
import { ManageTenentContextProvider } from '../context/ManageTenentUsersContext';
import { EventsContextProvider } from '../context/EventsContext';
import CreateOrEditEvent from '../containers/Events/CreateOrEditEvent';
import ViewEventDetails from '../containers/Events/ViewEventDetails';
import { ManageOffersContextProvider } from '../context/ManageOffersContext';
import { EventBookingsContextProvider } from '../context/EventBookingsContext';
import CreateManageVoucher from '../containers/ManageVoucher/CreateManageVoucher';
import ViewVoucher from '../containers/ManageVoucher/ViewManageVoucher';
import { ManageVoucherContextProvider } from '../context/ManageVoucherContext';
import { MRExtendBookingContextProvider } from '../context/MRExtendBookingContext';
import Notification from '../containers/Notification/notification';
import { LoyaltyCardContextProvider } from '../context/LoyaltyCardContext';
import CreateLoyaltyCard from '../containers/LoyaltyCards/CreateLoyaltyCard';
import ViewLoyaltyCard from '../containers/LoyaltyCards/ViewLoyaltyCard';
import AnalyticsHome from '../containers/Analytics/AnalyticsHome'
// import Payments from '../containers/Payments/Payments';

const Routes = () => {
  const [open, setOpen] = React.useState(false);

  const roles = localStorage.getItem('rolePermission');
  const rolesJson = JSON.parse(roles);

  return (
    <Router>
      <Header open={open} setOpen={setOpen} />
      <Switch>
        <Route exact path="/tenantusers" component={TenentContextProvider} />
        <Route exact path="/adduser" component={Adduser} />
        <Route exact path="/viewuser/:id" component={AddViewUser} />
        <Route exact path="/edituser/:id" component={EditUser} />
        <Route exact path="/editmeetingroom/:id" component={EditMeetingRoom} />
        {(rolesJson && rolesJson[0].userType === 'Caterer' && (
          <Route exact path="/" component={CategoryContextProvider} />
        )) || <Route exact path="/" component={BigCalender} />}
        <Route
          exact
          path="/cateringcategorylist"
          component={CateringCategoryList}
        />
        <Route exact path="/createcategory" component={CreateCategory} />
        <Route exact path="/spacelist" component={SpacesContextProvider} />
        <Route exact path="/createspace" component={CreateSpace} />
        <Route
          exact
          path="/catering-menu-list"
          component={CateringManuContextProvider}
        />
        <Route exact path="/viewspace/:id" component={ViewSpace} />
        <Route exact path="/editspace/:id" component={CreateSpace} />
        <Route
          exact
          path="/create-cateringmenu"
          component={CreateAndUpdateCateringMenu}
        />
        <Route
          exact
          path="/view-cateringmenu/:id"
          component={ViewCateringMenu}
        />
        <Route
          exact
          path="/edit-cateringmenu/:id"
          component={CreateAndUpdateCateringMenu}
        />
        <Route
          exact
          path="/viewmeetingroomlist/:id"
          component={MeetingRoomViewList}
        />
        <Route
          exact
          path="/meetingrooms-list"
          component={MeetingRoomContextProvider}
        />
        <Route exact path="/addmeetingroom" component={AddMeetingRoomUser} />
        <Route
          exact
          path="/offers-list"
          component={OffersListContextProvider}
        />
        <Route
          exact
          path="/news-announcements"
          component={NewsAnnouncementsContextProvider}
        />
        <Route exact path="/events-list" component={EventsContextProvider} />
        <Route exact path="/create-an-event" component={CreateOrEditEvent} />
        <Route exact path="/edit-an-event/:id" component={CreateOrEditEvent} />
        <Route
          exact
          path="/view-event-details/:id"
          component={ViewEventDetails}
        />
        <Route exact path="/add-newsarticle" component={AddNewsArticle} />
        <Route exact path="/edit-newsarticle/:id" component={AddNewsArticle} />
        <Route exact path="/view-newsarticle/:id" component={ViewNewsArticle} />
        <Route exact path="/create-offers" component={CreateAndUpdateOffer} />
        <Route exact path="/view-offers/:id" component={ViewOffer} />
        <Route exact path="/edit-offers/:id" component={CreateAndUpdateOffer} />
        <Route exact path="/cateringlist" component={CategoryContextProvider} />
        <Route exact path="/create-category" component={CreateCategory} />
        <Route exact path="/edit-category/:id" component={CreateCategory} />
        <Route exact path="/view-category/:id" component={ViewCategory} />
        <Route exact path="/createloyaltycard" component={CreateLoyaltyCard} />
        <Route
          exact
          path="/edit-loyaltycard/:id"
          component={CreateLoyaltyCard}
        />
        <Route exact path="/viewloyaltycard/:id" component={ViewLoyaltyCard} />
        <Route exact path="/bookinglist" component={BookingContextProvider} />
        <Route exact path="/notification" component={Notification} />
        <Route
          exact
          path="/meetingroom-booking"
          component={MRBookingContextProvider}
        />
        <Route
          exact
          path="/order-preview/"
          component={MRExtendBookingContextProvider}
        />
        <Route exact path="/view-booking/:id" component={ViewBookedMeeting} />
        <Route
          exact
          path="/building-attributes"
          component={BuildingAttributesContextProvider}
        />
        <Route
          exact
          path="/edit-building-attributes/:id"
          component={EditBuildingAttributes}
        />
        <Route
          exact
          path="/view-building-attributes/:id"
          component={ViewBuildingAttributes}
        />
        <Route
          exact
          path="/managetenants"
          component={ManageTenentContextProvider}
        />
        <Route
          exact
          path="/event-bookings"
          component={EventBookingsContextProvider}
        />
        <Route
          exact
          path="/card-management"
          component={ManageOffersContextProvider}
        />
        <Route
          exact
          path="/voucherlist"
          component={ManageVoucherContextProvider}
        />
        <Route
          exact
          path="/loyaltycardlist"
          component={LoyaltyCardContextProvider}
        />
        <Route
          exact
          path="/analytics"
          component={AnalyticsHome}
        />
        <Route
          exact
          path="/Create-Manage-Voucher"
          component={CreateManageVoucher}
        />
        <Route exact path="/edit-voucher/:id" component={CreateManageVoucher} />
        <Route exact path="/view-voucher/:id" component={ViewVoucher} />
      </Switch>
      <Sidebar open={open} setOpen={setOpen} />
      {/* <Route exact path="/payments" component={Payments} /> */}
    </Router>
  );
};

export default Routes;
