import { makeStyles } from '@material-ui/core';

const useButtonStyles = makeStyles({
  formButton: {
    marginTop: '40px',
    width: '100% !important',
    display: 'flex',
    justifyContent: 'flex-end',
    '& Button': {
      height: '47px',
      lineHeight: '47px',
      border: '1px solid #000',
      outline: 'none',
      background: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      width: 'auto',
      padding: '0 45px',
      color: '#fff',
      marginLeft: '30px',
      cursor: 'pointer',
      fontFamily: ['Varta', 'sans-serif'].join(','),
      '&:hover': {
        background: '#000000',
      },
      '&:first-child': {
        background: 'transparent',
        color: '#444444',
        border: 'none',
      },
    },
  },
  lineformButtonMain:{
    width: '100% !important',
    display:'block !important',

  },
  lineformButton: {
    marginBottom: '0px !important',
    marginTop: '20px !important',
    width: '100% !important',
    '& hr': {
      margin: '24px auto',
    },
  },
});
export default useButtonStyles;
